<div class="modal fade" id="impersonationModal" tabindex="-1" role="dialog" aria-labelledby="impersonationModalHeader" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="impersonationModalHeader">
          Give These Details To Support Person
        </h5>
      </div>
      <div class="modal-body details">
        <div class="text-break"><b>User Name : </b>{{userName}}</div>
        <div><b>Impersonation Code :</b> {{impersonationCode}}</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hideModal('impersonationModal');"> Ok </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="impersonatingModal" tabindex="-1" role="dialog" aria-labelledby="impersonatingModalHeader" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="impersonatingModalHeader">Start Impersonating</h5>
      </div>
      <div class="modal-body">
        <sms-alert></sms-alert>
        <div class="filter-nav">
            <ul class="nav nav-pills" role="tablist">
              <li class="nav-item" value="impersonating" (click)="onTabClick(true)">
                <a [class.active]="showImpersonateTab" class="nav-link"  data-bs-toggle="tab" href="#" role="tab">Impersonation</a>
              </li>
              <li *ngIf="isImplicitImpersonator" value="resetPassword" (click)="onTabClick(false)">
                <a [class.active]="!showImpersonateTab" class="nav-link" data-bs-toggle="tab" href="#" role="tab">Security Information</a>
              </li>
            </ul>
        </div>
        <div [ngStyle]="{'display': showImpersonateTab ? 'block' : 'none'}">
          <form *ngIf="form" [formGroup]="form" autocomplete="off">
            <div class="form-group" [class.has-danger]="shouldShowErrors('id')">
              <label class="req form-label">Impersonation</label>
              <jjk-select [options]="impersonationOption" [(selection)]="selectedImpersonation"></jjk-select>
            </div>

            <div class="form-group" [hidden]="!isImplicitImpersonator" [class.has-danger]="shouldShowErrors('description')">
              <label class="req form-label"> Description </label>
              <textarea class="form-control" rows="5" formControlName="description" maxlength="260"></textarea>
            </div>

            <div class="form-group" [class.has-danger]="shouldShowErrors('userName') || getControl('userName').errors?.invalidUser">
              <label for="userName" class="small req form-label"><strong>User Name</strong></label>
              <input name="userName" class="form-control large-input" type="text" formControlName="userName" maxlength="100">
              <div *ngIf="getControl('userName').errors?.invalidUser" class="invalid-text">Invalid User Name.</div>
            </div>
            <div class="form-group" [hidden]="isImplicitImpersonator" [class.has-danger]="shouldShowErrors('impersonationCode') || getControl('impersonationCode').errors?.invalidCode">
              <label for="impersonationCode" class="small req form-label"><strong>Impersonation Code</strong></label>
              <input name="impersonationCode" class="form-control large-input" type="text" formControlName="impersonationCode" maxlength="20">
              <div *ngIf="getControl('impersonationCode').errors?.invalidCode" class="invalid-text">Invalid Code.</div>
            </div>
          </form>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" *ngIf="!isImpersonating" (click)="onFormSubmit()" [disabled]="isFormSubmitted"> Impersonate </button>
            <button type="button" class="btn btn-primary" *ngIf="isImpersonating" (click)="endImpersonating()">End Impersonate </button>
            <button type="button" class="btn btn-secondary" (click)="hideModal('impersonatingModal')"> Cancel </button>
          </div>
        </div>
        <div *ngIf="isImplicitImpersonator" [ngStyle]="{'display': showImpersonateTab ? 'none' : 'block'}">
          <form *ngIf="resetForm" [formGroup]="resetForm" autocomplete="off">
            <div class="form-group" [class.has-danger]="showErrors && getControlEmail('email').errors?.required">
              <label for="signIn" class="small req form-label"><strong>Email</strong></label>
              <input name="signIn" class="form-control large-input" type="text" formControlName="email" title="Please enter a valid Email Address" maxlength="254">
              <div *ngIf="showErrors && getControlEmail('email').errors?.emailAddress" class="invalid-text">Please enter a valid email address.</div>
              <div *ngIf="getControlEmail('email').errors?.emailNotFound" class="invalid-text">Email address not found.</div>
            </div>
          </form>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" *ngIf="!isImpersonating" (click)="sendPasswordReset()" [disabled]="isFormSubmitted"> Send Password Reset </button>
            <button type="button" class="btn btn-secondary" (click)="hideModal('impersonatingModal')"> Cancel </button>
          </div>
        </div>
    </div>
  </div>
  </div>
</div>

<div class="modal fade" id="impersonationEndModal" tabindex="-1" role="dialog" aria-labelledby="impersonationEndModalHeader" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="impersonationEndModalHeader">
          End Impersonation
        </h5>
      </div>
      <div class="modal-body">
        <div>Are you sure you want to end impersonation ?</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hideModal('impersonationEndModal')"> Cancel </button>
        <button type="button" class="btn btn-primary" (click)="endImpersonating()"> End Impersonation </button>
      </div>
    </div>
  </div>
</div>