import { Component, Input } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'jjk-cobranding-logo',
    templateUrl: './cobranding-logo.component.html',
    styleUrls: ['./cobranding-logo.component.scss'],
})
export class CobrandingLogoComponent {
    testSource: any;

    private _fileLogoURL: string;
    @Input()
    set fileLogoURL(logoUrl) {
        this._fileLogoURL = logoUrl;
    }
    get fileLogoURL() { return this._fileLogoURL; }

    imgError() {
        this.fileLogoURL = null;
    }
}
