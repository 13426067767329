<div>
    <section class="filter">
        <form class="navbar-form" role="search" (keydown)="keyDownFilterSearch($event)">

            <div class="input-group">

                <!-- Main filter -->
                <input class="form-control" name="srch-term" id="srch-term" type="text" [(ngModel)]="searchTerm" [placeholder]="placeholder">

                <span class="input-group-btnx">
                    <a class="btn btn-placeholder" (click)="mainFilterdDeleteClick()">
                        <i class="fas fa-times"></i>
                    </a>
                </span>

                <a *ngIf="!addedFilterState" class="btn-sm" (click)="handleFilterState()">  <i class="fa fa-plus" aria-hidden="true"></i> Add Filter </a>

            </div>
            <p *ngIf="searchHint" class="note" [innerHtml]="searchHint"></p>

            <!-- Main Filter State - Single Search Button -->
            <div *ngIf="!addedFilterState" class="row">
                <div class="col-md-12 filter-apply">
                    <button type="button" class="btn btn-primary" (click)="ApplyAdvancedFiltersSearch()"> Search </button>
                </div>
            </div>

            <!-- Extra Filters State -->
            <div *ngIf="addedFilterState">

                <div class="filter-container" *ngFor="let filter of filters; let i = index">
                    <datagrid-filter [onInitInsertFilter]='onInitInsertFilter' [filter]="filter" [columns]="filterColumns"
                        [selectedFilters]="selectedFilters" [tableData]="tableData" (updateFilters)="updateSelectedFilters()"
                        (removeFilter)="onRemoveFilterClick(i)"></datagrid-filter>
                </div>

                <div class="addAnotherfilter">
                    <button type="button" *ngIf="filters.length < maxNumberOfFilters" class="btn-sm" (click)="addFilter()">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        Add Another Filter
                    </button>
                </div>

                <div class="row">
                    <div class="col-md-12 filter-apply">
                        <button type="button" class="btn btn-secondary btn-margin" (click)="removeAllFilters()"> Remove Filters</button>
                        <button type="button" class="btn btn-primary" (click)="ApplyAdvancedFiltersSearch()"> Apply Filters</button>
                    </div>
                </div>
            </div>

            <!-- Results Count Label -->
            <div *ngIf="showResultCount && (!prePopulatedFilters || filtersApplied)" class="row">
                <div class="col-md-12">
                    <div class="results"> {{resultsCount()}} </div>
                </div>
            </div>

        </form>
    </section>

</div>