<div class="alert alert-danger" *ngIf="form.get('fileInputName').errors && fileUpload && form.get('fileInputName').errors.invalidFileExtension">
  Only files of these types can be uploaded: <span *ngFor="let type of controlData.configOptions.acceptableFileTypes">{{ type | uppercase}} </span>
</div>
<div class="alert alert-danger" *ngIf="form.get('fileInputSize').errors && fileUpload && form.get('fileInputSize').errors.invalidFileSize">
  Only files up to {{ controlData.configOptions.maxFileSize }} MB can be uploaded.
</div>

<div *ngIf="controlData" class="form-group" [class.has-danger]="showErrors">
  <label for="exampleInputFile" class="btn btn-secondary form-label" *ngIf="controlData.displayName">
    <strong>{{ controlData.displayName }}</strong>
  </label>
  <label *ngIf="fileUpload" for="exampleInputFile" class="small filename form-label">{{ fileUpload.name }}</label>
  <input type="file" class="form-control-file hidden-xs-up" id="exampleInputFile" aria-describedby="fileHelp" (change)="onFileChange($event)" [attr.disabled]="controlData?.isDisabled ? 'true' : null" />
  <input type="hidden" name="fileHidden" [formControl]="fileInputName" #fileInputName />
  <input type="hidden" name="fileHiddenSize" [formControl]="fileInputSize" />
</div>
