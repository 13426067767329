<div class="modal fade" id="touModal" tabindex="-1" role="dialog" aria-labelledby="touModalHeader"
  data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="touModalHeader">Terms of Use</h1>
      </div>
      <div class="modal-body">
        <p><input type="checkbox" (change)="isConfirmDisabled = !isConfirmDisabled"/> I have read and agree to the J. J. Keller <a href="https://www.jjkeller.com/learn/terms-conditions" target="_blank">Terms of Use</a> and <a href="https://www.jjkeller.com/learn/notice-at-collection" target="_blank">Notice at Collection</a></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        <button type="button" class="btn" [ngClass]="'btn-primary'" (click)="onConfirm()" [disabled]="isConfirmDisabled">Accept</button>
      </div>
    </div>
  </div>
</div>
