import { Component, ContentChildren, QueryList } from '@angular/core';
import { DataGridAdvancedFilterItemComponent } from '@components/datagrid-advanced-filter-item/datagrid-advanced-filter-item.component';

@Component({
  selector: 'jjk-datagrid-advanced-search',
  template: ``,
})
export class DataGridAdvancedSearchComponent {

  constructor() {
  }

  @ContentChildren(DataGridAdvancedFilterItemComponent) childrenFilterColumnComponentList !: QueryList<DataGridAdvancedFilterItemComponent>;
}
