import { formatDate } from '@angular/common';
import { ColumnVO, SortModelItem } from 'ag-grid-community';
import { Dictionary } from 'app/modules/messaging/types/dictionary';
import { ApiMessage } from 'app/modules/messaging/types/Messages/apiMessage';
import { ApiActionsEnum } from '@constants/enums/api-actions.enum';
import { BooleanFilterTypes } from '@constants/enums/boolean-filter-types.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { Gender } from '@constants/enums/gender-enum';
import { MedicalAccommodationStatusEnum } from '@constants/enums/medical-accommodation-status.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { PhysicalStateEnum } from '@constants/enums/physical-state.enum';
import { ProductMonitoringStatusEnum } from '@constants/enums/product-monitoring-status.enum';
import { MedicalVaccinationStatusEnum } from '@constants/enums/vaccination-status-enum';
import { IncidentType } from '@constants/incidents/incident-type.enum';
import { OshaInjuryClassification } from '@constants/incidents/osha-injury-classification.enum';
import { DATAKEYS, TOPICS } from '@constants/messages.constants';
import { TaskPriorityEnum } from '@constants/task/task-priority';
import { TaskStatusEnum } from '@constants/task/task-status';
import { TaskTypeEnum } from '@constants/task/task-type';
import { environment } from '@env/environment';
import { JJKellerApiResponse } from '@models/api/JJKellerApiResponse.model';
import { LoggedInUserInfo } from '../../../environments/LoggedInUserInfo';
import { MessageBusService } from '../../modules/messaging/services/messageBusService';
import { FilterExpressionBuilder, IFilterDataOnly } from './models/Filter-Entry';
import { FilterExpression } from './models/FilterExpression';
import { GroupingCriteria } from './models/GroupingCriteria';
import { PartialCountCriteria } from './models/PartialCountCriteria';
import { SortCriteriaEntry } from './models/SortCriteriaEntry';

export class ApiFactory {
    private msg: ApiMessage = new ApiMessage();
    private msgBus: MessageBusService;

    newVersionEndpointList = [
        ApiEntityTypesEnum.ClassroomTrainingEvent,
        ApiEntityTypesEnum.TrainingRecords,
        ApiEntityTypesEnum.ClassroomProgram,
        ApiEntityTypesEnum.TrainingAsset
    ];

    /** FACTORY METHODS */
    public static saveNewEntity(entityType: ApiEntityTypesEnum, entity: any): ApiFactory {
        return new ApiFactory(ApiActionsEnum.CREATE)
            .addApiEntityType(entityType)
            .addBodyEntry(entity)
            .addApiVersion(environment.apiVersion, entityType);
    }

    // This method allows total override of the Swagger mapped endpoints.  We FROWN HEAVILY
    // on it's use, so for now it is commented out.
    // public static executeLowLevel(endPointTemplate: string, restAction: ApiActionsEnum): ApiFactory {

    //     const newEntry = new ApiFactory(restAction)
    //         .addApiEntityType(ApiEntityTypesEnum.OVERRIDE)
    //         .addApiVersion(environment.apiVersion);
    //     newEntry.msg.isRequestRaw = true;
    //     newEntry.msg.endPointUrlTemplate = endPointTemplate;
    //     return newEntry;
    // }

    public static retrieveEntity(entityType: ApiEntityTypesEnum): ApiFactory {
        return new ApiFactory(ApiActionsEnum.RETRIEVE)
            .addApiEntityType(entityType)
            .addApiVersion(environment.apiVersion, entityType);
    }

    public static updateEntity(entityType: ApiEntityTypesEnum, entity: any): ApiFactory {
        return new ApiFactory(ApiActionsEnum.UPDATE)
            .addApiEntityType(entityType)
            .addBodyEntry(entity)
            .addApiVersion(environment.apiVersion, entityType);
    }

    public static deleteEntity(entityType: ApiEntityTypesEnum): ApiFactory {
        return new ApiFactory(ApiActionsEnum.DELETE)
            .addApiEntityType(entityType)
            .addApiVersion(environment.apiVersion, entityType);
    }

    /** PRIVATE CONSTRUCTOR  */
    private constructor(apiAction: ApiActionsEnum) {
        this.msgBus = MessageBusService.AppInjector.get(MessageBusService);
        this.msg.apiAction = apiAction;
        if (this.msg.apiAction === ApiActionsEnum.CREATE || this.msg.apiAction === ApiActionsEnum.UPDATE) {
            this.addDataEntry(DATAKEYS.HTTP_KEYS.BODY, undefined);
        }

        // These two are required for nearly every msg, so add them by default.

        // It is required for Impersonation feature, so add by default.
        if (LoggedInUserInfo.Instance.userInfo.impersonationId) {
            this.addImpersonationId(LoggedInUserInfo.Instance.userInfo.impersonationId.toString());
        }
    }

    /** BUILDER METHODS */
    public addDataEntry(key: string, value: any): ApiFactory {
        this.msg.messageData[key] = value;
        return this;
    }

    /** This method will add additional pathing to the end of the Route
     * in order to help the route selector find the correct path.
     */
    public addRouteHint(routeToAppend: string): ApiFactory {
        this.msg.stringToAppendToRoute = routeToAppend;
        return this;
    }

    public addUserActivity(isUserActivity: boolean): ApiFactory {
        this.msg.isUserActivity = isUserActivity;
        return this;
    }

    public addDataEntryAsMap(data: Map<string, any> | Dictionary): ApiFactory {
        this.msg.messageData = data;
        return this;
    }

    public addBodyEntry(value: any): ApiFactory {
        this.addDataEntry(DATAKEYS.HTTP_KEYS.BODY, value);
        return this;
    }

    public addHeader(headerName: string, headerValue: string): ApiFactory {
        const adjustedHeaderValue = headerName.startsWith('X') ? headerName : `X-${headerName}`;
        this.msg.additionalHeaders.set(adjustedHeaderValue, headerValue);
        return this;
    }

    public setTimeout(timeout: string): ApiFactory {
        this.msg.additionalHeaders.set('timeout', timeout);
        return this;
    }

    public addSenderId(id: number): ApiFactory {
        this.msg.senderId = id;
        return this;
    }

    private addImpersonationId(impersonationId: string): ApiFactory {
        this.addDataEntry('impersonationId', impersonationId);
        return this;
    }

    public addEntityId(id: string): ApiFactory {
        this.addDataEntry('id', id);
        return this;
    }

    public addApiEntityType(entityType: ApiEntityTypesEnum): ApiFactory {
        this.msg.apiEntityType = entityType;
        return this;
    }

    public addApiVersion(apiVersion: number, entity: ApiEntityTypesEnum,): ApiFactory {
        // Helps determine which version to use
        if(this.newVersionEndpointList.includes(entity)){
            apiVersion = 2;
        }
        this.msg.apiVersion = apiVersion;
        this.addDataEntry(DATAKEYS.HTTP_KEYS.VERSION_ID, apiVersion);
        return this;
    }

    public addFilterEntries(filterData: IFilterDataOnly[]): ApiFactory {
        this.msg.filters = this.msg.filters.concat(filterData);
        return this;
    }

    public addFilterEntry(filterEntry: FilterExpression): ApiFactory {
        this.msg.filters.push(filterEntry);
        return this;
    }

    public addFiltersFromFilterModel(filterModel: any, entity: ApiEntityTypesEnum): ApiFactory {
        for (const prop in filterModel) {
            let filterVals = null;
            if (Object.prototype.hasOwnProperty.call(filterModel, prop)) {
                const filterOperation = filterModel[prop].type ?? filterModel[prop].filterType;
                let filterValue = filterModel[prop].filter;
                const filter = FilterExpressionBuilder.For(entity);
                let filterType = ParameterTypeEnum.String;
                let ExpressionBuilder;
                let guids = [];

                // convert boolean types
                if (entity.toString() === 'IncidentViewModel' || entity.toString() === 'Employee' || entity.toString() === 'ClassroomTrainingEvent' || entity.toString() === 'TrainingAsset' || entity.toString() === 'Event' || entity.toString() === 'Location') {
                    if(prop in BooleanFilterTypes){
                        const propValue = filterModel[prop].values.length > 0 ? filterModel[prop].values[0] : null;
                        filterVals = propValue === null ? null : (propValue === 'Yes' || propValue === 'Active' || propValue === true) ?  [true] : [false];
                        filterType = ParameterTypeEnum.Boolean;
                    }
                }

                if (entity.toString() === 'IncidentViewModel' || entity.toString() === 'Employee'){
                    if(prop == 'incidentTypeId'){
                        if(filterModel[prop].values == 'Recordable')
                        {
                            guids = [IncidentType.RecordableIncident, IncidentType.OtherRecordableCase];

                        }else{
                            guids = [IncidentType.EquipmentFailure , IncidentType.FirstAid , IncidentType.NearMiss , IncidentType.OtherNonRecordableIncident , IncidentType.PropertyDamage , IncidentType.SafetyObservation];

                        }
                        filterVals = guids;
                    }

                    if (prop == 'month') {
                        filterVals = filterModel[prop].values.map(Number);
                    }
                    if (prop == 'year') {
                        filterVals = filterModel[prop].values.map(Number);
                    }

                    if (prop == 'oshaInjuryClassificationId') {
                        if (['Hearing Loss', OshaInjuryClassification.HearingLoss].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(OshaInjuryClassification.HearingLoss);
                        }
                        if (['All Other Illnesses', OshaInjuryClassification.AllOtherIllnesses].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(OshaInjuryClassification.AllOtherIllnesses);
                        }
                        if (['Not Applicable', OshaInjuryClassification.NotApplicable].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(OshaInjuryClassification.NotApplicable);
                        }
                        if (['Skin Disorder', OshaInjuryClassification.SkinDisorder].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(OshaInjuryClassification.SkinDisorder);
                        }
                        if (['Injury', OshaInjuryClassification.Injury].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(OshaInjuryClassification.Injury);
                        }

                        if (['Poisoning', OshaInjuryClassification.Poisoning].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(OshaInjuryClassification.Poisoning);
                        }

                        if (['Respiratory Condition', OshaInjuryClassification.RespiratoryCondition].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(OshaInjuryClassification.RespiratoryCondition);
                        }
                        if (filterModel[prop].values.toString().includes('(not populated)')){
                            guids.push('(not populated)');
                        }
                        filterVals = guids;
                    }

                    if (prop == 'genderId') {
                        if (['Female', Gender.Female].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(Gender.Female);
                        }
                        if (['Male', Gender.Male].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(Gender.Male);
                        }
                        if (['Other', Gender.Other].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(Gender.Other);
                        }
                        if (['Unknown', Gender.Unknown].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(Gender.Unknown);
                        }
                        if (filterModel[prop].values.toString().includes('(not populated)')){
                            guids.push('(not populated)');
                        }
                        filterVals = guids;
                    }
                }

                if (entity.toString() === 'Chemical' && (prop == 'location' || prop == 'workArea')) {
                    ExpressionBuilder = filter.Use(prop, filterType)
                                                .Equal(filterModel[prop].additionalProperty)
                                                .Build().AsExpression;

                        this.msg.filters = this.msg.filters.concat(ExpressionBuilder);
                        continue;
                }

                if (entity.toString() === 'SafetyDataSheet' && (prop == 'locations' || prop == 'workAreas' || prop == 'identifiers')) {
                        if ((prop == 'locations' || prop == 'workAreas') && filterModel[prop].additionalProperty) {
                            ExpressionBuilder = filter.Use(prop + 'Ids', filterType)
                                                .Contains(filterModel[prop].additionalProperty)
                                                .Build().AsExpression;
                        }
                        else {
                            ExpressionBuilder = filter.Use(prop, filterType)
                            .Contains(filterModel[prop].values)
                            .Build().AsExpression;
                        }
                        this.msg.filters = this.msg.filters.concat(ExpressionBuilder);
                        continue;
                }

                if (entity.toString() === 'Task' && prop == 'associatedLinkedTo')
                {
                    ExpressionBuilder = filter.Use('associatedId', filterType)
                                                .Equal(filterVals ? filterVals : filterModel[prop].additionalProperty)
                                                .Build().AsExpression;

                    this.msg.filters = this.msg.filters.concat(ExpressionBuilder);
                    continue;
                }

                if (entity.toString() === 'ClassroomTrainingEvent' && prop === 'groupName' && filterModel[prop].filterType === 'set')
                {
                    ExpressionBuilder = filter.Use('group', filterType)
                                                .Equal(filterVals ? filterVals : filterModel[prop].additionalProperty)
                                                .Build().AsExpression;

                    this.msg.filters = this.msg.filters.concat(ExpressionBuilder);
                    continue;
                }

                if ((entity.toString() === 'Incident' || entity.toString() === 'Chemical' || entity.toString() === 'LocationGridView' || entity.toString() === 'Checklist') && prop === 'groupName' && filterModel[prop].filterType === 'set')
                {
                    ExpressionBuilder = filter.Use('groupId', filterType)
                                                .Equal(filterVals ? filterVals : filterModel[prop].additionalProperty)
                                                .Build().AsExpression;

                    this.msg.filters = this.msg.filters.concat(ExpressionBuilder);
                    continue;
                }

                if (entity.toString() === 'GlobalProductViewModel' && (prop === 'productMonitoringStatus' || prop === 'physicalState')) {
                    let property: string;
                    if (prop === 'productMonitoringStatus') {
                        property = 'productMonitoringStatusId';
                        if (['Complete', ProductMonitoringStatusEnum.Complete].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(ProductMonitoringStatusEnum.Complete);
                        }
                        if (['In Progress', ProductMonitoringStatusEnum.InProgress].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(ProductMonitoringStatusEnum.InProgress);
                        }
                        if (['Monitoring Due', ProductMonitoringStatusEnum.MonitoringDue].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(ProductMonitoringStatusEnum.MonitoringDue);
                        }
                        if (['Unable to Source', ProductMonitoringStatusEnum.UnableToSource].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(ProductMonitoringStatusEnum.UnableToSource);
                        }
                        if (['SDS Acquired', ProductMonitoringStatusEnum.SDSAcquired].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(ProductMonitoringStatusEnum.SDSAcquired);
                        }

                        if (['In Review', ProductMonitoringStatusEnum.InReview].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(ProductMonitoringStatusEnum.InReview);
                        }
                    }

                    if (prop === 'physicalState') {
                        property = 'physicalStateId';
                        if (['Aerosol', PhysicalStateEnum.Aerosol].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(PhysicalStateEnum.Aerosol);
                        }
                        if (['Gas', PhysicalStateEnum.Gas].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(PhysicalStateEnum.Gas);
                        }
                        if (['Liquid', PhysicalStateEnum.Liquid].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(PhysicalStateEnum.Liquid);
                        }
                        if (['Solid', PhysicalStateEnum.Solid].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(PhysicalStateEnum.Solid);
                        }
                    }

                    filterVals = guids;
                    ExpressionBuilder = filter.Use(property, filterType)
                                        .Equal(filterVals)
                                        .Build().AsExpression;

                    this.msg.filters = this.msg.filters.concat(ExpressionBuilder);
                    continue;
                }

                if (entity.toString() === 'Task') {

                    if (prop == 'priorityId') {
                        if (['High', TaskPriorityEnum.High].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(TaskPriorityEnum.High);
                        }
                        if (['Medium', TaskPriorityEnum.Medium].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(TaskPriorityEnum.Medium);
                        }
                        if (['Low', TaskPriorityEnum.Low].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(TaskPriorityEnum.Low);
                        }
                        if (filterModel[prop].values.toString().includes('(not populated)')){
                            guids.push('(not populated)');
                        }
                        filterVals = guids;
                    }
                    if (prop == 'typeId') {
                        if (['Task', TaskTypeEnum.Task].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(TaskTypeEnum.Task);
                        }
                        if (['Corrective Action', TaskTypeEnum.CorrectiveAction].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(TaskTypeEnum.CorrectiveAction);
                        }
                        if (['Preventive Action', TaskTypeEnum.PreventiveAction].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(TaskTypeEnum.PreventiveAction);
                        }
                        if (['Form', TaskTypeEnum.Form].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(TaskTypeEnum.Form);
                        }
                        if (filterModel[prop].values.toString().includes('(not populated)')){
                            guids.push('(not populated)');
                        }
                        filterVals = guids;
                    }

                    if (prop == 'statusId') {
                        if (['Complete', TaskStatusEnum.Complete].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(TaskStatusEnum.Complete);
                        }
                        if (['Verified', TaskStatusEnum.Verified].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(TaskStatusEnum.Verified);
                        }
                        if (['Canceled', TaskStatusEnum.Canceled].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(TaskStatusEnum.Canceled);
                        }
                        if (['To Do', TaskStatusEnum.ToDo].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(TaskStatusEnum.ToDo);
                        }
                        if (['On Hold', TaskStatusEnum.OnHold].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(TaskStatusEnum.OnHold);
                        }
                        if (['In Progress', TaskStatusEnum.InProgress].some(e => filterModel[prop].values.toString().includes(e))){
                            guids.push(TaskStatusEnum.InProgress);
                        }
                        if (filterModel[prop].values.toString().includes('(not populated)')){
                            guids.push('(not populated)');
                        }
                        filterVals = guids;
                    }
                }

                if (prop == 'accommodationStatusId') {
                    if (['Not Requested', MedicalAccommodationStatusEnum.NotRequested].some(e => filterModel[prop].values.includes(e))){
                        guids.push(MedicalAccommodationStatusEnum.NotRequested);
                    }
                    if (['Requested', MedicalAccommodationStatusEnum.Requested].some(e => filterModel[prop].values.includes(e))){
                        guids.push(MedicalAccommodationStatusEnum.Requested);
                    }
                    if (['Approved', MedicalAccommodationStatusEnum.Approved].some(e => filterModel[prop].values.includes(e))){
                        guids.push(MedicalAccommodationStatusEnum.Approved);
                    }
                    if (['Denied', MedicalAccommodationStatusEnum.Denied].some(e => filterModel[prop].values.includes(e))){
                        guids.push(MedicalAccommodationStatusEnum.Denied);
                    }
                    if (filterModel[prop].values.includes('(not populated)')){
                        guids.push('(not populated)');
                    }
                    filterVals = guids;
                }

                if (prop == 'vaccinationStatusId') {
                    if (['Vaccinated', MedicalVaccinationStatusEnum.Vaccinated].some(e => filterModel[prop].values.includes(e))){
                        guids.push(MedicalVaccinationStatusEnum.Vaccinated);
                    }
                    if (['Unvaccinated', MedicalVaccinationStatusEnum.Unvaccinated].some(e => filterModel[prop].values.includes(e))){
                        guids.push(MedicalVaccinationStatusEnum.Unvaccinated);
                    }
                    if (filterModel[prop].values.includes('(not populated)')){
                        guids.push('(not populated)');
                    }
                    filterVals = guids;
                }

                switch (filterModel[prop].filterType)
                {
                    case 'number':
                        filterType = ParameterTypeEnum.Number;
                        break;
                    case 'date':
                        filterType = ParameterTypeEnum.Date;
                        filterValue = filterModel[prop].dateFrom;
                        break;
                    case 'time':
                        filterType = ParameterTypeEnum.Time;
                        break;
                    case 'set':
                        filterType = ParameterTypeEnum.StringArray;
                        break;
                }

                if (entity.toString() === 'IncidentViewModel' && prop == 'cost') {
                    filterType = ParameterTypeEnum.NullableDecimal;
                }

                if (entity.toString() === 'Request' && prop == 'createdDate') {
                    filterType = ParameterTypeEnum.NonNullableDate;
                }

                if (entity.toString() === 'Location' && prop == 'naicsCode') {
                    filterType = ParameterTypeEnum.NullableDecimal;
                }

				if (entity.toString() === "ClassroomTrainingEvent" && filterModel[prop].filterType === "date") {
                    filterType = ParameterTypeEnum.NonNullableDate;
                }

                switch (filterOperation) {
                    case 'greaterThan': {
                        ExpressionBuilder = filter.Use(prop, filterType)
                                                .GreaterThan(filterValue)
                                                .Build().AsExpression;
                        break;
                    }
                    case 'greaterThanOrEqual': {
                        ExpressionBuilder = filter.Use(prop, filterType)
                                                .GreaterThanOrEqualTo(filterValue)
                                                .Build().AsExpression;
                        break;
                    }
                    case 'lessThan': {
                        ExpressionBuilder = filter.Use(prop, filterType)
                                                .LessThan(filterValue)
                                                .Build().AsExpression;
                        break;
                    }
                    case 'lessThanOrEqual': {
                        ExpressionBuilder = filter.Use(prop, filterType)
                                                .LessThanOrEqualTo(filterValue)
                                                .Build().AsExpression;
                        break;
                    }
                    case 'equals': {
                        ExpressionBuilder = filter.Use(prop, filterType)
                                                .Equal(filterValue)
                                                .Build().AsExpression;
                        break;
                    }
                    case 'notEqual': {
                        ExpressionBuilder = filter.Use(prop, filterType)
                                                .NotEqualTo(filterValue)
                                                .Build().AsExpression;
                        break;
                    }
                    case 'inRange': {
                        let firstValue: any;
                        let secondValue: any;
                        switch (filterType)
                        {
                            case ParameterTypeEnum.Number:
                            case ParameterTypeEnum.Time:
                            case ParameterTypeEnum.NullableDecimal:
                                firstValue = filterValue;
                                secondValue = filterModel[prop].filterTo;
                                break;
							case ParameterTypeEnum.Date:
                                firstValue = filterModel[prop].dateFrom;
                                secondValue = filterModel[prop].dateTo;
                                break;
                            case ParameterTypeEnum.NonNullableDate:
                                firstValue = filterModel[prop].dateFrom;
                                secondValue = filterModel[prop].dateTo;
                                break;
                        }
                        ExpressionBuilder = filter.Use(prop, filterType)
                                                .Between(firstValue, secondValue)
                                                .Build().AsExpression;
                        break;
                    }
                    case 'contains': {
                        ExpressionBuilder = filter.Use(prop, filterType)
                                                .Contains(filterValue)
                                                .Build().AsExpression;
                        break;
                    }
                    case 'notContains': {
                        ExpressionBuilder = filter.Use(prop, filterType)
                                                .DoesNotContain(filterValue)
                                                .Build().AsExpression;
                        break;
                    }
                    case 'startsWith': {
                        ExpressionBuilder = filter.Use(prop, filterType)
                                                .StartsWith(filterValue)
                                                .Build().AsExpression;
                        break;
                    }
                    case 'endsWith': {
                        ExpressionBuilder = filter.Use(prop, filterType)
                                                .EndsWith(filterValue)
                                                .Build().AsExpression;
                        break;
                    }
                    case 'set': {
                        ExpressionBuilder = filter.Use(prop, filterType)
                                                .Equal(filterVals ? filterVals : filterModel[prop].values)
                                                .Build().AsExpression;
                        break;
                    }
                }

                this.msg.filters = this.msg.filters.concat(ExpressionBuilder);
            }
        }
        return this;
    }

    public addSortCriteria(seqOrder: number, fieldName: string, sortDescending = true): ApiFactory {
        // verify the seq is unique
        this.msg.sortingCriteria.push(new SortCriteriaEntry(seqOrder, fieldName, sortDescending));
        return this;
    }

    public addSortCriteriaFromSortModel(sortModels: SortModelItem[]): ApiFactory {
        this.msg.sortingCriteria = this.msg.sortingCriteria.concat(sortModels.map((sortModelItem, index) =>
            new SortCriteriaEntry(index, sortModelItem.colId, sortModelItem.sort === 'desc')));
        return this;
    }

    public addGroupingCriteriaFromParams(rowGroupCols: ColumnVO[], groupKeys: string[], valueCols: ColumnVO[]): ApiFactory {
        this.msg.groupingCriteria = new GroupingCriteria(rowGroupCols.map(c => c.field), groupKeys, valueCols.map(c => { return { field: c.field, aggFunc: c.aggFunc } }));
        return this;
    }

    public addPartialCountCriteria(fieldName: string, values: string[]) {
        this.msg.partialCountCriteria = new PartialCountCriteria(fieldName, values);
        return this;
    }

    public changePageNumber(pageNumber: number): ApiFactory {
        this.msg.pageNumber = pageNumber;
        return this;
    }

    public changeItemsPerPage(itemsPerPage: number): ApiFactory {
        this.msg.itemsPerPage = itemsPerPage > 100 ? 100 : itemsPerPage;
        return this;
    }

    public removePaging(): ApiFactory {
        this.msg.itemsPerPage = 0;
        return this;
    }

    public addSuccessHandler(successHandler: (data: any) => void): ApiFactory {
        this.msg.successHandler = successHandler;
        return this;
    }

    public addErrorHandler(errorHandler: (data: JJKellerApiResponse) => void): ApiFactory {
        this.msg.errorHandler = errorHandler;
        return this;
    }

    public buildAndSend(): void {
        if (this.msg.isValid()) {
            if (this.msg.apiAction === ApiActionsEnum.CREATE || this.msg.apiAction === ApiActionsEnum.UPDATE) {
                if (this.msg.messageData[DATAKEYS.HTTP_KEYS.BODY] === undefined) {
                    throw new Error('Must specify Body');
                }
            }
        }

        this.sendMessage(this.msg);
    }

    public addReportParams(data: any): ApiFactory {
        data.reportGenerationdDateTime = formatDate(new Date(), 'MM/dd/yyyy HH:mm', 'en-US').toString();
        this.msg.reportParams = data;
        return this;
    }

    public withFullReponse(): ApiFactory {
        this.msg.shouldReturnFullResponse = true;
        return this;
    }

    public skipClientSideCache(): ApiFactory {
        this.msg.skipClientSideCache = true;
        return this;
    }

    public generateCSV(value): ApiFactory {
        this.msg.generateCSV = value;
        return this;
    }

    private sendMessage(msg: ApiMessage): void {
        msg.setTopic(TOPICS.API.API_MASTER_SERVICE);
        this.msgBus.send(msg);
    }
}
