import { OperatorEnum } from '../../../constants/enums/OperatorEnum.enum';

export class Operation {
    public logicState = 'AND';
    public operator: OperatorEnum;
    public expressionValue: number | Date | string;
    public optionalSecondValue: number | Date | string;

    constructor(
        operator: OperatorEnum,
        expressionValue: number | Date | string,
        optSecondValue: number | Date | string = undefined,
        logicState = 'AND',
    ) {
        this.expressionValue = expressionValue;
        this.optionalSecondValue = optSecondValue;
        this.operator = operator;
        this.logicState = logicState;
    }
}
