import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fileCanBePreviewed } from '@utilities/helpers';

@Component({
    selector: 'jjkp-file-view-or-download',
    templateUrl: './file-view-or-download.component.html',
    styleUrls: ['./file-view-or-download.component.scss'],
})
export class FileViewOrDownloadComponent {
    @Input() fileSize: any;
    @Input() fileType: any;
    @Output() openAsDownload = new EventEmitter<boolean>();
    @Input() isDownloading = false;
    @Input() showDownload = true;
    @Input() isDownloadable = true;

    isPreviewable(){
        return fileCanBePreviewed(this.fileType);
    }

    getFileSizeText(kbsize: number) {
        if (kbsize) {
            if (kbsize > 1023) {
                return (kbsize / 1024).toFixed(2) + 'mb';
            } else {
                return kbsize.toFixed(0).toString() + 'kb';
            }
        } else {
            return '0kb';
        }
    }
    download() {
        this.openAsDownload.emit(true);
        ($('.tooltip') as any).tooltip('hide');
    }
    open() {
        this.openAsDownload.emit(false);
        ($('.tooltip') as any).tooltip('hide');
    }
}
