
<button class="btn btn-link btn-sm no-padding bold" (click)="openWindow()">Update Vaccination Status</button> |

<modal-window [id]="modalId" [title]="title" [confirmButtonText]="'Save'" [isConfirmDisabled]="processing" (onConfirm)="onSave()" (onCancel)="closeWindow()" (onX)="closeWindow()" [autoClose]="false">
    <form *ngIf="editForm" [formGroup]="editForm">
      <h2>Vaccination</h2>

      <div class="form-group" [ngClass]="showErrors && editForm.get('result').errors?.required ? 'form-group-error' : ''">
        <label class="form-label" for="result"><strong class="req">Vaccination Status</strong></label>
        <jjk-select [options]="resultOptions" id="result" [(selection)]="selectedResult" (selectionChange)="updateFormValidation($event)"
            [ngClass]="showErrors && editForm.get('result').errors?.required ? 'field-error' : ''"></jjk-select>
      </div>

      <div class="form-group" [ngClass]="showErrors && editForm.get('date').errors?.required ? 'form-group-error' : ''">
        <label class="form-label" for="date"><strong [class.req]="requireDocs">Last Vaccination Date</strong></label>
        <jjk-date-picker name="date" formControlNameValue="date" [maxDate]="maxDate"></jjk-date-picker>
      </div>

      <sms-alert-dismiss *ngIf="showAlert"
          [messageText]="attachmentError" (dismissAlertClick)="dismissAlert()" [customStyle]="'Error'">
      </sms-alert-dismiss>

      <h2>Documentation</h2>
      <div class="form-group">
        <label class="form-label" [class.req]="requireDocs">Attach Proof of Vaccination</label>
        <jjk-file-upload *ngIf="attachmentFile && isOpen" [isValidationEnabled]="showErrors && requireDocs" [model]="attachmentFile" (fileChange)="onFileChange($event)" ></jjk-file-upload>
      </div>

    </form>
</modal-window>