import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { DataGridColumnComponent } from '@components/datagrid-column/datagrid-column.component';

@Component({
    selector: 'jjk-datagrid-column-checkbox',
    templateUrl: './datagrid-column-checkbox.component.html',
    providers: [{ provide: DataGridColumnComponent, useExisting: forwardRef(() => DataGridColumnCheckboxComponent)}],
})

export class DataGridColumnCheckboxComponent extends DataGridColumnComponent implements OnInit, AfterViewInit {
    @Input() component: any;
    @Input() row: any;
    @Input() columnName: string;
    @Input() popoverContent: string;
    @Input() showSelectAll = false;
    @Input() disableSelection: any;
    @Input() disableSelectAll: any;

    @Output() action = new EventEmitter();
    @Output() selectAllAction = new EventEmitter<boolean>();
    @Output() selectionDisabledAction = new EventEmitter();

    constructor() {
        super();
        this._columnType = 'Checkbox';
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.canSort = false;
        if (!this.showSelectAll) {
            this.displayText = '';
        }
    }

    valueChanged(component, row, columnName) {
        this.action.emit({ component, row, columnName });
    }
}
