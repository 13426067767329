import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';

export const ChemicalRoutes = {
    DashboardChemicals: { urlRoute: '', associatedApiEntity: ApiEntityTypesEnum.Chemical, breadcrumbText: 'Chemicals Dashboard', children: {} },
    ProductList: { urlRoute: 'product_list', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
    ViewProduct: { urlRoute: 'product_list/:id', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
    GridSafetyDataSheet: { urlRoute: 'sds_employee_access', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
    GridSafetyDataSheetNonAuthOld: { urlRoute: 'safety_data_sheets/:authCode', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
    GridSafetyDataSheetNonAuth: { urlRoute: 'sds_employee_access/:authCode', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
    GridBinder: { urlRoute: 'sds_binders', associatedApiEntity: ApiEntityTypesEnum.Binder, children: {} },
    DetailBinder: { urlRoute: 'sds_binders/:id', associatedApiEntity: ApiEntityTypesEnum.Binder, children: {} },
    EditUploadedBinder: { urlRoute: 'sds_binders/upload/:id', associatedApiEntity: ApiEntityTypesEnum.Binder, children: {} },
    UploadBinder: { urlRoute: 'sds_binders/upload', associatedApiEntity: ApiEntityTypesEnum.Binder, children: {} },
    ReportCharts: { urlRoute: 'chemicals_reports_charts', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
    AddProduct: { urlRoute: 'product_information/add', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
    AddProductParentSDS: { urlRoute: 'product_information/add/:parentId', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
    EditProduct: { urlRoute: 'product_information/edit/:id', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
    AddCompanyUsage: { urlRoute: 'company_usage/add/:chemicalId', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
    EditCompanyUsage: { urlRoute: 'company_usage/edit/:companyUsageId', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
    AddSafetyDataSheet: { urlRoute: 'safety_data_sheets/add/:chemicalId', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
    EditSafetyDataSheet: { urlRoute: 'safety_data_sheets/edit/:sdsId', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
    ChemicalSettings: { urlRoute: 'sds_settings/:redirectToUrl', associatedApiEntity: ApiEntityTypesEnum.ChemicalSettings, children: {} },
    SafetyDataSearch: { urlRoute: 'sds_search', associatedApiEntity: ApiEntityTypesEnum.Chemical, children: {} },
};
