import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';

/*
This separate break out is used to build a complete route list at the app level while
maintaining Lazy loading of modules.  We need this information to restore a deep linking
request.
*/
export const TrainingRoutes = {
  DashboardTraining: { urlRoute: '', breadcrumbText: 'Training Dashboard', children: {} },
  TrainingAssetLibrary: { urlRoute: 'training_asset_library', associatedApiEntity: ApiEntityTypesEnum.TrainingAsset, breadcrumbText: 'Training / Asset Library', children: {} },
  AddTrainingAsset: { urlRoute: 'training_asset_library/add', associatedApiEntity: ApiEntityTypesEnum.TrainingAsset, breadcrumbText: 'Training / Asset Library / Add', children: {} },
  EditTrainingAsset: { urlRoute: 'training_asset_library/edit/:id', associatedApiEntity: ApiEntityTypesEnum.TrainingAsset, breadcrumbText: 'Training / Asset Library / Edit', children: {} },
  CloneTrainingAsset: { urlRoute: 'training_asset_library/clone/:id', associatedApiEntity: ApiEntityTypesEnum.TrainingAsset, breadcrumbText: 'Training / Asset Library / Clone', children: {} },
  ViewTrainingAsset: { urlRoute: 'training_asset_library/details/:id', associatedApiEntity: ApiEntityTypesEnum.TrainingAsset, breadcrumbText: 'Training / Asset Library / view', children: {} },
  ClassroomProgram: { urlRoute: 'classroom_programs', associatedApiEntity: ApiEntityTypesEnum.ClassroomProgram, breadcrumbText: 'Training / Classroom Program', children: {} },
  AddClassroomProgram: { urlRoute: 'classroom_programs/add', associatedApiEntity: ApiEntityTypesEnum.ClassroomProgram, breadcrumbText: 'Training / Classroom Program / Add', children: {} },
  EditClassroomProgram: { urlRoute: 'classroom_programs/edit/:id', associatedApiEntity: ApiEntityTypesEnum.ClassroomProgram, breadcrumbText: 'Training / Classroom Program / Edit', children: {} },
  CloneClassroomProgram: { urlRoute: 'classroom_programs/clone/:id', associatedApiEntity: ApiEntityTypesEnum.ClassroomProgram, breadcrumbText: 'Training / Classroom Program / Clone', children: {} },
  ViewTrainingProgramAsset: { urlRoute: 'classroom_programs/details/:programId/asset/:assetId', associatedApiEntity: ApiEntityTypesEnum.ClassroomProgram, breadcrumbText: 'Training / Classroom Programs / View', children: {} },
  ClassroomProgramReorganize: { urlRoute: 'classroom_programs/customize/:id', associatedApiEntity: ApiEntityTypesEnum.ClassroomProgram, breadcrumbText: 'Training / Classroom Programs / Customize', children: {} },
  ClassroomProgramView: { urlRoute: 'classroom_programs/details/:id', associatedApiEntity: ApiEntityTypesEnum.ClassroomProgram, breadcrumbText: 'Training / Classroom Programs / View', children: {} },
  ClassroomTrainingEvents: { urlRoute: 'classroom_training_events', associatedApiEntity: ApiEntityTypesEnum.ClassroomTrainingEvent, breadcrumbText: 'Training / Classroom Training Events', children: {} },
  AddClassroomTrainingEvents: { urlRoute: 'classroom_training_events/add', associatedApiEntity: ApiEntityTypesEnum.ClassroomTrainingEvent, breadcrumbText: 'Training / Classroom Training Events / Add', children: {} },
  CloneClassroomTrainingEvents: { urlRoute: 'classroom_training_events/clone', associatedApiEntity: ApiEntityTypesEnum.ClassroomTrainingEvent, breadcrumbText: 'Training / Classroom Training Events / Clone', children: {} },
  EditClassroomTrainingEvents: { urlRoute: 'classroom_training_events/edit/:id', associatedApiEntity: ApiEntityTypesEnum.ClassroomTrainingEvent, breadcrumbText: 'Training / Classroom Training Events / Edit', children: {} },
  ClassroomTrainingEventView: { urlRoute: 'classroom_training_events/details/:id', associatedApiEntity: ApiEntityTypesEnum.ClassroomProgram, breadcrumbText: 'Training / Classroom Training Events / View', children: {} },
  ClassroomTrainingEventEditResultsStep1: { urlRoute: 'classroom_training_events/edit_results/step_1/:id', associatedApiEntity: ApiEntityTypesEnum.ClassroomTrainingEvent, breadcrumbText: 'Training / Classroom Training Events / Edit Results: Step 1', children: {} },
  ClassroomTrainingEventEditResultsStep2: { urlRoute: 'classroom_training_events/edit_results/step_2/:id', associatedApiEntity: ApiEntityTypesEnum.ClassroomTrainingEvent, breadcrumbText: 'Training / Classroom Training Events / Edit Results: Step 2', children: {} },
  ClassroomTrainingEventEditResultsSingle: { urlRoute: 'classroom_training_events/details/edit_results_single/:id', associatedApiEntity: ApiEntityTypesEnum.ClassroomTrainingEvent, breadcrumbText: 'Training / Classroom Training Events / Edit Results', children: {} },
  OnlineCourses: { urlRoute: 'online_courses', associatedApiEntity: ApiEntityTypesEnum.Course, breadcrumbText: 'Training / Online Courses', children: {} },
  CourseEnrollment: { urlRoute: 'online_courses/enroll/:id', associatedApiEntity: ApiEntityTypesEnum.Enrollment, breadcrumbText: 'Training / Online Courses / Enrollment', children: {} },
  MultiCourseEnrollment: { urlRoute: 'online_courses/enrollment', associatedApiEntity: ApiEntityTypesEnum.Enrollment, breadcrumbText: 'Training / Online Courses / Enrollment', children: {} },
  CourseEnrollmentStep2: { urlRoute: 'online_courses/enroll/2/:id', associatedApiEntity: ApiEntityTypesEnum.Enrollment, breadcrumbText: 'Training / Online Courses / Enrollment', children: {} },
  CourseUnenrollment: { urlRoute: 'online_courses/unenroll/:id', associatedApiEntity: ApiEntityTypesEnum.Enrollment, breadcrumbText: 'Training / Online Courses / Unenroll', children: {} },
  OnlineCourseView: { urlRoute: 'online_courses/details/:id', associatedApiEntity: ApiEntityTypesEnum.ClassroomProgram, breadcrumbText: 'Training / Online Courses / View', children: {} },
  EditCourseEnrollment: { urlRoute: 'online_courses/edit_enrollment/:id/:enrollId', associatedApiEntity: ApiEntityTypesEnum.Enrollment, breadcrumbText: 'Training / Online Courses / Enrollment/ Edit', children: {} },
  CourseResendNotificationsSingle: { urlRoute: 'online_courses/resend_notifications/:id', associatedApiEntity: ApiEntityTypesEnum.CourseNotification, breadcrumbText: 'Training / Online Courses / Resend Notifications', children: {} },
  CourseResendNotificationsMultiple: { urlRoute: 'online_courses/resend_notifications_multiple/:id', associatedApiEntity: ApiEntityTypesEnum.CourseNotification, breadcrumbText: 'Training / Online Courses / Resend Notifications', children: {} },
  EnrollmentOrders: { urlRoute: 'enrollment_orders', associatedApiEntity: ApiEntityTypesEnum.Enrollment, breadcrumbText: 'Enrollment Orders', children: {} },
};
