import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { agGridGrids } from '@constants/aggrid-grids';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { IAgGridMaskedValueCellParams } from '@models/ag-grid/ag-grid-masked-value-cell-params.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { LoggerService } from '@services/core/logger-service.class';

@Component({
    selector: 'ag-grid-masked-value-cell',
    templateUrl: './ag-grid-masked-value-cell.component.html',
})
export class AgGridMaskedValueCellComponent implements ICellRendererAngularComp {

    private _params : IAgGridMaskedValueCellParams;
    public get params() : IAgGridMaskedValueCellParams {
        return this._params;
    }
    public set params(p : IAgGridMaskedValueCellParams) {
        this._params = p;
        if (this.params.gridId === agGridGrids.MEDICAL_RECORDKEEPING_Id){
            const colId = this.params.column.getColId();
            if (colId === 'medicalVaccinationId') {
                this.isPopulated = this._params?.data?.medicalVaccinationId;
            } else if (colId === 'medicalTestId') {
                this.isPopulated = this._params?.data?.medicalTestId;
            }
        }
    }

    isShowingResult = false;
    result = '';
    isLoading = false;
    isPopulated = true;

    constructor() {
    }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams): boolean {
        this.params = params;
        this.isShowingResult = false;
        this.result = '';
        // As we have updated the params we return true to let AG Grid know we have handled the refresh.
        // So AG Grid will not recreate the cell renderer from scratch.
        return true;
    }

    showResult() {
        const supportedGrids = [agGridGrids.EMPLOYEE_MEDICAL_RECORDKEEPING_Id, agGridGrids.MEDICAL_RECORDKEEPING_Id];

        if (this.params.gridId && supportedGrids.includes(this.params.gridId)) {
            let isVaccination: boolean = undefined;
            let entityId: string = undefined;

            if (this.params.gridId === agGridGrids.EMPLOYEE_MEDICAL_RECORDKEEPING_Id) {
                isVaccination = this.params.data.resultType === 'Vaccination Status';
                entityId = this.params.data.id;
            } else if (this.params.gridId === agGridGrids.MEDICAL_RECORDKEEPING_Id){
                const colId = this.params.column.getColId();
                if (colId === 'medicalVaccinationId' || colId === 'medicalTestId') {
                    isVaccination = colId === 'medicalVaccinationId';
                    entityId = isVaccination ? this.params.data.medicalVaccinationId : this.params.data.medicalTestId;
                }
            }

            if (isVaccination !== undefined) {
                this.isLoading = true;
                ApiFactory.retrieveEntity(isVaccination ? ApiEntityTypesEnum.MedicalVaccination : ApiEntityTypesEnum.MedicalTest)
                .addRouteHint(isVaccination ? 'VaccinationStatus' : 'Result')
                .addEntityId(entityId)
                .addSuccessHandler((data) => {
                    this.result = data;
                    this.isLoading = false;
                    this.isShowingResult = true;
                })
                .addErrorHandler((error) => {
                    LoggerService.trace('Error getting result from masked medical result cell.', this.params);
                    LoggerService.error(error);
                    this.isLoading = false;
                })
                .skipClientSideCache()
                .buildAndSend();
            }
        }
    }

    hideResult() {
        this.result = '';
        this.isShowingResult = false;
    }
}