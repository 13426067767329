import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'jjk-popover',
    templateUrl: './popover.component.html',
})
export class PopoverComponent implements AfterViewInit {
    @Input() public content = '';
    @Input() public title = '';
    @Input() public placement = 'right';
    @Input() public isDarkBlueIcon = false;

    constructor() {
    }

    @ViewChild('popover', { static: true }) popover: ElementRef;

    ngAfterViewInit(): void {

        this.popover.nativeElement.dataset.content = this.content;

        if (this.title) {
            this.popover.nativeElement.dataset.originalTitle = this.title;
        }
    }

}
