import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';

/*
This separate break out is used to build a complete route list at the app level while
maintaining Lazy loading of modules.  We need this information to restore a deep linking
request.
*/
export const AuditsRoutes = {
  DashboardAudits: { urlRoute: '', breadcrumbText: 'Audits Dashboard', children: {} },
  QuestionLibrary: { urlRoute: 'question_library', associatedApiEntity: ApiEntityTypesEnum.AuditsQuestionLibrary, breadcrumbText: 'Audits / Question Library', children: {} },
  AddQuestion: { urlRoute: 'question_library/add', associatedApiEntity: ApiEntityTypesEnum.Question, breadcrumbText: 'Audits / Question Library / Add', children: {} },
  EditQuestion: { urlRoute: 'question_library/edit/:id', associatedApiEntity: ApiEntityTypesEnum.Question, breadcrumbText: 'Audits / Question Library / Edit', children: {} },
  CloneQuestion: { urlRoute: 'question_library/clone', associatedApiEntity: ApiEntityTypesEnum.Question, breadcrumbText: 'Audits / Question Library / Clone', children: {} },
  ViewQuestion: { urlRoute: 'question_library/details/:id', associatedApiEntity: ApiEntityTypesEnum.Question, breadcrumbText: 'Audits / Question Library / View', children: {} },
  Checklists: { urlRoute: 'checklists', associatedApiEntity: ApiEntityTypesEnum.Checklist, breadcrumbText: 'Audits / Checklists', children: {} },
  EditChecklist: { urlRoute: 'checklist/edit/:id', associatedApiEntity: ApiEntityTypesEnum.Checklist, breadcrumbText: 'Audits / Checklists / Edit', children: {} },
  CloneChecklist: { urlRoute: 'checklist/clone', associatedApiEntity: ApiEntityTypesEnum.Checklist, breadcrumbText: 'Audits / Checklists / Clone', children: {} },
  AddChecklist: { urlRoute: 'checklist/add', associatedApiEntity: ApiEntityTypesEnum.Checklist, breadcrumbText: 'Audits / Checklists / Add', children: {} },
  ViewChecklists: { urlRoute: 'checklists/details/:id', associatedApiEntity: ApiEntityTypesEnum.AuditsChecklists, breadcrumbText: 'Audits / Checklists / View', children: {} },
  ChecklistReorganize: { urlRoute: 'checklists/customize/:id', associatedApiEntity: ApiEntityTypesEnum.Checklist, breadcrumbText: 'Audits / Checklists / Customize', children: {} },
  ChecklistViewQuestion: { urlRoute: 'checklists/question/:checklistSectionId/:id', associatedApiEntity: ApiEntityTypesEnum.Checklist, breadcrumbText: 'Audits / Checklists / Questions', children: {} },
  Events: { urlRoute: 'audit_events', associatedApiEntity: ApiEntityTypesEnum.Checklist, breadcrumbText: 'Audits / Events', children: {} },
  ViewEvents: { urlRoute: 'audit_events/details/:id', associatedApiEntity: ApiEntityTypesEnum.Event, breadcrumbText: 'Audits / Events / View', children: {} },
  ViewProjectedEvents: { urlRoute: 'audit_events/details/:templateId/:seriesNumber', associatedApiEntity: ApiEntityTypesEnum.AuditScheduleTemplate, breadcrumbText: 'Audits / Events / View', children: {} },
  EditEvent: { urlRoute: 'audit_events/edit/:id', associatedApiEntity: ApiEntityTypesEnum.Event, breadcrumbText: 'Audits / Audit Events / Edit', children: {} },
  EditProjectedEvent: { urlRoute: 'audit_events/edit/:templateId/:seriesNumber', associatedApiEntity: ApiEntityTypesEnum.Event, breadcrumbText: 'Audits / Audit Events / Edit', children: {} },
  EventReview: { urlRoute: 'audit_events/review/:id', associatedApiEntity: ApiEntityTypesEnum.Event, breadcrumbText: 'Audits / Audit Events / Review', children: {} },
  EventReviewNonAuth: { urlRoute: 'audit_events/review/:id/:authCode', associatedApiEntity: ApiEntityTypesEnum.Event, children: {} },
  CloneEvent: { urlRoute: 'audit_events/clone', associatedApiEntity: ApiEntityTypesEnum.Event, breadcrumbText: 'Audits / Audit Events / Clone', children: {} },
  CloneOneTimeEvent: { urlRoute: 'audit_events/clone/:id', associatedApiEntity: ApiEntityTypesEnum.Event, breadcrumbText: 'Audits / Audit Events / Clone', children: {} },
  CloneProjectedEvent: { urlRoute: 'audit_events/clone/:templateId/:seriesNumber', associatedApiEntity: ApiEntityTypesEnum.Event, breadcrumbText: 'Audits / Audit Events / Clone', children: {} },
  AddEvent: { urlRoute: 'audit_events/add', associatedApiEntity: ApiEntityTypesEnum.Event, breadcrumbText: 'Audits / Audit Events / Add', children: {} },
  EventReviewComplete: { urlRoute: 'audit_events/review-complete/:eventId', associatedApiEntity: ApiEntityTypesEnum.Event, breadcrumbText: 'Audits / Audit Events / Review', children: {} },
  EventReviewCompleteNonAuth: { urlRoute: 'audit_events/review-complete/:eventId/:authCode', associatedApiEntity: ApiEntityTypesEnum.Event, children: {} },
  EventReviewCancelledNonAuth: { urlRoute: 'audit_events/review-cancelled/:eventId/:authCode', associatedApiEntity: ApiEntityTypesEnum.Event, children: {} },
  EditEventResponse: { urlRoute: 'audit_events/details/:eventId/questions/edit/:id', associatedApiEntity: ApiEntityTypesEnum.Event, breadcrumbText: 'Audits / Audit Events / Edit', children: {} },
  SelfAssignmentChecklists: { urlRoute: 'selfassignment_checklists', associatedApiEntity: ApiEntityTypesEnum.Checklist, breadcrumbText: 'Audits / SelfAssignment_Checklists', children: {} },
  SelfAssignmentChecklistsNonAuth: { urlRoute: 'selfassignment_checklists/:authCode', associatedApiEntity: ApiEntityTypesEnum.Checklist, breadcrumbText: 'Audits / SelfAssignment_Checklists', children: {} },
  SelfAssignmentReview: { urlRoute: 'selfassignment_checklists/review/:id', associatedApiEntity: ApiEntityTypesEnum.Checklist, breadcrumbText: 'Audits / CheckLists / Review', children: {} },
  SelfAssignmentReviewNonAuth: { urlRoute: 'selfassignment_checklists/review/:id/:authCode', associatedApiEntity: ApiEntityTypesEnum.Checklist, children: {} },
  SelfAssignmentReviewComplete: { urlRoute: 'selfassignment_checklists/review-complete/:eventId', associatedApiEntity: ApiEntityTypesEnum.Checklist, breadcrumbText: 'Audits / CheckLists / Review', children: {} },
  SelfAssignmentReviewCompleteNonAuth: { urlRoute: 'selfassignment_checklists/review-complete/:eventId/:authCode', associatedApiEntity: ApiEntityTypesEnum.Checklist, children: {} },
  AddSchedule: { urlRoute: 'audit_schedule/add', associatedApiEntity: ApiEntityTypesEnum.Schedule, breadcrumbText: 'Audits / Audit Schedule / Add', children: {} },
  EditSchedule: { urlRoute: 'audit_schedule/edit/:id', associatedApiEntity: ApiEntityTypesEnum.Schedule, breadcrumbText: 'Audits / Audit Schedule / Edit', children: {} },
  CloneSchedule: { urlRoute: 'audit_schedule/clone/:id', associatedApiEntity: ApiEntityTypesEnum.Event, breadcrumbText: 'Audits / Audit Schedule / Clone', children: {} },
};
