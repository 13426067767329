import { ChartType } from '@constants/enums/chart-types.enum';
import { ChartsBaseModel } from '../charts-base.model';
import { RatesByLocationModel } from './rates-by-location.model';

export class IncidentRateChartModel extends ChartsBaseModel {
    type = ChartType.LINE;
    xAxisLabel = 'Months';
    yAxisLabel = 'Rate';
    title = 'Recordable Incident Rate';
    legend = true;
    legendTitle = 'Locations';
    data: RatesByLocationModel[];
    startDate: Date;
    endDate: Date;
}
