import { Injectable } from '@angular/core';
import { ApiActionsEnum } from '@constants/enums/api-actions.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { DATAKEYS } from '@constants/messages.constants';
import { environment } from '@env/environment';
import { AutoIncrementingIdentifierDirective } from '@modules/messaging/baseClasses/AutoIncrementingIdentifier';
import { ApiMessage } from '@modules/messaging/types/Messages/apiMessage';
import { ApiPrePostProcessingMiddleware } from '@services/core/api-pre-post-processing-middleware.service';
import { ApiTemplateManager } from '@services/core/api-template-manager.class';
import { ApiPostProcessHandler } from '@services/core/ApiPostProcessHandler';
import { ApiPreProcessingHandler } from '@services/core/ApiPreProcessingHandler';
import { CacheInvalidationEngine } from '@services/core/CacheInvalidationEngine.service';
import { EndPoint } from '@services/core/endPoint.model';
import { HttpClientWithCache } from '@services/core/httpClient-with-Cache.class';
import { DataControlPackage } from '@services/core/models/DataControlPackage.model';

@Injectable()
export class EndpointBroker extends AutoIncrementingIdentifierDirective {

    private apiTemplateManager: ApiTemplateManager;

    // SHORTHAND WHEN BUILDING TEMPLATES
    protected TS = ApiTemplateManager.TOKEN_START;
    protected TE = ApiTemplateManager.TOKEN_END;

    public addPreMsgProcessingHandler(handler: ApiPreProcessingHandler, friendlyName = '') {
        this.apiMiddleware.addPreMsgProcessingHandler(handler, friendlyName);
    }

    public removePreMsgProcessingHandler(handler: ApiPreProcessingHandler) {
        this.apiMiddleware.removePreMsgProcessingHandler(handler);
    }

    public addPostMsgProcessingHandler(handler: ApiPostProcessHandler, friendlyName = '') {
        this.apiMiddleware.addPostMsgProcessingHandler(handler, friendlyName);
    }

    public removePostMsgProcessingHandler(handler: ApiPostProcessHandler) {
        this.apiMiddleware.removePostMsgProcessingHandler(handler);
    }

    public async get(msg: ApiMessage) {
        let url: string;
        if (!msg.isRequestRaw) {
            msg = this.apiMiddleware.distributeToPreProcessors(msg, ApiActionsEnum.RETRIEVE);
            url = await this.endPointConstruction(msg);
            url += url.indexOf('?') === -1 ? '?' : '&';
            url += 'dcr=' + encodeURIComponent(DataControlPackage.encodeAsJSON(msg));
        } else {
            url = msg.endPointUrlTemplate;
        }
        this.http.get(url, msg, (d, a) => this.apiMiddleware.distributeToPostProcessors(d, a));
    }

    public async put(msg: ApiMessage) {
        let url: string;

        if (!msg.isRequestRaw) {
            msg = this.apiMiddleware.distributeToPreProcessors(msg, ApiActionsEnum.UPDATE);
            url = await this.endPointConstruction(msg);
        } else {
            url = msg.endPointUrlTemplate;
        }
        this.http.put(url, msg, (d, a) => this.apiMiddleware.distributeToPostProcessors(d, a));
    }

    public async post(msg: ApiMessage) {
        let url: string;

        if (!msg.isRequestRaw) {
            msg = this.apiMiddleware.distributeToPreProcessors(msg, ApiActionsEnum.CREATE);
            url = await this.endPointConstruction(msg);
        } else {
            url = msg.endPointUrlTemplate;
        }
        this.http.post(url, msg, (d, a) => this.apiMiddleware.distributeToPostProcessors(d, a));
    }

    public async delete(msg: ApiMessage) {
        let url: string;

        if (!msg.isRequestRaw) {
            msg = this.apiMiddleware.distributeToPreProcessors(msg, ApiActionsEnum.DELETE);
            url = await this.endPointConstruction(msg);
        } else {
            url = msg.endPointUrlTemplate;
        }
        this.http.delete(url, msg, (d, a) => this.apiMiddleware.distributeToPostProcessors(d, a));
    }

    // #region private helpers
    private async endPointConstruction(msg: ApiMessage): Promise<string> {
        let ourEndPoint: EndPoint;

        // if this is a LOW LEVEL EXECUTION, then skip the auto lookup
        if (msg.apiEntityType && msg.apiEntityType !== ApiEntityTypesEnum.OVERRIDE) {
            // first find the Entity Endpoints Collection
            const matchingEndpoints = await this.apiTemplateManager.getEndPointsForEntity(msg.apiEntityType);

            // Narrow the list to those matching the ApiAction
            let endPoints = matchingEndpoints.filter((ep) => ep.apiAction === msg.apiAction).sort((x, y) => x.urlTemplate.length-y.urlTemplate.length);
            if (msg.messageData.id) {
                endPoints = endPoints.filter((x) => x.urlTemplate.indexOf('{id}') > -1);
            }

            // Now determine based on the required parameters
            ourEndPoint = this.matchEndPointByParameters(endPoints, msg);
            if (ourEndPoint == null) {
                throw new Error('no Endpoints matched the message being processd, Perhaps you are missing a parameter value?');
            }
        } else {
            // This code block is executed ONLY when performing a low level endpoint execution
            const paramsFromTemplate = msg.stringToAppendToRoute.split(this.TS);
            const cleanParams = paramsFromTemplate.map((p) => p.trim().substr(0, p.indexOf(this.TE))).filter((p) => p !== '');
            ourEndPoint = EndPoint.ManualConfiguration(msg.stringToAppendToRoute, msg.apiAction, cleanParams, []);
        }

        // Now build the url from the endpoint template
        const completeUrl = environment.jjkellerPortalApiRootUrl + this.populateUrlTemplate(ourEndPoint, msg).substr(1);
        return completeUrl;
    }

    /** This method will look for each of the required
     * parameters from the endpoint in the message provided.
     * The first one to match will be returned.
     */
     private matchEndPointByParameters(endPoints: EndPoint[], msg: ApiMessage): EndPoint {
        // If we match req fields but not the entire route, we can return this.
        let potentialEndPoint: EndPoint = null;
        const partialMatch = msg.apiEntityType + '/' + msg.stringToAppendToRoute;
        endPoints = endPoints.sort((x, y) => y.reqFields.length-x.reqFields.length);
        for (let eIndex = 0; eIndex < endPoints.length; eIndex++) {
            const e: EndPoint = endPoints[eIndex];
            const reqFields: any[] = e.reqFields
                .filter((x) => x.name.indexOf('.') === -1) // Filtering deep navigation properties
                .map((x) => x.name.charAt(0).toLowerCase() + x.name.slice(1)); // c# pascal case to js camel case

            // Required control data comes in messageData but payloads come with messageData[DATAKEYS.HTTP_KEYS.BODY]
            // In the other hand enum parameters could contain Zero beign a valid value, this is why we look for the
            // explicit definition instead of the value itself
            const msgData = msg.messageData[DATAKEYS.HTTP_KEYS.BODY];
            const isValidProperty = msgData && msgData instanceof FormData
                ? (fieldName: string) => msgData.has(fieldName) || msg.messageData.hasOwnProperty(fieldName)
                : (fieldName: string) => msg.messageData.hasOwnProperty(fieldName);

            const isMatch = !reqFields.some((name) => !isValidProperty(name));
            // Did we match exactly or was there additional route text too?
            if (isMatch) {
                // if we have a hint and it matches the end, we found the match.
                if (msg.stringToAppendToRoute && (e.urlTemplate.endsWith(msg.stringToAppendToRoute) || e.urlTemplate.includes(partialMatch))) {
                    return e;
                }
                // We found an entry that ends in the entity name, but if a hint was provided, then this may not
                // be the best match, so save it, but keep looking
                if (e.urlTemplate.endsWith(msg.apiEntityType) || e.urlTemplate.endsWith(this.TE)) {
                    // If no hint was provided, then this is the best match
                    if (!msg.stringToAppendToRoute) {
                        return e;
                    } else {
                        // likely match but not exact
                        potentialEndPoint = e;
                    }
                }
            }
        }
        return potentialEndPoint;
    }

    private populateUrlTemplate(endPoint: EndPoint, msg: ApiMessage): string {
        let urlTemplate = endPoint.urlTemplate;

        // If the endpoint already has defined query values, we need to just append, otherwise setup for query params
        let optionString = urlTemplate.indexOf('?') < 0 ? '?' : '';

        endPoint.reqFields.forEach((f) => {
            const fieldName = f.name || f;
            const key = this.TS + fieldName + this.TE;
            const value = msg.messageData[fieldName];
            // is this template based or query?
            if (urlTemplate.indexOf(key) < 0) {
                if (optionString !== '?') { optionString += '&'; }
                optionString += `${fieldName}=${value}`;
            } else {
                urlTemplate = urlTemplate.replace(key, value);
            }
        });

        endPoint.optFields.forEach((f) => {
            const fieldName = f.name || f;
            const optionalValueFound = !!msg.messageData[fieldName];

            if (optionalValueFound) {
                if (optionString !== '?') { optionString += '&'; }
                optionString += `${fieldName}=${msg.messageData[fieldName]}`;
            }
        });

        if (optionString !== '?') {
            urlTemplate += optionString;
        }

        return urlTemplate;
    }
    // #endregion private helpers

    constructor(
        private http: HttpClientWithCache,
        cacheService: CacheInvalidationEngine,
        private apiMiddleware: ApiPrePostProcessingMiddleware) {
        super();
        this.apiTemplateManager = new ApiTemplateManager(cacheService);
    }
}
