import { Component, HostListener, Inject, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ACTIONS, TOPICS } from '@constants/messages.constants';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { EnableMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageTypeEnum } from '@modules/messaging/baseClasses/MessageTypeEnum';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { AuthService } from '@services/auth.service';
import { CacheInvalidationEngine } from '@services/core/CacheInvalidationEngine.service';
import { GoogleTagManagerService } from '@services/google-tag-manager.service';
import { MenuService } from '@services/menu.service';
import { SplitioService } from '@services/splitio.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends EnableMessageProcessingDirective implements OnInit, OnDestroy {
    isAuthenticated: boolean;
    userName: string;
    isCallbackPage = false;

    constructor(
        private router: Router,
        private auth: AuthService,
        private menuService: MenuService,
        private googleTagService: GoogleTagManagerService,
        private splitioService: SplitioService,
        private cacheEngine: CacheInvalidationEngine,
        mb: MessageBusService,
        private zone: NgZone,
        public authStateService: OktaAuthStateService,
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
    ) {
        super(mb);
    }

    navigationEvent$: Subscription;

    @Output()
    isAuthorized: boolean;
    isAboutToExpire: boolean;
    migrated = LoggedInUserInfo.Instance.userInfo.migrated;
    isOktaFirstLogin: boolean;
    isLogin: boolean;
    isNotMigratedJjk = false;
    userEmail: string;
    b2cOktaToggle = false;

    //** SITE UNDER MAINTENANCE FLAG **/
    smsUnderMaintenance = false;

    protected configureMessageBus(builder: IMsgProcessingReg): void {
        builder.add()
            .inBoundDataTopic(TOPICS.APPLEVEL.SYSTEM)
            .listenForMessageType(MessageTypeEnum.SystemEventMessage)
            .withAction(ACTIONS.AUTH.AUTH_LOGIN_SUCCESS)
            .thenCallFunction(() => {
                this.splitioService.getToggle(TOGGLES.OKTA_UNIFIED_LOGIN).then((value) => {
                    if (value === TREATMENT.ON) {
                        setTimeout(() => {
                            this.zone.run(async () => {
                                this.isAuthenticated = await this.oktaAuth.isAuthenticated();
                                if (this.isAuthenticated) {
                                    await this.auth.receivedAuthResponse();
                                }
                                this.isAboutToExpire = this.auth.shouldWarnPasswordExpire();
                                this.isOktaFirstLogin = this.auth.shouldShowOktaFirstLogin();
                                this.isNotMigratedJjk = this.userEmail?.toLowerCase().endsWith('@jjkeller.com') &&
                                    !LoggedInUserInfo.Instance.userInfo.oktaUID;
                                this.userEmail = LoggedInUserInfo.Instance.userInfo.user?.email;
                                this.isAuthorized = true;
                            });
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            this.zone.run(async () => {
                                this.isAboutToExpire = this.auth.shouldWarnPasswordExpire();
                                this.isOktaFirstLogin = this.auth.shouldShowOktaFirstLogin();
                                this.isNotMigratedJjk = this.userEmail.toLowerCase().endsWith('@jjkeller.com') &&
                                    !LoggedInUserInfo.Instance.userInfo.oktaUID;
                            });
                        }, 1000);
                        this.isAuthorized = true;
                        this.userEmail = LoggedInUserInfo.Instance.userInfo.user.email;
                    }
                });
            });
        builder.add()
            .inBoundDataTopic(TOPICS.APPLEVEL.SYSTEM)
            .listenForMessageType(MessageTypeEnum.SystemEventMessage)
            .withAction(ACTIONS.AUTH.AUTH_REQUEST_OKTA_SIGNUP)
            .thenCallFunction(() => {
                this.zone.run(async () => {
                    this.isAuthorized = false;
                    this.router.navigate([AuthRoutes.Signup.urlRoute], { state: { emailAddress: this.userEmail } });
                });
            });
    }

    async ngOnInit() {
        super.ngOnInit();
        if (window.location.href.startsWith("https://jjkellerportal.com/")) {
            const newUrl = window.location.href.replace("https://", "https://www.")
            window.location.href = newUrl;
        }
        if (window.location.pathname === "/login/callback" && (await this.splitioService.getToggle(TOGGLES.OKTA_UNIFIED_LOGIN)) === TREATMENT.ON) {
            this.isCallbackPage = true;
        }

        this.auth.migrationStatusEmitter.subscribe((data: boolean) => {
            this.migrated = data;
            LoggedInUserInfo.Instance.userInfo.migrated = data;
            if (this.migrated && !this.userEmail.toLowerCase().endsWith('@jjkeller.com')) {
                ($('#authMigrationInfo') as any).modal('show');
                ($('#passwordExpireModal') as any).addClass('auth-migration-form-modal-active');
            }
        });

        if ((await this.splitioService.getToggle(TOGGLES.OKTA_UNIFIED_LOGIN)) === TREATMENT.ON) {
            this.isAuthenticated = await this.oktaAuth.isAuthenticated();
            if (this.isAuthenticated) {
                await this.auth.receivedAuthResponse();
            }
        }

        this.b2cOktaToggle = await this.splitioService.getToggle(TOGGLES.FORGOTPASSWORD_B2C_CREATE_OKTA) === TREATMENT.ON

        //** Get UNDER MAINTENANCE FLAG
        if(await this.splitioService.getToggle(TOGGLES.SMS_UNDER_MAINTENANCE) == TREATMENT.ON)
        {
            this.smsUnderMaintenance = true;
            window.localStorage.setItem('sms_under_maintenance', 'on');
        }
        else
        {
            this.smsUnderMaintenance = false;
            window.localStorage.setItem('sms_under_maintenance', 'off');
        }

        this.changeLayoutIfAuthPage(this.router.url);

        this.navigationEvent$ = this.router.events.subscribe(async (event) =>
         {
            if (event instanceof NavigationEnd) {
                this.googleTagService.sendPageView(event.urlAfterRedirects);
                window.localStorage.setItem('previousUrl', event.urlAfterRedirects);
                if (event.urlAfterRedirects !== '/login/callback') {
                    this.isCallbackPage = false;
                }
            }
        });

        this.cacheEngine.extractInvalidatedEntityGraph(ApiEntityTypesEnum.Employee);
    }

    ngOnDestroy() {
        if (this.navigationEvent$) {
            this.navigationEvent$.unsubscribe();
        }

        // destroy() returns a promise, so if you want to, for example,
        // navigate to another page without loosing impressions, you
        // can do that once the promise resolves.
        this.splitioService.destroyClient();
        window.localStorage.removeItem('previousUrl');
    }

    hideSideNav() {
        if (this.menuService.isSideNavOpen ) {
            this.menuService.toggleSideNav();
        }
    }

    @HostListener('window:keyup', ['$event'])
    keyboardEvent(event: KeyboardEvent) {
        this.messageBus.messageProcessedNotifier();
    }

    @HostListener('window:click', ['$event'])
    mouseEvent(event: MouseEvent) {
        this.messageBus.messageProcessedNotifier();
    }

    changeLayoutIfAuthPage(url: string) {
        if (this.b2cOktaToggle && url?.indexOf('auth-redirect') > 0) {
            this.isLogin = true;
            return;
        }

        const fullRoutes = Object.keys(AuthRoutes).map((key) => AuthRoutes[key]);

        fullRoutes.forEach((element) => {
            if (url.includes(element.urlRoute.toLowerCase())) {
                this.isLogin = true;
                return;
            }
        });
    }
}
