import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
    selector: 'ag-grid-quick-actions',
    templateUrl: './ag-grid-quick-actions.component.html',
    styleUrls: ['./ag-grid-quick-actions.component.scss']
})
export class AGGridQuickActionsComponent implements AfterViewInit {

    loading = true;

    private _selection: string[] = [];
    private _quickActionsValues: string[];
    private _quickActions: string[];

    private _quickActionCounts: number[] = [];
    @Input()
    public get quickActionCounts(): number[] {
        return this._quickActionCounts;
    }
    public set quickActionCounts(value: number[]) {
        if (value !== undefined) {
            this._quickActionCounts = value;
            this._quickActionCounts.unshift(value.reduce((previousValue, currentValue) => previousValue + currentValue));
            this.loading = false;
        }
        else {
            this._quickActionCounts = undefined;
            this.loading = true;
        }
    }

    public get selection(): string[] {
        return this._selection;
    }
    public set selection(value: string[]) {
        this._selection = value;
        this.updateDropdownSelection();
    }

    @Input() quickActionsField: string;
    @Input() filterValues: string[];
    @Input() justifyContent: string;

    @Input()
    public get quickActionsValues(): string[] {
        return this._quickActionsValues;
    }
    public set quickActionsValues(value: string[]) {
        this._quickActionsValues = value;
    }
    
    @Input()
    public get quickActions(): string[] {
        return this._quickActions;
    }
    public set quickActions(value: string[]) {
        if (value !== this._quickActions) {
            this.loading = true;
            this._quickActions = value;
            this._quickActions.unshift('All');
        }
    }

    @Output() selectionChanged = new EventEmitter();
    @Output() resize = new EventEmitter();

    @ViewChild('ngSelect', { static: false })
    private ngSelectComponent: NgSelectComponent;

    constructor() { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.refreshSelection();
            this.onResize();    
        }, 0);
    }

    onResize() {
        const container = document.querySelector(`.quickActions`) as HTMLElement;
        const height = window.getComputedStyle(container, null).getPropertyValue('height');
        this.resize.emit(height);
    }

    update(value) {
        setTimeout(() => {
            this.onResize();    
        }, 0);
    }

    onActionClick(value: any) {
        this.updateSelection(value);
    }

    updateSelection(quickAction: any) {
        if (quickAction !== 'All') {
            if (this._selection.includes(quickAction)) {
                this._selection.splice(this._selection.findIndex(q => q === quickAction), 1);
            }
            else {
                this._selection.push(quickAction);
            }

            if (this._selection.length === 0) {
                this._selection.push('All');
            }
            else {
                const index = this._selection.findIndex(q => q === 'All');
                if (index !== -1) {
                    this._selection.splice(index, 1);
                }
                else {
                    if (this._selection.length === this.quickActions.length - 1) {
                        this._selection = ['All'];
                    }
                }
            }
        }
        else {
            this._selection = this._selection.includes('All') ? [] : ['All'];
        }

        this.emitSelection();

        this.quickActionCounts = undefined;
    }

    updateDropdownSelection() {
        if (this._selection.length === this.quickActions.length - 1 && !this._selection.includes('All')) {
            this._selection = ['All'];
        }
        else {
            if (this.selection.length > 1 && this._selection.includes('All') && this._selection[this._selection.length - 1] !== 'All') {
                this._selection.splice(this._selection.findIndex(q => q === 'All'), 1);
            }
            else {
                if (this._selection.includes('All')) {
                    this._selection = ['All'];
                }
            }
        }

        if (!this._selection.length) {
            this.ngSelectComponent.close();
        }

        this.emitSelection();
    }

    emitSelection() {
        this._selection = [...this._selection];

        if (this._selection.length === 1 && this._selection[0] === 'All') {
            this.selectionChanged.emit(this.quickActionsValues ? this.quickActionsValues : this.quickActions.filter((a , i) => i > 0));
            return;
        }

        if (this._quickActionsValues && this._quickActionsValues.join() !== this._quickActions.join()) {
            const values = this._selection.map(a => this._quickActionsValues[this._quickActions.indexOf(a) - 1]);
            this.selectionChanged.emit(values);
        }
        else {
            this.selectionChanged.emit(this._selection);
        }
    }

    resetSelection() {
        this._selection = ['All'];
        this.quickActionCounts = undefined;
    }

    refreshSelection() {
        if (this._quickActionsValues && this._quickActionsValues.join() !== this._quickActions.filter((a, i) => i > 0).join()) {
            this.quickActionCounts = undefined;
            this._selection = this.filterValues ? this.filterValues.map((a, i) => this.quickActions[this.quickActionsValues.indexOf(a) + 1]) : ['All'];
        } else if (this.filterValues === undefined) {
            this._selection.push('All');   
        } else {
            this._selection = this.filterValues;
        }

        this._selection = [...this._selection];
    }
}
