<div *ngIf="controlData" class="form-group" [class.has-danger]="showErrors">
    <label [attr.for]="controlData.name" class="small form-label" [class.req]="controlData.required" *ngIf="controlData.displayName">
      <strong>{{controlData.displayName}}</strong>
    </label>

  <jjk-tree-select
    [options]="controlData.options"
    [filter]="true"
    [attr.disabled]="controlData?.isDisabled"
    [(selectedOption)]="selectedValue"></jjk-tree-select>

    <dynamic-form-label-applies-to [controlData]="controlData"></dynamic-form-label-applies-to>
  </div>
