
export enum BooleanFilterTypes {
    isSharpsCase = 'isSharpsCase',
    isPrivacyCase = 'isPrivacyCase',
    isContractWorker = 'isContractWorker',
    isActive = 'isActive',
    isShortTerm = 'isShortTerm',
    mandatory = 'mandatory',
    isRecurring = 'isRecurring',
 }
