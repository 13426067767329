import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { environment } from '@env/environment';
import { EditorialTableIndexResult } from '@models/entity-models/editorial-asa/editorial-table-index-result.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { FilterExpressionBuilder } from '@services/core/models/Filter-Entry';
import { SetTitleService } from '@services/set-title.service';
import { SplitioService } from '@services/splitio.service';

@Component({
    selector: 'topics-dashboard',
    templateUrl: './topics-dashboard.component.html',
    styleUrls: ['./topics-dashboard.component.scss'],
})
export class TopicsDashboardComponent implements OnInit {

    title = 'Topics Dashboard';
    searchTerm = '';
    showLoader = false;
    popularTopicsUrl: string;
    industries = [];
    selectedIndustry = null;
    isLoading = true;

    constructor(private titleService: SetTitleService, private router: Router,
        private splitioService: SplitioService) {
        this.popularTopicsUrl = environment.publicjjkazurebloburl + 'populartopics/';
     }

    async ngOnInit() {
        window.scrollTo(0, 0);
        this.titleService.setTitle(this.title);
        if(await this.splitioService.getToggle(TOGGLES.AEM_IMPLEMENTATION) === TREATMENT.ON) {this.loadAEM();}
        this.getIndustries();
    }
    private loadAEM() {
        ApiFactory.retrieveEntity(ApiEntityTypesEnum.AEM)
        .addDataEntry("currentURL", this.router.url)
        .addSuccessHandler((response) => {
            const parsedPageData = JSON.parse(response.pageData);
            let aemData = {};
            if (response.userData) {
                aemData = Object.assign({}, parsedPageData, {
                    user: response.userData
                });
            } else {
                aemData = parsedPageData;
            }

            (window as any).digitalData = [];
           $("head").append(response.header);
           $("body").append(response.footer);
           (window as any).digitalData.push(aemData);
        })
        .addErrorHandler((response) => {
            console.error(response.errorData);
        })
        .removePaging()
        .buildAndSend();
    }
    async searchClick(searchTerm) {

        if (searchTerm === '') { return; }
        this.showLoader = true;
        const topics = await this.getTopics(searchTerm);

        if (topics.length && topics[0].id && topics[0].description.toLowerCase() === searchTerm.trim().toLowerCase()) {
            this.router.navigateByUrl(`/topic_index/${topics[0].id}`);
        } else {
            this.router.navigateByUrl(`/topic_index?search=${searchTerm}`);
        }
        this.showLoader = false;

    }

    async getTopics(searchValue) {
        let topics: any;
        const apiFactory = ApiFactory.retrieveEntity(ApiEntityTypesEnum.EditorialAsa);
        const search = FilterExpressionBuilder.For(ApiEntityTypesEnum.EditorialAsa)
            .Use('Search', ParameterTypeEnum.String)
            .Contains(`${searchValue}`)
            .Build().AsExpression;
        apiFactory.addFilterEntries(search);
        const searchFields = FilterExpressionBuilder.For(ApiEntityTypesEnum.EditorialAsa)
            .Use('SearchFields', ParameterTypeEnum.String)
            .Contains(`Description`)
            .Build().AsExpression;
        apiFactory.addFilterEntries(searchFields);
        const filter = FilterExpressionBuilder.For(ApiEntityTypesEnum.EditorialAsa)
            .Use('Filter', ParameterTypeEnum.String)
            .Contains(`(PartitionKey eq 'Topics' or PartitionKey eq 'Synonyms') and IsActive eq '1'`)
            .Build().AsExpression;
        apiFactory.addFilterEntries(filter);
        const select = FilterExpressionBuilder.For(ApiEntityTypesEnum.EditorialAsa)
            .Use('Select', ParameterTypeEnum.String)
            .Contains(`RowKey,IndexKey,Description,TopicID`)
            .Build().AsExpression;

        await new Promise<void>((resolve, reject) => {
            apiFactory.addFilterEntries(select)
                .addSuccessHandler((response: EditorialTableIndexResult) => {
                    topics = this.mapSearchData(response.value);
                    resolve();
                })
                .addErrorHandler((response) => {
                    reject();
                })
                .removePaging()
                .buildAndSend();
        });

        return topics;
    }

    async mapSearchData(data: any[]) {
      return data.map((element) => {
        return {
          id: element.TopicID ? element.TopicID : element.rowKey,
          description: element.description,
        };
      });
    }

    async getIndustries() {
        const apiFactory = ApiFactory.retrieveEntity(ApiEntityTypesEnum.EditorialAsa);
        const select = FilterExpressionBuilder.For(ApiEntityTypesEnum.EditorialAsa)
            .Use('Select', ParameterTypeEnum.String)
            .Contains(`RowKey,Description`)
            .Build().AsExpression;
        apiFactory.addFilterEntries(select);
        const filter = FilterExpressionBuilder.For(ApiEntityTypesEnum.EditorialAsa)
            .Use('Filter', ParameterTypeEnum.String)
            .Contains(`TaxanomyID eq '8' and IsActive eq '1'`)
            .Build().AsExpression;
        apiFactory.addFilterEntries(filter);
        const orderBy = FilterExpressionBuilder.For(ApiEntityTypesEnum.EditorialAsa)
            .Use('OrderBy', ParameterTypeEnum.String)
            .Contains(`Description`)
            .Build().AsExpression;

        await new Promise<void>((resolve, reject) => {
            apiFactory.addFilterEntries(orderBy)
                .addSuccessHandler((response: EditorialTableIndexResult) => {
                    this.industries = response.value
                                    .map((x) => {
                                        return {
                                            id: x.rowKey,
                                            displayText: x.description,
                                        };
                                    });
                    this.isLoading = false;
                    resolve();
                })
                .addErrorHandler((response) => {
                    reject();
                })
                .removePaging()
                .buildAndSend();
        });
    }

    goToTopicIndex() {
        if (this.selectedIndustry) {
            this.router.navigateByUrl(this.getTopicIndexURL(this.selectedIndustry));
        }
    }

    getTopicIndexURL(id: string) {
        return '/topic_index?filters=[{"column": "Industries", "operator": "Contains", "fieldName": "IndustryID", "filterValue": ["' + id + '"]}]';
    }
}
