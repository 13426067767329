import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TOGGLES } from '@constants/toggles';
import { environment } from '@env/environment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { AutoIncrementingIdentifierDirective } from '@modules/messaging/baseClasses/AutoIncrementingIdentifier';
import { SplitFactory } from '@splitsoftware/splitio';
import * as SplitIO from '@splitsoftware/splitio/types/splitio';

@Injectable({
    providedIn: 'root',
})
export class SplitioService extends AutoIncrementingIdentifierDirective {
    private splitio: SplitIO.ISDK;
    private splitClient: SplitIO.IClient;
    private permanentToggles = 40;

    private _isReady = new BehaviorSubject<boolean>(false);
    isReady = this._isReady.asObservable();

    constructor() {
        super();
    }

    async initSdk(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const localFeatureTogglesConfig = {};
            Object.keys(TOGGLES).forEach((t) => {
                Object.defineProperty(localFeatureTogglesConfig, TOGGLES[t], {
                    value: 'on',
                    writable: true,
                    configurable: false,
                    enumerable: true,
                });
            });

            // By default, all toggles are 'on' in localhost, to override a toggle, use the format below
            localFeatureTogglesConfig[TOGGLES.SMS_UNDER_MAINTENANCE] = 'off'; // default off locally for now
            localFeatureTogglesConfig[TOGGLES.TASK_TYPE_FORM] = 'on'; // default off locally for now

            this.splitio = SplitFactory({
                startup: {
                    // * Issue - When using JavaScript SDK in Browser, the SDK status will mostly return Not Ready when users are on a slow Network (for example 3G).
                    // * Root Cause - It's taking long time for the SDK to fetch the Splits and Segments Information data from Split Cloud due to slow network, which might cause "control" treatments.
                    // * Solution - To resolve this issue, we need to Increase the startup.readyTimeout value to ensure it covers the SDK fetching Split configuration time
                    requestTimeoutBeforeReady: 5,
                    retriesOnFailureBeforeReady: 3,
                    readyTimeout: 5,
                    eventsFirstPushWindow: 10, // 10 sec
                },
                // Running the SDK in 'off-the-grid' Mode since authorizationKey : 'localhost'
                // To bind a non 'off-the-grid' client, inject the real API Key
                core: {
                    authorizationKey: environment.splitioAuthKey,
                    key: LoggedInUserInfo.Instance.userInfo.companyId.toString().toUpperCase(), // set key to current users' company ID
                    labelsEnabled: true,
                },
                // In non-localhost mode, this map is ignored.
                features: localFeatureTogglesConfig,
                scheduler: {
                    featuresRefreshRate: 30, // 30 sec
                    segmentsRefreshRate: 60, // 60 sec
                    metricsRefreshRate: 60, // 60 sec
                    impressionsRefreshRate: 60, // 60 sec
                    eventsPushRate: 60, // 60 sec
                    eventsQueueSize: 500, // 500 items
                },
                storage: {
                    type: 'LOCALSTORAGE',
                    prefix: 'MYPREFIX',
                },
                debug: false,
            });

            this.splitClient = this.splitio.client();

            // verify if sdk is initialized
            await this.verifyReady();
            // Or you can use the Logger API which two methods, enable and disable,
            // which will have an immediate effect.
            // this.splitio.Logger.enable();
            this.splitio.Logger.disable();
            // You could also set the log level programatically after v10.4.0
            // Acceptable values are: 'DEBUG', 'INFO', 'WARN', 'ERROR', 'NONE'
            // this.splitio.Logger.setLogLevel('DEBUG');
            resolve();
        });
    }

    async getToggle(toggle: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            if (!this.splitClient) {
                await this.initSdk();
            }
            this.isReady.subscribe({
                next: (isReady) => {
                    if (isReady) {
                        resolve(this.splitClient.getTreatment(toggle));
                    }
                },
            });
        });
    }

    destroyClient(): void {
        this.splitClient.destroy().then(() => {
            this.splitClient = null;
        });
    }

    //Verify that toggles were loaded correctly
    async confirmSplitsLoadedCorrectly() {

        if (environment.splitioAuthKey === "localhost" ) { return; }

        const manager: SplitIO.IManager = this.splitio.manager();
        const splitViewsList = manager.splits();

        const splitsInLocalStorage = Object.keys(localStorage)
            .filter(function (key) {
                return key.indexOf('MYPREFIX.SPLITIO') >= 0;
            }).length;

        if (splitsInLocalStorage < this.permanentToggles || splitsInLocalStorage < splitViewsList.length) {
            this.destroyClient();
            await this.initSdk();
        }

    }

    private async verifyReady(): Promise<void> {
        return new Promise((resolve) => {
            this.splitClient.on(this.splitClient.Event.SDK_READY, () => {
                this._isReady.next(true);
                resolve();
            });
        });

    }
}
