<div *ngIf="rules">
    <div *ngIf="header && loaderTop" class="flex-container">
        <div class="flex-child">
            <h1 *ngIf="header">{{header}}</h1>
        </div>
        <div class="flex-child">
            <jjk-loader *ngIf="loaderTopActive"></jjk-loader>
        </div>
    </div>
    <h1 *ngIf="header && !loaderTop">{{header}}</h1>
    <div id="paragraph" *ngIf="paragraph"><strong>{{paragraph}}</strong></div>
    <ul *ngFor="let rule of rules">
        <li *ngIf="!showValidation">{{rule.displayText}}</li>
        <li *ngIf="showValidation" [class.error-text]="showErrorText && validationErrors && validationErrors[rule.errorName]">{{rule.displayText}}
             <i *ngIf="rule.errorName" class="fa-solid fa-circle-check" [ngClass]="validationErrors && (validationErrors['required'] || validationErrors[rule.errorName]) ? 'gray' : 'green'"></i>
        </li>
    </ul>
</div>
