<body class="jjk-signup">
    <div id="pageId">
        <div class="wrapper-full">
            <div class="container-fluid">
                <div class="row">
                    <div id="leftContentDiv" class="col-lg-9">
                        <sms-alert-dismiss *ngIf="showAlert" [messageText]="alertText" (dismissAlertClick)="showAlert = false" [customStyle]="alertStyle"></sms-alert-dismiss>
                        <h1>Sign Up</h1>
                        <p *ngIf="showSignUp">To access J. J. Keller’s Safety Management Suite, enter your email address.</p>
                        <div *ngIf="(!isUnifiedLogin && isNotRegistered) || (isUnifiedLogin && isEnterPassword)" class="isOkta info">
                            <p *ngIf="!isJjkellerAccount">{{associatedWithOtherProducts}} Proceed to {{isUnifiedLogin ? 'sign up for' : 'log into'}}  Safety Management Suite with the same credentials you used for {{oktaProducts}}.</p>
                            <p *ngIf="isJjkellerAccount">In order to proceed please use your Network Credentials (email & password) to {{isUnifiedLogin ? 'sign up for' : 'log into'}} Safety Management Suite.</p>
                        </div>
                        <jjkp-password-rules *ngIf="showSignUp && isCreatePassword" [rules]="rules" [showValidation]="true"
                            [showErrorText]="showRuleErrorText" (rulesAreLoaded)="rulesAreLoaded = true"
                            [validationErrors]="newPasswordValidationErrors"></jjkp-password-rules>
                        <div id="api" data-name="Unified">
                            <form [formGroup]="form" *ngIf="form" aria-label="Sign up with your email address">
                                <div *ngIf="signUpError" id="alertWrapper">
                                    <sms-alert-dismiss [messageText]="signUpError" (dismissAlertClick)="signUpError = false" [customStyle]="'Error'"></sms-alert-dismiss>
                                </div>
                                <div class="entry" *ngIf="showSignUp">
                                    <div class="entry-item">
                                        <label for="signInName" class="req form-label">
                                            Email Address
                                        </label>
                                        <div class="flex-div">
                                            <input (input)="emailTextChange($event.target.value)" appAutofocus type="text" id="email"
                                                formControlName="email" [class.field-error]="this.showErrors && hasErrors('email')"
                                                name="Email Address" maxlength="254" aria-label="Email Address">
                                                <a *ngIf="(isOkta && !isJjkellerAccount && !isUnifiedLogin) || (isUnifiedLogin && isUserAlreadyRegistered)" class="create-account" [routerLink]="loginUrl" [state]="{ emailAddress: userEmail, addToSms: isNotRegistered, inviteToken: inviteToken, materialCode: materialCode, accountNumber: accountNumber, promoCode: promoCode, targetUrl: targetUrl }"><span class="login-arrow"></span>Log in {{isNotRegistered ? 'to create account' : ''}}</a>
                                                <a *ngIf="isJjkellerAccount && !isUnifiedLogin" class="create-account" [routerLink]="loginUrl" [state]="{ emailAddress: userEmail, addToSms: isNotRegistered, inviteToken: inviteToken, materialCode: materialCode, accountNumber: accountNumber, promoCode: promoCode, targetUrl: targetUrl }"><span class="login-arrow"></span>Log in with network credentials {{isNotRegistered ? 'to create account' : ''}}</a>
                                        </div>
                                        <div *ngIf="(isOkta && !isJjkellerAccount && !isUnifiedLogin) || (isUnifiedLogin && isUserAlreadyRegistered)" class="flex-div">
                                            <span class="vector-badge"></span>
                                            <p>Log into <strong><i>Safety Management Suite</i></strong> with the email you've provided</p>
                                        </div>
                                        <div *ngIf="isJjkellerAccount && !isUnifiedLogin" class="flex-div">
                                            <span class="vector-badge"></span>
                                            <p>Log into <strong><i>Safety Management Suite</i></strong> with network credentials</p>
                                        </div>
                                    </div>
                                    <div *ngIf="isUnifiedLogin && isEnterPassword">
                                        <div class="entry-item">
                                            <label for="oktaPassword" class="req form-label">Password</label>
                                            <input password formControlName="oktaPassword" id="oktaPassword"
                                                name="Password" [class.field-error]="shouldShowErrors('oktaPassword')"
                                                aria-label="Password" aria-required="true" autofocus>
                                        </div>
                                    </div>
                                    <div *ngIf="isCreatePassword">
                                        <div class="entry-item">
                                            <label for="newPassword" class="req form-label">New Password</label>
                                            <input password formControlName="newPassword" id="newPassword"
                                                name="New Password" [class.field-error]="shouldShowErrors('newPassword')"
                                                aria-label="New Password" aria-required="true" autofocus>
                                        </div>
                                        <div class="entry-item">
                                            <label for="passwordConfirm" class="req form-label">Confirm Password</label>
                                            <input password formControlName="passwordConfirm" id="passwordConfirm"
                                                name="passwordConfirm"
                                                [class.field-error]="shouldShowErrors('passwordConfirm')"
                                                aria-label="Confirm password" aria-required="true" (keyup)="updateNewPasswordValidity()">
                                        </div>
                                    </div>
                                    <div *ngIf="isCreatePassword || isEnterPassword" class="buttons">
                                        <p>By signing up for J. J. Keller Safety Management Suite, you agree to the J. J. Keller <a href="{{termsOfUseUrl}}"
                                                target="_blank">Terms of Use</a>. For information about how we use any personal information we collect for our own purposes, please review our <a href="{{noticeAtCollectionUrl}}" target="_blank">Notice at Collection</a>.</p>
                                        <save-button [innerText]="'Sign Up'" (onSave)="onFormSubmit()" [isDisabled]="isProcessingRequest"></save-button>
                                        <back-button [targetUrl]="loginUrl" [overridehistory]="true"></back-button>
                                    </div>
                                </div>
                                <div *ngIf="!isCreatePassword && !isEnterPassword" class="buttons">
                                    <save-button [innerText]="'Next'" (onSave)="next()" [isDisabled]="isProcessingRequest || isOkta || isTokenRequired || isJjkellerAccount"></save-button>
                                    <back-button [targetUrl]="loginUrl"></back-button>
                                    <jjk-loader *ngIf="isProcessingRequest"></jjk-loader>
                                    <div *ngIf="isJjkellerAccount && !isUnifiedLogin" class="flex-div">
                                        <a [routerLink]="[]" class="skip-login create-account" role="button" (click)="goToSignup()"><span class="login-arrow"></span>Skip Login, Proceed to Signup</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</body>
