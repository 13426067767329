import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { AuthService } from '@services/auth.service';
import { SplitioService } from '@services/splitio.service';

@Component({
    selector: 'jjkp-enter-email',
    styleUrls: ['./enter-email.component.scss'],
    templateUrl: './enter-email.component.html',
})
export class EnterEmailComponent extends ValidatableFormBaseDirective {

    @Input() header: string;
    @Output() successResponse = new EventEmitter<any>();
    @Output() failedResponse = new EventEmitter<string>();
    @Output() setAlert = new EventEmitter<string>();
    @Output() clearAlert = new EventEmitter();
    email: string;
    isDisabledJJKEmail = false;
    isProcessingRequest = false;
    b2c2OktaToggle = false;

    constructor(private formBuilder: UntypedFormBuilder,
                private authService: AuthService,
                private router: Router,
                private splitioService: SplitioService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.buildForm();
        this.b2c2OktaToggle = (await this.splitioService.getToggle(TOGGLES.FORGOTPASSWORD_B2C_CREATE_OKTA)) === TREATMENT.ON;
    }

    private buildForm() {
        this.form = this.formBuilder.group({
            email: [null, [Validators.email, Validators.required]]
        });
    }

    protected async submitForm(eventData?: any) {
        this.b2c2OktaToggle = (await this.splitioService.getToggle(TOGGLES.FORGOTPASSWORD_B2C_CREATE_OKTA)) === TREATMENT.ON;
        this.isProcessingRequest = true;
        this.email = this.getControl('email').value;
        if(this.email.toLowerCase().endsWith('@jjkeller.com') && this.b2c2OktaToggle) {
            this.isProcessingRequest = false;
            this.isDisabledJJKEmail = true;
            this.setAlert.emit("Safety Management Suite requires that you use your network password, the same one used to unlock your PC. If you have forgotten your network password, please contact the J. J. Keller IT Help Desk for assistance.");
        }
        else {
            await this.authService.requestResetForgottenPasswordEmail(this.email)
                .then(() => this.successResponse.emit({email: this.email}))
                .catch(errorMessage => this.failedResponse.emit(errorMessage))
                .finally(() => this.isProcessingRequest = false);
        }
    }

    changeDetection(event) {
        this.email = event.target.value;
        if(!this.email.toLowerCase().endsWith('@jjkeller.com') && this.b2c2OktaToggle) {
            this.isDisabledJJKEmail = false;
            this.clearAlert.emit();
        } 
    }

    navigateToLoginPage() {
        this.router.navigateByUrl(AuthRoutes.Login.urlRoute);
    }
}
