import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ControlSize } from '@constants/enums/control-size.enum';
import { DynamicControl } from '@models/dynamic-forms.export';

@Component({
    selector: 'dynamic-form-control',
    templateUrl: './dynamic-form-control.component.html',
    styleUrls: ['./dynamic-form-control.component.scss'],
})
export class DynamicFormControlComponent {
    _controlData: DynamicControl;
    get controlData(): DynamicControl {
        return this._controlData;
    }
    @Input() set controlData(value: DynamicControl) {
        this._controlData = value;
        this.onControlDataUpdate();
    }

    get showErrors() {
        return !this.controlData.valid && this.controlData.showErrors;
    }

    @Input() resetControl: Observable<void>;
    controlSize?: ControlSize;

    constructor() {
    }

    // This method could be overwritten by any derived classes
    protected onControlDataUpdate() { }

    getStyleClasses() {
        const classes = [];
        if (this.controlData.configOptions && this.controlData.configOptions.size) {
            classes.push(this.controlData.configOptions.size.toString());
        } else if (this.controlSize) {
            classes.push(this.controlSize); // Child control override
        } else {
            classes.push(ControlSize.Large); // Overall default
        }
        return classes;
    }

    getValue() {
        return this.controlData.value;
    }
}
