import { AfterViewInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CardLinkComponent } from '../card-link/card-link.component';

@Component({
    selector: 'jjk-cards-bar',
    templateUrl: './cards-bar.component.html',
})
export class CardsBarComponent implements AfterViewInit {
    @Input() title = '';
    @Input() centerText = false;

    @ContentChildren(CardLinkComponent) notificationCards !: QueryList<CardLinkComponent>;
    subscription$: Subscription;

    //#region cards
    private _cards = new BehaviorSubject<CardLinkComponent[]>([]);
    set cards(value) {
        this._cards.next(value);
    }
    get cards() {
        return this._cards.getValue();
    }
    cards$: Observable<CardLinkComponent[]> = this._cards.asObservable();
    //#endregion

    constructor() {
    }

    ngAfterViewInit() {
        setTimeout(() => this.cards = this.notificationCards.toArray());
    }

    onCardClick(card: CardLinkComponent) {
        if (card) {
            card.onClick.emit(card.title);
        }
    }
}
