import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from '@env/environment';
import { removeFromArray } from '@utilities/helpers';

@Component({
  selector: 'jjk-froala-editor',
  templateUrl: './froala-editor.component.html',
  styleUrls: ['./froala-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FroalaEditorComponent),
      multi: true,
    },
  ],
})
export class FroalaEditorComponent implements OnInit, ControlValueAccessor {
  @Input() id = 'froalaEditor';
  @Input() placeholderText = 'Enter a description';
  @Input() charCounterMax = 7000;
  @Input() isDisabled = false;
  @Input() toolbarButtonsToRemove: string[];
  @Input() height = 400;
  @Output() input = new EventEmitter();
  @Output() contentChanged = new EventEmitter<boolean>();

  onChange: (value: any) => void;
  onTouch: () => void;

  private _value: any;
  @Input()
  public get value(): any {
    return this._value;
  }
  public set value(value: any) {
    this._value = value;
  }

  public get isOverLimit(): boolean {
    return (this.value?.length ?? 0) > this.charCounterMax;
  }

  editorOptions: any;

  constructor() {
  }

  ngOnInit(): void {
    this.editorOptions = {
      key: environment.froalaEditorLicense,
      iconsTemplate: 'font_awesome_5',
      placeholderText: this.placeholderText,
      height: this.height,
      editorClass: 'froala-class',
      charCounterCount: false,
      quickInsertTags: [],
      toolbarButtons: [
        ['bold', 'italic', 'underline', 'fontSize', 'fontFamily'],
        ['paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent'],
        ['insertTable', 'insertLink', 'undo', 'redo']
      ],
      events: {
        'froalaEditor.html.set': (e, editor) => {
          editor.events.trigger('charCounter.update');
        },
        'input':() => {
          this.input.emit();
        },
        'contentChanged':() => {
         this.contentChanged.emit(true);
        }
      },
      attribution: false,
    };
    this.removeToolbarOptions();
  }

  removeToolbarOptions() {
    this.toolbarButtonsToRemove?.forEach(buttonToRemove => {
      this.editorOptions?.toolbarButtons?.forEach(toolbarArray => {
        removeFromArray(toolbarArray, buttonToRemove);
      })
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
}
