import { AfterViewInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { DragAndDropActionItemComponent } from '@components/drag-and-drop-action-item/drag-and-drop-action-item.component';

@Component({
    selector: 'jjk-drag-and-drop-grid-section-row',
    template: `<ng-content></ng-content>`,
})
export class DragAndDropGridSectionRowComponent implements AfterViewInit {

    constructor() {
    }

    @Input() title: string;
    @Input() addButton?: string;

    sectionActionItems: DragAndDropActionItemComponent[];

    @ContentChildren(DragAndDropActionItemComponent) childSectionActionItemComponentList !: QueryList<DragAndDropActionItemComponent>;

    ngAfterViewInit() {
        this.sectionActionItems = this.childSectionActionItemComponentList.toArray();
    }
}
