import { Directive, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IDatePickerEnabled } from '@models/forms/date-picker-enabled.model';
import { IValidatableForm } from '@models/forms/validatable-form.model';
import { dateFormat, isNumberKey, keyDown, keyPress } from '@utilities/helpers';

/**
 * Base implementation of core logic needed to build a form with validation
 * per JJK specs.
 */
@Directive()
export abstract class ValidatableFormBaseDirective implements IValidatableForm, IDatePickerEnabled, OnDestroy {
    private _form: UntypedFormGroup;
    get form() { return this._form; }
    set form(val) {
        this._form = val;
        this.formStatusSubscription$ = this.form.statusChanges.subscribe((status) => this.validationInProgress = status === 'PENDING');
    }

    private _validationInProgress = false;
    private get validationInProgress() { return this._validationInProgress; }
    private set validationInProgress(val: boolean) {
        if (val !== this._validationInProgress) {
            this._validationInProgress = val;
            this.validationInProgress$.next(this._validationInProgress);
        }
    }
    public validationInProgress$ = new BehaviorSubject<boolean>(false);

    private isValidFormSubmitted = true;
    private formStatusSubscription$: Subscription;
    private validationSubscription$: Subscription;

    isNumberKey = (event) => isNumberKey(event);

    /**
     * Defines logic that the derived class wants invoked
     * when a valid form is submitted.
     */
    protected abstract submitForm(eventData?: any): any | void;

    //#region DatePicker functions

    public selectedDate(value: any, formControlName: string) {
        this.form.patchValue({ [formControlName]: dateFormat(value.start) });
    }

    // make sure that maxDate and minDate options are respected when invalid dates are typed in more than once
    public updateInput(formControlName: string) {
        /*const picker = this.datePickers.find((element) => {
            return element.datePicker.element[0].name === formControlName;
        });
        if (picker) {
            if (picker.options.minDate) {
                if (moment(new Date(this.getControl(formControlName).value)) < moment(new Date(picker.options.minDate))) {
                    this.getControl(formControlName).setValue(moment(new Date(picker.options.minDate)).format('L'));
                }
            }
            if (picker.options.maxDate) {
                if (moment(new Date(this.getControl(formControlName).value)) > moment(new Date(picker.options.maxDate))) {
                    this.getControl(formControlName).setValue(moment(new Date(picker.options.maxDate)).format('L'));
                }
            }
        }*/
    }

    public keyPress(event: any) {
        keyPress(event);
    }

    public keyDown(event: any) {
        keyDown(event);
    }

    //#endregion

    constructor() {
    }

    ngOnDestroy() {
        if (this.formStatusSubscription$) { this.formStatusSubscription$.unsubscribe(); }
        if (this.validationSubscription$) { this.validationSubscription$.unsubscribe(); }
    }

    public getControl(formControlName: string): AbstractControl {
        return this.form.controls[formControlName];
    }

    public trimOnBlur(event: any) {
        if (!event.target.value.trim().length) {
            this.form.get(event.target.name).setValue(null);
        } else {
            this.form.get(event.target.name).setValue(event.target.value.trim());
        }
    }

    public hasErrorsIgnorePristine(formControlName: string): boolean {
        if (!this.form.pristine && (this.getControl(formControlName).dirty)) {
            return this.hasErrors(formControlName);
        }
        return false;
    }

    public hasErrors(formControlName: string): boolean {
        return this.getControl(formControlName).errors !== null;
    }

    public shouldShowErrors(formControlName: string): boolean {
        return this.hasErrors(formControlName) && !this.isValidFormSubmitted;
    }

    public onFormSubmit(eventData?: any) {
        if (this.form.pristine) {
            // if the form is still pristine, don't bother checking form status (which has bugs currently tied to this scenario)
            // https://github.com/angular/angular/issues/13200
            this.performFormSubmission(eventData);
        } else {
            if (this.validationInProgress) {
                this.validationSubscription$ = this.validationInProgress$
                    .pipe(
                        filter((inProgress) => !inProgress),
                    )
                    .subscribe(() => this.performFormSubmission(eventData));
            } else {
                this.performFormSubmission(eventData);
            }
        }
    }

    private performFormSubmission(eventData?: any) {
        this.isValidFormSubmitted = false;

        if (this.form.invalid) {
            // scroll to first error
            setTimeout(() => {
                const errorField = $("form input, jjk-froala-editor").filter('.ng-invalid:visible:first');
                if (errorField.length) {
                    window.scrollTo(0, errorField.offset().top - (window.innerHeight / 3));
                    errorField.trigger('focus');
                }
            }, 100);
            return;
        }

        this.isValidFormSubmitted = true;
        this.submitForm(eventData);
    }

    public resetFormSubmissionStatus() {
        this.isValidFormSubmitted = true;
    }

    public updateNewPasswordValidity() {
        this.getControl('newPassword').updateValueAndValidity();
    }
}
