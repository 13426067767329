import { Injectable } from '@angular/core';
import { ApiMasterService } from './core/api-master-service.class';

// TODO: DEPENDING ON FUTURE STATE, WE MAY DELETE THIS AND LOAD SERVICES AT THE APP.MODULE LEVEL

/**
 * The ApiManager is a host container for all API services that
 * make up the application.  Each service will in itself manage
 * the messages needed to perform it's work.
 */
@Injectable({
    providedIn: 'root',
})
export class ApiManagerService {
    constructor(
        private apiMasterService: ApiMasterService,
        // private userSettingsService: UserSettungsService
        ) {}
}
