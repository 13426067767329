import { Pipe, PipeTransform } from '@angular/core';
import { DataGridPipeManager } from '@components/datagrid/datagrid-pipemanager.service';
import { walkPathForObj } from '@utilities/helpers';

@Pipe({ name: 'gridGetField' })
export class GridGetFieldPipe implements PipeTransform {
    constructor(private dataGridPipeManager: DataGridPipeManager) {
    }

    transform(value: any, colConfig: any): any {
        const currentValue = colConfig.columnName.indexOf('.') >= 0
            ? walkPathForObj(value, colConfig.columnName)
            : value[colConfig.columnName];

        // Handles Pipes
        if (colConfig.pipe) {
            const pipeConfig = colConfig.pipe.split(':');
            const pipeName = pipeConfig[0];
            const pipeArg = pipeConfig.length > 1 ? pipeConfig[1] : '';

            // Looks for matches with the columns to bring the corresponding value, otherwise it respects the assigned value so that it can be handled later by the PIPE
            let columnPipeArg = value.hasOwnProperty(pipeArg) ? value[pipeArg] : pipeArg;

            columnPipeArg = (this.dataGridPipeManager.validateValue(columnPipeArg)) ? columnPipeArg : pipeArg;
            return this.dataGridPipeManager.applyPipe(currentValue, pipeName, columnPipeArg);
        } else {
            return this.dataGridPipeManager.applyPipe(currentValue, '', '');
        }
    }
}
