// Cant find?
// import { dateFormat } from '@utilities/helpers';
import { DynamicControl } from './dynamic-control.model';
import { DynamicValidator } from './dynamic-validator.model';

export class DynamicDateControl extends DynamicControl {
    constructor(name, displayName, value = null, dynamicValidators: DynamicValidator | DynamicValidator[] = null, configOptions = null) {
        super('date', name, displayName, value, dynamicValidators, configOptions);
        // super('date', name, displayName, dateFormat(value), dynamicValidators, configOptions);
    }
}
