import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FilterType } from '@constants/advanced-filter/filter-type.enum';
import { FilterColumn } from '@models/advanced-filter/filter-column.model';
import { FilterOperatorEnum } from '@models/advanced-filter/filter-operator.enum';
import { Filter } from '@models/advanced-filter/filter.model';
import { FilterValue } from '@models/advanced-filter/filterValue.model';
import { getFilterOperatorsByType } from '@utilities/filters-helper';
import { isNullOrUndefined, keyDown, keyPress } from '@utilities/helpers';

@Component({
    selector: 'datagrid-filter',
    templateUrl: './datagrid-filter.component.html',
    styleUrls: ['./datagrid-filter.component.scss'],
})
export class DataGridFilterComponent implements OnInit, OnDestroy {

    @Input() filter: Filter;
    @Input() columns: FilterColumn[];
    @Input() tableData: any[];
    @Input() onInitInsertFilter: false;

    @Input() selectedFilters: any[];

    @Output() removeFilter = new EventEmitter();
    @Output() updateFilters = new EventEmitter();

    daterange: any = {};
    areOptionsLoading = false;
    typeAheadInput$ = new Subject<string>();
    typeAheadSubscription$: Subscription;

    filterType = FilterType;
    private autoCompleteValues$: Subscription;

    private selectFilterGrouped = false;
    private availableColumns: any[];
    onlyavailableColumns: any[];
    filterOperators: any[];

    defaultDate = moment();

    optionsSingle: any = {
        showDropdowns: true,
        locale: { format: 'MM/DD/YYYY' },
        autoApply: true,
        singleDatePicker: true,
        minDate: moment('1900-01-01'),
    };

    optionsRange: any = {
        showDropdowns: true,
        locale: { format: 'MM/DD/YYYY' },
        autoApply: false,
        singleDatePicker: false,
        minDate: moment('1900-01-01'),
    };

    private _selectedOptions;
    get selectedOptions() {
        return this._selectedOptions;
    }
    set selectedOptions(value) {
        this._selectedOptions = value;
        this.filter.filterValue = this._selectedOptions;
    }

    private _selectedGroupedOptions;
    get selectedGroupedOptions() {
        return this._selectedGroupedOptions;
    }
    set selectedGroupedOptions(value) {
        this._selectedGroupedOptions = value;
        this.filter.filterValue = this._selectedGroupedOptions;
    }

    private _normalSelectOptions;
    get normalSelectOptions() {
        return this._normalSelectOptions;
    }
    set normalSelectOptions(value) {
        this._normalSelectOptions = value;
        this.filter.filterValue = this._normalSelectOptions;
    }

    constructor() {
    }

    ngOnInit(): void {
        this.getAvailableColumns();
        const colObj = this.filter.operator ? this.columns.find((x) => x.name === this.filter.column) : this.filter.column;
        this.updateOperators(colObj);
    }

    ngOnDestroy(): void {
        if (this.autoCompleteValues$) { this.autoCompleteValues$.unsubscribe(); }
        if (this.typeAheadSubscription$) { this.typeAheadSubscription$.unsubscribe(); }
    }

    updateValueType(operator) {
        this.filter.operator = operator;

        if (this.filter.filterType === FilterType.date || this.filter.filterType === FilterType.dateRange) {
            const dateObject: any = {};
            dateObject.startDate = this.filter.filterValue ?
                this.filter.filterValue.startDate :
                moment().startOf('day');
            dateObject.label = undefined;

            if (operator === 'Is Between') {
                this.filter.filterType = FilterType.dateRange;
                dateObject.endDate = moment().endOf('day');
            } else {
                this.filter.filterType = FilterType.date;
                dateObject.endDate = undefined;
            }

            this.selectedDate(dateObject, null);
        }
    }

    updateOperators(columnObj) {
        if (!isNullOrUndefined(columnObj)) {
            if (this.filter.column !== columnObj.name) {
                this.filter.filterValue = '';

                if (this.autoCompleteValues$) { this.autoCompleteValues$.unsubscribe(); }
            }

            this.filter.column = columnObj.name;
            this.filter.fieldName = columnObj.fieldName;
            const columnConfig = this.getFilterColumn(columnObj.name);

            if (this.filter.filterType !== columnConfig.filterType) {
                this.filter.filterType = columnConfig.filterType;
                if (this.onInitInsertFilter === false && this.filter.filterType !== FilterType.date) {
                    this.filter.filterValue = '';
                }
            }

            !isNullOrUndefined(columnConfig.grouped) && columnConfig.grouped
                ? this.selectFilterGrouped = true
                : this.selectFilterGrouped = false;

            this.filter.operators = getFilterOperatorsByType(this.filter.filterType);
            /* eslint-disable no-case-declarations */
            switch (this.filter.filterType) {
                case FilterType.date:
                    if (this.filter.operators.includes(this.filter.operator)) {
                        this.filter.operator = this.filter.operator || FilterOperatorEnum.Equals;
                    } else {
                        this.filter.operator = FilterOperatorEnum.Equals;
                    }

                    if (this.filter.operator === FilterOperatorEnum.IsBetween) {
                        this.filter.filterType = FilterType.dateRange;
                    }

                    const dateObject: any = {};
                    dateObject.startDate = !isNullOrUndefined(this.filter.filterValue) && !isNullOrUndefined(this.filter.filterValue.startDate) ? this.filter.filterValue.startDate : moment().startOf('day');
                    dateObject.endDate = !isNullOrUndefined(this.filter.filterValue) && !isNullOrUndefined(this.filter.filterValue.endDate) ? this.filter.filterValue.endDate : moment().endOf('day');
                    dateObject.label = undefined;

                    this.selectedDate(dateObject, null);
                    break;
                case FilterType.bool:
                    this.filter.operator = FilterOperatorEnum.Equals;
                    if (this.filter.filterValue === '') {
                        this.filter.filterValue = true;
                    }
                    break;
                case FilterType.autocomplete:
                case FilterType.nestedAutocomplete:
                    if (this.filter.operators.includes(this.filter.operator)) {
                        this.filter.operator = this.filter.operator || FilterOperatorEnum.Contains;
                    } else {
                        this.filter.operator = FilterOperatorEnum.Contains;
                    }
                    this.filter.filterValue = this.filter.filterValue || '';
                    this.filter.filterValues = columnConfig.dataSource;
                    break;
                case FilterType.select:
                    if (this.filter.operators.includes(this.filter.operator)) {
                        this.filter.operator = this.filter.operator || FilterOperatorEnum.Equals;
                    } else {
                        this.filter.operator = FilterOperatorEnum.Equals;
                    }

                    if (this.selectFilterGrouped) {
                        this.selectedGroupedOptions = [];
                        const selectGroupedValues = (columnConfig.dataSource as any[])
                            .map((x) => {
                                return {
                                    id: x.value,
                                    displayText: x.value,
                                    contentId: undefined,
                                    groupName: x.groupBy,
                                };
                            });

                        this.filter.filterValues = selectGroupedValues.sort(this.sortOptionsAscendingWithGroupName);
                    } else {
                        const values = this.mapToLookupValues(columnConfig.dataSource);

                        this.filter.filterValues = values;
                        this.filter.filterValue = '';
                        this.selectedGroupedOptions = undefined;
                    }
                    break;
                case FilterType.multiselect:
                    this.selectedOptions = this.filter.filterValue && this.onInitInsertFilter ? this.filter.filterValue : [];
                    const lookupValues = this.mapToLookupValues(columnConfig.dataSource);
                    this.filter.operator = this.filter.operator && this.onInitInsertFilter ? this.filter.operator : FilterOperatorEnum.Contains;
                    this.filter.filterValues = lookupValues;

                    break;
                case FilterType.multiselectAsync:
                    this.filter.filterValues = of([]);
                    this.typeAheadSubscription$ = this.typeAheadInput$.pipe(
                        debounceTime(1000),
                        distinctUntilChanged(),
                        tap((searchTerm) => this.getOptionsAsync(searchTerm, columnConfig.getOptionsFunction))).subscribe();
                    break;
                case FilterType.number:
                    if (this.filter.operators.includes(this.filter.operator)) {
                        this.filter.operator = this.filter.operator || FilterOperatorEnum.Equals;
                    } else {
                        this.filter.operator = FilterOperatorEnum.Equals;
                    }
                    this.filter.filterValues = this.filter.filterValue || '';
                    break;
                default:
                    if (this.filter.operators.includes(this.filter.operator)) {
                        this.filter.operator = this.filter.operator || FilterOperatorEnum.Contains;
                    } else {
                        this.filter.operator = FilterOperatorEnum.Contains;
                    }
                    this.filter.filterValue = this.filter.filterValue || '';
                    break;
            }
            /* eslint-enable no-case-declarations */
        }
    }

    private async getOptionsAsync(searchTerm: string, getOptionsFunction?: (searchTerm: string) => Promise<any[]>) {
        if (searchTerm) {
            this.areOptionsLoading = true;
            const values = await getOptionsFunction(searchTerm);
            this.filter.filterValues = of(this.mapToLookupValues(values));
            this.areOptionsLoading = false;
        } else {
            this.filter.filterValues = of([]);
        }
    }

    private mapToLookupValues(dataSource: string[] | FilterValue[]): any {
        return (dataSource as (string | FilterValue)[]).map((x) => {
            if (x instanceof FilterValue) {
                return {
                    id: x.value,
                    displayText: x.displayText,
                };
            } else {
                return {
                    id: x,
                    displayText: x,
                };
            }
        });
    }

    private sortOptionsAscendingWithGroupName(a, b) {
        const fullA = `${a.groupName}-${a.displayText}`;
        const fullB = `${b.groupName}-${b.displayText}`;
        return fullA.localeCompare(fullB);
    }

    getFilterColumn(columnName: string): FilterColumn {
        const i = this.columns.findIndex((c) => c.name === columnName);
        return i < 0 ? this.columns[0] : this.columns[i];
    }

    keyPress(event: any) {
        keyPress(event);
    }

    keyDown(event: any) {
        keyDown(event);
    }

    selectedDate(value: any, datepicker?: any) {
        if (moment.isMoment(value)) {
            this.filter.filterValue = value;
        }
        if (!isNullOrUndefined(value) && !isNullOrUndefined(value.startDate)) {
            // this is the date the user selected
            this.filter.filterValue = value;

            // any object can be passed to the selected event and it will be passed back here
            if (datepicker) {
                datepicker.startDate = value.startDate;
                datepicker.endDate = value.endDate;
            }
            // or manipulate your own internal property
            this.daterange.startDate = value.startDate;
            this.daterange.endDate = value.endDate;
            this.daterange.label = value.label;
        }
    }

    removeFilterClick() {
        this.updateFilters.emit('');
        this.removeFilter.emit(this.filter.column);
    }

    onSelectItemClick(event) {
        this.updateFilters.emit(event.name);
        this.getAvailableColumns();
    }

    getAvailableColumns() {
        this.availableColumns = this.columns.filter((a) => {
            return this.selectedFilters.findIndex((x) => x === a.name) === -1;
        });

        this.onlyavailableColumns = this.availableColumns.map((e) => ({ ...e }));
    }

    defaultSearch(term, item) {
        term = term.toLocaleLowerCase();
        return item.displayText.toLocaleLowerCase().indexOf(term) > -1;
    }
}
