<footer>
	<div class="container-fluid">
		<div class="row">
			<section class="footer-top col-12">
				<ul>
					<li><a href="{{aboutJJKUrl}}" target="_blank">About</a></li>
					<li><a href="{{termsOfUseUrl}}" target="_blank">Terms of Use</a></li>
					<li><a href="{{noticeAtCollectionUrl}}" target="_blank">Notice at Collection</a></li>
					<li><a href="{{supportContactUrl}}" target="_blank">Support</a></li>
				</ul>
			</section>
			<section class="col-lg-7 col-md-6 col-sm-12 footer-copy" >
				<p>J. J. Keller is the trusted source for DOT / Transportation, OSHA / Workplace Safety, Human
				Resources, Construction Safety and Hazmat / Hazardous Materials regulation compliance products and
				services. J. J. Keller helps you increase safety awareness, reduce risk, follow best practices,
				improve safety training, and stay current with changing regulations.</p>
				<a class="btn btn-primary" href="{{jjkUrl}}" target="_blank">VISIT J. J. KELLER.COM</a>
			</section>
			<section class="col-lg-5 col-md-6 col-sm-12 jjk">
				 <img id="jjkLogo" src="{{jjkHorizontalRGBLogo}}" alt="">
				<ul>
					<li>Connect With Us</li>
					<li><a href="https://www.facebook.com/JJKellerAssoc/" target="_blank" class="facebook"><i class="fab fa-facebook" aria-hidden="true"></i></a></li>
					<li><a href="https://twitter.com/JJKeller" target="_blank" class="twitter"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
					<li><a href="https://www.linkedin.com/company/j--j--keller-&-associates-inc-" target="_blank" class="linkedin"><i class="fab fa-linkedin" aria-hidden="true"></i></a></li>
					<li><a href="https://www.youtube.com/channel/UClruR_EJGlqS25_6b-2Jc7A" target="_blank" class="youtube"><i class="fab fa-youtube" aria-hidden="true"></i></a> </li>
				</ul>
				<p class="copyright">Copyright <span id="copyrightYear"></span> J. J. Keller & Associates, Inc.</p>
			</section>
		</div>
	</div>
</footer>