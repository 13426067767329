import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sms-alert-dismiss',
  templateUrl: './alert-dismiss.component.html',
  styleUrls: ['./alert-dismiss.component.scss'],
})

export class AlertDismissComponent implements OnInit, OnDestroy {

  @Input() messageText: string;
  @Input() customStyle;
  @Output() dismissAlertClick = new EventEmitter();

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  dismissAlert() {
    this.dismissAlertClick.emit();
  }
}
