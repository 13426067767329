import { Component, Input, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AlertArea, AlertType, IAlert } from '@models/alert';
import { AlertService } from '@services/alert.service';
import { isValidArray } from '@utilities/helpers';

@Component({
  selector: 'sms-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {

  public alerts: IAlert[] = [];
  newAlert$: Subscription;
  removeAlerts$: Subscription;

  _alertArea: AlertArea = AlertArea.All;

  get area(): AlertArea {
    return this._alertArea;
  }
  @Input() set area(value: AlertArea) {
    this._alertArea = value;
    if (!this._alertArea) {
      this._alertArea = AlertArea.All;
    }
  }

  @Input() dismissTimeout = 10000;

  alertClass(alertType: AlertType): string {
    return `alert-${AlertType[alertType].toLowerCase()}`;
  }

  message(alertMessage: string): SafeHtml {
    if (alertMessage) {
      return this.sanitizer.sanitize(SecurityContext.HTML, alertMessage);
    }
  }

  constructor(private sanitizer: DomSanitizer, private alertService: AlertService) { }

  ngOnInit() {
    this.newAlert$ = this.alertService.subscribe((alert) => this.showAlert(alert));
    this.removeAlerts$ = this.alertService.onRemoveAlerts$.subscribe((alertData) => this.removeAlerts(alertData));
  }

  ngOnDestroy(): void {
    if (this.newAlert$) { this.newAlert$.unsubscribe(); }
  }

  removeAlerts(alertsData: string | string[] | AlertArea) {
    // Driven by alert id
    if (typeof alertsData === 'string') {
      this.removeAlertById(alertsData);
    } else if (isValidArray(alertsData)) {
      (alertsData as string[]).forEach((id) => {
        this.removeAlertById(id);
      });
    } else {
      const alertArea = alertsData as AlertArea;
      if (alertArea === AlertArea.All) {
        this.alerts = [];
        return;
      }
      const itemsToRemove = this.alerts.filter((x) => x.area === alertArea);
      if (isValidArray(itemsToRemove)) {
        itemsToRemove.forEach((alert) => {
          this.removeAlertById(alert.id);
        });
      }
    }
  }

  private removeAlertById(alertId: string) {
    const alertIndex = this.alerts.findIndex((x) => x.id === alertId);
    if (alertIndex > -1) {
      this.alerts[alertIndex] = null; // Invalidates the value
      this.alerts.splice(alertIndex, 1); // Remove it from the array
    }
  }

  private showAlert(alert: IAlert) {
    if (isValidArray(this.alerts.filter((x) => x.area === alert.area && x.message === alert.message))) {
      return; // Duplicated alert, being displayed
    }
    // Checking listening area
    if (alert && ((this._alertArea === AlertArea.All && alert.area !== AlertArea.Modals) || this._alertArea === alert.area)) {
      this.alerts.push(alert);
      setTimeout(() => {
        this.removeAlerts(alert.id);
      }, this.dismissTimeout);
    }
  }
}
