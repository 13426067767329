<form *ngIf="form" [formGroup]="form">
  <h2>{{title}}</h2>

  <div class="form-group" [ngClass]="showErrors && form.get('hazardType').errors?.required ? 'form-group-error' : ''">
    <label for="hazardOption" class="form-label"><strong [class.req]="isAdd || isEdit">Hazard</strong></label>
    <p *ngIf="isView || isDelete" [innerHTML]="record?.hazardType"></p>
    <jjk-select *ngIf="isEdit || isAdd" [options]="hazardOptions" id="hazardOption" [(selection)]="selectedHazardType"
      required [ngClass]="showErrors && form.get('hazardType').errors?.required ? 'field-error' : ''">
    </jjk-select>
  </div>

  <div class="form-group">
    <label for="categoryOption" class="form-label"><strong>Category/Division/Type </strong></label>
    <p *ngIf="isView || isDelete" [innerHTML]="record?.hazardCategory"></p>
    <jjk-select *ngIf="isEdit || isAdd" [options]="categoryOptions" id="categoryOption" [(selection)]="selectedCategory">
    </jjk-select>
  </div>

  <div class="form-group" [ngClass]="showErrors && form.get('otherHazardText').errors?.required ? 'form-group-error' : ''">
    <label class="form-label" [class.req]="isOtherHazard && isEdit" for="otherHazardText">If Other</label>
    <p *ngIf="isView || isDelete" [innerHTML]="record?.otherHazardText"></p>
    <input *ngIf="isEdit || isAdd" type="text" [ngClass]="{ 'disabled': !isOtherHazard || isDelete, 'field-error': showErrors && form.get('otherHazardText').errors?.required}"
      formControlName="otherHazardText" maxlength="250" class="form-control" name="otherHazardText" id="otherHazardText" placeholder="If Other..." (change)="onTextChange($event)">
    <label *ngIf="isEdit || isAdd" class="form-label" for="result"> ONLY requried if 'Hazard' is 'Other'</label>
  </div>

</form>