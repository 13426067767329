import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceBase } from '@models/api-service-base.model';

@Injectable()
export class SimpleCustomDataApiService extends ApiServiceBase {
    private canDeleteSimpleCustomDataUrl = (companyId: string, id: string, customDataType: string) =>
        `SimpleCustomData/CanDelete?CompanyId=${companyId}&Id=${id}&CustomDataType=${customDataType}`

    constructor() {
        super();
    }

    canDeleteSimpleCustomData(companyId: string, id: string, customDataType: string): Observable<boolean> {
        return this.get(this.canDeleteSimpleCustomDataUrl(companyId, id, customDataType));
    }
}
