<div class="row">
    <div class="col-md-12 notifications">
            <div class="description" *ngIf="title">
                <h2 tabindex="0">{{title}}</h2>
            </div>
            <div class="card-deck">
                <ng-container *ngFor="let card of cards, index as i">
                    <ng-container *ngIf="card.routerLink">
                        <a
                        class="card flex-row align-items-center mb-4 {{card.cardClass}}"
                            [routerLink]="card.routerLink"
                            [queryParams]="card.queryParams"
                            [state]="card.state"
                            auth [authFeature]="card.authFeature"
                            *featureToggle="card.featureToggleName"
                            (click)="onCardClick(card)">
                            <div>
                                <h3 class="card-title flex-fill">{{card.title}}</h3>
                                <div>
                                    <span *ngIf="card.showSubtitle" class="card-subtitle">{{card.subtitle}}</span>
                                    <span *ngIf="card.showSubtitle" class="card-subtitleCounter">{{card.subtitleCounter}}</span>
                                </div>
                            </div>
                            <span *ngIf="!card.isLoading" class="large-text flex-fill text-end">{{card.counter}}</span>
                            <jjk-loader *ngIf="card.isLoading"></jjk-loader>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="!card.routerLink">
                        <span
                            class="card flex-row align-items-center mb-4 {{card.cardClass}}"
                            [queryParams]="card.queryParams"
                            [state]="card.state"
                            auth [authFeature]="card.authFeature"
                            *featureToggle="card.featureToggleName"
                            (click)="onCardClick(card)">

                            <h3 class="card-title flex-fill">{{card.title}}</h3>
                            <span *ngIf="!card.isLoading" class="large-text flex-fill text-end {{card.countClass}}">{{card.counter}}</span>
                            <jjk-loader *ngIf="card.isLoading"></jjk-loader>
                        </span>
                    </ng-container>
                    <div *ngIf="(i + 1) % 3 === 0" class="w-100 d-none d-sm-block d-lg-block d-xl-block d-xxl-none"></div>
                    <div *ngIf="(i + 1) % 3 === 0" class="w-100 d-none d-sm-block d-lg-block d-xl-block d-xxl-none"></div>

                </ng-container>
            </div>
    </div>
</div>