import { Directive, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { stringIsDate } from '../common/helpers';
import { SmsColumnDefinition } from '../common/models/sms-column-definition.model';
import { ISmsColumnSortedEvent } from '../sms-sortable-column/sms-column-sorted-event.model';
import { SmsSortService } from '../sms-sortable-column/sms-sort.service';

@Directive({
    selector: '[sms-sortable-table]',
})
export class SmsSortableTableDirective implements OnInit, OnChanges, OnDestroy {
    @Input() data: any[] = null;
    @Input() defaultSort: string = null;
    @Input() defaultSortDirection = 'asc';

    @Input() sortModule = '';

    private columnSortedSubscription: Subscription;
    private columnInfo: SmsColumnDefinition[];
    private arraySortKey = 'arraySortKey132400'; // Just a random key to avoid developer duplicate properties
    private startSortingOnChanges = false;

    constructor(private sortService: SmsSortService) { }

    ngOnChanges() {
        if (this.startSortingOnChanges) {
            this.columnInfo = SmsColumnDefinition.buildColumnInfo(this.data);
            this.sortService.raiseLastSortEvent(this.sortModule, { sortColumn: this.defaultSort, sortDirection: this.defaultSortDirection });
        }
    }

    ngOnDestroy() {
        if (this.columnSortedSubscription) { this.columnSortedSubscription.unsubscribe(); }
    }

    ngOnInit() {
        if (!this.data) {
            console.error('No data found for sms-sortable-table! Try binding the [data] property to a dataset.');
        } else {
            this.columnInfo = SmsColumnDefinition.buildColumnInfo(this.data);
            this.columnSortedSubscription = this.sortService.columnSorted$
                .subscribe((event: ISmsColumnSortedEvent) => {
                    const columnInfo = this.getColumnInfo(event.sortColumn);
                    if (columnInfo) {
                        this.sort(this.data, event);
                    }
                });
            this.startSortingOnChanges = true;
            // Forcing last sorting setup to execute
            this.sortService.raiseLastSortEvent(this.sortModule, { sortColumn: this.defaultSort, sortDirection: this.defaultSortDirection });
        }
    }

    private getColumnInfo(columnName: string): SmsColumnDefinition {
        return this.columnInfo.find((column) => column.value === columnName);
    }

    private sort(array: any[], event: ISmsColumnSortedEvent) {

        // Attaching new property to preserve original array order
        if (array[0][this.arraySortKey] === undefined) {
            array.forEach((value, index) => {
                value[this.arraySortKey] = index;
            });
        }

        const resultFunc = (x, y, rev) => {
            // Do actual sorting, reverse as needed
            return ((x < y) ? -1 : ((x > y) ? 1 : 0)) * (rev ? -1 : 1);
        };

        // Return the required a, b function
        const sortFunc = (field, rev, primerFn) => {
            return (a, b) => {
                // Reset a, b to the field
                let x = primerFn(pathValue(a, field));
                let y = primerFn(pathValue(b, field));

                // The comparison does not work well with null, so that is why we've to re-asign the invalid value
                if (x === null && typeof y === 'string') { x = ''; }
                if (typeof x === 'string' && y === null) { y = ''; }
                // Empty dates should be sorted as lower value
                if (x === null && y instanceof Date) { x = new Date(-8640000000000000); } // Min JS date value
                if (x instanceof Date && y === null) { y = new Date(-8640000000000000); } // Min JS date value

                // If equal items result will be defined with arraySortKey
                // This is needed to get *** same results all the time in all browsers ***
                // If the given sort column in number based we have to send the value converted for good compare
                const tempResult = (!isNaN(Number(x)) && !isNaN(Number(y)) ? resultFunc(+x, +y, rev) : resultFunc(x, y, rev));
                return tempResult !== 0
                    ? tempResult
                    : resultFunc(a[this.arraySortKey], b[this.arraySortKey], rev);
            };
        };

        // Have to handle deep paths e.g. fileType.type, fileType.size, etc
        const pathValue = (obj, path) => {
            path = path.split('.');
            const len = path.length;
            for (let i = 0; i < len; i++) {
                obj = obj[path[i]];
            }
            return obj;
        };

        const primer = (value) => {
            // Null values are handled outside
            if (value === null || value === undefined) { return null; }

            // Dates are already numbers
            if (value instanceof Date) {
                return value;
            } else if (typeof value === 'string' && stringIsDate(value)) {
                // If not an instance of date it could come as a valid date string value
                const date = moment(String(value).trim());
                if (date.isValid()) {
                    return date.toDate();
                }
            }
            // Any other type returns as string
            return String(value).toUpperCase();
        };

        array.sort(sortFunc(event.sortColumn, event.sortDirection === 'desc', primer));
    }
}
