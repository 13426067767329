import { EntityState } from "@constants/enums/entity-state";
import { AssociatedType } from "../autogenerated/associatedtype";
import { User } from "../user";

export class AttachmentInfo {
        associatedId: string;
        associatedTypeId: string;
        companyId: string;
        createdByUserId: string;
        createdDate: Date;
        description: string;
        fileName: string;
        fileSize?: number;
        fileType: string;
        fileUrl: string;
        id: string;
        impersonationCreatedByUserId?: string;
        impersonationModifiedByUserId?: string;
        isDeleted: boolean;
        modifiedByUserId?: string;
        modifiedDate?: Date;
        associatedType: AssociatedType;
        createdByUser: User;
        modifiedByUser: User;
        groupId: string;
        permissions: number;
        file: any;
        entityState: EntityState;
}