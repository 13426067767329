import { Component, Inject, OnInit } from '@angular/core';
import { FormControlStatus, UntypedFormBuilder, Validators } from '@angular/forms';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { environment } from '@env/environment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { AuthService } from '@services/auth.service';
import { LoggerService } from '@services/core/logger-service.class';
import { NavigationService } from '@services/navigation.service';
import { SplitioService } from '@services/splitio.service';
import { EmailAddressValidatorAsync } from '@utilities/validators/email-address.validator';

@Component({
  selector: 'jjkp-login-okta-widget',
  styleUrls: ['./login-okta-widget.component.scss'],
  templateUrl: './login-okta-widget.component.html'
})
export class LoginOktaWidgetComponent extends ValidatableFormBaseDirective implements OnInit {

    termsOfUseUrl = environment.termsOfUseUrl;
    noticeAtCollectionUrl = environment.noticeAtCollectionUrl;
    sms_under_maintenance = false;
    refireEmailCheck = false;
    isProcessingRequest = false;
    isValidEmail = false;
    isMigrate = false;
    emailCurrentlyProcessing = '';
    forgotPasswordRoute = `/${AuthRoutes.ForgotPassword.urlRoute}`;

    constructor(private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        protected splitioService: SplitioService,
        private navigator: NavigationService,
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {
            super();
         }

    async ngOnInit() {
        if((await this.splitioService.getToggle(TOGGLES.SMS_UNDER_MAINTENANCE)) == TREATMENT.ON)
        {
            this.sms_under_maintenance = true;
        }

        if ((LoggedInUserInfo.Instance.userInfo.accessToken && await this.oktaAuth.isAuthenticated())) {
            this.navigator.navigateTo('/dashboard');
        } else {
            this.authService.redirectLoggedInUser();
        }
        this.buildForm();
    }

    navigateToSignUpPage() {
        this.navigator.navigateTo(AuthRoutes.Signup.urlRoute, true);
    }

    protected async submitForm(eventData?: any) {
        await this.oktaAuth.signInWithRedirect( { loginHint: this.getControl('email').value });
    }

    private buildForm() {
        const emailAddress = window.history.state && window.history.state.emailAddress ? window.history.state.emailAddress : null;

        this.form = this.formBuilder.group({
            email: [emailAddress, {validators: Validators.required, asyncValidators: EmailAddressValidatorAsync()}]
        });

        this.checkEmail(this.form.get("email").status);

        this.form.get("email").statusChanges.subscribe(newStatus => {
            this.checkEmail(newStatus);
        });
    }

    private checkEmail(newStatus: FormControlStatus) {
        if (this.emailCurrentlyProcessing !== '' && this.emailCurrentlyProcessing === this.getControl('email').value) {
            this.refireEmailCheck = false;
        } else {
            this.isValidEmail = false;
            this.isMigrate = false;
            if (newStatus === 'VALID') {
                this.showSpinnerAndActivateLogin()
            }
        }
    }

    async showSpinnerAndActivateLogin() {
        if (!this.isProcessingRequest) {
            this.isProcessingRequest = true;
            const currentEmail = this.getControl('email').value;
            this.emailCurrentlyProcessing = currentEmail;
            await this.authService.checkIfOktaUser(currentEmail, true)
             .then((data) => {
                this.isProcessingRequest = false;
                this.emailCurrentlyProcessing = '';
                if (this.refireEmailCheck) {
                    this.showSpinnerAndActivateLogin()
                    this.refireEmailCheck = false;
                }
                else {
                    this.isValidEmail = data.isOktaSmsUser;
                    this.isMigrate = (!data.isOktaSmsUser && data.isSmsUser)
                }
             })
             .catch((errorMessage) => {
                LoggerService.trace("Error checking if okta user in login-okta-widget", errorMessage);
                this.emailCurrentlyProcessing = '';
                this.isProcessingRequest = false;
                this.isValidEmail = false;
                this.isMigrate = false;

                if (this.refireEmailCheck) {
                    this.showSpinnerAndActivateLogin()
                    this.refireEmailCheck = false;
                }
             });
        }
        else
        {
            this.refireEmailCheck = true;
        }
    }
}
