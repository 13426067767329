import { Component } from '@angular/core';
import { isNullOrEmpty } from '@utilities/helpers';

@Component({
  selector: 'jjkp-ag-grid-custom-list-floating-filter',
  templateUrl: './ag-grid-custom-list-floating-filter.component.html',
  styleUrls: ['./ag-grid-custom-list-floating-filter.component.scss'],
})
export class AgGridCustomListFloatingFilter {
  params: any;
  currentValue: string = null;

  agInit(params): void {
    this.params = params;
  }

  onParentModelChanged(parentModel) {
    // When the filter is empty we will receive a null message here
    const filterCount = isNullOrEmpty(this.params.filterParams.groupingItem) ? this.params.filterParams.values.length - 1 : this.params.filterParams.values.length;
    if (parentModel && parentModel?.values?.length > 0) {
      const appliedFilters = [...new Set(parentModel.values)];
      if(appliedFilters.length == filterCount)
        this.currentValue = '';
      else
        this.currentValue = '(' + appliedFilters.length + ') ' + appliedFilters;
    } else {
      this.currentValue = null;
    }
  }

  onInputClick() {
    this.params.showParentFilter();
  }
}