import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControlOptions, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthErrorMessage } from '@constants/auth/auth-error-message.enum';
import { TechSupprtNumber } from '@constants/messages.constants';
import { ChangePassword } from '@models/auth/change-password.model';
import { PasswordRuleViewModel } from '@models/auth/rule.model';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { ConvertApiErrorToAlertMessage, GetPasswordRulesAndValidation } from '@pages/auth/helpers';
import { AuthService } from '@services/auth.service';
import { LoggerService } from '@services/core/logger-service.class';
import { NavigationService } from '@services/navigation.service';
import { isNullOrEmpty } from '@utilities/helpers';
import { PasswordValidators } from '@utilities/validators/password-validators';

@Component({
    selector: 'jjkp-new-password',
    styleUrls: ['./new-password.component.scss'],
    templateUrl: './new-password.component.html',
})
export class NewPasswordComponent extends ValidatableFormBaseDirective implements OnInit {

    @Output() success = new EventEmitter<void>();
    @Output() answerFailure = new EventEmitter<string>();
    @Output() passwordFailure = new EventEmitter<string>();
    @Output() loadingFailure = new EventEmitter<string>();
    @Output() resendClicked = new EventEmitter<string>();

    @Input() emailAddress: string;
    @Input() recoveryAnswer: string;
    @Input() rules: PasswordRuleViewModel[];
    @Input() b2c2OktaToggle: boolean;

    ruleValidators: ValidatorFn[];
    rulesAreLoaded: boolean;
    hideResend = true;
    resendLinkText = "Resend Verification Code";
    isExistingInOkta: boolean;
    hasOnlyOtherApplications = false;
    nonSMSproducts: any;
    newPasswordText = "New Password";

    private parentActionSuccess: EventEmitter<boolean> = new EventEmitter();
    hasOktaSMS: boolean;

    get rulesViewValidationErrors(): ValidationErrors {
        return this.form ? this.getControl('newPassword').errors : null;
    }

    get showRuleErrorText(): boolean {
        return this.form ? this.shouldShowErrors('newPassword') : false;
    }

    alertText: string;
    showAlert = false;
    isProcessingRequest = false;

    constructor(private formBuilder: UntypedFormBuilder,
        private navigator: NavigationService,
        private authService: AuthService) {
        super();
    }

   async ngOnInit(): Promise<void> {
        if(this.b2c2OktaToggle){
           await this.authService.checkIfOktaUser(this.emailAddress)
            .then((data) => {
                 this.isExistingInOkta = !isNullOrEmpty(data.id);
                 this.hasOktaSMS = data.products.filter(p => p == 'Safety Management Suite' || p == 'Safety Management Suite RO' || p == 'SafetyManagementSuite.APIM').length > 0;
                 this.nonSMSproducts = data.products.filter(p => p !== 'Safety Management Suite' && p !== 'Safety Management Suite RO' && p !== 'SafetyManagementSuite.APIM');
                 this.hasOnlyOtherApplications = !(this.nonSMSproducts.length === 0) && (this.hasOktaSMS == false);
                 if (!this.isExistingInOkta) {
                    this.getPasswordRules();
                }
                else{
                    if(this.isExistingInOkta && !this.hasOnlyOtherApplications){
                        this.getPasswordRules();
                    }
                   this.buildForm();
                }
            })
            .catch((errorMessage) => {
                LoggerService.error(errorMessage);
                this.getPasswordRules();
            })
        }
        else{this.getPasswordRules();}
    }

    async getPasswordRules() {
        await this.authService.getPasswordRules()
            .then(data => this.rules = GetPasswordRulesAndValidation(data, this.emailAddress))
            .catch((errorMessage) => this.loadingFailure.emit(errorMessage));
        this.ruleValidators = this.rules.filter(r => r.validator).map(r => r.validator);
        this.buildForm();
    }

    private buildForm() {
        if(this.b2c2OktaToggle){
            this.rulesAreLoaded = true;
            this.newPasswordText = "Password";
        }
            this.form = this.formBuilder.group({
                newPassword: [null, this.hasOnlyOtherApplications ? Validators.required :  this.ruleValidators],
                passwordConfirm: [null, this.hasOnlyOtherApplications ? null : Validators.required]
            }, {
                validators: this.hasOnlyOtherApplications ? null : [PasswordValidators.PasswordMatchValidator('newPassword', 'passwordConfirm')],
            } as AbstractControlOptions);
    }
    protected cancel() {
        this.navigator.navigateTo(AuthRoutes.Login.urlRoute);
    }

    protected async submitForm(eventData?: any) {
        this.resetPassword(this.getControl('newPassword').value)
    }

    async resetPassword(newPassword) {
        this.isProcessingRequest = true;
        const changePassword = {
            emailAddress: this.emailAddress,
            newPassword: newPassword
        } as ChangePassword

        this.parentActionSuccess.emit(true)
        await this.authService.requestResetPassword(changePassword)
            .then((response) => this.success.emit(response))
            .catch(error => {
                this.emitErrorMessage(error)
            })
            .finally(() => {
                this.isProcessingRequest = false;
                this.parentActionSuccess.emit(false)
            });
    }

    emitErrorMessage(apiErrorMessage: AuthErrorMessage) {
        this.passwordFailure.emit(this.hasOnlyOtherApplications ? ConvertApiErrorToAlertMessage(apiErrorMessage, `An error has occurred. Your user has not been updated. Please contact technical support at ${TechSupprtNumber}.`): ConvertApiErrorToAlertMessage(apiErrorMessage, `An error has occurred. Your password has not been updated.`));
        if (apiErrorMessage == AuthErrorMessage.SessionExpired) {
            this.hideResend = false;
        }
    }

    resend() {
        this.resendClicked.emit();
        this.hideResend = true;
    }
}
