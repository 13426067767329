export enum MedicalAccommodationStatusEnum {
    NotRequested = '7f6530f3-062d-47e9-bf86-25f1e8286ace',
    Requested = 'c647a267-8d24-47a7-aa57-a574a9b2e08e',
    Approved = '61146b5a-58f4-45a8-91d1-3cc84e728f4e',
    Denied = '41f0e6d4-c04b-4841-924c-d1d5653574c8',
  }

  export const MedicalAccommodationStatusMap = new Map([
    [MedicalAccommodationStatusEnum.NotRequested, 'Not Requested'],
    [MedicalAccommodationStatusEnum.Requested, 'Requested'],
    [MedicalAccommodationStatusEnum.Approved, 'Approved'],
    [MedicalAccommodationStatusEnum.Denied, 'Denied'],
  ]);
