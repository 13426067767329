import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'jjk-card-link',
  template: '',
})
export class CardLinkComponent {

  constructor() {
  }

  @Input() title: string;
  @Input() routerLink: string;
  @Input() queryParams: string;
  @Input() state: any;
  @Input() authTag: any;
  @Input() featureToggleName: string;
  @Output() onClick = new EventEmitter<string>();

}
