import { Guid } from 'guid-typescript';
import { Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JJKUserInfo } from '@models/core/JJKUserInfo.class';
import { AutoIncrementingIdentifierDirective } from '@modules/messaging/baseClasses/AutoIncrementingIdentifier';
import { decryptData, encryptData } from '@services/crypto.service';

export class LoggedInUserInfo extends AutoIncrementingIdentifierDirective {
    private static _loggedInUserInfoInstance: LoggedInUserInfo = null;

    private USERINFOKEY = 'tempKey';
    private tokenHelper = new JwtHelperService();
    private _decodedToken: any;
    private _userInfo: JJKUserInfo = undefined;
    private _clientUserInfo: JJKUserInfo = undefined;
    private tempUserKey = 'defaultKey';

    public userInfoChanged = new Subject<JJKUserInfo>();

    public get userInfo(): JJKUserInfo {
        // Is this already loaded and ready?  If so, return what we have
        if (this._userInfo.isInitialized) {
            return this._userInfo;
        }

        // Nothing loaded yet, do we have it stored perhaps?
        if (window.localStorage.getItem(this.USERINFOKEY) !== null)
            this.tempUserKey = window.localStorage.getItem(this.USERINFOKEY) ;
        const encryptedValues = window.localStorage.getItem(this.tempUserKey);
        const ls = encryptedValues !== null ? decryptData(encryptedValues,this.tempUserKey) : null;

        if (ls) {
            const ui = decodeURIComponent(escape(atob(ls)));
            this._userInfo.disableUpdateNotifications();
            this._userInfo.populateFromJSON(JSON.parse(ui));
            if (this._userInfo.companyId === JJKUserInfo.emptyGuid) {
                // this.resetLoggedInUserData();
            } else {
                this._userInfo.registerPropertyChangedHandler(() => this.persistJJKUserInfoToLocalStorage());
                this._userInfo.registerNewAuthTokenDecoder((t) => this.extractDataFromToken(t));
                // DECODE THE TOKEN FROM LOCAL STORAGE
                if (this._userInfo.accessToken) {
                    this.extractDataFromToken(this._userInfo.accessToken);
                }
                this._userInfo.enableUpdateNotifications();
            }
            this.userInfoChanged.next(this._userInfo);
            return this._userInfo;
        }
        // If everything else failed, just return it
        return this._userInfo;
    }

    public get clientUserInfo(): JJKUserInfo {
        this._clientUserInfo = new JJKUserInfo();
        Object.assign(this._clientUserInfo, this._userInfo);
        if (this._clientUserInfo) {
            delete this._clientUserInfo['_company'];
            delete this._clientUserInfo['_user'];
            delete this._clientUserInfo['_mainUser'];
            delete this._clientUserInfo['_roles'];
            delete this._clientUserInfo['_groups'];
            delete this._clientUserInfo['_groupRole'];
            delete this._clientUserInfo['_userGroups'];
            delete this._clientUserInfo['_features'];
            delete this._clientUserInfo['_subscriptionFeature'];
            delete this._clientUserInfo['_subscription'];
            delete this._clientUserInfo['_subscriptionType'];
            delete this._clientUserInfo['_impersonation'];
            delete this._clientUserInfo['_userDataProfile'];
            delete this._clientUserInfo['_permissions'];
            delete this._clientUserInfo['_permissionsPerRole'];
            delete this._clientUserInfo['_calculatedPermissions'];
            delete this._clientUserInfo['_rootGroup'];
            delete this._clientUserInfo['_sharedGroup'];
            delete this._clientUserInfo['_companyGroup'];
            delete this._clientUserInfo['_noAccessGroup'];
            delete this._clientUserInfo['_jjkCompanyId'];
            delete this._clientUserInfo['_jjkUserId'];
            delete this._clientUserInfo['_azureUID'];
            delete this._clientUserInfo['_isTrialSelfRenewal'];
            delete this._clientUserInfo['_selfRenewalMaterialCode'];
            delete this._clientUserInfo['_selfRenewalPromoCode'];
            delete this._clientUserInfo['_sharedGroupId'];
            delete this._clientUserInfo['_companyGroupId'];
            delete this._clientUserInfo['_noAccessGroupId'];
            delete this._clientUserInfo['_rootGroupId'];
            delete this._clientUserInfo['_userFirstName'];
            delete this._clientUserInfo['_userLastName'];
            delete this._clientUserInfo['_errorMessage'];
            delete this._clientUserInfo['_ignorePropertyChangedNotification'];
            delete this._clientUserInfo['_lastRefreshedDateTime'];
            delete this._clientUserInfo['_logoUrl'];
            delete this._clientUserInfo['_allowNoAccessControlGroup'];

            /* We only want to pass the company and user IDs to the API if this is an impersonation or non-auth request,
             * otherwise they will be grabbed from the access token */
            if (this._clientUserInfo['_impersonationId'] === '' && this._clientUserInfo['_isNonAuth'] === false) {
                delete this._clientUserInfo['_companyId'];
                delete this._clientUserInfo['_userId'];
            }
        }
        return this._clientUserInfo;
    }

    public static get Instance() {
        if (LoggedInUserInfo._loggedInUserInfoInstance === null || !window.localStorage.getItem(LoggedInUserInfo._loggedInUserInfoInstance.USERINFOKEY)) {
            LoggedInUserInfo._loggedInUserInfoInstance = new LoggedInUserInfo();
        }
        return LoggedInUserInfo._loggedInUserInfoInstance;
    }

    private persistJJKUserInfoToLocalStorage(): void {
        const liu = JSON.stringify(this._userInfo);
        const b64 = btoa(unescape(encodeURIComponent(liu)));

        window.localStorage.removeItem(this.tempUserKey);
        this.tempUserKey = encryptData(this.USERINFOKEY, this._userInfo.userKey != undefined ? this._userInfo.userKey : this.tempUserKey);
        window.localStorage.setItem(this.USERINFOKEY, this.tempUserKey);

        const encryptedValues = encryptData(b64,this.tempUserKey)
        window.localStorage.setItem(this.tempUserKey, encryptedValues);
    }

    constructor() {
        super();
        this._userInfo = new JJKUserInfo();
        this._userInfo.registerPropertyChangedHandler(() => this.persistJJKUserInfoToLocalStorage());
        this._userInfo.registerNewAuthTokenDecoder((t) => this.extractDataFromToken(t));
        this.userInfoChanged.next(this._userInfo);
    }

    public get isAuthorized(): boolean {
        if (!this.clientUserInfo?.accessToken) {
            return false;
        }

        try {
            // In order to determine if the token expired, a valid token would need to be issued. This call tests both.
            if (this.tokenExpired) {
                return false;
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    public get smsUserDataLoaded() { return this.userInfo && !(this.userInfo.companyId.toString() === Guid.EMPTY); }
    public get decodedToken() { return this._decodedToken; }
    public get tokenExpirationAsDate() {
        const tokenExpirationDate = new Date(this.decodedToken['exp'] * 1000).getTime();
        return tokenExpirationDate;
    }
    public get tokenExpirationInAsMs() {
        const tokenDate = new Date(this.tokenExpirationAsDate).getTime();
        return tokenDate;
    }

    public get msUntilExpired() {
        const currentTimeInMs = new Date().getTime();
        const msToExpiration = this.tokenExpirationInAsMs - currentTimeInMs;
        if (msToExpiration <= 0) { return 0; }

        return msToExpiration;
    }

    public get tokenExpired() {
        return this.msUntilExpired <= 0;
    }

    public get isNewUser() { return this.tokenHelper.decodeToken(this._userInfo.accessToken)['newUser']; }
    public get tokenSafe() { try { return this._userInfo.accessToken; } catch (e) { return null; } }

    extractDataFromToken(jwt: string) {
        this._decodedToken = this.tokenHelper.decodeToken(jwt);
        this._userInfo.populateFromToken(this.decodedToken);
    }

    public resetLoggedInUserData = () => {
        window.localStorage.removeItem(this.USERINFOKEY);
        window.localStorage.removeItem(this.tempUserKey);
        // reset all the properties
        Object.assign(this._userInfo, new JJKUserInfo());
        this.userInfoChanged.next(this._userInfo);
    }

    reportError(description: string, errorData: any) {
    }
}
