<div class="form-group d-xl-flex">

    <div class="container-FilterItem flex-column">
        <!-- Filter DropDown -->
        <ng-select #selectitem class="custom" [items]="onlyavailableColumns" bindValue="name" bindLabel="name"
            [searchable]="false" [closeOnSelect]="true" [(ngModel)]="filter.column" [searchFn]="defaultSearch"
            placeholder='Select One' [virtualScroll]="true" (change)="updateOperators($event)"
            (mouseenter)="onSelectItemClick($event)" (focusin)="onSelectItemClick($event)"
            (focusout)="onSelectItemClick($event)">
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-flex">
                    <div *ngIf="item.icon" style="width: 5%" class="text-center me-2"> <i [class]="item.icon"></i></div>
                    {{item.name}}
                </div>
            </ng-template>
        </ng-select>
    </div>

    <div class="container-FilterItem flex-column">
        <!-- Operator -->
        <ng-container *ngIf="filter.filterType !== filterType.bool">
            <ng-select #selectoperator class="custom" [items]="filter.operators" [searchable]="false"
                [closeOnSelect]="true" [(ngModel)]="filter.operator" [searchFn]="defaultSearch" placeholder='Select One'
                [virtualScroll]="true" (change)="updateValueType($event)">
                <ng-option *ngFor="let o of filter.operators" [value]="o">{{o}}</ng-option>
            </ng-select>
        </ng-container>
    </div>

    <div class="container-FilterItem flex-column">
        <!-- Filter Value inputs -->
        <div class="container-filterinputs d-xl-flex" [ngSwitch]="filter.filterType">
            <!-- Date -->
            <div *ngSwitchCase="filterType.date">
                <jjk-date-picker [name]="dateInput" [value]="defaultDate"
                    [isDisabled]="controlData?.isDisabled" (dateChanged)="selectedDate($event)"></jjk-date-picker>
            </div>

            <!-- Range Date -->
            <div *ngSwitchCase="filterType.dateRange">
                <jjk-date-range-picker [startDate]="defaultDate" [endDate]="defaultDate"
                [isDisabled]="controlData?.isDisabled" (dateChanged)="selectedDate($event)"></jjk-date-range-picker>
            </div>

            <!-- Boolean -->
            <div *ngSwitchCase="filterType.bool">
                <div class='input-group bool'>
                    <label class="switch switch-flat">
                        <input class="switch-input" type="checkbox" [(ngModel)]="filter.filterValue">
                        <span class="switch-label" data-on="Yes" data-off="No"></span><span
                            class="switch-handle"></span>
                    </label>
                </div>
            </div>

            <!-- Autocomplete -->
            <div *ngSwitchCase="filterType.autocomplete">
                <input ngui-auto-complete [source]="filter.filterValues" [(ngModel)]="filter.filterValue"
                    [accept-user-input]="true" [tab-to-select]="false" [max-num-list]="30"
                    no-match-found-text="No Matches Found" name="filterValue" class="form-control filterValue"
                    autocomplete="off" placeholder="select" />
            </div>

            <!-- Select -->
            <div *ngSwitchCase="filterType.select">

                <ng-container *ngIf="selectFilterGrouped; then groupedSelect; else normalSelect"></ng-container>

                <ng-template #normalSelect>
                    <div class="jjkselect-control">
                        <jjk-select [options]="filter.filterValues" [(selection)]="selectedGroupedOptions"></jjk-select>
                    </div>
                </ng-template>

                <ng-template #groupedSelect>
                    <div class="jjkselect-control">
                        <jjk-select [options]="filter.filterValues" [groupBy]="'groupName'" [isGrouped]="true"
                            [(selection)]="selectedGroupedOptions"></jjk-select>
                    </div>
                </ng-template>

            </div>

            <!-- Multi-Select -->
            <div *ngSwitchCase="filterType.multiselect">
                <div class="jjkselect-control">
                    <jjk-select #multiSelect [options]="filter.filterValues" [multiple]="true"
                        [(selection)]="selectedOptions"></jjk-select>
                </div>
            </div>
            <div *ngSwitchCase="filterType.number" class="d-flex">
                <input
                    [ngClass]="filter.operator === 'Is Between'? 'form-control form-control-number filterValue' : 'form-control filterValue'"
                    type="text" [(ngModel)]="filter.filterValue" aria-describedby="free" autocomplete="off" />

                <div *ngIf="filter.operator === 'Is Between'" style="margin:5px">and</div>
                <input *ngIf="filter.operator === 'Is Between'" class="form-control form-control-number filterValue"
                    type="text" [(ngModel)]="filter.tofilterValue" aria-describedby="free" autocomplete="off" />
            </div>

            <!-- Multi-Select -->
            <div *ngSwitchCase="filterType.multiselectAsync">
                <div class="jjkselect-control">
                    <jjk-select [options]="filter.filterValues" [multiple]="true" [searchViaApi]="true"
                        [showLoading]="areOptionsLoading" [typeAheadInput$]="typeAheadInput$"
                        [(selection)]="selectedOptions" placeholderText="Type to search"
                        typeToSearchText="No items found"></jjk-select>
                </div>
            </div>

            <!-- Default -->
            <div *ngSwitchDefault>
                <input class="form-control filterValue" type="text" [(ngModel)]="filter.filterValue"
                    aria-describedby="free" autocomplete="off" />
            </div>

            <div class="input-group-removefilter">
                <button type="button" class="btn btn-removefilter" (click)="removeFilterClick()">
                    <i class="fa fa-times"></i>
                </button>
            </div>

        </div>

    </div>

    <div class="filterseparator"></div>

</div>