<div class="modal fade" id="authMigrationInfo" tabindex="-1" role="dialog" aria-labelledby="authMigrationInfoHeader"
  data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="authMigrationInfoHeader">Site Password Requirements Update</h1>
      </div>
      <div class="modal-body">
        <p>J. J. Keller has implemented additional security practices that require you to update your password annually. Please click proceed below to setup your new password.</p>
      </div>
      <div class="modal-footer">
        <div id="modalFooterLoader">
        <jjk-loader *ngIf="isLoading">
        </jjk-loader>
        </div>
        <div>
        <button type="button" class="btn" [ngClass]="'btn-primary'" (click)="onConfirmSetUp()">Proceed</button>
      </div>
      </div>
    </div>
  </div>
</div>

<div style="position: absolute;" class="modal fade" id="authMigrationForm" tabindex="-1" role="dialog" aria-labelledby="authMigrationFormHeader"
  data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div *ngIf="isExistingInOkta" class="modal-content">
      <div style="padding-bottom:0px;" class="modal-header">
        <h5 class="modal-title" style="font-weight: 600;" id="authMigrationFormHeader">Security Enhancement Requirements</h5>
      </div>
      <hr style="width: 93%; margin-bottom: 0px;">
      <div *ngIf="showMigrationSubmissionErrorAlert" id="alertWrapper">
      <sms-alert-dismiss [messageText]="alertText" (dismissAlertClick)="showMigrationSubmissionErrorAlert = false" [customStyle]="'Error'"></sms-alert-dismiss>
      </div>
      <div *ngIf="!hasOtherApplications" style="padding-top:0px; padding-bottom:25px" class="modal-body">
        <p>Your Safety Management Suite account credentials can be used to log into other J. J. Keller & Associates Inc. products.<br></p>
        <p>Any changes to credentials will affect future products associated with this email.<br>
          <br>
          Contact support at <a href="tel:833-813-7268">833-813-7268</a> or <a href="mailto:safetysupport@jjkeller.com">safetysupport&#64;jjkeller.com</a> for any questions.</p>
      <div id="migrateLoginForm">
        <jjkp-migrate-login-form (cancelClicked)="closeForm()"></jjkp-migrate-login-form>
      </div>
      </div>
      <div *ngIf="hasOtherApplications" style="padding-top:0px; padding-bottom:25px" class="modal-body">
        <p>Your Safety Management Suite account will now use the same login credentials as the following J. J. Keller sites.  As a one-time verification, please enter the same password you use to access:<br></p>
          <ul>
            <li *ngFor="let item of nonSMSproducts | slice:0:3">{{item}}</li>
          </ul>
          <p>Please note that any changes or updates to your credentials will be affected across all products.<br>
          <br>
          Contact support at <a href="tel:833-813-7268">833-813-7268</a> or <a href="mailto:safetysupport@jjkeller.com">safetysupport&#64;jjkeller.com</a> for any questions.</p>
      <div id="migrateLoginForm">
        <jjkp-migrate-login-form (cancelClicked)="closeForm()"></jjkp-migrate-login-form>
      </div>
      </div>
    </div>
    <div *ngIf="!isExistingInOkta" class="modal-content">
      <div style="padding-bottom:0px;" class="modal-header">
        <h5 class="modal-title" style="font-weight: 600;" id="authMigrationFormHeader">Security Information</h5>
      </div>
      <hr style="width: 93%; margin-bottom: 0px;">
      <div *ngIf="showMigrationSubmissionErrorAlert" id="alertWrapper">
      <sms-alert-dismiss [messageText]="alertText" (dismissAlertClick)="showMigrationSubmissionErrorAlert = false" [customStyle]="'Error'"></sms-alert-dismiss>
      </div>
      <div *ngIf="!rules" style="padding-top:0px; padding-bottom:25px" class="modal-body">
        <jjk-loader [marginTop]="'20px'" [loadingText]="loadingFormMessage">
        </jjk-loader>
      </div>
      <div *ngIf="rules" id="passwordRulesWrapper" class="modal-body">
        <jjkp-password-rules [rules]="rules" [showValidation]="true" [validationErrors]="rulesViewValidationErrors"
          [showErrorText]="showPasswordRuleErrorText" (rulesAreLoaded)="rulesAreLoaded = true"></jjkp-password-rules>
        <form [formGroup]="form" *ngIf="form" [hidden]="!rulesAreLoaded">
          <div class="entry">
            <div class="entry-item">
              <label class="req form-label" for="newPassword">New Password</label>
                <input password formControlName="newPassword" id="newPassword" name="newPassword"
                  [class.field-error]="shouldShowErrors('newPassword')" aria-label="New Password" aria-required="true"
                  autofocus>
            </div>
            <div class="entry-item">
              <label class="req form-label" for="passwordConfirm">Confirm New Password</label>
                <input password formControlName="passwordConfirm" id="passwordConfirm" name="passwordConfirm"
                  [class.field-error]="shouldShowErrors('passwordConfirm')" aria-label="Confirm new password"
                  aria-required="true" (keyup)="updateNewPasswordValidity()">
              <div class="password-error">
                <div *ngIf="shouldShowErrors('passwordConfirm')" class="invalid-text">
                  {{getControl('passwordConfirm')?.errors?.passwordsMatch}}</div>
              </div>
            </div>
            <div id="submissionLoaderWrapper" *ngIf="waitingFormSubmit" class="modal-body">
              <jjk-loader [marginTop]="'20px'" [loadingText]="'Please wait while we update your credentials...'">
              </jjk-loader>
            </div>
            <div *ngIf="!serverError" id="saveComponentWrapper">
              <save-button [innerText]="'Save'" (onSave)="onFormSubmit()" [title]="'Save'"></save-button>
            </div>
            <div *ngIf="serverError" id="cancelComponentWrapper">
              <button id="closeButton" (click)="closeForm()" type="button"
                class="btn btn-secondary text-decoration-none">Close</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
