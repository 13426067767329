<li *ngIf="isProdOwnerType">
  <a class="dropdown-item btn btn-accordion w-100 text-start" (click)="closeSideNavigation()" [routerLink]="'/admin_settings'">Admin Settings</a>
</li>
<li>
  <a class="dropdown-item btn btn-accordion w-100 text-start" (click)="closeSideNavigation()" [routerLink]="'/profile'">Profile</a>
</li>
<ng-container *ngIf="!isSdsManagement && showSettingsMenuOption && this.featureAuthService.hasAccess('setting,admin')">
<li>
  <a class="dropdown-item btn btn-accordion w-100 text-start" (click)="closeSideNavigation()" [routerLink]="'/settings'">Settings</a>
</li>
</ng-container>
<ng-container *ngIf="!isSdsManagement && this.featureAuthService.hasAccess('import-export,view')">
<li *featureToggle="'importexport_feature'">
  <a class="dropdown-item btn btn-accordion w-100 text-start" (click)="closeSideNavigation()" [routerLink]="'/import-export'">Import / Export Center</a>
</li>
</ng-container>
<li>
  <a class="dropdown-item btn btn-accordion w-100 text-start" (click)="showModal('impersonationModal')" [routerLink]="[]">Allow Impersonation</a>
</li>
<li *ngIf="impersonationId()=='end'">
  <a class="dropdown-item btn btn-accordion w-100 text-start" (click)="pauseImpersonation()" [routerLink]="[]">Pause Impersonation</a>
</li>
<li *ngIf="impersonationId()=='end'">
  <a class="dropdown-item btn btn-accordion w-100 text-start" (click)="showModal('impersonationEndModal')" [routerLink]="[]">End Impersonation</a>
</li>
<li>
  <a class="dropdown-item btn btn-accordion w-100 text-start" href="https://support.jjkeller.com/Safety_Management_Suite" target="_blank">
    Support Center <i class="fas fa-external-link-alt"></i>
  </a>
</li>
<li *featureToggle="'security_auth_test'">
  <a class="dropdown-item btn btn-accordion w-100 text-start" (click)="Get401()">Get 401 Unauthorized</a>
</li>
<div class="dropdown-divider"></div>
<li>
  <a id="logout-button" class="dropdown-item btn btn-accordion w-100" (click)="logout()">Log out</a>
</li>