import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IToolPanel } from 'ag-grid-community';
import { DeleteComponent } from '@components/delete/delete.component';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { AGGridSettingsToolPanelParams } from '@models/ag-grid/ag-grid-settings-tool-panel-params.model';
import { AlertArea, AlertType } from '@models/alert';
import { GridView } from '@models/entity-models/autogenerated/gridview';
import { GridViewState } from '@models/entity-models/autogenerated/gridviewstate';
import { AlertService } from '@services/alert.service';
import { ApiFactory } from '@services/core/api-factory.class';
import { LoggerService } from '@services/core/logger-service.class';
import { FilterExpressionBuilder } from '@services/core/models/Filter-Entry';
import { applyGridViewToGrid } from '@utilities/grid-helpers';
import { focusOn, isNullOrUndefined } from '@utilities/helpers';

@Component({
  selector: 'ag-grid-settings-toolpanel',
  templateUrl: './ag-grid-settings-toolpanel.component.html',
  styleUrls: ['./ag-grid-settings-toolpanel.component.scss']
})
export class AGGridSettingsToolPanelComponent implements IToolPanel {

  filtersShowing: boolean;
  isColumnFilterPresent: boolean;
  gridColumnsChanged: boolean;
  params: AGGridSettingsToolPanelParams;
  views: any[] = [];
  editOrSaveModalId = "edit_or_save_modal";
  showErrors: boolean;
  viewSaveForm: UntypedFormGroup;
  currentEditedGridViewId: any;
  isLoading = true;
  isViewLoading = false;
  private saveErrorMessage = "There was a problem saving the view. Please try again.";
  private saveSuccessMessage = "View successfully saved."
  private loadStateErrorMessage = "There was a problem loading the Saved View. Please try again.";

  @ViewChild('deleteViewModal', { static: true })
  private deleteModal: DeleteComponent;

  constructor(private viewFormBuilder: UntypedFormBuilder, private alertService: AlertService) { }

  agInit(params: AGGridSettingsToolPanelParams): void {
    this.params = params;

    this.viewSaveForm = this.viewFormBuilder.group({
      name: ['', [Validators.required, Validators.pattern(new RegExp("^[a-zA-Z0-9 _,.-]+$"))]],
      isDefault: false,
    });
    // subscribe to behavior subjects on the main grid API so we can be aware of state changes
    if ((this.params.api as any).floatingFilterVisible) {
      (this.params.api as any).floatingFilterVisible.subscribe(value => {
        if (value != undefined) {
          this.filtersShowing = value;
        }
      });
    }
    if ((this.params.api as any).columnFiltersPresent) {
      (this.params.api as any).columnFiltersPresent.subscribe(value => {
        if (value != undefined) {
          this.isColumnFilterPresent = value;
        }
      });
    }
    if ((this.params.api as any).gridColumnsChanged) {
      (this.params.api as any).gridColumnsChanged.subscribe(value => {
        if (value != undefined) {
          this.gridColumnsChanged = value;
        }
      });
    }
    // Get the GUID of the current grid from the DB. Then get the user's saved views for that grid.
    if (this.params.hideSaveViews){
      this.isLoading = false;
    }
    else if (this.params.gridId) {
      this.refreshViewsList();
    }
    else {
      LoggerService.trace('trace', 'Error loading saved grid views: gridId not found.');
    }
  }

  refresh(): boolean {
    return true;
  }

  onViewClicked(id: string) {
    if (this.isLoading || this.isViewLoading) {
      return;
    }
    try {
      this.isViewLoading = true;
      ApiFactory.retrieveEntity(ApiEntityTypesEnum.GridView)
        .addEntityId(id)
        .addSuccessHandler((data: GridView) => {
            try {
                const columns = JSON.parse(data.gridViewState[0].gridColumnState);
                const index = columns.findIndex(c => c.colId === this.params.quickActionsColumn);
                if (index !== -1)
                {
                    columns[index].hide = true;
                    columns[index].lockVisible = true;
                    columns[index].suppressFiltersToolPanel = true;
                    data.gridViewState[0].gridColumnState = JSON.stringify(columns);
                }
                applyGridViewToGrid(data, this.params.api)
                this.isViewLoading = false;
            }
            catch (error) {
                this.alertService.addErrorAlert(this.loadStateErrorMessage, AlertArea.All);
                this.isViewLoading = false;
                LoggerService.trace('trace', error);
            }
        })
        .addErrorHandler((error) => {
            this.alertService.addErrorAlert(this.loadStateErrorMessage, AlertArea.All);
            this.isViewLoading = false;
            LoggerService.trace('trace', error);

        })
        .buildAndSend();
    }
    catch (error) {
      this.alertService.addErrorAlert(this.loadStateErrorMessage, AlertArea.All);
      this.isViewLoading = false;
      LoggerService.trace('trace', error);
    }
  }

  refreshViewsList() {
    const simpleExpression = FilterExpressionBuilder.For(ApiEntityTypesEnum.GridView)
            .Use('GridId', ParameterTypeEnum.String)
            .Equal(this.params.gridId)
            .Build().AsExpression;
    ApiFactory.retrieveEntity(ApiEntityTypesEnum.GridView)
    .addFilterEntries(simpleExpression)
      .addSuccessHandler((response: GridView[]) => {
        this.views = response;
        this.sortViews();
        this.isLoading = false;
      })
      .addErrorHandler((error) => {
        LoggerService.trace('trace', 'Error retrieving GridViews.');
        LoggerService.trace('trace', error);
        this.isLoading = false;
      })
      .removePaging()
      .buildAndSend();
  }

  onShowFloatingFilter() {
    this.params.onShowFilters();
  }

  onSaveView() {
    ($(`#${this.editOrSaveModalId}`) as any).modal('show');
    focusOn('name', true);
  }

  onEditView(id: string) {
    if (this.isLoading || this.isViewLoading) {
      return;
    }
    this.currentEditedGridViewId = id;
    const currentGridView = this.views.find(x => x.id === this.currentEditedGridViewId);
    this.viewSaveForm.get('isDefault').setValue(currentGridView.isDefault);
    this.viewSaveForm.get('name').setValue(currentGridView.name);
    ($(`#${this.editOrSaveModalId}`) as any).modal('show');
    focusOn('name', true);
  }

  onSaveViewConfirm() {
    if (this.viewSaveForm.valid && this.params.gridId) {
      try {
        this.isLoading = true;
        if (this.currentEditedGridViewId) {
          //EDIT PATH
          const currentGridView = this.views.find(x => x.id === this.currentEditedGridViewId);
          const editedGridView = {
            ...currentGridView,
            isDefault: this.viewSaveForm.get('isDefault')?.value ?? false,
            name: this.viewSaveForm.get('name').value
          }
          ApiFactory.updateEntity(ApiEntityTypesEnum.GridView, editedGridView)
          .addSuccessHandler((Response: GridView) => {
            // Remove isDefault from any old items if this one was updated to default
            this.adjustDefaultInViewList(Response);
            // Update the existing item in the grid now that we know it saved successfully.
            currentGridView.name = Response.name;
            currentGridView.isDefault = Response.isDefault;
            this.sortViews();
            this.alertService.addAlert(AlertType.Success, this.saveSuccessMessage);
            this.isLoading = false;
          })
          .addErrorHandler((error) => {
            LoggerService.trace('trace', error);
            this.alertService.addErrorAlert(this.saveErrorMessage, AlertArea.All);
            this.isLoading = false;
          })
          .buildAndSend();
        }
        else {
          // SAVE NEW PATH
          const newGridView = {
            gridId: this.params.gridId,
            isDefault: this.viewSaveForm.get('isDefault')?.value ?? false,
            name: this.viewSaveForm.get('name').value,
            isDeleted: false
          };

          ApiFactory.saveNewEntity(ApiEntityTypesEnum.GridView, newGridView)
          .addSuccessHandler((responseView: GridView) => {
            // New GridView saved successfully, now save the GridViewState
            const newGridViewState = {
              gridViewId: responseView.id,
              isDeleted: false,
              gridFilterState: JSON.stringify(this.params.api.getFilterModel()),
              gridColumnState: JSON.stringify(this.params.api.getColumnState())
            };
            ApiFactory.saveNewEntity(ApiEntityTypesEnum.GridViewState, newGridViewState)
            .addSuccessHandler((responseState: GridViewState) => {
              // Remove isDefault from any old items if this one was updated to default
              this.adjustDefaultInViewList(responseView);
              this.views.push(responseView);
              this.sortViews();
              this.alertService.addAlert(AlertType.Success, this.saveSuccessMessage);
              this.isLoading = false;
            })
            .addErrorHandler((error) => {
              // The GridView saved but the GridViewState didn't, need to delete the GridView in that case.
              // Should be pretty rare as the GridViewState object is pretty basic. If it becomes a larger
              // problem, we could make a single call to the API, passing a VM. Then we could just rollback the
              // whole transaction on the API side and return a single error. But this should be fine for now.
              ApiFactory.deleteEntity(ApiEntityTypesEnum.GridView)
              .addEntityId(responseView.id)
              .addSuccessHandler(() => {})
              .buildAndSend();

              LoggerService.trace('trace', error);
              this.alertService.addErrorAlert(this.saveErrorMessage, AlertArea.All);
              this.isLoading = false;
            })
            .buildAndSend();
          })
          .addErrorHandler((error) => {
              LoggerService.trace('trace', error);
              this.alertService.addErrorAlert(this.saveErrorMessage, AlertArea.All);
              this.isLoading = false;
          })
          .buildAndSend();
        }
        this.clearAddEditForm();
        ($(`#${this.editOrSaveModalId}`) as any).modal('hide');
      } catch (error) {
        LoggerService.trace('trace', error);
        this.alertService.addErrorAlert(this.saveErrorMessage, AlertArea.All);
          this.isLoading = false;
      }
    }
    else {
      this.showErrors = true;
    }
  }

  onDeleteCompleted(deletedRecord: GridView) {
    this.isLoading = true;
    const index = this.views.findIndex(x => x.id === deletedRecord.id);
    if (index > -1) {
      this.views.splice(index, 1);
    }
    this.alertService.addAlert(AlertType.Success, `View ${ deletedRecord.name } successfully deleted.`);
    this.isLoading = false;
  }

  onDeleteFailed(error: any) {
    LoggerService.trace('trace', error);
    this.alertService.addErrorAlert('There was a problem deleting the view. Please try again.', AlertArea.All);
  }

  onDeleteView(id: string) {
    if (this.isLoading || this.isViewLoading) {
      return;
    }
    const recordToDelete = this.views.find(x => x.id === id);

    if (recordToDelete) {
      this.deleteModal.open(recordToDelete);
    }
  }

  onCancelSaveView() {
    this.clearAddEditForm();
  }

  onResetFilters() {
    ($(`#clearFiltersModal`) as any).modal('show');
  }

  onResetGridState() {
    ($(`#resetModal`) as any).modal('show');
  }

  onResetConfirm() {
    const defView = this.views?.find(x => x.isDefault === true);
    if(isNullOrUndefined(defView))
      this.params.onResetGridState();
    else
      this.onViewClicked(defView.id);

  }

  onClearConfirm() {
    this.params.onResetFilters();
  }

  onCancelReset() {
    ($(`#resetModal`) as any).modal('hide');
  }

  onCancelClear() {
    ($(`#clearFiltersModal`) as any).modal('hide');
  }

  private clearAddEditForm() {
    this.viewSaveForm.get('name').setValue('');
    this.viewSaveForm.get('isDefault').setValue(false);
    this.currentEditedGridViewId = '';
    this.showErrors = false;
  }

  private sortViews() {
    this.views = this.views.sort(function(a, b) {
      // Always show the defaulted view on top
      if (a.isDefault || b.isDefault) {
        return a.isDefault ? -1 : 1;
      }
      return a.name?.localeCompare(b.name ?? '') ?? 0;
   });
  }

  private adjustDefaultInViewList(gridView: GridView) {
    if (gridView.isDefault) {
      this.views.filter(x => x.isDefault && x.id !== gridView.id).forEach(x => x.isDefault = false);
    }
  }
}
