import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ScreenService } from '@components/hacksaw/screen.service';
import { BreadcrumbItem } from '@models/breadcrumb-item.model';

@Component({
    selector: 'jjkp-page-header',
    templateUrl: './page-header.component.html',
})
export class PageHeaderComponent implements AfterViewInit {

    @Input() breadcrumbItems: BreadcrumbItem[] = [];
    @Input() title: string;
    showMenu = false;
    extraSmall = false;
    open = false;
    buttonPadding = 4;
    buttonWidth = this.buttonPadding;
    wrapper;

    constructor(private screenService: ScreenService, private ref: ChangeDetectorRef) { }

    ngAfterViewInit(): void {
        this.loadElements();

        this.screenService.screenResized$
            .subscribe((dimensions) => this.updateMenu(dimensions));

        this.ref.detectChanges();
    }

    loadElements() {
        this.wrapper = document.getElementsByClassName(`wrapper`)[0];

        const actionMenu = document.getElementById(`pageActionsMenu`);
        const btns = actionMenu.getElementsByTagName(`button`);
        Array.from(btns).forEach((element) => {
            this.buttonWidth += element.offsetWidth + this.buttonPadding;
        });
    }

    updateMenu(dimensions) {
        // Allow for page padding for different media queries for .wrapper
        const adjustedWrapper = dimensions.screenWidth < 991 ? this.wrapper.offsetWidth - 30 : this.wrapper.offsetWidth - 60;
        // Always show menu if the screen is < 690px wide, or if the adjusted width is smaller than the button group
        this.showMenu = (dimensions && dimensions.screenWidth < 690) || (adjustedWrapper < this.buttonWidth);
        this.extraSmall = (dimensions && dimensions.screenWidth < 575.98);

    }
}
