<!--top nav-->
<section class="d-flex align-items-center nav-overflow-container">
  <div class="filter-nav filter-nav-top filter-nav-slide scroll flex-grow-1 nav-overflow">
    <!-- Nav tabs -->
    <nav #navBar class="nav-item" mat-tab-nav-bar role="navigation" [tabPanel]="tabPanel">

        <button *ngIf="dashboardPermission"  mat-tab-link class="nav-link" [ngClass]="getActiveClass(0, 'dashboard')" (click)="activeToggle($event)">
        <span data-skip-on-tab="true"><img src="assets/images/home.svg" class="d-inline me-1"> Home</span></button>

        <button *ngIf="chemicalDashboardPermission && !isSdsManagement" mat-tab-link class="nav-link" [ngClass]="getActiveClass(1, 'chemicals')" (click)="activeToggle($event)">
        <span data-skip-on-tab="true"><img src="assets/images/chemicals.svg" class="d-inline me-1"> Chemicals</span></button>

        <button *ngIf="incidentDashboardPermission && !isSdsManagement" mat-tab-link class="nav-link" [ngClass]="getActiveClass(2, 'incidents')" (click)="activeToggle($event)">
        <span data-skip-on-tab="true"><img src="assets/images/incidents.svg" class="d-inline me-1"> Incidents</span></button>

        <button *ngIf="trainingDashboardPermission && !isSdsManagement" mat-tab-link class="nav-link" [ngClass]="getActiveClass(3, 'training')" (click)="activeToggle($event)">
        <span data-skip-on-tab="true"><img src="assets/images/training.svg" class="d-inline me-1"> Training</span></button>

        <button *ngIf="auditDashboardPermission && !isSdsManagement" mat-tab-link class="nav-link" [ngClass]="getActiveClass(4, 'audits')" (click)="activeToggle($event)">
          <span     data-skip-on-tab="true"><img src="assets/images/audits.svg" class="d-inline me-1"> Audits</span></button>

        <button *ngIf="topicDashboardPermission && !isSdsManagement" mat-tab-link class="nav-link" [ngClass]="getActiveClass(5, 'topics')" (click)="activeToggle($event)">
        <span data-skip-on-tab="true"><img src="assets/images/topics.svg" class="d-inline me-1"> Topics</span></button>

    </nav>
    <mat-tab-nav-panel #tabPanel><router-outlet></router-outlet></mat-tab-nav-panel>
  </div>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</section>
