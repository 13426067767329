<div class="flex-container">
    <div class="flex-child">
        <h1 *ngIf="header">{{header}}</h1>
    </div>
    <div class="flex-child">
        <jjk-loader *ngIf="isProcessingRequest"></jjk-loader>
    </div>
</div>
<form [formGroup]="form" *ngIf="form" aria-label="Sign in with your email address">
    <div class="entry">
        <div id="emailAddressWrapper" class="entry-item">
            <label class="req form-label">
                Email Address
            </label>
            <input appAutofocus type="text" id="signInName" name="Email Address" title="Please enter a valid Email Address" maxlength="254" formControlName="email" aria-label="Email Address" [class.field-error]="shouldShowErrors('email')" (change)="changeDetection($event)">
            <div *ngIf="shouldShowErrors('email')" class="invalid-text">Please enter a valid email address.</div>
        </div>
        <save-button [innerText]="'Continue'" (onSave)="onFormSubmit()" [isDisabled]="isProcessingRequest || isDisabledJJKEmail"></save-button>
        <back-button (click)="navigateToLoginPage()" [isDisabled]="isProcessingRequest"></back-button>
    </div>
</form>
