
export class Stack<T> {

    public get currentItem(): T { return this.stack[this.stackIndex]; }

    constructor(private stackSize: number = 100) {
        this.stack = new Array<T>(this.stackSize);
    }

    private stack: T[];
    private stackIndex = this.stackSize - 1;

    reset() {
        this.stack = new Array<T>(this.stackSize);
        this.stackIndex = this.stackSize - 1;
    }

    public push(item: T) {
        if (this.stackIndex === 0) {
            throw new Error('Stack size exceeded');
        }

        if (this.currentItem === undefined) {
            this.stack[this.stackIndex] = item;
            return;
        }
        this.stack[--this.stackIndex] = item;
    }

    public pop(): T {
        if (this.stack.length === 0) {
            throw new Error('Stack is empty');
        }

        this.stackIndex++;
        return this.currentItem;
    }

    public peek(position: number) {
        if (position > this.stackSize - 1) { throw new Error('cannot peek beyond stack size'); }
        return this.stack[position];
    }

    public async purgeUntilMatch(f: (e: T) => boolean): Promise<boolean> {
        while ((this.stackIndex !== this.stackSize - 1) && f(this.currentItem) === false) {
            this.stackIndex++;
        }

        // We did not find an entry
        if (this.stackIndex === this.stackSize - 1) { return false; }

        // Entry found!
        return true;
    }
}
