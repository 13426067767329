import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { DynamicValidators } from '@models/dynamic-forms.export';
import { GlobalPercentBy } from '@models/entity-models/autogenerated/globalpercentby';
import { GlobalProportion } from '@models/entity-models/autogenerated/globalproportion';
import { GlobalIngredientViewModel } from '@models/entity-models/sds-management/global-ingredient-view-model';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { isNullOrEmpty, sortByProperty } from '@utilities/helpers';

@Component({
  selector: 'add-edit-ingredient-form',
  templateUrl: './add-edit-ingredient-form.component.html',
  styleUrls: ['../slide-out-modal.component.scss'],
})
export class AddEditIngredientFormComponent extends ValidatableFormBaseDirective implements OnInit {

  @Input() isEdit: Boolean;
  @Input() isDelete: Boolean;
  @Input() isView: Boolean;
  @Input() isAdd: Boolean;
  @Input() record: GlobalIngredientViewModel;
  @Input() sdsId: string;
  @Output() updatedRecord = new EventEmitter();
  @Output() clearRecord = new EventEmitter();

  title: string;
  showErrors: boolean;
  processing = false;
  proportionOptions: any[] = [];
  percentByOptions: any[] = [];
  casRegistryNumberValidation = '^([1-9]{1,1})([0-9]{1,6})-([0-9]{2})-[0-9]/*$|^([1-9]{5,10})/*$';
  casRegistryNumberValidationWithoutLeadingZero = '^([0-9]{2,7})-([0-9]{2})-[0-9]/*$';

  private _selectedProportion = '';
  get selectedProportion() {
    return this._selectedProportion;
  }
  set selectedProportion(val) {
    this._selectedProportion = val;
    this.form.patchValue({
      globalProportionId: this._selectedProportion,
      globalProportion: this.proportionOptions.find(c => c.id == this._selectedProportion)?.displayText
    });
  }

  private _selectedPercentBy = '';
  get selectedPercentBy() {
    return this._selectedPercentBy;
  }
  set selectedPercentBy(val) {
    this._selectedPercentBy = val;
    this.form.patchValue({
      globalPercentById: this._selectedPercentBy,
      globalPercentBy: this.percentByOptions.find(c => c.id == this._selectedPercentBy)?.displayText
    });
  }

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  async ngOnInit() {
    this.buildForm();
    this.getProportionOptions();
    this.getPercentByOptions();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.isEdit) {
      this.setUpEditPage();
    }
    else if (this.isView) {
      this.setUpViewPage();
    }
    else if (this.isDelete) {
      this.setUpDeletePage();
    }
  }

  setUpAddPage() {
    this.title = `Add Ingredient`;
    this.selectedPercentBy = null;
    this.selectedProportion = null;
    this.form.patchValue({
      casRegistryNumber: '',
      globalPercentBy: '',
      globalPercentById:'',
      globalProportion: '',
      globalProportionId: '',
      id: Guid.EMPTY,
      ingredientName: '',
      isDeleted: '',
      maxPercent: '',
      minPercent: '',
      regulated: '',
      safetyDataSheetId: this.sdsId,
    });
  }

  setUpViewPage() {
    this.title = `View Ingredient`;
    if(!isNullOrEmpty(this.record.casRegistryNumber) && !RegExp(this.casRegistryNumberValidationWithoutLeadingZero).test(this.record.casRegistryNumber))
      this.record.casRegistryNumber = this.record.casRegistryNumber.slice(0, this.record.casRegistryNumber.length - 5) + this.record.casRegistryNumber.slice(this.record.casRegistryNumber.length - 5, this.record.casRegistryNumber.length - 3) + '-' + this.record.casRegistryNumber.slice(this.record.casRegistryNumber.length - 3, this.record.casRegistryNumber.length - 1) + '-' + this.record.casRegistryNumber.slice(this.record.casRegistryNumber.length - 1, this.record.casRegistryNumber.length);
  }

  async setUpEditPage() {
    this.title = `Edit Ingredient`;
    this.selectedPercentBy = this.record.globalPercentById;
    this.selectedProportion = this.record.globalProportionId;
    if((!isNullOrEmpty(this.record.casRegistryNumber) && !RegExp(this.casRegistryNumberValidationWithoutLeadingZero).test(this.record.casRegistryNumber)) || this.isView)
      this.record.casRegistryNumber = this.record.casRegistryNumber.slice(0, this.record.casRegistryNumber.length - 5) + this.record.casRegistryNumber.slice(this.record.casRegistryNumber.length - 5, this.record.casRegistryNumber.length - 3) + '-' + this.record.casRegistryNumber.slice(this.record.casRegistryNumber.length - 3, this.record.casRegistryNumber.length - 1) + '-' + this.record.casRegistryNumber.slice(this.record.casRegistryNumber.length - 1, this.record.casRegistryNumber.length);

    this.form.patchValue({
      casRegistryNumber: this.record.casRegistryNumber,
      globalPercentBy: this.record.globalPercentBy,
      globalPercentById: this.record.globalPercentById,
      globalProportion: this.record.globalProportion,
      globalProportionId: this.record.globalProportionId,
      id: this.record.id,
      ingredientName: this.record.ingredientName,
      isDeleted: this.record.isDeleted,
      maxPercent: this.record.maxPercent,
      minPercent: this.record.minPercent,
      regulated: this.record.regulated,
      safetyDataSheetId: this.sdsId,
      wasModified: this.record.wasModified,
      isNew: this.record.isNew,

    })

  }

  setUpDeletePage() {
    if((!isNullOrEmpty(this.record.casRegistryNumber) && !RegExp(this.casRegistryNumberValidationWithoutLeadingZero).test(this.record.casRegistryNumber)) || this.isView)
      this.record.casRegistryNumber = this.record.casRegistryNumber.slice(0, this.record.casRegistryNumber.length - 5) + this.record.casRegistryNumber.slice(this.record.casRegistryNumber.length - 5, this.record.casRegistryNumber.length - 3) + '-' + this.record.casRegistryNumber.slice(this.record.casRegistryNumber.length - 3, this.record.casRegistryNumber.length - 1) + '-' + this.record.casRegistryNumber.slice(this.record.casRegistryNumber.length - 1, this.record.casRegistryNumber.length);

    this.title = `Delete Ingredient`;
    this.form.patchValue({
      casRegistryNumber: this.record.casRegistryNumber,
      globalPercentBy: this.record.globalPercentBy,
      globalPercentById: this.record.globalPercentById,
      globalProportion: this.record.globalProportion,
      globalProportionId: this.record.globalProportionId,
      id: this.record.id,
      ingredientName: this.record.ingredientName,
      isDeleted: this.record.isDeleted,
      maxPercent: this.record.maxPercent,
      minPercent: this.record.minPercent,
      regulated: this.record.regulated,
      safetyDataSheetId: this.sdsId,
      wasModified: this.record.wasModified,
      isNew: this.record.isNew,
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      id: [''],
      safetyDataSheetId: [''],
      ingredientName: ['', Validators.required],
      regulated: [''],
      casRegistryNumber: [null, Validators.pattern(new RegExp(this.casRegistryNumberValidation))],
      globalProportionId: [''],
      globalProportion: [''],
      minPercent: ['', [DynamicValidators.validationRange("0","100"), DynamicValidators.hasAtMostDecimalPlaces(3)]],
      maxPercent: ['', [DynamicValidators.validationRange("0","100"), DynamicValidators.hasAtMostDecimalPlaces(3)]],
      globalPercentById: [''],
      globalPercentBy: [''],
      isDeleted: [false],
      wasModified: [true],
      isNew: [false]
    });
  }

  getProportionOptions(): Promise<void> {
    const apiFactory = ApiFactory.retrieveEntity(ApiEntityTypesEnum.GlobalProportion);
    return new Promise((resolve, reject) => {
      apiFactory.addSuccessHandler((data: GlobalProportion[]) => {
        sortByProperty<GlobalProportion>(data, (d) => d.name, false);
        this.proportionOptions = data.map((element) => ({
          id: element.id,
          displayText: element.name
        }));

        resolve();
      })
        .removePaging()
        .buildAndSend();
    });
  }
  getPercentByOptions(): Promise<void> {
    const apiFactory = ApiFactory.retrieveEntity(ApiEntityTypesEnum.GlobalPercentBy);
    return new Promise((resolve, reject) => {
      apiFactory.addSuccessHandler((data: GlobalPercentBy[]) => {
        sortByProperty<GlobalPercentBy>(data, (d) => d.name, false);
        this.percentByOptions = data.map((element) => ({
          id: element.id,
          displayText: element.name
        }));

        resolve();
      })
        .removePaging()
        .buildAndSend();
    });
  }
  clearForm() {
    this.processing = false;
    this.form.reset;
    this.selectedPercentBy = null;
    this.selectedProportion = null;
    this.showErrors = false;
  }

  protected submitForm() { }
}
