import { IIdentifiable } from '../interfaces/IIdentifiable';
import { Message } from './Messages/Message';

export class MessageDistributionPackage<M extends Message, R extends IIdentifiable<any>> {
    public message: M;
    public recipients: R[];

    constructor(recipients: R[], message: M) {
        this.recipients = recipients;
        this.message = message;
    }
}
