import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'jjk-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
    // Use this to bind to a reactive form
    @Input() formControlNameValue: string;
    // Use this to bind directly to a value (without using forms)
    @Input() value: moment.Moment;
    // Html name attribute (for labeling)
    @Input() name: string;
    @Input() minDate: moment.Moment;
    @Input() maxDate: moment.Moment;
    @Input() isDisabled: boolean;
    @Input() placeholder = "mm/dd/yyyy";
    @Input() isFormValid: boolean;
    @Output() dateChanged = new EventEmitter<moment.Moment>();

    @ViewChild('dateInput', {
        read: MatInput
    }) dateInput: MatInput;

    parentForm: UntypedFormGroup;

    constructor(public controlContainer: ControlContainer) { }

    ngOnInit() {
        this.parentForm = this.controlContainer.control as UntypedFormGroup;
    }

    onDateChanged(event: MatDatepickerInputEvent<moment.Moment>) {
        this.dateChanged.emit(event.value);
    }

    resetValue() {
      this.dateInput.value = undefined;
    }

    formIsInvalid(){
        return !this.isFormValid && (this.parentForm?.controls[this.formControlNameValue]?.invalid == true && this.parentForm?.controls[this.formControlNameValue]?.touched == true);
    }
}
