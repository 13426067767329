import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ChartParams } from '@models/chart/chart-params.model';
import { ChartsBaseModel } from '@models/reporting/charts/charts-base.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { LegendPosition } from '@swimlane/ngx-charts';

@Component({
    selector: 'jjk-incidents-by-type-chart',
    templateUrl: './jjk-incidents-by-type-chart.component.html',
    styles: [`
        .chart-container {
        margin-bottom: 45px;
        min-height: 300px;
        }
        `],
})

export class JJKIncidentsByTypeChartComponent implements OnChanges {
    @Input() chartType = 'Pie';
    @Input() view;
    @Input() legend = true;
    @Input() legendTitle = 'Locations';
    @Input() legendPosition = LegendPosition.Below;
    @Input() chartData: ChartsBaseModel;
    @Input() results: any;
    @Input() showAddButtonEmptyState = true;
    @Input() emptyStateMessage;

    @Output() action = new EventEmitter();

    isEmptyState = false;

    constructor() {
        const params = new ChartParams();
        params.StartDate = moment().utc().startOf('year').format();
        params.EndDate = moment().utc().endOf('year').format();

        ApiFactory.retrieveEntity(ApiEntityTypesEnum.Chart)
            .addHeader('X-ChartParams', JSON.stringify(params))
            .addDataEntry('chartType', 'IncidentsYear')
            .addRouteHint('Chart/ChartAsync')
            .addSuccessHandler((x) => {
                this.chartData = this.mapIncidentsPerTypeChart(x);
                if (this.chartData.data.length === 0) {
                    this.isEmptyState = true;
                }
            }).buildAndSend();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.chartData) {
            this.updateChart(changes.chartData.currentValue);
        }
    }

    mapIncidentsPerTypeChart(x) {
        const model = new ChartsBaseModel() ;
        model.data = x.nameValues;
        model.colorScheme.domain = [];
        x.nameValues.forEach((element: { color: string; }) => {
            model.colorScheme.domain.push(element.color);
        });
        model.title = 'Types of Incidents – Current Year';
        return model;
    }

    updateChart(chartData) {
        this.chartData = chartData;
    }

    onEmptyStateAddNewClick(event: any) {
        this.action.emit(event);
    }
}
