import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthErrorMessage } from '@constants/auth/auth-error-message.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { environment } from '@env/environment';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { AuthService } from '@services/auth.service';
import { ApiFactory } from '@services/core/api-factory.class';
import { NavigationService } from '@services/navigation.service';
import { SplitioService } from '@services/splitio.service';
import { isNullOrEmpty } from '@utilities/helpers';
import { EmailAddressValidatorAsync } from '@utilities/validators/email-address.validator';
import { ConvertApiErrorToAlertMessage } from '../helpers';

@Component({
    selector: 'jjkp-login',
    styleUrls: ['./login.component.scss'],
    templateUrl: './login.component.html',
})
export class LoginComponent extends ValidatableFormBaseDirective implements OnInit {

    termsOfUseUrl = environment.termsOfUseUrl;
    noticeAtCollectionUrl = environment.noticeAtCollectionUrl;

    refireRedirectCheck = false;
    alertText = '';
    showAlert = false;
    isProcessingRequest = false;
    forgotPasswordRoute = `/${AuthRoutes.ForgotPassword.urlRoute}`;
    addToSms = false;
    inviteToken: string;
    targetUrl: string;
    materialCode: string;
    accountNumber: string;
    promoCode : string;
    sms_under_maintenance = false;
    b2cOktaToggle = false;

    constructor(private formBuilder: UntypedFormBuilder,
                private navigator: NavigationService,
                private authService: AuthService,
                protected splitioService: SplitioService) {
        super();
    }

    async ngOnInit(): Promise<void> {
       
        if((await this.splitioService.getToggle(TOGGLES.SMS_UNDER_MAINTENANCE)) == TREATMENT.ON)
        {
            this.sms_under_maintenance = true;
        }
        this.b2cOktaToggle = (await this.splitioService.getToggle(TOGGLES.FORGOTPASSWORD_B2C_CREATE_OKTA)) === TREATMENT.ON;

        this.authService.redirectLoggedInUser();

        if (window.history.state) {
            if (window.history.state.addToSms) {
                this.addToSms = true;
                this.inviteToken = window.history.state.inviteToken;
                this.materialCode = window.history.state.materialCode;
                this.accountNumber = window.history.state.accountNumber;
                this.promoCode = window.history.state.promoCode;
            }
            this.targetUrl = window.history.state.targetUrl;
        }

        const alertMessage = window.localStorage.getItem('message');
        if(this.b2cOktaToggle && alertMessage) {
            this.alertText = alertMessage;
            this.showAlert = true;
            window.localStorage.removeItem('message');
        }
        this.buildForm();
    }

    navigateToSignUpPage() {
        this.navigator.navigateTo(AuthRoutes.Signup.urlRoute, true);
    }

    private buildForm() {
        const emailAddress = window.history.state && window.history.state.emailAddress ? window.history.state.emailAddress : null;

        this.form = this.formBuilder.group({
            email: [emailAddress, {validators: Validators.required, asyncValidators: EmailAddressValidatorAsync()}],
            password: [null, Validators.required]
        });

        if (!this.b2cOktaToggle) {
            this.form.get("email").statusChanges.subscribe(newStatus => {
                if (newStatus === 'VALID') {
                    this.showSpinnerAndRedirect()
                }
            });
        }
    }

    protected async submitForm(eventData?: any) {
        this.isProcessingRequest = true;
        const credentials = {
            emailAddress: this.getControl('email').value,
            password: this.getControl('password').value
        };

        if(window.location.href.includes('b2c')) {
            this.navigator.navigateTo(AuthRoutes.Login.urlRoute, true);
        }

        if (this.addToSms) {
            await this.authService.createIdentity(credentials, this.inviteToken, this.materialCode, this.accountNumber, this.promoCode).then(async () => {
                this.showResponseMessage(await this.authService.verifyOktaUser(credentials, this.targetUrl));
            })
            .catch(error => this.showResponseMessage(ConvertApiErrorToAlertMessage(error)))
            .finally(() => this.isProcessingRequest = false);
        }
        else {
            this.showResponseMessage(await this.authService.verifyOktaUser(credentials, this.targetUrl));
        }
    }

    async showResponseMessage(errorMessage){
        if (isNullOrEmpty(errorMessage)) return;
        if (errorMessage == AuthErrorMessage.LockedOut) {
            const rules = await this.authService.getPasswordRules();
            this.alertText = `Invalid password. The account has been locked for ${rules?.lockout?.autoUnlockMinutes} minutes.`;
        } else if (errorMessage == AuthErrorMessage.NotSetup) {
            this.alertText = `Please use the Forgot Password link to reset your credentials.`;
        } else {
            this.alertText = 'The username or password provided in the request are invalid.';
        }
        this.showAlert = true;
        this.isProcessingRequest = false;
    }

    async showSpinnerAndRedirect() {
        if (!this.isProcessingRequest) {
            this.isProcessingRequest = true;
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.Security)
            .addRouteHint("AuthProvider")
            .addDataEntry('emailAddress', encodeURIComponent(this.getControl('email').value))
            .addSuccessHandler((data) => {
                const credentials = {
                    emailAddress: this.getControl('email').value,
                    password: this.getControl('password').value,
                };
                this.isProcessingRequest = false;
                if (this.refireRedirectCheck) {
                    this.showSpinnerAndRedirect()
                    this.refireRedirectCheck = false;
                }
                else if (data.isRedirect === true) {
                    this.authService.requestLogin(credentials)
                }
            })
            .addErrorHandler((data) =>
            {
                this.isProcessingRequest = false;

                if (this.refireRedirectCheck) {
                    this.showSpinnerAndRedirect()
                    this.refireRedirectCheck = false;
                }
            })
            .removePaging()
            .buildAndSend();
        }
        else
        {
            this.refireRedirectCheck = true;
        }
    }
}
