import { CommonModule } from '@angular/common';
/**
 * This module makes use of APP_INITIALIZER to perform authentication
 * discovery and reroute the user if necessary before the app is fully loaded.
 */
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthForbiddenComponent } from '@components/auth-forbidden/auth-forbidden.component';
import { AuthUnauthorizedComponent } from '@components/auth-unauthorized/auth-unauthorized.component';
import { AzureLoginResponseComponent } from '@components/AzureLoginResponseComponent/azure-login-response.component';
import { SharedModule } from '@components/shared.module';
import { AuthRoutingModule } from '@pages/auth/auth-routing.module';
import { ForgotPasswordComponent } from '@pages/auth/forgot-password/forgot-password.component';
import { LoginOktaWidgetComponent } from '@pages/auth/login/login-okta-widget.component';
import { LoginSplitComponent } from '@pages/auth/login/login-split.component';
import { LoginComponent } from '@pages/auth/login/login.component';
import { ResetPasswordComponent } from '@pages/auth/reset-password/reset-password.component';
import { SignUpComponent } from '@pages/auth/signup/signup.component';
import { AuthService } from '@services/auth.service';

@NgModule({
    imports: [
        CommonModule,
        AuthRoutingModule,
        ReactiveFormsModule,
        SharedModule,
    ],
    declarations: [
        AuthForbiddenComponent,
        AuthUnauthorizedComponent,
        AzureLoginResponseComponent,
        LoginComponent,
        LoginOktaWidgetComponent,
        LoginSplitComponent,
        ForgotPasswordComponent,
        SignUpComponent,
        ResetPasswordComponent,
    ],
    providers: [AuthService],
})
export class AuthModule {}
