import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { getPropertyIfExists, isNullOrEmpty, isNullOrUndefined } from '@utilities/helpers';

@Component({
    selector: 'ag-grid-actions-custom-column',
    templateUrl: './ag-grid-actions-custom-column.component.html',
})
export class AGGridActionsCustomColumnComponent implements ICellRendererAngularComp {
    @Input() cell: any;
    @Output() onClicked = new EventEmitter<boolean>();

    showActionsMenu = false;
    groupingRow = false;

    // Complete list of parameters, provided by the grid
    public params: any;
    public componentParent: any;
    // Use this to configure component appearance and behavior based on custom parameters
    customColumnParams: any;
    // Contains the row datasource
    row: any;

    generalmenuPermissions: any[] = [];

    constructor() {
    }

    agInit(params: any): void {
        this.params = params;
        this.componentParent = this.params.context.componentParent;
        this.row = this.params.data;

        if (!isNullOrUndefined(params.customColumnParams)) {
            const _customColumnParams = params.customColumnParams;

            this.generalmenuPermissions = [...new Set(_customColumnParams.map(x => x.authFeature))];
            this.generalmenuPermissions = this.generalmenuPermissions.filter((v, i) => (this.generalmenuPermissions.indexOf(v) === i));

            this.generalmenuPermissions.forEach(element => {
                const show = this.checkpermissions(element);

                _customColumnParams.map(x => {
                    if (!isNullOrUndefined(x.authFeature) && x.authFeature === element) {
                        x['visible'] = show;
                    }
                });
            });

            _customColumnParams.map((ccp) => {
                if (ccp.visible && !isNullOrUndefined(ccp.shouldDisplay)) {
                    const displayConfig = ccp.shouldDisplay.split(':');
                    const column = displayConfig[0];
                    const flag = displayConfig.length > 1 ? displayConfig[1] : '';

                    const value = this.getRowValue(this.row, column).toString();

                    if (flag !== '') {
                        if (flag.indexOf(',') >= 0) {
                            const displayconditions = flag.split(',');

                            if (!displayconditions.find((x) => x === value)) {
                                ccp.visible = false;
                            }
                        } else {
                            if (value !== flag) {
                                ccp.visible = false;
                            }
                        }
                    }
                }
                if (ccp.visible && !isNullOrUndefined(ccp.shouldDisplayExpression)) {
                    ccp.visible = ccp.shouldDisplayExpression(this.row);
                }
            });

            this.customColumnParams = JSON.parse(JSON.stringify(_customColumnParams));
            this.showActionsMenu = this.customColumnParams.find(t => t.visible === true);
            this.groupingRow = this.params.node.group;
        }
    }

    checkpermissions(feature) {
        const split = feature.toString().split(',');
        const domain = split[0];
        const permissionType = (split.length > 1) ? split[1] : '';
        const ignoreGroup = (split.length > 2) ? split[2] === 'ignoreGroup' : false;
        const companyDataGroupingEnabled = (split.length > 2) ? split[2] === 'companyDataGroupingEnabled' : false;

        return LoggedInUserInfo.Instance.userInfo.checkAuthFeature(domain, permissionType, this.params.data, false, ignoreGroup, companyDataGroupingEnabled);
    }

    refresh(): boolean {
        return true;
    }

    getRowValue(row: any, property: string) {
        return getPropertyIfExists(row, property);
    }

    onChildComponentActionEmit(action: any) {
        this.params['ChildComponentAction'] = action;
        this.params.action(this.params);

        // Takes from Params the Selected Action
        const selectedAction = this.customColumnParams.find(t => t.displayText === action);

        // Checks if the element has elements that should be saved in the localstorage
        if (!isNullOrEmpty(selectedAction.localStorageParams)) {

            // builds an array with the parameters to save
            const paramsToSave = [];
            selectedAction.localStorageParams.map((x) => {
                const params = {
                    name: x,
                    value: this.params.data[x],
                }
                paramsToSave.push(params);
            })

            // Saves the parameters in localStorage
            this.storeParams(action, paramsToSave);
        }
    }

    storeParams(key, paramsToSave) {
        const jsonGrid = JSON.stringify(paramsToSave);
        window.localStorage.setItem(key, jsonGrid);
    }
}
