export enum Gender {
  Female = '64e96df7-4561-467f-b0d6-24600366ada2',
  Male = '039930ac-ea23-4f8b-919e-342861acce11',
  Other = '4202c01e-103d-409d-b380-3d27694a1e2f',
  Unknown = '8f790bf5-ffc3-47c4-a6ef-9084bebef6af',
}

export const GenderMap = new Map([
  [Gender.Female, 'Female'],
  [Gender.Male, 'Male'],
  [Gender.Other, 'Other'],
  [Gender.Unknown, 'Unknown'],
]);
