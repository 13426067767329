import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageBusService } from 'app/modules/messaging/services/messageBusService';
import { Subscription } from 'rxjs';
import { UserTypes } from '@constants/enums/user-type.enum';
import { ACTIONS, TOPICS } from '@constants/messages.constants';
import { environment } from '@env/environment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { EnableMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageTypeEnum } from '@modules/messaging/baseClasses/MessageTypeEnum';
import { LoggerService } from '@services/core/logger-service.class';
import { ImpersonationService } from '@services/impersonation.service';
import { MenuService } from '@services/menu.service';

@Component({
    selector: 'layout-header',
    templateUrl: './layout-header.component.html',
    styleUrls: ['./layout-header.component.scss'],
})
export class LayoutHeaderComponent extends EnableMessageProcessingDirective implements OnInit, OnDestroy {
    isAuthorized: boolean;
    authorized$: Subscription;
    cntrlIsPressed: boolean;
    isNonAuth: boolean;
    siteLogo: string;
    isIE: boolean;

    constructor(public menuService: MenuService, mb: MessageBusService, public impersonationService: ImpersonationService) {
        super(mb);
        $(document).on('keydown', (event) => {
            if (event.key === 'Control') {
                this.cntrlIsPressed = true;
            }
        });
        $(document).on('keyup' , () => {
            this.cntrlIsPressed = false;
        });
        this.isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
    }

    protected configureMessageBus(builder: IMsgProcessingReg): void {
        builder
            .add()
            .inBoundDataTopic(TOPICS.APPLEVEL.SYSTEM)
            .listenForMessageType(MessageTypeEnum.SystemEventMessage)
            .withAction(ACTIONS.AUTH.AUTH_LOGIN_SUCCESS)
            .thenCallFunction((f) => this.updateUserData());
    }

    updateUserData() {
        this.isAuthorized = LoggedInUserInfo.Instance.isAuthorized;
        this.isNonAuth = LoggedInUserInfo.Instance.userInfo.isNonAuth;
        this.siteLogo = LoggedInUserInfo.Instance.userInfo.logoUrl;
    }

    async ngOnInit() {
        super.ngOnInit();
        this.updateUserData();
    }

    ngOnDestroy() {
        if (this.authorized$) {
            this.authorized$.unsubscribe();
        }
    }

    updateUrl() {
        this.siteLogo = null;
    }

    login() {
        // convert to message
        const msg = this.msgBuilder.SystemEventMessage().usingHostSenderId().then.proceedWithoutData.assignAction(ACTIONS.AUTH.AUTH_REQUEST_LOGIN).sendOn.systemTopic().build();
        this.sendTopicMessage(msg);
    }

    logout() {
        // convert to message
        const msg = this.msgBuilder.SystemEventMessage().usingHostSenderId().then.proceedWithoutData.assignAction(ACTIONS.AUTH.AUTH_REQUEST_LOGOUT).sendOn.systemTopic().build();
        this.sendTopicMessage(msg);
    }

    toggleSideNav() {
        this.menuService.toggleSideNav();
    }

    openImpersonatingModal(event: Event) {
        if (this.cntrlIsPressed
            && (LoggedInUserInfo.Instance.userInfo.user || LoggedInUserInfo.Instance.userInfo.mainUser)
            && (LoggedInUserInfo.Instance.userInfo.user.userType || LoggedInUserInfo.Instance.userInfo.mainUser.userType)
            && ((LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.SupportUser || LoggedInUserInfo.Instance.userInfo.mainUser.userType === UserTypes.SupportUser) || (LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.SuperAdmin || LoggedInUserInfo.Instance.userInfo.mainUser.userType === UserTypes.SuperAdmin)
            || (LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.ProductOwner || LoggedInUserInfo.Instance.userInfo.mainUser.userType === UserTypes.ProductOwner))) {
            ($('#impersonatingModal') as any).modal('show');
            this.cntrlIsPressed = false;
            LoggerService.trace('trace', LoggedInUserInfo.Instance.userInfo);
            LoggerService.trace('trace', LoggedInUserInfo.Instance.userInfo.user);
            LoggerService.trace('trace', LoggedInUserInfo.Instance.userInfo.mainUser);
            // event.stopPropagation();
        }
    }

    showImpersonationEndModal() {
        ($('#impersonationEndModal') as any).modal('show');
    }

    get canImpersonate() {
        if (LoggedInUserInfo.Instance.userInfo.mainUser && LoggedInUserInfo.Instance.userInfo.impersonation) {
            if (LoggedInUserInfo.Instance.userInfo.impersonationId !== '' && (LoggedInUserInfo.Instance.userInfo.mainUser.userType === UserTypes.SupportUser || LoggedInUserInfo.Instance.userInfo.mainUser.userType === UserTypes.SuperAdmin || LoggedInUserInfo.Instance.userInfo.mainUser.userType === UserTypes.ProductOwner)) {
                return true;
            }
        }
        return false;
    }

    get userName() {
        return LoggedInUserInfo.Instance.userInfo.user.userName || LoggedInUserInfo.Instance.userInfo.user.email;
    }
}
