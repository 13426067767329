<div class="settings-tool-panel-wrapper">
  <div class="topFloat">
    <div class="topButtonList">
      <div class="linkWrapper">
        <a [hidden]="filtersShowing" (click)="onShowFloatingFilter()" class="eye-icon" aria-hidden="true"
          data-bs-toggle="tooltip" data-bs-placement="top" data-bs-boundary="window" title="Show Filters"></a>
        <a [hidden]="!filtersShowing" (click)="onShowFloatingFilter()" class="eye-slash-icon" aria-hidden="true"
          data-bs-toggle="tooltip" data-bs-placement="top" data-bs-boundary="window" title="Hide Filters"></a>
      </div>

      <p class="pipe">|</p>

      <div class="linkWrapper">
        <a *ngIf="isColumnFilterPresent" (click)="onResetFilters()" class="clearFilter-icon-active" aria-hidden="true"
          data-bs-toggle="tooltip" data-bs-placement="top" data-bs-boundary="window" title="Clear Filters"></a>
        <i *ngIf="!isColumnFilterPresent" class="clearFilter-icon-inactive" aria-hidden="true"
          data-bs-toggle="tooltip" data-bs-placement="top" data-bs-boundary="window" title="Clear Filters"></i>
      </div>

      <p class="pipe">|</p>

      <div *ngIf="gridColumnsChanged" class="linkWrapper"><a (click)="onResetGridState()" class="resetFilter-icon-active" aria-hidden="true"
        data-bs-toggle="tooltip" data-bs-placement="top" data-bs-boundary="window" title="Reset"></a></div>
      <div *ngIf="!gridColumnsChanged" class="linkWrapper"><i class="resetFilter-icon-inactive" aria-hidden="true"
        data-bs-toggle="tooltip" data-bs-placement="top" data-bs-boundary="window" title="Reset"></i></div>

      <p class="pipe" *ngIf="!params.hideSaveViews">|</p>

      <div class="linkWrapper" *ngIf="!params.hideSaveViews"><a class="save-icon" aria-hidden="true" (click)="onSaveView()" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-boundary="window" title="Save View"></a></div>
    </div>

    <div class="viewsTitle" *ngIf="!params.hideSaveViews">
      <h2>Saved Views</h2>
      <hr>
    </div>
  </div>

  <div class="viewsList" [ngClass]="isViewLoading ? 'disabled-views-list' : ''" *ngIf="!params.hideSaveViews">
    <div class="viewsLineItem" *ngFor="let view of views" [hidden]="isLoading">
      <a class="edit-icon" aria-hidden="true" (click)="onEditView(view.id)"></a>
      <a class="flex-grow" (click)="onViewClicked(view.id)">{{view.name}} <ng-container *ngIf="view.isDefault"> (<strong class="default-word">default</strong>)</ng-container></a>
      <a class="delete-icon" aria-hidden="true" (click)="onDeleteView(view.id)"></a>
    </div>
  </div>

  <div *ngIf="!params.hideSaveViews && !isLoading && !views?.length" class="view-list-overlay">Save a <a (click)="onSaveView()"><strong>New View</strong></a></div>
  <div *ngIf="isLoading && !params.hideSaveViews" class="view-list-overlay"><i class="fa-solid fa-spinner fa-lg fa-spin"></i> Loading</div>
</div>

<modal-window [id]="editOrSaveModalId" [title]="currentEditedGridViewId ? 'Edit' : 'Save'" [confirmButtonText]="'Save'"
    (onConfirm)="onSaveViewConfirm()" (onCancel)="onCancelSaveView()" (onX)="onCancelSaveView()" [autoClose]="false">
  <form [formGroup]="viewSaveForm">
    <div class="form-group" [ngClass]="showErrors && viewSaveForm.get('name').errors?.required ? 'form-group-error' : ''">
      <label class="form-label" for="name"><strong class="req">View Name</strong></label>
      <input type="text" id="name" name="name" class="form-control" maxlength="100" formControlName="name" required [ngClass]="this.showErrors && viewSaveForm.get('name').errors?.required ? 'field-error' : ''" autocomplete="off">
      <div class="alert alert-danger" *ngIf="this.showErrors && viewSaveForm.get('name').errors?.pattern">
        Valid characters are A-Z a-z 0-9 ,._-
      </div>
    </div>

    <div class="form-check">
      <input type="checkbox" class="form-check-input" id="isDefault" name="isDefault" formControlName="isDefault" ng-trim="false" />
      <label for="isDefault" class="form-check-label">
        Default View
      </label>
    </div>
  </form>
</modal-window>

<jjk-delete #deleteViewModal modalId="ViewDeleteModal" entityType="GridView" (onDeleteSuccessful)="onDeleteCompleted($event)" (onDeleteFailed)="onDeleteFailed($event)"
            entityNameOverride="saved view" additionalMessageAfterEntityName="from this grid" additionalMessageBeforeActionWord="permanently">
</jjk-delete>

<!-- Reset Modal Window -->
<modal-window [id]="'resetModal'" [title]="'Reset View'" [cancelButtonText]="'Close'" [confirmButtonText]="'Confirm'" (onConfirm)="onResetConfirm()" (onCancel)="onCancelReset()" [autoClose]="true">
  <span>  All columns and filters will be reset to the default view.  </span>
</modal-window>

<!-- Clear Modal Window -->
<modal-window [id]="'clearFiltersModal'" [title]="'Clear Filters'" [cancelButtonText]="'Close'" [confirmButtonText]="'Confirm'" (onConfirm)="onClearConfirm()" (onCancel)="onCancelClear()" [autoClose]="true">
  <span>  All applied filters will be removed.  </span>
</modal-window>
