import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'jjkp-enrollment-count-card',
  templateUrl: './enrollment-count-card.component.html',
  styleUrls: ['./enrollment-count-card.component.scss']
})
export class EnrollmentCountCardComponent {

    @Input() public enrollmentsExpiringSoonCount = 0;
    @Input() public remainingEnrollments = 0;
    @Input() public disableNavigation = false;
    @Input() public color;

    @Output() onCardClick: EventEmitter<any> = new EventEmitter();

    constructor(private router: Router) { }

    cardClicked() {
        this.router.navigate(["/training/enrollment_orders"]);
    }

}
