import { ApiMessage } from 'app/modules/messaging/types/Messages/apiMessage';
import { NgZone, Injectable } from '@angular/core';
import { JJKellerApiResponse } from '@models/api/JJKellerApiResponse.model';
import { ApiPreProcessingHandler } from './ApiPreProcessingHandler';
import { ApiPostProcessHandler } from './ApiPostProcessHandler';
import { ApiActionsEnum } from '@constants/enums/api-actions.enum';
import { AutoIncrementingIdentifierDirective } from '@modules/messaging/baseClasses/AutoIncrementingIdentifier';

@Injectable()
export class ApiPrePostProcessingMiddleware extends AutoIncrementingIdentifierDirective {
    public registeredPreProcessors = new Map<ApiPreProcessingHandler, string>();
    public registeredPostProcessors = new Map<ApiPostProcessHandler, string>();

    public addPreMsgProcessingHandler(handler: ApiPreProcessingHandler, friendlyName = '') {
        // we have it already, so leave
        if (this.registeredPreProcessors.has(handler)) { return; }

        // its new, so register it.
        this.registeredPreProcessors.set(handler, friendlyName);
    }

    public removePreMsgProcessingHandler(handler: ApiPreProcessingHandler) {
        if (!this.registeredPreProcessors.has(handler)) { return; }

        const name = this.registeredPreProcessors.get(handler);
        this.registeredPreProcessors.delete(handler);
    }

    public addPostMsgProcessingHandler(handler: ApiPostProcessHandler, friendlyName = '') {
        // we have it already, so leave
        if (this.registeredPostProcessors.has(handler)) { return; }

        // its new, so register it.
        this.registeredPostProcessors.set(handler, friendlyName);
    }

    public removePostMsgProcessingHandler(handler: ApiPostProcessHandler) {
        if (!this.registeredPostProcessors.has(handler)) { return; }

        const name = this.registeredPostProcessors.get(handler);
        this.registeredPostProcessors.delete(handler);
    }

    public distributeToPreProcessors(m: ApiMessage, a: ApiActionsEnum): ApiMessage {
        let msgAsOfLastUpdate: ApiMessage = m;
        let msgOut: ApiMessage = m;
        this.zone.runTask(() => {
            this.registeredPreProcessors.forEach((v, k) => {

                // There is no break in forEach, so this says skip until done
                if (msgAsOfLastUpdate === null) { return; }

                try { msgOut = k(msgAsOfLastUpdate, a); } catch (e) {
                    this.registeredPreProcessors.delete(k);
                    // we want to try the next processor, so returning keeps the last message processor state loaded
                    return;
                }

                if (msgOut === null) {
                    return;
                }

                msgAsOfLastUpdate = msgOut;
            });
        });
        return msgAsOfLastUpdate;
    }

    public distributeToPostProcessors(successData: any, action: ApiActionsEnum): any {
        let responseAsOfLastUpdate: JJKellerApiResponse = successData;
        let responseOut: JJKellerApiResponse = successData;
        this.zone.runTask(() => {
            this.registeredPostProcessors.forEach((v, k) => {

                // There is no break in forEach, so this says skip until done
                if (responseAsOfLastUpdate === null) { return; }

                try { responseOut = k(responseAsOfLastUpdate, action); } catch (e) {
                    this.registeredPostProcessors.delete(k);
                    // we want to try the next processor, so returning keeps the last message processor state loaded
                    return;
                }

                if (responseOut === null) {
                    return;
                }

                responseAsOfLastUpdate = responseOut;
            });
        });
        return responseAsOfLastUpdate;
    }

    constructor(private zone: NgZone) {
        super();
    }
}
