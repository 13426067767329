import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthErrorMessage } from '@constants/auth/auth-error-message.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { environment } from '@env/environment';
import { PasswordRequirements } from '@models/auth/password-requirements.model';
import { PasswordRuleViewModel } from '@models/auth/rule.model';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { AuthService } from '@services/auth.service';
import { ApiFactory } from '@services/core/api-factory.class';
import { SplitioService } from '@services/splitio.service';
import { focusOn } from '@utilities/helpers';
import { EmailAddressValidatorAsync } from '@utilities/validators/email-address.validator';
import { PasswordValidators } from '@utilities/validators/password-validators';
import { ConvertApiErrorToAlertMessage, GetPasswordRulesAndValidation } from '../helpers';

@Component({
    selector: 'jjk-signup',
    styleUrls: ['./signup.component.scss'],
    templateUrl: './signup.component.html',
})
export class SignUpComponent extends ValidatableFormBaseDirective {

    @Output() emailText = new EventEmitter<string>();

    termsOfUseUrl = environment.termsOfUseUrl;
    noticeAtCollectionUrl = environment.noticeAtCollectionUrl;

    passwordRules: PasswordRequirements;
    aError: AuthErrorMessage;
    showSignUp = true;
    isCreatePassword = false;
    isEnterPassword = false;
    isTokenRequired = false;
    isOkta = false;
    isNotRegistered = false;
    isJjkellerAccount = false;
    isUnifiedLogin = false;
    isUserAlreadyRegistered = false;
    showErrors = false;
    skipCheck = false;
    userEmail: any;
    userPassword: any;
    userPassword2: any;
    rules: PasswordRuleViewModel[];
    rulesAreLoaded: boolean;
    ruleValidators: ValidatorFn[];
    showAlert: boolean;
    alertText: string;
    signUpError: string;
    inviteToken: string;
    targetUrl: string;
    materialCode: string;
    accountNumber: string;
    promoCode : string;
    oktaProducts: string;
    isProcessingRequest = false;
    isJjkMigration = false;
    associatedWithOtherProducts = 'The email you have provided is associated with another J. J. Keller & Associates, Inc. product.';
    alertStyle = 'Error';
    showErrorValidation = false;
    loginUrl = `/${AuthRoutes.Login.urlRoute}`;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private splitioService: SplitioService,
        private router: Router) {
        super();
    }

    private buildForm() {
        if (window.history.state && window.history.state.emailAddress && window.history.state.emailAddress.toLowerCase().endsWith('@jjkeller.com')) {
            this.userEmail = window.history.state.emailAddress;
            this.isJjkMigration = true;
            this.goToSignup();
        }

        this.ruleValidators.push(Validators.required);
        this.form = this.formBuilder.group({
            email: [this.userEmail ?? null, { validators: Validators.required, asyncValidators: EmailAddressValidatorAsync() }],
            newPassword: [null, this.ruleValidators],
            passwordConfirm: [null, { validators: Validators.required }],
            oktaPassword: [null, { validators: Validators.required }],
        }, {
            validators: [PasswordValidators.PasswordMatchValidator('newPassword', 'passwordConfirm')],
        });
    }

    get newPasswordValidationErrors(): ValidationErrors {
        return this.form ? this.getControl('newPassword').errors : null;
    }

    async ngOnInit(): Promise<void> {
        if((await this.splitioService.getToggle(TOGGLES.OKTA_UNIFIED_LOGIN)) == TREATMENT.ON)
        {
            this.isUnifiedLogin = true;
        }
        await this.authService.getPasswordRules()
            .then(data => this.rules = GetPasswordRulesAndValidation(data))
            .catch((errorMessage) => this.showError(errorMessage));
        this.ruleValidators = this.rules.filter(r => r.validator).map(r => r.validator);
        this.setPasswordRules();
        this.checkForParameters();
        this.buildForm();
    }

    showError(message: string, error = true) {
        this.alertText = message;
        this.alertStyle = error ? 'Error' : '';
        this.showAlert = true;
    }

    protected async submitForm() {
        const credentials = {
            emailAddress: this.getControl('email').value,
            password: this.isCreatePassword ? this.getControl('newPassword').value : this.getControl('oktaPassword').value
        };
        this.showErrorValidation = false;
        if (!this.isJjkMigration) {
            this.createIdentity(credentials);
        }
        else {
            this.migrateJjkUser(credentials);
        }
    }

    async createIdentity(credentials: any) {
        this.isProcessingRequest = true;
        await this.authService.createIdentity(credentials, this.inviteToken, this.materialCode, this.accountNumber, this.promoCode).then(async () => {
            await this.authService.verifyOktaUser(credentials, this.targetUrl);
        })
        .catch(error => this.handleErrorMessage(error))
        .finally(() => this.isProcessingRequest = false);
    }

    async migrateJjkUser(credentials: any) {
        this.isProcessingRequest = true;
        await new Promise<void>((resolve, reject) => {
            ApiFactory.saveNewEntity(ApiEntityTypesEnum.Security, credentials)
                .addRouteHint('Migrate')
                .addSuccessHandler(() => {
                    resolve();
                })
                .addErrorHandler((error: any) => {
                    reject(error);
                })
                .buildAndSend();
        }).then(async () => {
            await this.authService.verifyOktaUser(credentials, this.targetUrl);
        })
        .catch(error => this.handleErrorMessage(error))
        .finally(() => this.isProcessingRequest = false);
    }

    handleErrorMessage(apiErrorMessage: AuthErrorMessage) {
        this.signUpError = (ConvertApiErrorToAlertMessage(apiErrorMessage));
    }

    emailTextChange(data) {
        this.showSignUp = true;
        this.isCreatePassword = false;
        this.isEnterPassword = false;
        this.isTokenRequired = false;
        this.isOkta = false;
        this.isNotRegistered = false;
        this.isUserAlreadyRegistered = false;
        this.isJjkellerAccount = false;
        this.showAlert = false;
        this.skipCheck = this.isProcessingRequest;
        this.userEmail = data;
        this.signUpError = '';
        this.form.get('newPassword').setValue(null);
        this.form.get('newPassword').updateValueAndValidity();
        this.form.get('passwordConfirm').setValue(null);
        this.form.get('passwordConfirm').updateValueAndValidity();
    }

    setPassword(data) {
        this.userPassword = data;
    }

    setPassword2(data) {
        this.userPassword2 = data;
    }

    async setPasswordRules() {
        this.passwordRules = await this.authService.getPasswordRules();
    }

    login() {
        this.router.navigate(['/dashboard']);
    }

    async checkForParameters() {
            this.showSignUp = true;
            this.inviteToken = this.activatedRoute.snapshot.queryParams['invite'];
            this.targetUrl = this.activatedRoute.snapshot.queryParams['tu'];
            this.materialCode = this.activatedRoute.snapshot.queryParams['mc'];
            this.accountNumber = this.activatedRoute.snapshot.queryParams['an'];
            this.promoCode = this.activatedRoute.snapshot.queryParams['pc'];
    }

    async next() {
        if (!this.getControl('email').valid) {
            this.showErrors = true;
            focusOn('email', true);
            return;
        }

        if (!this.isOkta) {
            this.isProcessingRequest = true;
            await this.authService.checkIfOktaUser(this.userEmail)
                .then((data) => {
                    if (!this.skipCheck) {
                        if (!data.id) {
                            if (!data.inviteTokenRequired || this.inviteToken) {
                                if (this.userEmail.toLowerCase().endsWith('@jjkeller.com')) {
                                    this.isJjkellerAccount = true;
                                    this.isNotRegistered = true;
                                    if (this.isUnifiedLogin) {
                                        this.isCreatePassword = true;
                                    }
                                }
                                else {
                                    this.isCreatePassword = true;
                                }
                            }
                            else {
                                this.isTokenRequired = true;
                                this.showError('Please, look in your email inbox for an invitation.', false);
                            }
                        }
                        else {
                            this.isOkta = true;
                            if (!data.products.includes('Safety Management Suite')) {
                                this.isNotRegistered = true;
                                this.isEnterPassword = true;
                                if (this.userEmail.toLowerCase().endsWith('@jjkeller.com')) {
                                    this.isJjkellerAccount = true;
                                }
                                else {
                                    this.oktaProducts = data.products.join(', ');
                                    if (data.products.length > 1) {
                                        const products = `${this.oktaProducts.substring(0, this.oktaProducts.lastIndexOf(','))} and ${this.oktaProducts.substring(this.oktaProducts.lastIndexOf(',') + 2)}`;
                                        this.oktaProducts = products;
                                        this.associatedWithOtherProducts = 'The email you have provided is associated with other J. J. Keller & Associates, Inc. products.'
                                    }
                                }
                            }
                            else {
                                this.showError('User is already registered.');
                                this.isUserAlreadyRegistered = true;
                            }
                        }
                        if (this.isEnterPassword) {
                            this.form.get('newPassword').clearValidators();
                            this.form.get('newPassword').updateValueAndValidity();
                            this.form.get('passwordConfirm').clearValidators();
                            this.form.get('passwordConfirm').updateValueAndValidity();
                            this.form.get('oktaPassword').setValidators(Validators.required);
                            this.form.get('oktaPassword').updateValueAndValidity();
                            this.form.clearValidators();
                            this.form.updateValueAndValidity();
                        } else {
                            this.form.get('oktaPassword').clearValidators();
                            this.form.get('oktaPassword').updateValueAndValidity();
                            this.form.get('newPassword').setValidators(this.ruleValidators);
                            this.form.get('newPassword').updateValueAndValidity();
                            this.form.get('passwordConfirm').setValidators(Validators.required);
                            this.form.get('passwordConfirm').updateValueAndValidity();
                            this.form.setValidators(PasswordValidators.PasswordMatchValidator('newPassword', 'passwordConfirm'));
                            this.form.updateValueAndValidity();
                        }
                    }
                    else {
                        this.skipCheck = false;
                    }
                })
                .catch((errorMessage) => {
                    this.showError(errorMessage);
                });
            this.isProcessingRequest = false;
        }
    }

    goToSignup() {
        this.isJjkellerAccount = false;
        this.isNotRegistered = false;
        this.isOkta = false;
        this.isCreatePassword = true;
    }
}
