import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { getPropertyIfExists, isNullOrEmpty, isNullOrUndefined } from '@utilities/helpers';
import { FeatureAuthService } from '@services/feature-auth.services';
import { BuildRouterLinkPipe } from '@utilities/pipes/build-router-link.pipe';

@Component({
    selector: 'ag-grid-actions-with-submenu-column',
    templateUrl: './ag-grid-actions-with-submenu-column.component.html',
    styleUrls: ['./ag-grid-actions-with-submenu-column.component.scss'],
})
export class AGGridActionsWithSubmenuColumnComponent implements ICellRendererAngularComp {
    @Input() cell: any;
    @Output() onClicked = new EventEmitter<boolean>();

    showActionsMenu = false;
    groupingRow = false;
    isTouchEvent = false;

    // Complete list of parameters, provided by the grid
    public params: any;
    public componentParent: any;
    // Use this to configure component appearance and behavior based on custom parameters
    customColumnParams: any;
    // Contains the row datasource
    row: any;

    generalmenuPermissions: any[] = [];
    cloneHTMLShow: boolean;
    subMenu: any;

    constructor(private featureAuth: FeatureAuthService) {

    }

    agInit(params: any): void {
        this.params = params;
        this.componentParent = this.params.context.componentParent;
        this.row = this.params.data;
        if (!isNullOrUndefined(params.customColumnParams)) {
            let _customColumnParams = params.customColumnParams;
            this.generalmenuPermissions = [...new Set(_customColumnParams.map(x => x.authFeature))];
            this.generalmenuPermissions = this.generalmenuPermissions.filter((v, i) => (this.generalmenuPermissions.indexOf(v) === i));

            params.customColumnParams.forEach(element => {
                const show = !element.authFeature.includes('view') ? this.featureAuth.hasAccess(element.authFeature, false, params.data) : true;
                if(show){
                    _customColumnParams.map(x => {
                        if (!isNullOrUndefined(x.authFeature) && x.authFeature === element.authFeature) {
                            x['visible'] = show;
                        }
                    });
                }
                else{
                    _customColumnParams = _customColumnParams.filter(i => i.authFeature != element.authFeature);
                }

            });

            _customColumnParams.map((ccp) => {
                if(!isNullOrUndefined(ccp.subMenu)){
                    ccp.subMenu.map((subc) => {
                        if(!isNullOrUndefined(subc.shouldSubDisplayExpression)){
                            subc.visible = subc.shouldSubDisplayExpression(this.row);
                        }
                        if (!isNullOrUndefined(subc.getField)) {
                            subc.field = subc.getField(this.row);
                        }
                        if (!isNullOrUndefined(subc.getField2)) {
                            subc.field2 = subc.getField2(this.row);
                        }
                    })
                }

                if (ccp.visible && !isNullOrUndefined(ccp.shouldDisplay)) {
                    const displayConfig = ccp.shouldDisplay.split(':');
                    const column = displayConfig[0];
                    const flag = displayConfig.length > 1 ? displayConfig[1] : '';

                    const value = this.getRowValue(this.row, column).toString();

                    if (flag !== '') {
                        if (flag.indexOf(',') >= 0) {
                            const displayconditions = flag.split(',');

                            if (!displayconditions.find((x) => x === value)) {
                                ccp.visible = false;
                            }
                        } else {
                            if (value !== flag) {
                                ccp.visible = false;
                            }
                        }
                    }
                }

                if (!isNullOrUndefined(ccp.getField)) {
                    ccp.field = ccp.getField(this.row);
                }
                if (!isNullOrUndefined(ccp.getField2)) {
                    ccp.field2 = ccp.getField2(this.row);
                }

                if (ccp.visible && !isNullOrUndefined(ccp.shouldDisplayExpression)) {
                    ccp.visible = ccp.shouldDisplayExpression(this.row);
                }
            });

            this.customColumnParams = JSON.parse(JSON.stringify(_customColumnParams));
            this.showActionsMenu = this.customColumnParams.find(t => t.visible === true);
            this.groupingRow = this.params.node.group;
        }
    }

    refresh(): boolean {
        return true;
    }

    getRowValue(row: any, property: string) {
        return getPropertyIfExists(row, property);
    }

    onMouseclick($event) {
        this.onMouseover($event);
    }

    onMouseover($event) {
        if ($event.srcElement.lastElementChild) {
            if ($event.srcElement.lastElementChild.className == "dropdown-submenu-item") {
                $event.srcElement.lastElementChild.style.display = "block";
            }
        }
    }

    onMouseout($event) {
        //Only do this on desktop
        if (!this.isTouchEvent) {
            //Hacky Hack McHackster
            $event.srcElement.lastElementChild.style.display = "none";
        }
    }

    touchDown(event: any) {
        this.isTouchEvent = true;
        // handle event without applying class
      }

    onChildComponentActionEmit(action: any, dropdownItem: any = null, subAction: any = null) {
        this.params['ChildComponentAction'] = action;
        if(dropdownItem != null){
            const link = new BuildRouterLinkPipe().transform(this.params.data, dropdownItem);
            this.params['actionLink'] = link;
        }
        if(subAction != null){
            this.params['subAction'] = subAction;
        }
        this.params.action(this.params);

        // Takes from Params the Selected Action
        const selectedAction = this.customColumnParams.find(t => t.displayText === action);

        // Checks if the element has elements that should be saved in the localstorage
        if (!isNullOrEmpty(selectedAction.localStorageParams)) {

            // builds an array with the parameters to save
            const paramsToSave = [];
            selectedAction.localStorageParams.map((x) => {
                const params = {
                    name: x,
                    value: this.params.data[x],
                }
                paramsToSave.push(params);
            })

            // Saves the parameters in localStorage
            this.storeParams(action, paramsToSave);
        }
    }

    storeParams(key, paramsToSave) {
        const jsonGrid = JSON.stringify(paramsToSave);
        window.localStorage.setItem(key, jsonGrid);
    }
}
