export enum IncidentType {
    RecordableIncident = '13e7fce1-fd47-4b15-a5bd-b1f3180f13f3',
    OtherRecordableCase = '27b5931b-e8bb-4c47-9d90-29d17b377eac',
    NearMiss = '58392508-40fe-4bdd-8fa5-5ac1fb9e1a22',
    FirstAid = '767de1af-4b0f-4c89-a485-dfb37246e2b9',
    OtherNonRecordableIncident = 'e2082493-9fe0-407e-9c1c-7d74ed21ed4c',
    SafetyObservation = '18d80c7f-63c4-4eac-a37f-d58272e3cd0d',
    PropertyDamage = '68c9323e-52b7-4b5e-a572-fdb263fc4dd3',
    EquipmentFailure = 'abe2732b-af9d-4dea-9423-bfaabf30e4f0',
}
