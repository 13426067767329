import { EditorialContentTypeEnum } from '@constants/editorial/editorial-content-type.enum';
import { EditorialProductEnum } from '@constants/editorial/editorial-product.enum';
import { EditorialTemplateTypeEnum } from '@constants/editorial/editorial-template-type.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { Filter } from '@models/advanced-filter/filter.model';
import { EditorialMetaDataIndexContent } from '@models/entity-models/editorial-asa/editorial-metadata-index-content.model';
import { NewsGridViewModel } from '@models/entity-models/news/news-grid-view.model';
import { FilterExpressionBuilder, IFilterDataOnly } from '@services/core/models/Filter-Entry';
import { dateToDateString, isValidArray } from './helpers';

/**
 * Returns a array of object containing EditorialASA Facet Data
 * @param facetData The supplied facet to convert to id/displayText objects
 * @param idIsNumber Boolean value to indicate whether the facet id is a number or string. Default = true
 */
export function GetFacetData(facetData: string[], idIsNumber = true): any [] {
    const data = [];

    if (facetData === null || facetData === undefined) { return data; }

    facetData.forEach((facet) => {
        const facetPieces = facet.split('^');
        const id = idIsNumber ? +facetPieces[0] : facetPieces[0];

        data.push({ id, displayText: facetPieces[1] });
    });

    return data;
}

/**
 * Returns the string that can be used in the Facets filter expression of a request to the EditorialAsaController
 * @param filters Filters array of items
 */
export function getFacetFilterString(filters: Filter[]): string {
    let filterString = '';
    for (let i = 0; i < filters.length; i++) {
        if (i > 0) {
            filterString += ' and ';
        }
        if (filters[i].operator === 'Does Not Contain') {
            filterString += 'not ';
        }
        filterString += `${filters[i].fieldName}/any(i: search.in(i, '${(filters[i].filterValue as string[]).join(',')}'))`;
    }
    return filterString;
}

export function getMiniGridSelectFilter(): IFilterDataOnly[] {
    return FilterExpressionBuilder.For(ApiEntityTypesEnum.EditorialAsa)
    .Use('Select', ParameterTypeEnum.String)
    .Equal(`ID,Type,Title,PublishDate,ProductID,Product,Product_Facets,Promoted`)
    .Build().AsExpression;
}

export function getMiniGridOrderFilter(): IFilterDataOnly[] {
    return FilterExpressionBuilder.For(ApiEntityTypesEnum.EditorialAsa)
    .Use('OrderBy', ParameterTypeEnum.String)
    .Equal(`PublishDate desc`)
    .Build().AsExpression;
}

export function getMiniGridTopFilter(): IFilterDataOnly[] {
    return FilterExpressionBuilder.For(ApiEntityTypesEnum.EditorialAsa)
    .Use('Top', ParameterTypeEnum.String)
    .Equal(3)
    .Build().AsExpression;
}

export function getMiniGridFilters(): IFilterDataOnly[] {
    return [...getMiniGridSelectFilter(), ...getMiniGridOrderFilter(), ...getMiniGridTopFilter()];
}

export function getEditorialFilter(templateId: EditorialTemplateTypeEnum, contentTypeId: EditorialContentTypeEnum, productId: EditorialProductEnum[] = null): IFilterDataOnly[] {
    let filterString = `TemplateID/any(t: t eq '${templateId}') and isDeleted eq false`;

    if (contentTypeId) {
        filterString += ` and ContentTypeID/any(t: t eq '${contentTypeId}')`;
    }

    if (isValidArray(productId)) {
        filterString += ` and ProductID/any(p: search.in(p, '${productId.join(',')}'))`;
    }
    return FilterExpressionBuilder.For(ApiEntityTypesEnum.EditorialAsa)
    .Use('Filter', ParameterTypeEnum.String)
    .Equal(filterString)
    .Build().AsExpression;
}

export function mapEditorialDataToNewsGridViewModel(data: EditorialMetaDataIndexContent[]): NewsGridViewModel[] {
    return data.map((r) => {
        return {
            id: r.ID,
            titleInfo: r.publishDate ? `${dateToDateString(r.publishDate)} - ${r.type}` : r.type,
            newsTitle: r.title,
        } as NewsGridViewModel;
    });
}

export function validDateTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
}

/**
 * Returns an array of citation objects as an id/displayText list for edit controls
 * Cloned JJK supplied data has funky citation facets that don't include the correct displayText, so we need to get it from the CfrTitle Facets
 * If the facet's displayText is equal to any titleFacet's ids, it will use the titleFacet's title as the returned displayText
 * If not, the facet's id and displayText will both be returned
 * @param citationFacets The supplied citation_Facets to convert to id/displayText objects
 * @param citationTitleFacets The sibling citation_CfrTitle_Facets to indicate whether the facet id is a number or string. Default = true
 */
export function GetCitationsFromFacetsAndTitleFacets(citationFacets: any, citationTitleFacets: any, isCloneMode: boolean) {
    if (isCloneMode && citationFacets && citationFacets.length > 0  && citationTitleFacets && citationTitleFacets.length > 0){
        const facets = GetFacetData(citationFacets);
        const titleFacets = GetFacetData(citationTitleFacets, false);

        return facets.map((cit) => {
            const title = titleFacets.find((tf) => tf.id === cit.displayText);
            return {
                id: cit.id,
                displayText: title && title.displayText || cit.displayText,
            };
        });
    } else {
        return GetFacetData(citationFacets);
    }

}
