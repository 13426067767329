export class AgGridStateModel {

    private _gridKey: string;
    private _gridColumnState: any;
    private _gridFilterState: any;
    private _defaulColumnDefs: any;

    public get gridKey(): string {
        return this._gridKey;
    }
    public set gridKey(value: string) {
        this._gridKey = value;
    }

    public get gridColumnState(): any {
        return this._gridColumnState;
    }
    public set gridColumnState(value: any) {
        this._gridColumnState = value;
    }

    public get gridFilterState(): any {
        return this._gridFilterState;
    }
    public set gridFilterState(value: any) {
        this._gridFilterState = value;
    }

    public get defaulColumnDefs(): any {
        return this._defaulColumnDefs;
    }
    public set defaulColumnDefs(value: any) {
        this._defaulColumnDefs = value;
    }

    constructor(_gridKey: string) {
        this._gridKey = _gridKey;
    }

    populateFromJSON(obj: any) {

        Object.assign(this, obj);
        this.gridColumnState = obj['_gridColumnState'];
        this.gridFilterState = obj['_gridFilterState'];
        this.defaulColumnDefs = obj['_defaulColumnDefs'];
    }
}
