import { EventEmitter, Injectable, Output } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { IncidentQuestionnaire } from '@constants/incident-questionnaire';
import { ApiServiceBase } from '@models/api-service-base.model';
import { QuestionType } from '@models/dynamic-modal-wizard/question-type';
import { IQuestion } from '@models/dynamic-modal-wizard/sms-question-definition.model';
import { Question } from '@models/dynamic-modal-wizard/sms-question.model';
import { IncLookupOshaInjuryClassification } from '@models/entity-models/incidents/inc-lookup-osha-injury-classification';
import { ApiFactory } from './core/api-factory.class';

@Injectable()
export class IncidentApiService extends ApiServiceBase {
    @Output() AllTrendYearsEmitter = new EventEmitter<any>();

    incidentQuestionnaire: IQuestion[] = [
        new Question(1, QuestionType.Question, 'Did the person experience an injury or illness?', { onYes: 2, onNo: 10 }),
        new Question(2, QuestionType.Question, 'Is this person a contract worker?', { onYes: 3, onNo: 4 }),
        new Question(3, QuestionType.Question, 'Are you responsible for the daily supervision of this person?', { onYes: 4, onNo: 10 }),
        new Question(4, QuestionType.Question, 'Is this injury or illness work-related?', { onYes: 5, onNo: 10 }),
        new Question(5, QuestionType.Question, 'Is this injury or illness a new case?', { onYes: 6, onNo: 10 }),
        new Question(
            6,
            QuestionType.Question,
            'Did the employee have a needlestick injury or cut from a sharp object that was contaminated with another person’s blood or other potentially infectious material?',
            { onYes: 9, onNo: 7 },
        ),
        new Question(
            7,
            QuestionType.Question,
            'Was the employee medically removed under the medical surveillance requirements of any OSHA standard?',
            { onYes: 9, onNo: 8 },
        ),
        new Question(
            8,
            QuestionType.Question,
            `Did the injury or illness result in:
                                                <li>Death,</li>
                                                <li>Days away from work,</li>
                                                <li>Restricted work or transfer to another job,</li>
                                                <li>Medical treatment beyond first aid,</li>
                                                <li>Hearing loss or contraction of tuberculosis,</li>
                                                <li>Loss of consciousness, or</li>
                                                <li>A significant injury or illness diagnosed by a physician or healthcare provider (HCP)</li>`,
            { onYes: 9, onNo: 10 },
        ),
        new Question(
            9,
            QuestionType.DecisionYes,
            'Based on your answers this incident is recordable and should appear on your OSHA injury forms.',
        ),
        new Question(
            10,
            QuestionType.DecisionNo,
            'Based on your answers this incident is not recordable. You may still want to record it to keep a record of the incident in order to track near misses, first aid incidents, and other incidents. This incident will not appear on OSHA 300 related forms.',
        ),
    ];

    private privacyQuestionnaire: IQuestion[] = [
        new Question(1, QuestionType.Question, 'Is the injury or illness to an intimate body part or the reproductive system?', {
            onYes: 7,
            onNo: 2,
        }),
        new Question(2, QuestionType.Question, 'Is the injury or illness a result of a sexual assault?', { onYes: 7, onNo: 3 }),
        new Question(3, QuestionType.Question, 'Is the injury or illness related to a mental illness?', { onYes: 7, onNo: 4 }),
        new Question(4, QuestionType.Question, 'Is the illness HIV, hepatitis, or tuberculosis?', { onYes: 7, onNo: 5 }),
        new Question(
            5,
            QuestionType.Question,
            'Is the injury or illness from a needlestick injuries or cuts from sharp objects that are contaminated with another person&apos;s blood or other potentially infectious material?',
            { onYes: 8, onNo: 6 },
        ),
        new Question(
            6,
            QuestionType.Question,
            'Has the employee independently and voluntarily requested that his or her name not be entered on the log?',
            { onYes: 7, onNo: 9 },
        ),
        new Question(
            7,
            QuestionType.DecisionYes,
            'Based on your answers, this is a privacy case and needs to be documented as such and kept confidential.',
        ),
        new Question(
            8,
            QuestionType.DecisionYes,
            'Based on your answers, this is a privacy case and needs to be documented as such and kept confidential. <br> Note: Because this case involved a contaminated sharp, it will also be documented as a sharp case.',
        ),
        new Question(9, QuestionType.DecisionNo, 'Based on your answers, this is not a privacy case.'),
    ];

    sharpsQuestionnaire: IQuestion[] = [
        new Question(1, QuestionType.Question, 'Did an object penetrate the skin of the injured employee?', { onYes: 2, onNo: 7 }),
        new Question(2, QuestionType.Question, 'Was the object contaminated with another person’s blood?', { onYes: 8, onNo: 3 }),
        new Question(3, QuestionType.Question, 'Was the object contaminated with other potentially infectious materials?', {
            onYes: 8,
            onNo: 7,
            onDontKnow: 4,
        }),
        new Question(4, QuestionType.Question, 'Was the object contaminated with another person’s human body fluids?', {
            onYes: 8,
            onNo: 5,
        }),
        new Question(
            5,
            QuestionType.Question,
            'Was the object contaminated with another person’s unfixed tissue or organ (other than intact skin) from a human (living or dead)?',
            { onYes: 8, onNo: 6 },
        ),
        new Question(
            6,
            QuestionType.Question,
            'Was the object contaminated with another person’s HIV-containing cell or tissue cultures, organ cultures, HIV- or HBV-containing culture medium or other solutions; and blood, organs, or other tissues from experimental animals infected with HIV or HBV?',
            { onYes: 8, onNo: 7 },
        ),
        new Question(7, QuestionType.DecisionNo, 'Based on your answers, this object was not a contaminated sharp.'),
        new Question(
            8,
            QuestionType.DecisionYes,
            `Based on your answers, this object was indeed a contaminated sharp and needs to be documented as such.
                                                 <br> Note: Because this case involved a contaminated sharp, it will also be documented as a privacy case.
                                                 See <a [routerLink]="" >Privacy Cases</a> for more information.`,
        ),
    ];

    private oshaInjuryClassifications = [
        { Id: Guid.raw(), Value: 0, Description: 'Injury' } as IncLookupOshaInjuryClassification,
        { Id: Guid.raw(), Value: 1, Description: 'SkinDisorder' } as IncLookupOshaInjuryClassification,
        { Id: Guid.raw(), Value: 2, Description: 'RespiratoryCondition' } as IncLookupOshaInjuryClassification,
        { Id: Guid.raw(), Value: 3, Description: 'Poisoning' } as IncLookupOshaInjuryClassification,
        { Id: Guid.raw(), Value: 4, Description: 'HearingLoss' } as IncLookupOshaInjuryClassification,
        { Id: Guid.raw(), Value: 5, Description: 'AllOtherIllnesses' } as IncLookupOshaInjuryClassification,
        { Id: Guid.raw(), Value: 6, Description: 'NotApplicable' } as IncLookupOshaInjuryClassification,
    ];

    constructor() {
        super();
    }

    getQuestionnaire(questionnaire: IncidentQuestionnaire): Observable<IQuestion[]> {
        // We have to put
        if (questionnaire === IncidentQuestionnaire.PrivacyCase) {
            return of(this.privacyQuestionnaire);
        }
        if (questionnaire === IncidentQuestionnaire.SharpsCase) {
            return of(this.sharpsQuestionnaire);
        }
        if (questionnaire === IncidentQuestionnaire.RecordableIncident) {
            return of(this.incidentQuestionnaire);
        }
    }

    getOshaInjuryClassifications(): Observable<IncLookupOshaInjuryClassification[]> {
        return of(this.oshaInjuryClassifications);
    }

    async GetAllTrendsYears() {
        await new Promise<void>((resolve) => {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.IncidentViewModel)
                .addRouteHint('GetAllTrendsYears')
                .addSuccessHandler((response) => { this.AllTrendYearsEmitter.emit(response); resolve(); })
                .addErrorHandler(() => resolve())
                .removePaging()
                .buildAndSend();
        });
    }
}
