import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { DynamicControl, DynamicFormGroup, SubmitStatus } from '@models/dynamic-forms.export';
import { ApiFactory } from '@services/core/api-factory.class';
import { focusOn, newRandomId } from '@utilities/helpers';

@Component({
    selector: 'dynamic-form-sub-form',
    templateUrl: './dynamic-form-sub-form.component.html',
    styleUrls: ['./dynamic-form-sub-form.component.scss'],
})
export class DynamicFormSubFormComponent implements OnInit, OnChanges {
    @Input() entityType: ApiEntityTypesEnum;
    @Input() fullDataList: any[];
    @Input() formControls: DynamicControl[];
    @Input() title: string;
    @Input() subFormId: string;
    @Input() isOpen: boolean;
    @Input() uniqueField: string; // this is the name of the field that needs to be checked for uniqueness
    @Input() uniqueFieldDisplayName: string; // Only needed if the Make Active Modal should display something other than the 'name' prop.
    @Output() validFormSubmitted = new EventEmitter();
    @Output() notUnique = new EventEmitter();
    @Output() closeForm = new EventEmitter();
    subFormGroup: DynamicFormGroup;
    modalData = [];
    modalDisplay: string;
    resetControls: Subject<void> = new Subject<void>();

    // Secure id in case a component name is repeated in the screen
    alphaId = newRandomId(4);
    isFormSubmitted = false;

    constructor() {
    }

    ngOnInit() {
        this.subFormGroup = new DynamicFormGroup(this.formControls);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.entityType){
            this.entityType = this.entityType === ApiEntityTypesEnum.EmployeeViewModel
                ? ApiEntityTypesEnum.Employee
                : this.entityType;
        }
        // set focus to first input
        if (this.subFormGroup && changes.isUnique && changes.isUnique.currentValue === false && this.uniqueField) {
            this.subFormGroup.controls[this.uniqueField].setErrors({ notUnique: true });
            this.subFormGroup.submitStatus = SubmitStatus.WithErrors;
        } else if (this.subFormGroup && changes.isOpen && changes.isOpen.previousValue === false && changes.isOpen.currentValue === true) {
            const firstInput = this.getJQuerySubForm().find(':text,textarea,select').filter(':first');
            focusOn(firstInput);
        } else if (this.subFormGroup && changes.isOpen && changes.isOpen.currentValue === false) {
            this.subFormGroup.reset();
            this.subFormGroup.submitStatus = SubmitStatus.Succeded;
            const lastSubFormInput = this.getJQuerySubForm().find(':input').filter(':last');
            const allInputs = this.getJQuerySubForm().closest('form').find(':input');
            const nextInput = $(allInputs[allInputs.index(lastSubFormInput) + 1]);
            setTimeout(() => {
                nextInput.trigger('focus');
            }, 300);
        }
    }

    private getJQuerySubForm(): JQuery<HTMLElement> {
        return $(`#${this.subFormId}[alphaId='${this.alphaId}']`);
    }

    reset() {
        this.closeForm.emit();
        this.subFormGroup.reset();
        this.subFormGroup.submitStatus = SubmitStatus.NotStarted;
    }

    async submit() {
        if (this.isFormSubmitted) { return; }
        this.isFormSubmitted = true;
        await this.newValueIsUnique().then(() => {
                this.validateAndSave();
            })
            .catch(() => {
                this.subFormGroup.submitStatus = SubmitStatus.WithErrors;
                this.isFormSubmitted = false;
            });
    }

    validateAndSave() {
        if (this.subFormGroup.invalid) {
            setTimeout(() => {
                this.subFormGroup.submitStatus = SubmitStatus.WithErrors;
                this.isFormSubmitted = false;
                const firstError = this.getJQuerySubForm().find('input').filter('.ng-invalid:first');
                focusOn(firstError);
            }, 0);
        } else {
            const newObject = this.subFormGroup.value;
            newObject.isActive = true;
            newObject.groupId = newObject.groupId ?? LoggedInUserInfo.Instance.userInfo.sharedGroupId;

            ApiFactory.saveNewEntity(this.entityType, newObject)
                .addSuccessHandler((id) => {
                    this.isFormSubmitted = false;
                    this.validFormSubmitted.emit(id);
                    this.resetControls.next();
                    this.subFormGroup.reset();
                })
                .buildAndSend();
        }
    }

    private async dataGroupingIsOn(entityType: ApiEntityTypesEnum) {
        switch (entityType) {
            case ApiEntityTypesEnum.Employee:
            case ApiEntityTypesEnum.Equipment:
            case ApiEntityTypesEnum.HealthcareFacility:
            case ApiEntityTypesEnum.HealthcareProfessional:
            case ApiEntityTypesEnum.Location:
            case ApiEntityTypesEnum.WorkArea:
                return true;
            default:
                return false;
        }
    }

    async newValueIsUnique(): Promise<boolean> {
        if (!this.uniqueField) return;

        if (this.uniqueField && this.subFormGroup.controls[this.uniqueField].value) {
            if (await this.dataGroupingIsOn(this.entityType)) {
                return await new Promise<boolean>((resolve, reject) => {
                    const apiFactory = ApiFactory.retrieveEntity(this.entityType);
                    apiFactory.addRouteHint('DuplicateData').addDataEntry('fieldName', encodeURIComponent(this.uniqueField)).addDataEntry('value', encodeURIComponent(this.subFormGroup.controls[this.uniqueField].value));
                    apiFactory.addSuccessHandler((duplicate) => {
                        if (duplicate) {
                            reject(false);
                            this.subFormGroup.controls[this.uniqueField].setErrors({ notUnique: true });
                            this.checkForInactive(duplicate);
                        } else {
                            this.subFormGroup.controls[this.uniqueField].setErrors({ notUnique: null });
                            this.subFormGroup.controls[this.uniqueField].updateValueAndValidity();
                            resolve(true);
                        }
                    })
                    .addErrorHandler(() => reject())
                    .buildAndSend();
                });
            }
            else {
                const uniqueValidationList = this.fullDataList.map((d) => d[this.uniqueField]);
                const found = uniqueValidationList.find((i) => i !== null && i !== undefined && i.toLocaleLowerCase() === this.subFormGroup.controls[this.uniqueField].value.trim().toLocaleLowerCase());
                if (found) {
                    this.subFormGroup.controls[this.uniqueField].setErrors({ notUnique: true });
                    this.checkForInactiveInList(found);
                } else {
                    this.subFormGroup.controls[this.uniqueField].setErrors({ notUnique: null });
                    this.subFormGroup.controls[this.uniqueField].updateValueAndValidity();
                }
                return found;
            }

        }

    }

    reActivate(obj: any) {
        if (this.dataGroupingIsOn(this.entityType)){
            ApiFactory.updateEntity(this.entityType, [obj.id])
            .addRouteHint("MakeActive")
                .addSuccessHandler((response) => {
                    if (response[obj.id]) {
                        this.reset();
                        this.validFormSubmitted.emit(obj);
                    }
                })
                .buildAndSend();
        } else {
            obj.isActive = true;
            if (obj.hasOwnProperty('location')) { obj.location = null; }
            if (obj.hasOwnProperty('jobTitle')) { obj.jobTitle = null; }
            ApiFactory.updateEntity(this.entityType, obj)
                .addSuccessHandler((id) => this.validFormSubmitted.emit(id))
                .buildAndSend();

        }
    }

    checkForInactive(duplicate) {
        if (!duplicate) return false;
        if (!duplicate.isActive && LoggedInUserInfo.Instance.userInfo.canEditGroup(duplicate.groupId)) {
            this.modalData = duplicate;
            this.modalDisplay = `${this.subFormGroup.controls[this.uniqueField].value} already exists and is inactive. Do you want to reactivate?`;
            ($(`#makeActiveModal${this.alphaId}`) as any).modal('show');
        }
    }

    checkForInactiveInList(found: string) {
        const match = this.fullDataList.find((f) => f[this.uniqueField] === found);
        if (!match.isActive) {
            this.modalData = match;
            if (this.uniqueFieldDisplayName) {
                this.modalDisplay = `A record for ${this.uniqueFieldDisplayName} ${match[this.uniqueField]} already exists and is inactive. Do you want to reactivate?`;
            } else {
                this.modalDisplay = `${match.name} already exists and is inactive. Do you want to reactivate?`;
            }
            ($(`#makeActiveModal${this.alphaId}`) as any).modal('show');
        }
    }
}
