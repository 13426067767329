import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { GetRowIdFunc, GetRowIdParams, GridApi, GridReadyEvent } from 'ag-grid-community';
import { AGGridActionsCustomColumnComponent } from '@components/ag-grid-actions-custom-column/ag-grid-actions-custom-column.component';
import { AgGridCustomListFilterComponent } from '@components/ag-grid-custom-list-filter/ag-grid-custom-list-filter.component';
import { AgGridCustomTimeFilterComponent } from '@components/ag-grid-custom-time-filter/ag-grid-custom-time-filter.component';
import { AGGridCustomToolPanelComponent } from '@components/ag-grid-custom-toolpanel/ag-grid-custom-toolpanel.component';
import { AGGridDownloadCustomColumnComponent } from '@components/ag-grid-download-custom-column/ag-grid-download-custom-column.component';
import { AgGridRecurringAuditCellRendererComponent } from '@components/ag-grid-recurring-audit-cell-renderer/ag-grid-recurring-audit-cell-renderer.component';
import { AgGridSdsStatusTooltipComponent } from '@components/ag-grid-sds-status-tooltip/ag-grid-sds-status-tooltip.component';
import { GridMode } from '@constants/enums/grid-mode.enum';
import { AgGridStateModel } from '@models/aggrid-state.model';
import { SDSSearchRadioButtonComponent } from '@pages/chemicals/sds-search/sds-search-radio-button.component';
import { AgGridStateService } from '@services/aggridState.service';
import { applyPredefinedGridState, defaultGridInit, formatColumnsForExportAndClipboard } from '@utilities/grid-helpers';
import { isNullOrUndefined } from '@utilities/helpers';

@Component({
    selector: 'ag-grid',
    templateUrl: './ag-grid.component.html',
    styleUrls: ['./ag-grid.component.scss'],
})
export class AGGridComponent {

    // Variables
    context: any;
    loading = true;
    displayedRowCount = 0;
    toolbarActionsEnabled = false;
    gridColumnsChanged = false;
    gridReady = false;
    sideBar: any;

    public gridApi: GridApi;
    gridRowData: any[] = [];

    defaultTextFilterOptions = ['equals','notEqual','contains','notContains','startsWith','endsWith'];
    defaultNumberFilterOptions = ['equals','notEqual','lessThan','lessThanOrEqual','greaterThan','greaterThanOrEqual','inRange'];
    defaultDateFilterOptions = ['equals','notEqual','lessThan','greaterThan','inRange'];
    columnsDateIds: string[] = [];
    columnsDateWidth = 221;

    private _rowData: any[];
    @Input()
    set rowData(tableData: any[]) {
        this._rowData = tableData;
        this.loadData();
    }
    get rowData() { return this._rowData; }

    @Input() getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
        return params.data.id;
    };

    private _actionsChildComponent = '';
    public get actionsChildComponent() {
        return this._actionsChildComponent;
    }
    public set actionsChildComponent(value) {
        if (!isNullOrUndefined(this.actionsChildComponent)) {
            this.handleGridActionComponentEvent(value);
            this._actionsChildComponent = value;
        }
    }

    constructor(private agGridState: AgGridStateService) {
        this.context = { componentParent: this };
    }

    private _columnDefinition: any[];
    @Input()
    set columnDefinition(tableData: any[]) {
        this._columnDefinition = tableData;
        this.setDefaultFilterOptions();
    }
    get columnDefinition() { return this._columnDefinition; }

    @Input() gridStateModel: AgGridStateModel;
    @Input() defaultColumnDefinition: any[];
    @Input() showSideBar: boolean;
    @Input() noFiltersOnSideBar = false;
    @Input() rowSelection = 'multiple';
    @Input() rowHeight = 40;
    @Input() height = '100vh';
    @Input() maxHeight = '680px';
    @Input() overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Fetching data...</span>';
    @Input() overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">Your search did not return any results...</span>';
    @Input() actionMenuColumn: any[];
    @Input() showSelectColumn = false;
    @Input() overwriteSidebar: any;
    @Input() customToolPanelParams: any;
    @Input() customFilterList: string[] = [];
    @Input() autosizeColumns = true;
    @Input() forceClearRowData = false;
    @Input() gridMode: GridMode;
    @Input() dataSource: any;
    @Input() pageSize = 100;
    @Input() customContextMenuItems: any[];
    @Input() enableCharts = false;
    @Input() additionalCustomSidePanelActions: any[];
    @Input() initialLoadStateQuery: any;
    @Input() initialLoadStateQueryParams: any;

    @Output() childComponentClick = new EventEmitter();

    // AGGrid Custom Components
    components = {
        agGridActionsCustomColumnComponent: AGGridActionsCustomColumnComponent,
        agGridDownloadCustomColumnComponent: AGGridDownloadCustomColumnComponent,
        agGridCustomToolPanelComponent: AGGridCustomToolPanelComponent,
        agGridCustomListFilterComponent: AgGridCustomListFilterComponent,
        agGridCustomTimeFilterComponent: AgGridCustomTimeFilterComponent,
        sDSSearchRadioButtonComponent: SDSSearchRadioButtonComponent,
        agGridSdsStatusTooltipComponent: AgGridSdsStatusTooltipComponent,
        agGridRecurringAuditCellRendererComponent: AgGridRecurringAuditCellRendererComponent,
    };

    style = {
        width: '100%',
        height: '100%',
        flex: '1 1 auto',
    };

    loadData() {
        if (!this.gridReady) { return; }
        if (isNullOrUndefined(this.rowData) && this.gridMode === GridMode.ClientSideRowModel) { return; }

        if (this.gridMode === GridMode.ClientSideRowModel) {
            if (this.forceClearRowData) {
                this.gridRowData = [];
                this.gridRowData.push(...this.rowData);
                this.gridApi.applyTransaction({ add: this.gridRowData });
                this.displayedRowCount = this.gridRowData.length;
            } else {
                this.gridRowData.push(...this.rowData);
                this.gridApi.applyTransaction({ add: this.gridRowData });
                this.gridApi.showLoadingOverlay();
                this.displayedRowCount = this.gridRowData.length;

                if (this.initialLoadStateQuery?.predefinedState) {
                    applyPredefinedGridState(this.initialLoadStateQuery.predefinedState, this.gridApi, this.initialLoadStateQueryParams);
                    this.initialLoadStateQuery = null;
                }
                else {
                    this.restoreAgGridPreviousState();
                }
            }
        }
        if (this.gridMode === GridMode.ServerSideRowModel) {
            this.gridApi.setGridOption("serverSideDatasource", this.dataSource);

            if (this.initialLoadStateQuery?.predefinedState) {
                applyPredefinedGridState(this.initialLoadStateQuery.predefinedState, this.gridApi, this.initialLoadStateQueryParams);
                this.initialLoadStateQuery = null;
            }
            else {
                this.restoreAgGridPreviousState();
            }
        }

        setTimeout(() => {
            if (this.gridMode === GridMode.ClientSideRowModel && this.forceClearRowData === false) {
                this.gridApi.hideOverlay();
            } else if (this.forceClearRowData === true) {
            }
            this.loading = false;
        }, 100);
    }

    setDefaultFilterOptions() {
        // Check for preset filter options; otherwise, assign supported default filters.
        this.columnDefinition.filter(x => x.filter === 'agTextColumnFilter')
            .forEach(filter => {
                if (!filter?.filterParams) {
                    filter['filterParams'] = { suppressAndOrCondition: true,
                                                            defaultOption: 'contains',
                                                            buttons: ['reset'],
                                                            filterOptions: this.defaultTextFilterOptions };
                } else if (!filter?.filterParams?.filterOptions) {
                    filter.filterParams['filterOptions'] = this.defaultTextFilterOptions;
                }
        });
        this.columnDefinition.filter(x => x.filter === 'agNumberColumnFilter' )
            .forEach(filter => {
                if (!filter?.filterParams) {
                    filter['filterParams'] = {  defaultOption: 'equals',
                                                buttons: ['reset'],
                                                suppressAndOrCondition: true,
                                                filterOptions: this.defaultNumberFilterOptions };
                } else if (!filter?.filterParams?.filterOptions) {
                    filter.filterParams['filterOptions'] = this.defaultNumberFilterOptions;
                }
        });
        this.columnDefinition.filter(x => x.filter === 'agDateColumnFilter')
            .forEach(filter => {
                if (!filter?.filterParams) {
                    filter['filterParams'] = { filterOptions: this.defaultDateFilterOptions };
                } else if (!filter?.filterParams?.filterOptions) {
                    filter.filterParams['filterOptions'] = this.defaultDateFilterOptions;
                }
                 // stablish the minum width allow so the placeholder of the filter can be seen completely
                 filter.minWidth = this.columnsDateWidth;
                 this.columnsDateIds.push(filter.field);
        });
    }

    // ------------------------------------------------
    // AgGrid - Events
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;

        // Bind Grid Default Events
        defaultGridInit(params);

        // sets the Sidebar Tool Panel
        this.setSidebar();

        if ((this.actionMenuColumn?.length > 0)) { this.addActionMenuColumn(); }
        if (this.showSelectColumn) { this.addRowSelectColumn(); }

        if (this.gridMode === GridMode.ClientSideRowModel) {
            this.gridApi.showLoadingOverlay();
        }
        if (this.gridMode === GridMode.ServerSideRowModel) {
            this.gridApi.setGridOption("serverSideDatasource", this.dataSource);
        }

        this.gridApi.setGridOption("columnDefs", this.columnDefinition);

        this.gridReady = true;

        if (this.loading) { this.loadData(); }

        // allow tooltips to work with dynamic content
        (<any>$('.grid-wrapper')).tooltip({
            selector: '[data-bs-toggle="tooltip"]'
        });
    }

    onFilterChanged() {
        if (!this.gridApi.isDestroyed()) {
            this.getDisplayedRowCount();
            this.clearCheckboxes();
            this.toolbarActionsEnabled = this.gridApi.isColumnFilterPresent() ? true : false;
            this.saveState();
        }
    }

    onSortChanged() {
        this.clearCheckboxes();
        this.onGridColumnsChanged();
    }

    onColumnPinned() {
        if (!this.loading) {
            this.onGridColumnsChanged();
        }
    }

    onColumnResized() {
        if (!this.loading) {
            this.onGridColumnsChanged();
        }
    }

    onGridColumnsChanged() {
        if (!this.gridReady) { return; }

        let currentDef: any = this.gridApi.getColumnDefs();
        currentDef = currentDef.filter(col => col.hide !== true);

        if (isNullOrUndefined(this.gridStateModel.defaulColumnDefs)) {
            this.gridStateModel.defaulColumnDefs = currentDef;
            return;
        } else {

            let differencesFound = false;
            this.gridStateModel.defaulColumnDefs.forEach((element, index) => {
                if (!isNullOrUndefined(currentDef[index]?.colId)) {
                    if (element.colId !== currentDef[index].colId) {
                        differencesFound = true;
                    }
                    if (element.pinned !== currentDef[index].pinned) {
                        differencesFound = true;
                    }
                    if (element.sort !== currentDef[index].sort) {
                        differencesFound = true;
                    }
                    if (element.width !== currentDef[index].width) {
                        differencesFound = true;
                    }
                }
            });

            if (this.gridStateModel.defaulColumnDefs.length !== currentDef.length) { differencesFound = true; }

            this.gridColumnsChanged = differencesFound ? differencesFound : false;
            if (differencesFound) { this.saveState(); }
        }
    }
    clearCheckboxes() {
        if (this.getSelectedNodes().length > 0) {
            this.getSelectedNodes().forEach((rowNode) => {
                rowNode.setSelected(false);
            });
        }
    }
    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        const elementToLookFor = document.getElementsByClassName('ag-tool-panel-wrapper')[0];
        const curElement = (<Element>event.target).contains(elementToLookFor);
        // collapse toolPanel based on mouse focus
        if (curElement) {
            setTimeout(() => {

                this.gridApi.closeToolPanel();

         }, 200);
        }
    }
    // collapse toolPanel based on focus of grid
    cellFocused(event) {
       setTimeout(() => {

            this.gridApi.closeToolPanel();

        }, 200);
    }
    // ------------------------------------------------
    // AgGrid - State Methods
    saveState() {
        this.saveAgGridFilterState();
        this.saveAgGridColumnState();

        this.agGridState.updateAgGridState(this.gridStateModel);
    }

    saveAgGridColumnState() {
        (window as any).colState = this.gridApi.getColumnState();
        this.gridStateModel.gridColumnState = (window as any).colState;
    }

    saveAgGridFilterState() {
        this.gridStateModel.gridFilterState = this.gridApi.getFilterModel();
    }

    resetAGGridFilters() {
        this.clearCustomFilters();
        this.gridApi.setFilterModel(null);
        this.autoSizeAll();
        this.saveState();
    }

    restoreAgGridPreviousState() {

        this.gridStateModel = this.agGridState.restoreAgGridState(this.gridStateModel.gridKey);

        if (!isNullOrUndefined(this.gridStateModel.gridColumnState) && !isNullOrUndefined(this.gridStateModel.gridFilterState)) {
            if (!isNullOrUndefined(this.gridStateModel.gridColumnState)) {
                (window as any).colState = this.gridStateModel.gridColumnState;
                this.gridApi.applyColumnState({
                    state: (window as any).colState,
                    applyOrder: true,
                });
            }
            if (!isNullOrUndefined(this.gridStateModel.gridFilterState)) {
                this.gridApi.setFilterModel(this.gridStateModel.gridFilterState);
            }
        } else {
            this.autoSizeAll();
        }
    }

    // ------------------------------------------------

    // Sidebar
    setSidebar() {
        if (this.showSideBar) {
            if (!isNullOrUndefined(this.overwriteSidebar)) { this.sideBar = this.overwriteSidebar; return; }

            if (this.showSideBar) {
                this.sideBar = {
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                            toolPanelParams: {
                                supressPivots: true,
                                suppressPivotMode: true,
                                suppressRowGroups: true,
                                suppressValues: true,
                            }
                        },
                    ],
                };
            }

            if (!this.noFiltersOnSideBar) {
                this.sideBar.toolPanels.push({
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel'
                });
            }

            if (this.customToolPanelParams) {

                this.sideBar.toolPanels.push({
                    id: 'customToolPanel',
                    labelDefault: 'Actions',
                    labelKey: 'customToolPanel',
                    iconKey: 'chart',
                    toolPanel: 'agGridCustomToolPanelComponent',
                    toolPanelParams: {
                        gridType: this.customToolPanelParams.gridType,
                        columnsToIgnore: this.customToolPanelParams.columnsToIgnore,
                        onDelete: this.handleCustomToolPanelDelete.bind(this),
                        onPrint301: this.handleCustomToolPanelPrint301.bind(this),
                        onMakeActive: this.handleCustomToolPanelMakeActive.bind(this),
                        onMakeInactive: this.handleCustomToolPanelMakeInactive.bind(this),
                        onResetLearnerPassword: this.handleCustomToolPanelResetLearnerPassword.bind(this),
                        rowModelType: this.gridMode,
                        additionalActions: this.additionalCustomSidePanelActions,
                        action: this.handleGridActionComponentEvent.bind(this),
                        userHasEditPermissions: this.customToolPanelParams.userHasEditPermissions,
                        hideDelete: this.customToolPanelParams.hideDelete,
                        hideImportExport: this.customToolPanelParams.hideImportExport
                    }
                });
            }

        } else {
            this.sideBar = 'false';
        }
    }

    // Context Menu
    getContextMenuItems = (params) => {
        if (this.customContextMenuItems) {
            return this.customContextMenuItems;
        } else {
            const contextMenuItems = [
                'copy',
                'copyWithHeaders',
                'paste'
            ];
            return contextMenuItems;
        }
    }

    // Action Menu Column
    addActionMenuColumn() {

        this.columnDefinition.unshift({
            headerName: 'Menu',
            width: 90,
            minWidth: 90,
            maxWidth: 90,
            filter: false,
            suppressMovable: true,
            colId: 'ActionsColumn',
            cellRenderer: 'agGridActionsCustomColumnComponent',
            cellClass: 'actions-button-cell bold',
            cellRendererParams: {
                customColumnParams: this.actionMenuColumn,
                action: this.gridActionComponentEvent,
            },
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true,
            lockPosition: true,
            suppressMenu: true,
            sortable: false,
        });
    }

    gridActionComponentEvent(params) {
        params.context.componentParent.actionsChildComponent = params;
    }

    handleCustomToolPanelDelete() {
        const selectedNodes = this.getSelectedNodes();
        selectedNodes['ChildComponentAction'] = 'CustomToolPanelDelete';
        this.childComponentClick.emit(selectedNodes);
    }

    handleCustomToolPanelPrint301() {
        const selectedNodes = this.getSelectedNodes();
        selectedNodes['ChildComponentAction'] = 'CustomToolPanelPrintForm301';
        this.childComponentClick.emit(selectedNodes);
    }

    handleCustomToolPanelMakeActive() {
        const selectedNodes = this.getSelectedNodes();
        selectedNodes['ChildComponentAction'] = 'CustomToolPanelMakeActive';
        this.childComponentClick.emit(selectedNodes);
    }

    handleCustomToolPanelMakeInactive() {
        const selectedNodes = this.getSelectedNodes();
        selectedNodes['ChildComponentAction'] = 'CustomToolPanelMakeInactive';
        this.childComponentClick.emit(selectedNodes);
    }

    handleCustomToolPanelResetLearnerPassword() {
        const selectedNodes = this.getSelectedNodes();
        selectedNodes['ChildComponentAction'] = 'CustomToolPanelResetLearnerPassword';
        this.childComponentClick.emit(selectedNodes);
    }

    getSelectedNodes() {
        const rowData = [];
        if (this.gridMode === GridMode.ClientSideRowModel) {
            this.gridApi.forEachNodeAfterFilter(node => {
                rowData.push(node);
            });
            return rowData.filter(x => x.isSelected());
        }
        else {
            return this.gridApi.getSelectedNodes();
        }
    }

    handleGridActionComponentEvent(params) {
        if (!isNullOrUndefined(params.ChildComponentAction)) {
            this.childComponentClick.emit(params);
        }
    }

    addRowSelectColumn() {

        this.columnDefinition.unshift({
            headerName: '',
            colId: 'SelectColumn',
            checkboxSelection: true,
            suppressMovable: true,
            headerCheckboxSelection: this.gridMode === GridMode.ClientSideRowModel,
            headerCheckboxSelectionFilteredOnly: this.gridMode === GridMode.ClientSideRowModel,
            width: 40,
            minWidth: 40,
            maxWidth: 40,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true,
            filter: false,
            lockPosition: true,
            suppressMenu: true,
            cellRendererParams: { checkbox: true },
        });
    }

    autoSizeAll() {
        if (this.autosizeColumns) {
            const allColumnIds = [];
            this.gridApi.getColumns().forEach((column) => {
                allColumnIds.push(column.getId());
            });
            this.gridApi.autoSizeColumns(allColumnIds, false);

            // Set the default widths to be the same as the resized column widths so the grid doesn't always think a change has been made that can be reset.
            let currentDef: any = this.gridApi.getColumnDefs();
            currentDef = currentDef.filter(col => col.hide !== true);

            if (isNullOrUndefined(this.gridStateModel.defaulColumnDefs)) {
                this.gridStateModel.defaulColumnDefs = currentDef;
            }

            this.gridStateModel.defaulColumnDefs?.forEach((element, index) => {
                if (!isNullOrUndefined(currentDef[index]?.colId)) {
                    element.width = currentDef[index].width
                }
            });
        }
    }

    clearCustomFilters() {
        // This list must contain the name of the custom filters implemented for the grid instance
        this.customFilterList.forEach(element => {
            try {
                const agGridFilter = (this.gridApi.getFilterInstance(element) as AgGridCustomListFilterComponent);
                agGridFilter.clearFilter();
            } catch (error) {
                console.error('An error occurred cleaning the filter ' + element, error);
            }
        });
    }

    getDisplayedRowCount() {
        if (!isNullOrUndefined(this.gridApi) && !this.gridApi.isDestroyed()) {
            this.displayedRowCount = this.gridApi.getDisplayedRowCount();
        }
    }

    // ------------------------------------------------
    // ag-grid-custom-toolbar - Actions
    onToolbarActionClick(event) {
        switch (event) {
            case 'Reset': {
                this.gridApi.resetColumnState();
                this.resetAGGridFilters();
                break;
            }
            case 'Clear': {
                this.resetAGGridFilters();
                break;
            }
        }
    }

    processCellForClipboard(params) {
        return formatColumnsForExportAndClipboard(params);
    }
}
