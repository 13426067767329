import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from '@utilities/helpers';
import { ErrorEntry } from './ErrorEntry.model';

export class JJKellerApiResponse {
    successData: any[] = [];
    errorData: ErrorEntry[] = [];
    httpStatusCode: number;
    totalRows: number;
    partialRowCount: any;

    wasSuccessful(): boolean {
        return this.errorData.length === 0;
    }

    public addErrorEntry(error: ErrorEntry) {
        this.errorData.push(error);
    }

    public addError(error: any) {
        this.errorData.push(new ErrorEntry(error));
    }

    constructor(data: any = null) {
        if (data != null) {
            this.successData = (data && data.successData) || '';
            if (data.errorData && data.errorData.length) {
                data.errorData.map((err) => this.addError(err));
            }
            this.httpStatusCode = data.statusCode;
        }
    }

    public HttpErrorResponse_Mapper(errorResponse: HttpErrorResponse) {

        let exceptionMessage: string;
        if (!isNullOrUndefined(errorResponse && errorResponse.error && errorResponse.error.errorData)) {

            errorResponse.error.errorData.map((x) => {
                exceptionMessage = x.errorMessage;
                if (exceptionMessage?.lastIndexOf('Nested Msgs:') > -1) { exceptionMessage = exceptionMessage.substring(0, exceptionMessage.lastIndexOf('Nested Msgs:')); }
            });

        } else {
            exceptionMessage = errorResponse?.statusText;
        }

        if (errorResponse != null) {
            const errorEntry: ErrorEntry = {
                exceptionData: errorResponse.error?.errorData?.[0]?.exceptionData || exceptionMessage,
                errorMessage: exceptionMessage,
                showToUser: true,
                errorRaw: errorResponse.message,
            };

            const apiError = new JJKellerApiResponse();
            apiError.httpStatusCode = errorResponse.status;
            apiError.errorData.push(errorEntry);
            apiError.successData = [];

            return apiError;
        }
    }
}
