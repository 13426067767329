import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { PasswordRuleViewModel } from '@models/auth/rule.model';
import { GetPasswordRulesAndValidation } from '@pages/auth/helpers';
import { AuthService } from '@services/auth.service';

@Component({
    selector: 'jjkp-password-rules',
    styleUrls: ['./password-rules.component.scss'],
    templateUrl: './password-rules.component.html',
})
export class PasswordRulesComponent implements OnInit{

    @Input() rules: PasswordRuleViewModel[];
    @Output() rulesAreLoaded = new EventEmitter<PasswordRuleViewModel[]>();
    @Input() private parentActionSuccess: EventEmitter<boolean>;
    @Output() error = new EventEmitter<string>();

    @Input() header: string;
    @Input() paragraph = 'Password Requirements:';
    @Input() showValidation = false;
    @Input() validationErrors: ValidationErrors;
    @Input() showErrorText: boolean;
    @Input() loaderTop = false;
    loaderTopActive= false;

    constructor(private authService: AuthService) {
    }

    ngOnInit(): void {
        if (this.parentActionSuccess) {
            this.parentActionSuccess.subscribe(data => {
                if (data) {
                    this.loaderTopActive = true;
                } else {
                    this.loaderTopActive = false;
                }
            });
        }
        this.getPasswordRules();
    }

    async getPasswordRules() {
        if (!this.rules) {
            await this.authService.getPasswordRules()
                .then(data => this.rules = GetPasswordRulesAndValidation(data))
                .catch((errorMessage) => this.error.emit(errorMessage));
        }
        this.rulesAreLoaded.emit(this.rules);
    }
}
