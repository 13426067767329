import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'jjk-date-range-picker',
    templateUrl: './date-range-picker.component.html',
})
export class DateRangePickerComponent implements OnInit {
    @Input() startDate: moment.Moment;
    @Input() endDate: moment.Moment;
    @Input() isDisabled: boolean;
    @Output() dateChanged = new EventEmitter<any>();

    parentForm: UntypedFormGroup;

    private _startDateValue: moment.Moment;
    private _endDateValue: moment.Moment;

    constructor(public controlContainer: ControlContainer) { }

    ngOnInit() {
        this.parentForm = this.controlContainer.control as UntypedFormGroup;
    }

    onDateChanged(event: MatDatepickerInputEvent<moment.Moment>, name: string) {
        this.updateValue(event.value, name);
        this.dateChanged.emit({ startDate: this._startDateValue, endDate: this._endDateValue });
    }

    private updateValue(value: moment.Moment, name: string) {
        if (name === 'endDate') {
            this._endDateValue = value;
        } else {
            this._startDateValue = value;
        }
    }
}
