<modal-window [id]="modalId" [title]="title" [confirmButtonText]="confirmButtonText" (onConfirm)="onDeleteConfirm()" [isRedConfirmButton]="mainButtonisRed" (onCancel)="onCancel()"
              (onX)="onX()" [isConfirmDisabled]="!canDelete">
  <p *ngIf="entityName && (!deleteMany || (totalEntityCount === 1 && entitiesWithNoPermissionCount === 0))">Are you sure you want to{{ additionalMessageBeforeActionWord ? " " + additionalMessageBeforeActionWord: "" }} {{ confirmButtonText | lowercase }} the {{ entityName }}{{ additionalMessageAfterEntityName ? " " + additionalMessageAfterEntityName: "" }}? {{ extraMessageAfterAdditionalMessage ? " " + extraMessageAfterAdditionalMessage: "" }}</p>
  <p *ngIf="deleteMany && totalEntityCount !== 1 && canDelete">You are attempting to {{ confirmButtonText | lowercase }} {{ totalEntityCount }} records<ng-container *ngIf="entitiesWithNoPermissionCount > 0">, of which you have sufficient permissions to delete {{ totalEntityCount - entitiesWithNoPermissionCount }}</ng-container>. Proceed by selecting "Delete" below. </p>
  <p *ngIf="!canDelete">You do not have permission to {{ confirmButtonText | lowercase }} the selected record(s)</p>
  <ng-container *ngIf="canDelete">
    <p>{{ additionalMessage }}</p>
    <p *ngIf="recordName"><strong>{{ recordName }}</strong></p>
    <p>{{ additionalLineMessage }}</p>
    <p>{{ additionalSecondLineMessage }}</p>
    <ng-content *ngIf="!recordName">
    </ng-content>
  </ng-container>
</modal-window>
