import { UserInterfaceMessage } from 'app/modules/messaging/types/Messages/userInterfaceMessage';
import { MessageAction } from '../types/MessageAction';
import { MessageHandler } from '../types/MessageHandler';
import { MessageHandlerRegistration } from '../types/messageHandlerRegistration';
import { ApiMessage } from '../types/Messages/apiMessage';
import { DataMessage } from '../types/Messages/dataMessage';
import { Message } from '../types/Messages/Message';
import { RouteMessage } from '../types/Messages/routeMessage';
import { SystemEventMessage } from '../types/Messages/SystemEventMessage';
import { MessageTypeEnum } from './MessageTypeEnum';

//#region INTERFACES
export interface IMsgProcessingReg {
    add(): ITopicBuilder;
}

export interface ITopicBuilder {
    inBoundDataTopic(topic: string): IAddMsgType;
    inBoundUITopic(topic: string): IAddMsgType;
}

export interface IAddMsgType {
    listenForMessageType(msgTypeEnum: MessageTypeEnum): IAddMsgAction;
}

export interface IAddMsgAction {
    withAction(msgAction: MessageAction): IAddMsgHandler;
}

export interface IAddMsgHandler {
    thenCallFunction(fn: MessageHandler): IAddNewTopicActionOrType;
}

export interface IAddNewTopicActionOrType extends IMsgProcessingReg, IAddMsgType, IAddMsgAction {}
//#endregion INTERFACES

export class MessageBusConfigurationBuilder implements IAddNewTopicActionOrType, IAddMsgHandler, IMsgProcessingReg {
    /** This will hold all handlers keyed by Topic Name */
    public inBoundEntries = new Map<string, MessageHandlerRegistration<Message>[]>();

    currentEntryBeingBuilt: MessageHandlerRegistration<Message>;

    /** These represent the IMessageProcessingRegistration Interface */
    add(): ITopicBuilder {
        return this;
    }

    /** These represent the ITopicBuilder Interface */
    inBoundDataTopic(topic: string): IAddMsgType {
        this.currentEntryBeingBuilt = new MessageHandlerRegistration();
        this.currentEntryBeingBuilt.relatesToData = true;
        return this.saveRegistration(topic);
    }

    inBoundUITopic(topic: string): IAddMsgType {
        this.currentEntryBeingBuilt = new MessageHandlerRegistration();
        this.currentEntryBeingBuilt.relatesToData = false;
        return this.saveRegistration(topic);
    }

    saveRegistration(topic: string): IAddMsgType {
        this.currentEntryBeingBuilt.forTopic = topic;

        if (this.inBoundEntries.has(topic) === false) {
            this.inBoundEntries.set(topic, new Array<MessageHandlerRegistration<Message>>());
        }

        this.inBoundEntries.get(topic).push(this.currentEntryBeingBuilt);
        return this;
    }
    /** These represent the IAddMessageType Interface */
    listenForMessageType(msgType: MessageTypeEnum): IAddMsgAction {
        /** Are we declaring multiple entries for this message type? */
        if (this.currentEntryBeingBuilt.messageType !== null) {
            const newEntry = Object.create(this.currentEntryBeingBuilt) as MessageHandlerRegistration<Message>;
            this.inBoundEntries.get(newEntry.forTopic).push(newEntry);
            this.currentEntryBeingBuilt = newEntry;

            // be sure to signal the builder that we want to use the current entry
            this.currentEntryBeingBuilt.messageAction = null;
        }

        this.currentEntryBeingBuilt.messageType =
            msgType === MessageTypeEnum.ApiMessage
                ? ApiMessage.messageRegistrationKey
                : msgType === MessageTypeEnum.DataMessage
                ? DataMessage.messageRegistrationKey
                : msgType === MessageTypeEnum.RouteMessage
                ? RouteMessage.messageRegistrationKey
                : msgType === MessageTypeEnum.SystemEventMessage
                ? SystemEventMessage.messageRegistrationKey
                : msgType === MessageTypeEnum.UserInterface
                ? UserInterfaceMessage.messageRegistrationKey
                : null;

        return this;
    }

    /** These represent the IAddMessageAction Interface */
    withAction(msgAction: string): IAddMsgHandler {
        /** Are we declaring multiple entries for this message type? */
        if (this.currentEntryBeingBuilt.messageAction !== null) {
            const newEntry = Object.create(this.currentEntryBeingBuilt) as MessageHandlerRegistration<Message>;
            this.inBoundEntries.get(newEntry.forTopic).push(newEntry);
            this.currentEntryBeingBuilt = newEntry;
        }

        this.currentEntryBeingBuilt.messageAction = msgAction;
        return this;
    }

    /** These represent the IAddMessageHandler Interface */
    thenCallFunction(fn: MessageHandler): IAddNewTopicActionOrType {
        this.currentEntryBeingBuilt.handler = fn;
        return this;
    }

    build(): Map<string, MessageHandlerRegistration<Message>[]> {
        return this.inBoundEntries;
    }

    buildAsArray(): MessageHandlerRegistration<Message>[] {
        const results = new Array<MessageHandlerRegistration<Message>>();
        this.inBoundEntries.forEach((v) => {
            results.push(...v);
        });
        return results;
    }
}
