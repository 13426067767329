import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ACTIONS } from '@constants/messages.constants';
import { EnableMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageBusService } from '@modules/messaging/services/messageBusService';

@Directive()
export abstract class MessageButtonBaseComponentDirective extends EnableMessageProcessingDirective {
    _styleClasses: any;

    constructor(mb: MessageBusService) {
        super(mb);
        this._styleClasses = 'btn-primary';
        this.clicked = new EventEmitter<any>();
    }

    @Input() set msgAction(action: string) {
        const actionParts = action.split('.');
        let part = ACTIONS;
        for (let i = 1; i < actionParts.length - 1; i++) {
            part = part[actionParts[i]];
        }
        this.action = part[actionParts[actionParts.length - 1]];
    }

    protected action = '';

    @Input() get stylingClasses() {
        return this._styleClasses;
    }
    set stylingClasses(classes: any) {
        this._styleClasses = classes;
    }

    @Input() buttonText: string;

    // we raise the even here, so any other post button press needs can be handled
    @Output()
    public clicked: EventEmitter<any>;

    @Input()
    __clicked = (data) => {
        this.sendMessage(data);
        this.clicked.emit(data);
    }

    protected configureMessageBus(builder: IMsgProcessingReg): void { }

    protected abstract sendMessage(data): void;
}
