import { TOGGLES } from '@constants/toggles';
import { environment } from '@env/environment';

export const SIDE_NAV_CONFIG = [
    {
        groupName: '',
        accordion: [
            {
                id: 'dashboard',
                name: 'Dashboard',
                icon: 'custom-icon-dashboard',
                route: '/dashboard',
                authTag: 'dashboard',
            },
        ],
    },
    {
        groupName: 'RESOURCES',
        accordion: [
            {
                id: 'webcasts',
                name: 'Webcasts',
                icon: 'custom-icon-webcasts',
                route: environment.webcastsUrl,
                authTag: 'webcast',
                featureToggleName: TOGGLES.WEBCASTS_LINK,
            },
            {
                id: 'discussions',
                name: 'Discussions',
                icon: 'custom-icon-discussion',
                route: environment.discussionsSite,
                authTag: 'discussion',
                featureToggleName: TOGGLES.DISCUSSIONS_FEATURE,
            },
            {
                id: 'expertHelp',
                name: 'Expert Help',
                icon: 'custom-icon-expert-help',
                route: 'expert-help',
                authTag: 'expert-help',
                featureToggleName: TOGGLES.EXPERT_HELP_FEATURE,
            },
            {
                id: 'tasks',
                name: 'Tasks',
                icon: 'custom-icon-tasks',
                route: '/tasks',
                authTag: 'task',
                featureToggleName: TOGGLES.TASK_FEATURE,
            },
            {
                id: 'companyData',
                name: 'Company Data',
                icon: 'custom-icon-company-data',
                route: '/company_data',
                authTag: 'company-data',
            },
            {
                id: 'reportingCenter',
                name: 'Reporting Center',
                icon: 'custom-icon-reporting-center',
                route: '/reporting_center',
                authTag: 'reporting-center',
                featureToggleName: TOGGLES.REPORTING_CENTER_FEATURE,
            },
            {
                id: 'supportCenter',
                name: 'Support Center',
                icon: 'custom-icon-support-center',
                route: environment.supportCenterUrl,
                authTag: 'dashboard',
            },
        ],
    },
    {
        groupName: 'MENU',
        accordion: [
            {
                id: 'chemicals',
                name: 'Chemicals',
                icon: 'custom-icon-chemical',
                authTag: 'chemical',
                featureToggleName: TOGGLES.CHEMICAL_FEATURE,
                items: [
                    { route: '/chemicals/product_list', name: 'Product List', authTag: 'chemical-productlist' },
                    { route: '/chemicals/sds_search', name: 'SDS Search', authTag: 'chemical-productlist'},
                    { route: '/chemicals/sds_employee_access', name: 'SDS Center', authTag: 'chemical-sds', featureToggleName: TOGGLES.CHEMICAL_SDS },
                    { route: '/chemicals/sds_binders', name: 'SDS Binders', authTag: 'chemical-binder', featureToggleName: TOGGLES.CHEMICAL_SDS_BINDERS },
                ],
            },
            {
                id: 'sdsManagement',
                name: 'SDS Management',
                icon: 'custom-icon-sds-management',
                authTag: 'sds-management',
                items: [
                    { route: '/sds_management/requests', name: 'Requests', authTag: 'sds-management-requests' },
                    { route: '/sds_management/global_products', name: 'Global Products', authTag: 'sds-management-global-products'},
                ],
            },
            {
                id: 'incidents',
                name: 'Incidents',
                icon: 'custom-icon-incidents',
                authTag: 'incident',
                featureToggleName: TOGGLES.INCIDENT_FEATURE,
                items: [
                    { route: '/incidents/incident_recordkeeping', name: 'Incident Recordkeeping', authTag: 'incident-recordkeeping' },
                    { route: '/incidents/employment_data', name: 'Employment Data', authTag: 'incident-employment', featureToggleName: TOGGLES.INCIDENT_EMPLOYMENT_DATA },
                    { route: '/incidents/incident_trend_analysis', name: 'Incident Trends Analysis' , authTag: 'incident-trend', featureToggleName: TOGGLES.INCIDENT_TREND_GRID },
                    { route: '/incidents/incident_osha_reports', name: 'Incident & OSHA Reports', authTag: 'incident-report', featureToggleName: TOGGLES.INCIDENT_OSHA_REPORTS },
                ],
            },
            {
                id: 'training',
                name: 'Training',
                icon: 'custom-icon-training',
                authTag: 'training',
                featureToggleName: TOGGLES.TRAINING_FEATURE,
                items: [
                    { route: '/training/classroom_programs', name: 'Classroom Programs', authTag: 'training-programs', featureToggleName: TOGGLES.TRAINING_PROGRAMS },
                    { route: '/training/classroom_training_events', name: 'Classroom Training Events', authTag: 'training-events', featureToggleName: TOGGLES.TRAINING_EVENTS },
                    { route: 'training/training_asset_library', name: 'Training Asset Library', authTag: 'training-assets', featureToggleName: TOGGLES.TRAINING_ASSET_LIBRARY },
                    { route: 'training/online_courses', name: 'Online Courses', authTag: 'training-online', featureToggleName: TOGGLES.TRAINING_ONLINE },
                    { route: 'training/enrollment_orders', name: 'Enrollment Orders', authTag: 'training-online'},
                ],
            },
            {
                id: 'audits',
                name: 'Audits',
                icon: 'custom-icon-audit',
                authTag: 'audit',
                featureToggleName: TOGGLES.AUDIT_FEATURE,
                items: [
                    { route: '/audits/checklists', name: 'Checklists', authTag: 'audit-checklist', featureToggleName: TOGGLES.AUDIT_CHECKLISTS },
                    { route: '/audits/audit_events', name: 'Audit Events', authTag: 'audit-events', featureToggleName: TOGGLES.AUDIT_EVENTS },
                    { route: '/audits/question_library', name: 'Question Library', authTag: 'audit-questions', featureToggleName: TOGGLES.AUDIT_QUESTION_LIBRARY },
                    { route: '/audits/selfassignment_checklists', name: 'Self-Assignment Checklists', authTag: 'audit-checklist', featureToggleName: TOGGLES.AUDIT_SELF_ASSIGNMENT },
                ],
            },
            {
                id: 'medical',
                name: 'COVID-19 Support',
                icon: 'custom-icon-medical',
                route: '/medical_recordkeeping',
                authTag: 'covid-medical,view',
                featureToggleName: TOGGLES.COVID_RECORDKEEPING,

            },
            {
                id: 'plansPolicies',
                name: 'Plans & Policies',
                icon: 'custom-icon-plans',
                route: '/plans_policies',
                authTag: 'pnp',
                featureToggleName: TOGGLES.PLANS_POLICIES_FEATURE,
            },
            {
                id: 'topicIndex',
                name: 'Topic Index',
                icon: 'custom-icon-topic-index',
                route: '/topic_index',
                authTag: 'topic',
                featureToggleName: TOGGLES.TOPIC_INDEX_FEATURE,
            },
            {
                id: 'regSense',
                name: 'RegSense',
                icon: 'custom-icon-regsense',
                route: '/regsense',
                authTag: 'regsense',
                featureToggleName: TOGGLES.REGSENSE_FEATURE,
            },
            {
                id: 'news',
                name: 'News',
                icon: 'custom-icon-news',
                route: '/news',
                authTag: 'news',
                featureToggleName: TOGGLES.NEWS_FEATURE,
            },
        ],
    },
];
