import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { cspRules } from '@components/csp-chemicals-label-configurator/csp-rules';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { environment } from '@env/environment';
import { CreateSheetLabelResponse } from '@models/entity-models/chemicals/csp/createSheetLabelResponse';
import { CreateSheetLabelValues } from '@models/entity-models/chemicals/csp/createSheetLabelValues';
import { ApiFactory } from '@services/core/api-factory.class';
import { ScriptService } from '@services/script.service';
import { SplitioService } from '@services/splitio.service';
import { isNullOrUndefined } from '@utilities/helpers';

declare let aperture;
declare let settings;

@Component({
    selector: 'csp-chemicals-label-configurator',
    templateUrl: './csp-chemicals-label-configurator.component.html',
    styleUrls: ['./csp-chemicals-label-configurator.component.scss'],
})
export class CSPChemicalLabelConfiguratorComponent implements AfterViewInit {

    productFamily = 'HAZCHEMLABEL';
    iframeCSP_URL: string;
    readyConfigurator: any;
    iframe_Element: HTMLIFrameElement;

    @Input() width = 0;
    @Input() height = 0;

    private _data: any;
    @Input()
    set data(data: any) {
        if (!isNullOrUndefined(data)) {
            if (data !== this._data) {
                this._data = data;
                this._data.ProductFamily = this.productFamily;
                this._data.ProductName = ` ${this._data.ProductName}`; // add space for padding the header in the label
                this.onValidDataChange();
            }
        }
    }
    get data() { return this._data; }

    @Output() actionClear = new EventEmitter();
    @Output() labelDownloading = new EventEmitter();
    @Output() labelDownloaded = new EventEmitter();

    constructor(private scriptService: ScriptService,
                private splitioService: SplitioService) {

        this.splitioService.getToggle(TOGGLES.CHEMICAL_LABELS).then((value) => {
            if (value === TREATMENT.ON) {
                this.scriptService.load('csp', 'porthole').then((_) => {
                    jQuery(document).ready(($) => {
                        const proxyDomain = this.cspProxyDomain();
                        const iframeSrc = this.iframeUrl();
                        const rulesMapConfig = cspRules;

                        // ------------------------------------------------
                        // ---->> SIZES
                        // ------------------------------------------------
                        const Sizes = [
                            {
                                HeightInInches: 7.75,
                                WidthInInches: 4.75,
                                Legend: '4-3/4 x 7-3/4',
                            },
                            {
                                HeightInInches: 7.0,
                                WidthInInches: 3.0,
                                Legend: '3 x 7',
                            },
                            {
                                HeightInInches: 3.5,
                                WidthInInches: 2,
                                Legend: '2 x 3-1/2',
                            },
                            {
                                HeightInInches: 8.125,
                                WidthInInches: 5.0,
                                Legend: '5 x 8-1/8',
                            },
                            // -----------------------------------------------
                            {
                                HeightInInches: 4.75,
                                WidthInInches: 7.75,
                                Legend: '7-3/4 x 4-3/4',
                            },
                            {
                                HeightInInches: 3.0,
                                WidthInInches: 7.0,
                                Legend: '7 x 3',
                            },
                            {
                                HeightInInches: 2,
                                WidthInInches: 3.5,
                                Legend: '3-1/2 x 2',
                            },
                            {
                                HeightInInches: 5.0,
                                WidthInInches: 8.125,
                                Legend: '8-1/8 x 5',
                            },
                         {
                            HeightInInches: 3.333,
                            WidthInInches: 4.0,
                            Legend: '4 x 3-1/3',
                        },
                        {
                            HeightInInches: 4.0,
                            WidthInInches: 3.333,
                            Legend: '3-1/3 x 4'
                        },];

                        function mapSizeToValue(width: number, height: number) {
                            if (width === 4.75 && height === 7.75) { return CreateSheetLabelValues.AveryPresta94255Rectangle; }
                            if (width === 7.75 && height === 4.75) { return CreateSheetLabelValues.AveryPresta94255Rectangle; }
                            if (width === 3 && height === 7) { return CreateSheetLabelValues.AveryPresta94250Rectangle; }
                            if (width === 7 && height === 3) { return CreateSheetLabelValues.AveryPresta94250Rectangle; }
                            if (width === 2 && height === 3.5) { return CreateSheetLabelValues.AveryPresta94238Rectangle; }
                            if (width === 3.5 && height === 2) { return CreateSheetLabelValues.AveryPresta94238Rectangle; }
                            if (width === 5 && height === 8.125) { return CreateSheetLabelValues.AveryPresta94259Rectangle; }
                            if (width === 8.125 && height === 5) { return CreateSheetLabelValues.AveryPresta94259Rectangle; }
                            if (width === 3.333 && height === 4.0) {return CreateSheetLabelValues.AveryPresta94215Rectangle; }
                            if (width === 4.0 && height === 3.333) {return CreateSheetLabelValues.AveryPresta94215Rectangle; }
                            return 1;
                        }

                        function OnMessage(messageEvent) {
                            if (messageEvent && messageEvent.origin === proxyDomain) {
                                const data = messageEvent.data;
                                if (data && data.Success && data.MessageType) {
                                    if (data.MessageType === 'AddToCart') {
                                        const loadingIndicator = document.getElementById('loadingIndicator');
                                        loadingIndicator.setAttribute('style', 'display:block');
                                        const sizeValue = mapSizeToValue(+data.WidthInInches, +data.HeightInInches);
                                        const labelDownloadingButton = document.getElementById('labelDownloadingButton');
                                        const labelDownloadedButton = document.getElementById('labelDownloadedButton');
                                        labelDownloadingButton.click();

                                        ApiFactory.retrieveEntity(ApiEntityTypesEnum.Chemical)
                                            .addRouteHint('CreateSheetLabel')
                                            .addDataEntry('designId', data.DesignId)
                                            .addDataEntry('selectedSize', sizeValue)
                                            .addSuccessHandler((response: CreateSheetLabelResponse) => {
                                                window.open(response.blobStorageUri, '_blank');
                                                loadingIndicator.setAttribute('style', 'display:none');
                                                labelDownloadedButton.click();
                                            })
                                            .buildAndSend();

                                        // Clear iframe
                                        const _iframe = document.getElementById('configurator') as HTMLIFrameElement;
                                        _iframe.src = iframeSrc;
                                        _iframe.width = '0';
                                        _iframe.height = '0';
                                    }
                                }
                            }

                            if (messageEvent.data === 'scrollTop') {
                                if ($(document).scrollTop() !== 0) {
                                    const previewContainer = $('#configurator');
                                    $('html, body').animate({ scrollTop: previewContainer.offset().top }, 'slow');
                                    if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
                                        previewContainer.css('overflow-y', 'hidden');
                                        previewContainer.scrollTop(0);
                                        previewContainer.css('overflow-y', 'auto');
                                    }
                                    settings.messageOut('scrollTop');
                                }
                            }
                        }

                        function selectMySize(selectedSize: any) {
                            const key = selectedSize?.HeightInInches + 'x' + selectedSize?.WidthInInches;
                            const rulesMap = settings.rulesMapConfig;

                            return rulesMap[key] || 'Not FOUND!';
                        }

                        this.readyConfigurator = aperture.configuratorWindow({
                            proxy: proxyDomain + '/cspconfigurator/proxy',
                            iframeName: 'configurator',
                            messageCallBack: OnMessage,
                            ChemAppEditorRules: selectMySize,
                            Sizes,
                            rulesMapConfig,
                        }, $);
                    });
                });
            }
        });

        this.iframeCSP_URL = this.iframeUrl();
    }

    ngAfterViewInit() {
        this.loadIframe();
    }

    onValidDataChange() {
        this.readyConfigurator.init(this._data);
        if (this.iframe_Element.src === 'about:blank') {
            this.reloadIframe();
        }
    }

    loadIframe() {
        this.iframe_Element = document.getElementById('configurator') as HTMLIFrameElement;
        this.iframe_Element.src = this.iframeCSP_URL;
    }

    reloadIframe() {
        this.iframe_Element = document.getElementById('configurator') as HTMLIFrameElement;
        this.iframe_Element.src = this.iframeCSP_URL;
        this.iframe_Element.width = this.width.toString();
        this.iframe_Element.height = this.height.toString();

        setTimeout(() => {
            this.readyConfigurator.init(this._data);
        }, 300);
    }

    actionClearEmit(event) {
        this.actionClear.emit();
    }

    labelDownloadingEmit() {
        this.labelDownloading.emit();
    }

    labelDownloadedEmit() {
        this.labelDownloaded.emit();
    }

    private iframeUrl() {
        return `${environment.cspChemicalLabelConfiguratorIframesrc}`;
    }

    private cspProxyDomain() {
        return `${environment.cspProxyDomain}`;
    }
}
