import { Component, EventEmitter, forwardRef, Input, Output, PipeTransform } from '@angular/core';
import { DataGridColumnComponent } from '@components/datagrid-column/datagrid-column.component';

@Component({
    selector: 'jjk-datagrid-column-text',
    template: `<ng-content></ng-content>`,
    providers: [{ provide: DataGridColumnComponent, useExisting: forwardRef(() => DataGridColumnTextComponent) }],
})
export class DataGridColumnTextComponent extends DataGridColumnComponent {
    @Input() public popoverContent: string;
    @Input() public popoverTitle: string;

    @Output() action = new EventEmitter();

    constructor() {
        super();
        this._columnType = 'Text';
    }

}
