<div class="col-md-12">
  <form [formGroup]="formGroup?.baseForm" (ngSubmit)="submit()" *ngIf="dynamicForm?.formControls" class="form-group">
    <div class="form-group" *ngFor="let control of dynamicForm.formControls">
      <dynamic-form-control [controlData]='control'></dynamic-form-control>
    </div>
    <ng-content></ng-content>
    <section class="edit-actions text-end">
      <div class="col-lg-8">
        <back-button></back-button>
        <button data-bs-toggle="collapse" class="btn btn-primary" type="submit">
          Save
        </button>
      </div>
    </section>
  </form>
</div>
