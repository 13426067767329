import { Params } from '@angular/router';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { RootNavigationTypesEnum } from '../../constants/enums/root-navigation-types.enum';

export class NavigationEntry {
    urlParams: Params;

    public children: NavigationEntry[];

    public static async FromRouteString(url: string): Promise<NavigationEntry> {
        const ne = new NavigationEntry();

        if (!url) {
            ne.urlRoute = '';
            return ne;
        }

        ne.urlRoute = url;

        if (url.startsWith('/')) { url = url.substring(1); }

        // are there params?
        const paramIndex = url.indexOf('?');
        if (paramIndex > -1) {
            ne.addParamFromQueryString(url.substring(paramIndex + 1));
            ne.urlRoute = url.substring(0, paramIndex);
        }
        return ne;
    }

    private static async findRouteEntry(url: string, navEntry: NavigationEntry = null): Promise<NavigationEntry> {

        if (navEntry.urlRoute === url) {
            return navEntry;
        }

        const routesAsNavEntries = Object.keys(navEntry.children).map((key) => navEntry.children[key] as unknown as NavigationEntry);

        for (let i = 0; i < routesAsNavEntries.length; i++) {
            return await NavigationEntry.findRouteEntry(url, routesAsNavEntries[i]);
        }

        return null;
    }

    public addParamFromQueryString(params: string) {
        const kvp = params.split('&');
        this.urlParams = kvp;
    }

    public get AssignedEntity() {
        return this.optionalEntityType ? this.optionalEntityType : this.optionalRootType;
    }

    asFullUrl(): string {
        let url = `${this.urlRoute}`;
        if (this.urlParams) {
            url += '?';
            this.urlParams.forEach((entry) => {
                url += `${entry}&`;
            });
            url = url.substr(0, url.length - 1);
        }

        return url;
    }

    constructor(
        public optionalEntityType?: ApiEntityTypesEnum,
        public optionalRootType?: RootNavigationTypesEnum,
        public urlRoute: string = undefined,
        public breadCrumbText: string = undefined,
        public dataModel: any = undefined) {
    }
}
