export enum HazardTypeEnum {
  Hazard = '0f4e1d11-d1b1-4ef4-b0f6-137c341ca982',
  Precautionary = '7fe5bbf2-b4df-4865-846f-98ffc5015c36',
  Supplemental = '27e4985c-984a-4873-9fc0-e66b5fbf14c8'
}

export const HazardTypeMap = new Map([
  [HazardTypeEnum.Hazard, 'Hazard'],
  [HazardTypeEnum.Precautionary, 'Precautionary'],
  [HazardTypeEnum.Supplemental, 'Supplemental'],
]);

export const HazardTypeDescMap = new Map([
  ['Hazard', HazardTypeEnum.Hazard],
  ['Precautionary', HazardTypeEnum.Precautionary],
  ['Supplemental', HazardTypeEnum.Supplemental],
]);