import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { DataGridColumnComponent } from '@components/datagrid-column/datagrid-column.component';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';

@Component({
    selector: 'jjk-datagrid-column-toggle',
    templateUrl: './datagrid-column-toggle.component.html',
    providers: [{ provide: DataGridColumnComponent, useExisting: forwardRef(() => DataGridColumnToggleComponent) }],
})

export class DataGridColumnToggleComponent extends DataGridColumnComponent implements OnInit {
    @Input() component: any;
    @Input() row: any;
    @Input() columnName: string;
    @Input() disableWhen: boolean;
    @Input() popoverContent: string;
    @Input() popoverTitle: string;

    @Output() action = new EventEmitter();

    toggleValue = false;
    isDisabled = false;

    constructor() {
        super();
        this._columnType = 'Toggle';
    }

    ngOnInit() {
        if (this.row) {
            this.toggleValue = this.row[this.columnName];
            this.isDisabled = this.disableWhen === this.toggleValue;
            if (!this.isDisabled) {
                const split = this.authFeature.toString().split(',');
                const domain = split[0];
                const type = (split.length > 1) ? split[1] : '';
                const show = LoggedInUserInfo.Instance.userInfo.checkAuthFeature(domain, type, this.row);
                this.isDisabled = (!show && split.length === 3 && split[2] === 'disable') ? true : false;
            }
        }
    }

    valueChanged(component, row, columnName) {
        this.row[this.columnName] = this.toggleValue;
        this.action.emit({ component, row, columnName });
    }
}
