<div [ngSwitch]="controlData.type">
  <div *ngSwitchCase="'file-upload'">
    <dynamic-form-control-file-upload [controlData]="controlData"></dynamic-form-control-file-upload>
  </div>
  <div id="textDiv" *ngSwitchCase="'text'">
    <dynamic-form-control-text [controlData]="controlData"></dynamic-form-control-text>
  </div>
  <div *ngSwitchCase="'rte'">
    <dynamic-form-control-richedit [controlData]="controlData"></dynamic-form-control-richedit>
  </div>
  <div *ngSwitchCase="'select'">
    <dynamic-form-control-select [controlData]="controlData" [resetControl]="resetControl"></dynamic-form-control-select>
  </div>
  <div *ngSwitchCase="'select-multi'">
    <dynamic-form-control-multi-select [controlData]="controlData"></dynamic-form-control-multi-select>
  </div>
  <div *ngSwitchCase="'tree-select'">
    <dynamic-form-control-tree-select [controlData]="controlData"></dynamic-form-control-tree-select>
  </div>
  <div *ngSwitchCase="'date'">
    <dynamic-form-control-date-picker [controlData]="controlData"></dynamic-form-control-date-picker>
  </div>
  <div *ngSwitchCase="'slider'">
    <dynamic-form-control-slider [controlData]="controlData"></dynamic-form-control-slider>
  </div>
  <div *ngSwitchDefault>
    <dynamic-form-control-text [controlData]="controlData" [type]="controlData.type"></dynamic-form-control-text>
  </div>
</div>
