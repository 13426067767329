import { Dictionary } from '../dictionary';
import { MessageType } from '../messageType';

/*******************************************************************************************
 * This is the practical numeric ID basse class for all messages.  This class manages space
 * for topics and private routing.
 *******************************************************************************************/
export class Message {

    // this is used during handler registration
    public static messageRegistrationKey: MessageType = 'Message';

    // each message must have a unique id, if one is not provided, we gen one from this root
    private static _root_id = 0;
    private _msgId: number;

    public action: string; // this should probably be an enum
    public messageData: Dictionary = new Dictionary();
    public senderId: number | string;
    public isUserActivity = true;

    public get id() {
        return this._msgId;
    }

    // this is used in message identification
    public get messageType(): MessageType {
        return Message.messageRegistrationKey;
    }

    /** Topics are ignored when Broadcasting, but otherwise this collection serves
     * to build part of the filtering pipeline in delivering to proper recipients */
    public topics: string[] = [];

    /** isPrivate instructs the message recipient to specify a specif recipientId
     *  when sending the response message.  The recipientId is the senderId from
     *  the message creator
     */
    public get isPrivate() {
        return this.recipientId !== -1;
    }

    /** The recipientId is used during filtering to determine the intended recipient
     * of a message sould it be marked private.  This value is usally assigned when
     * an initiating message provides the senderId along with the request.
     */
    public recipientId = -1;

    public isResponse = false;

    protected set(sId: null | number, topics: string | string[] | null, action: string = null, messageParts: Dictionary = {}) {

        this.senderId = sId || -1 * Message._root_id++;
        this.action = action;
        this.messageData = messageParts;

        if (topics) {
            this.topics = [].concat(topics);
        }
    }

    public setTopic(topic: string): Message {
        if (this.topics.indexOf(topic) < 0) {
            this.topics.push(topic);
        }
        return this;
    }
}
