
<div class="grid-wrapper" [ngStyle]="{'height': this.height, 'max-height': this.maxHeight, 'margin-bottom': this.marginBottom}">
    <div [ngStyle]="style">
        <ag-grid-quick-actions #agGridQuickActions *ngIf="showQuickActions && !loading"
            [quickActionsField]="quickActionsField"
            [quickActions]="quickActions"
            [quickActionsValues]="quickActionsValues"
            [quickActionCounts]="quickActionCounts"
            [filterValues]="quickActionsColumnFilter"
            (selectionChanged)="quickActionsChanged($event)"
            (resize)='fixMargin($event)'
            [justifyContent]="justifyContent">
        </ag-grid-quick-actions>

        <ag-grid-custom-toolbarV2 *ngIf="showSelectAllToolbar"
            [hidden]="loading"
            [rowCount]="selectedRowsCount"
            [showSelectAll]="true"
                (action)="onToolbarActionClick($event)">
        </ag-grid-custom-toolbarV2>

        <ag-grid-angular #agGrid class="ag-theme-alpine" style="width: 100%; height: 95%;"
            [ngClass]="{'grid-with-settings-panel' : !this.hideSettingsToolPanel && (this.gridId || this.hideSaveViews)}"
            [context]="context"
            [rowSelection]="rowSelection"
            [rowData]="gridRowData"
            [columnDefs]="columnDefinition"
            [defaultColDef]="defaultColumnDefinition"
            [gridOptions]="gridOptions"
            [sideBar]="sideBar"
            [getContextMenuItems]="getContextMenuItems"
            [components]="components"
            [allowDragFromColumnsToolPanel]="true"
            [overlayLoadingTemplate]="overlayLoadingTemplate"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [animateRows]="false"
            [rowHeight]="rowHeight"
            [suppressRowTransform]="true"
            [enableRangeSelection]="true"
            [suppressRowClickSelection]="true"
            [processCellForClipboard]="processCellForClipboard"
            (filterChanged)="onFilterChanged()"
            (columnResized)="onColumnResized()"
            (sortChanged) = "onSortChanged()"
            (columnPinned)="onColumnPinned()"
            (displayedColumnsChanged)="onGridColumnsChanged()"
            (gridReady)="onGridReady($event)"
            (cellFocused)="cellFocused($event)"
            (selectionChanged)="onSelectionChanged($event)"
            [rowModelType]="gridMode"
            [suppressServerSideInfiniteScroll]="'false'"
            [pagination]="showPagination"
            [paginationPageSize]="pageSize"
            [cacheBlockSize]="pageSize"
            [blockLoadDebounceMillis]="1000"
            [maxBlocksInCache]="3"
            [enableCharts]="enableCharts"
            [icons]="icons"
            [isRowSelectable]="isRowSelectable"
            [getRowId]="getRowId"
            (storeRefreshed)="refreshCustomActions()">
        </ag-grid-angular>
    </div>
</div>
