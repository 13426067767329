import { Component, EventEmitter, Output } from '@angular/core';
import { ColumnVisibleEvent, IClipboardCopyRowsParams, IToolPanel } from 'ag-grid-community';
import { GridMode } from '@constants/enums/grid-mode.enum';
import { AGGridCustomToolPanelParams } from '@models/ag-grid/ag-grid-custom-tool-panel-params.model';
import { formatColumnsForExportAndClipboard } from '@utilities/grid-helpers';

@Component({
  selector: 'ag-grid-custom-toolpanel',
  templateUrl: './ag-grid-custom-toolpanel.component.html',
  styleUrls: ['./ag-grid-custom-toolpanel.component.scss'],
})
export class AGGridCustomToolPanelComponent implements IToolPanel {

    @Output() onDelete: EventEmitter<void> = new EventEmitter();
    params: AGGridCustomToolPanelParams;
    itemsSelected = false;
    isGridActionsHidden = false;
    showExportAll = false;
    agInit(params: AGGridCustomToolPanelParams): void {
        this.params = params;
        this.params.api.addEventListener(
            'selectionChanged',
            this.refreshSelected.bind(this)
          );
        this.params.api.addEventListener(
            'filterChanged',
            this.refreshSelected.bind(this)
          );
        this.params.api.addEventListener('columnVisible', this.columnVisible.bind(this));

        if (this.params.onExportAll !== undefined) {
            this.showExportAll = true;
        }
    }

    refresh(): boolean {
        return true;
    }

    refreshSelected(): void {
        let selectedNodeFound = false;
        if (this.params.rowModelType === GridMode.ClientSideRowModel) {
            this.params.api.forEachNodeAfterFilter(node => {
                // can't break out of this so the return should at least be slightly faster
                if (selectedNodeFound) { return; }
                if (node.isSelected())
                {
                    selectedNodeFound = true;
                }
            });
            this.itemsSelected = selectedNodeFound;
        }
        else{
            this.itemsSelected = this.params.api.getSelectedNodes()?.length > 0;
        }
    }

    GenerateReport(e: Event, type: string, onlySelected: boolean) {
        if (type === 'CSV')
        {
            this.params.api.exportDataAsCsv({
                columnKeys: this.getDisplayedColumnKeys(),
                onlySelected,
                processCellCallback: (params) => {
                    return formatColumnsForExportAndClipboard(params);
                },
            });
        }
        else
        {
            this.params.api.exportDataAsExcel({
                columnKeys: this.getDisplayedColumnKeys(),
                onlySelected,
                processCellCallback: (params) => {
                    return formatColumnsForExportAndClipboard(params);
                },
            });
        }
    }

    Copy(e: Event) {
        const clipboardCopyRowsParams = {
            includeHeaders: false,
            columnKeys: this.getDisplayedColumnKeys(),
        } as IClipboardCopyRowsParams
        this.params.api.copySelectedRowsToClipboard(clipboardCopyRowsParams)
    }

    CopyWithHeaders(e: Event) {
        const clipboardCopyRowsParams = {
            includeHeaders: true,
            columnKeys: this.getDisplayedColumnKeys(),
        } as IClipboardCopyRowsParams
        this.params.api.copySelectedRowsToClipboard(clipboardCopyRowsParams)
    }

    printForm301(e: Event) {
        this.params.onPrint301();
    }

    Delete(e: Event) {
        this.params.onDelete();
    }

    findAndMonitorSDSs(e: Event) {
        this.params.onFindAndMonitorSDSs();
    }

    CompanyUsage(e: Event) {
        this.params.onCompanyUsage();
    }

    massDataGroupUpdate(e: Event) {
        this.params.onMassDataGroupUpdate();
    }

    onExportAllClick() {
        this.params.onExportAll();
    }

    MakeActive(e: Event) {
        this.params.onMakeActive();
    }

    MakeInactive(e: Event) {
        this.params.onMakeInactive();
    }

    ResetLearnerPassword(e: Event) {
        this.params.onResetLearnerPassword();
    }

    getDisplayedColumnKeys(IncludeIgnoredColumns = false)
    {
        return this.params.api.getAllDisplayedColumns().map((item) => item.getId()).filter(x => IncludeIgnoredColumns || !this.params.columnsToIgnore.includes(x));
    }
    columnVisible(params: ColumnVisibleEvent) {
        if (params.api.getAllDisplayedColumns().length === 0) {
            this.isGridActionsHidden = true;
        }
        else {
            this.isGridActionsHidden = false;
        }
    }

    onAdditionalActionClick(action) {
        this.params['ChildComponentAction'] = action;
        this.params.action(this.params);
    }
}
