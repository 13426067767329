/**
 * Object mapping column priority indexes to screen width (px).
 * Values are upper bounds of visibility.
 * Example: A column with priority 3 will hide once screen width < 640px.
 */
const ColumnBreakpoints = {
    0: 0, // use to indicate columns that start manuallyToggled and thus never hide on resize
    1: 320,
    2: 480,
    3: 640,
    4: 800,
    5: 960,
    6: 1120,
};
export default ColumnBreakpoints;
