import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[customInputRestriction]',
    providers: [{provide: NG_VALIDATORS, useExisting: CustomInputRestrictionDirective, multi: true}]
})
export class CustomInputRestrictionDirective implements Validator, OnChanges {
    inputElement: ElementRef;

    @Input('customInputRestriction') restrictedInput: string;

    onChange: () => void;

    constructor(el: ElementRef) {
        this.inputElement = el;
    }
    validate(control: AbstractControl): ValidationErrors {
        return this.validatorFn(control.value);
    }
    registerOnValidatorChange?(fn: () => void): void {
        this.onChange = fn;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('customInputRestriction' in changes) {
          if (this.onChange) {
              this.onChange();
            }
        }
    }

    validatorFn(value: string): ValidationErrors {
        if (value.split('').some(ch => this.restrictedInput.indexOf(ch) !== -1))
        {
            return { customInputRestriction : 'The following characters are invalid: ' + this.restrictedInput.split('').join(' ') };
        }
        else {
            return null;
        }
    }

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        const e = event as KeyboardEvent;
        if (this.restrictedInput.indexOf(e.key) !== -1)
        {
            e.preventDefault();
        }
    }
}
