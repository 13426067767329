import { Column, ColumnResizedEvent, ColumnVisibleEvent, GridApi, GridReadyEvent } from 'ag-grid-community';
import { ProcessCellForExportParams } from 'ag-grid-community/dist/lib/interfaces/exportParams';
import * as moment from 'moment';
import { agGridGrids } from '@constants/aggrid-grids';
import { AgGridPredefinedState } from '@constants/enums/ag-grid-predefined-state.enum';
import { GridView } from '@models/entity-models/autogenerated/gridview';
import { ReportColumnConfig } from '@models/entity-models/reportingcenter/report-columns-config';
import { isNullOrEmpty, isNullOrEmptyDate, isNullOrUndefined } from './helpers';

export function notPopulated(value: any, renderer = false, emptyOnGroupRow = false): string {
    return emptyOnGroupRow ? '' : ((isNullOrEmpty(value) || isNullOrUndefined(value)) ? notPopulatedRenderer(renderer) : value.toString());
}

export function notPopulatedConcatTwoValues(value1: any, value2: any, renderer = false): string {
    if ((isNullOrEmpty(value1) || isNullOrUndefined(value1)) && (isNullOrEmpty(value2) || isNullOrUndefined(value2)))
        return notPopulatedRenderer(renderer);

    return (!isNullOrUndefined(value1) ? value1.toString() : '') +' '+ (!isNullOrUndefined(value2) ? value2.toString() : '');
}

export function notPopulatedDataMissing(value: any, renderer = false, emptyOnGroupRow = false): string {
    return emptyOnGroupRow ? '' : ((isNullOrEmpty(value) || isNullOrUndefined(value)) ? notPopulatedDataMissingRenderer(renderer) : value.toString());
}

export function notPopulatedDate(value: any, renderer = false, emptyOnGroupRow = false): string {
    return emptyOnGroupRow  ? '' : (isNullOrUndefined(value) || isNullOrEmptyDate(value) || (moment(value) < moment('1900/01/02'))? notPopulatedRenderer(renderer) : moment(value).format('MM/DD/YYYY'));
}

export function notPopulatedTime(value: any, renderer = false, emptyOnGroupRow = false): string {
    return emptyOnGroupRow  ? '' : (isNullOrUndefined(value) || isNullOrEmptyDate(value) ? notPopulatedRenderer(renderer) : moment(value).format('hh:mm A'));
}

export function hmsToHrsMinsSecs(str: string) {
    const p = str.split(':');

    if (p.length == 3) {
        const seconds = (+p[0]) * 60 * 60 + (+p[1]) * 60 + (+p[2]);
        const numHours = Math.floor(seconds/ 3600);
        const numMinutes = Math.floor((seconds % 3600) / 60);
        const numSeconds = (seconds % 3600) % 60;
        let finalTime: string = '';
        if (numHours) {
            finalTime += numHours + (numHours > 1 ? ' Hrs ' : ' Hr ');
        }
        if (numMinutes) {
            finalTime += numMinutes + ' Mins ';
        }
        if (numSeconds) {
            finalTime += numSeconds + ' Secs';
        }
        return finalTime.trimEnd();
    } else {
        return str;
    }
}

export function notPopulated24hTime(value: any, renderer = false): string {
    return isNullOrUndefined(value) || isNullOrEmptyDate(value) ? notPopulatedRenderer(renderer) : moment(value).format('HH:mm');
}

export function notPopulatedWithSemicolon(value: any, renderer = false): string {
    return (isNullOrEmpty(value) || value === 'undefined') ? notPopulatedRenderer(renderer) : value.toString().replace(',', '; ').replace(';;', ';');
}

export function notPopulatedRenderer(renderer = false): string {
    return renderer ? `<span class="italic">(not populated)</span>` : '(Not Populated)';
}

export function notPopulatedDataMissingRenderer(renderer = false): string {
    return renderer ? `<span class="italic">(Data Missing)</span>` : '(Data Missing)';
}

export function activeOrInactive(value: any): string {
    return value ? 'Active' : 'Inactive';
}

export function yesOrNo(value: any, emptyOnGroupRow = false): string {
    return emptyOnGroupRow  ? '' : (value ? 'Yes' : 'No');
}

export function notPopulatedNumber(value: any, emptyOnGroupRow = false): string {
    return emptyOnGroupRow  ? '' : (isNullOrUndefined(value) ? 0 : value.toString());
}

export function defaultGridInit(params: GridReadyEvent) {
    bindGridDefaultEvents(params);
}

export function bindGridDefaultEvents(params: GridReadyEvent) {
    params.api.addEventListener('columnResized', onColumnResized);
    params.api.addEventListener('columnVisible', onColumnVisible);
    params.api.addEventListener('columnPinned', onColumnPinned);
}

export function onColumnResized(params: ColumnResizedEvent) {
    params.api.resetRowHeights();
}

export function onColumnVisible(params: ColumnVisibleEvent) {
    if (params.api.getAllDisplayedColumns().every(x => x.getColDef().suppressColumnsToolPanel)) {
        params.api.setColumnsVisible(params.api.getAllDisplayedColumns(), false);
    }
    else if (params.api.getAllDisplayedColumns().length > 0) {
        params.api.setColumnsVisible(params.api.getColumns().filter(x => x.getColDef().suppressColumnsToolPanel), true);
    }
    params.api.resetRowHeights();
}

export function stringDateComparator(filterDate: Date, cellValue: any): number {
    const dateAsString = cellValue;
    if (dateAsString === null || dateAsString === '') { return -1; }
    const cellDate = new Date(cellValue);
    if (filterDate.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterDate) {
      return -1;
    }
    if (cellDate > filterDate) {
      return 1;
    }
}

export function timeComparatorForDateTypes(date1: Date, date2: Date): number {
    if (date1 === null) { return -1; }
    if (date2 === null) { return 1; }
    const time1 = new Date().setHours(date1.getHours(), date1.getMinutes(), date1.getSeconds());
    const time2 = new Date().setHours(date2.getHours(), date2.getMinutes(), date2.getSeconds());
    if (time1 === time2) {
      return 0;
    }
    if (time1 > time2) {
      return 1;
    }
    if (time1 < time2) {
      return -1;
    }
}

export function onColumnPinned(event) {
    const allCols = event.api.getAllGridColumns();
    const allFixedCols = allCols.filter((col) => col.getColDef().lockPosition);
    const allNonFixedCols = allCols.filter((col) => !col.getColDef().lockPosition);
    const pinnedCount = allNonFixedCols.filter((col) => col.getPinned() === 'left').length;
    const pinFixed = pinnedCount > 0;
    const columnStates = [];
    allFixedCols.forEach((col) => {
            if (pinFixed !== col.isPinned()) {
                columnStates.push({
                    colId: col.getId(),
                    pinned: pinFixed ? 'left' : null,
                });
            }
        });
    if (columnStates.length > 0) {
      event.api.applyColumnState({ state: columnStates });
    }
  }

export function formatColumnsForExportAndClipboard(params: ProcessCellForExportParams): string {
    // TODO: Find a way to do this generically
    switch (params.column.getColId()) {
        case 'reviewedOn':
        case 'sdsVersionDate':
        case 'usageStartDate':
        case 'usageEndDate':
        case 'deathDate':
        case 'hireDate':
        case 'terminationDate':
        case 'birthDate':
        case 'modifiedDate':
        case 'incidentDate':
        case 'dateOfBirth':
            return notPopulatedDate(params.value);
        case 'incidentTime':
            return notPopulatedTime(params.value);
        case 'year':
        case 'monthlyNumOfEmployees':
        case 'hoursWorked':
        case 'oshaIncidenceRate':
        case 'dartRate':
        case 'ltcRate':
        case 'severityRate':
            if (params.context.componentParent.gridId === agGridGrids.INCIDENT_TREND_Id) {
                return notPopulatedDataMissing(params.value);
            }
            else {
                return params.value;
            }
        case 'peakEmploymentCountName':
            // this field can be 1-19 which shows as a date when csv is opened in excel, so format it so it won't show as a date in excel, but only for the csv format.
            if (params.context.componentParent.gridId === agGridGrids.INCIDENT_TREND_Id) {
                return (isNullOrEmpty(params.value) || isNullOrUndefined(params.value) || params.type !== "csv") ? notPopulatedDataMissing(params.value) : `="${params.value}"`;
            }
            else {
                return (isNullOrEmpty(params.value) || isNullOrUndefined(params.value) || params.type !== "csv") ? params.value : `="${params.value}"`;
            }
        case 'month':
            if (params.context.componentParent.gridId === agGridGrids.INCIDENT_TREND_Id) {
                return params.value ? moment(params.value, 'MM').format('MMM') : params.value;
            }
            else {
                return params.value;
            }
        default:
            return params.value;
    }
}

export function applyPredefinedGridState(stateName: AgGridPredefinedState, gridApi: GridApi, initialLoadStateQueryParams?: any) {
    switch (stateName) {
        case AgGridPredefinedState.NewChemicalSDS: {
            gridApi.setFilterModel(null);

            gridApi.applyColumnState({ state: [ { colId: 'dateAcquired', hide: false } ] });
            const daFilter = gridApi.getFilterInstance('dateAcquired');
            const date = moment(new Date()).subtract(14, 'days').format("YYYY-MM-DD");
            daFilter?.setModel({ filterType:'date', type: 'greaterThan', dateFrom: date });

            gridApi.onFilterChanged();
            break;
        }
        case AgGridPredefinedState.InfoRequiredSDS: {
            gridApi.setFilterModel(null);

            const filter = gridApi.getFilterInstance('sdsStatus');
            filter?.setModel({ values: ['Info Required']});

            gridApi.onFilterChanged();
            break;
        }
        case AgGridPredefinedState.OpenIncidents: {
            gridApi.setFilterModel(null);

            const openIncidentsFilter = gridApi.getFilterInstance("incidentStatusName");
            openIncidentsFilter?.setModel({ filterType: 'set', values: ['To Do', 'In Progress', 'Reopened']});

            gridApi.onFilterChanged();
            break;
        }
        case AgGridPredefinedState.OpenTasks: {
            gridApi.setFilterModel(null);

            const openTasksFilter = gridApi.getFilterInstance("statusId");
            openTasksFilter?.setModel({ filterType: 'set', values: ['To Do', 'In Progress', 'On Hold']});

            gridApi.onFilterChanged();
            break;
        }
        case AgGridPredefinedState.OpenOshaRecordableIncidents: {
            gridApi.setFilterModel(null);

            const incidentStatusFilter = gridApi.getFilterInstance("incidentStatusName");
            incidentStatusFilter?.setModel({ filterType: 'set', values: ['To Do', 'In Progress', 'Reopened', 'On Hold']});
            const openOshaRecordablesFilter = gridApi.getFilterInstance("incidentTypeName");
            openOshaRecordablesFilter?.setModel({ filterType: 'set', values: ['Recordable Incident', 'Other Recordable Case']});

            gridApi.onFilterChanged();
            break;
        }
        case AgGridPredefinedState.OpenIncidentTasks: {
            gridApi.setFilterModel(null);

            const associatedTypeFilter = gridApi.getFilterInstance("associatedTypeId");
            associatedTypeFilter?.setModel({ filterType: 'string', value: '9F999CF8-4ED1-491B-BE76-B0E54E0257F4'});
            const openTasksStatusFilter = gridApi.getFilterInstance("statusId");
            openTasksStatusFilter?.setModel({ filterType: 'set', values: ['To Do', 'In Progress', 'On Hold']});

            gridApi.onFilterChanged();
            break;
        }
        case AgGridPredefinedState.PastDueTrainingEvents: {
            gridApi.setFilterModel(null);

            const pastDueEventsFilter = gridApi.getFilterInstance("status");
            pastDueEventsFilter?.setModel({ filterType: 'set', values: ['Past Due']});

            gridApi.onFilterChanged();
            break;
        }
        case AgGridPredefinedState.PastDueAudits: {
                gridApi.setFilterModel(null);

                gridApi.applyColumnState({ state: [ { colId: 'dueDate', hide: false } ] });
                const dueDateFilter = gridApi.getFilterInstance('dueDate');
                const dueDate = moment(new Date()).format("YYYY-MM-DD");
                dueDateFilter?.setModel({ filterType: 'date', type: 'lessThan', dateFrom: dueDate });
                const statusFilter = gridApi.getFilterInstance("statusName");
                statusFilter?.setModel({ filterType: 'set', values: ['In Progress', 'Info Required', 'Reopened', 'To Do']});

                gridApi.onFilterChanged();
                break;
        }
        case AgGridPredefinedState.OpenAuditTasks: {
            gridApi.setFilterModel(null);

            const associatedTypeFilter = gridApi.getFilterInstance("associatedTypeId");
            associatedTypeFilter?.setModel({ filterType: 'string', value: 'DD00378D-0E59-467C-8B11-5655FE982866'});
            const openTasksStatusFilter = gridApi.getFilterInstance("statusId");
            openTasksStatusFilter?.setModel({ filterType: 'set', values: ['To Do', 'In Progress', 'On Hold']});

            gridApi.onFilterChanged();
            break;
        }
        case AgGridPredefinedState.MissingEmployeeData: {
            gridApi.setFilterModel(null);
            const statusFilter = gridApi.getFilterInstance('dataStatus');
            statusFilter?.setModel({ filterType: 'set', values: ['Data Missing', 'Partially Complete']});
            gridApi.onFilterChanged();
            break;
        }
        case AgGridPredefinedState.TrainingAsset: {
            gridApi.setFilterModel(null);
            const topicsFilter = gridApi.getFilterInstance("topics");
            topicsFilter?.setModel({
                type: 'contains',
                filter: initialLoadStateQueryParams
            });
            gridApi.onFilterChanged();
            break;
        }
        default:
            break;
    }
}

export function applyGridViewToGrid(gridView: GridView, gridApi: GridApi) {
    if (gridView.gridViewState[0].gridColumnState) {
        gridApi.applyColumnState({
            state: JSON.parse(gridView.gridViewState[0].gridColumnState),
            applyOrder: true,
        });
    }
    if (gridView.gridViewState[0].gridFilterState) {
        gridApi.setFilterModel(JSON.parse(gridView.gridViewState[0].gridFilterState));
    }
}
export function emptyOnGroupRows(params: any): boolean {
    const col = params.colDef.coldId || params.colDef.field;
    return params.node.group && col !== 'ag-Grid-AutoColumn' && !params.column.aggregationActive;
}

export function mapAndFilterColumnsForCSV(columns: Column[]): ReportColumnConfig[] {
    return columns
        .filter((column) => !column.getUserProvidedColDef().suppressColumnsToolPanel)
        .map((column) => {
                const def = column.getUserProvidedColDef();
                return {
                    columnName: def.field,
                    displayText: def.headerName,
                    format: getColumnFormat(def.filter?.toString()),
                } as ReportColumnConfig;
    })
}

export function getColumnFormat(filterType: string): string {
    if (!filterType) { return;}
    if (filterType === 'agDateColumnFilter')
        return 'ShortDate';
    if (filterType === 'agGridCustomTimeFilterComponent')
        return 'Time';
}

export function getDefaultColDef() {
    return {
        flex: 1,
        minWidth: 150,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            defaultOption: 'contains',
            buttons: ['reset']
        },
    };
}
