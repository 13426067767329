export enum RouteValidationResult {
    // If the verification needs to change / insert a route, this will be set
    routeModificationOccurred = 0,
    // Indicates the route can be navigated as is
    routeRequestIsValid,
    // indicates the route needs authorization and user has not logged in
    routeRequestNotAuthorized,
    // The changes made to the Navigation require we stop processing and refresh
    stopAndRefreshNavigation,
}
