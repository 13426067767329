import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateFormat',
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any): any {
        return value && moment(value).isAfter('1899-12-31T00:00:00Z', 'day') ? moment(value).format('MM/DD/YYYY') : '';
    }
}
