import { Component, Input } from '@angular/core';

@Component({
    selector: 'jjk-drag-and-drop-index-grid',
    template: `<ng-content></ng-content>`,
})
export class DragAndDropGridIndexComponent {

    @Input() columnName: string;
    @Input() displayText: string;
    @Input() addButton?: string;
    @Input() menuButtonDisplayText: string;

}
