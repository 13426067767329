import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Event, NavigationEnd, NavigationExtras, NavigationStart, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { RootNavigationTypesEnum } from '@constants/enums/root-navigation-types.enum';
import { ACTIONS, DATAKEYS } from '@constants/messages.constants';
import { RootRoutes } from '@constants/settings/root-routes.const';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { NonAuthResponse } from '@models/nonAuth/nonAuthResponse';
import { EnableServiceMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableServiceMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { Stack } from '@modules/messaging/types/stack.class';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { IncidentRoutes } from '@pages/incidents/Incident-routes.const';
import { ApplicationInsightsService } from '@services/application-insights.service';
import { ApiFactory } from '@services/core/api-factory.class';
import { SplitioService } from '@services/splitio.service';
import { NavigationEntry } from './NavigationEntry';
import { RouteValidationResult } from './RouteValidationResult';
import { RouteValidator } from './RouteValidator';

export interface NavigationRouteData {
    urlRoute: string;
    associatedApiEntity: ApiEntityTypesEnum | RootNavigationTypesEnum;
    BreadCrumbText: string;
}

@Injectable()
export class SmsNavigationManager extends EnableServiceMessageProcessingDirective  {
    static MAX_STACK = 100;
    private allRoutes = null;

    private weSentBroadcastNotificationOfAuthorization = false;
    private navStack = new Stack<NavigationEntry>(SmsNavigationManager.MAX_STACK);
    private routeValidators = new Map<ApiEntityTypesEnum | RootNavigationTypesEnum, RouteValidator[]>();

    constructor(
        private router: Router,
        mb: MessageBusService,
        private splitioService: SplitioService,
        private appInsights: ApplicationInsightsService,
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {
        super(mb);

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                this.appInsights.startTrackPage(event.url);
            }

            if (event instanceof NavigationEnd) {
                this.appInsights.stopTrackPage(event.url);
            }
        });

        this.getRoutes();
    }

    registerRouteValidator(entity: ApiEntityTypesEnum | RootNavigationTypesEnum, validator: RouteValidator) {
        if (this.routeValidators.has(entity) === false) {
            this.routeValidators.set(entity, new Array<RouteValidator>());
        }

        this.routeValidators.get(entity).push(validator);
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree>
    {
        if ((await this.splitioService.getToggle(TOGGLES.SMS_UNDER_MAINTENANCE)) !== TREATMENT.ON)
        {
            if (this.isNonAuthRoute(route) || LoggedInUserInfo.Instance.userInfo.isNonAuth) {
                const authCode = route.firstChild ? route.firstChild.paramMap.get('authCode') : route.paramMap.get('authCode');
                if (authCode) {
                    if (LoggedInUserInfo.Instance.isAuthorized && !LoggedInUserInfo.Instance.userInfo.isNonAuth) {
                        const navigationExtras: NavigationExtras = {
                            queryParams: route.firstChild.queryParams,
                            queryParamsHandling: 'merge'
                        };
                        this.router.navigate(route.firstChild ? [route.url[0].path, ...route.firstChild.url.slice(0, route.firstChild.url.length - 1).map(r => r.path)] : [route.url[0].path], navigationExtras);
                        return false;
                    } else {
                        await this.getNonAuthToken(authCode, state.url);
                        this.appInsights.setAuthenticatedUser(authCode);
                        return true;
                    }
                } else {
                    this.nonAuthLogout();
                }
            }
            if (LoggedInUserInfo.Instance.userInfo.isTrialSelfRenewal) {
                this.navigateToMessage('Renew');
                return false;
            } else if (await this.isUserAuthorized()) {
                if (!LoggedInUserInfo.Instance.userInfo.isInitialized && (await this.splitioService.getToggle(TOGGLES.OKTA_UNIFIED_LOGIN)) === TREATMENT.ON ) {
                    return false; // do nothing, the user has logged into okta but the user data hasn't loaded yet
                }
                if ((await this.isAllowed(route)) || (window.localStorage.getItem('previousUrl') === state.url)) {
                    return true;
                } else {
                    this.navigateToDashboard();
                    return false;
                }
            }

            const fullUrl = state.url;
            this.requestLogin(fullUrl);
            return false;
        }
        else
        {
            this.navigateToDashboard();
            return false;
        }
    }

    requestLogin(desiredUrl: string) {
        const url = this.router.parseUrl(desiredUrl).toString();
        const msg = this.msgBuilder.SystemEventMessage()
            .usingHostSenderId()
            .then
            .addData(DATAKEYS.APPLEVEL.SECURITY.USER_DATA, LoggedInUserInfo.Instance.userInfo.azureUID)
            .addData(DATAKEYS.APPLEVEL.ROUTE.URL, url)
            .assignAction(ACTIONS.AUTH.AUTH_REQUEST_LOGIN)
            .sendOn.systemTopic().build();
        this.sendTopicMessage(msg);
    }

    navigateToDashboard() {
        const allowedDashboard = this.getAllowedDashboard();
        if (allowedDashboard) {
            const route = this.allRoutes.find((e) => e.authTag && e.authTag.split(',')[0] === allowedDashboard.shortName);
            this.router.navigate([route.urlRoute]);
        }
    }

    async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        // Verify User is Logged in and Authenticated.
        if (LoggedInUserInfo.Instance.userInfo.isTrialSelfRenewal) {
            this.navigateToMessage('Renew');
            return false;
        } else if (await this.isUserAuthorized()) {
            if (await this.isAllowed(route)) {
                if (route.url.length > 0 && route.url[0].path === IncidentRoutes.EmploymentData.urlRoute) {
                    const path = 'company_data/locations';
                    const t = this.router.parseUrl(path);
                    return t;
                }
                return true;
            } else {
                this.navigateToDashboard();
                return false;
            }
        }

        this.requestLogin(route.url.toString());
        return false;
    }

    async isUserAuthorized(): Promise<boolean> {
        if ((await this.splitioService.getToggle(TOGGLES.OKTA_UNIFIED_LOGIN)) === TREATMENT.ON && await this.oktaAuth.isAuthenticated()) {
            LoggedInUserInfo.Instance.userInfo.accessToken = this.oktaAuth.getAccessToken();
            LoggedInUserInfo.Instance.userInfo.refreshToken = this.oktaAuth.getRefreshToken();
            LoggedInUserInfo.Instance.userInfo.idToken = this.oktaAuth.getIdToken();
        }
        if (LoggedInUserInfo.Instance.isAuthorized && !LoggedInUserInfo.Instance.userInfo.isTrialSelfRenewal) {
            if (!this.weSentBroadcastNotificationOfAuthorization) {
                await this.sendLoginSuccessNotification();
            }
            return true;
        }

        this.weSentBroadcastNotificationOfAuthorization = false;
        return false;
    }

    private async sendLoginSuccessNotification() {
        this.weSentBroadcastNotificationOfAuthorization = true;
        await this.splitioService.initSdk();
        await this.splitioService.confirmSplitsLoadedCorrectly();

        const msg =
            this.msgBuilder
                .SystemEventMessage()
                .usingHostSenderId()
                .then.proceedWithoutData
                .assignAction(ACTIONS.AUTH.AUTH_LOGIN_SUCCESS)
                .sendOn
                .systemTopic()
                .build();
        this.sendTopicMessage(msg);
    }

    isNonAuthRoute(route: ActivatedRouteSnapshot): boolean {
        return (route.data && route.data.isNonAuth) || (route.firstChild && route.firstChild.data.isNonAuth);
    }

    async getNonAuthToken(authCode: string, url: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.Security)
                .addDataEntry('authCode', authCode)
                .addRouteHint('GetAuthToken')
                .addSuccessHandler((d: NonAuthResponse) => {
                    if (this.isValidUrl(url, d.redirectToRoute)) {
                        LoggedInUserInfo.Instance.userInfo.populateFromSmsDb(d.userProfile);
                        LoggedInUserInfo.Instance.userInfo.accessToken = d.authToken;
                        LoggedInUserInfo.Instance.userInfo.isNonAuth = true;
                        this.sendLoginSuccessNotification();
                        resolve();
                    } else {
                        this.nonAuthLogout();
                        reject();
                    }
                })
                .addErrorHandler(() => {
                    this.nonAuthLogout();
                    reject();
                }).buildAndSend();
        });
    }

    private isValidUrl(currentUrl: string, tokenUrl: string): boolean {
        return currentUrl.startsWith(tokenUrl);
    }

    private nonAuthLogout() {
        LoggedInUserInfo.Instance.resetLoggedInUserData();
        this.requestLogin('');
    }

    async isAllowed(route: ActivatedRouteSnapshot): Promise<boolean> {
        let show = true;

        const authTag = (route.data && route.data.authTag) || (route.firstChild && route.firstChild.data && route.firstChild.data.authTag);
        if (authTag) {
            show = false;
            const split = authTag.toString().split(',');
            const domain = split[0];
            const type = (split.length > 1) ? split[1] : '';
            let companyDataGroupingEnabled = false;
            if (domain === 'company-data' &&
                route.url.some(x => x.path === 'locations')) {
                companyDataGroupingEnabled = true;
            }

            const isAdmin = LoggedInUserInfo.Instance.userInfo.isRootLevelAdmin || LoggedInUserInfo.Instance.userInfo.IsSuperUser || LoggedInUserInfo.Instance.userInfo.isAdmin;
            if (domain === 'setting' && route.url.some(x => x.path === 'settings') && !isAdmin)
            {
                return false;
            }

            show = LoggedInUserInfo.Instance.userInfo.checkAuthFeature(domain, type, null, true, false, companyDataGroupingEnabled);
        }
        return show;
    }

    /*
    This method will take in a request to route to a new location based on the entity type
    and the action associated with it.  Prior to the Navigation, the route and data model
    associated with it will be validated against any registered validators.  These Validators
    will attempt to "correct" any invalid routes by inserting addition routes, changing data or
    any other means.
    */
    async navigateTo(navEntry: NavigationEntry) {
        if (navEntry.urlRoute.startsWith('/')) {
            navEntry.urlRoute = navEntry.urlRoute.substr(1);
        }

        if ((await this.verifyRoutePermission(navEntry)) === false) {
            throw new Error('you are not authorized to navigate route ' + navEntry.urlRoute);
        }

        this.navStack.push(navEntry);

        const s = this.router.navigateByUrl(navEntry.asFullUrl());
    }

    async navigateBackTo(url: string) {
        if (!url) {
            throw new Error('you must provide a value for Url');
        }

        // Look through the stack for the last matching url
        if (await this.navStack.purgeUntilMatch((r) => r.urlRoute === url)) {
            return;
        }

        await this.navigateTo(await NavigationEntry.FromRouteString(url));
    }

    async navigateToMessage(message: string) {
        this.router.navigate(['/message'], { state: { message } });
    }
    /*
    This method will process all RouteVerification entries.  If a false is returned by any of them
    execution will stop at that point.  A false means that validation failed and there was nothing
    that could be do to "heal" the route request automatically.
    */
    private async verifyRoutePermission(navEntry: NavigationEntry): Promise<boolean> {

        const validators = this.routeValidators.get(navEntry.AssignedEntity);
        if (!validators) {
            return true;
        }

        // We have validators, so lets run them
        const isValid = await this.processValidators(navEntry, validators);

        return true;
    }

    /*
    This method will process each validator one at a time.  Unless the result of each
    validator "stage" indicates an error or need to stop, we will continue to process
    each validator.
    */
    private async processValidators(navEntry: NavigationEntry, validators: RouteValidator[]): Promise<RouteValidationResult> {

        if (validators) { return RouteValidationResult.routeRequestIsValid; }
        let validationResult: RouteValidationResult;
        const index = 0;

        do {
            validationResult = await validators[index](navEntry, this);
        } while (validationResult !== RouteValidationResult.stopAndRefreshNavigation &&
            validationResult === RouteValidationResult.routeRequestIsValid);

        return validationResult;
    }

    async handleBrowserNavigation(event: BeforeUnloadEvent) {
        this.navStack.reset();

        const r = this.router.config;

        this.navigateTo(await NavigationEntry.FromRouteString(''));
    }

    async handleBrowserBackNavigation(event: PopStateEvent) { }

    protected configureMessageBus(builder: IMsgProcessingReg): void { }

    getRoutes() {
        this.allRoutes = [];
        const routes = Object.keys(RootRoutes).map((key) => RootRoutes[key]);
        routes.forEach((element) => {
            this.allRoutes.push(element);
            const child = Object.keys(element.children).map((key) => element.children[key]);
            if (child.length > 0) {
                this.getChildren(child);
            }
        });
    }

    getChildren(routes) {
        routes.forEach((element) => {
            this.allRoutes.push(element);
            const child = Object.keys(element.children).map((key) => element.children[key]);
            if (child.length > 0) {
                this.getChildren(child);
            }
        });
    }

    getAllowedDashboard() {
        return LoggedInUserInfo.Instance.userInfo.features.find((f) => f.featureName === 'Dashboard' && f.subFeatureName !== 'N/A' && f.show === true);
    }
}
