import { Component, Input } from '@angular/core';
import { FilterService } from '@services/filter.service';

@Component({
    selector: 'jjk-mini-grid',
    templateUrl: './mini-grid.component.html',
    styleUrls: ['./mini-grid.component.scss'],
    providers: [FilterService],
})
export class MiniGridComponent {

    @Input() cardName: string;
    @Input() tableData: any;
    @Input() loading: boolean;
    @Input() loadingText: string;
    @Input() showAllRouterLink: string;
    @Input() selectedTabParam: string;
    @Input() state: any;
    @Input() url: string;
    @Input() displayText = 'Article';
    @Input() useRouterLink = false;
    @Input() emptyUrl: string;
    @Input() emptyMessage = 'There are no items to show';
    @Input() defaultSort;
    @Input() defaultSortDirection = 'desc';
    @Input() secondColumnTitle;
    @Input() secondColumnValue;
    @Input() secondColumnIsDate = false;
    @Input() thirdColumnTitle;
    @Input() thirdColumnValue;
    @Input() thirdColumnIsDate = false;
    @Input() viewAuthFeature = '';
    @Input() searchValue: any;
    @Input() predefinedState: any;
    constructor() {}
}
