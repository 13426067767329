import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { ChartType } from '@constants/enums/chart-types.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ChartParams } from '@models/chart/chart-params.model';
import { ChartsBaseModel } from '@models/reporting/charts/charts-base.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { axisFormatWholeNumber } from '../chart-helpers';
import { LegendPosition } from '@swimlane/ngx-charts';

@Component({
    selector: 'jjk-completed-tasks-chart',
    templateUrl: './jjk-completed-tasks-chart.component.html',
    styles: [`
        .chart-container {
        margin-bottom: 45px;
        min-height: 300px;
        }
        `],
})

export class JJKCompletedTasksChartComponent {
    @Input() chartType = 'Bar';
    @Input() view;
    @Input() legend = true;
    @Input() legendTitle = '';
    @Input() legendPosition = LegendPosition.Below;
    @Input() chartData: ChartsBaseModel;
    @Input() results: any;
    @Input() locId: string;
    @Input() showAddButtonEmptyState = true;
    @Input() emptyStateMessage;

    @Output() action = new EventEmitter();

    isEmptyState = true;

    constructor() {
      const params = new ChartParams();
      params.StartDate = moment().utc().subtract(6, 'month').startOf('month').format();
      params.EndDate = moment().utc().subtract(1, 'month').endOf('month').format();

      ApiFactory.retrieveEntity(ApiEntityTypesEnum.Chart)
          .addHeader('X-ChartParams', JSON.stringify(params))
          .addDataEntry('chartType', 'TasksCompleted')
          .addRouteHint('Chart/ChartAsync')
          .addSuccessHandler((x) => {
              this.chartData = this.mapCompletedTasksRate(x);
          }).buildAndSend();
    }

    mapCompletedTasksRate(x) {
        const model = new ChartsBaseModel() ;
        model.title = 'Completed Tasks - Previous 6 Months';
        model.type = ChartType.BAR;
        model.view = [700, 400];
        model.colorScheme.domain = [];
        model.colorScheme.domain.push('#466ac2');
        model.showXAxis = true;
        model.showYAxis = true;
        model.gradient = false;
        model.showLegend = false;
        model.legendTitle = '';
        model.legendPosition = this.legendPosition;
        model.showXAxisLabel = false;
        model.xAxisLabel = '';
        model.showYAxisLabel = false;
        model.yAxisLabel = '';

        model.data = x.namesValuesPair;

        for (const pair of x.namesValuesPair) {
            if (pair.value > 0) {
                this.isEmptyState = false;
                break;
            }
        }
        return model;
    }

    axisFormatWholeNumber(val) {
        return axisFormatWholeNumber(val);
    }

    onEmptyStateAddNewClick(event: any) {
        this.action.emit(event);
    }
}
