export enum TaskStatusEnum {
    Complete = '14338896-97f4-473d-9776-6a22d393e6a9',
    Verified = 'a2c439bc-e12c-4b93-8472-6d819c55269e',
    Canceled = 'e7d5c825-4d4d-4429-97b5-7d308bb11173',
    ToDo = 'ac925b04-3af3-470e-adac-85e9d2cedfaf',
    OnHold = '4fb2dfda-d66b-4c58-a93e-a581d9c7ae47',
    InProgress = '5132fdd3-1a16-4920-ab65-b968b12277a5',
  }

  export const TaskStatusMap = new Map([
    [TaskStatusEnum.Complete, 'Complete'],
    [TaskStatusEnum.Verified, 'Verified'],
    [TaskStatusEnum.Canceled, 'Canceled'],
    [TaskStatusEnum.ToDo, 'To Do'],
    [TaskStatusEnum.OnHold, 'On Hold'],
    [TaskStatusEnum.InProgress, 'In Progress'],
  ]);
