<div class="form-group">
  <div>
    <label style="display: inline-block">{{ title || 'Attachments' }}</label>&nbsp;
    <sms-alert-dismiss *ngIf="showAlert"
      [messageText]="errorMessage" (dismissAlertClick)="dismissAlert()" [customStyle]="'Error'">
    </sms-alert-dismiss>
    <a auth [authFeature]="addAuthFeature" [authEntity]="parentEntity" [routerLink]="[]" class="small" (click)="openEditModal();"><strong>Add</strong></a>
  </div>

  <jjk-empty-state auth [authFeature]="addAuthFeature" [authEntity]="parentEntity" *ngIf="isEmptyState()" (action)='openEditModal()'></jjk-empty-state>

  <div class="table-responsive" *ngIf="attachments.length">
    <table class="table table-striped">
      <tbody>
        <ng-container *ngFor="let attachment of attachments">
          <tr *ngIf="!attachment.isDeleted">
            <td scope="row">
              <div>
                <p><a *ngIf="attachment?.entityState === entityState.Unchanged" auth [authFeature]="downloadAuthFeature" [authEntity]="parentEntity" (click)="download(attachment)">{{attachment.fileName}} <i class="fas fa-download"></i></a></p>
                <p><a *ngIf="attachment?.entityState !== entityState.Unchanged" auth [authFeature]="downloadAuthFeature" [authEntity]="parentEntity">{{attachment.fileName}}</a></p>
                <ng-container *ngIf="downloadAuthFeature">
                  <span auth [authFeature]="downloadAuthFeature" [authEntity]="parentEntity" [authNegate]="true">{{attachment.fileName}}</span>
                </ng-container>
              </div>
              <div>
                <p>{{attachment.description}}</p>
                <ul class="actions">
                  <li auth [authFeature]="addAuthFeature" [authEntity]="attachment"><a [routerLink]="[]" (click)="openEditModal(attachment?.id);">Edit</a></li>
                  <li auth [authFeature]="addAuthFeature" [authEntity]="attachment"><a [routerLink]="[]" (click)="openDeleteModal(attachment?.id);">Delete</a></li>
                </ul>                      
                <audit-info [auditInfo]="attachment" [addEndSpacing]="false"></audit-info>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<attachments-edit-modal [id]="'attachment-edit-modal'" [data]="attachments" (onAttachment)="onAttachmentChange($event)"></attachments-edit-modal>
<modal-window [id]="'attachment-delete-modal'" [title]="'Delete'" [confirmButtonText]="'Delete'" (onConfirm)="onDeleteModalConfirm($event)">
  <p>Are you sure you want to delete this file?</p>
</modal-window>