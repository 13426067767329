<div *ngIf="!isDisabled">
  <ng-select #ngSelect *ngIf="!multiple && isGrouped" class="custom" [class.extra-large]="isExtraLarge" [class.jjk-select-disabled]="isDisabled" [items]="options" bindValue="id" bindLabel="displayText" [searchable]="searchable" [closeOnSelect]="true" [(ngModel)]="selection" [searchFn]="defaultSearch" placeholder={{placeholderText}} [virtualScroll]="true" (add)="onAdd($event)" [groupBy]="groupBy" [selectableGroup]="false" (change)="onChange($event)" (clear)="clearClick()" (close)="onClose()" (remove)="onRemove($event)" [disabled]="isDisabled">
    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
      <b>{{ item.groupName | uppercase }}</b>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      {{ item.displayText }}
    </ng-template>
    <ng-template ng-footer-tmp *ngIf="footerButton">
        <button type="button" (click)="footerButtonClick()" class="footer-btn">{{footerButtonText}}</button>
    </ng-template>
  </ng-select>

  <ng-select #ngSelect *ngIf="!multiple && !isGrouped" class="custom" [class.extra-large]="isExtraLarge" [loading]="showLoading" [class.jjk-select-disabled]="isDisabled" [items]="options" bindValue="id" bindLabel="displayText" [searchable]="searchable" [closeOnSelect]="true" [(ngModel)]="selection" [searchFn]="defaultSearch" placeholder={{placeholderText}} [virtualScroll]="true" (add)="onAdd($event)" (change)="onChange($event)" (close)="onClose()" (remove)="onRemove($event)" [disabled]="isDisabled" (clear)="clearClick()">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" >
      <div class="d-flex" [class.disabled-option]="item.showDisable">
        <div *ngIf="item.icon" class="text-center me-2"><i [class]="item.icon"></i></div>{{item.displayText}}
      </div>
    </ng-template>
    <ng-template ng-footer-tmp *ngIf="footerButton">
        <button type="button" (click)="footerButtonClick()" class="footer-btn">{{footerButtonText}}</button>
    </ng-template>
  </ng-select>

  <ng-select #ngSelect *ngIf="!lockItems && multiple && !isGrouped && !searchViaApi" class="custom" [class.extra-large]="isExtraLarge" [class.jjk-select-disabled]="isDisabled" [items]="options" bindValue="id" bindLabel="displayText" [searchable]="searchable" [closeOnSelect]="false" [(ngModel)]="selection" [searchFn]="searchMultiple" placeholder={{placeholderText}} [virtualScroll]="true" [multiple]="true" [groupBy]="groupBy" [selectableGroup]="true" (add)="onAdd($event)" (change)="onChange($event)" (clear)="clearClick()" (close)="onClose()" (remove)="onRemove($event)" [disabled]="isDisabled">
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let item of items | slice:0:2">
          <span class="ng-value-label">{{item.displayText}}</span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </div>
      <div class="ng-value" *ngIf="items.length > 2">
          <span class="ng-value-label">{{items.length - 2}} more...</span>
      </div>
    </ng-template>
    <ng-template ng-option-tmp ng-multi-label-tmp let-item="item" let-item$="item$" let-index="index">
        <input  id="item-{{index}}" [ngClass]="item.parent !== undefined ? 'nestedItem' : ''" type="checkbox" [ngModel]="item$.selected"/> {{item.displayText}}
    </ng-template>
    <ng-template ng-header-tmp *ngIf="selectAllOption && !lockItems">
      <input type="checkbox" [checked]="isAllSelected" id="selectAllChk" (change)="selectAllClick($event.target.checked)"/> <label class="form-label" for="selectAllChk"> Select All </label>
    </ng-template>
    <ng-template ng-footer-tmp *ngIf="footerButton">
        <button type="button" (click)="footerButtonClick()" class="footer-btn">{{footerButtonText}}</button>
    </ng-template>
  </ng-select>

  <ng-select #ngSelect *ngIf="multiple && isGrouped && !searchViaApi" class="custom" [class.extra-large]="isExtraLarge" [class.jjk-select-disabled]="isDisabled" [items]="options" bindValue="id" bindLabel="displayText" [searchable]="searchable" [closeOnSelect]="false" [(ngModel)]="selection" [searchFn]="searchMultiple" placeholder={{placeholderText}} [virtualScroll]="true" [multiple]="true" [groupBy]="groupBy" [selectableGroup]="true" (add)="onAdd($event)" (change)="onChange($event)" (clear)="clearClick()" (close)="onClose()" (remove)="onRemove($event)" [disabled]="isDisabled">
    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /><b> {{item.groupName | uppercase}}</b>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.displayText}}
    </ng-template>
    <ng-template ng-footer-tmp *ngIf="footerButton">
        <button type="button" (click)="footerButtonClick()" class="footer-btn">{{footerButtonText}}</button>
    </ng-template>
  </ng-select>

  <ng-select #ngSelect *ngIf="multiple && searchViaApi" class="custom" [class.extra-large]="isExtraLarge" [class.jjk-select-disabled]="isDisabled" [items]="options | async" [loading]="showLoading" [typeahead]="typeAheadInput$" bindValue="id" bindLabel="displayText" [searchable]="searchable" [closeOnSelect]="false" [(ngModel)]="selection" [searchFn]="searchMultiple" placeholder={{placeholderText}} [virtualScroll]="true" [multiple]="true" [groupBy]="groupBy" [selectableGroup]="true" (add)="onAdd($event)" (change)="onChange($event)" (clear)="clearClick()" (close)="onClose()" (remove)="onRemove($event)" typeToSearchText={{typeToSearchText}} [disabled]="isDisabled">
    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /><b> {{item.groupName | uppercase}}</b>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.displayText}}
    </ng-template>
    <ng-template ng-footer-tmp *ngIf="footerButton">
        <button type="button" (click)="footerButtonClick()" class="footer-btn">{{footerButtonText}}</button>
    </ng-template>
  </ng-select>

</div>

<div *ngIf="isDisabled">
  <input name="jjkSelectDisable" [ngModel]="isDisableSelectValue" [placeholder]="!isDisableSelectValue ? '(not populated)' : ''" [attr.disabled]="isDisabled" class="form-control large-input" type="text" />
</div>

<div *ngIf="lockItems">
  <ng-select #ngSelect *ngIf="multiple && !isGrouped && !searchViaApi" class="custom" [class.extra-large]="isExtraLarge" [class.jjk-select-disabled]="isDisabled" [items]="options" bindValue="id" bindLabel="displayText" [searchable]="searchable" [closeOnSelect]="false" [(ngModel)]="selection" [searchFn]="searchMultiple" placeholder={{placeholderText}} [virtualScroll]="true" [multiple]="true" [groupBy]="groupBy" [selectableGroup]="true" (add)="onAdd($event)" (change)="onChange($event)" (clear)="clearClick()" (close)="onClose()" (remove)="onRemove($event)"
  [disabled]="true">
    <ng-template ng-multi-label-tmp2 let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let item of items | slice:0:2">
          <span class="ng-value-label">{{item.displayText}}</span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </div>
    </ng-template>
  </ng-select>
</div>