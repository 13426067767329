import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { GhsPictogram } from '@models/entity-models/autogenerated/ghspictogram';

@Component({
    selector: 'ghs-pictogram',
    templateUrl: './ghspictogram.component.html',
    styleUrls: ['./ghspictogram.component.scss'],
})
export class GhsPictogramComponent implements OnInit {

    @Input() pictogram: GhsPictogram;
    @Input() sizeClass: string;
    endpoint: string;
    environment: string;
    route: string;
    
    constructor() {}

    async ngOnInit() {
            this.environment = environment.jjkellerportalapiurl;
            this.route = 'GhsPictogram/Download/';
            this.endpoint = this.environment + this.route;
    }
}
