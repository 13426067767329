<!-- Modal -->
<div id="{{modalId}}" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">{{title}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="onCancel()"></button>
      </div>
      <div *ngIf="subTextURL !== ''" class="modal-sub-text">
        <p>
          <a id="modal-sub-text-a-2" [routerLink]="subTextURL !== '' ? subTextURL +  (recordId ? '/' + recordId : '') : ''"
            target="_blank"><span>{{modalSubText}}</span><i class="fas fa-external-link-alt"></i></a>
        </p>
      </div>
      <div class="modal-body">
        <form>
          <div *ngFor="let question of questionnaire">
            <ng-container *ngIf="question.type === 'question' && showSection[question.id]">
              <p [innerHTML]="question.displayText"></p>
              <label><input name="options-{{question.id}}" [(ngModel)]="selectedOption[question.id]" type="radio" [value]="true" [checked]="selectedOption[question.id]" (change)="onSelectionChange(question)" /> Yes</label>
              <label><input name="options-{{question.id}}" [(ngModel)]="selectedOption[question.id]" type="radio" [value]="false" [checked]="!selectedOption[question.id]" (change)="onSelectionChange(question)" /> No</label>
              <label *ngIf="question.nextItemToShow['onDontKnow']"><input name="options-{{question.id}}" [(ngModel)]="selectedOption[question.id]" type="radio" [value]="'dontKnow'" [checked]="!selectedOption[question.id]" (change)="onSelectionChange(question)" /> I Don't Know</label>
            </ng-container>
            <ng-container *ngIf="(question.type === 'decisionYes' || question.type === 'decisionNo') && showSection[question.id]">
              <h2 [innerHTML]="question.displayText"></h2>
            </ng-container>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="onCancel()">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="disableConfirm()" (click)="onConfirm()">{{saveOnDecisionOnly && (!lastQuestion || lastQuestion.type === QuestionType.Question) ? 'Next' : this.confirmButtonText}}</button>
      </div>
    </div>
  </div>
</div>
