import { MessageType } from '../messageType';
import { Message } from './Message';

/********************************************************************************************
 * This message is specific to the Navigation system.  Messages of this type are sent to
 * change the current page content.  Routing is performed with the standard Angular Router
 * in response to Message Actions and URL values
 ********************************************************************************************/
export class RouteMessage extends Message {
           // this is used during handler registration
           public static messageRegistrationKey: MessageType = 'RouteMessage';

           // this is used in message identification
           public get messageType(): MessageType {
               return RouteMessage.messageRegistrationKey;
           }
       }
