import { QueueEntry } from './queueEntry';

export class Queue<T> {

    // highest priority item
    private front: QueueEntry<T>;

    // lowest priority item
    private back: QueueEntry<T>;

    // current queue length
    private _length = 0;
    public get length() { return this._length; }

    public enqueue(item: T) {
        const node = new QueueEntry<T>(item);
        if (this._length === 0) {
            this.front = node;
        } else {
            this.back.next = node;
        }
        this.back = node;

        this._length += 1;

        return this;
    }

    public dequeue() {
        if (this._length === 0) {
            return;
        }
        const node = this.front;
        this.front = node.next;

        this._length -= 1;

        return node.value;
    }

    public peek() {
        if (this._length === 0) {
            return;
        }

        return this.front.value;
    }

    public toArray() {
        const arr = [];
        let node = this.front;
        while (node) {
            arr.push(node.value);
            node = node.next;
        }

        return arr;
    }

    public toString() {
        return this.toArray().join(', ');
    }
}
