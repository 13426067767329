import { Component, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';
import * as moment from 'moment';
import { ChartType } from '@constants/enums/chart-types.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ChartItem } from '@models/chart/chart-item.model';
import { ChartParams } from '@models/chart/chart-params.model';
import { Chart } from '@models/chart/chart.model';
import { ChartsBaseModel } from '@models/reporting/charts/charts-base.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { axisFormatWholeNumber } from '../chart-helpers';
import { LegendPosition } from '@swimlane/ngx-charts';

@Component({
    selector: 'jjk-training-chart',
    templateUrl: './jjk-training-chart.component.html',
    styles: [`
        .chart-container {
        margin-bottom: 45px;
        min-height: 300px;
        }
        `],
})

export class JJKTrainingChartComponent {
    @Input() chartType = 'BarAndLine';
    @Input() view;
    @Input() legend = true;
    @Input() legendTitle = 'Locations';
    @Input() legendPosition = LegendPosition.Below;
    @Input() @Output() chartData: ChartsBaseModel;
    @Input() results: any;
    @Input() showAddButtonEmptyState = true;
    @Input() emptyStateMessage;

    @Output() action = new EventEmitter();

    isEmptyState = true;

    constructor(public zone: NgZone) {
      const params = new ChartParams();
      params.StartDate = moment().utc().subtract(1, 'year').format();
      params.EndDate = moment().utc().format();
      params.LocationId = null;

      ApiFactory.retrieveEntity(ApiEntityTypesEnum.Chart)
          .addHeader('X-ChartParams', JSON.stringify(params))
          .addDataEntry('chartType', 'TrainingChart')
          .addRouteHint('Chart/ChartAsync')
          .addSuccessHandler((x) => {
              this.chartData = this.mapTraining(x);
          }).buildAndSend();
    }

    mapTraining(x) {
        const model = new ChartsBaseModel() ;
        model.title = 'Training – Previous 12 Months';
        model.type = ChartType.BAR_AND_LINE;
        model.view = [1000, 400];
        model.showXAxis = true;
        model.showYAxis = true;
        model.gradient = false;
        model.showLegend = true;
        model.legendTitle = 'Legend';
        model.legendPosition = this.legendPosition;
        model.showXAxisLabel = true;
        model.xAxisLabel = '';
        model.showYAxisLabel = true;
        model.yAxisLabel = 'Learners';
        model.yAxisLabelRight = 'Score';
        model.showGridLines = true;
        model.innerPadding = '10%';
        model.animations = true;

        model.lineChartScheme = {
            name: 'coolthree',
            selectable: true,
            group: 'Ordinal',
            domain: ['#f17e3d', '#f4bf0e'],
        };

        model.comboBarScheme = {
            name: 'LightBlue',
            selectable: true,
            group: 'Ordinal',
            domain: ['#466ac2', '#aaaea2'],
        };

        model.showRightYAxisLabel = true;

        model.lineChartSeries = this.mapLineCharts(x.xAxis, x.lineCharts);
        model.barChart = this.mapBarCharts(x.xAxis, x.barCharts);
        return model;
    }

    mapBarCharts(xAxis, barCharts) {
        const convertedBarCharts = [];

        for (let i = 0; i < xAxis.length; i++) {
          const element = xAxis[i];
          const chart = new Chart();
          chart.name = element;
          chart.series = new Array(2);

          const trainingCounts = barCharts[0];
          const trainingItem = new ChartItem();
          trainingItem.name = trainingCounts.label;
          trainingItem.value = trainingCounts.values[i];
          if (trainingItem.value > 0) {
            this.isEmptyState = false;
          }

          const enrollmentCounts = barCharts[1];
          const enrollmentItem = new ChartItem();
          enrollmentItem.name = enrollmentCounts.label;
          enrollmentItem.value = enrollmentCounts.values[i];
          if (enrollmentItem.value > 0) {
            this.isEmptyState = false;
          }

          chart.series[0] = trainingItem;
          chart.series[1] = enrollmentItem;

          convertedBarCharts.push(chart);
        }

        return convertedBarCharts;
      }

      mapLineCharts(xAxis, lineCharts) {
        const convertedLineCharts = [];

        const element = lineCharts[0];
        const averageScore = new Chart();
        averageScore.name = element.label;
        averageScore.series = [];

        for (let j = 0; j < xAxis.length; j++) {
          const averageScoreItem = new ChartItem();
          averageScoreItem.name = xAxis[j];
          averageScoreItem.value = element.values[j];
          if (averageScoreItem.value > 0) {
            this.isEmptyState = false;
          }
          averageScore.series.push(averageScoreItem);
        }

        convertedLineCharts.push(averageScore);

        return convertedLineCharts;
      }

      onEmptyStateAddNewClick(event) {
        return;
      }

      axisFormatWholeNumber(val) {
        return axisFormatWholeNumber(val);
      }

      yRightAxisTickFormattingFN(val) {
        return val + '%';
      }
}
