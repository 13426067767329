import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AttachmentsEditModalComponent } from '@components/attachments-edit-modal/attachments-edit-modal.component';
import { ModalWindowComponent } from '@components/modal-window/modal-window.component';
import { AssociatedType } from '@constants/enums/associated-type.enum';
import { EntityState } from '@constants/enums/entity-state';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { environment } from '@env/environment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { AttachmentFile } from '@models/attachments-file.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { LoggerService } from '@services/core/logger-service.class';
import { FilterExpressionBuilder } from '@services/core/models/Filter-Entry';
import { booleanToString, isValidArray, processBlobResponse } from '@utilities/helpers';
import { SplitioService } from '@services/splitio.service';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { AttachmentInfo } from '@models/entity-models/attachments/attachment-info';

require('formdata-polyfill');

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent implements OnInit {
  attachments: AttachmentFile[] = [];
  entityState = EntityState;
  showAlert = false;
  attachMultipleEntitiesToggle = false;
  errorMessage = 'An error occurred uploading the file, please try again later.';

  @Input() title: string;
  // View mode performs the operations right away while edit mode needs an overall saving trigger
  @Input() isEditMode = false;
  @Input() addAuthFeature: string;
  @Input() downloadAuthFeature: string;
  @Input() parentEntity: any;
  private _associatedId: string;
  get associatedId(): string { return this._associatedId; }
  @Input()
  set associatedId(value: string) {
    this._associatedId = value;
    this.loadData();
  }

  private _associatedTypeId: AssociatedType;
  get associatedTypeId(): AssociatedType { return this._associatedTypeId; }
  @Input()
  set associatedTypeId(value: AssociatedType) {
    this._associatedTypeId = value;
    this.loadData();
  }

  @Output() onAttachments = new EventEmitter<AttachmentFile[]>();

  @ViewChild(AttachmentsEditModalComponent, { static: true }) editModal: AttachmentsEditModalComponent;
  @ViewChild(ModalWindowComponent, { static: true }) deleteModal: ModalWindowComponent;

 constructor(private splitioService: SplitioService) {
 }

  async ngOnInit() {
    ($('body') as any).tooltip({ selector: '[data-bs-toggle="tooltip"]' });
    this.attachMultipleEntitiesToggle = await this.splitioService.getToggle(TOGGLES.ATTACHMENT_MULTIPLE_ENTITIES) === TREATMENT.ON;
  }

  openEditModal(attachmentId = '') {
    this.editModal.data = this.attachments;
    this.editModal.show(attachmentId);
  }

  openDeleteModal(attachmentId = '') {
    this.deleteModal.data = attachmentId;
    this.deleteModal.show();
  }

  onDeleteModalConfirm(attachmentId: string) {
    if (!this.isEditMode) {
      this.deleteAttachment(attachmentId);
    } else {
      this.removeLocalAttachment(attachmentId);
    }
  }

  onAttachmentChange(attachment: AttachmentFile) {
    attachment.companyId = LoggedInUserInfo.Instance.userInfo.companyId.toString();
    attachment.createdByUserId = LoggedInUserInfo.Instance.userInfo.userId.toString();

    const attachmentInfo = this.mapAttachmentFileToAttachmentInfo(attachment, this.associatedId,this.associatedTypeId);

    if (!this.isEditMode) {
      this.saveAttachment(attachmentInfo);
      return;
    }

    switch (attachment.entityState) {
      case EntityState.Added: {
        this.addLocalAttachment(attachment);
        break;
      }
      case EntityState.Modified: {
        this.updateLocalAttachment(attachment);
        break;
      }
      case EntityState.Deleted: {
        this.removeLocalAttachment(attachment.id);
        break;
      }
      default: {
        this.throwEntityStateNotSupportedError(attachment.entityState);
      }
    }
  }

  isEmptyState() {
    return !(this.attachments && this.attachments.length && this.attachments.some((x) => !x.isDeleted));
  }

  download(attachment) {
    ApiFactory.retrieveEntity(ApiEntityTypesEnum.Attachment)
      .addRouteHint('Download')
      .addEntityId(attachment.id)
      .addSuccessHandler((x) => processBlobResponse(x, attachment.openAsDownload))
      .buildAndSend();
  }

  async saveAll(associatedId: string) {
    if (!isValidArray(this.attachments)) { return; }

    const attachmentsChanges = this.attachments.filter((x) => x.entityState !== EntityState.Unchanged);
    
    if (isValidArray(attachmentsChanges)) {
      const saveProcesses = attachmentsChanges.map(async (x) => {
        const attachmentInfo = this.mapAttachmentFileToAttachmentInfo(x, associatedId, this.associatedTypeId);
        if (x.entityState === EntityState.Added || x.entityState === EntityState.Modified) {
          await this.saveAttachment(attachmentInfo);
        } else if (x.entityState === EntityState.Deleted) {
          await this.deleteAttachment(attachmentInfo.id);
        } else {
          this.throwEntityStateNotSupportedError(attachmentInfo.entityState);
        }
      });
      await Promise.all(saveProcesses)
      .catch(function(err) {
        throw(err);
      });
    }
  }

  private loadData() {
    // Loading associated attachments
    if (this.associatedId && this.associatedTypeId) {

      const apiFactory = ApiFactory.retrieveEntity(ApiEntityTypesEnum.Attachment)
      .addSuccessHandler((response: AttachmentFile[]) => {
          this.attachments = response;
          this.attachments.forEach((x) => this.fixAttachment(x));
          this.sortAttachments();
      })
      .removePaging();    
 
      if (this.attachMultipleEntitiesToggle) {
        apiFactory.addRouteHint('AllByAssociatedId')
        .addDataEntry('associatedId', this.associatedId);
      } else {
        const associatedIdEx = FilterExpressionBuilder.For(ApiEntityTypesEnum.Attachment)
          .Use('AssociatedId', ParameterTypeEnum.String)
          .Equal(this.associatedId)
          .Build().AsExpression;

        const associatedIdTypeEx = FilterExpressionBuilder.For(ApiEntityTypesEnum.Attachment)
          .Use('AssociatedTypeId', ParameterTypeEnum.String)
          .Equal(this.associatedTypeId)
          .Build().AsExpression;

        apiFactory.addFilterEntries(associatedIdEx)
        .addFilterEntries(associatedIdTypeEx);
      }
      apiFactory.buildAndSend();
      
    }
  }

  private saveAttachment(attachmentInfo: AttachmentInfo): Promise<void> {
    let apiFactory: ApiFactory;
    if (attachmentInfo.entityState === EntityState.Added) {
      const formData = this.buildFormData(attachmentInfo);
      apiFactory = ApiFactory.saveNewEntity(ApiEntityTypesEnum.Attachment, formData);
    } else if (attachmentInfo.entityState === EntityState.Modified) {
      apiFactory = ApiFactory.updateEntity(ApiEntityTypesEnum.Attachment, attachmentInfo);
    } else {
      this.throwEntityStateNotSupportedError(attachmentInfo.entityState);
    }

    return new Promise<void>((resolve, reject) => {
      this.editModal.isDisabled = true;
      if (this.attachMultipleEntitiesToggle) {
        apiFactory.addRouteHint('AttachmentWithAssociation');
      }
      apiFactory.addSuccessHandler((response: AttachmentFile) => {       
        this.fixAttachment(response);
        this.removeLocalAttachment(attachmentInfo.id);
        this.addLocalAttachment(response);
        this.dismissAlert();
        resolve();
      }).addErrorHandler((error) => {
        LoggerService.trace('trace', error);
        if (error && error.errorData) {
          this.errorMessage = error.errorData[0].exceptionData.toString();
        }
        this.hideEditModal();
        this.showAlert = true;
        reject(this.errorMessage);
      })
        .buildAndSend();
    });
  }

  private hideEditModal() {
    this.editModal.close();
    this.editModal.isDisabled = false;
  }

  dismissAlert() {
    this.showAlert = false;
  }

  private deleteAttachment(attachmentId: string): Promise<void> {
    const apiFactory = ApiFactory.deleteEntity(ApiEntityTypesEnum.Attachment);

    if (this.attachMultipleEntitiesToggle){
      const attachment = this.attachments.find((x) => x.id === attachmentId);
      console.log(attachment);
      apiFactory.addRouteHint("RemoveAttachmentAssociation")
                .addEntityId(attachment.attachmentAssociationId)
    } else {
      apiFactory.addEntityId(attachmentId)
    }
    
    return new Promise<void>((resolve, reject) => {
      apiFactory.addSuccessHandler(() => {
          this.removeLocalAttachment(attachmentId);
          resolve();
        })
        .addErrorHandler(() => {
          resolve();
        })
        .buildAndSend();
    });
  }

  private addLocalAttachment(attachment: AttachmentFile) {
    this.attachments.push(attachment);
    this.sortAttachments();
    this.hideEditModal();
    this.onAttachments.emit(this.attachments);
  }

  private updateLocalAttachment(attachment: AttachmentFile) {
    this.attachments = this.attachments.filter((x) => x.id !== attachment.id);
    this.addLocalAttachment(attachment);
  }

  private removeLocalAttachment(attachmentId: string) {
    const removedOne = this.attachments.find((x) => x.id === attachmentId);
    if (!removedOne) { return; }

    removedOne.isDeleted = true;
    removedOne.entityState = EntityState.Deleted;
    this.updateLocalAttachment(removedOne);
  }

  private throwEntityStateNotSupportedError(state: EntityState) {
    throw new Error(`EntityState: ${state} not supported`);
  }

  private buildFormData(attachmentInfo: AttachmentInfo): FormData {
    const data = new FormData();
    data.append('id', attachmentInfo.entityState === EntityState.Added ? undefined : attachmentInfo.id);
    data.append('companyId', attachmentInfo.companyId);
    data.append('associatedId', attachmentInfo.associatedId);
    data.append('associatedTypeId', attachmentInfo.associatedTypeId);
    data.append('file', attachmentInfo.file);
    data.append('fileName', attachmentInfo.fileName);
    data.append('description', attachmentInfo.description);
    data.append('isDeleted', booleanToString(attachmentInfo.isDeleted)); // FormData accept just strings
    data.append('createdByUserId', attachmentInfo.createdByUserId);

    return data;
  }

  private sortAttachments(): AttachmentFile[] {
    return this.attachments.sort((a, b) => a.fileName.localeCompare(b.fileName));
  }

  private fixAttachment(x: AttachmentFile) {
    // Downloads should happen through the Api
    x.fileUrl = `${environment.jjkellerPortalApiRootUrl}api/v${environment.apiVersion}/${x.companyId}/${x.createdByUserId}/Attachment/${x.id}/Download`;
    x.entityState = EntityState.Unchanged;
    x.groupId = this.parentEntity.groupId;
  }

  private mapAttachmentFileToAttachmentInfo(attachment: AttachmentFile, associatedId: string, associatedTypeId: string): AttachmentInfo {
    const attachmentInfo = new AttachmentInfo();

    attachmentInfo.id = attachment.id;
    attachmentInfo.companyId = attachment.companyId;
    attachmentInfo.associatedId = associatedId;
    attachmentInfo.associatedTypeId = associatedTypeId;
    attachmentInfo.description = attachment.description;
    attachmentInfo.file = attachment.file;
    attachmentInfo.fileName = attachment.fileName;
    attachmentInfo.fileType = attachment.fileType;
    attachmentInfo.fileUrl = attachment.fileUrl;
    attachmentInfo.fileSize = attachment.fileSize;
    attachmentInfo.createdByUserId = attachment.createdByUserId;
    attachmentInfo.createdDate = attachment.createdDate;
    attachmentInfo.isDeleted = attachment.isDeleted;
    attachmentInfo.permissions = attachment.permissions;
    attachmentInfo.entityState = attachment.entityState;

    return attachmentInfo;
  }

}
