import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { getAlphaIndexArray, isNumeric } from '@utilities/helpers';

@Component({
  selector: 'alpha-navigation',
  templateUrl: './alpha-navigation.component.html',
  styleUrls: ['./alpha-navigation.component.scss'],
})
export class AlphaNavigationComponent implements OnInit {
  alpha: string[];
  currentIndex: string;
  @Input() dataSource: any[];
  @Input() lookUpField: (t: any) => any;
  @Input() generateLinks = true;
  @Output() onItemClick = new EventEmitter<string>();

  constructor(private router: Router) { }

  ngOnInit() {
    this.alpha = getAlphaIndexArray();
  }

  dataExistsFor(index: string) {
    if (!this.dataSource || this.dataSource.length === 0 || !this.lookUpField) { return false; }

    return index === '#' ? this.dataSource.map(this.lookUpField).find((i) => i.length > 0 && isNumeric(i[0])) :
      this.dataSource.map(this.lookUpField).find((i) => i.toUpperCase().startsWith(index));
  }

  onIndexClick(index: string) {
    this.onItemClick.emit(index);
  }

  onIndexChange(index: string) {
    if (this.generateLinks) {
      this.router.navigate([], { fragment: index === '#' ? 'numbers' : index });
    }
    this.onItemClick.emit(index);
  }
}
