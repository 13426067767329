export class ErrorEntry {
    exceptionData: any[];
    errorMessage: string;
    showToUser: boolean;
    errorRaw: string;

    constructor(error: any) {
        this.errorRaw = error;
        this.exceptionData = error.exceptionData;
        this.errorMessage = error.errorMessage;
        this.showToUser = error.showToUser;
    }
}
