import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

@Pipe({name: 'dueDate'})
export class DueDatePipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  private _usLocale = 'en-US';
    transform(value: string, status: any) {
        const dueDate = (new DatePipe(this._usLocale) as PipeTransform).transform(value, 'MM/dd/yyyy');
        if (this.isOverdue(value, status.toLowerCase())) {
            return this.sanitized.bypassSecurityTrustHtml(
                `<span class="past-due-red bold"> ${dueDate} </span>`);
        } else {
            return dueDate;
        }
    }
    isOverdue(date: any, status: string): boolean {
        if (status === 'to do' || status === 'in progress' || status === 'on hold') {
            return moment(date).isBefore(moment().toDate()) && !moment().isSame(moment(date), 'day');
        }
        return false;
    }
}
