export enum OshaInjuryClassification {
  Injury = 'ea2711a1-fc37-46d1-b15d-9414ebd699ec',
  SkinDisorder = '52bb2f36-0aa0-4702-936f-6216e19f702d',
  RespiratoryCondition = '9e130205-5cae-4836-877e-e75004ad2a67',
  Poisoning = '40a59b3d-212a-4a25-820f-9a983076284a',
  HearingLoss = 'fd6b4643-11ed-4a64-998e-03439bbf4273',
  AllOtherIllnesses = 'f4071582-0b0c-46c6-b6d8-2e5a20b461a5',
  NotApplicable = 'fe5ea140-0e95-43f2-8eae-2fd74ad293c1',
}

export const OshaInjuryClassificationMap = new Map([
  [OshaInjuryClassification.Injury, 'Injury'],
  [OshaInjuryClassification.SkinDisorder, 'Skin Disorder'],
  [OshaInjuryClassification.RespiratoryCondition, 'Respiratory Condition'],
  [OshaInjuryClassification.Poisoning, 'Poisoning'],
  [OshaInjuryClassification.HearingLoss, 'Hearing Loss'],
  [OshaInjuryClassification.AllOtherIllnesses, 'All Other Illnesses'],
  [OshaInjuryClassification.NotApplicable, 'Not Applicable'],
]);
