<div *ngIf="formControlNameValue" [formGroup]="parentForm" class="datepicker-container">
    <mat-form-field appearance="outline" [ngClass]="{'invalidControl': formIsInvalid()}">
        <input #dateInput
            matInput
            [name]="name"
            [matDatepicker]="picker"
            [placeholder]="placeholder"
            [formControlName]="formControlNameValue"
            class="datepicker"
            [attr.disabled]="isDisabled === true ? 'disabled' : null"
            [min]="minDate"
            [max]="maxDate"
            (dateChange)="onDateChanged($event)">
        <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="isDisabled"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>

<div *ngIf="!formControlNameValue" class="datepicker-container">
    <mat-form-field appearance="outline">
        <input
            matInput
            [name]="name"
            [matDatepicker]="picker"
            [placeholder]="placeholder"
            [value]="value"
            class="datepicker"
            [attr.disabled]="isDisabled === true ? 'disabled' : null"
            [min]="minDate"
            [max]="maxDate"
            (dateChange)="onDateChanged($event)">
        <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="isDisabled"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>