import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PimcoreService } from '@services/pimcore.service';

@Component({
    selector: 'jjk-pimcore-zone',
    templateUrl: './pimcore-zone.component.html',
})
export class PimcoreZoneComponent implements OnInit, OnDestroy {

    @Input() zones: string;
    htmlBlockContent: string;

    private apiSubscription$: Subscription;

    constructor(public pimcoreService: PimcoreService) { }

    ngOnInit() {
        this.apiSubscription$ = this.pimcoreService.pimcoreHtmlBlockList$
            .subscribe((data) => {
                this.htmlBlockContent = data && data.length > 0 ? this.pimcoreService.returnZoneContent(this.zones) : null;
            });
    }

    ngOnDestroy(): void {
        if (this.apiSubscription$) { this.apiSubscription$.unsubscribe(); }
    }
}
