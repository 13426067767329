import { isNumeric } from '@utilities/helpers';
import { convertToFriendlyString } from '../helpers';
import { ISmsColumnDropdownOptions } from './sms-column-dropdown-options.model';
import { SmsColumnVisibility } from './sms-column-visibility.model';

/**
 * Contains properties that a column in Hacksaw components use
 * to handle responsive toggling, sorting, etc. Also contains
 * methods to assist in the creation of definitions given a datasource.
 */
export class SmsColumnDefinition {
    displayText = '';
    value = '';
    isNumeric = false;
    visibility = new SmsColumnVisibility();
    hidden = false;

    /**
     * Build column information for minimap based on data and optional column options.
     * Returns an array of column definitions with various data used to hide/show/toggle columns.
     * @param data
     * @param columnOptions
     */
    static buildColumnInfo(data: any[], columnOptions?: ISmsColumnDropdownOptions[]): SmsColumnDefinition[] {
        if (!(data || columnOptions)) {
            return [];
        }

        const firstRow = data && data.length > 0 ? data[0] : {};
        const cols = Object.keys(firstRow);
        const columnInfo = (columnOptions ? columnOptions.map((col) => col.columnName) : cols)
            .map((colName, index) => {
                const colDef = new SmsColumnDefinition();
                let columnOverrides;

                // Check if column options are provided and override defaults if necessary
                if (columnOptions) {
                    columnOverrides = columnOptions.find((column) => column.columnName === colName);
                    if (columnOverrides !== undefined) {
                        colDef.displayText = columnOverrides.displayText || convertToFriendlyString(colName);
                        colDef.value = columnOverrides.columnName || colName;
                        colDef.isNumeric = isNumeric(firstRow[colDef.value]);
                        colDef.visibility.alwaysShow = columnOverrides.alwaysShow || false;
                        colDef.visibility.priority = columnOverrides.priority;
                        colDef.visibility.manuallyToggled = colDef.visibility.priority === 0;
                        colDef.visibility.hidden = colDef.visibility.manuallyToggled;
                        colDef.hidden = columnOverrides.hidden;
                    } else {
                        colDef.displayText = convertToFriendlyString(colName);
                        colDef.value = colName;
                        colDef.isNumeric = isNumeric(firstRow[colDef.value]);
                        this.assignColumnDefaults(colDef, index);
                    }
                } else {
                    colDef.displayText = convertToFriendlyString(colName);
                    colDef.value = colName;
                    colDef.isNumeric = isNumeric(firstRow[colDef.value]);
                    colDef.hidden = false;
                    this.assignColumnDefaults(colDef, index);
                }

                return colDef;
            });

        return columnInfo;
    }

    private static assignColumnDefaults(columnDefinition: SmsColumnDefinition, index: number): SmsColumnDefinition {
        // Column visibility defaults
        if (index >= 0 && index < 2) {
            columnDefinition.visibility.priority = 1;
        } else if (index >= 2 && index < 4) {
            columnDefinition.visibility.priority = 2;
        } else if (index >= 4 && index < 6) {
            columnDefinition.visibility.priority = 3;
        } else if (index >= 6 && index < 8) {
            columnDefinition.visibility.priority = 4;
        } else if (index >= 8 && index < 10) {
            columnDefinition.visibility.priority = 5;
        } else {
            columnDefinition.visibility.priority = 6;
        }

        // default to hide columns beyond the first 5 per the design
        if (index > 4) {
            columnDefinition.visibility.hidden = true;
            columnDefinition.visibility.manuallyToggled = true;
        }

        return columnDefinition;
    }
}
