import * as CryptoJS from 'crypto-js';

    export function encryptData(value, secretKey) {
        try {
            return CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();    
        } catch (error) {
            console.log(error);
        }        
    }

    export function decryptData(value, secretKey) {

        try {
          const bytes = CryptoJS.AES.decrypt(value, secretKey);
          if (bytes.toString()) {
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          }
          return value;
        } catch (e) {
          console.log(e);
        }
    }