import { CachingEntityRelationship } from '@constants/enums/caching-entity-relationship';
import { LOGGING } from '@constants/logging';

export class HttpCacheService {

    constructor() {
    }

    cache = new Map();
    expiry = LOGGING.SETTINGS.KEY_EXPIRATION_IN_MINUTES * 60 * 1000;
    relatedEntities: string[] = [];

    get(url: string) {
        const result = this.cache.get(url);
        if (result) {
            const response = result.response;
            const setDate = result.date;
            return Date.now() - setDate.getTime() > this.expiry ? this.delete(url) : response;
        }
        else { return null; }
    }

    set(url: string, response, date, endpointKey) {
        this.cache.set(url, { response, date, endpointKey });
    }

    delete(url: string) {
        this.cache.delete(url);
        return null;
    }

    deleteRelatedData(endpointKey: string) {

        this.relatedEntities = [];
        this.relatedEntities.push(endpointKey);

        const entityRelated = CachingEntityRelationship[endpointKey] as string[];

        if (entityRelated?.length > 0) {
            entityRelated.forEach((v) => {
                if (this.relatedEntities.indexOf(v) === -1) { this.relatedEntities.push(v); }
            });
        }

        const array = [...this.cache].map(([name, value]) => ({ name, value }));
        this.relatedEntities.forEach(element => {
            const relatedData = array.filter(d => {
                if (d.value.endpointKey === element) {
                    this.cache.delete(d.name);
                }
            });
        });

        return null;
    }

    clearAllCacheData() {
        this.cache.clear();
    }
}
