import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { isNullOrUndefined } from '@utilities/helpers';

@Component({
    selector: 'jjkp-reminders-new',
    templateUrl: './reminders-new.component.html',
    styleUrls: ['./reminders-new.component.scss'],
})
export class RemindersNewComponent implements OnInit {
    @Input() toolTipText = 'Reminder emails will be sent based on your selection here.';
    @Input() shouldShowErrors = false;
    @Input() isDisabled = false;

    @Output() remindersDisabled: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() isSendImmediatelyChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    private _isSendImmediately = false;
    immediateIsDisabled = false;
    optionDisabled = false;
    @Input()
    public get isSendImmediately(): boolean {
        return this._isSendImmediately;
    }
    public set isSendImmediately(value: boolean) {
        this._isSendImmediately = value;
        this.isSendImmediatelyChange.emit(this.isSendImmediately);
    }

    private _eventDate: any;
    @Input()
    public get eventDate(): any {
        return this._eventDate;
    }
    public set eventDate(value: any) {
        this._eventDate = value ? `${moment(new Date(value)).format('YYYY-MM-DD')}T00:00:00Z` : null;
        setTimeout(() => {
            this.refreshReminders();
        }, 0);
    }

    @Output() firstReminderChanged = new EventEmitter<any>(true);
    private _firstReminderDate;
    @Input()
    public get firstReminderDate() {
        return this._firstReminderDate;
    }
    public set firstReminderDate(value) {
        if (value && this.eventDate) {
            const reminderDate = `${moment.utc(new Date(value)).format('YYYY-MM-DD')}T00:00:00Z`;
            if (!this._firstReminderDate || moment.utc(reminderDate).format('L') !== moment.utc(this.firstReminderDate).format('L')) {
                this._firstReminderDate = reminderDate;
                this._reminder1 = this.getReminderDays(this.eventDate, reminderDate);
            }
        }
    }

    @Output() secondReminderChanged = new EventEmitter<any>(true);
    private _secondReminderDate;
    @Input()
    public get secondReminderDate() {
        return this._secondReminderDate;
    }
    public set secondReminderDate(value) {
        if (value && this.eventDate) {
            const reminderDate = `${moment.utc(new Date(value)).format('YYYY-MM-DD')}T00:00:00Z`;
            if (!this._secondReminderDate || moment.utc(reminderDate).format('L') !== moment.utc(this.secondReminderDate).format('L')) {
                this._secondReminderDate = reminderDate;
                this._reminder2 = this.getReminderDays(this.eventDate, reminderDate);
            }
        }
    }

    reminders: any[] = [];
    reminders2: any[] = [];

    private _reminder1: any;
    get reminder1() { return this._reminder1; }
    set reminder1(value) {
        this._reminder1 = value;
        if (this.eventDate && !isNullOrUndefined(this._reminder1)) {
            const firstReminder = this.reminderInformation(this._reminder1);
            if (isNullOrUndefined(this.firstReminderDate) || moment.utc(this.firstReminderDate).format('L') !== moment.utc(firstReminder).format('L')) {
                this.firstReminderChanged.emit(firstReminder);
            }
        } else {
            this._firstReminderDate = undefined;
            this.firstReminderChanged.emit(undefined);
        }
    }

    private _reminder2: any;
    get reminder2() { return this._reminder2; }
    set reminder2(value) {
        this._reminder2 = value;
        if (this.eventDate && !isNullOrUndefined(this._reminder2)) {
            const secondReminder = this.reminderInformation(this._reminder2);
            if (isNullOrUndefined(this.secondReminderDate) || moment.utc(this.secondReminderDate).format('L') !== moment.utc(secondReminder).format('L')) {
                this.secondReminderChanged.emit(secondReminder);
            }
        } else {
            this.secondReminderChanged.emit(undefined);
        }
    }

    constructor() {
    }

    ngOnInit() {
    }

    private get getReminders(): any[] {
        return [{
            id: 30,
            displayText: 'One Month Prior',
            showDisable: this.optionDisabled,
            disabled: false,
        }, {
            id: 14,
            displayText: 'Two Weeks Prior',
            showDisable: this.optionDisabled,
            disabled: false,
        }, {
            id: 7,
            displayText: 'One Week Prior',
            showDisable: false,
            disabled: false,
        }, {
            id: 3,
            displayText: 'Three Days Prior',
            showDisable: this.optionDisabled,
            disabled: false,
        }, {
            id: 2,
            displayText: 'Two Days Prior',
            showDisable: this.optionDisabled,
            disabled: false,
        }, {
            id: 1,
            displayText: 'One Day Prior',
            showDisable: this.optionDisabled,
            disabled: false,
        }, {
            id: 0,
            displayText: 'Morning Of',
            showDisable: this.optionDisabled,
            disabled: false,
        },
        ];
    }

    removeFirstReminderOptionFromSecondReminder() {
        const indexToRemove = this.reminders2.findIndex((x) => x.id === this.reminder1);
        this.reminders2.splice(indexToRemove, 1); // Do not show the reminder that is already selected for first reminder.
        if (this.reminder1 === this.reminder2) {
            this.reminder2 = undefined;
        }
    }

    private getReminderDays(eventDate: any, reminderDate: any): number {
        const date1 = moment(eventDate);
        const date2 = moment(reminderDate);
        return Math.round(date1.diff(date2, 'days', true));
    }

    private reminderInformation(value: number) {
        const eventDate = this._eventDate;
        switch (value) {
            case 30:
                return moment.utc(eventDate).subtract(30, 'd').format();
                break;
            case 14:
                return moment.utc(eventDate).subtract(14, 'd').format();
                break;
            case 7:
                return moment.utc(eventDate).subtract(7, 'd').format();
                break;
            case 3:
                return moment.utc(eventDate).subtract(3, 'd').format();
                break;
            case 2:
                return moment.utc(eventDate).subtract(2, 'd').format();
                break;
            case 1:
                return moment.utc(eventDate).subtract(1, 'd').format();
                break;
            case 0:
                return moment.utc(eventDate).format();
                break;
            case null:
                return moment.utc('01/01/1999').format();
                break;
        }
    }
    removeIrrelevantReminderOptions() {
        // grey out irrelevant choices from reminder list
        const dEvent = moment(this.eventDate);
        const thisDay = moment(new Date());
        const daysAway = Math.abs(thisDay.diff(dEvent, 'days'));
        this.reminders = this.getReminders;
        this.reminders2 = this.getReminders;
        if (!isNaN(daysAway)) {
            if (new Date() > new Date(this.eventDate)) {
                // if in the past disable all notification options
                this.reminders.forEach(element => {
                    element.showDisable = true;
                    element.disabled = true;
                });
                this.clearReminders();
            }
            else {
                // disable just the ones we need to disable
                this.reminders.filter(x => x.id > daysAway).forEach(element => {
                    element.showDisable = true;
                    element.disabled = true;
                });
                this.reminders2.filter(x => x.id > daysAway).forEach(element => {
                    element.showDisable = true;
                    element.disabled = true;
                });
            }

        }
        this.removeFirstReminderOptionFromSecondReminder();
    }

    clearReminders() {
        // remove selected notification if it isn't within the newly chosen date
        const dEvent = moment(this.eventDate);
        const thisDay = moment(new Date());
        const daysAway = Math.abs(thisDay.diff(dEvent, 'days')) + 1;
        this.reminder1 = this.reminder1 > daysAway ? null : this.reminder1;
    }

    refreshReminders() {
        // is the due date today?
        if (this.eventDate === `${moment(new Date()).format('YYYY-MM-DD')}T00:00:00Z` || (new Date() > new Date(this.eventDate) && this.eventDate)) {
            // disable other notification days - check the "send immediately" and lock it
            this.isSendImmediately = true;
            this.immediateIsDisabled = true;
            this.isDisabled = true;
            // clear reminder out in background
            this.reminder1 = this.reminderInformation(null);
            this.remindersDisabled.emit(true);
        }
        else {
            this.clearReminders();
            this.isSendImmediately = false;
            this.immediateIsDisabled = false;
            this.isDisabled = false;
            this.remindersDisabled.emit(false);
            // all the other things
            this.removeIrrelevantReminderOptions();
            if (this._reminder1 && this.eventDate) {
                const reminder1 = this.reminderInformation(this._reminder1);
                if (moment.utc(this.firstReminderDate).format('L') !== moment.utc(reminder1).format('L')) {
                    this.firstReminderChanged.emit(reminder1);
                }
            }
            if (this._reminder2 && this.eventDate) {
                const reminder2 = this.reminderInformation(this.reminder2);
                if (moment.utc(this.secondReminderDate).format('L') !== moment.utc(reminder2).format('L')) {
                    this.secondReminderChanged.emit(reminder2);
                }
            }
        }
    }
}
