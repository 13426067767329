<header>
  <div class="jjkellerBranding" [ngClass]="isIE ? 'print-hide' : ''">
    <a class="jjkellerLogoMark" (click)="openImpersonatingModal($event)">
      <img id="jjkLogo" src="assets/images/jjkDiamond.svg" alt="">
    </a>
    <a *ngIf="!isNonAuth" class="smsLogo" [routerLink]="['dashboard']">
      <img class="imgDesktop" src="assets/images/sms_logo.svg" alt="Safety Management Suite">
      <img class="imgMobile" src="assets/images/sms_logo_mobile.svg" alt="Safety Management Suite">
    </a>
    <span *ngIf="isNonAuth" class="smsLogo">
      <img class="imgDesktop" src="assets/images/sms_logo.svg" alt="Safety Management Suite">
      <img class="imgMobile" src="assets/images/sms_logo_mobile.svg" alt="Safety Management Suite">
    </span>
  </div>
  <div class="userHeaderItems">
    <div class="profile-dropdown print-hide">
      <ul class='nav navbar-nav' *ngIf="!isNonAuth">
        <li>
          <layout-profile-menu></layout-profile-menu>
        </li>
      </ul>
    </div>
    <div class="cobrandingLogo">
      <jjk-cobranding-logo *ngIf="!isNonAuth" [fileLogoURL]="siteLogo"></jjk-cobranding-logo>
    </div>
  </div>
</header>
<div class="mobile-nav">
  <div class="d-flex justify-content-between align-items-center">
    <button id="menu-toggle" class="btn btn-primary btn-menu" (click)="toggleSideNav()">
      <i class="fa fa-bars" aria-hidden="true"></i> MENU
    </button>
    <div id="sub-profile-menu" class="print-hide" *ngIf="!isNonAuth">
      <layout-profile-menu></layout-profile-menu>
    </div>
  </div>
</div>
<div class="btn btn-primary impersonation" *ngIf="canImpersonate" (click)="showImpersonationEndModal()">
  Impersonating {{userName}}
</div>