import { Injectable } from '@angular/core';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { FilterExpressionBuilder } from './core/models/Filter-Entry';

@Injectable()
// Common, reusable methods used throughout application
export class SmsCommon {

    constructor() {
    }

    static getFilterExpression(entityType: ApiEntityTypesEnum, fieldName: string, fieldValue: string) {
        return FilterExpressionBuilder.For(entityType)
            .Use(fieldName, ParameterTypeEnum.String)
            .Equal(fieldValue)
            .Build().AsExpression;
    }
}
