<header>
    <div class="jjkellerBranding">
        <a class="jjkellerLogoMark">
          <img id="jjkLogo" src="assets/images/jjkDiamond.svg" alt="">
        </a>
        <span class="smsLogo">
          <img class="imgDesktop" src="assets/images/sms_logo.svg" alt="Safety Management Suite">
          <img class="imgMobile" src="assets/images/sms_logo_mobile.svg" alt="Safety Management Suite">
        </span>
      </div>
</header>
<div class="message">
    {{message}}
</div>
