import { Guid } from 'guid-typescript';
import { FrequencyInfo } from '../editorial-asa/frequency-info';
import { TrainingRecord } from './training-record';

export class ClassroomTrainingEvent {
    Id: string;
    Title: string;
    Group: Guid;
    InternalTrainingProgramId: string;
    UserTrainingProgramId: string;
    ProgramFrequency: FrequencyInfo[];
    Mandatory: boolean;
    EventDate: string;
    ThresholdScore: number;
    AttendeeIds: Guid[];
    FacilitatorIds: Guid[];
    Reminders: string[];
    Duration: string;
    LocationIds: Guid[];
    WorkAreaIds: Guid[];
    JobFunctionIds: Guid[];
    Description: string;
    AddedBy: string;
    Status: string;
    CompletedOn: Date;
    TrainingRecords: TrainingRecord[];
}
