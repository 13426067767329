<div class="accordion-item">
    <div>
        <a data-bs-toggle="collapse" href="{{'#' + toggleId}}" id="{{'expand' + toggleId}}" role="button" aria-expanded="false" [attr.aria-controls]="toggleId" class="collapsed"  (click)="onClick()">
            <div class="section_title">
                <h2>{{title}}
                    <ng-content select=".badge"></ng-content>
                    <jjk-label [displayText]="labelDisplayText" [color]="labelColor" (click)="onLabelClick()" ></jjk-label>
                    <div *ngIf="accordionNotificationText" class="accordion-item-notification" [ngClass]="notificationType ? notificationType : 'info'">{{accordionNotificationText}}</div>
                </h2>
                <span class="additionalText" *ngIf="additionalText">{{ additionalText }}</span>
                <span class="completion" *ngIf="fieldCompletion">({{ fieldCompletion?.completedFields }}/{{ fieldCompletion?.totalFields }} fields populated)</span>
                <p>{{description}} <a [routerLink]="[]" *ngIf="descriptionLinkText" class="small" (click)="onDescriptionLinkClick($event);false">{{descriptionLinkText}}</a></p>

                <div *ngIf="toggleId" class="show-section text-end">
                    <span class="more-less">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </span>
                </div>
                <ng-content select=".alert"></ng-content>
            </div>
            <p *ngIf="unableToSourceMessage" class="unableToSource-message">{{unableToSourceMessage}}</p>
        </a>

        <a *ngIf="addRouterLink; else editRouterLinkHtml" class="small" [routerLink]="addRouterLink" auth [authFeature]="authFeature" [authEntity]="authEntity">Add</a>
        <ng-template #editRouterLinkHtml>
            <a *ngIf="editRouterLink && !hideEditRouterLink" class="small" [routerLink]="editRouterLink" auth [authFeature]="authFeature" [authEntity]="authEntity">{{editWording}}</a>
        </ng-template>
        <a *ngIf="additionalRouterLink" class="small" [routerLink]="additionalRouterLink" auth [authFeature]="addtionalLinkAuthFeature ? addtionalLinkAuthFeature : authFeature" [authEntity]="authEntity">{{additionalRouterLinkText}}</a>
        <a *ngIf="additionalRouterLink1" class="small" [routerLink]="additionalRouterLink1" auth [authFeature]="addtionalLinkAuthFeature ? addtionalLinkAuthFeature : authFeature" [authEntity]="authEntity">{{additionalRouterLinkText1}}</a>
        <a *ngIf="additionalRouterLink2" class="small" [routerLink]="additionalRouterLink2" auth [authFeature]="addtionalLinkAuthFeature ? addtionalLinkAuthFeature : authFeature" [authEntity]="authEntity">{{additionalRouterLinkText2}}</a>
        <ng-content select="[customLinks]"></ng-content>
    </div>
    <div class="collapse expand-all" id="{{toggleId}}">
        <ng-content></ng-content>
    </div>
</div>
