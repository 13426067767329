import { Component } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { AgPromise, IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { CheckboxItem } from '@models/checkbox-item.model';

@Component({
  selector: 'jjkp-ag-grid-custom-list-filter',
  templateUrl: './ag-grid-custom-list-filter.component.html',
  styleUrls: ['./ag-grid-custom-list-filter.component.scss'],
})
export class AgGridCustomListFilterComponent implements IFilterAngularComp {
    params: any;
    model: any;
    listItems = Array<CheckboxItem>();
    groupedItems = Array<CheckboxItem>();
    groupItems = false;

    agInit(params: IFilterParams): void {
        this.params = params;
        this.listItems = ['(Select All)'].concat(this.params.values).map((i, x) => new CheckboxItem(x, i, true));
        if (this.params.groupingItem && this.params.groupedItems) {
            this.groupItems = true;
            this.groupedItems = this.listItems.filter((i) => this.params.groupedItems.includes(i.label));
        }
    }

    isFilterActive(): boolean {
        return this.listItems.some((i) => !i.checked);
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        return this.listItems.filter((i, x) => x > 1 && i.checked).some((i) => i.label === params.data.incidentStatusName);
    }

    getModel() {
        return { values: this.listItems.filter((i) => i.checked && i.label !== '(Select All)' && i.label !== this.params.groupingItem).map((i) => i.label), filterType: 'set' };
    }

    setModel(model: any): void | AgPromise<void> {
        if (model && model.values.length > 0) {
            this.listItems.forEach((i) => i.checked = false);

            model.values.forEach(element => {
                this.listItems.map((i) => {
                    if (i.label === element) { i.checked = true; }
                });
            });

            this.listItems[1].checked = this.listItems.filter((i) => this.params.groupedItems.includes(i.label)).every((i) => i.checked);
            this.listItems[0].checked = this.listItems.filter((i, x) => x > 0).every((i) => i.checked);
        }
        else
        {
            this.listItems.forEach((i) => { i.checked = true; });
        }
        this.params.filterChangedCallback();
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

    onChange(item): void {
        if (item.label === '(Select All)') {
            this.listItems.forEach((i) => i.checked = item.checked);
        } else {
            if (item.label === this.params.groupingItem) {
                this.listItems.filter((i) => this.params.groupedItems.includes(i.label)).forEach((i) => i.checked = item.checked);
            } else {
                this.listItems[1].checked = this.listItems.filter((i) => this.params.groupedItems.includes(i.label)).every((i) => i.checked);
            }
            this.listItems[0].checked = this.listItems.filter((i, x) => x > 0).every((i) => i.checked);
        }
        this.params.filterChangedCallback();
    }

    clearFilter() {
        this.listItems.map((i) => {
            if (i.checked === false) {
                i.checked = true;
            }
        });
    }
}
