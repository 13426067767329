import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DynamicForm, DynamicFormGroup, SubmitStatus } from '@models/dynamic-forms.export';
import { isValidArray } from '@utilities/helpers';

@Component({
    selector: 'dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent {
    private _dynamicForm: DynamicForm;
    get dynamicForm(): DynamicForm {
        return this._dynamicForm;
    }
    @Input() set dynamicForm(value: DynamicForm) {
        this._dynamicForm = value;
        this.setFormGroup();
    }

    @Output() validFormSubmitted = new EventEmitter();
    formGroup: DynamicFormGroup;

    constructor() {
    }

    private setFormGroup() {
        if (this.dynamicForm && isValidArray(this.dynamicForm.formControls)) {
            this.formGroup = new DynamicFormGroup(this.dynamicForm.formControls);
        }
    }

    submit() {
        if (this.formGroup.invalid) {
            this.formGroup.submitStatus = SubmitStatus.WithErrors;
            return;
        }
        this.formGroup.submitStatus = SubmitStatus.Sent;
        this.validFormSubmitted.emit(this.formGroup.value);
    }
}
