import { FilterType } from '@constants/advanced-filter/filter-type.enum';

export class FilterColumn {
    name: string;
    filterType: FilterType;
    dataSource?: any;
    grouped?: boolean;
    fieldName?: string;
    // for multiselect that get data asynchronously must recieve a string
    getOptionsFunction?: (searchTerm: string) => Promise<any[]>;
}
