import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { ConvertApiErrorToAlertMessage } from '@pages/auth/helpers';
import { AuthService } from '@services/auth.service';

@Component({
    selector: 'jjkp-verification-code',
    styleUrls: ['./verification-code.component.scss'],
    templateUrl: './verification-code.component.html',
})
export class VerificationCodeComponent extends ValidatableFormBaseDirective {

    @Input() header: string;
    @Input() emailAddress: string;
    @Input() resendText: string;
    @Input() verificationCode: string;
    @Input() expiredTokenReset: boolean;
    @Output() successResponse = new EventEmitter<any>();
    @Output() failedResponse = new EventEmitter<string>();
    @Output() verificationCodeEvt = new EventEmitter<any>();
    @Output() cancelClicked = new EventEmitter<string>();
    @Output() resendClicked = new EventEmitter<string>();

    isProcessingRequest = false;
    hideResend = true;

    constructor(private formBuilder: UntypedFormBuilder,
                private authService: AuthService,
                private router: Router) {
        super();
        this.buildForm();
    }

    ngOnInit() {
        if (this.verificationCode && !this.expiredTokenReset) {
            this.getControl('verificationCode').setValue(this.verificationCode);
            this.processVerificationCode(this.verificationCode);
        }
    }

    private buildForm() {
        this.form = this.formBuilder.group({
            verificationCode: [null, [Validators.required]]
        });
    }

    protected async submitForm() {
        const verificationCode = this.getControl('verificationCode').value;
        await this.processVerificationCode(verificationCode);
    }

    async processVerificationCode(code: string) {
        this.isProcessingRequest = true;
        this.verificationCodeEvt.emit(code);
        await this.authService.getNonAuthToken(code)
            .then(() => this.successResponse.emit())
            .catch(errorMessage => {
                this.failedResponse.emit(ConvertApiErrorToAlertMessage(errorMessage));
                this.hideResend = false;
            })
            .finally(() => this.isProcessingRequest = false);
    }

    onCancel() {
        this.cancelClicked.emit();
        this.router.navigateByUrl(AuthRoutes.Login.urlRoute);
    }

    resend() {
        this.resendClicked.emit();
        this.hideResend = true;
    }
}
