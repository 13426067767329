<ng-container *ngIf="isLoaded">
 <!-- Tab Items -->
<section class="filter-nav">
  <ul class="nav nav-pills" role="tablist">
    <li *ngIf="showAllTab" class="nav-item" [value]="'showAllTab'" (click)="onTabClick(showAllTab)">
      <a class="nav-link" [class.active]="tabFilter === ''" data-bs-toggle="tab" href="#" role="tab">{{showAllTab}}</a>
    </li>
    <li *ngFor="let t of filteredtabItems" [value]="t" (click)="onTabClick(t.displayText)">
      <a class="nav-link" [class.active]="tabFilter === t.displayText" data-bs-toggle="tab" href="#" role="tab">{{ t.displayText }}</a>
    </li>
  </ul>
</section>
 <!-- DropDown -->
<section>
  <div class="large-filter-mobile">
    <select class="mb10" id="tab_selector" [(ngModel)]="tabFilter" (change)="onTabClick($event.target.value)">
      <option *ngIf="showAllTab" [value]="'showAllTab'">{{showAllTab}}</option>
      <option *ngFor="let t of filteredtabItems" [value]="t.displayText">{{ t.displayText }}</option>
    </select>
  </div>
</section>
</ng-container>