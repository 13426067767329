import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { PimcoreHtmlBlock } from '@models/pimcore-html-block.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { isValidArray } from '@utilities/helpers';
import { SplitioService } from './splitio.service';

@Injectable()
export class PimcoreService {

    private pimcoreIsOn: string;

    private _pimcoreHtmlBlockList = new BehaviorSubject<PimcoreHtmlBlock[]>([]);
    set pimcoreHtmlBlockList(value) {
        this._pimcoreHtmlBlockList.next(value);
    }
    get pimcoreHtmlBlockList() {
        return this._pimcoreHtmlBlockList.getValue();
    }
    pimcoreHtmlBlockList$: Observable<PimcoreHtmlBlock[]> = this._pimcoreHtmlBlockList.asObservable();

    constructor(private splitioService: SplitioService) {
        const getPimcoreFeatureToggle = this.splitioService.getToggle(TOGGLES.PIMCORE_FEATURE);
        Promise.all([getPimcoreFeatureToggle]).then((values) => {
            this.pimcoreIsOn = values[0];
            if (this.pimcoreIsOn === TREATMENT.ON) {
                this.getContentFromApi();
            }
        });
    }

    getContentFromApi() {
        ApiFactory.retrieveEntity(ApiEntityTypesEnum.Pimcore)
            .addSuccessHandler((response: PimcoreHtmlBlock[]) => {
                this.pimcoreHtmlBlockList = isValidArray(response) ? response : null;
            })
        .removePaging()
        .buildAndSend();
    }

    public returnZoneContent(zones: string): string {
        zones = zones ? `SMS:${zones}` : null;
        if (this.pimcoreHtmlBlockList.length) {
            const pimcoreHtmlBlock = this.pimcoreHtmlBlockList.find((c) => c.zones === zones);
            return pimcoreHtmlBlock && pimcoreHtmlBlock.htmlBlockContent || null;
        } else {
            return null;
        }
    }
}
