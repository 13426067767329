<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 main-content">
        <sms-alert></sms-alert>
        <div class="cssGridWrapper">
            <div class="titleBlock">
                <h1>{{ title }}</h1>
                <p>{{ description }}</p>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
