import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DynamicValidator } from './dynamic-validator.model';

export class DynamicValidators {
  static readonly required = new DynamicValidator('required', true, () => {
    return Validators.required;
  });
  static readonly maxLength = (defaultValue: number) => {
    return new DynamicValidator('maxLength', defaultValue, () => {
      return Validators.maxLength(defaultValue);
    });
  }
  static readonly minLength = (defaultValue: number) => {
    return new DynamicValidator('minLength', defaultValue, () => {
      return Validators.minLength(defaultValue);
    });
  }
  static validationRange(min: string, max: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const inRange = control.value <= max && control.value >= min;

        return inRange ? null : {outOfRange: {value: control.value}};
      }
    }
  }
  static hasAtMostDecimalPlaces(maxPlaces: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let message = 'Input has too many decimal places';
      if (control.value) {
        if (!isNaN(control.value) || control.value == '.') {
            const v = control.value.toString();
            if (v.includes('.')) {
                const a = v.split('.')[1];
                if (a.length > maxPlaces) {
                    return { hasAtMostDecimalPlaces: { message } };
                }
            }
            return null;
        }
        message = 'Input is an invalid number';
        return { hasAtMostDecimalPlaces: { message } };
      }
      return null;
    }
  }
  static readonly custom = (validator: ValidatorFn) => {
    return new DynamicValidator('custom', null, () => {
      return validator;
    });
  }
  static readonly greaterThan = (defaultValue: number | Date, refControl: UntypedFormControl = null, messageOverride: string = null) => {
    return new DynamicValidator('greaterThan', defaultValue, () => {
      return (control: UntypedFormControl) => {
        if (!control.value) { return null; } // Nothing to validate
        const message = messageOverride !== undefined && messageOverride !== null // Empty string could be sent to avoid any message
          ? messageOverride
          : `Should be greater than ${refControl ? refControl.value : defaultValue}`;
        // Don't do validations over other types
        let isValid = false;
        if (!isNaN(control.value)) {
          if (Number(control.value) > Number(refControl.value)) {
            isValid = true;
          }
        } else {
          const dateValue = moment(control.value).toDate();
          if (dateValue && dateValue > moment(refControl.value).toDate()) {
            isValid = true;
          }
        }
        return isValid ? null : { greaterThan: { message } }; // Null -> Everything fine
      };
    });
  }

  static readonly greaterThanDatepickers = (defaultValue: number | Date, refControl: UntypedFormControl = null, messageOverride: string = null) => {
    return new DynamicValidator('greaterThan', defaultValue, () => {
      return (control: UntypedFormControl) => {
        if (!control.value) { return null; } // Nothing to validate
        const message = messageOverride !== undefined && messageOverride !== null // Empty string could be sent to avoid any message
          ? messageOverride
          : `Should be greater than ${refControl ? refControl.value.startDate : defaultValue}`;
        // Don't do validations over other types
        let isValid = false;
        if (!isNaN(control.value)) {
          if (Number(control.value) > Number(refControl.value)) {
            isValid = true;
          }
        } else {
          const dateValue = moment(control.value.startDate).toDate();
          if (dateValue && dateValue > moment(refControl.value.startDate).toDate()) {
            isValid = true;
          }
        }
        return isValid ? null : { greaterThan: { message } }; // Null -> Everything fine
      };
    });
  }
}
