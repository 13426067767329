import { Component, Input } from '@angular/core';

@Component({
  selector: 'jjk-datagrid-column',
  template: ``,
})
export class DataGridColumnComponent {
  @Input() columnName: string;
  @Input() displayText: string;
  @Input() priority = 0;
  @Input() alwaysShow: boolean;
  @Input() pipe: string;
  @Input() canSort = true;
  @Input() authFeature: string = null;
  @Input() hidden = false;

  protected _columnType: string;
  public get columnType() { return this._columnType; }

  constructor() {
  }
}
