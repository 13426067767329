/*
This separate break out is used to build a complete route list at the app level while
maintaining Lazy loading of modules.  We need this information to restore a deep linking
request.
*/
export const SettingRoutes = {
    RolePermission: { urlRoute: 'roles_permissions', children: {} },
    IncidentPermission: { urlRoute: 'incidents_auto_numbering_edit', children: {} },
    IncidentIntakeForm: { urlRoute: 'incident_intake_form', breadcrumbText: 'Settings / Incident Intake Form Settings', children: {} },
    LocationPermission: { urlRoute: 'incidents_auto_numbering_edit/locations', children: {} },
    FeatureAccess: { urlRoute: 'feature-access', breadcrumbText: 'Settings / Feature Access', children: {} },
    DataGroupAccess: { urlRoute: 'group-access', breadcrumbText: 'Settings / Data Group Access', children: {} },
    UserGroupAccess: { urlRoute: 'user-group-access', breadcrumbText: 'Settings / User Group Access', children: {} },
    UserAccess: { urlRoute: 'user-access', breadcrumbText: 'Settings / User Access', children: {} },
    ExplainAccess: { urlRoute: 'explain-access', breadcrumbText: 'Settings / Explain Access', children: {} },
    CobrandingSiteLogo: { urlRoute: 'manage-site-logo', breadcrumbText: 'Settings / Manage Cobranding Site Logo', children: {} },
};
