import { environment } from '@env/environment';

export const SIDE_NAV_SDS_MGMT = [
    {
        groupName: '',
        accordion: [
            {
                id: 'dashboard',
                name: 'Dashboard',
                icon: 'custom-icon-dashboard',
                route: '/dashboard',
                authTag: 'dashboard',
            },
        ],
    },
    {
        groupName: 'RESOURCES',
        accordion: [
            {
                id: 'supportCenter',
                name: 'Support Center',
                icon: 'custom-icon-support-center',
                route: environment.supportCenterUrl,
                authTag: '',
            },
        ],
    },
    {
        groupName: 'MENU',
        accordion: [
            {
                id: 'sdsManagement',
                name: 'SDS Management',
                icon: 'custom-icon-sds-management',
                authTag: 'sds-management',
                items: [
                    { route: '/sds_management/requests', name: 'Requests', authTag: 'sds-management-requests'},
                    { route: '/sds_management/global_products', name: 'Global Products', authTag: 'sds-management-global-products'},
                ],
            },
        ],
    },
];
