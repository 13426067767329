import { Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { isNullOrUndefined } from '@utilities/helpers';
import { FeatureAuthService } from '@services/feature-auth.services';

@Directive({
    selector: '[auth]',
})
export class AuthorizeDirective implements OnDestroy {

    private _authFeature: string;
    private _authEntity: any;
    private _authNegate = false;
    private _subscription: Subscription;

    @Input()
    set authFeature(value: string) {
        if (value) {
            this._authFeature = value;
            this.refresh();
        }
    }

    @Input()
    set authEntity(value: any) {
        if (this._authFeature && value) {
            this._authEntity = value;
            this.refresh();
        }
    }

    @Input()
    set authNegate(value: boolean) {
        if (this._authFeature && value) {
            this._authNegate = value;
            this.refresh();
        }
    }
    constructor(
        private featureAuthService: FeatureAuthService,
        private element: ElementRef,
        private renderer: Renderer2,
    ) {
        this._subscription = LoggedInUserInfo.Instance.userInfoChanged.subscribe((userInfo) => {
            this.refresh();
        });
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    private refresh() {
        this.display(
            this.featureAuthService.hasAccess(this._authFeature, this._authNegate, this._authEntity),
            this.featureAuthService.isDisabled(this._authFeature, this._authNegate, this._authEntity));
    }

    private display(show: boolean, disable: boolean) {
        if (show) {
            if (!isNullOrUndefined(this.element.nativeElement.parentNode)) {
                this.renderer.appendChild(this.element.nativeElement.parentNode,this.element.nativeElement);
            } else {
                const parent = this.renderer.createElement('ng-container');
                this.renderer.appendChild(parent,this.element.nativeElement);
            }
        } else {
            if (!isNullOrUndefined(this.element.nativeElement.parentNode)) {
                this.renderer.removeChild(this.element.nativeElement.parentNode,this.element.nativeElement);
            } else {
                const parent = this.renderer.createElement('ng-container');
                this.renderer.appendChild(parent,this.element.nativeElement);
                this.renderer.removeChild(parent,this.element.nativeElement);
            }
        }
        if (disable) {
            this.renderer.setProperty(this.element.nativeElement, 'disabled', 'true');
            this.renderer.addClass(this.element.nativeElement, 'disabled');
        } else {
            this.renderer.removeAttribute(this.element.nativeElement, 'disabled');
            this.renderer.removeClass(this.element.nativeElement, 'disabled');
        }
    }
}
