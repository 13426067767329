import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { NonAuthRoutes } from '@constants/enums/non-auth-routes.enum';
import { UserTypes } from '@constants/enums/user-type.enum';
import { SIDE_NAV_CONFIG } from '@constants/settings/side-nav-config';
import { SIDE_NAV_NON_AUTH_CONFIG } from '@constants/settings/side-nav-non-auth-config';
import { SIDE_NAV_SDS_MGMT } from '@constants/settings/side-nav-sds-mgmt';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { IMenuGroup } from '@models/side-nav.models';
import { SmsNavigationManager } from '@modules/navigation/sms-navigation-manager.class';
import { MenuService } from '@services/menu.service';
import { SplitioService } from '@services/splitio.service';
import { checkSSO, hideModal, unsubscribeFromAll } from '@utilities/helpers';

@Component({
    selector: 'layout-side-nav',
    templateUrl: './layout-side-nav.component.html',
    styleUrls: ['./layout-side-nav.component.scss'],
})
export class LayoutSideNavComponent implements OnInit, OnDestroy {

    // Menu values to allow us to target specific menu groups or items
    menuGroupResources = 'RESOURCES';
    menuItemExpertHelp = 'expertHelp';
    menuItemDiscussions = 'discussions';
    ssoType = '';
    openSubMenuFlag = true;
    subscriptions$: Subscription[] = [];
    navList: IMenuGroup[] = this.loadNavLinks();
    active = false;
    isNonAuth = false;

    constructor(
        private menuService: MenuService,
        private router: Router,
        private splitioService: SplitioService,
        private navigator: SmsNavigationManager) {
        this.openSubMenuFlag = true;
        router.events.subscribe((val) => {
            this.setMenuItemActive();
            this.openMenuItemActive();
        });
        this.router.events
            .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
            .subscribe((events: RoutesRecognized[]) => {
                // comparing previous url to current url
                if (events[0].urlAfterRedirects !== events[1].urlAfterRedirects) {
                    this.setMenuItemActive();
                    this.openSubMenuFlag = true;
                    this.openMenuItemActive();
                }
            });
    }

    async ngOnInit() {
        this.setMenuItemActive();
        this.menuService.change.subscribe((active) => {
            this.active = active;
            this.setMenuItemActive();
        });
        this.menuService.refresh.subscribe((data) => {
            this.refreshNavList();
        });
        this.subscriptions$.push(this.splitioService.isReady.subscribe({
            next: (isReady) => {
                if (isReady) {
                    this.refreshNavList();
                }
            },
        }));
    }

    ngOnDestroy(): void {
        unsubscribeFromAll(this.subscriptions$);
    }

    private refreshNavList() {
        this.navList = [];
        setTimeout(() => { this.navList = this.loadNavLinks(); }, 0);
    }

    closeSideNav(a?: any) {
        if (a) {
            if (a.id === this.menuItemExpertHelp) {
                this.ssoType = 'Expert Help-Zendesk';
                checkSSO(this.ssoType);
                return;
            } else if (a.id === this.menuItemDiscussions) {
                this.ssoType = 'Discussions';
                checkSSO(this.ssoType);
                return;
            } else if (a.id === 'dashboard') {
                this.navigator.navigateToDashboard();
                return;
            } else if (a.id === 'supportCenter') {
                window.open(a.route, '_blank');
                return;
            } else if (a.id === 'webcasts') {
                window.open(a.route, '_blank');
                return;
            } else if (!a.route) {
                window.location.reload();
                return;
            }
            if (!LoggedInUserInfo.Instance.userInfo.isNonAuth || !Object.values(NonAuthRoutes).includes(a.route)) {
                this.router.navigate([a.route]);
            }
        }
        this.menuService.toggleSideNav();
    }

    loadNavLinks() {
        let navList: IMenuGroup[];
        if (LoggedInUserInfo.Instance.userInfo.isNonAuth) {
            this.isNonAuth = true;
            navList = SIDE_NAV_NON_AUTH_CONFIG;
        } else if (LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.SdsMgmt) {
            navList = SIDE_NAV_SDS_MGMT;
        } else {
            navList = SIDE_NAV_CONFIG;
        }

        const sdsManagementIndex = navList.filter(n => n.groupName === 'MENU')[0].accordion.findIndex(i => i.id === 'sdsManagement');
        if (sdsManagementIndex > -1 && !(LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.SuperAdmin || LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.SupportUser ||
            LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.ProductOwner || LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.SdsMgmt)) {
            navList.filter(n => n.groupName === 'MENU')[0].accordion.splice(sdsManagementIndex, 1);
        }

        return navList;
    }

    // needed so we can call method from template, but use the helper method (instead of duplicating code)
    hideModal(selector: string) {
        hideModal(selector);
    }

    setMenuItemActive() {
        setTimeout(() => {
            this.processMenuItems('nav-item-route', 'active');
            this.processMenuItems('sub-nav-item-route', 'active-sub-nav');
            this.openMenuItemActive();
        }, 1000);
    }

    openMenuItemActive() {
        setTimeout(() => {
            this.openSubMenu('sub-nav-item-route', 'active-sub-nav');
        }, 1000);
    }

    processMenuItems = (menuAttrName: string, cssClassName: string) => {

        if (LoggedInUserInfo.Instance.userInfo.isNonAuth) {
            const btns: any = document.querySelectorAll('[sidebar-nav]');
            for (const btnitem of btns as any) {
                if (!this.router.url.replace(/[\W_]/g, '').toLowerCase().startsWith(btnitem.getAttribute('sidebar-nav').toLowerCase().replace(/[\W_]/g, ''))) {
                    btnitem.setAttribute('class', 'hide-item');
                }
            }
        }

        const items: NodeListOf<Element> = document.querySelectorAll('[' + menuAttrName + ']');
        for (const item of items as any) {
            item.classList.remove(cssClassName);

            if (this.router.url.replace(/[\W_]/g, '').toLowerCase() === item.getAttribute(menuAttrName).toLowerCase().replace(/[\W_]/g, '') ||
                (LoggedInUserInfo.Instance.userInfo.isNonAuth && this.router.url.replace(/[\W_]/g, '').toLowerCase().startsWith(item.getAttribute(menuAttrName).toLowerCase().replace(/[\W_]/g, '')))) {
                item.setAttribute('class', cssClassName);
            }
        }

    }

    openSubMenu = (menuAttrName: string, cssClassName: string) => {
        const items: NodeListOf<Element> = document.querySelectorAll('[' + menuAttrName + ']');
        // const cnElems = document.getElementsByClassName('collapse show');
        for (const item of items as any) {
            if (this.router.url.replace(/[\W_]/g, '').toLowerCase().startsWith(item.getAttribute(menuAttrName).toLowerCase().replace(/[\W_]/g, ''))) {
                if (menuAttrName === 'sub-nav-item-route') {
                    if (this.openSubMenuFlag) {
                        item.closest('.collapse').setAttribute('class', 'collapse show');
                        this.openSubMenuFlag = false;
                    }
                }
            }
        }
    }
}
