import { ApiActionsEnum } from '@constants/enums/api-actions.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';

export class EndPoint {
    urlTemplate: string;
    reqFields: any[];
    optFields: any[];
    apiAction: ApiActionsEnum;
    entityType: ApiEntityTypesEnum;

    /**
     * This method is used only when overriding the auto endpoint logic.
     * @param urlTemplate
     * @param apiAction
     * @param reqParameters
     * @param optParameters
     */
    public static ManualConfiguration(urlTemplate: string,
                                      apiAction: ApiActionsEnum,
                                      reqParameters: string[],
                                      optParameters: string[]) {
        const endPoint = new EndPoint(urlTemplate , null, apiAction);
        endPoint.reqFields = reqParameters;
        endPoint.optFields = optParameters;
        return endPoint;
    }

    populate(url: string, swaggerEntry: any): EndPoint {
        if (!swaggerEntry) {
            return this;
        }

        this.entityType = ApiEntityTypesEnum[ApiEntityTypesEnum[swaggerEntry.tags.toString()]];
        this.processParameters(swaggerEntry.parameters);
    }

    /** This method will look at each parameter and decide
     * required or not, storing them accordingly
     */
    private processParameters(parameters: any[]) {
        this.reqFields = parameters.filter((e) => e.required);
        this.optFields = parameters.filter((e) => !e.required);
    }

    constructor(url: string, swaggerEntry: any, apiAction: ApiActionsEnum) {
        this.urlTemplate = url;
        this.apiAction = apiAction;
        this.populate(url, swaggerEntry);
    }
}
