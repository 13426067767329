export const allValidExtension = [
    '.doc',
    '.docx',
    '.ppt',
    '.pptx',
    '.xls',
    '.xlsx',
    '.gif',
    '.jpg',
    '.jpeg',
    '.png',
    '.rtf',
    '.tsv',
    '.txt',
    '.csv',
    '.pdf',
    '.svg',
    '.mov',
    '.mp4',
    '.mkv',
    '.wav',
    '.wmv',
];
