import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ISmsColumnSortedEvent } from './sms-column-sorted-event.model';

@Injectable()
export class SmsSortService {
    private columnSortedSource = new Subject<ISmsColumnSortedEvent>();
    columnSorted$ = this.columnSortedSource.asObservable();

    private defaultSortConfig = { sortColumn: 'title', sortDirection: 'asc' } as ISmsColumnSortedEvent;
    private sortEvents = new Map<string, ISmsColumnSortedEvent>();

    constructor() {
    }

    raiseSortEvent(sortModule: string, sortEvent = this.defaultSortConfig) {
        this.saveSortEvent(sortModule, sortEvent);
        this.columnSortedSource.next(sortEvent);
    }

    raiseLastSortEvent(sortModule: string, eventOverride?: ISmsColumnSortedEvent) {
        const lastEvent = this.sortEvents.get(sortModule);
        if (lastEvent) {
            if (lastEvent.sortColumn === eventOverride.sortColumn) {
                this.columnSortedSource.next(lastEvent);
            } else {
                this.eventOverride(sortModule, eventOverride);
            }
        } else {
            this.eventOverride(sortModule, eventOverride);
        }
    }

    eventOverride(sortModule: string, event?: ISmsColumnSortedEvent) {
        if (event) {
            this.raiseSortEvent(sortModule, event);
        } else {
            this.raiseSortEvent(sortModule);
        }
    }

    getLastSortEvent(sortModule: string) {
        return this.sortEvents.get(sortModule);
    }

    private saveSortEvent(sortModule: string, sortEvent: ISmsColumnSortedEvent) {
        this.sortEvents.set(sortModule, sortEvent);
    }
}

export function SmsSortServiceFactory() {
    return new SmsSortService();
}

export const smsSortServiceServiceProvider = {
    provide: SmsSortService,
    useFactory: SmsSortServiceFactory,
};
