import { Component, Input } from '@angular/core';

@Component({
    selector: 'jjkp-file-icon',
    templateUrl: './file-icon.component.html',
})
export class FileIconComponent {
    @Input() fileType: any;

}
