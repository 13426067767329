<div class="datepicker-container">
    <mat-form-field appearance="outline">
        <mat-date-range-input [rangePicker]="picker">
            <input
                matStartDate
                class="datepicker"
                placeholder="Start date"
                [value]="startDate"
                [attr.disabled]="isDisabled"
                (dateInput)="onDateChanged($event, 'startDate')"
                (dateChange)="onDateChanged($event, 'startDate')">
            <input
                matEndDate
                class="datepicker"
                placeholder="End date"
                [value]="endDate"
                (dateInput)="onDateChanged($event, 'endDate')"
                (dateChange)="onDateChanged($event, 'endDate')">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
</div>