import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[jjk-bootstrap-popover]',
})
export class PopoverDirective implements AfterViewInit {
    @Input() content = '';
    @Input() title = '';
    @Input() trigger = 'focus';
    @Input() placement = 'right';

    constructor(private element: ElementRef,
                private renderer: Renderer2) {
    }

    ngAfterViewInit() {
        this.renderer.setAttribute(this.element.nativeElement, 'data-bs-toggle', 'popover');
        this.renderer.setAttribute(this.element.nativeElement, 'data-bs-placement', this.placement);
        this.renderer.setAttribute(this.element.nativeElement, 'data-bs-trigger', this.trigger);
        this.renderer.setAttribute(this.element.nativeElement, 'data-bs-container', 'body');
        this.renderer.setAttribute(this.element.nativeElement, 'data-bs-content', this.content);
        if (this.title) {
            this.renderer.setAttribute(this.element.nativeElement, 'data-bs-title', this.title);
        }
        setTimeout(() => {
            ($('[data-bs-toggle="popover"]') as any).popover();
        }, 1000);
    }

}