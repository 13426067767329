import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ag-grid-custom-toolbarV2',
  templateUrl: './ag-grid-custom-toolbarV2.component.html',
  styleUrls: ['./ag-grid-custom-toolbarV2.component.scss']
})
export class AGGridCustomToolbarV2Component {

  _rowCount: number;
    @Input()
    set rowCount(data: number) {
        this._rowCount = data;
    }
    get rowCount() {
        return this._rowCount;
    }
    
    @Input() showSelectAll = false;

    @Output() action = new EventEmitter();

    constructor() {
    }

    onSelectAll(option = true) {
       if (option) {
        this.action.emit('selectAll');
       } else {
        this.action.emit('deselectAll');
       }
    }

}
