import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jjkp-login-split',
  templateUrl: './login-split.component.html',
})
export class LoginSplitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
