import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbItem } from '@models/breadcrumb-item.model';

@Component({
  selector: 'sms-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {

  @Input() breadcrumbItems: BreadcrumbItem[] = [];

  constructor() {
  }

  ngOnInit() {
  }
}
