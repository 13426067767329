import { Component, Input, OnInit } from '@angular/core';
import { IToggle } from '../../../models/toggle-model';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit, IToggle {
  id: string;
  @Input() isChecked: boolean;
  @Input() isDisabled: boolean;
  parent: IToggle;
  children: IToggle[] = [];

  ngOnInit() {
  }

  getIsDisabled(child: IToggle): boolean {
    return this.parent.isDisabled // a disabled parent disables all its children
      || child.isDisabled // child-level disable
      || (
        (!this.parent.isDisabled && !this.parent.isChecked) // parent-level check disables children
      );
  }

  public setParent(parentToggle: ToggleComponent) {
    this.parent = parentToggle;

    if (this.parent.children.find((x) => x.id === this.id) === undefined) {
      this.parent.children.push(this);
    }
  }
}
