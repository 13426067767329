import { Pipe, PipeTransform } from '@angular/core';
import { isValidArray, monthFormat } from '@utilities/helpers';

@Pipe({
    name: 'monthFormat',
})
export class MonthFormatPipe implements PipeTransform {
    transform(value: any, ...args: any[]) {
        return value
            ? monthFormat(value, isValidArray(args) ? args[0] : undefined)
            : '';
    }
}
