import { MessageType } from '../messageType';
import { Message } from './Message';

export class DataMessage extends Message {
    // this is used during handler registration
    public static messageRegistrationKey: MessageType = 'DataMessage';

    // this is used in message identification
    public get messageType(): MessageType {
        return DataMessage.messageRegistrationKey;
    }
}
