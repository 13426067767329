import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ModalAction } from '@constants/enums/modal-action.enum';
import { TrainingEventStatus } from '@constants/enums/training-event-status.enum';
import { fileTypesForPreview } from '@constants/file-types-for-preview';
import { User } from '@models/entity-models/autogenerated/user';
import { ClassroomTrainingEvent } from '@models/entity-models/training/training-classroom-program-event.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { LoggerService } from '@services/core/logger-service.class';
import { SplitioService } from '@services/splitio.service';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';

/**
 * Returns a boolean representing whether n
 * can parse to a Number properly.
 * @param {*} n
 */
export const isNumeric = (n) => {
    return n instanceof Date || (!isNaN(parseFloat(n)) && isFinite(n));
};

/**
 * Generates a new alphanumeric id
 * @param len The new id length
 */
export function newRandomId(len: number): string {
    let text = '';
    const charset = 'abcdefghijklmnopqrstuvwxyz';
    const numset = '0123456789';

    for (let i = 0; i < len; i++) {
        if (i % 2 === 0) {
            text += charset.charAt(Math.floor(Math.random() * charset.length));
        } else {
            text += numset.charAt(Math.floor(Math.random() * numset.length));
        }
    }

    return text;
}

/**
 * Allow us to execute a complex function to return a value based on another value check first
 * @param value Value to be checked
 * @param notValue To return when the value is not valid
 * @param yesValuefn Determines logic to process the original value and build a new one based on it
 */
export function ifNot<T>(value: T, notValue: any, yesValuefn: (yesValue: T) => any = null): any {
    // Empty arrays are truthy so they need special handling
    if (value instanceof Array) {
        return value.length > 0;
    }
    // If there is a valid processing function then go for it, otherwise just return the original value
    return value ? (yesValuefn ? yesValuefn(value) : value) : notValue;
}

/**
 * True if the given instance is an Array and is not empty
 * @param object Object to look for the property name
 * @param value Value to perform the properties lookup
 */
export function getPropertyNameByValue<T>(object: T, value: any): string {
    return Object.keys(object).find((key) => object[key] === value);
}

/**
 * Returns the object with the updated form values for matching properties.
 * Form control names must match the object properties they update.
 * @param form FormGroup object with new values
 * @param object Object with properties to be updated
 */
export function updatePropertiesWithFormValues(form: UntypedFormGroup, object: any): any {
    Object.keys(form.controls).forEach((key) => {
        object[key] = form.get(key).value;
    });
    return object;
}

/**
 * Evaluates an object to determine if it's a valid array or not
 * @param value Array instance to be evaluated
 */
export function isValidArray(value: any) {
    return value instanceof Array && value.length > 0;
}

/**
 * Search for an element and put focus on it
 * @param jQueryOrId Html element or the element id to lookup
 */
export function focusOn(jQueryOrId: string | JQuery<HTMLElement>, selectContent = false) {
    const foundElement = resolveElement(jQueryOrId);
    if (foundElement && foundElement.length > 0) {
        setTimeout(() => {
            window.scrollTo(0, foundElement.offset().top - window.innerHeight / 3);
            foundElement.focus();
            if (selectContent) {
                foundElement.select();
            }
        }, 500);
    }
}

/**
 * Looks and shows up a modal dialog
 * @param jQueryOrId Html element or the element id to lookup
 * @param autoFocus If true sets focus on the first text, textarea, select element
 */
export function showModal(jQueryOrId: string | JQuery<HTMLElement>, autoFocus = true) {
    const foundElement = resolveElement(jQueryOrId);
    foundElement.modal(ModalAction.Show);
    if (autoFocus) { focusOnFirstInput(foundElement); }
}

/**
 * Sets focus on the first text, textarea, select element
 * @param jQueryOrId Html element or the element id to lookup
 */
export function focusOnFirstInput(jQueryOrId: string | JQuery<HTMLElement>) {
    const foundElement = resolveElement(jQueryOrId);
    if (foundElement) {
        focusOn(foundElement.find(':text,textarea,select').filter(':first'));
    }
}
/**
 * Looks and hides a modal dialog
 * @param jQueryOrId Html element or the element id to lookup
 */
export function hideModal(jQueryOrId: string | JQuery<HTMLElement>) {
    const foundElement = resolveElement(jQueryOrId);
    foundElement.modal(ModalAction.Hide);
}

export function isString(x: any) {
    return typeof x === 'string' || x instanceof String;
}

export function isBoolean(x: any) {
    return typeof x === 'boolean' || x instanceof Boolean;
}

export function isNumberKey(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
        return false;
    }
    return true;
}
/**
 * Build a new { id, displayText } array based on the given enum
 * @param enumeration The enum to be processed
 */
export function enumToListOptions(enumeration): any {
    const options = [];
    Object.keys(enumeration).forEach((x) => {
        options.push({ id: x, displayText: enumeration[x] });
    });
    return options;
}

export function enumNameFromValue(enumeration, value): string {
    let name = '';
    Object.keys(enumeration).some((el) => {
        if (enumeration[el] === value) {
            name = el;
            return true;
        }
        return false;
    });
    return name;
}

/**
 * Function to sort map JSON data response for use as options by a select box
 * @param json JSON data being passed
 */
export function mapToListObjects(json: any) {
    return json
        .map((x) => ({
            id: x.id,
            displayText: x.name,
            isActive: x.isActive,
            isDeleted: x.isDeleted,
            name: x.name || '',
        }))
        .sort(sortOptionsAscending);
}
/**
 * Compose a full name in the format of [lastName, firstName].
 * Remarks: if will ommit @lastName or @firstName if not valid
 */
export function buildFullName(lastName?: string, firstName?: string) {
    if (firstName && lastName) {
        return `${lastName}, ${firstName}`;
    } else if (firstName) {
        return firstName;
    } else if (lastName) {
        return lastName;
    }
    return '';
}
/**
 * Returns today's date in the MM/DD/YYY string format
 */
export const today = {
    asShortDateString: dateToDateString(new Date()),
};
/**
 * @returns HH:MM of full json date string
 */
export function dateToTimeString(fulldate: any): string {
    const time = fulldate ? fulldate.substring(11, 16) : fulldate;
    return time;
}
/**
 * @returns MM/DD/YYY of date or full json date string
 */
export function dateToDateString(fulldate: any): string {
    if (!fulldate) { return fulldate; }
    const date = new Date(fulldate);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const shortdate = `${fixDigit(month)}/${fixDigit(day)}/${year}`;
    return shortdate;
}

/**
 * Prepends zeros to single digits
 */
export function fixDigit(val): string {
    if (!val || val === '00') { return val; }
    if (typeof (val) === 'string') {
        val = parseInt(val, 10) || val;
    }
    return (val < 10 ? '0' : '') + val;
}

/**
 * Removes properties with null values from the object
 */
export function removeNulls(obj) {
    for (const prop in obj) {
        if (obj[prop] === null || obj[prop] === undefined) {
            delete obj[prop];
        }
    }
}

export function GetFirstOrNull<T>(theArray: T[], propertySelector: (data: T) => any) {
    if (theArray && theArray.length > 0) {
        return propertySelector(theArray[0]);
    }
    return null;
}

export function GetFirstMatchOrNull<T>(theArray: T[], match: (item) => boolean, propertySelector?: (data: T) => any) {
    if (theArray && theArray.length > 0) {
        for (const item of theArray) {
            if (match(item)) {
                if (propertySelector) {
                    return propertySelector(item);
                } else {
                    return item;
                }
            }
        }
    }
    return null;
}

export function stringToDate(value: string) {
    return value ? new Date(value) : undefined;
}

export function booleanToString(value: boolean) {
    return value ? 'true' : 'false';
}

export function booleanToYesNo(value: boolean) {
    return value ? 'Yes' : 'No';
}

export function valueToBoolean(value) {

    if (isBoolean(value)) {
        return value;
    }

    if (isString(value)) {

        value = value.toLocaleLowerCase();

        if (value === 'true') {
            return true;
        } else if (value === 'false') {
            return false;
        } else if (value === 'yes') {
            return true;
        } else if (value === 'no') {
            return false;
        } else {
            return false;
        }
    }

    return value === 1 ? true : false;

}

export function editorialDateFormat(value: any) {
    return new Date(value).getTime() > new Date('1/1/1900').getTime() ? value : null;
}

export function dateFormat(value: any, format?: string) {
    return value && moment(value).isAfter('1899-12-31T00:00:00Z', 'day')
        ? moment(new Date(value)).format(format || 'MM/DD/YYYY')
        : '';
}

export function momentFormatToSubmit(value: moment.Moment, format?: string): string {
    return value && value.isAfter('1899-12-31T00:00:00Z', 'day')
        ? value.format(format || 'MM/DD/YYYY')
        : '';
}

export function momentFormatFromApi(value: Date | string): moment.Moment {
    if (!value) {
        return null;
    }
    const dateAsMoment = moment(value);
    return dateAsMoment.isAfter('1899-12-31T00:00:00Z', 'day')
        ? dateAsMoment
        : null;
}

export function monthFormat(value: number, format?: string) {
    return value
        ? moment(`${value}/01/2019`).format(format || 'MMMM')
        : '';
}

export function getDateAuditFormat() {
    return moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS');
}

// Converts MMM strings to 1 based corresponding month
export function getMonthFilterParamsDateFormat(month: any) {
    switch (month) {
        case 'Jan': {
            month = 1;
            break;
        }
        case 'Feb': {
            month = 2;
            break;
        }
        case 'Mar': {
            month = 3;
            break;
        }
        case 'Apr': {
            month = 4;
            break;
        }
        case 'May': {
            month = 5;
            break;
        }
        case 'Jun': {
            month = 6;
            break;
        }
        case 'Jul': {
            month = 7;
            break;
        }
        case 'Aug': {
            month = 8;
            break;
        }
        case 'Sep': {
            month = 9;
            break;
        }
        case 'Oct': {
            month = 10;
            break;
        }
        case 'Nov': {
            month = 11;
            break;
        }
        case 'Dec': {
            month = 12;
            break;
        }
        default: {
            month = 1;
            break;
        }
    }

    return month;
}

export function timeFormat(value: any) {
    return value ?
        moment(value).format('h:mmA')
        : '';
}

export function resolveElement(jQueryOrId: string | JQuery<HTMLElement>) {
    return isString(jQueryOrId)
        ? $(`#${jQueryOrId}`).filter(':first')
        : (jQueryOrId as any);
}
export function sortOptionsDescending(a, b) {
    if (a.displayText.toLowerCase() > b.displayText.toLowerCase()) {
        return -1;
    } else if (a.displayText.toLowerCase() < b.displayText.toLowerCase()) {
        return 1;
    } else {
        return 0;
    }
}

export function sortOptionsAscending(a, b) {
    if (a.displayText?.toLowerCase() < b.displayText?.toLowerCase()) {
        return -1;
    } else if (a.displayText?.toLowerCase() > b.displayText?.toLowerCase()) {
        return 1;
    } else {
        return 0;
    }
}

// Assumes the list has a property id
export function findId(list, id, required) {
    const length = list.length >>> 0;
    for (let i = 0; i < length; i++) {
        if (list[i].id === id) {
            return list[i];
        }
    }
    if (required === true) {
        throw new Error(('Assert failed'));
    }
    return null;
}

/**
* Walks a path such as item.subitem.subitem[2].subitem3
* starting from obj as base following path and returns the obj
* Part of the path can be {num} which uses the findId on the selected
* element to search for the element with that id.
* id.
* @param obj
* @param path
*/
export function walkPathForObj(obj: any, path: string): any {

    const split = path.split('.');
    for (let elem of split) {
        // eslint-disable-next-line no-useless-escape
        elem = elem.replace('\'', '').replace('\"', '');
        elem = elem.trim();
        if (elem[elem.length - 1] === ']') {
            const start = elem.indexOf('[');
            const num = elem.substr(start + 1, elem.length - start - 2).trim();
            obj = (obj as any)[elem.substr(0, start)];
            obj = (obj as any[])[Number(num)];
        } else if (elem[elem.length - 1] === '}') {
            const start = elem.indexOf('{');
            const num = elem.substr(start + 1, elem.length - start - 2).trim();
            obj = (obj as any)[elem.substr(0, start)];
            obj = findId(obj as any[], num, true);
        } else {
            obj = obj && (obj as any)[elem] || '';
        }
    }
    return obj;
}

export function scrollToTop() {
    window.scrollTo(0, 0);
}

export function isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
}

export function isNullOrEmpty(value: any): boolean {
    return value === null || (value !== 0 && (!value || (value && value.toString().trim() === '')));
}

export function isNullOrEmptyDate(value: any): boolean {
    return value === null || value === '';
}

export function fileCanBePreviewed(value: string): boolean {
    if (value && fileTypesForPreview.includes(value)){
        return true;
    } else {
        return false;
    }
}

export function processBlobResponse(res: HttpResponse<Blob>, openAsDownload = true, fileNameOverride = undefined) {
    const fileName = fileNameOverride ? fileNameOverride : getFileNameFromHttpHeaders(res.headers);
    const url = window.URL.createObjectURL(res.body);

    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        window.open(url, '_self');
        return;
    }
    if (!openAsDownload) {
        window.open(url, '_blank');
        return;
    }

    openUrl(url, fileName);
    window.URL.revokeObjectURL(url);
}

export function fileDirectPreview(res: HttpResponse<Blob>) {
    const url = window.URL.createObjectURL(res.body);
    window.open(url, '_self');
}

export function getFileNameFromHttpHeaders(headers: HttpHeaders) {
    const disposition = headers.get('Content-Disposition');

    // File name can come wrapped with/without ""
    let startString = 'filename="';
    let startIndex = disposition.indexOf(startString);
    if (startIndex !== -1) {
        return stringPart(disposition, startString, '";');
    }

    startString = 'filename=';
    startIndex = disposition.indexOf(startString);
    if (startIndex !== -1) {
        return stringPart(disposition, startString, ';');
    }

    throw new Error('File name cannot be resolved with the given http headers');
}

export function stringPart(text: string, startString: string, endString: string) {
    let fileName = text.substring(text.indexOf(startString) + startString.length);
    fileName = fileName.substring(0, fileName.indexOf(endString));
    return fileName;
}

export function openUrl(url: string, fileName?: string) {
    // This can't be done but DOM manipulation otherwise pop-up blockers might affect the logic
    // Besides of this, html elements cannot sent authorization headers so using <a href="" is not an option
    const a = document.createElement('a');
    a.setAttribute('style', 'display: none');
    a.id = newRandomId(4);
    a.href = url;
    a.target = '_blank';
    if (fileName) { a.download = fileName; }
    document.body.appendChild(a);
    a.click();
    $(`#${a.id}`).remove(); // HTMLElement does not support direct remove in MS browsers
}

/* Method called when Expert Help or Discussions links are clicked - this will call the ExpertHelp or Discussions API method
to get the proper SSO URL to send the user to.  API will not be called if another API call is already in progress*/
let isSSOComplete = true;

export function checkSSO(ssoType: string) {
    if (isSSOComplete) {
        let apiEntity;
        let apiRouteHint;
        if (ssoType === 'Expert Help-Zendesk') {
            apiEntity = ApiEntityTypesEnum.ExpertHelp;
            apiRouteHint = 'ExpertHelpSSO';
        } else if (ssoType === 'Discussions') {
            apiEntity = ApiEntityTypesEnum.Discussions;
            apiRouteHint = 'VanillaSSO';
        }
        isSSOComplete = false;
        const apiFactory = ApiFactory.retrieveEntity(apiEntity)
            .addRouteHint(apiRouteHint)
            .addSuccessHandler((data) => {
                redirectToSSOSite(data);
            })
            .addErrorHandler(() => {
                handleSSOError();
                isSSOComplete = true;
            });
        if (ssoType == 'Expert Help-Zendesk') {
            apiFactory.skipClientSideCache();
        }
        apiFactory.buildAndSend();
    }
}

async function redirectToSSOSite(data) {
    const splitioService = new SplitioService()
   const isZendesk = await splitioService.getToggle(TOGGLES.ZENDESK_SSO_CONVERSION)
    if (isZendesk === TREATMENT.ON) {
    const form = $("<form />").attr("method", "POST").attr("action", data.url);
    $("<input />").attr("type", "hidden").attr("name", "jwt").attr("value", data.jwt).appendTo(form);
    form.appendTo("body");
    form.submit();
} else {
    window.open(data, '_self');
}
}

// If an error occurred, show the error modal (we don't care what the error is at this point)
function handleSSOError() {
    showModal('ssoModal');
}

/**
 * Generates a new Impersonation Code
 */
export function newImpersonationCode(): string {
    let text = '';
    const numset = '0123456789';
    for (let i = 0; i < 8; i++) {
        if (i === 4) { text += '-'; }
        text += numset.charAt(Math.floor(Math.random() * numset.length));
    }
    return text;
}

/**
 * This method will create citation links from data saved in the SMS DB tables for those items that have them mapped.
 * @param citation
 */
export function createCitationLinks(citations: any[]): string {
    return citations.sort((a, b) => a.lookupName.localeCompare(b.lookupName)).map((c) => {

        let id = c.lookupName.replace(/ /gi, '');
        if (c.lookupName.includes('USC')) {
            id = `u${id.toLowerCase()}`;
        } else {
            id = `r${id}`;
        }

        return `<a href="/regsense/details/${id}">${c.lookupName}</a>`;
    }).join(', ');
}

/**
 * This method will create citation links from the CfrTitle property of the EditorialMetaDataIndexContent object or any other data structured in that manner.
 * @param cfrTitles
 */
export function createCitationLinksFromCfrTitle(cfrTitles: string[]): string {
    return cfrTitles.sort((a, b) => a.substring(1).localeCompare(b.substring(1))).map((cfr) => {

        let id = cfr.replace(/ /gi, '');
        if (cfr.includes('USC')) {
            id = `u${id.toLowerCase()}`;
        } else {
            id = `r${id}`;
        }

        return `<a href="/regsense/details/${id}">${cfr}</a>`;
    }).join(', ');
}

/**
 * This method will create citation links from the citation_CfrTitle_Facet property of the EditorialMetaDataIndexContent object or any other data structured in that manner.
 * @param citation_CfrTitle_Facet
 */
export function createCitationLinksFromFacet(citation_CfrTitle_Facet: string[]): string {
    return citation_CfrTitle_Facet.sort((a, b) => a.substring(1).localeCompare(b.substring(1))).map((a) => {

        const facetParts = a.split('^');
        let id = facetParts[0];
        const desc = facetParts[1];

        if (id.includes('USC')) {
            id = id.toLowerCase();
        }

        return `<a href="/regsense/details/${id}">${desc}</a>`;
    }).join(', ');
}

/**
* Returns an array of indexes of a given string char(s) within another string
* @param str The string to search for indexes in
* @param value The string char(s) to find the indexes of
*/
export function allIndexesOf(str: string, value: string) {
    const indices = [];
    for (let i = 0; i < str.length; i++) {
        if (str[i] === value) {
            indices.push(i);
        }
    }
    return indices;
}

/**
 * Remove all the whitespaces from the string.
 */
export function fullTrim(str: string) {
    return str ? str.replace(/\s/g, '') : '';
}

/**
 * Navigate to an anchor on a page
 */
export function goToAnchor(anchor: string) {
    if (anchor) {
        setTimeout(() => {
            const element_to_scroll_to = document.getElementById(anchor);
            window.scrollTo(0, element_to_scroll_to.offsetTop + 75);
        }, 500);
    }
}

export function sortByProperty<T>(array: T[], property: (p: T) => any, sortDescending = false) {
    if (isValidArray(array)) {
        array.sort((a, b) => compareByProperty(a, b, property));
        if (sortDescending) {
            array.reverse();
        }
    }
}

export function compareByProperty(a, b, property: (p: any) => any) {
    if (property(a) < property(b)) {
        return -1;
    }
    if (property(a) > property(b)) {
        return 1;
    }
    return 0;
}

export function getFileType(fileType: string, isJjkData: boolean) {
    if (isJjkData) {
        let mimeType: string;
        switch (fileType) {
            case 'doc': {
                mimeType = 'application/msword';
                break;
            }
            case 'docx': {
                mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                break;
            }
            case 'xls': {
                mimeType = 'application/vnd.ms-excel';
                break;
            }
            case 'xlsx': {
                mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                break;
            }
            case 'ppt': {
                mimeType = 'application/vnd.ms-powerpoint';
                break;
            }
            case 'pptx': {
                mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
                break;
            }
            case 'jpg':
            case 'jpeg': {
                mimeType = 'image/jpeg';
                break;
            }
            case 'txt': {
                mimeType = 'text/plain';
                break;
            }
            case 'mp4': {
                mimeType = 'video/mp4';
                break;
            }
            default: {
                const mimeTypes = ['application/pdf', 'application/rtf', 'image/gif', 'image/png', 'text/csv', 'application/zip']
                    .filter((t) => t.endsWith(fileType));
                mimeType = mimeTypes.length ? mimeTypes[0] : 'text/plain';
            }
        }
        return mimeType;
    } else {
        return fileType;
    }
}

export function copyToClipboard(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
}

export function getAlphaIndexArray() {
    const alpha = ['#'];
    alpha.push(...Array(26).fill(1).map((i, x) => String.fromCharCode(65 + x)));
    return alpha;
}

export function getShowableUsers(users: User[], getOnlyActive = true) {
    if (isValidArray(users)) {
        users = users.filter((u) => !u.hideInUI);

        if (getOnlyActive) {
            users = users.filter((u) => u.isActive === true);
        }
    }
    return users;
}

/**
* Returns the parsed JSON value of the given string
* @param str The string json which is required to be parsed
*/
export function parseJSON(str: string) {
    let parsedJson: any;
    try {
        parsedJson = JSON.parse(str);
    } catch (error) {
        parsedJson = null;
        LoggerService.trace('trace', error);
    }
    return parsedJson;
}

export function secondsToTime(ms) {
    return [Math.floor((ms / 3600) % 24),
    Math.floor((ms / 60) % 60),
    Math.floor(ms % 60)].map((value) => value < 10 ? '0' + value : value).join(':');
}

export function sortOptionsByIsActive(a, b) {
    if (a.orig.isActive === b.orig.isActive) {
        return (a.displayText < b.displayText) ? -1 : (a.displayText > b.displayText) ? 1 : 0;
    } else {
        return (a.orig.isActive < b.orig.isActive) ? 1 : -1;
    }
}

export function unsubscribeFromAll(subscriptions$: Subscription[]) {
    if (isValidArray(subscriptions$)) {
        subscriptions$.forEach((s) => {
            s.unsubscribe();
        });
    }
}

export function calculateAvailableEnrollments(orders: any): number {
    let availableEnrollments = 0;
    const now = moment(new Date()).startOf('day').utc().toDate();
    orders.forEach((order) => {
        if (moment(order.endDate).startOf('day').utc().toDate() > now) {
            availableEnrollments += order.balance;
        }
    });
    return availableEnrollments;
}

export function calculateEnrollmentsExpiringSoon(orders: any): number {
    let enrollmentsExpiringSoon = 0;
    const currentDay = moment(new Date()).startOf('day').utc();
    const now = currentDay.toDate();
    const nowPlusTwoWeeks = currentDay.add(14, 'day').toDate();
    orders.forEach((order) => {
        if (moment(order.endDate).startOf('day').utc().isBetween(now, nowPlusTwoWeeks))
        {
                enrollmentsExpiringSoon += order.balance;
        }
    });
    return enrollmentsExpiringSoon;
}

export function calculateTrainingRecordCompletedOnDate(status: string, completedOn: any) {
    if (!isNullOrEmpty(status) && status !== 'Assigned') {
        return isNullOrEmptyDate(completedOn) ? new Date() : completedOn;
    }
    if ((isNullOrEmpty(status) || status === 'Assigned') && completedOn !== null) {
        return null;
    }
}

export function calculateTrainingClassroomEventCompletedOnDate(records: any[], event: any) {
    if (records.length === 0 || records.find((r) => r.status == null || r.status === 'Assigned')) {
        // If there is an incomplete status or there are no records, nullify the date
        return null;
    } else {
        // If all the statuses are complete, and there isn't already a date set, set the date
        if (event.completedOn) {
            return event.completedOn;
        }
        return new Date();
    }
}

export function calculateTrainingClassroomEventStatus(records: any[], event: any) {
    if (records.length === 0 || records.find((r) => r.status == null || r.status === 'Assigned')) {
        if (records.find(x => x.status !== null && x.status !== 'Assigned')) {
            // Return In Progress because some are complete and some aren't
            return TrainingEventStatus.InProgress;
        }

        // Return past due only for ToDo events with an event date in the past
        const eDate = new Date(event.eventDate);
        const today = new Date();
        today.setHours(0,0,0,0);
        if (eDate < today) {
            return TrainingEventStatus.PastDue;
        }

        // Return To Do because no records are complete and not past due
        return TrainingEventStatus.ToDo;
    } else {
        // this only gets hit if there are more than 0 records and they're all complete
        return TrainingEventStatus.Complete;
    }
}

export function buildEventRequest(event: any) {
    const request = new ClassroomTrainingEvent();
    request.Id = event.id;
    request.UserTrainingProgramId = event.userTrainingProgramId;
    request.InternalTrainingProgramId = event.internalTrainingProgramId;
    request.Title = event.title;
    request.Group = event.group;
    request.Mandatory = event.mandatory;
    request.EventDate = event.eventDate;
    request.ProgramFrequency = event.programFrequency;
    request.Duration = event.duration;
    request.LocationIds = event.locationIds;
    request.FacilitatorIds = event.facilitatorIds;
    request.Reminders = event.reminders;
    request.Description = event.description;
    request.ThresholdScore = event.thresholdScore;
    request.AttendeeIds = event.attendeeIds;
    request.Status = event.status;
    request.CompletedOn = event.completedOn;
    // request.WorkAreaIds = this.selectedWorkArea ? [this.selectedWorkArea] : [];
    // request.JobFunctionIds = this.selectedJobFunctions;
    return request;
}

export function keyPress(event: any) {
    // eslint-disable-next-line no-useless-escape
    const pattern = /[0-9\+\-\+\/ ]/;
    const inputChar = String.fromCharCode(Number(event.key));

    if ((event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'UpArrow' &&
        event.key !== 'RightArrow' && event.key !== 'DownArrow' &&
        !pattern.test(event.key) && !event.metaKey && !event.ctrlKey)) {
        event.preventDefault();
    }
}

export function keyDown(event: any) {
    if (event.key === 'Backspace') {
        $(event.target).trigger('click');
    }
}

export function getPropertyIfExists(theObject, property): any {
    if (theObject && property) {
        if (!isNullOrUndefined(theObject[property])) {
            return theObject[property];
        }
    }
    return '';
}

/**
 * Search for single element and remove it from Array
 * @param arr Array
 * @param value Array element to remove
 */
 export function removeFromArray(arr: any[], value) {
    const indexOfObject = arr.findIndex((object) => {
        return object === value;
      });
      if (indexOfObject !== -1) {
        arr.splice(indexOfObject, 1);
      }
      return arr;
}

/**
 * Maps Enum to select options for our jjk-select component
 * @param e Enum
 */
export function mapEnumToSelectOptions(e: any) {
    return Object.keys(e).map((s) => ({'id': e[s], 'displayText': e[s] }));
}
