import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'jjk-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent implements OnInit {

  @Input() marginLeft: string;
  @Input() marginTop: string;
  @Input() marginBottom: string;
  @Input() loadingText = '';

  constructor() {
  }

  ngOnInit(): void { }
}
