<div class="indexComponent flex-column" *ngIf="componentLoaded && indexComponent" >
    <div cdkDropList class="drag-and-drop-index-list" (cdkDropListDropped)="sectionDrop($event)">

        <div class="section-box">
            <!-- Add Another Item Button -->
            <div *ngIf="indexComponent.addButton" class="container-addButton">
                <button class="btn-sm" (click)="addSection()">
                    {{ indexComponent.addButton }}
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
            </div>

            <div class="Title">{{ indexComponent.displayText }}</div>
        </div>

        <div class="standard-box" *ngFor="let section of componentDataSource; let i = index" cdkDrag>
            <div class="drag-handle" cdkDragHandle>
                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
            </div>

            <div class="custom-box-placeholder" *cdkDragPlaceholder></div>
            <a [routerLink]="[]" [fragment]="generateAttrID(section, indexComponent.columnName)">
                <div class="Title">{{ getRowValue(section, indexComponent.columnName) }}</div>
            </a>

        </div>

    </div>
</div>

<div class="componentContainer d-xl-flex" *ngIf="componentLoaded">
    <div class="div-overlay" *ngIf="indexComponent_Active"></div>
    <div class="mainComponent flex-column" cdkDropListGroup>

        <ng-container *ngFor="let section of componentDataSource; let i = index">

            <div cdkDropList [cdkDropListData]="section" class="drag-and-drop-list" (cdkDropListDropped)="rowDrop($event)">

                <div class="section-box" cdkDragLockAxis="y" cdkDrag [cdkDragDisabled]="true" [attr.id]="generateAttrID(section, indexComponent.columnName)" >

                    <ng-container #sectionRow>
                        <div class="sectionRow">

                            <!-- Add Another Item Button -->
                            <div *ngIf="sectionComponent.addButton" class="container-addButton">
                                <button class="btn-sm" (click)="addStandardRow(section)">
                                    {{ sectionComponent.addButton }}
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </div>

                            <!-- Section Title -->
                            <div class="Title">
                                <span [innerHTML]="getRowValue(section, sectionComponent.title)"></span>
                            </div>

                            <!-- Section Action Items -->
                            <div class="container-SectionItems">
                                <ul class="actions">
                                    <ng-container *ngFor="let actionItem of shouldDisplayAction(section, sectionComponent.sectionActionItems)">
                                        <li>
                                            <jjk-drag-and-drop-action-item [displayText]="actionItem.displayText"
                                                [component]="actionItem" [row]="section"
                                                (action)="onDragAndDropComponent($event)"></jjk-drag-and-drop-action-item>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="standard-box" *ngFor="let item of getStandardRowCollection(section)" cdkDragLockAxis="y" cdkDrag
                    [cdkDragDisabled]="false">

                    <div class="custom-box-placeholder" *cdkDragPlaceholder></div>

                    <ng-container #standardRow>
                        <div class="standardRow">

                            <!-- Drag Handle-->
                            <div class="drag-handle" cdkDragHandle>
                                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                      <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                                      <path d="M0 0h24v24H0z" fill="none"></path>
                                    </svg>
                            </div>

                            <!-- Header -->
                            <div *ngIf="standardRowComponent.header" class="standardRowHeader">
                                <span [innerHTML]="getRowValue(item, standardRowComponent.header)"></span>
                            </div>

                            <!-- Title -->
                            <div *ngIf="standardRowComponent.title" class="Title">
                                <span [innerHTML]="getRowValue(item, standardRowComponent.title)"></span>
                            </div>

                            <!-- Content -->
                            <div *ngIf="standardRowComponent.content" class="Content">
                                <span [innerHTML]="getRowValue(item, standardRowComponent.content)"></span>
                            </div>

                            <!-- Action Items -->
                            <div *ngIf="standardRowComponent.actionItems" class="container-SectionItems">
                                <ul class="actions">
                                    <ng-container *ngFor="let actionItem of shouldDisplayAction(item, standardRowComponent.actionItems); let iLink = index; let isFirst = first">
                                        <ng-container *ngIf="iLink < visibleActions; then normalAction; else additionalActions">
                                        </ng-container>

                                        <ng-template #normalAction>
                                            <li>
                                                <jjk-drag-and-drop-action-item [displayText]="actionItem.displayText"
                                                    [component]="actionItem" [container]="section" [row]="item"
                                                    (action)="onDragAndDropComponent($event)">
                                                </jjk-drag-and-drop-action-item>
                                            </li>
                                          </ng-template>

                                          <ng-template #additionalActions>
                                            <span id="ellipsis">
                                              <ng-container *ngIf="iLink == visibleActions">
                                                <a role="button" id="moreActions" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true" data-bs-target="ellipsis">
                                                    <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                </a>

                                                <div class="dropdown-menu" aria-labelledby="moreActions">
                                                    <div *ngFor="let actionItem of shouldDisplayAction(item, standardRowComponent.actionItems); let sublink = index">
                                                        <jjk-drag-and-drop-action-item  *ngIf="sublink >= visibleActions" [ngClass]="{'ellipsis-action-item':true}"
                                                        [displayText]="actionItem.displayText"  [component]="actionItem" [container]="section" [row]="item"
                                                        (action)="onDragAndDropComponent($event)">
                                                    </jjk-drag-and-drop-action-item>
                                                    </div>
                                                  </div>
                                              </ng-container>
                                            </span>
                                          </ng-template>
                                    </ng-container>
                                </ul>
                            </div>

                            <!-- Footer -->
                            <div *ngIf="standardRowComponent.footer" class="standardRowFooter">
                                <span [innerHTML]="getRowValue(item, standardRowComponent.footer)"></span>
                            </div>

                            <div *ngIf="standardRowComponent.showFileInfo">
                                <a *ngIf="item.fileType" data-bs-toggle="tooltip" target="_blank" data-bs-placement="bottom" title="" download (click)="download({ row: item })" [attr.data-bs-original-title]="!standardRowComponent.fileDownloadable || isFileDownloadable(item) ? 'Download' : ''" [ngClass]="!standardRowComponent.fileDownloadable || isFileDownloadable(item) ? 'loading' : 'noDownload'">

                                  <ng-container [ngSwitch]="item.fileType.toLowerCase()">
                                    <i *ngSwitchCase="'application/pdf'" class="fas fa-file-pdf file-type"></i>
                                    <i *ngSwitchCase="'application/rtf'" class="fas fa-file-word file-type"></i>
                                    <i *ngSwitchCase="'application/msword'" class="fas fa-file-word file-type"></i>
                                    <i *ngSwitchCase="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" class="fas fa-file-word file-type"></i>
                                    <i *ngSwitchCase="'application/vnd.ms-excel'" class="fas fa-file-excel file-type"></i>
                                    <i *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" class="fas fa-file-excel file-type"></i>
                                    <i *ngSwitchCase="'text/tab-separated-values'" class="fas fa-file-excel file-type"></i>
                                    <i *ngSwitchCase="'image/gif'" class="fas fa-file-image file-type"></i>
                                    <i *ngSwitchCase="'image/jpeg'" class="fas fa-file-image file-type"></i>
                                    <i *ngSwitchCase="'image/png'" class="fas fa-file-image file-type"></i>
                                    <i *ngSwitchCase="'text/csv'" class="fas fa-file-spreadsheet file-type"></i>
                                    <i *ngSwitchCase="'application/zip'" class="fas fa-file-archive file-type"></i>
                                    <i *ngSwitchCase="'application/vnd.ms-powerpoint'" class="fas fa-file-powerpoint file-type"></i>
                                    <i *ngSwitchCase="'application/vnd.openxmlformats-officedocument.presentationml.presentation'" class="fas fa-file-powerpoint file-type"></i>
                                    <i *ngSwitchCase="'text/plain'" class="fas fa-file file-type"></i>
                                    <i *ngSwitchCase="'video/mp4'" class="fad fa-video"></i>
                                    <i *ngSwitchDefault class="fas fa-file-text file-type"></i>

                                  </ng-container>
                                  <i *ngIf="!standardRowComponent.fileDownloadable || isFileDownloadable(item)" style="padding-left: 5px;" class="fa fa-download"></i>
                                  <br/>
                                  <span *ngIf="!standardRowComponent.fileDownloadable || isFileDownloadable(item)" class="file-size-inline">{{getFileSizeText(item.fileSize)}}</span>
                                </a>
                                <jjk-loader *ngIf="getRowValue(item, standardRowComponent.fileDownloading)" [marginLeft]="'10px'" [loadingText]="'Downloading...'"></jjk-loader>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>

        </ng-container>
    </div>

</div>