import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiActionsEnum } from '@constants/enums/api-actions.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ACTIONS, DATAKEYS } from '@constants/messages.constants';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { JJKellerApiResponse } from '@models/api/JJKellerApiResponse.model';
import { AutoIncrementingIdentifierDirective } from '@modules/messaging/baseClasses/AutoIncrementingIdentifier';
import { IMsgType, OutboundMessageBuilder } from '@modules/messaging/baseClasses/OutboundMessageBuilder';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { ApiMessage } from '@modules/messaging/types/Messages/apiMessage';
import { CacheInvalidationEngine } from '@services/core/CacheInvalidationEngine.service';
import { ApiPostProcessHandler } from './ApiPostProcessHandler';
import { EndpointDataCacheEntry } from './EndpointDataCacheEntry';
import { SimpleHttpHeadersClass } from './SimpleHttpHeadersClass';

@Injectable()
export class HttpClientWithCache extends AutoIncrementingIdentifierDirective {
    private static cache: EndpointDataCacheEntry[] = new Array<EndpointDataCacheEntry>();

    constructor(private httpClient: HttpClient, private cacheEngine: CacheInvalidationEngine, private router: Router) {
        super();
    }

    public get(endpoint: string, msg: ApiMessage, postProcessor: ApiPostProcessHandler): void {

        const isFileDownload = endpoint.indexOf('/Download') !== -1;
        if (isFileDownload) {
            this.fileDownload(endpoint, msg, postProcessor);
            return;
        }

        const ourHeaders = this.buildHeaderBlock(msg.additionalHeaders, true);
        ourHeaders.set('EndpointKey', msg.apiEntityType);
        if (msg.skipClientSideCache) {
            ourHeaders.set('SkipClientSideCache', '1');
        }
        this.httpClient.get(endpoint, { headers: ourHeaders }).subscribe(
            (data: JJKellerApiResponse) => {
                const returnData = msg.shouldReturnFullResponse ? data : data.successData;
                msg.successHandler(postProcessor ? postProcessor(returnData, ApiActionsEnum.RETRIEVE) : returnData);
            },
            (errorData) => {
                if (this.logoutIf401(errorData)) {
                    return;
                }
                this.getErrorHandler(errorData, msg, false);
            },
        );
    }

    public put(endpoint: string, msg: ApiMessage, postProcessor: ApiPostProcessHandler): void {
        // If we are updating the information, then we clearly have invalidated the cache
        this.purgeEndpoint(msg);

        const isFileDownload = endpoint.indexOf('/Download') !== -1;
        if (isFileDownload) {
            this.put_fileDownload(endpoint, msg, postProcessor);
            return;
        }

        const ourHeaders = this.buildHeaderBlock(msg.additionalHeaders);
        ourHeaders.set('EndpointKey', msg.apiEntityType);

        const msgData = msg.messageData[DATAKEYS.HTTP_KEYS.BODY];

        if (msgData instanceof FormData) {
            ourHeaders.delete('Content-Type');
        }

        (msgData instanceof FormData
            ? this.httpClient.put(endpoint, msgData, {
                headers: ourHeaders,
            })
            : this.httpClient.put(endpoint, JSON.stringify(msgData), {
                headers: ourHeaders,
            }))
            .subscribe(
                (data: JJKellerApiResponse) => {
                    msg.successHandler(postProcessor ? postProcessor(data.successData, ApiActionsEnum.UPDATE) : data.successData);
                },
                (error) => {
                    if (!(msg.apiEntityType == ApiEntityTypesEnum.Security && (endpoint.indexOf('/ResetPassword') || endpoint.indexOf('/ForgotPassword'))) && this.logoutIf401(error)) {
                        return;
                    }
                    this.msgErrorHandler(msg, error);
                },
            );
    }

    public post(endpoint: string, msg: ApiMessage, postProcessor: ApiPostProcessHandler): void {
        // If we are updating the information, then we clearly have invalidated the cache
        this.purgeEndpoint(msg);

        const ourHeaders = this.buildHeaderBlock(msg.additionalHeaders);
        ourHeaders.set('EndpointKey', msg.apiEntityType);

        const msgData = msg.messageData[DATAKEYS.HTTP_KEYS.BODY];
        if (msgData instanceof FormData) {
            ourHeaders.delete('Content-Type');
        }

        (msgData instanceof FormData
            ? this.httpClient.post(endpoint, msgData, {
                headers: ourHeaders,
            })
            : this.httpClient.post(endpoint, JSON.stringify(msgData), {
                headers: ourHeaders,
            }))
            .subscribe(
                (data: JJKellerApiResponse) => {
                    msg.successHandler(postProcessor ? postProcessor(data.successData, ApiActionsEnum.CREATE) : data.successData);
                },
                (error) => {
                    if (this.logoutIf401(error)) {
                        return;
                    }
                    this.msgErrorHandler(msg, error);
                },
            );
    }

    public delete(endpoint: string, msg: ApiMessage, postProcessor: ApiPostProcessHandler): void {
        // If we are updating the information, then we clearly have invalidated the cache
        this.purgeEndpoint(msg);

        const ourHeaders = this.buildHeaderBlock(msg.additionalHeaders);
        ourHeaders.set('EndpointKey', msg.apiEntityType);

        this.httpClient.delete(endpoint, {
            headers: ourHeaders,
        }).subscribe(
            (data: JJKellerApiResponse) => {
                msg.successHandler(postProcessor ? postProcessor(data.successData, ApiActionsEnum.DELETE) : data.successData);
            },
            (error) => {
                if (this.logoutIf401(error)) {
                    return;
                }
                this.msgErrorHandler(msg, error);
            },
        );
    }

    private purgeEndpoint(msg: ApiMessage) {
        const additionalEntityEndpointsToPurge = this.cacheEngine.extractInvalidatedEntityGraph(msg.apiEntityType);

        additionalEntityEndpointsToPurge.forEach((e) => {
            HttpClientWithCache.cache.forEach((k) => {
                if (k.apiEntityType === e) {
                    k.destroy();

                    const index = HttpClientWithCache.cache.indexOf(k);
                    HttpClientWithCache.cache.splice(index, 1);
                }
            });
        });
    }

    /**
     * This method will take any headers included in the ApiMessage and
     * append the headers needed for the API call to succeed.
     * @param currentHeaders
     */
    private buildHeaderBlock(currentHeaders: SimpleHttpHeadersClass, addNoCache = false): SimpleHttpHeadersClass {
        const userInfoInstance = LoggedInUserInfo.Instance.clientUserInfo;
        if (userInfoInstance.accessToken) {
            currentHeaders.set('Authorization', 'Bearer ' + userInfoInstance?.accessToken);
        }
        currentHeaders.set('X-ClientUserInfo', userInfoInstance.toString());
        currentHeaders.set('Content-Type', 'application/json; charset=utf-8');
        if (addNoCache) {
            currentHeaders.set('Cache-Control', 'no-cache, no-store, must-revalidate'); // Cache control needs to be disabled with IE/Edge browsers
            currentHeaders.set('Pragma', 'no-cache');
            currentHeaders.set('Expires', '0');
        }
        return currentHeaders;
    }

    private msgErrorHandler(msg: ApiMessage, error: any) {

        if (msg.errorHandler) {
            if (error instanceof HttpErrorResponse) {
                const jjkApiResponse = new JJKellerApiResponse().HttpErrorResponse_Mapper(error);
                msg.errorHandler(jjkApiResponse);
            } else {
                msg.errorHandler(error as JJKellerApiResponse);
            }
        }
    }

    private getErrorHandler(errorData: any, msg: ApiMessage, isFileDownload: boolean) {
        if (msg.errorHandler) {
            let apiError = new JJKellerApiResponse();
            if (msg.isRequestRaw || isFileDownload) {
                apiError.addError(errorData);
            } else {
                if (errorData instanceof HttpErrorResponse) {
                    apiError = new JJKellerApiResponse().HttpErrorResponse_Mapper(errorData);
                } else {
                    apiError.addError((errorData === true && errorData.error === true) ? errorData.error : errorData);
                }
            }
            msg.errorHandler(apiError);
        }
    }

    private fileDownload(endpoint: string, msg: ApiMessage, postProcessor: ApiPostProcessHandler) {
        const ourHeaders = this.buildHeaderBlock(msg.additionalHeaders, true);
        this.httpClient.get(endpoint, { headers: ourHeaders, observe: 'response', responseType: 'blob' })
            .subscribe((data: HttpResponse<Blob>) => {
                msg.successHandler(postProcessor ? postProcessor(data, ApiActionsEnum.RETRIEVE) : data);
            }, (errorData) => {
                this.getErrorHandler(errorData, msg, true);
            });
    }

    logoutIf401(errorData): boolean {
        if (errorData instanceof HttpErrorResponse) {
            if (errorData.status === 401) {
                if (errorData?.error?.errorData[0]?.errorMessage === "You Shall Not Pass!" || !LoggedInUserInfo.Instance.isAuthorized) {
                    const msgBuilder = new OutboundMessageBuilder(this.id) as IMsgType;
                    const msg = msgBuilder.SystemEventMessage().usingHostSenderId().then.proceedWithoutData.assignAction(ACTIONS.AUTH.AUTH_REQUEST_LOGOUT).sendOn.systemTopic().build();
                    const msgBus = MessageBusService.AppInjector.get(MessageBusService);
                    msgBus.send(msg);
                    return true;
                }
                else {
                    this.router.navigate(['/dashboard']);
                }
            }
        }
        return false;
    }

    // -----------------------------------------------------------------
    private put_fileDownload(endpoint: string, msg: ApiMessage, postProcessor: ApiPostProcessHandler) {

        const ourHeaders = this.buildHeaderBlock(msg.additionalHeaders);

        const msgData = msg.messageData[DATAKEYS.HTTP_KEYS.BODY];

        if (msgData instanceof FormData) {
            ourHeaders.delete('Content-Type');
        }

        this.httpClient.put(endpoint, msgData, { headers: ourHeaders, observe: 'response', responseType: 'blob' })
            .subscribe((data: HttpResponse<Blob>) => {
                msg.successHandler(postProcessor ? postProcessor(data, ApiActionsEnum.UPDATE) : data);
            }, (errorData) => {
                this.getErrorHandler(errorData, msg, true);
            });
    }
    // -----------------------------------------------------------------
}
