import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'jjk-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent {

    @Input() public displayText = 'No Records Exist - Add New';
    @Input() public showAddButton = true;

    @Output() action = new EventEmitter();

    onAddNewClick(event: any) {
        this.action.emit(event);
        event.preventDefault();
    }
}
