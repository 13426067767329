import { HttpHeaders } from '@angular/common/http';

/**
 * Angular has built filters into the HttpHeaders class to automatically
 * and silently purge "unsafe", ie, user defined headers.  This affects our
 * ability to pass data to the API in headers.  As a solution, this class trusts
 * the developer to understand the limits and use of Headers, and will allow such
 * "untrusted" headers to be included in the XMLHttpRequest header collection.
 * This is a "simple" header class because it does not support headers with collections
 * for children.  Therefore Headers are simple Key Value Pairs of <strin>:<string>.
 */
export class SimpleHttpHeadersClass extends HttpHeaders {
    constructor() {
        super();
    }
    private _headers = new Map<string, string>();

    has = (name: string) => this._headers.has(name);

    get = (name: string): string => this._headers.get(name);

    keys = (): string[] => Object.keys(this._headers.keys);

    getAll = (name: string): string[] => [].concat(this._headers.get(name));

    append(name: string, value: string | string[]): HttpHeaders {
        throw new Error('Method not implemented.');
    }

    set = (name: string, value: string | string[]): HttpHeaders => {
        this._headers.set(name, value as string);
        return this as unknown as HttpHeaders;
    }

    delete = (name: string, value?: string | string[]): HttpHeaders => {
        this._headers.delete(name);
        return this as unknown as HttpHeaders;
    }

    forEach = (fn: (name: string, values: string[]) => void) => {
        this._headers.forEach((v, k) => fn(k, [].concat(v)));
    }
}
