import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AssociatedType } from '@constants/enums/associated-type.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { FileSize } from '@constants/filesize.constants';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { AttachmentFile } from '@models/attachments-file.model';
import { Employee } from '@models/entity-models/autogenerated/employee';
import { MedicalTest } from '@models/entity-models/autogenerated/medicaltest';
import { MedicalTestResult } from '@models/entity-models/autogenerated/medicaltestresult';
import { AttachmentService } from '@services/attachment.service';
import { ApiFactory } from '@services/core/api-factory.class';
import { SplitioService } from '@services/splitio.service';
import { isNullOrUndefined, sortByProperty } from '@utilities/helpers';
import { ValidateFileExtension } from '@utilities/validators/file-extension.validator';
import { ValidateFileSize } from '@utilities/validators/file-size.validator';

@Component({
  selector: 'medical-test-modal-old',
  templateUrl: './medical-test-modal-old.component.html',
  styleUrls: ['./medical-test-modal-old.component.scss'],
})
export class MedicalTestModalOldComponent implements OnInit, OnChanges {

  // Input Employee for Add, and the medicalTest Object for Edit
  @Input() employee: Employee;
  @Input() medicalTestObject: MedicalTest;

  @Output() updatedMedical = new EventEmitter();
  @Output() clearMedical = new EventEmitter();
  @Input() isOpen;
  title: string;
  modalId = 'MedicalTestEditModal';
  editForm: UntypedFormGroup;
  showErrors: boolean;
  resultOptions: any[] = [];
  editMode: boolean;
  processing = false;
  showAlert = false;
  fileScanToggle = false;
  attachmentError = 'An error occurred uploading the file, please try again later.';

  attachmentFile: AttachmentFile;
  fileUpload = null;
  maxFileSize = FileSize.maxFileSize;
  associatedTypeId = AssociatedType.MedicalTest;
  maxDate = moment();

  private _selectedResult = null;
  get selectedResult() {
      return this._selectedResult;
  }
  set selectedResult(val) {
      this._selectedResult = val;
      this.editForm.patchValue({ result: this._selectedResult });
  }

  constructor(
    private formBuilder: UntypedFormBuilder, private splitioService: SplitioService, private attachmentService: AttachmentService,
    ) {
  }

  async ngOnInit() {
    this.fileScanToggle = (await this.splitioService.getToggle(TOGGLES.FILE_SCANNING_97762)) === TREATMENT.ON;
    this.getResultOptions();
    this.attachmentFile = new AttachmentFile();
    this.editForm = this.formBuilder.group({
      result: ['', Validators.required],
      date: ['', Validators.required],
      fileInputName: ['', [Validators.required, ValidateFileExtension]],
      fileInputSize: [0, ValidateFileSize],
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.medicalTestObject && changes.medicalTestObject.currentValue != undefined) {
      this.setUpEditPage();
    } else {
      this.setUpAddPage();
    }
  }

  setUpAddPage() {
    this.editMode = false;
    this.title = `Add Test Results for ${this.employee.lastName}, ${this.employee.firstName}`;
  }

  async setUpEditPage() {
    this.isOpen = true;
    this.editMode = true;
    this.title = `Edit Test Results for ${this.employee.lastName}, ${this.employee.firstName}`;
    this.selectedResult = this.medicalTestObject.medicalTestResultId;
    this.editForm.patchValue({ date: this.medicalTestObject.testDate });
    const response = await this.attachmentService.getAttachment(this.medicalTestObject.id, this.associatedTypeId);
    this.attachmentFile = Array.isArray(response) && response.length ? this.attachmentFile = response[0] : new AttachmentFile();
    this.bindFileInputs(this.attachmentFile?.fileName, this.attachmentFile?.fileSize);
  }

  getResultOptions() {
    ApiFactory.retrieveEntity(ApiEntityTypesEnum.MedicalTestResult)
    .addSuccessHandler((data: MedicalTestResult[]) => {
      sortByProperty<MedicalTestResult>(data, (d) => d.value, false);
      this.resultOptions = data.map((element) => ({
        id: element.id,
        displayText: element.name
        }));
      })
      .buildAndSend();
  }

  onSave() {
      if (this.editForm.valid) {
        this.processing = true;
        if (this.editMode) {
          const editedMedicalTest = {
            ...this.medicalTestObject,
            modifiedByUserId: LoggedInUserInfo.Instance.userInfo.user.id,
            id: this.medicalTestObject.id,
            medicalTestResultId: this.selectedResult,
            testDate: this.editForm.controls['date'].value,
          };
          ApiFactory.updateEntity(ApiEntityTypesEnum.MedicalTest, editedMedicalTest)
            .addSuccessHandler((response: any) => {
              this.updateAssociatedAttachment();
            })
            .addErrorHandler(() => {})
            .buildAndSend();
        } else {
          const x = {
            createdByUser: LoggedInUserInfo.Instance.userInfo.user,
            employeeId: this.employee.id,
            isDeleted: false,
            medicalTestResultId: this.selectedResult,
            testDate: this.editForm.controls['date'].value,
          };
          ApiFactory.saveNewEntity(ApiEntityTypesEnum.MedicalTest, x)
            .addSuccessHandler((response: any) => {
              this.medicalTestObject = response;
              this.saveAttachmentAndUpdateGrid(response);
            })
            .addErrorHandler(() => {})
            .buildAndSend();
        }
      } else {
          this.processing = false;
          this.showErrors = true;
          this.editForm.markAllAsTouched();
      }
  }

  async saveAttachmentAndUpdateGrid(newItem) {

    if (!isNullOrUndefined(this.fileUpload)) await this.saveNewAttachment(newItem);
    if (this.fileScanToggle && this.showAlert) {
      this.editMode = true;
      this.processing = false;
      return;
    }
    this.updatedMedical.emit({ result: newItem });
    this.closeWindow();
  }

  async saveNewAttachment(newItem): Promise<AttachmentFile> {
    const formData = this.buildNewAttachmentData(newItem);

    if (this.fileScanToggle) {
      const response = await this.attachmentService.createAttachment(formData)
      .catch((error) => {
        this.attachmentError = error;
        this.showAlert = true;
        return null;
      });
      return response;
    } else {
      return await this.attachmentService.createAttachment(formData);
    }

  }

  dismissAlert() {
    this.showAlert = false;
  }

  async updateAssociatedAttachment() {
    if (!isNullOrUndefined(this.fileUpload)) {
      if (!isNullOrUndefined(this.attachmentFile.id)) {
        await this.attachmentService.deleteAttachment(this.attachmentFile.id, this.attachmentFile.attachmentAssociationId);
      }
      await this.saveNewAttachment(this.medicalTestObject);
    }
    this.updatedMedical.emit({ result: this.medicalTestObject });
    this.closeWindow();
  }

  private buildNewAttachmentData(newItem): FormData {
    const data = new FormData();
    data.append('id', undefined);
    data.append('companyId', this.attachmentFile.companyId);
    data.append('associatedId', newItem.id);
    data.append('associatedTypeId', this.associatedTypeId);
    data.append('file', this.attachmentFile.file);
    data.append('fileName', this.attachmentFile.fileName);
    data.append('description', this.attachmentFile.description);
    data.append('isDeleted', 'false');
    data.append('createdByUserId', this.attachmentFile.createdByUserId);

    return data;
  }

  onFileChange($event) {
    this.showErrors = false;
    this.dismissAlert();
    this.fileUpload = $event?.file || null;
    this.bindFileInputs(this.fileUpload?.name, this.fileUpload?.size);
  }

  bindFileInputs(name?, size?) {
    this.editForm.controls['fileInputName'].setValue(name || '');
    this.editForm.controls['fileInputSize'].setValue(size || '');
  }

  clearAllAttachmentsFromForm() {
    this.attachmentFile = new AttachmentFile();
    this.fileUpload = null;
  }

  clearForm () {
    this.clearAllAttachmentsFromForm();
    this.isOpen = false;
    this.processing = false;
    this.editForm.reset();
    this.showErrors = false;
    this.dismissAlert();
    this.selectedResult = null;
  }

  closeWindow() {
    this.clearForm();
    this.clearMedical.emit();
    this.attachmentFile = new AttachmentFile();
    ($(`#${this.modalId}`) as any).modal('hide');
  }

  openWindow() {
    this.isOpen = true;
    ($(`#${this.modalId}`) as any).modal('show');

    $(`#${this.modalId}`).on('shown.bs.modal', function() {
        setTimeout(() => {
            $('#result', this).trigger('focus');
        }, 150);
    });

  }
}
