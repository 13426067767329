import * as moment from 'moment';
import { IncidentRateChartModel } from '@models/reporting/charts/incident-rate/rate-chart.model';
import { RateDataModel } from '@models/reporting/charts/incident-rate/rate-data.model';

export function mapIncidentRatesToLineChart(x) {
    if (x) {
        const model = new IncidentRateChartModel() ;
        model.startDate = x.startDate;
        model.endDate = x.endDate;
        model.data = monthlyLocationsToModel(x.monthlyLocationData);
        return model;
    }
  }

export function monthlyLocationsToModel(monthlyLocations) {
    return monthlyLocations
    .map((x) => ({
        location: x.locationName,
        date: x.month,
        totalHours: x.totalHoursWorked,
        incidentCount: x.incidentCount,
        rate: x.monthlyRate,
    }));
  }

export function getUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

export function mapIncidentRatesToDataList(rates: any[]) {
    const locations = new Array<any>();
    const locationNames = rates.map((r) => r.location).filter(getUnique);
    locationNames.forEach((location) => {
        const loc = {
            name: location,
            series: mapToSeriesData(rates.filter((r) => r.location === location)),
        };
        locations.push(loc);

    });
    return locations;
}

export function mapToSeriesData(rates: RateDataModel[]) {
      return rates
        .map((x) => ({
            name: moment(x.date).format('MM/YYYY'),
            value: x.rate,
            count: x.incidentCount,
            hours: x.totalHours,
            date: x.date,
        }));
}

export function axisFormatWholeNumber(val) {
    if (val % 1 === 0) {
        return val;
    } else {
        return '';
    }
}
