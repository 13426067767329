import { Component, Input } from '@angular/core';

@Component({
  selector: 'jjk-datagrid-column-custom-action',
  template: ``,
})
export class DataGridColumnCustomActionComponent {
  componentType: string;
  @Input() shouldDisplay: any;

  constructor() {
  }
}
