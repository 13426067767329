<span>
    <span>{{ cellValue }}</span>
    &nbsp;
    <span *ngIf="cellValue === 'Yes' || cellValue === 'Yes - Ended'" class="fa-stack small">
        <i class="fa-solid fa-circle fa-stack-2x"></i>
        <a href="{{'audits/audit_schedule/edit/' + templateId}}">
            <i class="fa-sharp fa-regular fa-arrows-repeat fa-stack-1x fa-inverse"
            jjk-bootstrap-popover [content]="'Edit Schedule'" [trigger]="'hover'"></i>
        </a>
      </span>

</span>
