import { Component } from '@angular/core';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { MessageButtonBaseComponentDirective } from './message-button-base.component';

@Component({
    selector: 'jjk-system-message-button',
    templateUrl: './system-message-button.component.html',
    styleUrls: ['./system-message-button.component.scss'],
})
export class SystemMessageButtonComponent extends MessageButtonBaseComponentDirective {
    protected sendMessage(data: any): void {
        const msg = this.msgBuilder
        .SystemEventMessage()
        .usingHostSenderId()
        .then.proceedWithoutData.assignAction(this.action)
        .sendOn.systemTopic()
        .build();
        this.sendTopicMessage(msg);
    }

    constructor(mb: MessageBusService) {
        super(mb);
    }
}
