import { Pipe, PipeTransform } from '@angular/core';
import { IDynamicSelectOptions } from '@models/dynamic-form-models/dynamic-select-control-options.model';
import { loadRoleData } from '@pages/settings/settings-helpers';

@Pipe({ name: 'loadRoleData' })
export class LoadRoleDataPipe implements PipeTransform {
    transform(value: number, isSapAdmin?: boolean): IDynamicSelectOptions[] {
        return loadRoleData(value, isSapAdmin);
    }
}
