<body class="jjk-login">
    <div class="main-content">
        <jjkp-enter-email *ngIf="showEmail" [header]="'Reset Your Password'"
            (successResponse)="openVerificationCode($event)" (failedResponse)="setAlert($event)" (setAlert)="setAlert($event)" (clearAlert)="clearAlert()">
        </jjkp-enter-email>
        <div *ngIf="isEmailSent && !this.showAlert && !this.showPasswordReset" class="message-block info">
            <p>An e-mail has been sent to the address you provided with a verification code to proceed.</p>
        </div>
        <br>
        <div *ngIf="this.showAlert" class="message-block danger">
            <p id="alertParagraph">{{this.alertText}}</p>
        </div>
        <jjkp-verification-code *ngIf="showVerificationCode || (showVerificationCode && hidePasswordForm)"
            [verificationCode]="verificationCode" 
            [header]="'Reset Your Password'" 
            [emailAddress]="email"
            [resendText]="resendText" 
            [expiredTokenReset]="expiredTokenReset"
            (successResponse)="openCreateNewPassword($event)" 
            (failedResponse)="setAlert($event)"
            (resendClicked)="resendVerificationCode()"
            (verificationCodeEvt)="setVerificationCode($event)">
        </jjkp-verification-code>
        <jjkp-new-password *ngIf="showPasswordReset && !hidePasswordForm" [emailAddress]="emailAddress" [b2c2OktaToggle]="b2c2OktaToggle"
            (success)="passwordSuccessfullyUpdated()"
            (passwordFailure)="setAlert($event)"
            (resendClicked)="hideErrorLabel()">
        </jjkp-new-password>
    </div>
</body>