<ng-container>
  <div class="ng-dropdown form-control" [ngClass]="{'disabled' : disabled}">
    <ul class="ng-dropdown-container" tabindex="0" [ngClass]="{'ng-dropdown-opened':isOpened}" #dropdownMenu>
      <li *ngIf="filter">
        <input #dropdownFilter class="form-control" placeholder="Search" (keydown)="onFilterSearch($event)" (blur)="blurDropdown()" autofocus />
      </li>
      <ul class="ng-dropdown-container-option" [ngStyle]="isOpened ? treeContainerStyle: {}">
        <ng-container *ngIf="treeData else noResults">
          <div class="ng-dropdown-tree">
            <jjk-tree id="apptree" class="apptree" *ngIf="treeData" [treeData]="treeData" [(selectedOption)]="selectedOption" (selectedOptionChange)="onSelectChange($event)"></jjk-tree>
          </div>
        </ng-container>
        <ng-template #noResults>
          <div class="text-center">
            <a>No Results Found</a>
          </div>
        </ng-template>
      </ul>
    </ul>

    <div *ngIf="!clearAll" class="ng-dropdown-button" (click)="onDropdownMenuClick($event)" #dropdownButton>
      <span>{{selectedText}}</span>
      <i class="fas fa-caret-down" aria-hidden="true" *ngIf="!isOpened"></i>
      <i class="fas fa-times" aria-hidden="true" *ngIf="isOpened" (click)="closeDropdown($event)"></i>
    </div>

    <div *ngIf="clearAll" class="ng-dropdown-button" (click)="onDropdownMenuClick($event)" #dropdownButton>
      <span>{{selectedText}}</span>     
      <span style="text-align: right">
        <i class="fas fa-caret-down" aria-hidden="true" *ngIf="!isOpened"></i>
              
        <i class="fas fa-times" aria-hidden="true" *ngIf="isOpened" style="padding-right: 10px;" (click)="clearClick()"  title="Clear All"></i>
        <i class="fas fa-caret-up" aria-hidden="true" *ngIf="isOpened" style="padding-right: 0px;"></i>
      </span>
    </div>
  </div>
</ng-container>