import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/*
 *
 * Usage:
 *   <p [innerHTML]="oitForm.governingBody | notPopulated"></p>
 * Example:
 *
*/
@Pipe({name: 'notPopulated'})
export class NotPopulatedPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    if (!value || value.length < 1 || value === 'null') {
        return this.sanitized.bypassSecurityTrustHtml(
          `<span class="italic">(not populated)</span>`);
    } else {
        return value;
    }
  }
}
