import { TOPICS } from '@constants/messages.constants';
import { MessageAction } from '../types/MessageAction';
import { ApiMessage } from '../types/Messages/apiMessage';
import { DataMessage } from '../types/Messages/dataMessage';
import { Message } from '../types/Messages/Message';
import { RouteMessage } from '../types/Messages/routeMessage';
import { SystemEventMessage } from '../types/Messages/SystemEventMessage';
import { UserInterfaceMessage } from '../types/Messages/userInterfaceMessage';

export interface IMsgSenderTopicReg {
    dataTopic(topic: string): IMsgBuild;
    uiTopic(topic: string): IMsgBuild;
    systemTopic(): IMsgBuild;
}
export interface ISendOn {
    sendOn: IMsgSenderTopicReg;
}

export interface IMsgType {
    UserInterfaceMessage(): IMsgSenderId;
    ApiMessage(): IMsgSenderId;
    DataMessage(): IMsgSenderId;
    RouteMessage(): IMsgSenderId;
    SystemEventMessage(): IMsgSenderId;
}

export interface IMsgSenderId {
    usingSpecificSenderId(id: number): IMsgSetSpecificRecipient;
    usingHostSenderId(): IMsgSetSpecificRecipient;
}

export interface IMsgSetSpecificRecipient {
    then: IMsgDataAdder;
    setRecipientId(recId: number): IMsgDataAdder;
}

export interface IUseAnd {
    and: IMsgAddAction;
}

export interface IMsgDataAdder {
    proceedWithoutData: IMsgAddAction;
    addData(key: string, value: any): IMsgDataAdder & IUseAnd & IMsgAddAction;
}

export interface IMsgBuild {
    build(): Message;
}

export interface IMsgAddAction {
    assignAction(action: MessageAction): ISendOn;
}

export class OutboundMessageBuilder
    implements
        IUseAnd,
        IMsgBuild,
        IMsgAddAction,
        IMsgDataAdder,
        IMsgSetSpecificRecipient,
        IMsgSenderId,
        IMsgType,
        IMsgSenderTopicReg,
        ISendOn {
    private hostId: number | string;

    get and(): IMsgAddAction {
        return this;
    }

    get using(): IMsgSenderTopicReg {
        return this;
    }
    get then(): IMsgDataAdder {
        return this;
    }

    get proceedWithoutData(): IMsgAddAction {
        return this;
    }

    get sendOn(): IMsgSenderTopicReg {
        return this;
    }
    msgBeingBuilt: Message;

    build(): Message {
        return this.msgBeingBuilt;
    }

    UserInterfaceMessage(): IMsgSenderId {
        this.msgBeingBuilt = new UserInterfaceMessage();
        return this;
    }

    ApiMessage(): IMsgSenderId {
        this.msgBeingBuilt = new ApiMessage();
        return this;
    }

    DataMessage(): IMsgSenderId {
        this.msgBeingBuilt = new DataMessage();
        return this;
    }

    RouteMessage(): IMsgSenderId {
        this.msgBeingBuilt = new RouteMessage();
        return this;
    }

    SystemEventMessage(): IMsgSenderId {
        this.msgBeingBuilt = new SystemEventMessage();
        return this;
    }

    assignAction(action: string) {
        this.msgBeingBuilt.action = action;
        return this;
    }
    addData(key: string, value: any): IMsgDataAdder & IUseAnd & IMsgAddAction {
        this.msgBeingBuilt.messageData[key] = value;
        return this;
    }

    setRecipientId(recId: number): IMsgDataAdder {
        this.msgBeingBuilt.recipientId = recId;
        return this;
    }

    usingSpecificSenderId(id: number): IMsgSetSpecificRecipient {
        this.msgBeingBuilt.senderId = id;
        return this;
    }

    usingHostSenderId(): IMsgSetSpecificRecipient {
        this.msgBeingBuilt.senderId = this.hostId;
        return this;
    }

    dataTopic(topic: string): IMsgBuild {
        this.msgBeingBuilt.topics.push(topic);
        return this;
    }

    uiTopic(topic: string): IMsgBuild {
        this.msgBeingBuilt.topics.push(topic);
        return this;
    }

    systemTopic(): IMsgBuild {
        this.msgBeingBuilt.setTopic(TOPICS.APPLEVEL.SYSTEM);
        return this;
    }
    constructor(hostId: number | string) {
        this.hostId = hostId;
    }
}
