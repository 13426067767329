<div class="grid-wrapper"
[ngStyle]="{'height': this.height, 'max-height': this.maxHeight}">
    <div [ngStyle]="style">

        <ag-grid-custom-toolbar
            [hidden]="loading"
            [rowCount]="displayedRowCount"
            [filtersChanged]="toolbarActionsEnabled"
            [columnsChanged]="gridColumnsChanged"
                (action)="onToolbarActionClick($event)">
        </ag-grid-custom-toolbar>

        <ag-grid-angular #agGrid class="ag-theme-alpine" style="width: 100%; height: 100%;"
            [context]="context"
            [rowSelection]="rowSelection"
            [rowData]="gridRowData"
            [columnDefs]="columnDefinition"
            [defaultColDef]="defaultColumnDefinition"
            [sideBar]="sideBar"
            [getContextMenuItems]="getContextMenuItems"
            [components]="components"
            [allowDragFromColumnsToolPanel]="true"
            [overlayLoadingTemplate]="overlayLoadingTemplate"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [animateRows]="false"
            [rowHeight]="rowHeight"
            [suppressRowTransform]="true"
            [enableRangeSelection]="true"
            [suppressRowClickSelection]="true"
            [processCellForClipboard]="processCellForClipboard"
            (filterChanged)="onFilterChanged()"
            (columnResized)="onColumnResized()"
            (sortChanged) = "onSortChanged()"
            (columnPinned)="onColumnPinned()"
            (displayedColumnsChanged)="onGridColumnsChanged()"
            (gridReady)="onGridReady($event)"
            (cellFocused)="cellFocused($event)"
            [rowModelType]="gridMode"
            [suppressServerSideInfiniteScroll]="'false'"
            [paginationPageSize]="pageSize"
            [cacheBlockSize]="pageSize"
            [blockLoadDebounceMillis]="1000"
            [maxBlocksInCache]="2"
            [enableCharts]="enableCharts"
            [getRowId]="getRowId">
        </ag-grid-angular>
    </div>
</div>
