<div class="form-group" *ngIf="model?.address1?.isShown">
  <label>{{ labelPrefix + model?.address1?.label }}</label>
  <p [innerHTML]="model?.address1?.value | notPopulated"></p>
</div>
<div class="form-group" *ngIf="model?.address2?.isShown">
  <label>{{ labelPrefix + model?.address2?.label }}</label>
  <p [innerHTML]="model?.address2?.value | notPopulated"></p>
</div>
<div class="form-group" *ngIf="model?.city?.isShown">
  <label>{{ labelPrefix + model?.city?.label }}</label>
  <p [innerHTML]="model?.city?.value | notPopulated"></p>
</div>
<div class="form-group" *ngIf="model?.stateName?.isShown">
  <label>{{ labelPrefix + model?.stateName?.label }}</label>
  <p [innerHTML]="model?.stateName?.value | notPopulated"></p>
</div>
<div class="form-group" *ngIf="model?.countyName?.isShown">
  <label>{{ labelPrefix + model?.countyName?.label }}</label>
  <p [innerHTML]="model?.countyName?.value | notPopulated"></p>
</div>
<div class="form-group" *ngIf="model?.countryName?.isShown">
  <label>{{ labelPrefix + model?.countryName?.label }}</label>
  <p [innerHTML]="model?.countryName?.value | notPopulated"></p>
</div>
<div class="form-group" *ngIf="model?.zip?.isShown">
  <label>{{ labelPrefix + model?.zip?.label }}</label>
  <p [innerHTML]="model?.zip?.value | notPopulated"></p>
</div>
