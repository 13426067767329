import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Subscription } from 'rxjs';
import { SmsColumnDefinition } from '../common/models/sms-column-definition.model';
import { SmsMinimapService } from '../sms-minimap/sms-minimap.service';
import { SmsSortableTableDirective } from '../sms-sortable-table/sms-sortable-table.directive';
import { SmsSortService } from './sms-sort.service';

@Component({
    selector: '[sms-sortable-column]',
    templateUrl: './sms-sortable-column.component.html',
    styleUrls: ['./sms-sortable-column.component.scss'],
})
export class SmsSortableColumnComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input('sms-sortable-column')
    columnName: string;

    @Input() public popoverContent: string;
    @Input() public popoverTitle: string;

    @Input('sms-sort-direction')
    sortDirection = '';

    sortModule = '';
    hidden = false;
    private columnSorted$: Subscription;

    constructor(private elementRef: ElementRef,
                private sortService: SmsSortService,
                @Optional() private smsMinimapService: SmsMinimapService,
                private hostTable: SmsSortableTableDirective) { }

    @HostListener('click', ['$event.target'])
    sort(element) {
        if (element != null && element.className && element.className.indexOf('sortable-btn') >= 0) {
            this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            this.sortService.raiseSortEvent(this.sortModule, {sortColumn: this.columnName, sortDirection: this.sortDirection});
        }
    }

    ngOnDestroy() {
        this.columnSorted$.unsubscribe();
    }

    ngOnInit() {
        const lastSortEvent = this.sortService.getLastSortEvent(this.sortModule);
        if (lastSortEvent && lastSortEvent.sortColumn === this.columnName) {
            this.sortDirection = lastSortEvent.sortDirection;
        }

        if (this.smsMinimapService) {
            this.smsMinimapService.columnInfoChanged$
                .subscribe((newColumnInfo) => {
                    this.onColumndInfoChanged(newColumnInfo);
                });
        }

        this.columnSorted$ = this.sortService.columnSorted$
            .subscribe((event) => {
                // reset this column's sort direction to hide the sort icons
                this.sortDirection = this.columnName !== event.sortColumn  ? '' : event.sortDirection;
            });
    }
    ngAfterViewInit() {
        this.sortModule = this.hostTable.sortModule;
    }

    private onColumndInfoChanged(newColumnInfo: SmsColumnDefinition[]) {
        const thisColumn = newColumnInfo.find((col) => col.value === this.columnName);
        this.hidden = thisColumn
            ? thisColumn.visibility.hidden
            : true;

        this.elementRef.nativeElement.style.display = this.hidden
            ? 'none'
            : 'table-cell';
    }
}
