
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Location, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DragAndDropGridIndexComponent } from '@components/drag-and-drop-grid-index/drag-and-drop-grid-index.component';
import { DragAndDropGridSectionRowComponent } from '@components/drag-and-drop-grid-section-row/drag-and-drop-grid-section-row.component';
import { DragAndDropGridStandardRowComponent } from '@components/drag-and-drop-grid-standard-row/drag-and-drop-grid-standard-row.component';
import { fullTrim, isNullOrUndefined, isValidArray } from '@utilities/helpers';

@Component({
    selector: 'jjk-drag-and-drop-grid',
    styleUrls: ['./drag-and-drop-grid.component.scss'],
    templateUrl: './drag-and-drop-grid.component.html',
})
export class DragAndDropGridComponent implements OnInit, AfterViewInit {

    constructor(private router: Router, private viewportScroller: ViewportScroller) { }

    private _dataSource: any[];
    @Input() visibleActions = 3;
    @Input()
    set dataSource(tableData: any[]) {
        this._dataSource = tableData;
        this.onTableDataChanges();
    }
    get dataSource() { return this._dataSource; }

    @Output() action = new EventEmitter();
    @Output() droppedAction = new EventEmitter();
    @Output() dragAndDropComponent = new EventEmitter();
    @Output() addNewSection = new EventEmitter();
    @Output() addNewStandardRow = new EventEmitter();

    @ContentChildren(DragAndDropGridSectionRowComponent) childSectionComponentList !: QueryList<DragAndDropGridSectionRowComponent>;
    @ContentChildren(DragAndDropGridStandardRowComponent) childStandardRowComponentList !: QueryList<DragAndDropGridStandardRowComponent>;
    @ContentChildren(DragAndDropGridIndexComponent) childDragAndDropGridIndexComponentList !: QueryList<DragAndDropGridIndexComponent>;

    componentLoaded = false;
    componentDataSource: any[];

    // Child Components
    // ------------------------------
    sectionComponent: DragAndDropGridSectionRowComponent;
    standardRowComponent: DragAndDropGridStandardRowComponent;
    indexComponent: DragAndDropGridIndexComponent;
    // ------------------------------

    indexComponent_Active = false;

    ngOnInit() {
        this.router.events.pipe(filter((e) => e instanceof Scroll)).subscribe((e: any) => {
            this.viewportScroller.scrollToAnchor(e.anchor);
        });
    }

    ngAfterViewInit() {
        this.sectionComponent = this.childSectionComponentList.toArray()[0];
        this.standardRowComponent = this.childStandardRowComponentList.toArray()[0];
        this.indexComponent = this.childDragAndDropGridIndexComponentList.toArray()[0];

        setTimeout(() => {
            this.componentLoaded = true;
            this.onTableDataChanges();
        }, 100);
    }

    onTableDataChanges() {

        if (isNullOrUndefined(this.dataSource)) { return; }

        if (!this.componentLoaded) { return; }

        this.componentDataSource = this.dataSource;
    }

    sectionDrop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.componentDataSource, event.previousIndex, event.currentIndex);

        this.emitChanges('Section', event.previousIndex, event.currentIndex, this.componentDataSource[event.currentIndex]);
    }

    rowDrop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            const sectionData = event.container.data[this.standardRowComponent.columnName];
            moveItemInArray(sectionData, (event.previousIndex - 1), (event.currentIndex - 1));

            this.emitChanges('Row', (event.previousIndex), (event.currentIndex), event.container.data);

          } else {

            const sectionData = event.container.data[this.standardRowComponent.columnName];
            const previousSectionData = event.previousContainer.data[this.standardRowComponent.columnName];

            transferArrayItem(previousSectionData, sectionData,
                                (event.previousIndex - 1),
                                (event.currentIndex - 1));

            this.emitChanges('Row', (event.previousIndex), (event.currentIndex), event.container.data, event.previousContainer.data);
        }
    }

    emitChanges(type: string, previousIndex: number, currentIndex: number, sectionData?: any, previousSectionData?: any) {

        const dragAndDropChanges = {
            type,
            previousIndex,
            currentIndex,
            sectionData,
            previousSectionData,
        };

        this.droppedAction.emit(dragAndDropChanges);
    }

    getMenuButtonDisplayText() {
        const menuDisplayText =  !isNullOrUndefined(this.indexComponent.menuButtonDisplayText) ? this.indexComponent.menuButtonDisplayText : 'Menu';
        return menuDisplayText;
    }

    onDragAndDropComponent(childcomponent) {
        childcomponent.component.action.emit(childcomponent);
    }

    getRowValue(row: any, value: string) {
        if (row && value) {
            if (isValidArray(row[value])) {
                return row[value][0];
            } else if (!isNullOrUndefined(row[value])) {
                return row[value];
            }
        }
    }

    getStandardRowCollection(section) {
        if (this.standardRowComponent.columnName) {
            return section[this.standardRowComponent.columnName];
        }
    }

    generateAttrID(row: any, value: string) {
        return fullTrim(this.getRowValue(row, value));
    }

    showHideIndexComponent() {
        this.indexComponent_Active = this.indexComponent_Active === true ? false : true;
    }

    addSection() {
        this.addNewSection.emit();
    }

    addStandardRow(section) {
        this.addNewStandardRow.emit(section);
    }

    shouldDisplayAction(row: any, componentArray: any[]) {

        const shouldDisplayComponent = componentArray.filter((s) => {
            if (isNullOrUndefined(s.shouldDisplay)) {
                return s;
            } else {

                if (typeof s.shouldDisplay === 'boolean') {
                    if (s.shouldDisplay) {
                        return s;
                    }
                }

                const displayConfig = s.shouldDisplay.split(':');
                const column = displayConfig[0];
                const flag = displayConfig.length > 1 ? displayConfig[1] : '';

                const value = this.getRowValue(row, column).toString();

                if (flag !== '') {

                    if (flag.indexOf(',') >= 0) {

                        const displayconditions = flag.split(',');

                        if (displayconditions.find((x) => x === value)) {
                            return s;
                        }

                    } else {

                        if (value === flag) {
                            return s;
                        }
                    }
                }
            }
        });

        return shouldDisplayComponent;
    }

    isFileDownloadable(item: any) {
        if (isNullOrUndefined(this.standardRowComponent.fileDownloadable)) {
            return true;
        } else {

            if (typeof this.standardRowComponent.fileDownloadable === 'boolean') {
                if (this.standardRowComponent.fileDownloadable) {
                    return true;
                }
            }

            const displayConfig = this.standardRowComponent.fileDownloadable.split(':');
            const column = displayConfig[0];
            const flag = displayConfig.length > 1 ? displayConfig[1] : '';
            const value = this.getRowValue(item, column).toString();

            if (flag !== '') {
                if (flag.indexOf(',') >= 0) {
                    const displayconditions = flag.split(',');
                    if (displayconditions.find((x) => x === value)) {
                        return true;
                    }
                } else {
                    if (value === flag) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    getFileSizeText(kbsize: number) {
        if (kbsize) {
            if (kbsize > 1023) {
                return (kbsize / 1024).toFixed(2) + 'mb';
            } else {
                return kbsize.toFixed(0).toString() + 'kb';
            }
        } else {
            return '0kb';
        }
    }

    download(item) {
        this.standardRowComponent.onDownload.emit(item);
    }
}
