import { Injectable } from '@angular/core';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { EnableMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { AuthService } from './auth.service';
import { ApiFactory } from './core/api-factory.class';
import { LoggerService } from '@services/core/logger-service.class';

@Injectable()
export class ImpersonationService extends EnableMessageProcessingDirective {

    constructor(private authService: AuthService, mb: MessageBusService) {
        super(mb);
    }
    protected configureMessageBus(builder: IMsgProcessingReg): void {}

    async endImpersonating(): Promise<void> {
        const loadComplete = new Promise<void>((resolve) => {
            if (LoggedInUserInfo.Instance.userInfo.impersonation) {
                const impersonations = LoggedInUserInfo.Instance.userInfo.impersonation.find((e) => e.id === LoggedInUserInfo.Instance.userInfo.impersonationId);
                impersonations.endTime = new Date();
                impersonations.isDeleted = true;
                ApiFactory.updateEntity(ApiEntityTypesEnum.Impersonations, impersonations)
                    .addSuccessHandler((response) => this.successEndImpersonating(response))
                    .addErrorHandler((error) => {
                        LoggerService.trace('trace', error);
                    })
                    .buildAndSend();
                resolve();
            } else {
                this.successEndImpersonating(null);
                resolve();
            }
        });
        return loadComplete;
    }

    async successEndImpersonating(response) {
        LoggerService.trace('trace', response);
        LoggedInUserInfo.Instance.userInfo.impersonation = null;
        LoggedInUserInfo.Instance.userInfo.impersonationId = '';
        LoggedInUserInfo.Instance.userInfo.userId = LoggedInUserInfo.Instance.userInfo.mainUser.id;
        LoggedInUserInfo.Instance.userInfo.azureUID = LoggedInUserInfo.Instance.userInfo.mainUser.azureInternalId;
        LoggedInUserInfo.Instance.userInfo.oktaUID = LoggedInUserInfo.Instance.userInfo.mainUser.oktaId;
        await this.authService.loadSmsUserInfo('', '');
        location.reload();
    }
}
