<div class="aggridtoolbar clearfix">

  <div *ngIf="rowCount && !showSelectAll" class="results">
      <span>
          {{ _rowCount }} Results
      </span>
  </div>

  <div *ngIf="showSelectAll" class="actionsMenuLeft">
      <ul class="actions">

          <li><a (click)="onSelectAll()"><i class="custom-icon" aria-hidden="true"></i> Select All</a></li>
          <li><a (click)="onSelectAll(false)"><i class="custom-icon" aria-hidden="true"></i> Deselect All</a></li>

          <ng-container *ngIf="showSelectAll && _rowCount > 0">
              <span class="resultsSelectedRows">
                  {{ _rowCount }} Rows Selected
              </span>
          </ng-container>
      </ul>
  </div>

</div>