<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 main-content">
        <sms-alert></sms-alert>
        <div class="cssGridWrapper">
            <div class="title-container">
                <h1>Product List</h1>
                <p>
                    Create and maintain your Chemical Product inventory. Link to product Safety Data Sheets (SDSs). Track usage by location and work area for recordkeeping, reporting & to create your SDS binders.
                    Add your own Safety Data Sheet with <b>Add Chemical</b> or search from our library with <b>Find SDS.</b>
                </p>
            </div>

            <div class="btns-container">
                <button auth authFeature="chemical,create" class="btn btn-sm btn-primary btnAdd" (click)="eventEmitter('Add')">Add Chemical</button>
                <button auth authFeature="chemical,create" class="btn btn-sm btn-secondary btnFind" (click)="eventEmitter('Find')">Find SDS</button>
            </div>

            <ng-content></ng-content>
        </div>
    </div>
</div>
