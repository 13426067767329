import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
// const { jjkellerportalapiurl } = environment;

export class ApiServiceBase {
    private httpClient: HttpClient;
    private headers: HttpHeaders;
    protected httpOptions: any;

    constructor() {
        // Manually retrieve the dependency from the injector
        // so the constructor has no dependency that must be passed in
        this.httpClient = inject(HttpClient);

        this.headers = new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            'Access-Control-Allow-Origin': '*',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0',
        });
        this.httpOptions = { headers: this.headers };
    }

    protected get<T>(path: string, httpParams?: HttpParams): Observable<T> {
        return this.httpClient.get<T>(this.buildUrl(path), Object.assign({ params: httpParams }, this.httpOptions))
            .pipe(
                catchError(this.handleError(null),
            ),
        );
    }

    protected post<T>(path, body, httpOptions?): Observable<T> {
        return this.httpClient.post<T>(this.buildUrl(path), body, httpOptions ? httpOptions : this.httpOptions)
            .pipe(
                catchError(this.handleError(null),
            ),
        );
    }

    protected delete<T>(path: string, httpParams?: HttpParams): Observable<T> {
        return this.httpClient.delete<T>(this.buildUrl(path), Object.assign({ params: httpParams }, this.httpOptions))
            .pipe(
                catchError(this.handleError(null),
            ),
        );
    }

    // TODO: implement this meaningfully
    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    private buildUrl(path) {
        return `${environment.jjkellerportalapiurl}${this.cleanPath(path)}`;
    }

    // remove '/' characters from path as our base api URL should include the trailing '/'
    private cleanPath(path: string): string {
        while (path.startsWith('/', 0)) {
            return this.cleanPath(path.slice(1));
        }
        return path;
    }

}
