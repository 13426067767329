import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { DataGridColumnComponent } from '@components/datagrid-column/datagrid-column.component';

@Component({
    selector: 'jjk-datagrid-column-file',
    template: `<ng-content></ng-content>`,
    providers: [{ provide: DataGridColumnComponent, useExisting: forwardRef(() => DataGridColumnFileComponent) }],
})
export class DataGridColumnFileComponent extends DataGridColumnComponent {
    private _fileType: string;

    @Input() public fileId: string;
    @Input() public fileUrl: string;
    @Input() public fileSize: string;
    @Input() public downloadable: string;
    @Input() downloading: string;

    @Output() action = new EventEmitter<any>();

    @Input() set fileType(value: string) {
        this._fileType = value;
        this.columnName = value;
    }
    get fileType(): string {
        return this._fileType;
    }

    constructor() {
        super();
        this._columnType = 'File';
    }

}
