import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';

@Injectable()
export class SetTitleService {

  constructor(private titleService: Title) { }

  setTitle(title: string) {
    this.titleService.setTitle(title + environment.titleSuffix);
  }

  getTitle() {
    return this.titleService.getTitle();
  }

}
