import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'jjk-label',
    templateUrl: './label.component.html',
})
export class LabelComponent {

    @Input() displayText: string;
    @Input() color: string;
    @Output() action = new EventEmitter();

    constructor() {
    }

    onClick(event: any) {
        this.action.emit(event);
    }
}
