import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ApiFactory } from '@services/core/api-factory.class';

@Component({
  selector: 'jjk-activate-inactivate',
  templateUrl: './activate-inactivate.component.html',
  styleUrls: ['./activate-inactivate.component.scss']
})
export class ActivateInactivateComponent implements OnInit {

    get showFirstObjectRecordName() {
        return !this.recordName && this.objectsToUpdate.length === 1 && this.objectsToUpdate[0]?.name && this.entitiesWithNoPermissionCount === 0;
    }

    get showAreYouSure() {
        return this.canUpdate && this.entityName && this.totalEntityCount === 1 && this.entitiesWithNoPermissionCount === 0;
    }

    @Input() public entityType: ApiEntityTypesEnum;
    @Input() public entityName = '';
    @Input() public modalId = 'activeInactiveModal';
    @Input() public recordName = '';
    @Input() action = 'Activate';
    @Input() public totalEntityCount = 0;
    @Input() public entitiesWithNoPermissionCount = 0;
    @Input() public objectsToUpdate: any[] = [];
    @Input() public canUpdate = true;

    @Output() onSuccessful: EventEmitter<any> = new EventEmitter();
    @Output() onFailed: EventEmitter<any> = new EventEmitter();
    @Output() onCancel: EventEmitter<any> = new EventEmitter();
    @Output() onX: EventEmitter<any> = new EventEmitter();
    @Output() onConfirmed: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    onModalConfirm() {

        if (this.canUpdate && this.objectsToUpdate) {
            ApiFactory.updateEntity(this.entityType, this.objectsToUpdate)
                .addRouteHint('UpdateMany')
                .addSuccessHandler((response) => this.onSuccess(response))
                .addErrorHandler((response) => this.onError(response))
                .buildAndSend();
        }
        else {
            this.onCancel.emit();
            return;
        }

        this.onConfirmed.emit();
    }

    private onSuccess(response) {
        this.onSuccessful.emit(response);
    }
    private onError(response) {
        this.onFailed.emit(response);
    }
    onModalCancel() {
        this.onCancel.emit();
    }
    onModalX() {
        this.onX.emit();
    }
}
