import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataGridComponent } from '@components/datagrid/datagrid.component';

@Component({
    selector: 'jjk-datagrid-column-custom-content',
    templateUrl: './datagrid-column-custom-content.component.html',
    providers: [DataGridComponent],
})
export class DataGridColumnCustomContentComponent {

    @Input() component: any;
    @Input() cell: any;
    @Input() rowIdx = 0;
    @Input() index = 0;

    @Output() action = new EventEmitter();

    constructor(private dataGrid: DataGridComponent) { }

    valueChanged(cell: any, component: any) {
        this.action.emit({ cell, component });
    }

    queryParams(cell: any, component: any) {
        if (component['componentType'] === 'link' && component['queryParams'] &&
            ((!cell[component['field']] && component['queryParamsOnlyWithEmptyField']) || !component['queryParamsOnlyWithEmptyField'])) {
            Object.keys(component['queryParams']).forEach((p) => {
                component['queryParams'][p] = cell[p];
            });
            return component['queryParams'];
        }
    }
}
