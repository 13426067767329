import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';
import { AppModule } from 'app/app.module';
import { environment } from '@env/environment';
import { LoggerService } from '@services/core/logger-service.class';

// AG-Grid License
LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-051900}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{J._J._Keller_&_Associates,_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Safety_Management_Suite}_only_for_{9}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Safety_Management_Suite}_need_to_be_licensed___{Safety_Management_Suite}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{17_February_2025}____[v3]_[01]_MTczOTc1MDQwMDAwMA==377639ffdc7507297a3d42c1f1b0deee');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((err) =>  LoggerService.trace('trace', err));
