import { Component, Input } from '@angular/core';

@Component({
    selector: 'jjk-search-tips',
    templateUrl: './search-tips.component.html',
})
export class SearchTipsComponent {

    showSearchTips = false;
    searchTipsText = 'Show';

    @Input() searchFields = 'Titles, Topics, Synonyms, and Citations';

    constructor() {
    }

    showHideSearchTips() {
        this.showSearchTips = !this.showSearchTips;
        if (this.showSearchTips) {
            this.searchTipsText = 'Hide';
        } else {
            this.searchTipsText = 'Show';
        }
    }

}
