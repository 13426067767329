<div>

  <!-- Header -->
  <layout-header *ngIf="isAuthorized"></layout-header>
  <auth-header *ngIf="(!isAuthorized && isLogin) || smsUnderMaintenance"></auth-header>
  <!-- Sidenav -->
  <layout-side-nav *ngIf="isAuthorized "></layout-side-nav>

  <!-- Main Content -->
  <jjkp-maintenance *ngIf="smsUnderMaintenance"></jjkp-maintenance>

  <jjk-loader *ngIf="isCallbackPage" class="d-flex justify-content-center callback-loader" [loadingText]="'<h2>Logging in</h2>'"></jjk-loader>

  <main class="wrapper" (click)="hideSideNav()" *ngIf="!smsUnderMaintenance">
    <print-page>
      <router-outlet  *ngIf="!smsUnderMaintenance"></router-outlet>
    </print-page>

    <layout-timeout-modal *ngIf="isAuthorized"></layout-timeout-modal>
    <layout-impersonation-modal *ngIf="isAuthorized"></layout-impersonation-modal>
    <password-expire-modal *ngIf="isAuthorized && isAboutToExpire"></password-expire-modal>
    <auth-migration-form *ngIf="isAuthorized"></auth-migration-form>
    <profile-modal *ngIf="isAuthorized"></profile-modal>
    <tou-modal *ngIf="isAuthorized"></tou-modal>
    <okta-first-login-modal *ngIf="isAuthorized && isOktaFirstLogin"></okta-first-login-modal>
    <jjk-account-migration *ngIf="isAuthorized && migrated && isNotMigratedJjk"></jjk-account-migration>
  </main>

  <!-- Footer -->
  <layout-footer *ngIf="isAuthorized"></layout-footer>
  <auth-footer *ngIf="(!isAuthorized && isLogin) || smsUnderMaintenance"></auth-footer>
</div>
