<div *ngIf="chartData"  class="chart-container">
  <h2 style="text-transform: none;">{{chartData.title}}<jjk-popover [content]="'To help identify trends, monthly audit conformance displayed here represents a view of collective audit scores for completed events where scoring is required, by due date of the event.'"></jjk-popover></h2>
  <jjk-empty-state *ngIf="isEmptyState" auth authFeature="audit,create" (action)='onEmptyStateAddNewClick($event)' [showAddButton]="showAddButtonEmptyState" [displayText]="emptyStateMessage"></jjk-empty-state>
  <combo-chart-component *ngIf="!isEmptyState"
    [view]="view"
    [scheme]="chartData.comboBarScheme"
    [colorSchemeLine]="chartData.lineChartScheme"
    [barChart]="chartData.barChart"
    [animations]="chartData.animations"
    [lineChart]="chartData.lineChartSeries"
    [gradient]="chartData.gradient"
    [xAxis]="chartData.showXAxis"
    [yAxis]="chartData.showYAxis"
    [legend]="chartData.showLegend"
    [legendTitle]="chartData.legendTitle"
    [legendPosition]="chartData.legendPosition"
    [showGridLines]="chartData.showGridLines"
    [showXAxisLabel]="chartData.showXAxisLabel"
    [showYAxisLabel]="chartData.showYAxisLabel"
    [showRightYAxisLabel]="chartData.showYAxisLabel"
    [xAxisLabel]="chartData.xAxisLabel"
    [yAxisLabel]="chartData.yAxisLabel"
    [yAxisLabelRight]="chartData.yAxisLabelRight"
    [yAxisTickFormatting]="axisFormatWholeNumber"
    [yRightAxisTickFormatting] = "yRightAxisTickFormattingFN">
    <ng-template #lineTooltipTemplate let-model="model">
      <div>
        {{ model.series }} ● {{ model.name }}
        <br />{{ model.value }}%
      </div>
    </ng-template>
    <ng-template #seriesTooltipTemplate let-model="model">
      <div *ngFor="let tooltipItem of model" class="tooltip-item">
        <ng-container *ngIf="tooltipItem.series=='Checklists'">
          <svg width="10" height="10">
            <rect rx="2" ry="2" width="10" height="10" style="fill:#466ac2;"/>
          </svg>&nbsp;&nbsp;
          {{tooltipItem.series}}: {{tooltipItem.value}}
        </ng-container>

        <ng-container *ngIf="tooltipItem.series=='Conformance'">
          <svg width="10" height="10">
            <rect rx="2" ry="2" width="10" height="10" style="fill:#f17e3d;"/>
          </svg>&nbsp;&nbsp;
          {{tooltipItem.series}}: {{tooltipItem.value}}%
        </ng-container>
      </div>
  </ng-template>
  </combo-chart-component>  
</div>
