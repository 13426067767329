import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'regulatedBadge'})
export class RegulatedPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
    transform(value: string, inProgress: boolean) {
        if (value && inProgress) {
            return this.sanitized.bypassSecurityTrustHtml(
                `${value} <span class="badge bg-dark"> Regulated </span>`);
        } else {
            return value;
        }
    }
}
