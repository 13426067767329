import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'sds-search-radio-button',
  template: `<div class="ngSelectionCell"><input id="{{params.data?.id}}" (click)="onClick($event)" name="selected" type="radio" />{{guid}}</div>
  `
})

export class SDSSearchRadioButtonComponent implements ICellRendererAngularComp {

  params;
  guid: string;

  agInit(params): void {
    this.params = params;
    // this.guid = this.params.guid || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data
      };
      this.params.onClick(params);
    }
  }
}
