import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined, newRandomId } from '@utilities/helpers';

@Component({
  selector: 'search-input',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements AfterViewInit {
  private viewInitialized = false;

  private _placeholder = 'Filter by title';
  @Input()
  public get placeholder() {
    return this._placeholder;
  }
  public set placeholder(value) {
    this._placeholder = value;
    this.setSearchBoxSize();
  }
  @Input() searchTerm = '';
  @Input() showLoader = false;
  @Input() loadingText = '';
  @Input() showSearchButton = false;
  @Input() minSearchTermLength = 3;
  @Input() showBackButton = false;
  @Input() backButtonText = "";
  @Input() customButtonStyle;

  @Output() filterSearch = new EventEmitter();
  @Output() searchClick = new EventEmitter();

  alphaId = newRandomId(4);
  showErrors = false;

  constructor(private router: Router) {}

  filter($event) {
    if (this.searchTerm && this.searchTerm.length < this.minSearchTermLength) {
      return;
    }
    if ($event.keyCode === 13) {
      this.searchClick.emit(this.searchTerm);
    }
    this.filterSearch.emit(this.searchTerm);
  }

  search() {
    if (this.searchTerm && this.searchTerm.length < this.minSearchTermLength) {
      this.showErrors = true;
      return;
    }

    this.searchClick.emit(this.searchTerm);
    this.showErrors = false;
  }

  searchDeleteClick() {
    this.searchTerm = '';
    this.searchClick.emit(this.searchTerm);
  }

  searchHasErrors() {
    if (this.searchTerm && this.searchTerm.length < this.minSearchTermLength) {
      return true;
    }
  }

  ngAfterViewInit(): void {
    this.viewInitialized = true;
    this.setSearchBoxSize();
  }

  setSearchBoxSize() {
    if (this.viewInitialized) {
      const searchBox = document.querySelector(`[alphaId='${this.alphaId}']`) as HTMLElement;
      if(!isNullOrUndefined(searchBox)) {
        const fontSize = window.getComputedStyle(searchBox, null).getPropertyValue('font-size');
        const fontFamily = window.getComputedStyle(searchBox, null).getPropertyValue('font-family');
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = `italic ${fontSize} ${fontFamily}`;
        const placeholderWidth = context.measureText(this.placeholder).width;
        searchBox.style.maxWidth = placeholderWidth < 230 ? '260px' : Math.ceil(placeholderWidth + 30) + 'px';
        // For pages with long placeholders on small devices, shrink text
        if (window.innerWidth < placeholderWidth + 45) {
          searchBox.style.fontSize = `.75rem`;
        }
      }
    }
  }

  back() {
    this.router.navigate(['/chemicals/product_list']);
  }
}
