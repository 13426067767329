import { AfterViewInit, ChangeDetectorRef, Component, ContentChildren, EventEmitter, Input, OnChanges, OnInit, Output, QueryList } from '@angular/core';
import { TabItemComponent } from '@components/tab-item/tab-item.component';

@Component({
  selector: 'jjk-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterViewInit, OnInit, OnChanges {

  constructor(private cdr: ChangeDetectorRef) { }

  @Input() tableData: any[];
  @Input() showAllTab: string;
  @Input() columnName: string;

  @Output() action = new EventEmitter();

  @ContentChildren(TabItemComponent) childrenTabItemComponentList !: QueryList<TabItemComponent>;

  isLoaded: boolean;
  tabItems: TabItemComponent[];
  public filteredtabItems: TabItemComponent[] = [];
  public tabFilter = '';

  ngAfterViewInit(): void {
    this.tabItems = this.childrenTabItemComponentList.toArray().sort((a, b) => a.priority - b.priority);
    this.tabItems.filter((item) => {
      if (item.showEmpty) {
        this.filteredtabItems = this.addTabItem(this.filteredtabItems, item);
      }
    });
  }

  ngOnInit(): void {
    this.isLoaded = false;
  }

  ngOnChanges(): void {
    if (this.tableData) {
      this.isLoaded = true;
      this.tableData.map((data) => this.filteredtabItems = this.tabItemValidate(this.tabItems, this.filteredtabItems, data, this.columnName));
    }
  }

  onTabClick(event: any) {
    this.action.emit(event);
  }

  public tabItemValidate(tabItems: TabItemComponent[], filtered: TabItemComponent[], data: any[], columnName: string) {
    // Data Source Columns Tab Items
    if (columnName) {

      const ColVal = data[columnName];
      if (ColVal) {
        if (filtered.findIndex((i) => i.displayText === ColVal) === -1) {
          const tabItem = new TabItemComponent();
          tabItem.displayText = data[columnName];
          filtered = this.addTabItem(filtered, tabItem);
        }

         // tab-Items Look up for matches in the data source
        tabItems.map((item) => {
          if (!item.showEmpty) {
              if (item.displayText === data[columnName]) {
                filtered = this.addTabItem(filtered, item);
              }
              if (item.searchFields && item.searchFields.find((x) => x === data[columnName])) {
                filtered = this.addTabItem(filtered, item);
              }
          }
        });

        this.cdr.detectChanges();
        return filtered;
      }
      else{return filtered;}

    } else {
      // tab-Items Look up for matches in the data source
      tabItems.map((item) => {
        if (!item.showEmpty) {
          Object.keys(data).map((key) => {
            if (item.displayText === data[key]) {
              filtered = this.addTabItem(filtered, item);
            }
            if (item.searchFields && item.searchFields.find((x) => x === data[key])) {
              filtered = this.addTabItem(filtered, item);
            }
          });
        }
      });
      this.cdr.detectChanges();
      return filtered;
    }
  }

  public addTabItem(filtered: TabItemComponent[], elem: TabItemComponent) {
    if (elem && elem.displayText && elem.displayText.trim() !== '') {
      // Add Item
      filtered.push(elem);
      filtered = this.sortFilteredTabItems(filtered);
    }
    return filtered;
  }

  public sortFilteredTabItems(filtered: TabItemComponent[]) {

    // Remove Duplicates
    filtered = Array.from(filtered.reduce((m, t) => m.set(t.displayText, t), new Map()).values());
    // Sort the items alphabetically (In case there is no priority.)
    filtered.sort((a, b) => a.displayText !== b.displayText ? a.displayText < b.displayText ? -1 : 1 : 0);
    // Sort the items by Priority
    filtered.sort((a, b) => a.priority - b.priority);

    return filtered;
  }

}
