import { Platform } from '@angular/cdk/platform';
import { Directive, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'input'
})
export class DisableAutoCompleteInputDirective {

  constructor(el: ElementRef, private platform: Platform) {
    if (this.platform.BLINK) {
      el.nativeElement.autocomplete = 'off';
    }
    else {
      el.nativeElement.autocomplete = 'off';
    }
  }
}
