<div class="modal fade" id="oktaFirstLoginModal" tabindex="-1" role="dialog" aria-labelledby="firstLoginHeader"
  data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div *ngIf="dataLoaded" class="modal-content">
      <div class="modal-header">
        <div class="notification-content">
            <span class="notification-logo"></span>
            <span id="firstLoginHeader">NOTIFICATION</span>
        </div>
      </div>
      <div class="modal-body">
        <div *ngIf="isNewOktaUser" class="notification-content credentials" [ngClass]="isSapUser ? '' : 'notSap'">
            <p>Your Safety Management Suite account credentials can be used to log into other J. J. Keller & Associates Inc. products. Any changes to credentials will affect future products associated with this email.<br><br>
                Contact support at <a href="tel:833-813-7268">833-813-7268</a> or <a href="mailto:safetysupport@jjkeller.com">safetysupport&#64;jjkeller.com</a> for any questions.</p>
        </div>
        <div *ngIf="!isNewOktaUser" class="notification-content credentials" [ngClass]="isSapUser ? '' : 'notSap'">
            <p>Your account credentials will remain unchanged across {{products}} and Safety Management Suite. Any changes to credentials will also affect login to {{otherProducts}}.<br><br>
                Contact support at <a href="tel:833-813-7268">833-813-7268</a> or <a href="mailto:safetysupport@jjkeller.com">safetysupport&#64;jjkeller.com</a> for any questions.</p>
        </div>
        <div *ngIf="!isSapUser" class="notification-content">
            <p>Great news! You're almost ready to use Safety Management Suite. Please proceed to complete setting up your company profile.</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" [ngClass]="'btn-primary'" (click)="onConfirm()">Proceed</button>
      </div>
    </div>
  </div>
</div>
