import { ApiActionsEnum } from '@constants/enums/api-actions.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ReportColumnConfig } from '@models/entity-models/reportingcenter/report-columns-config';
import { IFilterDataOnly } from '@services/core/models/Filter-Entry';
import { GroupingCriteria } from '@services/core/models/GroupingCriteria';
import { PartialCountCriteria } from '@services/core/models/PartialCountCriteria';
import { SortCriteriaEntry } from '@services/core/models/SortCriteriaEntry';
import { SimpleHttpHeadersClass } from '@services/core/SimpleHttpHeadersClass';
import { MessageType } from '../messageType';
import { Message } from './Message';

/**********************************************************************************************
 * The ApiMessage is intended to communicate specifically with API
 * Services.  The message allows for targeting of a specific service
 * and responding to that message privately if requested
 *********************************************************************************************/
export class ApiMessage extends Message {
    // this is used during handler registration
    public static messageRegistrationKey: MessageType = 'ApiMessage';
    isRequestRaw = false;
    AddAllDataEntriesToTheBody = false;

    // this is used in message identification
    public get messageType(): MessageType { return ApiMessage.messageRegistrationKey; }

    public filters: IFilterDataOnly[] = [];
    public sortingCriteria: SortCriteriaEntry[] = [];
    public groupingCriteria: GroupingCriteria;
    public partialCountCriteria: PartialCountCriteria;
    public pageNumber = 1;
    public itemsPerPage = 50;
    public additionalHeaders = new SimpleHttpHeadersClass();
    public endPointUrlTemplate: string = undefined;
    public apiEntityType: ApiEntityTypesEnum = undefined;
    private _apiAction: ApiActionsEnum = undefined;
    public apiVersion = 1;
    public stringToAppendToRoute: string;
    public reportParams: any;
    public shouldReturnFullResponse = false;
    public generateCSV: ReportColumnConfig[] = [];
    public skipClientSideCache = false;
    public successHandler: (apiResponse) => void = undefined;
    public errorHandler: (apiResponse) => void = undefined;
    public get apiAction() {
        return this._apiAction;
    }
    /** This is needed because the routing interacts with ACTION not ApiAction */
    public set apiAction(apa: ApiActionsEnum) {
        this._apiAction = apa;
        this.action = apa;
    }

    public isValid(): boolean {
        if (this.apiAction === undefined) {
            throw new Error('apiAction must be specified');
        }
        if (this.apiEntityType === undefined) {
            throw new Error('apiEntityType must be specified');
        }

        if (this.apiVersion === undefined) {
            throw new Error('api Version must be specified');
        }

        if (this.successHandler === undefined) {
            throw new Error('must specify a successHandler');
        }

        return true;
    }
}
