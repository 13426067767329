import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { SplitioService } from '@services/splitio.service';
import { isNullOrUndefined } from '@utilities/helpers';
import { HttpCacheService } from './HttpCache.service';

@Injectable()
export class CachingInterceptorService implements HttpInterceptor {

    ignoredEndpointList = [
        ApiEntityTypesEnum.Security,
        ApiEntityTypesEnum.UserDataProfile,
        ApiEntityTypesEnum.NumberingSequenceType,
        ApiEntityTypesEnum.Checklist, // Added because is causing "cyclic object value" exception
        ApiEntityTypesEnum.Attachment,
        ApiEntityTypesEnum.Chart, // Added because the DART chart on the Incident Dashboard doesn't update on new location selections
        ApiEntityTypesEnum.Order, // Added because we get this from TOD and use it to determine remaining amount of enrollments, so caching could cause TOD enrollment issues.
        ApiEntityTypesEnum.EmployeeViewModel, //Added because when you add a new employee it has to return this new record on the view model
        ApiEntityTypesEnum.ChemicalSettings, //Added because a setting that can be changed by multiple users shouldn't be cached
    ];

    callIfFailEndpointList = [
        ApiEntityTypesEnum.ClassroomTrainingEvent,
        ApiEntityTypesEnum.TrainingRecords,
        ApiEntityTypesEnum.ClassroomProgram,
        ApiEntityTypesEnum.TrainingAsset,
        ApiEntityTypesEnum.Enrollment,
        ApiEntityTypesEnum.Course
    ];

    State = false;

    constructor(private cache: HttpCacheService,
                private splitioService: SplitioService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        return from(this.handleRequest(req, next));
    }

    async handleRequest(req: HttpRequest<any>, next: HttpHandler) {
        // Gets the key from the header
        const key = req.headers.get('EndpointKey');

        if (!key || this.ignoredEndpointList.includes(ApiEntityTypesEnum[key])) { return next.handle(req).toPromise(); }

        // Check the status of the feature toggle to know whether or not to use the client-side cache
        this.State = await this.checkCacheToggle();

        if (this.State) {

            // I check if the request type is "Get"
            if (req.method === 'GET') {

                // Checks if it is already in the cache
                const cachedResponse = this.cache.get(req.url);

                if (cachedResponse && !req.headers.get('SkipClientSideCache')) {
                    if(this.callIfFailEndpointList.includes(ApiEntityTypesEnum[key]) && isNullOrUndefined(cachedResponse['status']))
                        return this.sendNewRequest(req, next).toPromise()
                    else
                        return of(cachedResponse).toPromise();
                }
                else
                {
                    return this.sendNewRequest(req, next).toPromise()
                }
            } else {
                // if the type of request is other than "Get"
                // then I clear the cache because it means that a record was Added / Updated / Deleted
                this.cache.deleteRelatedData(key);

                // If its not cachable, make a fresh request to the server.
                return next.handle(req).toPromise();
            }
        }
        return next.handle(req).toPromise();
    }

    sendNewRequest(req: HttpRequest<any>, next: HttpHandler){

        const endpointKey = req.headers.get('EndpointKey');

        return next.handle(req).pipe(
            tap((event) => this.cache.set(req.url, event, new Date(), endpointKey))
        );
    }

    async checkCacheToggle() {
        const toggleState = await this.splitioService.getToggle(TOGGLES.SMS_CLIENT_SIDE_CACHE);
        return toggleState === TREATMENT.ON;
    }
}
