import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatTabNav, MatTabsModule } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Dropdown, Popover } from 'bootstrap';
import { UserTypes } from '@constants/enums/user-type.enum';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { createPopper } from '@popperjs/core';
import { FeatureAuthService } from '@services/feature-auth.services';
import { SplitioService } from '@services/splitio.service';

@Component({
    selector: 'jjk-horizontal-navigation',
    templateUrl: './horizontal-navigation.component.html',
    styleUrls: ['./horizontal-navigation.component.scss'],
})
export class HorizontalNavigationComponent implements OnInit {

    private _navUrl = 'https://www.jjkellersafety.com/coming-soon/';
    url = '';

    isChemicalsActive: boolean;
    isIncidentsActive: boolean;
    isTrainingActive: boolean;
    isAuditsActive: boolean;
    isTopicsActive: boolean;
    isSdsManagement = LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.SdsMgmt;
    dashboardPermission: boolean;
    chemicalDashboardPermission: boolean;
    incidentDashboardPermission: boolean;
    trainingDashboardPermission: boolean;
    auditDashboardPermission: boolean;
    topicDashboardPermission: boolean;
    @ViewChild('navBar', { static: true })
    navBar: MatTabNav;
    constructor(
        private router: Router,
        private renderer: Renderer2,
        private ref: ElementRef,
        private splitioService: SplitioService,
        private featureAuth: FeatureAuthService,) { }

    async ngOnInit() {

        this.url = this.router.url;
        this.isChemicalsActive = await this.splitioService.getToggle(TOGGLES.CHEMICAL_FEATURE) === TREATMENT.ON;
        this.isIncidentsActive = await this.splitioService.getToggle(TOGGLES.INCIDENT_FEATURE) === TREATMENT.ON;
        this.isTrainingActive = await this.splitioService.getToggle(TOGGLES.TRAINING_FEATURE) === TREATMENT.ON;
        this.isAuditsActive = await this.splitioService.getToggle(TOGGLES.AUDIT_FEATURE) === TREATMENT.ON;
        this.isTopicsActive = await this.splitioService.getToggle(TOGGLES.TOPIC_INDEX_FEATURE) === TREATMENT.ON;
        this.dashboardPermission = this.featureAuth.hasAccess("dashboard-home");
        this.chemicalDashboardPermission = this.featureAuth.hasAccess("dashboard-chemical");
        this.incidentDashboardPermission = this.featureAuth.hasAccess("dashboard-incident");
        this.trainingDashboardPermission = this.featureAuth.hasAccess("dashboard-training");
        this.auditDashboardPermission = this.featureAuth.hasAccess("dashboard-audit");
        this.topicDashboardPermission = this.featureAuth.hasAccess("dashboard-topic");

    }

    getActiveClass(index: number, itemUrl: string): string {
        if (this.url.endsWith(itemUrl)) {
            this.navBar.selectedIndex = index;
            return 'active';
        }
    }
    activeToggle(e: any) {
        let targetText;
        e.target.innerText == null || e.target.innerText == '' ? (targetText = e.target.parentElement.innerText) : (targetText = e.target.innerText);
        // Keep this until all links are to pages within the site
        const links: any[] = Array.from(this.ref.nativeElement.querySelectorAll('.nav-link'));
        links.forEach((link) => {
            if(targetText.trim().endsWith(link.innerText.trim())){
                this.renderer.addClass(link, 'active');
            } 
           else{ this.renderer.removeClass(link, 'active');}
        });

        switch (targetText.trim()) {
            case 'Chemicals':
                if (this.isChemicalsActive) {
                    this.router.navigate([`chemicals`]);
                } else {
                    window.open(this._navUrl + 'chemicals', '_blank');
                }
                break;
            case 'Incidents':
                if (this.isIncidentsActive) {
                    this.router.navigate([`incidents`]);
                } else {
                    window.open(this._navUrl + 'incidents', '_blank');
                }
                break;
            case 'Training':
                if (this.isTrainingActive) {
                    this.router.navigate([`training`]);
                } else {
                    window.open(this._navUrl + 'training', '_blank');
                }
                break;
            case 'Audits':
                if (this.isAuditsActive) {
                    this.router.navigate([`audits`]);
                } else {
                    window.open(this._navUrl + 'audits', '_blank');
                }
                break;
            case 'Topics':
                if (this.isTopicsActive) {
                    this.router.navigate([`topics`]);
                } else {
                    window.open(this._navUrl + 'topics', '_blank');
                }
                break;
            default:
                this.router.navigate(['/dashboard']);
                break;
        }
    }
}
