<a class="small" (click)="showHideSearchTips()">{{ searchTipsText }} Search Tips</a>

<p class="small" [ngClass]="showSearchTips ? '' : 'd-none'">
    <strong>Search Tips:</strong><br />
    <em>All results are based on {{ searchFields }}</em><br />
    <em>Undecorated search words return content containing each word(s) exactly as spelled (ex: waste hazard)</em><br />
    <em>Add an asterisk (*) at the end of a word or partial word, to return results which <strong>contain</strong> the word <strong>and</strong> any other words (ex: waste haz*)</em><br />
    <em>Add a pipe ( | ) between words to return results which include (or contain with an *) any of the words (ex: waste | hazard)</em><br />
    <em>Add a minus (-) to the beginning of a word to exclude results which include (or contain with an *) that word (ex: waste -haz*)</em><br />
    <em>Add quotes ("...") to a phrase to return results matching the exact phrase (ex: "hazardous waste")</em>
</p>