import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { DynamicSelectDataload } from '@models/dynamic-forms.export';
import { FilterExpressionBuilder } from '@services/core/models/Filter-Entry';

export function loadRoleData(groupName = null, sapAdmin = false) {
  let roles = LoggedInUserInfo.Instance.userInfo.roles;

  // If an SAP Admin is selected, only the Admin role should be available for them
  if (sapAdmin) {
      roles = roles.filter((r) => r.name === 'Admin');
  // Hide the Admin role if the current sub type is not allowed to set new admins
  } else if (!LoggedInUserInfo.Instance.userInfo.subscriptionType.canSetAsAdmin) {
      roles = roles.filter((e) => e.name !== 'Admin');
  // If this the Root data group, do not show the None role option
  } else if (groupName === 'Shared') {
      roles = roles.filter((e) => e.name !== 'None');
  }

  return new DynamicSelectDataload(roles, 'id', 'name', 'value', 'desc', '').options;
}

export function getExpressionBuilderByUserGroupId(entityType: ApiEntityTypesEnum, userGroupId) {
    return FilterExpressionBuilder.For(entityType)
            .Use('UserGroupId', ParameterTypeEnum.String)
            .Equal(userGroupId)
            .Build().AsExpression;
}

export function getExpressionBuilderByUserId(entityType: ApiEntityTypesEnum, userId) {
    return FilterExpressionBuilder.For(entityType)
            .Use('UserId', ParameterTypeEnum.String)
            .Equal(userId)
            .Build().AsExpression;
}

export function getExpressionBuilderByGroupId(entityType: ApiEntityTypesEnum, groupId) {
    return FilterExpressionBuilder.For(entityType)
            .Use('GroupId', ParameterTypeEnum.String)
            .Equal(groupId)
            .Build().AsExpression;
}
