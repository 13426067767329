import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiActionsEnum } from '@constants/enums/api-actions.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { environment } from '@env/environment';
import { AutoIncrementingIdentifierDirective } from '@modules/messaging/baseClasses/AutoIncrementingIdentifier';
import { CacheInvalidationEngine } from '@services/core/CacheInvalidationEngine.service';
import { EndPoint } from './endPoint.model';

/** The ApiTemplateManager is the keeper of all registered
 * EndPoints according to the processing of the Swagger file
 * generated at the start of the application session.
 */
@Injectable({
    providedIn: 'root',
})
export class ApiTemplateManager extends AutoIncrementingIdentifierDirective { // implements Resolve<Promise<any>> {
    public static TOKEN_START = '{';
    public static TOKEN_END = '}';

    public static DiscoveredEndPoints: Map<string, EndPoint[]> = new Map<string, EndPoint[]>();

    private httpHandler: HttpClient;

    // resolve() {
    //     this.logCustom(LOGGING.HEADERS.APIFACTORY, 'loading the endpoints.....');
    //     return this.processSwaggerFile();
    // }
    /** This method will call out to the API server and
     * request a Swagger Json file which defines all available
     * endpoints.  It will then parse the file and create usable
     * registrations of those endpoints.
     */
    public async processSwaggerFile(): Promise<void> {

        if (ApiTemplateManager.DiscoveredEndPoints.size > 0) {
            return;
        }

        if (!this.httpHandler) {
            this.httpHandler = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
        }

        const swaggerUrl = environment.jjkellerPortalApiRootUrl + 'swagger/v1/swagger.json';

        let hdrs = new HttpHeaders();
        hdrs = hdrs.set('Content', 'application/json');
        hdrs = hdrs.append('Content-Type', 'application/json');
        const json = await this.httpHandler.get(swaggerUrl).toPromise(); // .subscribe((json) => {
        ApiTemplateManager.extractSwaggerEndpoints(json);
        // });
    }

    /** This method will return all the registerd Endpoints for
     * a given Entity Type.  It is then up to the caller to decide
     * which verb and template is correct for their use.
     */
    public async getEndPointsForEntity(entityType: ApiEntityTypesEnum): Promise<EndPoint[]> {
        const entityTypeName = ApiEntityTypesEnum[entityType];

        await this.processSwaggerFile();

        if (ApiTemplateManager.DiscoveredEndPoints.has(entityTypeName) === false) {
            throw new Error(`No such Entity Type ${entityTypeName} has been registered!`);
        }

        return ApiTemplateManager.DiscoveredEndPoints.get(entityTypeName);
    }

    /** This method will take the JSON from a Swagger file and parse it
     * into path records which are then used to construct a collection of
     * verb isolated - Entity Type endpoints.
     */
    private static extractSwaggerEndpoints(swagger: any): void {
        if (!swagger) {
            throw new Error('no endpoint data was pulled from the server, application will not run!');
        }

        // this will hold a list of all the identified endpoints which will be
        // written out to the console as code for an enum.
        const identifiedEndpoints = new Array<string>();

        const epStorage = new Map<string, EndPoint[]>();
        const pathing = swagger.paths;
        for (const path in pathing) {
            const swaggerEntry = pathing[path];

            // only include the type once
            const typeFound = ApiTemplateManager.extractTypeFromEmpointPath(path);
            if (typeFound) {
                const alreadyFound = identifiedEndpoints.includes(typeFound);
                if (alreadyFound === false) {
                    identifiedEndpoints.push(typeFound);
                }
            }
            // Determine what the entity is we are processing then create an entry for it
            let entityAsString = swaggerEntry.get
                ? swaggerEntry.get.tags
                : swaggerEntry.put
                    ? swaggerEntry.put.tags
                    : swaggerEntry.post
                        ? swaggerEntry.post.tags
                        : swaggerEntry.delete
                            ? swaggerEntry.delete.tags
                            : undefined;

            // Create the storage for it here
            const matchedEntity = ApiEntityTypesEnum[entityAsString];
            if (matchedEntity) {
                entityAsString = matchedEntity;
            }

            if (epStorage.has(entityAsString) === false) {
                epStorage.set(entityAsString, new Array<EndPoint>());
            }

            const endpointArray = epStorage.get(entityAsString);

            if (swaggerEntry.get) {
                endpointArray.push(new EndPoint(path, swaggerEntry.get, ApiActionsEnum.RETRIEVE));
            }
            if (swaggerEntry.post) {
                endpointArray.push(new EndPoint(path, swaggerEntry.post, ApiActionsEnum.CREATE));
            }
            if (swaggerEntry.put) {
                endpointArray.push(new EndPoint(path, swaggerEntry.put, ApiActionsEnum.UPDATE));
            }
            if (swaggerEntry.delete) {
                endpointArray.push(new EndPoint(path, swaggerEntry.delete, ApiActionsEnum.DELETE));
            }
        }

        // --------------------------------------------
        // build an output of the ApiEntityTypesEnum
        // --------------------------------------------
        /* eslint-disable no-useless-escape */
        let enumEntry = `export enum ApiEntityTypesEnum {
            \n\tOVERRIDE = \'OVERRIDE\',
            \n\tUNKNOWN = \'__UNKNOWN__\',
            \n\tSecurity = \'Security\',\n`;
        /* eslint-enable no-useless-escape */

        identifiedEndpoints.forEach((entry) => {
            if (entry) {
                enumEntry += `\t${entry} = '${entry}',\n`;
            }
        });
        enumEntry += '};';

        // ---------------------
        // Save found endpoints
        // ---------------------
        ApiTemplateManager.DiscoveredEndPoints = epStorage;
    }

    private static extractTypeFromEmpointPath(path: string): string {
        const startMoniker = '{userId}/';
        const start = path.indexOf(startMoniker);

        // trim the path to the start
        path = path.substring(start + startMoniker.length);

        const ending = path.indexOf('/');
        if (ending === -1) {
            return path;
        }
        path = path.substr(0, ending);

        return path.endsWith('}') ? '' : path;
    }

    constructor(private cacheService: CacheInvalidationEngine) {
        super();
    }
}
