
<div class="modal fade" id="profileModal" tabindex="-1" role="dialog" aria-labelledby="profileModalHeader"
  data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="profileModalHeader">My Profile</h5>
      </div>
      <div class="modal-body">
        <profile-form *ngIf="showForm" (profileSaved)="hideModal()"></profile-form>
      </div>
    </div>
  </div>
</div>
