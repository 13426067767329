import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { SplitioService } from '@services/splitio.service';
import { isNullOrUndefined, isValidArray } from '@utilities/helpers';

@Component({
    selector: 'jjk-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnDestroy, AfterViewInit {
    selectionValue: any | any[] = [];
    isDisableSelectValue: string;
    selectionAfterGroupClick: any[] = [];
    manuallyUpdate = false;
    _subFormId;
    isAllSelected: boolean;
    @ViewChild('ngSelect') rootElement;
    @Output() footerButtonClicked = new EventEmitter<any[]>();
    @Output() selectionAdded = new EventEmitter<any[]>();
    @Output() selectionRemoved = new EventEmitter<any[]>();
    @Output() selectionChange = new EventEmitter<any[]>();
    @Output() selectedValuesChange = new EventEmitter<any[]>();
    @Output() clear = new EventEmitter<any[]>();
    @Output() close = new EventEmitter<any[]>();
    @Input()
    get subFormId() {
        return this._subFormId;
    }
    set subFormId(val) {
        if (val !== undefined) {
            this._subFormId = `#${val}`;
        }
    }
    @Input()
    get selection() {
        return this.selectionValue;
    }
    set selection(val) {
        if (this.selectionValue !== val) {
            let ignoreVal = false;
            if (this.options) {
                if (val && isValidArray(val) && this.multiple && !this.searchViaApi) {
                    let option: any;
                    if (this.selectionValue?.length > val?.length) {
                        option = this.options.filter(option => option.id === this.selectionValue?.filter(item => !val?.includes(item))[0])[0];
                    }
                    else {
                        option = this.options.filter(option => option.id === val.filter(item => !this.selectionValue?.includes(item))[0])[0];
                    }

                    if (option && (option.hasChildren || option.parent !== undefined)) {
                        this.updateRelated(option, val.includes(option.id));
                        ignoreVal = true;
                    }
                }

                if (!ignoreVal) {
                    this.selectionValue = val;
                }

                this.selectionChange.emit(this.selectionValue);
            }
            else {
                this.selectionValue = val;
            }
        }
        if (this.isDisabled && this.selectionValue?.length > 0) {
            this.isDisableSelectValue = (this.options) ? this.options.find((x) => x.id === this.selectionValue)?.displayText : '';
        }
        this.isAllSelected = this.selectionValue?.length === this.options?.length;
    }
    @Input() searchable = true;
    @Input() options: any[];
    @Input() optionsTypeAhead: any[];
    @Input() multiple = false;
    @Input() placeholderText = '';
    @Input() typeToSearchText = '';
    @Input() groupBy = '';
    @Input() footerButton: boolean;
    @Input() isGrouped = false;
    @Input() isDisabled = false;
    @Input() showDisable = false;
    @Input() isExtraLarge = false;
    @Input() searchViaApi = false;
    @Input() showLoading = false;
    @Input() typeAheadInput$: Subject<string>;
    @Input() defaultValue = null;
    private resetSubscription: any;
    @Input() resetControl: Observable<void>;
    @Input() footerButtonText = 'Add New';
    @Input() selectAllOption = false;
    @Input() lockItems = false;
    @Input() skipCanAddNewSharedCheck = false;

    constructor(private splitioService: SplitioService) { }

    async ngOnInit() {
        this.footerButton = this.footerButton && (this.skipCanAddNewSharedCheck || LoggedInUserInfo.Instance.userInfo.canAddNew);
        if (!this.placeholderText) {
            this.placeholderText = this.multiple ? 'Select' : 'Select One';
        }
        if (this.resetControl) {
            this.resetSubscription = this.resetControl.subscribe(() => this.reset());
        }
        if (this.isDisabled && this.selectionValue?.length > 0) {
            this.isDisableSelectValue = (this.options) ? this.options.find((x) => x.id === this.selectionValue).displayText : '';
        }
    }

    ngAfterViewInit(): void {
        $('ng-select input').attr('autocomplete', 'off');
    }

    ngOnDestroy() {
        if (this.resetSubscription) { this.resetSubscription.unsubscribe(); }
    }

    reset() {
        setTimeout(() => {
            this.selection = this.defaultValue;
        }, 0);
    }
    clearClick() {
        this.clear.emit();
    }

    footerButtonClick() {
        this.footerButtonClicked.emit();
        this.rootElement.close();
    }

    selectAllClick(checked) {
        this.selection = checked ? this.options.map(item => item.id) : [];
    }

    onOptionChange(target) {
        if (this.multiple) {
            const option = this.options[+target.id.substring(5)];
            this.updateRelated(option, target.checked);
            this.selectionChange.emit(this.selectionValue);
        }
    }

    updateRelated(option: any, checked: boolean) {
        this.selectionValue = this.selectionValue ?? [];
        if (option.hasChildren) {
            if (checked) {
                this.selectionValue = [option.id, ...this.selectionValue, ...this.options.filter((item, i) => item.parent === option.id).map(item => item.id)];
            }
            else {
                this.selectionValue = this.selectionValue.filter(item => item !== option.id && !this.options.filter(item => item.parent === option.id).map(item => item.id).includes(item))
            }
        }
        if (option.parent !== undefined) {
            if (checked) {
                if (!this.options.filter(item => item.id !== option.id && item.parent === option.parent && (!this.selectionValue || !this.selectionValue.includes(item.id))).length) {
                    this.selectionValue = [option.id, option.parent, ...this.selectionValue];
                }
                else {
                    this.selectionValue = [option.id, ...this.selectionValue];
                }
            }
            else {
                this.selectionValue = this.selectionValue.filter(item => item !== option.parent && item !== option.id);
            }
        }
    }

    onAdd(value) {
        // group added - need to manually push all items in the group to the selected array
        if (value && value[this.groupBy] && !value['id']) {
            this.selectionAfterGroupClick = this.selection
                .concat(this.options
                    .filter((option) => option[this.groupBy] === value[this.groupBy])
                    .map((groupMatch) => groupMatch.id));

            // we may have duplicate ids as a result of group and singular adds being seperate,
            // so clean those up before updating parent components
            this.selectionAfterGroupClick = this.getUniqueElements(this.selectionAfterGroupClick);
            this.manuallyUpdate = true;
        } else {
            this.manuallyUpdate = false;
        }
    }

    onChange(value) {
        if (!isNullOrUndefined(value) && Array.isArray(value)){
            this.selectedValuesChange.emit(value);
            this.rootElement.searchTerm= '';
        }

        if (this.manuallyUpdate) {
            this.selection = this.selectionAfterGroupClick;
            this.manuallyUpdate = false;
        }
    }

    onRemove($event) {
        const { children, value } = $event;

        // group removed - need to manually remove all items in the group from the selected array
        if (children) {
            const groupToRemove = value.groupName;
            const idsOfGroupToRemove = this.options
                .filter((item) => item.groupName === groupToRemove)
                .map((item) => item.id);

            this.selectionAfterGroupClick = this.selection
                .filter((selectedId) => !idsOfGroupToRemove.includes(selectedId));
            this.manuallyUpdate = true;
        } else {
            // single item removed
            const idToRemove = value.id;
            this.selection = this.selection.filter((id) => id !== idToRemove);
            this.manuallyUpdate = false;
        }
    }

    onFocusDisabled($event) {
        // We're going to prevent any changes when the user puts the focus if the element is disabled
        if (this.isDisabled) {
            $event.preventDefault();
            $event.stopPropagation();
        }
    }

    defaultSearch(term, item) {
        term = term.toLocaleLowerCase();
        return item.displayText.toLocaleLowerCase().indexOf(term) > -1;
    }

    searchMultiple(term, item) {
        term = term.toLocaleLowerCase();
        const displayText = item.displayText.toLocaleLowerCase().indexOf(term);
        let groupName = null;
        if (item.groupName) {
            groupName = item.groupName.toLocaleLowerCase().indexOf(term);
        }
        return displayText > -1 || (groupName ? groupName > -1 : null);
    }

    private getUniqueElements(arr: any[]): any[] {
        const seen = {};
        return arr.filter((item) => {
            return seen.hasOwnProperty(item) ? false : (seen[item] = true);
        });
    }

    onClose() {
        this.close.emit();
    }
}
