import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function ValidateAllowedFileExtension(extensionList: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control || !control.value) { return; } // Nothing to validate

        const allowedExtensionsList = extensionList.split(',');

        const filename: string = control.value;

        const fileextension = (filename).substr(filename.lastIndexOf('.'));

        const allowedExtensions = allowedExtensionsList.map((e) => e.replace('.', ''));

        return allowedExtensionsList.indexOf(fileextension.toLowerCase()) > -1
        ? null
        : { invalidFileExtension: true, allowedExtensions };
    };
}
