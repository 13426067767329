import * as moment from 'moment';
import { FilterType } from '@constants/advanced-filter/filter-type.enum';
import { FilterOperatorEnum } from '@models/advanced-filter/filter-operator.enum';
import { AgGridPredefinedState } from '../constants/enums/ag-grid-predefined-state.enum';

export function getFilterQueryString(columnName: string, fieldName: string, value: string, operator = 'Contains'): any {
    return { filters: `[{"column": "${columnName}", "operator": "${operator}", "fieldName": "${fieldName}", "filterValue": "${value}"}]` };
}

export function getAGGridPredefinedStateQueryString(predefinedState: AgGridPredefinedState): any {
    return { predefinedState: predefinedState.toString() };
}

export function formatDateForFilters(theDate: moment.Moment): string {
    return theDate.format('l');
}

export function getFilterOperatorsByType(filterType: FilterType): FilterOperatorEnum[] {
    let operators: FilterOperatorEnum[];
    switch (filterType) {
        case FilterType.date:
            operators = [FilterOperatorEnum.Equals, FilterOperatorEnum.GreaterThan, FilterOperatorEnum.LessThan, FilterOperatorEnum.IsBetween];
            break;
        case FilterType.bool:
            operators = [FilterOperatorEnum.Equals];
            break;
        case FilterType.autocomplete:
        case FilterType.nestedAutocomplete:
            operators = [FilterOperatorEnum.Contains, FilterOperatorEnum.DoesNotContain];
            break;
        case FilterType.select:
            operators = [FilterOperatorEnum.Equals, FilterOperatorEnum.DoesNotEqual];
            break;
        case FilterType.multiselect:
            operators = [FilterOperatorEnum.Contains, FilterOperatorEnum.DoesNotContain];
            break;
        case FilterType.number:
            operators = [FilterOperatorEnum.Equals, FilterOperatorEnum.GreaterThan, FilterOperatorEnum.LessThan, FilterOperatorEnum.IsBetween];
            break;
        default:
            operators = [FilterOperatorEnum.Contains, FilterOperatorEnum.DoesNotContain];
            break;
    }
    return operators;
}
