import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApplicationInsightsService } from '@services/application-insights.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
    handleError = (err: any): void => {
        const appInsights = ApplicationInsightsService.Instance;
        try {
            if (environment.environmentName !== 'environmentProd' && (typeof err !== 'undefined') && (typeof appInsights !== 'undefined')) {
                appInsights.exception(err);
                console.log(err?.toString());
            } else if ((typeof err !== 'undefined') && (typeof appInsights !== 'undefined')) {
                appInsights.exception(err);
            }
        } catch (e) {
            console.log('Error Handling General Exception: ' + e.toString());
        }
    }
}
