import { Component } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { ACTIONS, TOPICS } from '@constants/messages.constants';
import { environment } from '@env/environment';
import { EnableMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageTypeEnum } from '@modules/messaging/baseClasses/MessageTypeEnum';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { UserInterfaceMessage } from '@modules/messaging/types/Messages/userInterfaceMessage';

@Component({
  selector: 'print-page',
  templateUrl: './print-page.component.html',
})
export class PrintPageComponent extends EnableMessageProcessingDirective{
  private _isReportView  = false;
  get isReportView(): boolean {
      return this._isReportView;
  }
  set isReportView(value: boolean) {
    this._isReportView = value;
    if (this._isReportView) {
        $('div.description').addClass('report-view');
    } else {
        $('div.description').removeClass('report-view');
    }
  }

  constructor(private router: Router, mb: MessageBusService) {
      super(mb);

      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
            this.isReportView = false;
        }
      });
    }

    protected configureMessageBus(builder: IMsgProcessingReg): void {
        builder.add()
            .inBoundDataTopic(TOPICS.PAGES.REPORT_PAGE)
            .listenForMessageType(MessageTypeEnum.UserInterface)
            .withAction(ACTIONS.UI.PAGES.SHOW)
            .thenCallFunction((m: UserInterfaceMessage) => {
                this.isReportView = true;
            })
            .withAction(ACTIONS.UI.PAGES.HIDE)
            .thenCallFunction((m: UserInterfaceMessage) => {
                this.isReportView = false;
            });
    }
}
