<div *ngIf="isPopulated">
    <div *ngIf="!isShowingResult">
        <btn class="btn-link" (click)="showResult()">Show Result</btn>
        <i *ngIf="isLoading" class="fa-solid fa-spinner fa-lg fa-spin"></i>
    </div>
    <div *ngIf="isShowingResult">
        {{result}}
        <btn class="btn-link" (click)="hideResult()">Hide</btn>
    </div>
</div>
<div *ngIf="!isPopulated">
    <span class="italic">(not populated)</span>
</div>

