// AUTO-GENERATED CODE
//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { EmploymentDataOshaSubmissionRequired } from './employmentdataoshasubmissionrequired';
import { Location } from './location';
import { User } from './user';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { IValidatorInfo, ValidatorInfo } from '@models/validations/validatorInfo';
import { IModelRecord, IModelAudit, IModelBase } from '@models/interfaces/entity-interfaces';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
export class NaicsCode  {

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
        createdByUserId: string;
        createdDate: Date;
        id: string;
        impersonationCreatedByUserId?: string;
        impersonationModifiedByUserId?: string;
        industryDART?: number;
        industryRI?: number;
        isDeleted: boolean;
        majorCodeId?: number;
        modifiedByUserId?: string;
        modifiedDate?: Date;
        naicsCode1: string;
        naicsText: string;
        nAICSVersion: number;
        requiredToSubmit: number;
        sicCode: string;
        sicText: string;
        groupId: string;
        permissions: number;

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    constructor(json?: Partial<NaicsCode>) {
        this.createdByUserId = json && json.createdByUserId;
        this.createdDate = (!json || json.createdDate === undefined) ? null : (json.createdDate === null ? new Date() : json.createdDate);
        this.id = json && json.id;
        this.impersonationCreatedByUserId = json && json.impersonationCreatedByUserId;
        this.impersonationModifiedByUserId = json && json.impersonationModifiedByUserId;
        this.industryDART = json && json.industryDART;
        this.industryRI = json && json.industryRI;
        this.isDeleted = json && json.isDeleted;
        this.majorCodeId = json && json.majorCodeId;
        this.modifiedByUserId = json && json.modifiedByUserId;
        this.modifiedDate = (!json || json.modifiedDate === undefined) ? null : json.modifiedDate;
        this.naicsCode1 = json && json.naicsCode1;
        this.naicsText = json && json.naicsText;
        this.nAICSVersion = json && json.nAICSVersion;
        this.requiredToSubmit = json && json.requiredToSubmit;
        this.sicCode = json && json.sicCode;
        this.sicText = json && json.sicText;
        this.groupId = json && json.groupId;
        this.permissions = json && json.permissions || 0;

    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validateInfo(fieldName: string): IValidatorInfo {
        switch (fieldName) {
        case 'createdByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'createdDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'id': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationCreatedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationModifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'industryDART': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'number',
            };
        }
        case 'industryRI': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'number',
            };
        }
        case 'isDeleted': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'majorCodeId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'number',
            };
        }
        case 'modifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'modifiedDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'naicsCode1': {
            return {
                required: false,
                minLength: undefined, maxLength: 255,
                type: 'string',
            };
        }
        case 'naicsText': {
            return {
                required: false,
                minLength: undefined, maxLength: 500,
                type: 'string',
            };
        }
        case 'nAICSVersion': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'number',
            };
        }
        case 'requiredToSubmit': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'number',
            };
        }
        case 'sicCode': {
            return {
                required: false,
                minLength: undefined, maxLength: 255,
                type: 'string',
            };
        }
        case 'sicText': {
            return {
                required: false,
                minLength: undefined, maxLength: 500,
                type: 'string',
            };
        }
            default: return { required: false, minLength: undefined, maxLength: undefined, type: undefined };
        }
    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validate(skip: string[] = []): [number, number, string[]?] {
        let count = 0;
        const errorFields = [];
        if (!skip.includes('createdByUserId') && ValidatorInfo.validateString(this.createdByUserId, this._validateInfo('createdByUserId'))) {
            count++;
            errorFields.push('createdByUserId');
        }
        if (!skip.includes('createdDate') && ValidatorInfo.validateDate(this.createdDate, this._validateInfo('createdDate'))) {
            count++;
            errorFields.push('createdDate');
        }
        if (!skip.includes('id') && ValidatorInfo.validateString(this.id, this._validateInfo('id'))) {
            count++;
            errorFields.push('id');
        }
        if (!skip.includes('impersonationCreatedByUserId') && ValidatorInfo.validateString(this.impersonationCreatedByUserId, this._validateInfo('impersonationCreatedByUserId'))) {
            count++;
            errorFields.push('impersonationCreatedByUserId');
        }
        if (!skip.includes('impersonationModifiedByUserId') && ValidatorInfo.validateString(this.impersonationModifiedByUserId, this._validateInfo('impersonationModifiedByUserId'))) {
            count++;
            errorFields.push('impersonationModifiedByUserId');
        }
        if (!skip.includes('industryDART') && ValidatorInfo.validateNumber(this.industryDART, this._validateInfo('industryDART'))) {
            count++;
            errorFields.push('industryDART');
        }
        if (!skip.includes('industryRI') && ValidatorInfo.validateNumber(this.industryRI, this._validateInfo('industryRI'))) {
            count++;
            errorFields.push('industryRI');
        }
        if (!skip.includes('isDeleted') && ValidatorInfo.validateString(this.isDeleted, this._validateInfo('isDeleted'))) {
            count++;
            errorFields.push('isDeleted');
        }
        if (!skip.includes('majorCodeId') && ValidatorInfo.validateNumber(this.majorCodeId, this._validateInfo('majorCodeId'))) {
            count++;
            errorFields.push('majorCodeId');
        }
        if (!skip.includes('modifiedByUserId') && ValidatorInfo.validateString(this.modifiedByUserId, this._validateInfo('modifiedByUserId'))) {
            count++;
            errorFields.push('modifiedByUserId');
        }
        if (!skip.includes('modifiedDate') && ValidatorInfo.validateDate(this.modifiedDate, this._validateInfo('modifiedDate'))) {
            count++;
            errorFields.push('modifiedDate');
        }
        if (!skip.includes('naicsCode1') && ValidatorInfo.validateString(this.naicsCode1, this._validateInfo('naicsCode1'))) {
            count++;
            errorFields.push('naicsCode1');
        }
        if (!skip.includes('naicsText') && ValidatorInfo.validateString(this.naicsText, this._validateInfo('naicsText'))) {
            count++;
            errorFields.push('naicsText');
        }
        if (!skip.includes('nAICSVersion') && ValidatorInfo.validateNumber(this.nAICSVersion, this._validateInfo('nAICSVersion'))) {
            count++;
            errorFields.push('nAICSVersion');
        }
        if (!skip.includes('requiredToSubmit') && ValidatorInfo.validateNumber(this.requiredToSubmit, this._validateInfo('requiredToSubmit'))) {
            count++;
            errorFields.push('requiredToSubmit');
        }
        if (!skip.includes('sicCode') && ValidatorInfo.validateString(this.sicCode, this._validateInfo('sicCode'))) {
            count++;
            errorFields.push('sicCode');
        }
        if (!skip.includes('sicText') && ValidatorInfo.validateString(this.sicText, this._validateInfo('sicText'))) {
            count++;
            errorFields.push('sicText');
        }
        return [count, 8, errorFields];
    }
}
