<section [hidden]="!show">
    <div class="card-deck">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">Safety Management Process <span>(based on ISO 45001)</span> </h3>
                <div class="flex-row">
                    <section class="design-process-section" id="process-tab">
                        <div class="container">
                            <div class="row" style="margin:0 auto;">
                                <div class="col-lg-12">
                                    <!-- design process steps-->
                                    <!-- Nav tabs -->
                                    <ul class="nav nav-tabs process-model more-icon-preocess">
                                        <li [ngClass]="selectedShieldId === 1 ? 'active' : selectedShieldId < 1 ? '' : 'visited'">
                                            <a (click)="onClick(1)">
                                                <i class="custom-plans-shield" aria-hidden="true"></i>
                                                <p>Plan</p>
                                            </a>
                                        </li>
                                        <li [ngClass]="selectedShieldId === 2 ? 'active' : selectedShieldId < 2 ? '' : 'visited'">
                                            <a (click)="onClick(2)">
                                                <i class="custom-tasks-shield" aria-hidden="true"></i>
                                                <p>Do</p>
                                            </a>
                                        </li>
                                        <li [ngClass]="selectedShieldId === 3 ? 'active' : selectedShieldId < 3 ? '' : 'visited'">
                                            <a (click)="onClick(3)">
                                                <i class="custom-audits-shield" aria-hidden="true"></i>
                                                <p>Check</p>
                                            </a>
                                        </li>
                                        <li [ngClass]="selectedShieldId === 4 ? 'active' : selectedShieldId < 4 ? '' : 'visited'">
                                            <a (click)="onClick(4)">
                                                <i class="custom-training-shield" aria-hidden="true"></i>
                                                <p>Act</p>
                                            </a>
                                        </li>
                                    </ul>
                                    <!-- end design process steps-->
                                    <!-- Tab panes -->
                                    <div class="tab-content">
                                        <div [ngClass]="selectedShieldId === 1 ? 'tab-pane active' : 'tab-pane'">
                                            <div class="design-process-content">
                                                <h3 class="semi-bold">Plan</h3>
                                                <p>Assess hazards, risks, and opportunities; establish EHS objectives; and create plans and procedures.</p>
                                                <a [routerLink]="'/plans_policies'" class="btn btn-primary" [state]="{tabsAndFilter: 'reset'}" auth authFeature="pnp">View Plans</a>
                                            </div>
                                        </div>
                                        <div [ngClass]="selectedShieldId === 2 ? 'tab-pane active' : 'tab-pane'">
                                            <div class="design-process-content">
                                                <h3 class="semi-bold">Do</h3>
                                                <p>Implement your EHS processes, procedures, and plans.</p>
                                                <a [routerLink]="'/tasks'" class="btn btn-primary" [state]="{tabsAndFilter: 'reset'}" auth authFeature="task">View Tasks</a>
                                            </div>
                                        </div>
                                        <div [ngClass]="selectedShieldId === 3 ? 'tab-pane active' : 'tab-pane'">
                                            <div class="design-process-content">
                                                <h3 class="semi-bold">Check</h3>
                                                <p>Monitor your EHS program, keep records, and generate reports.</p>
                                                <a [routerLink]="'/audits/checklists'" class="btn btn-primary" [state]="{tabsAndFilter: 'reset'}" auth authFeature="audit">View Checklists</a>
                                            </div>
                                        </div>
                                        <div [ngClass]="selectedShieldId === 4 ? 'tab-pane active' : 'tab-pane'">
                                            <div class="design-process-content">
                                                <h3 class="semi-bold">Act</h3>
                                                <p>Learn from near misses or incidents, implement corrective actions, continually improve your program.</p>
                                                <a [routerLink]="'/training'" class="btn btn-primary" [state]="{tabsAndFilter: 'reset'}" auth authFeature="training">View Training</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>