import { FilterType } from '@constants/advanced-filter/filter-type.enum';

export class Filter {
    // The current column
    column: string;
    // columns: FilterColumn[];

    // The current operator
    operator: string;

    // Operators available for this Filter
    operators: string[];

    // The type of filter: date, dateRange, autocomplete, nestedAutocomplete, etc
    filterType: FilterType;

    // Current value of the filter
    filterValue: any;

    // List of possible values for the filter
    filterValues: any;

    fieldName: string;

    toFilterValue: any;

    constructor(
        column = 'Title',
        operator = '',
        fieldName = '') {
        this.column = column;
        this.operator = operator;
        this.fieldName = fieldName;
    }
}
