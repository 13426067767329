<ng-container [ngSwitch]="fileType">
    <i *ngSwitchCase="'application/pdf'" class="fas fa-file-pdf file-type"></i>
    <i *ngSwitchCase="'application/rtf'" class="fas fa-file-word file-type"></i>
    <i *ngSwitchCase="'application/msword'" class="fas fa-file-word file-type"></i>
    <i *ngSwitchCase="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" class="fas fa-file-word file-type"></i>
    <i *ngSwitchCase="'application/vnd.ms-excel'" class="fas fa-file-excel file-type"></i>
    <i *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" class="fas fa-file-excel file-type"></i>
    <i *ngSwitchCase="'text/tab-separated-values'" class="fas fa-file-excel file-type"></i>
    <i *ngSwitchCase="'image/gif'" class="fas fa-file-image file-type"></i>
    <i *ngSwitchCase="'image/jpeg'" class="fas fa-file-image file-type"></i>
    <i *ngSwitchCase="'image/png'" class="fas fa-file-image file-type"></i>
    <i *ngSwitchCase="'text/csv'" class="fas fa-file-spreadsheet file-type"></i>
    <i *ngSwitchCase="'application/zip'" class="fas fa-file-archive file-type"></i>
    <i *ngSwitchCase="'application/vnd.ms-powerpoint'" class="fas fa-file-powerpoint file-type"></i>
    <i *ngSwitchCase="'application/vnd.openxmlformats-officedocument.presentationml.presentation'" class="fas fa-file-powerpoint file-type"></i>
    <i *ngSwitchCase="'text/plain'" class="fas fa-file file-type"></i>
    <i *ngSwitchCase="'video/mp4'" class="fas fa-video"></i>
    <i *ngSwitchCase="'pdf'" class="fas fa-file-pdf file-type"></i>
    <i *ngSwitchCase="'doc'" class="fas fa-file-word file-type"></i>
    <i *ngSwitchCase="'docx'" class="fas fa-file-word file-type"></i>
    <i *ngSwitchCase="'pptx'" class="fas fa-file-powerpoint file-type"></i>
    <i *ngSwitchCase="'mp4'" class="fad fa-video"></i>
    <i *ngSwitchCase="'csv'" class="fas fa-file-spreadsheet file-type"></i>
    <i *ngSwitchCase="'zip'" class="fas fa-file-archive file-type"></i>
    <i *ngSwitchCase="'gif'" class="fas fa-file-image file-type"></i>
    <i *ngSwitchCase="'jpeg'" class="fas fa-file-image file-type"></i>
    <i *ngSwitchCase="'jpg'" class="fas fa-file-image file-type"></i>
    <i *ngSwitchCase="'png'" class="fas fa-file-image file-type"></i>
    <i *ngSwitchCase="'pdfAlreadyAdded'" class="file-type pdf-product-list-added"></i>
    <i *ngSwitchDefault class="fas fa-file file-type"></i>
</ng-container>