
<div class="quickActions" (window:resize)="onResize()">
    <div [ngStyle]="{'justify-content': this.justifyContent}" class="quickActionsContainer">
        <ng-container *ngFor="let qa of quickActions; let i = index">
            <div class="quickActionsCard" [ngClass]="_selection?.includes(qa) ? 'quickActionSelected' : ''" (click)="onActionClick(qa)">{{qa}}
                <div *ngIf="loading" class="quickActionsBadge white"><i class="fas fa-spinner fa-spin"></i></div>
                <div *ngIf="!loading" class="quickActionsBadge">{{_quickActionCounts[i]}}</div>
            </div>
        </ng-container>
    </div>
    <div class="large-filter-mobile">
        <ng-select #ngSelect [multiple]="true" [items]="quickActions" [placeholder]="loading ? 'Loading...' : ''" [(ngModel)]="selection" [disabled]="loading" (add)="update($event)" (remove)="update($event)">
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" >
                <div class="d-flex">
                  {{item}}<div *ngIf="!loading" class="quickActionsBadge dropdown">{{_quickActionCounts[index]}}</div>
                </div>
              </ng-template>
        </ng-select>
    </div>
</div>
