import { Component, Input } from '@angular/core';

@Component({
  selector: 'jjk-tab-item',
  template: ``,
})
export class TabItemComponent {

  @Input() displayText = '';
  @Input() showEmpty = false;
  @Input() priority = 99;
  @Input() searchFields: string[];

  constructor() {
  }
}
