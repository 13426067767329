import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { ConvertApiErrorToAlertMessage } from '@pages/auth/helpers';
import { AuthService } from '@services/auth.service';
import { NavigationService } from '@services/navigation.service';
import { SplitioService } from '@services/splitio.service';

@Component({
    selector: 'jjkp-forgot-password',
    styleUrls: ['./forgot-password.component.scss'],
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
    showEmail: boolean;
    showPasswordReset: boolean;
    showVerificationCode: boolean;

    emailAddress: string;
    newPassword: string;
    verificationCode: string;
    expiredTokenReset: boolean;
    b2c2OktaToggle = false;
    showAlert: boolean;
    alertText: string;
    isEmailSent: boolean;
    showResendLink: boolean;
    hidePasswordForm: boolean;
    isProcessingRequest: boolean;
    resendText = "Resend Verification Code";

    constructor(private navigator: NavigationService,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private splitioService: SplitioService) {
    }

    async ngOnInit(): Promise<void> {
        this.authService.redirectLoggedInUser();
        this.showEmail = true;
        this.isEmailSent = false;
        this.showResendLink = false;
        this.b2c2OktaToggle = (await this.splitioService.getToggle(TOGGLES.FORGOTPASSWORD_B2C_CREATE_OKTA)) === TREATMENT.ON;
        if (this.activatedRoute.snapshot.queryParams['v'] && !this.hidePasswordForm) {
            this.verificationCode = decodeURIComponent(this.activatedRoute.snapshot.queryParams['v']);
            if (this.verificationCode) {
                this.openVerificationCode();
            }
        }
    }

    openVerificationCode(event?) {
        if (event) {
            this.emailAddress = event.email;
            this.isEmailSent = true;
            this.showAlert = false;
        }
        this.showVerificationCode = true;
        this.showEmail = false;
    }

    resetOpenVerificationCodeShowEmailMsg(alert?: any) {
        this.showAlert = false;
        this.showPasswordReset = false;
        this.showResendLink = false;

        if (alert) {
            this.showEmail = true;
            this.alertText = alert;
            this.showAlert = true;
        }
        else {
            this.openVerificationCode();
            this.isEmailSent = true;
        }
    }

   async openCreateNewPassword(event) {
        this.showVerificationCode = false;
        this.expiredTokenReset = false;
        this.hidePasswordForm = false;
        this.showPasswordReset = true;
    }

    passwordSuccessfullyUpdated() {
        this.navigator.navigateTo(AuthRoutes.Login.urlRoute);
    }

    setAlert(message) {
        if (message === undefined || message.endsWith('could not be verified')) {
            message = "Verification Code is invalid or expired. Please select Resend Verification Code."
        }
        this.alertText = message ;
        this.showAlert = true;
        this.showResendLink = true;
    }

    clearAlert() {
        this.alertText = null;
        this.showAlert = false;
    }

    setVerificationCode(code: any) {
        this.verificationCode = code;
    }

    restartForgotPassword() {
        let valueToResend = this.emailAddress;
        if (!valueToResend) {
            valueToResend = this.verificationCode;
        }
        if (valueToResend != undefined) {
            this.isProcessingRequest = true;
            this.authService.requestResetForgottenPasswordEmail(valueToResend)
                .then(() => this.resetOpenVerificationCodeShowEmailMsg())
                .catch((error) => this.resetOpenVerificationCodeShowEmailMsg(ConvertApiErrorToAlertMessage(error)))
                .finally(() => this.isProcessingRequest = false);
        }
        else {
            //Shouldn't get here unless something went majorly wrong
            this.resetOpenVerificationCodeShowEmailMsg("Verification Code is invalid or expired.");
        }
    }
    resendVerificationCode() {
        if(this.emailAddress) {
            this.authService.resetPasswordEmail(this.emailAddress);
            this.expiredTokenReset = true;  
            this.showResendLink = false;
            this.restartForgotPassword();
            this.isEmailSent = true;
            this.showAlert = false;
            this.hidePasswordForm = true;
        }
    }
    hideErrorLabel() {
        this.expiredTokenReset = true;  
        this.showResendLink = false;
        this.restartForgotPassword();
        this.isEmailSent = true;
        this.showAlert = false;
        this.hidePasswordForm = true;
    }
}
