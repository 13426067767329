import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'jjk-datagrid-column-file-download',
    templateUrl: 'datagrid-column-file-download.component.html',
})
export class DataGridColumnFileDownloadComponent {
    @Input() row: any;
    @Input() col: any;
    @Input() showDownload = true;
    @Output() openAsDownload = new EventEmitter<boolean>();

    openAsDownloadOrPreview(openAsDownload: boolean) {
        this.row.openAsDownload = openAsDownload;
        this.openAsDownload.emit(openAsDownload);
    }
}
