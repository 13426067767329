<div class="flex-container">
    <div class="flex-child">
        <h1 *ngIf="header">{{header}}</h1>
    </div>
    <div class="flex-child">
        <jjk-loader *ngIf="isProcessingRequest"></jjk-loader>
    </div>
</div>
<form [formGroup]="form" *ngIf="form" aria-label="Enter verification code">
    <div class="entry">
        <div id="verificationWrapper" class="entry-item">
            <label class="req form-label">
                Verification Code
            </label>
            <input appAutofocus type="text" id="authcode" name="Verification Code" title="Please provide the verification code that was emailed to you." maxlength="254" formControlName="verificationCode" aria-label="verificationCode" [class.field-error]="shouldShowErrors('verificationCode')">
            <div *ngIf="shouldShowErrors('verificationCode')" class="invalid-text">Please provide the verification code that was emailed to you.</div>
        </div>
        <save-button [innerText]="'Continue'" (onSave)="onFormSubmit()" [isDisabled]="isProcessingRequest"></save-button>
        <button [innerText]="'Cancel'" type="button" class="btn btn-secondary" (click)="onCancel()" [isDisabled]="isProcessingRequest"></button>
        <button *ngIf="!hideResend"  [innerText]="resendText" (click)="resend()" id="resendCode">resendText.resendText</button>
    </div>
</form>
