import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class MenuService {

  isSideNavOpen = false;
  isImpersonation = false;

  constructor() { }

  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() refresh: EventEmitter<boolean> = new EventEmitter();
  toggleSideNav() {
    this.isSideNavOpen = !this.isSideNavOpen;
    this.change.emit(this.isSideNavOpen);
  }

}
