export enum MedicalVaccinationStatusEnum {
    Vaccinated = '0a92f3aa-d889-473d-9571-c930a7727e87',
    Unvaccinated = '55d784ff-7faf-435f-9673-1a6a348068e0',
}
export const MedicalVaccinationStatusMap = new Map([
    [MedicalVaccinationStatusEnum.Vaccinated, 'Vaccinated'],
    [MedicalVaccinationStatusEnum.Unvaccinated, 'Unvaccinated'],
]);
// Remove the old code above when removing split MEDICAL_CP_40
export enum VaccinationStatus {
    Vaccinated = 'Vaccinated',
    Unvaccinated = 'Unvaccinated',
  }
