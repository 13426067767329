import { MessageType } from '../messageType';
import { Message } from './Message';

export class SystemEventMessage extends Message {
    // this is used during handler registration
    public static messageRegistrationKey: MessageType = 'SystemEventMessage';

    // this is used in message identification
    public get messageType(): MessageType { return SystemEventMessage.messageRegistrationKey; }

}
