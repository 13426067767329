import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { UserTypes } from '@constants/enums/user-type.enum';
import { ACTIONS } from '@constants/messages.constants';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { EnableMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { ApiFactory } from '@services/core/api-factory.class';
import { FeatureAuthService } from '@services/feature-auth.services';
import { ImpersonationService } from '@services/impersonation.service';

@Component({
  selector: 'layout-menu-options',
  templateUrl: './layout-menu-options.component.html',
  styleUrls: ['./layout-menu-options.component.scss'],
})
export class LayoutMenuOptionsComponent extends EnableMessageProcessingDirective implements OnInit{
  isProdOwnerType = LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.ProductOwner;
  isSdsManagement = LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.SdsMgmt;
  isRootLevel = LoggedInUserInfo.Instance.userInfo.isRootLevelAdmin;
  showSettingsMenuOption = true;
  @Output() public closeSideNav = new EventEmitter();

  constructor(private router: Router, mb: MessageBusService,
              public featureAuthService: FeatureAuthService,
              public impersonationService: ImpersonationService) {
    super(mb);
  }

  async ngOnInit() {
    const isAdmin = LoggedInUserInfo.Instance.userInfo.isRootLevelAdmin || LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.SAPAdmin || LoggedInUserInfo.Instance.userInfo.isAdmin;
    this.showSettingsMenuOption = isAdmin ? true : false;
  }

  protected configureMessageBus(builder: IMsgProcessingReg): void { }

  async logout() {
    if (LoggedInUserInfo.Instance.userInfo.impersonationId !== '' && LoggedInUserInfo.Instance.userInfo.impersonation && LoggedInUserInfo.Instance.userInfo.mainUser) {
      const impersonation = LoggedInUserInfo.Instance.userInfo.impersonation.find((e) => e.id === LoggedInUserInfo.Instance.userInfo.impersonationId);
      if (impersonation.impersonatingUserId === LoggedInUserInfo.Instance.userInfo.mainUser.id) {
        this.showModal('impersonationEndModal');
        return;
      }
    }
    const msg = this.msgBuilder.SystemEventMessage().usingHostSenderId().then.proceedWithoutData.assignAction(ACTIONS.AUTH.AUTH_REQUEST_LOGOUT).sendOn.systemTopic().build();
    await this.sendTopicMessage(msg);
    this.closeSideNavigation();
  }

  async Get401() {
    ApiFactory.retrieveEntity(ApiEntityTypesEnum.Security)
      .addRouteHint('Get401Unauthorized')
      .addSuccessHandler((d) => { console.log(d); })
      .addErrorHandler((e) => { console.log(e); }).buildAndSend();
  }

  closeSideNavigation() {
    this.closeSideNav.emit();
  }

  showModal(modalName: string) {
    ($('#' + modalName) as any).modal('show');
    this.closeSideNavigation();
  }

  impersonationId(): string {
    if (LoggedInUserInfo.Instance.userInfo && LoggedInUserInfo.Instance.userInfo.impersonationId !== '' && LoggedInUserInfo.Instance.userInfo.impersonation && LoggedInUserInfo.Instance.userInfo.mainUser) {
      const impersonation = LoggedInUserInfo.Instance.userInfo.impersonation.find((e) => e.id === LoggedInUserInfo.Instance.userInfo.impersonationId);
      if (impersonation && impersonation.impersonatingUserId === LoggedInUserInfo.Instance.userInfo.mainUser.id) {
        return 'end';
      }
      return 'allow';
    }
    return '';
  }

  async pauseImpersonation() {
    this.router.navigate(['/dashboard']);
    await this.impersonationService.successEndImpersonating(null);
  }
}
