import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'save-button',
  template: `<button class="btn btn-primary" (click)="onSaveClicked()" [disabled]="isDisabled" [type]="type">{{innerText}}<ng-content></ng-content></button>`,
})
export class SaveButtonComponent {
  @Input() innerText = 'Save';
  @Input() isDisabled = false;
  @Input() type = '';
  @Output() onSave = new EventEmitter();

  constructor() {
  }

  onSaveClicked() {
    this.onSave.emit();
  }
}
