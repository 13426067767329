<div *ngIf="!isDisabled">
  <div class="form-group" *ngIf="countryLabel" [class.form-group-error]="countryRequired && countryError">
    <label class="form-label" for="countryId" [class.req]="countryRequired">{{ countryLabel }}</label>
    <ng-select
      #ngSelect
      class="custom"
      [items]="countryList"
      bindValue="id"
      bindLabel="countryName"
      [closeOnSelect]="true"
      [(ngModel)]="selectedCountry"
      placeholder="Select One"
      [virtualScroll]="true"
      [disabled]="isDisabled"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        {{ item.countryName }}
      </ng-template>
    </ng-select>
    <ng-content select="[additionalCountryContent]"></ng-content>
    <div *ngIf="controlNeedsMoreInfo('countryId')" class="alert alert-warning">Needs Attention</div>
  </div>

  <div class="form-group" *ngIf="stateLabel" [class.form-group-error]="stateRequired && stateError">
    <label class="form-label" for="stateId" id ="stateLabel">{{ stateLabel }}</label>
    <ng-select
      #ngSelect
      class="custom"
      [items]="stateList"
      bindValue="id"
      bindLabel="stateName"
      [closeOnSelect]="true"
      [(ngModel)]="selectedState"
      placeholder="Select One"
      [virtualScroll]="true"
      [disabled]="stateList.length < 1 || isDisabled"
      [ngClass]="stateList.length < 1 ? 'jjk-select-disabled' : ''"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        {{ item.stateName }}
      </ng-template>
    </ng-select>
    <ng-content select="[additionalStateContent]"></ng-content>
    <div *ngIf="controlNeedsMoreInfo('stateId')" class="alert alert-warning">Needs Attention</div>
  </div>
</div>

<div *ngIf="isDisabled">
  <div class="form-group" *ngIf="countryLabel">
      <label class="form-label" for="countryId">{{ countryLabel }}</label>
      <input name="jjkCountryInput" [ngModel]="countryText" [placeholder]="!countryText ? '(not populated)' : ''" [attr.disabled] = "isDisabled" class="form-control large-input" type="text"/>
  </div>
  <div class="form-group" *ngIf="stateLabel">
      <label class="form-label" for="stateId">{{ stateLabel }}</label>
      <input name="jjkStateInput" [ngModel]="stateText" [placeholder]="!stateText ? '(not populated)' : ''" [attr.disabled] = "isDisabled" class="form-control large-input" type="text"/>
  </div>
</div>