import { Component, Input } from '@angular/core';
import { DynamicFormControlComponent } from '@components/dynamic-form-control/dynamic-form-control.component';
import { ifNot, isNumberKey } from '@utilities/helpers';

@Component({
    selector: 'dynamic-form-control-text',
    templateUrl: './dynamic-form-control-text.component.html',
    styleUrls: ['../dynamic-form-control/dynamic-form-control.component.scss'],
})
export class DynamicFormControlTextComponent extends DynamicFormControlComponent {
    @Input() type = 'text';
    maxlength: number;
    // event is required depending on the specific type of input field (number or text)
    onInputKeyPress = (event: any) => true;
    // event is required depending on the specific type of input field (number or text)
    onPasteHandler = (event: ClipboardEvent) => true;

    constructor() {
        super();
    }

    onControlDataUpdate() {
        super.onControlDataUpdate();
        this.maxlength = ifNot(this.controlData.findDynamicValidator('maxLength'), 0, (x) => x.value);
        if (this.type === 'number') {
            this.onInputKeyPress = isNumberKey;
            // Prevent user from pasting non number values
            this.onPasteHandler = (event: ClipboardEvent) => {
                const pastedText = event.clipboardData.getData('text');
                return !!Number(pastedText);
            };
        }
    }

    ngChange() {
    }

    getStyleClasses() {
        const classes = super.getStyleClasses();
        if (this.controlData && this.controlData.isDisabled) {
            classes.push('text-disabled');
        }
        return classes;
    }
}
