import { Component, Input } from '@angular/core';
import { NavigationService } from '@services/navigation.service';

@Component({
    selector: 'back-button',
    template: `<button type="button" class="btn" [ngClass]="buttonClass" (click)="goBack()" [disabled]="isDisabled">{{ innerText }}<ng-content></ng-content></button>`,
})
export class BackButtonComponent {
    @Input() innerText = 'Cancel';
    @Input() buttonClass = 'btn-secondary'
    @Input() isDisabled = false;
    @Input() targetUrl = '';
    @Input() overrideHistory = false;

    constructor(private navigator: NavigationService) { }

    goBack() {
        this.navigator.navigateTo(this.targetUrl, this.overrideHistory);
    }
}
