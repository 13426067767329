<nav class="sidebar" [ngClass]="active ? 'active' : ''">
  <div class="side-nav-wrapper">
    <ng-container *ngFor="let n of navList; let i = index">
      <div *featureToggle="n.featureToggleName" id="accordion">
        <ul class="sidebar-nav">
          <h3 *ngIf="n.groupName" [id]="n.groupName">{{n.groupName}}</h3>
          <ng-container *ngFor="let a of n.accordion">
            <li *featureToggle="a.featureToggleName" auth [authFeature]="a.authTag">
              <a
                *ngIf="a.items != undefined; else elseBlock"
                ngclass="btn btn-accordion w-100 text-start"
                [routerLink]="a.route"
                data-bs-toggle="collapse"
                [attr.nav-item-route]="a.route"
                [attr.data-bs-target]="'#' + a.id">

                <i class="custom-icon {{a.icon}}">{{a.name}}</i>
                <i class="fas fa-angle-down menu-angle-down"></i>
                <span *ngIf="a.notifications" class="badge badge-default">{{a.notifications}}</span>
              </a>
              <div *ngIf="a.items != undefined;" [id]="a.id" class="collapse" data-bs-parent="#accordion">
                <ul class="items mt-0">
                  <ng-container *ngFor="let i of a.items">
                    <li [attr.sidebar-nav]="i.route" *featureToggle="i.featureToggleName" auth [authFeature]="i.authTag">
                      <a
                        [attr.sub-nav-item-route]="i.route"
                        (click)="closeSideNav(i)">

                        {{ i.name }}
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </div>
              <ng-template #elseBlock>
                <a
                  ngclass="btn btn-accordion w-100 text-start"
                  data-bs-toggle="collapse"
                  [attr.nav-item-route]="a.route"
                  [attr.data-bs-target]="'#' + a.id"
                  (click)="closeSideNav(a)">

                  <i class="custom-icon {{a.icon}}">{{a.name}}</i>
                  <span *ngIf="a.notifications" class="badge badge-default">{{a.notifications}}</span>
                </a>
              </ng-template>
            </li>
          </ng-container>
        </ul>
        <hr *ngIf="!(i == (navList.length - 1))" />
      </div>
    </ng-container>
    <div id="menu-settings" *ngIf="!isNonAuth">
      <ul>
        <h3>SETTINGS</h3>
        <layout-menu-options (closeSideNav)="closeSideNav()"></layout-menu-options>
      </ul>
    </div>
  </div>
</nav>

<div class="modal fade" id="ssoModal" tabindex="-1" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        {{ssoType.includes('Expert Help') ? 'Expert Help' : ssoType}} is currently unavailable, and we are working to fix this issue. Please try to access this feature
        again later.
      </div>
      <div class="modal-footer">
        <jjk-system-message-button buttonText='Close' (clicked)="hideModal('ssoModal')"> </jjk-system-message-button>
      </div>
    </div>
  </div>
</div>