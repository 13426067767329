import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { AuthService } from '@services/auth.service';

@Component({
    selector: 'password-expire-modal',
    templateUrl: './password-expire-modal.component.html',
})
export class PasswordExpireModalComponent implements OnInit {
    isProcessingRequest: boolean;
    isVisible = false;
    changePasswordUrl = '/profile#ChangePassword'

    title = "Password Expiring soon"
    modalId = 'passwordExpireModal';

    @Output() passwordUpdated = new EventEmitter<string>();

    constructor(private router: Router, private authService: AuthService) {
    }

    async ngOnInit(): Promise<void> {
        setTimeout(() => this.openPasswordExpireModal(), 300);
    }

    openPasswordExpireModal() {
        if (this.isVisible) { return; }
        this.isVisible = true;
        this.title = `Password Expiring in ${LoggedInUserInfo.Instance.userInfo.passwordExpiresInDays} days`;
        ($('#passwordExpireModal') as any).modal('show');
    }

    async onFormSubmit(eventData?: any) {
        this.acknowledge(true);
        this.closeModal();
    }

    acknowledge(doRedirect: boolean) {
        LoggedInUserInfo.Instance.userInfo.promptedDaysToExpire = LoggedInUserInfo.Instance.userInfo.passwordExpiresInDays;
        if (doRedirect) {
            this.router.navigateByUrl(this.changePasswordUrl);
        }
    }

    onCancel () {
        this.acknowledge(false);
        this.closeModal();
    }

    closeModal() {
        this.isVisible = false;
        ($(`#${this.modalId}`) as any).modal('hide');
    }

}
