export const CachingEntityRelationship = {
    AdditionalEmployeeInfo: ['Incident', 'IncidentViewModel'],
    AdditionalForm300Info: ['Incident', 'IncidentViewModel'],
    AdditionalForm301Info: ['Incident', 'IncidentViewModel'],
    AffectedBodyPart: ['BodyPart', 'Incident', 'RecordableDetails'],
    BinderChemicalSafetyDataSheet: ['SafetyDataSheet', 'GlobalSafetyDataSheet'],
    BinderVersion: ['Binder', 'BinderStatus'],
    BinderVersionLocation: ['BinderVersion'],
    BinderVersionSafetyDataSheet: ['BinderVersion', 'SafetyDataSheet'],
    BinderVersionWorkArea: ['BinderVersion'],
    ChecklistArea: ['Checklist'],
    ChecklistCitation: ['Checklist'],
    ChecklistGoverningBody: ['Checklist'],
    ChecklistIndustry: ['Checklist'],
    ChecklistSection: ['Checklist'],
    ChecklistSectionJjkaQuestion: ['ChecklistSection', 'Question', 'Checklist'],
    ChecklistSectionQuestion: ['ChecklistSection', 'Question', 'Checklist'],
    ChecklistTopic: ['Checklist'],
    Chemical: ['SafetyDataSheetParent', 'BinderChemicalSafetyDataSheet', 'SafetyDataSheet', 'GlobalSafetyDataSheet', 'GlobalProduct'],
    CompanyUsage: ['Chemical'],
    EventReview: ['Event'],
    EventReviewQuestion: ['EventReviewSection', 'Event'],
    EventReviewQuestionAvailableResponse: ['EventReviewQuestion', 'EventReviewSection', 'Event'],
    EventReviewQuestionCorrectResponse: ['EventReviewQuestion', 'EventReviewSection', 'Event'],
    EventReviewSection: ['EventReview', 'Event'],
    EmployeeJobFunction: ['Employee', 'JobFunction'],
    EmploymentDataMonthly: ['IncidentViewModel'],
    EmploymentDataYear: ['IncidentViewModel'],
    EmploymentDataYearly: ['IncidentViewModel'],
    GeneralInformation: ['Incident', 'IncidentViewModel'],
    GlobalProduct: ['Chemical','GlobalProductViewModel','GlobalSDSSearch'],
    GlobalSafetyDataSheet: ['GlobalSDSSearch','BinderChemicalSafetyDataSheet'],
    Incident: ['IncidentViewModel'],
    Investigation: ['Incident', 'IncidentViewModel'],
    Location: ['LocationGridView'],
    MedicalTest: ['Employee'],
    MedicalVaccination: ['Employee'],
    RecordableDetails: ['Incident', 'IncidentViewModel'],
    Request: ['Chemical', 'GlobalProduct'],
    SafetyDataSheet: ['Chemical', 'Manufacturer', 'BinderChemicalSafetyDataSheet'],
    Task: ['IncidentViewModel'],
    TrainingRecords: ['ClassroomTrainingEvent']
};
