import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControlOptions, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { has } from 'lodash';
import { AuthErrorMessage } from '@constants/auth/auth-error-message.enum';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { ChangePassword } from '@models/auth/change-password.model';
import { PasswordRuleViewModel } from '@models/auth/rule.model';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { ConvertApiErrorToAlertMessage, GetPasswordRulesAndValidation } from '@pages/auth/helpers';
import { AuthService } from '@services/auth.service';
import { LoggerService } from '@services/core/logger-service.class';
import { NavigationService } from '@services/navigation.service';
import { SplitioService } from '@services/splitio.service';
import { isNullOrEmpty } from '@utilities/helpers';
import { PasswordValidators } from '@utilities/validators/password-validators';

@Component({
    selector: 'jjkp-reset-password',
    styleUrls: ['./reset-password.component.scss'],
    templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent extends ValidatableFormBaseDirective implements OnInit {

    @Output() loadingFailure = new EventEmitter<string>();

    alertText = '';
    showAlert = false;
    showAlertAuthCode = false;
    isProcessingRequest = false;
    email: string;
    verificationCode: string;
    resendText = "Resend Verification Code";
    hideResend = true;
    expiredTokenReset: boolean;

    title = 'Password has Expired, Reset is Required';
    alertMessage = 'Your old password has expired and must be reset in order for you to log back into Safety Management Suite.';

    rules: PasswordRuleViewModel[];
    rulesAreLoaded: boolean;
    ruleValidators: ValidatorFn[] = [];
    resetPasswordEmail: boolean;
    requestResetPassword = false;
    b2c2OktaToggle = false;
    private parentActionSuccess: EventEmitter<boolean> = new EventEmitter();
    isExistingInOkta: boolean;
    nonSMSproducts: any;
    hasOktaSMS: boolean;
    hasOnlyOtherApplications: boolean;
    newPasswordText = "New Password";

    get newPasswordValidationErrors(): ValidationErrors {
        return this.form ? this.getControl('newPassword').errors : null;
    }

    get showRuleErrorText(): boolean {
        return this.form ? this.shouldShowErrors('newPassword') : false;
    }

    constructor(private formBuilder: UntypedFormBuilder,
        private navigator: NavigationService,
        private authService: AuthService,
        private router: Router,
        private splitioService: SplitioService,
        private activatedRoute: ActivatedRoute) {
        super();
        if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state !== undefined) {
            this.email = this.router.getCurrentNavigation().extras.state.credentials.emailAddress;
        }
    }

    async ngOnInit(): Promise<void> {
        this.resetPasswordEmail = true;
        this.requestResetPassword = this.activatedRoute.snapshot.routeConfig.path === AuthRoutes.RequestResetPassword.urlRoute;
        if (this.activatedRoute.snapshot.queryParams['v']) {
            this.verificationCode = decodeURIComponent(this.activatedRoute.snapshot.queryParams['v']);
            if(this.requestResetPassword) {
                this.title = 'Request to reset your password.';
                this.alertMessage = "You requested to reset your password. If you didn't make this request, you can safely ignore & verification code will expire on it's own in 30 minutes."
            }
        } else {
            if (this.email) {
                await this.authService.resetPasswordEmail(this.email);
            }
        }
        this.b2c2OktaToggle = (await this.splitioService.getToggle(TOGGLES.FORGOTPASSWORD_B2C_CREATE_OKTA)) === TREATMENT.ON;
    }

    setVerificationCode(code: any) {
        this.verificationCode = code;
    }

    private async buildForm() {
        this.expiredTokenReset = false;
        this.showAlert = false;
        this.ruleValidators.push(Validators.required);
        if(this.b2c2OktaToggle){
            this.email = (this.requestResetPassword) ? LoggedInUserInfo.Instance.decodedToken["rqstRst"] : LoggedInUserInfo.Instance.decodedToken["rst"];
            await this.authService.checkIfOktaUser(this.email)
             .then((data) => {
                  this.isExistingInOkta = !isNullOrEmpty(data.id);
                  this.hasOktaSMS = data.products.filter(p => p == 'Safety Management Suite' || p == 'Safety Management Suite RO' || p == 'SafetyManagementSuite.APIM').length > 0;
                  this.nonSMSproducts = data.products.filter(p => p !== 'Safety Management Suite' && p !== 'Safety Management Suite RO' && p !== 'SafetyManagementSuite.APIM');
                  this.hasOnlyOtherApplications = !(this.nonSMSproducts.length === 0) && (this.hasOktaSMS == false);
             })
             .catch((errorMessage) => {

                 LoggerService.error(errorMessage);
             })
             if(!this.hasOnlyOtherApplications){
                 //the only time we want to call this when the toggle is on is when we don't have other applciations
                 await this.authService.getPasswordRules()
                 .then(data => this.rules = GetPasswordRulesAndValidation(data, this.email))
                 .catch((errorMessage) => this.setAlert(errorMessage));
                 this.ruleValidators = this.rules.filter(r => r.validator).map(r => r.validator);
             }
         }
        if(this.b2c2OktaToggle){
            this.rulesAreLoaded = true;
            this.newPasswordText = !this.requestResetPassword ? "New Password" : "Password";
            this.form = this.formBuilder.group({
                currentPassword: [null, (this.requestResetPassword) ? null : Validators.required],
                newPassword: [null, this.hasOnlyOtherApplications ? Validators.required :  this.ruleValidators],
                passwordConfirm: [null, this.hasOnlyOtherApplications ? null : Validators.required]
            }, {
                validators: this.hasOnlyOtherApplications ? null : [PasswordValidators.PasswordMatchValidator('newPassword', 'passwordConfirm')],
            } as AbstractControlOptions);
        }
        else {
            this.form = this.formBuilder.group({
                currentPassword: [null, (this.requestResetPassword) ? null : Validators.required],
                newPassword: [null, this.ruleValidators],
                passwordConfirm: [null, Validators.required]
            }, {
                validators: [PasswordValidators.PasswordMatchValidator('newPassword', 'passwordConfirm')],
            } as AbstractControlOptions );
        }
    }

    protected async submitForm(eventData?: any) {
        this.isProcessingRequest = true;
        this.showAlertAuthCode = false;
        const credentials = {
            emailAddress: this.email,
            password: this.requestResetPassword ? null : this.getControl('currentPassword').value,
            newPassword: this.getControl('newPassword').value,
        } as ChangePassword;
        this.parentActionSuccess.emit(true);
        if(this.requestResetPassword){
            await this.authService.requestResetPassword(credentials)
                .then(() => this.navigateToLoginPage())
                .catch(
                    errorMessage => this.setAlert(errorMessage))
                .finally(() => {
                    this.isProcessingRequest = false
                    this.parentActionSuccess.emit(false);
                });
        } else {
            await this.authService.resetPassword(credentials)
                .then(() => this.navigateToLoginPage())
                .catch(
                    errorMessage => this.setAlert(errorMessage))
                .finally(() => {
                    this.isProcessingRequest = false
                    this.parentActionSuccess.emit(false);
                });
        }
    }

    setAlert(apiErrorMessage: AuthErrorMessage) {
        this.alertText = ConvertApiErrorToAlertMessage(apiErrorMessage, apiErrorMessage);
        if (apiErrorMessage == AuthErrorMessage.SessionExpired) {
            this.hideResend = false;
        }
        this.showAlert = true;
    }

    setAlertAuthCode(apiErrorMessage: AuthErrorMessage) {
        this.alertText = ConvertApiErrorToAlertMessage(apiErrorMessage, apiErrorMessage);
        this.showAlertAuthCode = true;
    }

    navigateToLoginPage() {
        this.navigator.navigateTo(AuthRoutes.Login.urlRoute);
    }

    async openChangePassword() {
        this.showAlertAuthCode = false;
        if(!this.b2c2OktaToggle){
            await this.authService.getPasswordRules()
                .then(data => this.rules = GetPasswordRulesAndValidation(data, this.email))
                .catch((errorMessage) => this.setAlert(errorMessage));
            this.ruleValidators = this.rules.filter(r => r.validator).map(r => r.validator);
        }
        this.buildForm();
        this.resetPasswordEmail = false;
        this.email = (this.requestResetPassword)
                     ? LoggedInUserInfo.Instance.decodedToken["rqstRst"]
                     : LoggedInUserInfo.Instance.decodedToken["rst"];
    }

    resendVerificationCode() {
        if(this.email) {
            this.authService.resetPasswordEmail(this.email);
            this.showAlertAuthCode = false;
            this.hideResend = true;
            this.resetPasswordEmail = true;
            if(this.rules) {
                this.rules.length = 0;
            }
            this.expiredTokenReset = true;
        }
    }
}
