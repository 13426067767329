import { NonAuthRoutes } from '@constants/enums/non-auth-routes.enum';
import { TOGGLES } from '@constants/toggles';

export const SIDE_NAV_NON_AUTH_CONFIG = [
    {
        groupName: 'MENU',
        accordion: [
            {
                id: 'chemicals',
                name: 'Chemicals',
                icon: 'custom-icon-chemical',
                authTag: 'chemical,view',
                featureToggleName: TOGGLES.CHEMICAL_FEATURE,
                items: [
                    { route: NonAuthRoutes.SdsGrid, name: 'SDS Center', featureToggleName: TOGGLES.CHEMICAL_SDS },
                ],
            },
            {
                id: 'audits',
                name: 'Audits',
                icon: 'custom-icon-audit',
                authTag: 'audit,view',
                featureToggleName: TOGGLES.AUDIT_FEATURE,
                items: [
                    { route: NonAuthRoutes.EventReview, name: 'Audit Events', featureToggleName: TOGGLES.AUDIT_EVENTS },
                    { route: NonAuthRoutes.SelfAssignmentChecklist, name: 'Self-Assignment Checklists', featureToggleName: TOGGLES.AUDIT_SELF_ASSIGNMENT },
                ],
            },
            {
                id: 'incidents',
                name: 'Incidents',
                icon: 'custom-icon-incidents',
                authTag: 'incident,view',
                featureToggleName: TOGGLES.INCIDENT_FEATURE,
                items: [
                    { route: NonAuthRoutes.IncidentIntakeForm, name: 'Incident Intake Form', featureToggleName: TOGGLES.INCIDENT_NON_AUTH },
                ],
            },
        ],
    },
];
