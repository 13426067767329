<div class="form-group">
  <div class="alert alert-danger" *ngIf="isValidationEnabled && fileName?.errors?.invalidFileExtension">
    {{extensionErrorMsg}}
  </div>
  <div class="alert alert-danger" *ngIf="isValidationEnabled && fileSize?.errors?.invalidFileSize">
    Only files up to {{fileSize?.errors?.allowedSize}} {{fileSize?.errors?.allowedSizeUnit}} can be uploaded.
  </div>
  <div class="alert alert-danger" *ngIf="isValidationEnabled && invalidImageDimensions">
    Width and Height must not exceed {{maxWidth}} x {{maxHeight}} pixels.
  </div>
  <div class="alert alert-danger" *ngIf="isValidationEnabled && invalidSvgFile">
    SVG File is not valid.
  </div>
</div>

<div class="form-group">
  <label for="inputFile" class="btn btn-secondary input-file-label form-label" [class.has-danger]="isValidationEnabled && (fileName?.errors?.required || fileName?.errors?.invalidFileExtension || invalidImageDimensions || invalidSvgFile)">Choose File</label>
  <label for="inputFile" class="small input-file-name form-label">{{fileName.value}}</label>
  <input type="file" class="input-file hidden-xs-up" id="inputFile" accept="{{validatedFileTypes}}" #inputFile aria-describedby="fileHelp" (change)="onFileChange($event)">
  <p class="file-hint">File Type: {{hintText}}</p>
  <input type="hidden" [formControl]="fileName">
  <input type="hidden" [formControl]="fileSize">
  <input type="hidden" [formControl]="fileDimensions">
</div>
