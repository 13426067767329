import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'jjkp-grid-page-subactions',
    templateUrl: './subactions.component.html',
    styleUrls: ['../../../../../../src/styles/_gridpage.scss',
                '../../../../../../src/styles/_gridpagenav.scss'],
})
export class GridPageSubactionsComponent {

    @Input() authForAdd: string;
    @Input() addButtonDisplayText  = 'Add New';
    @Input() authForSecondary: string;
    @Input() secondaryButtonDisplayText: string;
    @Input() showPageSubActions: boolean;
    @Input() extraClassForPrimary = 'btnAdd';
    isActionsMenuOpen =  true;
    @Output() addEvent = new EventEmitter();
    @Output() secondaryEvent = new EventEmitter();

    constructor() {
    }

    newItem($event) {
        this.addEvent.emit($event);
    }

    secondaryButtonAction($event) {
        this.secondaryEvent.emit($event);
    }
}
