import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AssociatedType } from '@constants/enums/associated-type.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { MedicalVaccinationStatusEnum } from '@constants/enums/vaccination-status-enum';
import { FileSize } from '@constants/filesize.constants';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { AttachmentFile } from '@models/attachments-file.model';
import { Employee } from '@models/entity-models/autogenerated/employee';
import { MedicalVaccination } from '@models/entity-models/autogenerated/medicalvaccination';
import { MedicalVaccinationStatus } from '@models/entity-models/autogenerated/medicalvaccinationstatus';
import { AttachmentService } from '@services/attachment.service';
import { ApiFactory } from '@services/core/api-factory.class';
import { SplitioService } from '@services/splitio.service';
import { isNullOrUndefined, sortByProperty } from '@utilities/helpers';

@Component({
  selector: 'medical-vaccination-modal-old',
  templateUrl: './medical-vaccination-modal-old.component.html',
  styleUrls: ['./medical-vaccination-modal-old.component.scss'],
})
export class MedicalVaccinationModalOldComponent implements OnInit, OnChanges {

  @Input() employee: Employee;
  @Input() medicalVaccinationObject: MedicalVaccination;

  // Is currently a work around for having two file uploads on one page.
  // Without the ngIf in the html, the selected file will always show on the first form, regardless of which form it's selected on.
  @Input() isOpen;

  @Output() updatedMedical = new EventEmitter();
  @Output() clearMedical = new EventEmitter();

  title: string;
  modalId = 'MedicalVaccinationModal';
  editForm: UntypedFormGroup;
  showErrors: boolean;
  resultOptions: any[] = [];
  editMode: boolean;
  processing = false;
  showAlert = false;
  fileScanToggle = false;
  attachmentError = 'An error occurred uploading the file, please try again later.';

  attachmentFile: AttachmentFile;
  fileUpload = null;
  uploadedFileName: string;
  maxFileSize = FileSize.maxFileSize;
  associatedTypeId = AssociatedType.MedicalVaccination;
  maxDate = moment();
  requireDocs = false;

  private _selectedResult = null;
  get selectedResult() {
      return this._selectedResult;
  }
  set selectedResult(val) {
      this._selectedResult = val;
      this.editForm.patchValue({ result: this._selectedResult });
  }
  childFieldControlFileInput: AbstractControl;
  childFieldControlFileSize: AbstractControl;
  childFieldControlDate: AbstractControl;

  constructor(
    private formBuilder: UntypedFormBuilder, private splitioService: SplitioService, private attachmentService: AttachmentService,
    ) {
  }

  async ngOnInit() {
    this.buildForm();
    this.fileScanToggle = (await this.splitioService.getToggle(TOGGLES.FILE_SCANNING_97762)) === TREATMENT.ON;
  }

  buildForm() {
    this.getResultOptions();
    this.attachmentFile = new AttachmentFile();
    this.editForm = this.formBuilder.group({
      result: ['', Validators.required],
      date: [''],
      fileInputName: [''],
      fileInputSize: [0],
    });
    this.childFieldControlFileInput= this.editForm.get("fileInputName");
    this.childFieldControlFileSize = this.editForm.get("fileInputSize");
    this.childFieldControlDate = this.editForm.get("date");
  }

  async getResultOptions() {
    ApiFactory.retrieveEntity(ApiEntityTypesEnum.MedicalVaccinationStatus)
    .addSuccessHandler((data: MedicalVaccinationStatus[]) => {
      sortByProperty<MedicalVaccinationStatus>(data, (d) => d.value, false);
      this.resultOptions = data.map((element) => ({
        id: element.id,
        displayText: element.value === 0 ? 'Vaccinated' : "Unvaccinated"
        }));
      })
      .buildAndSend();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.medicalVaccinationObject && changes.medicalVaccinationObject.currentValue != undefined) {
      this.setUpEditPage();
    } else {
      this.setUpAddPage();
    }
  }

  setUpAddPage() {
    this.editMode = false;
    this.title = `Add Vaccination Status for ${this.employee.lastName}, ${this.employee.firstName}`;
  }

  async setUpEditPage() {
    this.isOpen = true;
    this.editMode = true;
    this.title = `Edit Vaccination Status for ${this.employee.lastName}, ${this.employee.firstName}`;
    this.selectedResult = this.medicalVaccinationObject.medicalVaccinationStatusId;
    this.editForm.patchValue({ date: this.medicalVaccinationObject.vaccinationDate });
    this.mapAttachment(await this.attachmentService.getAttachment(this.medicalVaccinationObject.id, this.associatedTypeId));
  }

  updateFormValidation(selResult){
    if (selResult == MedicalVaccinationStatusEnum.Vaccinated) {
      this.requireDocs = true;
      this.childFieldControlFileInput.addValidators(Validators.required);
      this.childFieldControlDate.addValidators(Validators.required);
    } else {
      this.requireDocs = false;
      this.childFieldControlFileInput.clearValidators();
      this.childFieldControlDate.clearValidators();
    }
    this.childFieldControlFileInput.updateValueAndValidity();
    this.childFieldControlDate.updateValueAndValidity();
  }

  mapAttachment(response: AttachmentFile | AttachmentFile[]){
    this.attachmentFile = Array.isArray(response) && response.length ? this.attachmentFile = response[0] : new AttachmentFile();
  }

  onSave() {
    // If the file selector has not been used in edit, but has original file attached, bind it to the form
    if (this.editMode && this.attachmentFile.id && !this.attachmentFile.file){
      this.bindFileInputs(this.attachmentFile.fileName, this.attachmentFile.fileSize);
    }
      if (this.editForm.valid) {
        this.processing = true;
        if (this.editMode) {
          const editedvaccObj = {
            ...this.medicalVaccinationObject,
            modifiedByUserId: LoggedInUserInfo.Instance.userInfo.user.id,
            id: this.medicalVaccinationObject.id,
            medicalVaccinationStatusId: this.selectedResult,
            vaccinationDate: this.editForm.controls['date'].value,
          };
          ApiFactory.updateEntity(ApiEntityTypesEnum.MedicalVaccination, editedvaccObj)
            .addSuccessHandler((response: any) => {
              this.updateAssociatedAttachment();
            })
            .addErrorHandler(() => {})
            .buildAndSend();
        } else {
          const vaccObj = {
            createdByUser: LoggedInUserInfo.Instance.userInfo.user,
            employeeId: this.employee.id,
            isDeleted: false,
            medicalVaccinationStatusId: this.selectedResult,
            vaccinationDate: this.editForm.controls['date'].value,
          };
          ApiFactory.saveNewEntity(ApiEntityTypesEnum.MedicalVaccination, vaccObj)
            .addSuccessHandler((response: any) => {
              this.medicalVaccinationObject = response;
              this.saveAttachmentAndUpdateGrid(response);
            })
            .addErrorHandler(() => {})
            .buildAndSend();
        }
      } else {
          this.processing = false;
          this.showErrors = true;
          this.editForm.markAllAsTouched();
      }
  }

  async saveAttachmentAndUpdateGrid(newItem) {
    if (!isNullOrUndefined(this.fileUpload)) await this.saveNewAttachment(newItem);
    if (this.fileScanToggle && this.showAlert) {
      this.editMode = true;
      this.processing = false;
      return;
    }
    this.updatedMedical.emit({ result: newItem });
    this.closeWindow();
  }

  async saveNewAttachment(newItem): Promise<AttachmentFile> {
    const formData = this.buildNewAttachmentData(newItem);
    if (this.fileScanToggle) {
      const response = await this.attachmentService.createAttachment(formData)
      .catch((error) => {
        this.attachmentError = error;
        this.showAlert = true;
        return null;
      });
      return response;
    } else {
      return await this.attachmentService.createAttachment(formData);
    }
  }

  dismissAlert() {
    this.showAlert = false;
  }

  private buildNewAttachmentData(newItem): FormData {
    const data = new FormData();
    data.append('id', undefined);
    data.append('companyId', this.attachmentFile.companyId);
    data.append('associatedId', newItem.id);
    data.append('associatedTypeId', this.associatedTypeId);
    data.append('file', this.attachmentFile.file);
    data.append('fileName', this.attachmentFile.fileName);
    data.append('description', this.attachmentFile.description);
    data.append('isDeleted', 'false');
    data.append('createdByUserId', this.attachmentFile.createdByUserId);

    return data;
  }

  async updateAssociatedAttachment() {
    if (!isNullOrUndefined(this.fileUpload)) {
      if (!isNullOrUndefined(this.attachmentFile.id)) {
        await this.attachmentService.deleteAttachment(this.attachmentFile.id, this.attachmentFile.attachmentAssociationId);
      }
      await this.saveNewAttachment(this.medicalVaccinationObject);
    }
    this.updatedMedical.emit({ result: this.medicalVaccinationObject });
    this.closeWindow();
  }

  onFileChange($event) {
    this.showErrors = false;
    this.dismissAlert();
    this.fileUpload = $event?.file || null;
    this.bindFileInputs(this.fileUpload?.name, this.fileUpload?.size);
  }

  bindFileInputs(name?, size?) {
    this.childFieldControlFileInput.setValue(name || '');
    this.childFieldControlFileSize.setValue(size || '');
  }

  clearAllAttachmentsFromForm() {
    this.attachmentFile = new AttachmentFile();
    this.fileUpload = null;
  }

  clearForm () {
    this.clearAllAttachmentsFromForm();
    this.processing = false;
    this.isOpen = false;
    this.editForm.reset();
    this.dismissAlert();
    this.selectedResult = null;
    this.showErrors = false;
  }

  closeWindow() {
    this.clearForm();
    this.clearMedical.emit();
    ($(`#${this.modalId}`) as any).modal('hide');
  }

  openWindow() {
    this.isOpen = true;
    ($(`#${this.modalId}`) as any).modal('show');

    $(`#${this.modalId}`).on('shown.bs.modal', function() {
        setTimeout(() => {
            $('#result', this).trigger('focus');
        }, 150);
    });

  }
}
