import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { SmsMinimapService } from '../sms-minimap/sms-minimap.service';

@Component({
    selector: '[sms-hideable-column-data]',
    templateUrl: './sms-hideable-column-data.component.html',
    styleUrls: ['./sms-hideable-column-data.component.scss'],
})
export class SmsHideableColumnDataComponent implements OnInit {
    @Input('sms-hideable-column-data')
    columnName: string;

    hidden = false;

    constructor(private smsMinimapService: SmsMinimapService,
                private elementRef: ElementRef) { }

    ngOnInit() {
        this.smsMinimapService.columnInfoChanged$
            .subscribe((newColumnInfo) => {
                const thisColumn = newColumnInfo.find((col) => col.value === this.columnName);
                if (thisColumn !== undefined) {
                    this.hidden = thisColumn.visibility.hidden;
                }
                if (this.hidden) {
                    this.elementRef.nativeElement.style.display = 'none';
                } else {
                    this.elementRef.nativeElement.style.display = 'table-cell';
                }
            });
    }

}
