import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnDestroy, Output, QueryList } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DataGridAdvancedFilterItemComponent } from '@components/datagrid-advanced-filter-item/datagrid-advanced-filter-item.component';
import { FilterType } from '@constants/advanced-filter/filter-type.enum';
import { FilterColumn } from '@models/advanced-filter/filter-column.model';
import { Filter } from '@models/advanced-filter/filter.model';
import { FilterValue } from '@models/advanced-filter/filterValue.model';
import { getFilterOperatorsByType } from '@utilities/filters-helper';
import { isNullOrUndefined, isValidArray, unsubscribeFromAll } from '@utilities/helpers';
import { LoggerService } from '@services/core/logger-service.class';

@Component({
    selector: 'jjk-datagrid-advanced-filter',
    templateUrl: './datagrid-advanced-filter.component.html',
    styleUrls: ['./datagrid-advanced-filter.component.scss'],
})
export class DataGridAdvancedFilterComponent implements AfterContentInit, OnDestroy {

    addedFilterState = false;
    advancedfilterColumns: DataGridAdvancedFilterItemComponent[];
    onInitInsertFilter = false;

    @Input() searchTerm = '';
    @Input() tableData: any[]; // data source
    @Input() searchFields: string[];
    @Input() showResultCount = true;
    @Input() searchHint: string;
    @Input() prePopulatedFilters: Observable<Filter[]>;
    @Input() maxNumberOfFilters = 6;
    @Input() filtersApplied = true;

    //#region placeholder
    private _placeholder = 'Filter by title';
    @Input()
    public get placeholder() {
        return this._placeholder;
    }
    public set placeholder(value) {
        this._placeholder = value;
        this.setSearchBoxSize();
    }
    //#endregion placeholder

    @Output() applyFilters = new EventEmitter<Filter[]>(true);

    @ContentChildren(DataGridAdvancedFilterItemComponent) childrenFilterColumnComponentList !: QueryList<DataGridAdvancedFilterItemComponent>;

    filterColumns: FilterColumn[] = []; // first dropdown, available criteria for filters
    filters: Filter[] = []; // filters displayed in the UI
    selectedFilters: string[] = []; // list of already selected filter criteria (from the first dropdowns)

    subscriptions$: Subscription[] = []; // to keep track of subscriptions and be able to unsubscribe onDestroy()

    constructor() {
    }

    ngAfterContentInit(): void {
        this.setSearchBoxSize();
        this.advancedfilterColumns = this.childrenFilterColumnComponentList.toArray();

        this.advancedfilterColumns.map((f) => {
            const filtercolumn = new FilterColumn();
            filtercolumn.name = f.displayText;
            if (f.dataSource) {
                const subscription = f.dataSource.subscribe((d) => filtercolumn.dataSource = d);
                this.subscriptions$.push(subscription);
            } else {
                filtercolumn.dataSource = this.getColumnValues(f);
            }
            filtercolumn.filterType = f.filterType;
            filtercolumn.grouped = !isNullOrUndefined(f.groupBy);
            filtercolumn.fieldName = f.columnName;
            filtercolumn.getOptionsFunction = f.getOptionsFunction;
            if (!f.hidden) {
                this.filterColumns.push(filtercolumn);
            }
        });

        if (this.prePopulatedFilters) {
            this.subscriptions$.push(this.prePopulatedFilters.subscribe((f) => this.handlePrePopulatedFilters(f)));
        }
    }

    ngOnDestroy() {
        unsubscribeFromAll(this.subscriptions$);
    }

    handlePrePopulatedFilters(filters: Filter[]) {
        filters = this.validatePrePopulatedFilters(filters);
        if (isValidArray(filters)) {
            this.addedFilterState = true;
            this.onInitInsertFilter = true;
            // Add advanced filters from prePopulatedFilters and applyFilters
            this.addFilter(filters);
            this.ApplyAdvancedFiltersSearch();
        }
    }

    validatePrePopulatedFilters(filters: Filter[]): Filter[] {
        let countValidObj = 0;
        const elementsToDelete = [];
        try {
            if (filters) {
                filters.forEach((element: Filter) => {
                    // Look up for prePopulatedFilters column in advanced filter columns
                    const validFilter = this.filterColumns.find((x) => x.fieldName === element.fieldName && x.name === element.column);

                    // Validate operator
                    if (validFilter) {
                        const operators = getFilterOperatorsByType(validFilter.filterType);
                        element.operator = operators.some((x) => x === element.operator) ? element.operator : operators[0];
                        countValidObj++;

                        const valuesToDelete = [];
                        // Validate element filter values
                        if (validFilter.dataSource) {
                            element.filterValue.forEach((value: string) => {
                                const validValue = validFilter.dataSource.find((x) => {
                                    if (x instanceof FilterValue) {
                                        return x.value === value;
                                    } else {
                                        return x === value;
                                    }
                                });
                                if (!validValue) {
                                    valuesToDelete.push(value);
                                }
                            });

                            // Delete not valid values from element obj
                            valuesToDelete.forEach((elementValue) => {
                                element.filterValue.splice(element.filterValue.indexOf(elementValue), 1);
                            });
                        }
                    } else {
                        elementsToDelete.push(element);
                    }
                });

                // Delete not valid elements from filters
                elementsToDelete.forEach((element) => {
                    filters.splice(filters.indexOf(element), 1);
                });
            }
        } catch (error) {
            LoggerService.trace('Invalid query string: ', filters);
            LoggerService.trace('Query string validation error: ', error);
        }
        return filters;
    }

    updateSelectedFilters() {
        this.selectedFilters = [];

        if (this.filters.length > 0) {

            this.filters.map((a) => {
                this.selectedFilters.push(a.column);
            });
        }
    }

    addFilter(filters?: Filter[]) {
        this.updateSelectedFilters();
        if (filters) {
            filters.forEach((element: Filter) => {
                this.filters.push(this.getDefaultFilter(element));
            });
        } else {
            this.onInitInsertFilter = false;
            this.filters.push(this.getDefaultFilter(null));
        }
    }

    getDefaultFilter(filterElement: any): Filter {
        if (filterElement) {
            const filterObj = new Filter(filterElement.column, filterElement.operator, filterElement.fieldName);
            filterObj.filterValue = filterElement.filterValue;
            return filterObj;
        } else if (this.filterColumns.length > 0) {
            return new Filter(this.filterColumns[0].name, '', this.filterColumns[0].fieldName);
        }
        return new Filter();
    }

    getDefaultFilterArray(): Filter[] {
        return new Array<Filter>(this.getDefaultFilter(null));
    }

    setSearchBoxSize() {
        const searchBox = document.getElementById('srch-term');
        if (searchBox) {
            const fontSize = window.getComputedStyle(searchBox, null).getPropertyValue('font-size');
            const fontFamily = window.getComputedStyle(searchBox, null).getPropertyValue('font-family');
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            context.font = `italic ${fontSize} ${fontFamily}`;
            const placeholderWidth = context.measureText(this._placeholder).width;
            searchBox.style.maxWidth = ((Math.ceil(placeholderWidth + 30)) < 260 ? 260 : Math.ceil(placeholderWidth + 30)) + 'px';
            searchBox.style.minWidth = '260px';
            // For pages with long placeholders on small devices, shrink text
            if (window.innerWidth < placeholderWidth + 45) {
                searchBox.style.fontSize = `.75rem`;
            }
        }
    }

    resultsCount() {
        return !isNullOrUndefined(this.tableData)
            ? this.tableData.length === 1 ? this.tableData.length + ' Result' : this.tableData.length + ' Results' : '';
    }

    handleFilterState() {
        this.addFilter();
        this.addedFilterState = true;
    }

    removeAllFilters() {
        this.filters = [];
        this.addedFilterState = false;
        this.searchTerm = '';
        const searchfilters = this.getAllFilters();
        this.applyFilters.emit(searchfilters);
        window.history.replaceState(null, null, window.location.pathname);
    }

    mainFilterdDeleteClick() {
        this.searchTerm = '';
        this.ApplyAdvancedFiltersSearch();
    }

    onRemoveFilterClick(filterIndex: number) {
        if (this.filters.length === 1) {
            this.filters = [];
            this.addedFilterState = false;
        } else {
            this.filters.splice(filterIndex, 1);
        }

        this.updateSelectedFilters();
        this.ApplyAdvancedFiltersSearch();
    }

    ApplyAdvancedFiltersSearch() {
        const searchfilters = this.getAllFilters();
        this.applyFilters.emit(searchfilters);
    }

    getAllFilters() {
        const _SearchFilters: Filter[] = [];

        this.filters.map((f) => {
            if ((!isNullOrUndefined(f.filterValue) && f.filterValue !== '' && !(f.filterValue instanceof Array))
                || (isValidArray(f.filterValue))) {
                _SearchFilters.push(f);
            }
        });

        this.searchFields.map((sf) => {

            const mainFilter = new Filter();
            mainFilter.column = sf;
            mainFilter.operator = 'Contains';
            mainFilter.filterValue = this.searchTerm;
            mainFilter.fieldName = sf;

            _SearchFilters.push(mainFilter);
        });

        return _SearchFilters;
    }

    private getColumnValues(item: DataGridAdvancedFilterItemComponent) {

        if (item.columnName && (item.filterType === FilterType.autocomplete || item.filterType === FilterType.select || item.filterType === FilterType.multiselect)) {

            // Fills DT with all the values in that column
            const dt: any[] = [];
            this.tableData.map((data) => Object.keys(data).map((row) => {
                if (row === item.columnName && data[row]) {
                    const rowValue = data[row].toString();

                    if (item.filterType === FilterType.multiselect) {
                        let separator: any;
                        if (rowValue.indexOf('|') >= 0) {
                            separator = '|';
                        } else if (rowValue.indexOf(',') >= 0) {
                            separator = ',';
                        }

                        if (separator) {
                            const arrayRowValues = rowValue.split(separator);
                            arrayRowValues.map((val) => {
                                dt.push(val.replace(/^\s+|\s+$/gm, ''));
                            });
                        } else {
                            dt.push(rowValue.replace(/^\s+|\s+$/gm, ''));
                        }
                    } else {
                        // check if the result should be grouped
                        if (isNullOrUndefined(item.groupBy)) {
                            dt.push(rowValue.replace(/^\s+|\s+$/gm, ''));
                        } else {

                            const itemDT = { value: rowValue.replace(/^\s+|\s+$/gm, ''), groupBy: data[item.groupBy] };
                            dt.push(itemDT);
                        }
                    }
                }
            }));

            // Remove Duplicates
            const filtered = isNullOrUndefined(item.groupBy)
                ? Array.from(dt.reduce((m, t) => m.set(t, t), new Map()).values())
                : dt.filter((v, i) => dt.findIndex((it) => it.value === v.value) === i);
            // Sort the items alphabetically
            filtered.sort((a, b) => {
                if (isNullOrUndefined(item.groupBy)) {
                    return a.toLowerCase() !== b.toLowerCase() ? a.toLowerCase() < b.toLowerCase() ? -1 : 1 : 0;
                }
            });
            // Remove Empty elements
            return filtered.filter((ue) => Object.keys(ue).length !== 0);
        }
    }

    keyDownFilterSearch(event) {
        if (event.keyCode === 13) {
            this.ApplyAdvancedFiltersSearch();
        }
    }

}
