export class DefaultIds {
    static JjkCompany    = '00000000-0000-0000-0000-000000000000';
    static JjkUser       = '00000000-0000-0000-0000-000000000001';
    static StubCompany   = '10000000-0000-0000-0000-000000000000';
    static StubUser      = '10000000-0000-0000-0000-000000000001';
    // Roles
    static AdminRole     = '00000000-0000-0000-0000-000000000001';
    static EditorRole    = '00000000-0000-0000-0000-000000000002';
    static ViewerRole    = '00000000-0000-0000-0000-000000000003';
    static NoneRole       = '00000000-0000-0000-0000-000000000004';
}
