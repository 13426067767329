import { AfterViewInit, Component, ContentChildren, EventEmitter, forwardRef, Input, Output, QueryList } from '@angular/core';
import { DataGridColumnCustomActionComponent } from '@components/datagrid-column-custom-action/datagrid-column-custom-action.component';
import { DataGridColumnComponent } from '@components/datagrid-column/datagrid-column.component';
import { walkPathForObj } from '@utilities/helpers';

@Component({
    selector: 'jjk-datagrid-column-custom',
    template: `<ng-content></ng-content>`,
    providers: [{ provide: DataGridColumnComponent, useExisting: forwardRef(() => DataGridColumnCustomComponent) }],
})
export class DataGridColumnCustomComponent extends DataGridColumnComponent implements AfterViewInit {
    @Input() id: string;
    @Input() title: string;
    @Input() routerLink: string;
    @Input() field: string;
    @Input() summary: string;
    @Input() summaryLength: string;
    @Input() summaryLocation: string;
    @Input() footerValue: string;
    @Input() footerDisplayText: string;
    @Input() footerRouterLink: string;
    @Input() contentAsButton = false;
    @Input() fileTypeFooter = false;
    @Input() fileType: string;
    @Input() fileSize: string;
    @Input() downloadable: string;
    @Input() downloading: string;
    @Input() customLoader: string;

    @Output() action = new EventEmitter();

    componentArray: any[];

    @ContentChildren(DataGridColumnCustomActionComponent) childComponentsList !: QueryList<DataGridColumnCustomActionComponent>;

    ObjectArray: any[];

    constructor() {
        super();
        this._columnType = 'Custom';
    }

    ngAfterViewInit() {
        this.componentArray = this.childComponentsList.toArray();
    }

    onChildComponentClick(childcomponent: any) {
        childcomponent.component.action.emit(childcomponent.cell);
    }

    public getField(cell: any, classVariableHoldingCellField: string) {
        if (classVariableHoldingCellField === null) {
            return undefined;
        }

        if (this[classVariableHoldingCellField] !== undefined) {
            classVariableHoldingCellField = this[classVariableHoldingCellField];

            if (classVariableHoldingCellField.indexOf('.') >= 0) {
                const value = walkPathForObj(cell, classVariableHoldingCellField);
                return value;
            }
        }

    }
}
