import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition,
} from '@angular/animations';
import { formatLabel } from '@swimlane/ngx-charts';

@Component({
  selector: 'g[ngx-combo-charts-series-vertical]',
  templateUrl: './combo-series-vertical.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition('* => void', [
        style({
          opacity: 1,
          transform: '*',
        }),
        animate(500, style({ opacity: 0, transform: 'scale(0)' })),
      ]),
    ]),
  ],
})
export class ComboSeriesVerticalComponent implements OnChanges {

  @Input() dims;
  @Input() type = 'standard';
  @Input() series;
  @Input() seriesLine;
  @Input() xScale;
  @Input() yScale;
  @Input() colors;
  @Input() tooltipDisabled = false;
  @Input() gradient: boolean;
  @Input() activeEntries: any[];
  @Input() seriesName: string;
  @Input() animations = true;
  @Input() noBarWhenZero = true;

  @Output() select = new EventEmitter();
  @Output() activate = new EventEmitter();
  @Output() deactivate = new EventEmitter();
  @Output() bandwidth = new EventEmitter();

  bars: any[] = [];
  m_series: any[] = [];
  barPadding = 8;

  x: any;
  y: any;

  ngOnChanges(changes): void {
    this.update();
  }

  update(): void {
    const count_per_group = this.series[0].series.length;
    const width = (this.dims.width - this.dims.width / this.series.length) / this.series.length;

    this.bars = [];
    this.m_series = [];

    this.series.map((d, index) => {
      d.series.forEach((element) => {
        this.m_series.push(element);
      });
    });

    if (this.m_series.length) {
      this.bandwidth.emit(width);
    }

    for (let i = 0; i < this.m_series.length; i += count_per_group) {
      const value = [];
      const data = [];
      const height = [];
      const y = [];
      const color = [];
      for (let k = 0; k < count_per_group; k++) {
        value.push(this.m_series[i + k].value);
        data.push(this.m_series[i + k]);
        height.push(Math.abs(this.yScale(this.m_series[i + k].value) - this.yScale(0)));
        if (this.m_series[i + k].value < 0) {
          y.push(0);
        } else {
          y.push(this.yScale(this.m_series[i + k].value));
        }
        color.push(this.colors.getColor(k));
      }

      const label = this.series[i / count_per_group].name;
      const formattedLabel = formatLabel(label);
      const roundEdges = false;

      const bar: any = {
        value,
        label,
        roundEdges,
        color,
        data,
        width: width * 0.9,
        formattedLabel,
        height,
        x: 0,
        y,
      };

      bar.x = this.xScale(this.series[i / count_per_group].name);
      let tooltipLabel = formattedLabel;
      if (this.seriesName) {
        tooltipLabel = `${this.seriesName} • ${formattedLabel}`;
      }

      this.getSeriesTooltips(this.seriesLine, 0);
      const lineValue = this.seriesLine[0].series[0].value;
      bar.tooltipText = `
        <span class="tooltip-label">${tooltipLabel}</span>
        <span class="tooltip-val"> Y1 - ${value.toLocaleString()} • Y2 - ${lineValue.toLocaleString()}%</span>
        `;
      this.bars.push(bar);
    }
  }
  getSeriesTooltips(seriesLine, index) {
    return seriesLine.map((d) => {
      return d.series[index];
    });
  }
  isActive(entry): boolean {
    if (!this.activeEntries) { return false; }
    const item = this.activeEntries.find((d) => {
      return entry.name === d.name && entry.series === d.series;
    });
    return item !== undefined;
  }

  onClick(data): void {
    this.select.emit(data);
  }

  trackBy(index, bar): string {
    return bar.label;
  }

}
