
<button class="btn btn-link btn-sm no-padding bold" (click)="openWindow()">Add Test Results</button>

    <modal-window [id]="modalId" [title]="title" [confirmButtonText]="'Save'" [isConfirmDisabled]="processing" (onConfirm)="onSave()" (onCancel)="closeWindow()" (onX)="closeWindow()" [autoClose]="false">
        <form *ngIf="editForm" [formGroup]="editForm">
          <h2>Test Results</h2>

          <div class="form-group" [ngClass]="showErrors && editForm.get('result').errors?.required ? 'form-group-error' : ''">
            <label class="form-label" for="result"><strong class="req">Test Results</strong></label>
            <jjk-select [options]="resultOptions" id="result" [(selection)]="selectedResult" required
                [ngClass]="showErrors && editForm.get('result').errors?.required ? 'field-error' : ''"></jjk-select>
          </div>

          <div class="form-group">
            <label class="form-label" for="date"><strong class="req">Test Date</strong></label>
            <jjk-date-picker name="date" formControlNameValue="date" [maxDate]="maxDate"></jjk-date-picker>
          </div>

          <sms-alert-dismiss *ngIf="showAlert"
          [messageText]="attachmentError" (dismissAlertClick)="dismissAlert()" [customStyle]="'Error'">
          </sms-alert-dismiss>

          <div class="form-group">
            <h2>Documentation</h2>
            <label class="req form-label">Attach Additional Information</label>
            <jjk-file-upload *ngIf="attachmentFile && isOpen" formControlNameValue="documentation" [isValidationEnabled]="showErrors" [model]="attachmentFile" (fileChange)="onFileChange($event)"></jjk-file-upload>
          </div>

        </form>
    </modal-window>