import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PasswordValidators {

  static usernameMatchValidator(username: string, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value || username == null) {
        return null;
      }
      const allParts = username?.toLowerCase().split(/[@.,\-_#]/);
      const usernameParts = allParts?.filter(s => s.length > 3);
      const valid = !usernameParts?.some(p => control.value.toLowerCase().includes(p));

      return valid ? null : error;
    };
  }

  static patternValidator(regex: RegExp, minCount: number, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const matches = control.value.match(regex);
      const valid = matches?.length >= minCount;

      return valid ? null : error;
    };
  }

  static PasswordMatchValidator(passwordControlName: string, passwordConfirmControlName: string, error: ValidationErrors = { passwordMatch: true }) {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
        const passwordControl = formGroup?.controls && formGroup?.controls[passwordControlName];
        const passwordConfirmControl = formGroup?.controls && formGroup?.controls[passwordConfirmControlName];
        const isValid = passwordControl?.value == passwordConfirmControl?.value;
        if (isValid) {
            return null;
        } else {
            passwordControl.setErrors({...passwordControl.errors, ...error});
            return error;
        }
    }
  }

  static UsernameAndPasswordMatchValidator(usernameControlName: string, passwordControlName: string){
    return (formGroup: UntypedFormGroup) => {
        const usernameControl = formGroup.controls[usernameControlName];
        const passwordControl = formGroup.controls[passwordControlName];

        const allParts = usernameControl.value?.toLowerCase().split(/[@.,\-_#]/);
        const usernameParts = allParts?.filter(s => s.length > 3);
        const valid = usernameParts && passwordControl.value && !usernameParts?.some(p => passwordControl.value?.toLowerCase().includes(p));

        return valid ? null :
          passwordControl.setErrors({...passwordControl.errors, hasUsername: true});
    };
  }
}