// AUTO-GENERATED CODE
//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { AuditScheduleTemplate } from './auditscheduletemplate';
import { Company } from './company';
import { CostCenter } from './costcenter';
import { Country } from './country';
import { EmployeeJobFunction } from './employeejobfunction';
import { EmploymentType } from './employmenttype';
import { Event } from './event';
import { Gender } from './gender';
import { GeneralInformation } from './generalinformation';
import { GeneralInformationHistory } from './generalinformationhistory';
import { Groups } from './groups';
import { JobTitle } from './jobtitle';
import { Location } from './location';
import { MedicalAccommodationStatus } from './medicalaccommodationstatus';
import { MedicalTest } from './medicaltest';
import { MedicalVaccination } from './medicalvaccination';
import { Shift } from './shift';
import { State } from './state';
import { Task } from './task';
import { User } from './user';
import { WorkArea } from './workarea';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { IValidatorInfo, ValidatorInfo } from '@models/validations/validatorInfo';
import { IModelRecord, IModelAudit, IModelBase } from '@models/interfaces/entity-interfaces';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
export class Employee implements IModelRecord, IModelAudit {

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
        cellPhone: string;
        companyId: string;
        costCenterId?: string;
        createdByUserId: string;
        createdDate: Date;
        dateOfBirth?: Date;
        employeeCode: string;
        employmentTypeId?: string;
        faxNumber: string;
        firstName: string;
        genderId?: string;
        groupId: string;
        hireCountryId?: string;
        hireDate?: Date;
        hireStateId?: string;
        homeAddress1: string;
        homeAddress2: string;
        homeCity: string;
        homeCountryId?: string;
        homePhone: string;
        homeStateId?: string;
        homeZipCode: string;
        hoursPerDay?: number;
        hoursPerWeek?: number;
        id: string;
        impersonationCreatedByUserId?: string;
        impersonationModifiedByUserId?: string;
        isActive?: boolean;
        isContractWorker?: boolean;
        isDeleted: boolean;
        isInsuranceProvided?: boolean;
        jobTitleId: string;
        lastName: string;
        locationId: string;
        medicalAccommodationStatusId?: string;
        middleInitial: string;
        modifiedByUserId?: string;
        modifiedDate?: Date;
        officePhone: string;
        shiftId?: string;
        studentPassword: string;
        studentUserName: string;
        supervisorId?: string;
        terminationDate?: Date;
        workAreaId?: string;
        workEmail: string;
        createdByUser: User;
        group: Groups;
        jobTitle: JobTitle;
        location: Location;
        modifiedByUser: User;
        workArea: WorkArea;
        employeeJobFunction: EmployeeJobFunction[];
        permissions: number;

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    constructor(json?: Partial<Employee>) {
        this.cellPhone = json && json.cellPhone;
        this.companyId = json && json.companyId;
        this.costCenterId = json && json.costCenterId;
        this.createdByUserId = json && json.createdByUserId;
        this.createdDate = (!json || json.createdDate === undefined) ? null : (json.createdDate === null ? new Date() : json.createdDate);
        this.dateOfBirth = (!json || json.dateOfBirth === undefined) ? null : json.dateOfBirth;
        this.employeeCode = json && json.employeeCode;
        this.employmentTypeId = json && json.employmentTypeId;
        this.faxNumber = json && json.faxNumber;
        this.firstName = json && json.firstName;
        this.genderId = json && json.genderId;
        this.groupId = json && json.groupId;
        this.hireCountryId = json && json.hireCountryId;
        this.hireDate = (!json || json.hireDate === undefined) ? null : json.hireDate;
        this.hireStateId = json && json.hireStateId;
        this.homeAddress1 = json && json.homeAddress1;
        this.homeAddress2 = json && json.homeAddress2;
        this.homeCity = json && json.homeCity;
        this.homeCountryId = json && json.homeCountryId;
        this.homePhone = json && json.homePhone;
        this.homeStateId = json && json.homeStateId;
        this.homeZipCode = json && json.homeZipCode;
        this.hoursPerDay = json && json.hoursPerDay;
        this.hoursPerWeek = json && json.hoursPerWeek;
        this.id = json && json.id;
        this.impersonationCreatedByUserId = json && json.impersonationCreatedByUserId;
        this.impersonationModifiedByUserId = json && json.impersonationModifiedByUserId;
        this.isActive = json && json.isActive;
        this.isContractWorker = json && json.isContractWorker;
        this.isDeleted = json && json.isDeleted;
        this.isInsuranceProvided = json && json.isInsuranceProvided;
        this.jobTitleId = json && json.jobTitleId;
        this.lastName = json && json.lastName;
        this.locationId = json && json.locationId;
        this.medicalAccommodationStatusId = json && json.medicalAccommodationStatusId;
        this.middleInitial = json && json.middleInitial;
        this.modifiedByUserId = json && json.modifiedByUserId;
        this.modifiedDate = (!json || json.modifiedDate === undefined) ? null : json.modifiedDate;
        this.officePhone = json && json.officePhone;
        this.shiftId = json && json.shiftId;
        this.studentPassword = json && json.studentPassword;
        this.studentUserName = json && json.studentUserName;
        this.supervisorId = json && json.supervisorId;
        this.terminationDate = (!json || json.terminationDate === undefined) ? null : json.terminationDate;
        this.workAreaId = json && json.workAreaId;
        this.workEmail = json && json.workEmail;
        this.createdByUser = (json && new User(json.createdByUser));
        this.group = (json && new Groups(json.group));
        this.jobTitle = (json && new JobTitle(json.jobTitle));
        this.location = (json && new Location(json.location));
        this.modifiedByUser = (json && new User(json.modifiedByUser));
        this.workArea = (json && new WorkArea(json.workArea));
        if (json && json.employeeJobFunction) {
            this.employeeJobFunction = [];
            for (const item of json.employeeJobFunction) {
                this.employeeJobFunction.push(new EmployeeJobFunction(item));
            }
        }
        this.permissions = json && json.permissions || 0;

    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validateInfo(fieldName: string): IValidatorInfo {
        switch (fieldName) {
        case 'cellPhone': {
            return {
                required: false,
                minLength: undefined, maxLength: 20,
                type: 'string',
            };
        }
        case 'companyId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'costCenterId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'createdByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'createdDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'dateOfBirth': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'employeeCode': {
            return {
                required: true,
                minLength: undefined, maxLength: 50,
                type: 'string',
            };
        }
        case 'employmentTypeId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'faxNumber': {
            return {
                required: false,
                minLength: undefined, maxLength: 20,
                type: 'string',
            };
        }
        case 'firstName': {
            return {
                required: true,
                minLength: undefined, maxLength: 50,
                type: 'string',
            };
        }
        case 'genderId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'groupId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'hireCountryId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'hireDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'hireStateId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'homeAddress1': {
            return {
                required: false,
                minLength: undefined, maxLength: 100,
                type: 'string',
            };
        }
        case 'homeAddress2': {
            return {
                required: false,
                minLength: undefined, maxLength: 100,
                type: 'string',
            };
        }
        case 'homeCity': {
            return {
                required: false,
                minLength: undefined, maxLength: 50,
                type: 'string',
            };
        }
        case 'homeCountryId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'homePhone': {
            return {
                required: false,
                minLength: undefined, maxLength: 20,
                type: 'string',
            };
        }
        case 'homeStateId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'homeZipCode': {
            return {
                required: false,
                minLength: undefined, maxLength: 15,
                type: 'string',
            };
        }
        case 'hoursPerDay': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'number',
            };
        }
        case 'hoursPerWeek': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'number',
            };
        }
        case 'id': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationCreatedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationModifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'isActive': {
            return {
                required: true,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'isContractWorker': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'isDeleted': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'isInsuranceProvided': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'jobTitleId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'lastName': {
            return {
                required: true,
                minLength: undefined, maxLength: 50,
                type: 'string',
            };
        }
        case 'locationId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'medicalAccommodationStatusId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'middleInitial': {
            return {
                required: false,
                minLength: undefined, maxLength: 10,
                type: 'string',
            };
        }
        case 'modifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'modifiedDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'officePhone': {
            return {
                required: false,
                minLength: undefined, maxLength: 20,
                type: 'string',
            };
        }
        case 'shiftId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'studentPassword': {
            return {
                required: false,
                minLength: undefined, maxLength: 50,
                type: 'string',
            };
        }
        case 'studentUserName': {
            return {
                required: false,
                minLength: undefined, maxLength: 15,
                type: 'string',
            };
        }
        case 'supervisorId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'terminationDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'workAreaId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'workEmail': {
            return {
                required: false,
                minLength: undefined, maxLength: 241,
                type: 'string',
            };
        }
            default: return { required: false, minLength: undefined, maxLength: undefined, type: undefined };
        }
    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validate(skip: string[] = []): [number, number, string[]?] {
        let count = 0;
        const errorFields = [];
        if (!skip.includes('cellPhone') && ValidatorInfo.validateString(this.cellPhone, this._validateInfo('cellPhone'))) {
            count++;
            errorFields.push('cellPhone');
        }
        if (!skip.includes('companyId') && ValidatorInfo.validateString(this.companyId, this._validateInfo('companyId'))) {
            count++;
            errorFields.push('companyId');
        }
        if (!skip.includes('costCenterId') && ValidatorInfo.validateString(this.costCenterId, this._validateInfo('costCenterId'))) {
            count++;
            errorFields.push('costCenterId');
        }
        if (!skip.includes('createdByUserId') && ValidatorInfo.validateString(this.createdByUserId, this._validateInfo('createdByUserId'))) {
            count++;
            errorFields.push('createdByUserId');
        }
        if (!skip.includes('createdDate') && ValidatorInfo.validateDate(this.createdDate, this._validateInfo('createdDate'))) {
            count++;
            errorFields.push('createdDate');
        }
        if (!skip.includes('dateOfBirth') && ValidatorInfo.validateDate(this.dateOfBirth, this._validateInfo('dateOfBirth'))) {
            count++;
            errorFields.push('dateOfBirth');
        }
        if (!skip.includes('employeeCode') && ValidatorInfo.validateString(this.employeeCode, this._validateInfo('employeeCode'))) {
            count++;
            errorFields.push('employeeCode');
        }
        if (!skip.includes('employmentTypeId') && ValidatorInfo.validateString(this.employmentTypeId, this._validateInfo('employmentTypeId'))) {
            count++;
            errorFields.push('employmentTypeId');
        }
        if (!skip.includes('faxNumber') && ValidatorInfo.validateString(this.faxNumber, this._validateInfo('faxNumber'))) {
            count++;
            errorFields.push('faxNumber');
        }
        if (!skip.includes('firstName') && ValidatorInfo.validateString(this.firstName, this._validateInfo('firstName'))) {
            count++;
            errorFields.push('firstName');
        }
        if (!skip.includes('genderId') && ValidatorInfo.validateString(this.genderId, this._validateInfo('genderId'))) {
            count++;
            errorFields.push('genderId');
        }
        if (!skip.includes('groupId') && ValidatorInfo.validateString(this.groupId, this._validateInfo('groupId'))) {
            count++;
            errorFields.push('groupId');
        }
        if (!skip.includes('hireCountryId') && ValidatorInfo.validateString(this.hireCountryId, this._validateInfo('hireCountryId'))) {
            count++;
            errorFields.push('hireCountryId');
        }
        if (!skip.includes('hireDate') && ValidatorInfo.validateDate(this.hireDate, this._validateInfo('hireDate'))) {
            count++;
            errorFields.push('hireDate');
        }
        if (!skip.includes('hireStateId') && ValidatorInfo.validateString(this.hireStateId, this._validateInfo('hireStateId'))) {
            count++;
            errorFields.push('hireStateId');
        }
        if (!skip.includes('homeAddress1') && ValidatorInfo.validateString(this.homeAddress1, this._validateInfo('homeAddress1'))) {
            count++;
            errorFields.push('homeAddress1');
        }
        if (!skip.includes('homeAddress2') && ValidatorInfo.validateString(this.homeAddress2, this._validateInfo('homeAddress2'))) {
            count++;
            errorFields.push('homeAddress2');
        }
        if (!skip.includes('homeCity') && ValidatorInfo.validateString(this.homeCity, this._validateInfo('homeCity'))) {
            count++;
            errorFields.push('homeCity');
        }
        if (!skip.includes('homeCountryId') && ValidatorInfo.validateString(this.homeCountryId, this._validateInfo('homeCountryId'))) {
            count++;
            errorFields.push('homeCountryId');
        }
        if (!skip.includes('homePhone') && ValidatorInfo.validateString(this.homePhone, this._validateInfo('homePhone'))) {
            count++;
            errorFields.push('homePhone');
        }
        if (!skip.includes('homeStateId') && ValidatorInfo.validateString(this.homeStateId, this._validateInfo('homeStateId'))) {
            count++;
            errorFields.push('homeStateId');
        }
        if (!skip.includes('homeZipCode') && ValidatorInfo.validateString(this.homeZipCode, this._validateInfo('homeZipCode'))) {
            count++;
            errorFields.push('homeZipCode');
        }
        if (!skip.includes('hoursPerDay') && ValidatorInfo.validateNumber(this.hoursPerDay, this._validateInfo('hoursPerDay'))) {
            count++;
            errorFields.push('hoursPerDay');
        }
        if (!skip.includes('hoursPerWeek') && ValidatorInfo.validateNumber(this.hoursPerWeek, this._validateInfo('hoursPerWeek'))) {
            count++;
            errorFields.push('hoursPerWeek');
        }
        if (!skip.includes('id') && ValidatorInfo.validateString(this.id, this._validateInfo('id'))) {
            count++;
            errorFields.push('id');
        }
        if (!skip.includes('impersonationCreatedByUserId') && ValidatorInfo.validateString(this.impersonationCreatedByUserId, this._validateInfo('impersonationCreatedByUserId'))) {
            count++;
            errorFields.push('impersonationCreatedByUserId');
        }
        if (!skip.includes('impersonationModifiedByUserId') && ValidatorInfo.validateString(this.impersonationModifiedByUserId, this._validateInfo('impersonationModifiedByUserId'))) {
            count++;
            errorFields.push('impersonationModifiedByUserId');
        }
        if (!skip.includes('isActive') && ValidatorInfo.validateString(this.isActive, this._validateInfo('isActive'))) {
            count++;
            errorFields.push('isActive');
        }
        if (!skip.includes('isContractWorker') && ValidatorInfo.validateString(this.isContractWorker, this._validateInfo('isContractWorker'))) {
            count++;
            errorFields.push('isContractWorker');
        }
        if (!skip.includes('isDeleted') && ValidatorInfo.validateString(this.isDeleted, this._validateInfo('isDeleted'))) {
            count++;
            errorFields.push('isDeleted');
        }
        if (!skip.includes('isInsuranceProvided') && ValidatorInfo.validateString(this.isInsuranceProvided, this._validateInfo('isInsuranceProvided'))) {
            count++;
            errorFields.push('isInsuranceProvided');
        }
        if (!skip.includes('jobTitleId') && ValidatorInfo.validateString(this.jobTitleId, this._validateInfo('jobTitleId'))) {
            count++;
            errorFields.push('jobTitleId');
        }
        if (!skip.includes('lastName') && ValidatorInfo.validateString(this.lastName, this._validateInfo('lastName'))) {
            count++;
            errorFields.push('lastName');
        }
        if (!skip.includes('locationId') && ValidatorInfo.validateString(this.locationId, this._validateInfo('locationId'))) {
            count++;
            errorFields.push('locationId');
        }
        if (!skip.includes('medicalAccommodationStatusId') && ValidatorInfo.validateString(this.medicalAccommodationStatusId, this._validateInfo('medicalAccommodationStatusId'))) {
            count++;
            errorFields.push('medicalAccommodationStatusId');
        }
        if (!skip.includes('middleInitial') && ValidatorInfo.validateString(this.middleInitial, this._validateInfo('middleInitial'))) {
            count++;
            errorFields.push('middleInitial');
        }
        if (!skip.includes('modifiedByUserId') && ValidatorInfo.validateString(this.modifiedByUserId, this._validateInfo('modifiedByUserId'))) {
            count++;
            errorFields.push('modifiedByUserId');
        }
        if (!skip.includes('modifiedDate') && ValidatorInfo.validateDate(this.modifiedDate, this._validateInfo('modifiedDate'))) {
            count++;
            errorFields.push('modifiedDate');
        }
        if (!skip.includes('officePhone') && ValidatorInfo.validateString(this.officePhone, this._validateInfo('officePhone'))) {
            count++;
            errorFields.push('officePhone');
        }
        if (!skip.includes('shiftId') && ValidatorInfo.validateString(this.shiftId, this._validateInfo('shiftId'))) {
            count++;
            errorFields.push('shiftId');
        }
        if (!skip.includes('studentPassword') && ValidatorInfo.validateString(this.studentPassword, this._validateInfo('studentPassword'))) {
            count++;
            errorFields.push('studentPassword');
        }
        if (!skip.includes('studentUserName') && ValidatorInfo.validateString(this.studentUserName, this._validateInfo('studentUserName'))) {
            count++;
            errorFields.push('studentUserName');
        }
        if (!skip.includes('supervisorId') && ValidatorInfo.validateString(this.supervisorId, this._validateInfo('supervisorId'))) {
            count++;
            errorFields.push('supervisorId');
        }
        if (!skip.includes('terminationDate') && ValidatorInfo.validateDate(this.terminationDate, this._validateInfo('terminationDate'))) {
            count++;
            errorFields.push('terminationDate');
        }
        if (!skip.includes('workAreaId') && ValidatorInfo.validateString(this.workAreaId, this._validateInfo('workAreaId'))) {
            count++;
            errorFields.push('workAreaId');
        }
        if (!skip.includes('workEmail') && ValidatorInfo.validateString(this.workEmail, this._validateInfo('workEmail'))) {
            count++;
            errorFields.push('workEmail');
        }
        return [count, 8, errorFields];
    }
}
