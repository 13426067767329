<div class="ag-filter-wrapper ag-focus-managed">
  <div class="ag-filter-body-wrapper ag-simple-filter-body-wrapper">
    <div class="ag-filter-body">
      <ng-select [(ngModel)]="selectedCompare" class="ag-input-field-input ag-text-field-input" [clearable]="false" [searchable]="false" (ngModelChange)="onCompareChange()">
        <ng-option [value]="'equals'">Equals</ng-option>
        <ng-option [value]="'greaterThan'" [hidden]="suppressGreaterThan">Greater than</ng-option>
        <ng-option [value]="'lessThan'" [hidden]="suppressLessThan">Less than</ng-option>
        <ng-option [value]="'notEqual'" [hidden]="suppressNotEqual">Not equal</ng-option>
        <ng-option [value]="'inRange'" [hidden]="suppressRange">In range</ng-option>
      </ng-select>
    </div>
    <div class="ag-filter-body">
      <input type="time" id="default-picker" [(ngModel)]="selectedTime1" class="ag-input-field-input ag-text-field-input" placeholder="HH:mm (24h)" (ngModelChange)="onChange()">
    </div>
    <div class="ag-filter-body" [hidden]="isSecondDateHidden">
      <input type="time" id="default-picker" [(ngModel)]="selectedTime2" class="ag-input-field-input ag-text-field-input" placeholder="HH:mm (24h)" (ngModelChange)="onChange()">
    </div>
  </div>
  <div class="ag-filter-apply-panel" [hidden]="!isResetVisible">
    <button type="button" ref="resetFilterButton" (click)="clearFilter()" class="ag-standard-button ag-filter-apply-panel-button">Reset
    </button>
  </div>
</div>