import { Input, Component, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { CheckboxItem } from '@models/checkbox-item.model';

@Component({
    selector: 'jjk-checkbox-list',
    templateUrl: './checkbox-list.component.html',
})
export class CheckboxListComponent implements OnInit, OnChanges {

    @Input() options = Array<CheckboxItem>();
    @Input() selectedValues: string[];
    @Output() selection = new EventEmitter<string[]>();

    constructor() {
    }

    ngOnInit(): void { }

    ngOnChanges() {
        if (this.selectedValues) {
            this.selectedValues.forEach((v) => {
                const element = this.options.find((x) => x.value === v);
                if (element) {
                    element.checked = true;
                }
            });
        }
    }

    selectAll() {
        this.options.forEach((x) => x.checked = true);
        this.setSelected();
    }

    clearAll() {
        this.options.forEach((x) => x.checked = false);
        this.setSelected();
    }

    onToggle() {
        this.setSelected();
    }

    private setSelected() {
        const checkedOptions = this.options.filter((x) => x.checked);
        this.selectedValues = checkedOptions.map((x) => x.value);
        this.selection.emit(checkedOptions.map((x) => x.value));
    }
}
