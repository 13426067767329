<div class="listFilter container mt-2 mb-2">
    <ng-container *ngFor="let item of listItems">
        <div class="filterItem" *ngIf="!groupItems || !params.groupedItems.includes(item.label)">
            <input type="checkbox" [(ngModel)]="item.checked" [id]="'cb' + item.value" (ngModelChange)="onChange(item)">
            <label class="form-label" [for]="'cb' + item.value">{{ item.label }}</label>
        </div>
        <div class="filterGroup" *ngIf="groupItems && item.label === params.groupingItem">
            <div class="filterItem" *ngFor="let groupItem of groupedItems">
                <input type="checkbox" [(ngModel)]="groupItem.checked" [id]="'cb' + groupItem.value" (ngModelChange)="onChange(groupItem)">
                <label class="form-label" [for]="'cb' + groupItem.value">{{ groupItem.label }}</label>
            </div>
        </div>
    </ng-container>
</div>