import { Directive, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';

@Injectable()
@Directive({
    selector: '[appEmailAddressValidator][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: EmailAddressValidator, multi: true },
    ],
})
export class EmailAddressValidator implements Validator {
    validator: ValidatorFn;

    constructor() {
        this.validator = validateEmailAddressFactory();
    }

    validate(c: UntypedFormControl) {
        return this.validator(c);
    }

    public validateEmailAddress(emailAddress: string): boolean {
        // eslint-disable-next-line no-useless-escape
        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        if (emailAddress === '' || emailAddress === null || emailAddress === undefined) {
            return true;
        } else {
            return regexp.test(emailAddress);
        }
    }

}

// validation function
function validateEmailAddressFactory(): ValidatorFn {
    return (c: AbstractControl) => {
        // eslint-disable-next-line no-useless-escape
        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        const isValid = regexp.test(c.value);

        if (isValid) {
            return null;
        } else {
            return { appEmailAddressValidator: { valid: false } };
        }
    };
}

export function EmailAddressValidatorAsync():AsyncValidatorFn {
    return (c: AbstractControl): Observable<ValidationErrors> => {
        const params = {
            emailAddress: c.value,
        } as IEmailAddress;

        if (params.emailAddress) {
            // eslint-disable-next-line no-useless-escape
            const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

            const isValid = regexp.test(params.emailAddress);

            if (!isValid) {
                return of({ emailAddress: 'Please enter a valid-email address' });
            }
        }

        return of(null);
    };
}

export interface IEmailAddress {
    emailAddress: string;
}
