import { environment } from '@env/environment';

export default {
  oidc: {
    clientId: `${environment.CLIENT_ID}`,
    issuer: `${environment.ISSUER}`,
    redirectUri: `${environment.CALLBACK_URL}`,
    scopes: ['openid', 'offline_access', 'SafetyManagementSuite.Api'],
    pkce: true,
    tokenManager: {
        storage: 'sessionStorage',
        autoRenew: true,
    },
  },
};