import { Directive } from '@angular/core';
import { MessageBusService } from '../services/messageBusService';
import { EnableMessageProcessingDirective } from './EnableMessageProcessing';

@Directive()
export abstract class EnableServiceMessageProcessingDirective extends EnableMessageProcessingDirective implements EnableMessageProcessingDirective {
    /**
     *
     */
    constructor(msgbus: MessageBusService) {
        super(msgbus);

        this.ngOnInit();
        this.buildMessageBusRegistrations();
    }
}
