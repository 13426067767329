import { Injectable } from '@angular/core';
import { agGridGrids } from '@constants/aggrid-grids';
import { AgGridStateModel } from '@models/aggrid-state.model';

@Injectable()
export class AgGridStateService {

    aggrids = Object.keys(agGridGrids).map((k) => agGridGrids[k]);

    restoreAgGridState(gridKey: string) {
        const jsonGridState = window.localStorage.getItem(gridKey);
        const agGridStateModel = new AgGridStateModel(gridKey);

        if (jsonGridState) {
            agGridStateModel.populateFromJSON(JSON.parse(jsonGridState));
            return agGridStateModel;
        }
        return agGridStateModel;
    }

    storeAgGridState(agGrid: AgGridStateModel) {
        const jsonGrid = JSON.stringify(agGrid);
        window.localStorage.setItem(agGrid.gridKey, jsonGrid);
    }

    updateAgGridState(agGrid: AgGridStateModel) {

        const jsonGridState = window.localStorage.getItem(agGrid.gridKey);
        if (jsonGridState) {
            window.localStorage.removeItem(agGrid.gridKey);
        }

        this.storeAgGridState(agGrid);
    }

    removeAllGridsFromLocalStorage() {

        this.aggrids.map((g) => {
            window.localStorage.removeItem(g);
        });
    }
}
