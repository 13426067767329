<div *ngIf="getHideableCols().length > 0" class="minimap float-end">
  <div class="minimap-dropdown" #columnToggle>
    <a class="btn column-toggle-btn" [ngClass]="showDropdown ? 'up' : 'down'" (click)="toggleDropdown()">
      <span>Columns</span>
    </a>
    <div class="sms-dropdown-menu" aria-labelledby="dropdownMenuButton" [ngClass]="showDropdown ? 'dropdown-show' : 'dropdown-hide'">
      <ng-container *ngFor="let column of getHideableCols()">
        <label *ngIf="!column.hidden">
          <input type="checkbox" [checked]="!column.visibility.hidden" (change)="toggleColumn(column.value)">
          {{ column.displayText }}
        </label>
      </ng-container>
    </div>
  </div>
  <div class="minimap-dots">
    <ul>
      <ng-container *ngFor="let column of columns" >
        <li *ngIf="!column.hidden" [class.minimap-dot-hidden]="column.visibility.hidden">
          <i></i>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

<ng-content></ng-content>
