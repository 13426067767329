import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
    selector: 'global-pictogram',
    templateUrl: './globalPictogram.component.html',
    styleUrls: ['./globalPictogram.component.scss'],
})
export class GlobalPictogramComponent implements OnInit {

    @Input() pictogram: string;
    @Input() sizeClass: string;
    endpoint: string;
    environment: string;
    route: string;

    constructor() {}

    async ngOnInit() {
            this.environment = environment.jjkellerportalapiurl;
            this.route = 'GhsPictogram/Download/';
            this.endpoint = this.environment + this.route;
    }
}
