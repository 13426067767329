 <!-- Label Component -->
    <ng-container *ngIf="displayText" [ngSwitch]="color" (onclick)="onClick($event)">
      <span *ngSwitchCase="'blue'" class="badge bg-primary">   {{displayText}}</span>
      <span *ngSwitchCase="'gray'" class="badge bg-secondary"> {{displayText}}</span>
      <span *ngSwitchCase="'green'" class="badge bg-success">  {{displayText}}</span>
      <span *ngSwitchCase="'red'" class="badge bg-danger">     {{displayText}}</span>
      <span *ngSwitchCase="'yellow'" class="badge bg-warning text-dark"> {{displayText}}</span>
      <span *ngSwitchCase="'light'" class="badge bg-info">     {{displayText}}</span>
      <span *ngSwitchCase="'white'" class="badge bg-light text-dark">    {{displayText}}</span>
      <span *ngSwitchCase="'black'" class="badge bg-dark">     {{displayText}}</span>
    </ng-container>