import { Injectable } from '@angular/core';
import { AssociatedType } from '@constants/enums/associated-type.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { AttachmentFile } from '@models/attachments-file.model';
import { isNullOrUndefined } from '@utilities/helpers';
import { ApiFactory } from './core/api-factory.class';
import { FilterExpressionBuilder } from './core/models/Filter-Entry';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { SplitioService } from '@services/splitio.service';

@Injectable()
export class AttachmentService {

    constructor(private splitioService: SplitioService) {
    }

    async getAttachment(associatedObjectId: string, associatedTypeId: AssociatedType) : Promise<AttachmentFile | AttachmentFile[]> {

        const attachMultipleEntitiesToggle = (await this.splitioService.getToggle(TOGGLES.ATTACHMENT_MULTIPLE_ENTITIES)) === TREATMENT.ON;       
        
        return new Promise((resolve, reject) => {
            const apiFactory = ApiFactory.retrieveEntity(ApiEntityTypesEnum.Attachment);
                
            if (attachMultipleEntitiesToggle) {
                apiFactory.addRouteHint('AllByAssociatedId')
                    .addDataEntry('associatedId', associatedObjectId);
            } else {
                const associatedIdEx = FilterExpressionBuilder.For(ApiEntityTypesEnum.Attachment)
                    .Use('AssociatedId', ParameterTypeEnum.String)
                    .Equal(associatedObjectId)
                    .Build().AsExpression;
    
                const associatedIdTypeEx = FilterExpressionBuilder.For(ApiEntityTypesEnum.Attachment)
                    .Use('AssociatedTypeId', ParameterTypeEnum.String)
                    .Equal(associatedTypeId)
                    .Build().AsExpression;
                
                apiFactory.addFilterEntries(associatedIdEx)
                    .addFilterEntries(associatedIdTypeEx);
            }
            
            apiFactory.addSuccessHandler((response: AttachmentFile | AttachmentFile[]) => {
                resolve(response);
            })
            .addErrorHandler(() => {
                reject();
            })
            .removePaging()
            .buildAndSend();
        });        
    }

    async createAttachment(fileToSave: any) : Promise<AttachmentFile>  {
        if (isNullOrUndefined(fileToSave)) return null;
        
        const attachMultipleEntitiesToggle = await this.splitioService.getToggle(TOGGLES.ATTACHMENT_MULTIPLE_ENTITIES) === TREATMENT.ON;
        
        return new Promise((resolve, reject) => {
            const apiFactory = ApiFactory.saveNewEntity(ApiEntityTypesEnum.Attachment, fileToSave);

            if (attachMultipleEntitiesToggle) {
                apiFactory.addRouteHint('AttachmentWithAssociation');
            }

            apiFactory.addSuccessHandler((response: AttachmentFile) => {               
                resolve(response);
            })
            .addErrorHandler((error) => {
                let errorMessage = 'An error occurred uploading the file, please try again later.';
                if (error && error.errorData) {
                    errorMessage = error.errorData[0].exceptionData.toString();
                }
                reject(errorMessage);
            })
            .buildAndSend();
        });
    }

    async deleteAttachment(attachmentId: string, attachmentAssociationId: string): Promise<void> {
        if (isNullOrUndefined(attachmentId)) return null;
        const attachMultipleEntitiesToggle = await this.splitioService.getToggle(TOGGLES.ATTACHMENT_MULTIPLE_ENTITIES) === TREATMENT.ON;
        const apiFactory = ApiFactory.deleteEntity(ApiEntityTypesEnum.Attachment);
        if (attachMultipleEntitiesToggle) {
            apiFactory.addRouteHint('RemoveAttachmentAssociation')
                      .addEntityId(attachmentAssociationId);
        } else {
            apiFactory.addEntityId(attachmentId);            
        }
        return new Promise<void>((resolve, reject) => {        
            apiFactory.addSuccessHandler(() => {
                resolve();
            })
            .addErrorHandler(() => {
                reject();
            })
            .buildAndSend();
        });
    }
}
