<div *ngIf='platform.TRIDENT || platform.SAFARI'>
  <ng-select #hourSelect [items]="hours" placeholder='HH' bindLabel="value" [closeOnSelect]="true" [virtualScroll]="true" [clearable]="false" [searchable]="true" [(ngModel)]="hourSelected"
    class="custom" [disabled]="disabled === false ? null : ''"></ng-select>
  <span>&nbsp;:&nbsp;</span>
  <ng-select #minutesSelect [items]="minutes" placeholder='mm' bindLabel="value" [closeOnSelect]="true" [virtualScroll]="true" [clearable]="false" [searchable]="true" [(ngModel)]="minutesSelected"
    class="custom" [disabled]="disabled === false ? null : ''"></ng-select>
  <ng-container *ngIf="use24HourTime === false">
    &nbsp;&nbsp;
    <ng-select *ngIf="use24HourTime === false" #ampmSelect [items]="ampm" bindLabel="value" [closeOnSelect]="true" [virtualScroll]="true" [clearable]="false" [searchable]="true" [(ngModel)]="ampmSelected"
      class="custom" [disabled]="disabled === false ? null : ''"></ng-select>
  </ng-container>
  <span *ngIf="disabled === false" title="Clear time" class="reset" (click)="this.resetTime()">×</span>
</div>
<div *ngIf='!platform.TRIDENT && !platform.SAFARI'>
  <input type="time" id="timeInput" [disabled]="disabled === false ? null : ''" class="form-control" [(ngModel)]='value'>
</div>