import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { timeFormat } from '@utilities/helpers';

@Pipe({
    name: 'timeFormat',
})
export class TimeFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any): any {
      return timeFormat(value);
    }
}
