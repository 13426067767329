<div *ngIf="controlData" class="form-group" [class.has-danger]="showErrors">
  <label [attr.for]="controlData.name" class="d-block form-label" [class.req]="controlData.required" *ngIf="controlData.displayName">
    <strong>{{ controlData.displayName }}</strong>
  </label>
  <label class="switch switch-flat">
    <input class="switch-input" [id]="controlData.name" type="checkbox" [formControl]="controlData" [attr.disabled]="controlData?.isDisabled ? 'true' : null">
    <span class="switch-label" data-on="Yes" data-off="No" [ngClass]="controlData.isDisabled ? 'disabled' : ''"></span> <span class="switch-handle"></span>
  </label>
  <div *ngIf="showErrors" class="error-message"></div>
</div>
