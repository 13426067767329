<div class="sortable" *ngIf="!hidden" [class.sortable-ascending]="sortDirection === 'asc'" [class.sortable-descending]="sortDirection === 'desc'">
  <button class="sortable-btn">
    <ng-content></ng-content>
    <span class="sortable-arrow"></span>
  </button>
  <ng-container *ngIf="popoverContent">
    <jjk-popover additional [content]="popoverContent" [title]="popoverTitle">&nbsp;</jjk-popover>
  </ng-container>
</div>
<ng-content select="[additional]"></ng-content>
