import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'jjk-expand-all',
  templateUrl: './expand-all.component.html',
  styleUrls: ['./expand-all.component.scss'],
})
export class ExpandAllComponent implements OnInit {

  @Output() isExpanded: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  expandAll() {
    $('.accordion-section').find('.expand-all').addClass('show');
    $('.accordion-section').find('[data-bs-toggle="collapse"]').removeClass('collapsed');
    this.onClick();
    window.scrollTo(0, 0);
  }

  collapseAll() {
    $('.accordion-section').find('.expand-all').removeClass('show');
    $('.accordion-section').find('[data-bs-toggle="collapse"]').addClass('collapsed');
    this.onClick();
    window.scrollTo(0, 0);
  }

  onClick() {
    this.isExpanded.emit(null);
  }
}
