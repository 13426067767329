export class TreeNode {
    id: string;
    name: string;
    parentId: number;
    enabled: boolean;
    opened: boolean;
    matched: boolean;
    role: any;
    roles: any[];
    children: TreeNode[];
}
export class FlatTreeNode {
    constructor(
        public id: string,
        public name: string,
        public parentId: number,
        public level: number,
        public disabled: boolean,
        public expandable: boolean,
    ) { }
}

export class FlatTreeRoleNode {
    constructor(
        public id: string,
        public name: string,
        public parentId: number,
        public level: number,
        public disabled: boolean,
        public role: any,
        public roles: any[],
        public expandable: boolean,
    ) { }
}
