<modal-window [id]="modalId" [title]="action" [confirmButtonText]="action" (onConfirm)="onModalConfirm()" (onCancel)="onModalCancel()" (onX)="onModalX()" [isConfirmDisabled]="!canUpdate">
  <p *ngIf="showAreYouSure">Are you sure you want to {{ action | lowercase }} the {{ entityName }}? </p>
  <p *ngIf="totalEntityCount > 1 && canUpdate">You are attempting to {{ action | lowercase }} {{ totalEntityCount }} records<ng-container *ngIf="entitiesWithNoPermissionCount > 0">, of which you have sufficient permissions to {{action}} {{ totalEntityCount - entitiesWithNoPermissionCount }}</ng-container>. Proceed by selecting "{{action}}" below. </p>
  <p *ngIf="!canUpdate">You do not have permission to {{ action | lowercase }} the selected record(s)</p>
  <ng-container *ngIf="canUpdate">
    <p *ngIf="recordName"><strong>{{ recordName }}</strong></p>
    <p *ngIf="showFirstObjectRecordName"><strong>{{objectsToUpdate[0].name}}</strong></p>
    <ng-content *ngIf="!recordName">
    </ng-content>
  </ng-container>
</modal-window>
