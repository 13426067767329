import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicFormControlComponent } from '@components/dynamic-form-control/dynamic-form-control.component';
import { ValidateFileExtension } from '@utilities/validators/file-extension.validator';
import { ValidateFileSize } from '@utilities/validators/file-size.validator';

@Component({
    selector: 'dynamic-form-control-file-upload',
    templateUrl: './dynamic-form-control-file-upload.component.html',
    styleUrls: ['./dynamic-form-control-file-upload.component.scss'],
})
export class DynamicFormControlFileUploadComponent extends DynamicFormControlComponent implements OnInit  {
    form: UntypedFormGroup;
    @ViewChild('fileInput') fileInput;

    fileUpload: any;
    downloadUrl: string;
    uploadUrl: string;
    dupFound: boolean;

    private fileInputName = new UntypedFormControl('', [Validators.required, ValidateFileExtension]);
    private fileInputSize = new UntypedFormControl(0, [ValidateFileSize]);

    constructor() {
        super();
    }

    ngOnInit() {
        this.form = new UntypedFormGroup({
            fileInputName: this.fileInputName,
            fileInputSize: this.fileInputSize,
        });
    }

    fieldHasErrors(fieldName: string) {
        return this.form.get(fieldName).errors
            && this.form.get(fieldName).errors.required;
    }

    onFileChange($event) {
        // reset validations
        this.controlData.showErrors = false;
        this.fileUpload = $event.target.files[0];
        this.fileInputName.setValue(this.fileUpload ? this.fileUpload.name : '');
        this.fileInputSize.setValue(this.fileUpload ? this.fileUpload.size : '');

        // don't override a user-entered title value
        if (!this.form.get('title').dirty) {
            this.form.controls['title'].setValue(this.fileUpload ? this.fileUpload.name : '');
        }
    }
}
