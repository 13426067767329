import { Component, Input, OnInit } from '@angular/core';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { Address } from '@models/address.model';
import { ApiFactory } from '@services/core/api-factory.class';

@Component({
    selector: 'address-item',
    templateUrl: './address.component.html',
})
export class AddressComponent implements OnInit {
    private _model: Address;
    get model(): Address {
        return this._model;
    }
    @Input() set model(value: Address) {
        this._model = value;
        this.setAddress();
    }

    labelPrefix = '';

    constructor() {
    }

    ngOnInit() { }

    setAddress() {
        if (this.model) {
            this.setCountry();
            this.setState();
            this.setCounty();
            if (this.model.labelPrefix) {
                this.labelPrefix = this.model.labelPrefix + ' ';
            }
        }
    }

    setState() {
        if (!this.model.stateName.value && this.model.stateId) {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.State)
                .addDataEntry('id', this.model.stateId)
                .addSuccessHandler((d: any) => {
                    // todo: null check?
                    this.model.stateName.value = d.stateName;
                })
                .buildAndSend();
        }
    }

    setCounty() {
        if (!this.model.countyName.value && this.model.countyId) {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.County)
                .addDataEntry('id', this.model.countyId)
                .addSuccessHandler((d: any) => {
                    this.model.countyName.value = d.countyName;
                })
                .buildAndSend();
        }
    }

    setCountry() {
        if (!this.model.countryName.value && this.model.countryId) {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.Country)
                .addDataEntry('id', this.model.countryId)
                .addSuccessHandler((d: any) => {
                    this.model.countryName.value = d.countyName;
                })
                .buildAndSend();
        }
    }
}
