import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ApiServiceBase } from '@models/api-service-base.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { isValidArray } from '@utilities/helpers';

@Injectable()
export class LocationsApiService extends ApiServiceBase {

    @Output() LocationsEmitter = new EventEmitter<any>();

    private canDeleteLocationUrl = (companyId: string, id: string) =>
        `Locations/CanDelete?CompanyId=${companyId}&Id=${id}`
    private getLocationsByCompanyIdUrl = (companyId: string) => `Locations/Get?CompanyId=${companyId}`;   
    constructor() {
        super();
    }

    canDeleteLocation(companyId: string, id: string): Observable<boolean> {
        return this.get(this.canDeleteLocationUrl(companyId, id));
    }

    getLocationsByCompanyId(companyId: string): Observable<any> {
        return this.get(this.getLocationsByCompanyIdUrl(companyId));
    }

    async canDeleteManyCheck(ids: any[]): Promise<any> {
        const response =  await this.simpleGridActionUpdate(ids, 'CanDeleteMany');
        const responseList = response.map((r) => {
                            return {
                                id: r.item1,
                                canBeDeleted: r.item2,
                            }
                        });
        return Promise.resolve(responseList);
    }

    async deleteMany(ids: any[]): Promise<any> {
        return await this.simpleGridActionUpdate(ids, 'DeleteMany');
    }

    async makeInactiveMany(items): Promise<any> {
        return await this.gridActionUpdate(items, 'UpdateMany');
    }

    async gridActionUpdate(items, routeHint:string): Promise<any> {
        if (!isValidArray(items)) return null;

        return new Promise<any>((resolve) => {
            ApiFactory.updateEntity(ApiEntityTypesEnum.Location, items)
                .addRouteHint(routeHint)
                .addSuccessHandler((response) => {
                    resolve(response);
                })
                .addErrorHandler((response) => {
                    resolve(response);
                })
                .buildAndSend();
            });
    }

    async simpleGridActionUpdate(ids: any[], routeHint:string): Promise<any> {
        if (!isValidArray(ids)) return null;

        return new Promise<any>((resolve) => {
            ApiFactory.updateEntity(ApiEntityTypesEnum.Location, ids)
                .addRouteHint(routeHint)
                .addBodyEntry(ids)
                .addSuccessHandler((response) => {
                    resolve(response);
                })
                .addErrorHandler((response) => {
                    resolve(response);
                })
                .buildAndSend();
            });
    }

    async GetDropDownListViewModel() {
        await new Promise<void>((resolve) => {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.Location)
                .addRouteHint('GetDropdownListViewModel')
                .addSuccessHandler((response) => { this.LocationsEmitter.emit(response); resolve(); })
                .addErrorHandler(() => resolve())
                .removePaging()
                .buildAndSend();
        });
    }
  
}
