import { QuestionType } from './question-type';
import { INextItemToShow } from './sms-next-question-to-show.model';
import { IQuestion } from './sms-question-definition.model';

export class Question implements IQuestion {
    id: number;
    type: QuestionType;
    displayText: string;
    nextItemToShow: INextItemToShow;

    constructor(id, type, text, nextItemToShow?) {
        this.id = id;
        this.type = type;
        this.displayText = text;
        this.nextItemToShow = nextItemToShow;
    }
}
