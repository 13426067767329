import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DynamicFormControlComponent } from '@components/dynamic-form-control/dynamic-form-control.component';

@Component({
    selector: 'dynamic-form-control-select',
    templateUrl: './dynamic-form-control-select.component.html',
    styleUrls: ['../dynamic-form-control/dynamic-form-control.component.scss'],
})
export class DynamicFormControlSelectComponent extends DynamicFormControlComponent implements OnChanges, OnDestroy, AfterViewInit {
    @Input() addNew: boolean;
    @Output() openAddNew = new EventEmitter<any[]>();
    @Input() defaultValue = null;
    @Input() resetControl: Observable<void>;

    private _selectedValue;
    get selectedValue() {
        return this._selectedValue;
    }
    set selectedValue(val) {
        this._selectedValue = val;
        this.controlData.patchValue(this.selectedValue);
    }
    formIsSubmitted$ = new Subscription();
    isGrouped: boolean;

    constructor() {
        super();
    }

    onControlDataUpdate() {
        super.onControlDataUpdate();
        if (this.controlData) {
            if (this.controlData.configOptions) {
                this.isGrouped = this.controlData.configOptions.isGrouped || false;
            }
            this.selectedValue = this.controlData.value;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.controlData && changes.controlData.isFirstChange) {
            this.defaultValue = changes.controlData.currentValue.value;
        }
    }

    ngOnDestroy() {
        this.formIsSubmitted$.unsubscribe();
    }

    ngAfterViewInit(): void {
        // removes browser default autocomplete for IE/Edge
        $('ng-select input').attr('autocomplete', 'off');
    }

    openAddNewForm() {
        this.openAddNew.emit();
    }
}
