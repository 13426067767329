import { Component, HostListener, OnDestroy, Output } from '@angular/core';
import * as moment from 'moment';
import { ACTIONS, TOPICS } from '@constants/messages.constants';
import { environment } from '@env/environment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { EnableMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageTypeEnum } from '@modules/messaging/baseClasses/MessageTypeEnum';
import { AuthService } from '@services/auth.service';
import { MessageBusService } from '../../../../modules/messaging/services/messageBusService';

@Component({
    selector: 'layout-timeout-modal',
    templateUrl: './layout-timeout-modal.component.html',
    styleUrls: ['./layout-timeout-modal.component.scss'],
})
export class LayoutTimeoutModalComponent extends EnableMessageProcessingDirective implements OnDestroy {

    @Output()
    countDownMsg: string;

    @Output()
    sessionExpired = false;

    @Output()
    loginoutText = 'Log Out';

    expirationValue = moment();
    expirationTimer: any;
    isVisible = false;

    constructor(mb: MessageBusService,
                private authService: AuthService) {
        super(mb);
    }

    @HostListener('window:popstate', ['$event'])
    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHander() {
        if (this.sessionExpired) {
            this.authService.logout();
        }
    }

    manageCountDownDisplay(): void {
        if (!LoggedInUserInfo.Instance.isAuthorized) { this.stopCountdown(); return; }
        if (this.expirationValue.minutes() === 0) {
            this.countDownMsg = `Your session will expire in ${this.expirationValue.subtract(1, 'second').format('ss')} Seconds`;
            if (this.expirationValue.seconds() === 0) {
                this.stopCountdown();
            }
        } else {
            this.countDownMsg = `Your session will expire in ${this.expirationValue.subtract(1, 'second').format('m:ss')} Minutes`;
        }
    }

    protected configureMessageBus(builder: IMsgProcessingReg): void {
        builder
            .add()
            .inBoundDataTopic(TOPICS.APPLEVEL.SYSTEM)
            .listenForMessageType(MessageTypeEnum.SystemEventMessage)
            .withAction(ACTIONS.AUTH.SESSION_EXPIRED)
            .thenCallFunction(() => this.openTimeoutModal());
    }

    openTimeoutModal() {
        if (this.isVisible) { return; }
        this.isVisible = true;
        ($('#timeoutModal') as any).modal('show');
        this.expirationValue = moment({ minute: environment.sessionFinalWarningTimerInMinutes });
        this.manageCountDownDisplay();
        this.expirationTimer = setInterval(() => this.manageCountDownDisplay(), 1000);
    }

    hideModal() {
        clearInterval(this.expirationTimer);
        ($('#timeoutModal') as any).modal('hide');
        this.isVisible = false;
    }

    stopCountdown() {
        clearInterval(this.expirationTimer);
        this.sessionExpired = true;
        this.loginoutText = 'Log In';
        this.countDownMsg = `Your session has expired. Any unsaved data will be lost.`;
    }
}
