import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ACTIONS, DATAKEYS } from '@constants/messages.constants';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { environment } from '@env/environment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { EnableMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { NavigationEntry } from '@modules/navigation/NavigationEntry';
import { SmsNavigationManager } from '@modules/navigation/sms-navigation-manager.class';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { SplitioService } from '@services/splitio.service';

@Component({
  selector: 'azure-login-response',
  template: '',
})

export class AzureLoginResponseComponent extends EnableMessageProcessingDirective implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private navigator: SmsNavigationManager,
    mb: MessageBusService,
    private splitioService: SplitioService) {
    super(mb);
  }

  public static FORGOTPASSWORD_ERRORCODE = () => 'AADB2C90118';
  public static CANCELLED_FORGET_PASSWORD = () => 'AADB2C90091';
  b2cOktaToggle = false;

  protected configureMessageBus(builder: IMsgProcessingReg): void {}

  private get errorEncountered(): boolean {
    return this.activatedRoute.snapshot.queryParams['error_description'] || (this.activatedRoute.snapshot.fragment && this.activatedRoute.snapshot.fragment.startsWith('error'));
  }

  private get errorDescription(): string {
    let errorDescription = this.activatedRoute.snapshot.queryParams['error_description'];
    if (!errorDescription) {
      const key = 'error_description=';
      const decLoc = this.activatedRoute.snapshot.fragment.indexOf(key) + key.length;
      errorDescription = this.activatedRoute.snapshot.fragment.substring(decLoc);
    }
    return errorDescription;
  }

  async ngOnInit() {
    super.ngOnInit();

    // state param will come through like this:
    // state=<redirect>:<token>:<materialcode>:<accountNumber>:<promocode>
    // redirect: should be set in all cases; is the target URL that the user will be redirected to
    // token: will be set only when the link came from an invitation link; used to verify the user is using the right e-mail
    // materialcode: will often be empty, but can be used to set the material code set for a new trial
    // accountNumber: will often be empty, but can be used to set the SAP account number for a subscription
    // promocode: will often be empty, but can be used to set the promo code for a new trial

    const stateParam = (this.activatedRoute.snapshot.queryParams['state'] as string)?.split(':');

    const targetUrl = stateParam[0];
    const invitationToken = stateParam[1] || '';
    const materialCode = stateParam[2] || '';
    const accountNumber = stateParam[3] || '';
    const promoCode = stateParam[4] || '';

    if (this.errorEncountered) {
      const forgotPassword = await this.didUserForgetPassword();
      if (forgotPassword) {
        LoggedInUserInfo.Instance.resetLoggedInUserData();

        const msg = this.msgBuilder.SystemEventMessage().usingHostSenderId()
          .then
          .addData(DATAKEYS.APPLEVEL.ROUTE.URL, targetUrl)
          .assignAction(ACTIONS.AUTH.REQUEST_PASSWORD_RESET)
          .sendOn.systemTopic()
          .build();

        this.sendTopicMessage(msg);

        return;
      }

      if (this.errorDescription.indexOf(AzureLoginResponseComponent.CANCELLED_FORGET_PASSWORD()) < 0) {

        this.navigator.navigateTo(await NavigationEntry.FromRouteString(targetUrl));
        return;
      }

      this.navigator.navigateTo(await NavigationEntry.FromRouteString('/fatal'));
      return;
    }

    // A user has logged into Azure
    let authCode = this.activatedRoute.snapshot.queryParams['code'];
    this.b2cOktaToggle = (await this.splitioService.getToggle(TOGGLES.FORGOTPASSWORD_B2C_CREATE_OKTA)) === TREATMENT.ON;
    if (authCode) {
      if(this.b2cOktaToggle){
        window.localStorage.setItem('message', 'Please use the Forgot Password link to reset your credentials.');
        this.navigator.navigateTo(await NavigationEntry.FromRouteString(AuthRoutes.Login.urlRoute));
        return;
      }
      await this.processLoginResponseFromAzure(authCode, targetUrl, invitationToken, materialCode, accountNumber, promoCode);
      return;
    }

    // A new user has been created in Azure
    authCode = this.activatedRoute.snapshot.queryParams['id_token'];
    if (authCode) {
      await this.processCreateNewUserResponseFromAzure(authCode, targetUrl, invitationToken, materialCode, accountNumber, promoCode);
      return;
    }

    // If we get here, then we had neither of the responses we expected!
    throw new EvalError('The expected [code] value was not present in the response!');

  }

  /**
   *  Step 1 of the Login process is to get an Authorization Code which we later convert
   *  to an Auth Token which is used to identify capability as defined by Azure scopes.
   * @param queryParams The collection of values from the Azure login request
   * @param targetUrl The location the user had intended to visit once authenticated
   */
  processLoginResponseFromAzure(authCode: string, targetUrl: string, invitationToken: string,
                                materialCode: string, accountNumber: string, promoCode: string) {
    try {
      // since we have a code, we now need to convert it to an AuthToken which occurs at a different Endpoint
      const msg = this.msgBuilder.SystemEventMessage().usingHostSenderId()
        .then
        .addData(DATAKEYS.AUTH.AUTH_CODE, authCode)
        .addData(DATAKEYS.GENERAL.DATA, targetUrl)
        .addData(DATAKEYS.APPLEVEL.ROUTE.INVITE_TOKEN, invitationToken)
        .addData(DATAKEYS.APPLEVEL.ROUTE.MATERIAL_CODE, materialCode)
        .addData(DATAKEYS.APPLEVEL.ROUTE.ACCOUNT_NUMBER, accountNumber)
        .addData(DATAKEYS.APPLEVEL.ROUTE.PROMO_CODE, promoCode)
        .addData(DATAKEYS.AUTH.CLIENT_ID, environment.azureEndpoints.clientIdentinfier)
        .and
        .assignAction(ACTIONS.AUTH.AUTH_REQUEST_AUTH_TOKEN)
        .sendOn.systemTopic()
        .build();

      this.sendTopicMessage(msg);
    } catch (e) {

    }
  }

  processCreateNewUserResponseFromAzure(id_token: string, targetUrl: string, invitationToken: string,
                                        materialCode: string, accountNumber: string, promoCode: string) {
    const msg = this.msgBuilder.SystemEventMessage().usingHostSenderId()
      .then
      .addData(DATAKEYS.AUTH.ID_TOKEN, id_token)
      .addData(DATAKEYS.APPLEVEL.ROUTE.URL, targetUrl)
      .addData(DATAKEYS.APPLEVEL.ROUTE.INVITE_TOKEN, invitationToken)
      .addData(DATAKEYS.APPLEVEL.ROUTE.MATERIAL_CODE, materialCode)
      .addData(DATAKEYS.APPLEVEL.ROUTE.ACCOUNT_NUMBER, accountNumber)
      .addData(DATAKEYS.APPLEVEL.ROUTE.PROMO_CODE, promoCode)
      .addData(DATAKEYS.AUTH.CLIENT_ID, environment.azureEndpoints.clientIdentinfier)
      .and
      .assignAction(ACTIONS.AUTH.CREATE_NEW_SMS_ACCOUNT)
      .sendOn.systemTopic()
      .build();

    this.sendTopicMessage(msg);
  }

  /**
   * An error does not necessarily mean the system was unable to process the user request.  When
   * the user claims to have lost or forgotten a password, it is reflected as an error with a
   * specific code. We check for that code first and act accordingly.  Otherwise, it is an error
   * which is unrecoverable.
   */
  async didUserForgetPassword(): Promise<boolean> {
    const intendedUrl = this.activatedRoute.snapshot.queryParams['state'] as string;

    // Did the user forget their password?
    if (this.errorEncountered) {
      if (this.errorDescription.indexOf(AzureLoginResponseComponent.FORGOTPASSWORD_ERRORCODE()) > -1) {
        this.sendRequestLostPassword(intendedUrl);
        return true;
      }
    }

    return false;
  }

  sendRequestLostPassword(intendedUrl: string) {
    const msg = this.msgBuilder.SystemEventMessage().usingHostSenderId()
      .then
      .addData(DATAKEYS.APPLEVEL.ROUTE.URL, intendedUrl)
      .assignAction(ACTIONS.AUTH.REQUEST_PASSWORD_RESET).sendOn.systemTopic()
      .build();
    this.sendTopicMessage(msg);

  }
}
