<div class="profile dropdown">
  <a class="avatar" role="button" id="myAccount" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tabindex="0">
    <span class="circle" *ngIf="!hasAvatar">{{userAvatar}}</span>
    <img class="circle" *ngIf="hasAvatar" src="{{avatar}}" onerror="this.src='assets/images/user-circle.svg'" alt="Safety Management Suite">
    <i class="fas fa-angle-down down-arrow"></i>
  </a>
  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="myAccount" x-placement="bottom-end" style="position: absolute; transform: translate3d(-114px, 29px, 0px); top: 0px; left: 0px; will-change: transform;">
    <div class="user-profile-values dropdown-item">
      <h3 [ngClass]="{'noBottomMargin': isSapAdmin}">{{userName}}</h3>
      <p *ngIf="isSapAdmin" class="accountAdmin">Account Administrator</p>
      <!-- <h4>TSO Group &nbsp; <a href="#">Change</a></h4> -->
    </div>
    <layout-menu-options></layout-menu-options>
  </div>
</div>