import { MessageAction } from './MessageAction';
import { Message } from './Messages/Message';
import { MessageType } from './messageType';

/** This class is used internally to represent an action and the function
 * to invoke when the action is received.
*/
export class MessageHandlerRegistration<T extends Message> {

    // is this for UI or Data
    public relatesToData = true;
    public forTopic = null;
    public messageAction: MessageAction = null;
    public handler: (message: T) => void;
    public isActive: true;
    public messageType: MessageType = null;
}
