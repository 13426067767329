import { Message } from '../types/Messages/Message';
import { ITopicRecipient } from './ITopicRecipient';

/** ***********************************************************************************************
 *  Since interfaces are a Typescript only concept, we declare our "interface" as an abstract
 * class.  It is important to note that while the class can be EXTENDED, to treat it as an
 * interface, import { IBroadcastRecipient } from "@app/core/messaging/interfaces/IBroadcastRecipient";
 * IMPLEMENT iimport { Message } from "./../types/Message";
t instead.  Thimport { Message } from "./../types/Message";
is Interface is used to capture the core functionality of
 * a message distribution system.
 * ************************************************************************************************/
export abstract class IMessageDistributor {
    public abstract distributeTopics(recipients: ITopicRecipient[], message: Message);
}
