import { Component, Input, OnInit } from '@angular/core';
import { getAlphaIndexArray, isNumeric } from '@utilities/helpers';

@Component({
  selector: 'alpha-index',
  templateUrl: './alpha-index.component.html',
  styleUrls: ['./alpha-index.component.scss'],
})
export class AlphaIndexComponent implements OnInit {
  private alpha: string[];

  @Input() dataSource: any[];
  @Input() idField: (t: any) => any;
  @Input() descriptionField: (t: any) => any;
  @Input() routerLink: any;
  @Input() showEmptyIndexes = true;

  constructor() {
  }

  ngOnInit() {
    this.alpha = getAlphaIndexArray();
  }

  dataExists() {
    return this.dataSource && this.dataSource.length > 0 && this.descriptionField;
  }

  dataExistsFor(index: string) {
    if (!this.dataSource || this.dataSource.length === 0 || !this.descriptionField) { return false; }

    return index === '#' ? this.dataSource.map(this.descriptionField).find((i) => i.length > 0 && isNumeric(i[0])) :
      this.dataSource.map(this.descriptionField).find((i) => i.toUpperCase().startsWith(index));
  }

  getItemsFor(alpha: string) {
    return alpha === '#' ? this.dataSource.filter((i) => this.descriptionField(i).length > 0 && isNumeric(this.descriptionField(i)[0])) :
      this.dataSource.filter((i) => this.descriptionField(i).toUpperCase().startsWith(alpha));
  }

  getLinkFor(data: any) {
    return this.routerLink && this.idField ? `${this.routerLink}/${this.idField(data)}` : '.';
  }
}
