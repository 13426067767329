// AUTO-GENERATED CODE
//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { AuditScheduleTemplate } from './auditscheduletemplate';
import { BinderVersionLocation } from './binderversionlocation';
import { ChecklistLocation } from './checklistlocation';
import { Company } from './company';
import { CompanyUsage } from './companyusage';
import { Country } from './country';
import { County } from './county';
import { Employee } from './employee';
import { EmploymentDataMonthly } from './employmentdatamonthly';
import { EmploymentDataYearly } from './employmentdatayearly';
import { Equipment } from './equipment';
import { Event } from './event';
import { GeneralInformation } from './generalinformation';
import { GeneralInformationHistory } from './generalinformationhistory';
import { Groups } from './groups';
import { IncidentIntake } from './incidentintake';
import { NaicsCode } from './naicscode';
import { NonAuth } from './nonauth';
import { NumberingSequenceLocation } from './numberingsequencelocation';
import { State } from './state';
import { Task } from './task';
import { User } from './user';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { IValidatorInfo, ValidatorInfo } from '@models/validations/validatorInfo';
import { IModelRecord, IModelAudit, IModelBase } from '@models/interfaces/entity-interfaces';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
export class Location implements IModelRecord, IModelAudit {

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
        address1: string;
        address2: string;
        city: string;
        companyId: string;
        countryId: string;
        countyId?: string;
        createdByUserId: string;
        createdDate: Date;
        establishmentType?: number;
        fein: string;
        groupId: string;
        id: string;
        impersonationCreatedByUserId?: string;
        impersonationModifiedByUserId?: string;
        isActive?: boolean;
        isDeleted: boolean;
        isShortTerm: boolean;
        locationCode: string;
        modifiedByUserId?: string;
        modifiedDate?: Date;
        naicsCodeId?: string;
        name: string;
        natureOfBusiness: string;
        stateId?: string;
        zipCode: string;
        country: Country;
        county: County;
        createdByUser: User;
        group: Groups;
        modifiedByUser: User;
        naicsCode1: NaicsCode;
        state: State;
        permissions: number;

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    constructor(json?: Partial<Location>) {
        this.address1 = json && json.address1;
        this.address2 = json && json.address2;
        this.city = json && json.city;
        this.companyId = json && json.companyId;
        this.countryId = json && json.countryId;
        this.countyId = json && json.countyId;
        this.createdByUserId = json && json.createdByUserId;
        this.createdDate = (!json || json.createdDate === undefined) ? null : (json.createdDate === null ? new Date() : json.createdDate);
        this.establishmentType = json && json.establishmentType;
        this.fein = json && json.fein;
        this.groupId = json && json.groupId;
        this.id = json && json.id;
        this.impersonationCreatedByUserId = json && json.impersonationCreatedByUserId;
        this.impersonationModifiedByUserId = json && json.impersonationModifiedByUserId;
        this.isActive = json && json.isActive;
        this.isDeleted = json && json.isDeleted;
        this.isShortTerm = json && json.isShortTerm;
        this.locationCode = json && json.locationCode;
        this.modifiedByUserId = json && json.modifiedByUserId;
        this.modifiedDate = (!json || json.modifiedDate === undefined) ? null : json.modifiedDate;
        this.naicsCodeId = json && json.naicsCodeId;
        this.name = json && json.name;
        this.natureOfBusiness = json && json.natureOfBusiness;
        this.stateId = json && json.stateId;
        this.zipCode = json && json.zipCode;
        this.country = (json && new Country(json.country));
        this.county = (json && new County(json.county));
        this.createdByUser = (json && new User(json.createdByUser));
        this.group = (json && new Groups(json.group));
        this.modifiedByUser = (json && new User(json.modifiedByUser));
        this.naicsCode1 = (json && new NaicsCode(json.naicsCode1));
        this.state = (json && new State(json.state));
        this.permissions = json && json.permissions || 0;

    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validateInfo(fieldName: string): IValidatorInfo {
        switch (fieldName) {
        case 'address1': {
            return {
                required: true,
                minLength: undefined, maxLength: 100,
                type: 'string',
            };
        }
        case 'address2': {
            return {
                required: false,
                minLength: undefined, maxLength: 100,
                type: 'string',
            };
        }
        case 'city': {
            return {
                required: true,
                minLength: undefined, maxLength: 50,
                type: 'string',
            };
        }
        case 'companyId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'countryId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'countyId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'createdByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'createdDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'establishmentType': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'number',
            };
        }
        case 'fein': {
            return {
                required: false,
                minLength: undefined, maxLength: 9,
                type: 'string',
            };
        }
        case 'groupId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'id': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationCreatedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationModifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'isActive': {
            return {
                required: true,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'isDeleted': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'isShortTerm': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'locationCode': {
            return {
                required: false,
                minLength: undefined, maxLength: 30,
                type: 'string',
            };
        }
        case 'modifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'modifiedDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'naicsCodeId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'name': {
            return {
                required: true,
                minLength: undefined, maxLength: 100,
                type: 'string',
            };
        }
        case 'natureOfBusiness': {
            return {
                required: false,
                minLength: undefined, maxLength: 50,
                type: 'string',
            };
        }
        case 'stateId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'zipCode': {
            return {
                required: false,
                minLength: undefined, maxLength: 15,
                type: 'string',
            };
        }
            default: return { required: false, minLength: undefined, maxLength: undefined, type: undefined };
        }
    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validate(skip: string[] = []): [number, number, string[]?] {
        let count = 0;
        const errorFields = [];
        if (!skip.includes('address1') && ValidatorInfo.validateString(this.address1, this._validateInfo('address1'))) {
            count++;
            errorFields.push('address1');
        }
        if (!skip.includes('address2') && ValidatorInfo.validateString(this.address2, this._validateInfo('address2'))) {
            count++;
            errorFields.push('address2');
        }
        if (!skip.includes('city') && ValidatorInfo.validateString(this.city, this._validateInfo('city'))) {
            count++;
            errorFields.push('city');
        }
        if (!skip.includes('companyId') && ValidatorInfo.validateString(this.companyId, this._validateInfo('companyId'))) {
            count++;
            errorFields.push('companyId');
        }
        if (!skip.includes('countryId') && ValidatorInfo.validateString(this.countryId, this._validateInfo('countryId'))) {
            count++;
            errorFields.push('countryId');
        }
        if (!skip.includes('countyId') && ValidatorInfo.validateString(this.countyId, this._validateInfo('countyId'))) {
            count++;
            errorFields.push('countyId');
        }
        if (!skip.includes('createdByUserId') && ValidatorInfo.validateString(this.createdByUserId, this._validateInfo('createdByUserId'))) {
            count++;
            errorFields.push('createdByUserId');
        }
        if (!skip.includes('createdDate') && ValidatorInfo.validateDate(this.createdDate, this._validateInfo('createdDate'))) {
            count++;
            errorFields.push('createdDate');
        }
        if (!skip.includes('establishmentType') && ValidatorInfo.validateNumber(this.establishmentType, this._validateInfo('establishmentType'))) {
            count++;
            errorFields.push('establishmentType');
        }
        if (!skip.includes('fein') && ValidatorInfo.validateString(this.fein, this._validateInfo('fein'))) {
            count++;
            errorFields.push('fein');
        }
        if (!skip.includes('groupId') && ValidatorInfo.validateString(this.groupId, this._validateInfo('groupId'))) {
            count++;
            errorFields.push('groupId');
        }
        if (!skip.includes('id') && ValidatorInfo.validateString(this.id, this._validateInfo('id'))) {
            count++;
            errorFields.push('id');
        }
        if (!skip.includes('impersonationCreatedByUserId') && ValidatorInfo.validateString(this.impersonationCreatedByUserId, this._validateInfo('impersonationCreatedByUserId'))) {
            count++;
            errorFields.push('impersonationCreatedByUserId');
        }
        if (!skip.includes('impersonationModifiedByUserId') && ValidatorInfo.validateString(this.impersonationModifiedByUserId, this._validateInfo('impersonationModifiedByUserId'))) {
            count++;
            errorFields.push('impersonationModifiedByUserId');
        }
        if (!skip.includes('isActive') && ValidatorInfo.validateString(this.isActive, this._validateInfo('isActive'))) {
            count++;
            errorFields.push('isActive');
        }
        if (!skip.includes('isDeleted') && ValidatorInfo.validateString(this.isDeleted, this._validateInfo('isDeleted'))) {
            count++;
            errorFields.push('isDeleted');
        }
        if (!skip.includes('isShortTerm') && ValidatorInfo.validateString(this.isShortTerm, this._validateInfo('isShortTerm'))) {
            count++;
            errorFields.push('isShortTerm');
        }
        if (!skip.includes('locationCode') && ValidatorInfo.validateString(this.locationCode, this._validateInfo('locationCode'))) {
            count++;
            errorFields.push('locationCode');
        }
        if (!skip.includes('modifiedByUserId') && ValidatorInfo.validateString(this.modifiedByUserId, this._validateInfo('modifiedByUserId'))) {
            count++;
            errorFields.push('modifiedByUserId');
        }
        if (!skip.includes('modifiedDate') && ValidatorInfo.validateDate(this.modifiedDate, this._validateInfo('modifiedDate'))) {
            count++;
            errorFields.push('modifiedDate');
        }
        if (!skip.includes('naicsCodeId') && ValidatorInfo.validateString(this.naicsCodeId, this._validateInfo('naicsCodeId'))) {
            count++;
            errorFields.push('naicsCodeId');
        }
        if (!skip.includes('name') && ValidatorInfo.validateString(this.name, this._validateInfo('name'))) {
            count++;
            errorFields.push('name');
        }
        if (!skip.includes('natureOfBusiness') && ValidatorInfo.validateString(this.natureOfBusiness, this._validateInfo('natureOfBusiness'))) {
            count++;
            errorFields.push('natureOfBusiness');
        }
        if (!skip.includes('stateId') && ValidatorInfo.validateString(this.stateId, this._validateInfo('stateId'))) {
            count++;
            errorFields.push('stateId');
        }
        if (!skip.includes('zipCode') && ValidatorInfo.validateString(this.zipCode, this._validateInfo('zipCode'))) {
            count++;
            errorFields.push('zipCode');
        }
        return [count, 8, errorFields];
    }
}
