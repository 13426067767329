import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { isNullOrUndefined } from '@utilities/helpers';

@Directive({
  selector: '[jjkPercentage]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PercentageDirective),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PercentageDirective),
      multi: true,
    },
  ],
})
export class PercentageDirective implements ControlValueAccessor, Validator {

  private navigationKeys = ['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'Home',
    'End', 'ArrowLeft', 'ArrowRight', 'Clear', 'Copy', 'Paste', 'Control', 'Shift',
  ];

  private validatorFn = Validators.pattern(new RegExp(/^(100|^\d{1,2})$/));

  private _value: any;
  @Input()
  public get value(): any {
    return this._value;
  }
  public set value(value: any) {
    this._value = value;
  }

  onChange: (value: any) => void;
  onTouch: () => void;
  onValidatorChange: () => void;

  constructor(private el: ElementRef) {  }

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    this.validateInputKeys(e);
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    event.preventDefault();
  }

  @HostListener('blur', ['$event']) onBlur(event) {
    if (this.value && this.el.nativeElement.value.indexOf('%') === -1) {
      this.el.nativeElement.value = this.el.nativeElement.value + '%';
    }
  }

  @HostListener('input', ['$event']) onInput(event) {
    this.value = event.target.value;
    this.onTouch();
    this.onChange(event.target.value);
  }

  @HostListener('focus', ['$event']) onFocus(event) {
    this.el.nativeElement.value = this.value;
  }

  validateInputKeys(e: KeyboardEvent) {
    if (this.navigationKeys.indexOf(e.key) > -1) {
      return;
    }
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  writeValue(value: any): void {
    this.el.nativeElement.value = value;
    if (!isNullOrUndefined(value)) {
      this.el.nativeElement.value += '%';
    }
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.el.nativeElement.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors {
      return this.validatorFn(control);
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }
}
