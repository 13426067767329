<form [formGroup]="form" *ngIf="form">
  <div class="form-group" [class.has-danger]="shouldShowErrors('firstName')">
    <label for="firstName" class="small req form-label"><strong>First Name</strong></label>
    <input name="firstName" class="form-control large-input" type="text" formControlName="firstName" maxlength="40" (blur)="trimOnBlur($event)">
  </div>

  <div class="form-group" [class.has-danger]="shouldShowErrors('lastName')">
    <label for="lastName" class="small req form-label"><strong>Last Name</strong></label>
    <input name="lastName" class="form-control large-input" type="text" formControlName="lastName" maxlength="40" (blur)="trimOnBlur($event)">
  </div>

  <div class="form-group" [class.has-danger]="shouldShowErrors('jobTitle')">
    <label for="jobTitle" class="small req form-label"><strong>Job Title</strong></label>
    <input name="jobTitle" class="form-control large-input" type="text" formControlName="jobTitle" maxlength="40" (blur)="trimOnBlur($event)">
  </div>

  <div class="form-group" [class.has-danger]="shouldShowErrors('phoneNum')">
    <label for="phoneNum" class="small req form-label"><strong>Phone Number</strong></label>
    <input name="phoneNum" class="form-control large-input" type="text" formControlName="phoneNum" maxlength="30">
    <div *ngIf="shouldShowErrors('phoneNum')" class="error-message">
      Valid phone number required
    </div>
  </div>

  <div class="form-group" [class.has-danger]="shouldShowErrors('phoneNumExt')">
    <label for="phoneNumExt" class="small form-label"><strong>Phone Number Extension</strong></label>
    <input name="phoneNumExt" class="form-control large-input" type="text" formControlName="phoneNumExt" maxlength="10" (blur)="trimOnBlur($event)">
  </div>

  <div class="form-group" [class.has-danger]="shouldShowErrors('companyName')">
    <label for="companyName" class="small req form-label"><strong>Company Name</strong></label>
    <input name="companyName" class="form-control large-input" type="text" formControlName="companyName" maxlength="90" (blur)="trimOnBlur($event)">
  </div>

  <div class="form-group" [class.has-danger]="shouldShowErrors('companyAddr1')">
    <label for="companyAddr1" class="small req form-label"><strong>Address 1</strong></label>
    <input name="companyAddr1" class="form-control large-input" type="text" formControlName="companyAddr1" maxlength="60" (blur)="trimOnBlur($event)">
  </div>

  <div class="form-group" [class.has-danger]="shouldShowErrors('companyAddr2')">
    <label for="companyAddr2" class="small form-label"><strong>Address 2</strong></label>
    <input name="companyAddr2" class="form-control large-input" type="text" formControlName="companyAddr2" maxlength="60" (blur)="trimOnBlur($event)">
  </div>

  <div class="form-group" [class.has-danger]="shouldShowErrors('companyCity')">
    <label for="companyCity" class="small req form-label"><strong>City</strong></label>
    <input name="companyCity" class="form-control large-input" type="text" formControlName="companyCity" maxlength="40" (blur)="trimOnBlur($event)">
  </div>

  <div class="form-group" [ngClass]="shouldShowErrors('companyCountryId') ? 'form-group-error' : ''">
    <label for="companyCountryId" class="req form-label">Country</label>
    <select class="form-control selectpicker" id="companyCountryId" formControlName="companyCountryId" [ngClass]="shouldShowErrors('companyCountryId') ? 'field-error' : ''">

      <option *ngFor="let country of countryList" value="{{ country.id }}">
        {{ country.countryName }}
      </option>
    </select>
  </div>

  <div class="form-group" *ngIf="stateList?.length" [class.has-danger]="shouldShowErrors('companyStateId')">
    <label for="companyStateId" class="req form-label">State/Province</label>
    <select class="form-control selectpicker" id="companyStateId" formControlName="companyStateId" [ngClass]="shouldShowErrors('companyStateId') ? 'field-error' : ''">
      <option [ngValue]="null" hidden>Choose State/Province</option>
      <option *ngFor="let state of stateList" value="{{ state.id }}">
        {{ state.stateName }}
      </option>
    </select>
  </div>

  <div class="form-group" [class.has-danger]="shouldShowErrors('companyPostalCode')">
    <label for="companyPostalCode" class= "form-label" [class.req]="this.stateList.length > 0"><strong>ZIP/Postal Code</strong></label>
    <input name="companyPostalCode" class="form-control large-input" type="text" formControlName="companyPostalCode" maxlength="10" (blur)="trimOnBlur($event)">
  </div>

  <section class="text-end">
    <button type="button" class="btn btn-primary" (click)="onFormSubmit()" [disabled]="isFormSubmitted">
      Save
    </button>
  </section>
</form>