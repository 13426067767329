import { Injectable } from '@angular/core';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { EntityDepGraph } from '@models/entity-models/autogenerated/entity-dependency-graph';
import { AutoIncrementingIdentifierDirective } from '@modules/messaging/baseClasses/AutoIncrementingIdentifier';

@Injectable()
export class CacheInvalidationEngine extends AutoIncrementingIdentifierDirective {

    public extractInvalidatedEntityGraph(apiEntity: ApiEntityTypesEnum): ApiEntityTypesEnum[] {
        const affectedEntities = (EntityDepGraph[apiEntity] as string[]);
        if (!affectedEntities) {
            return [ApiEntityTypesEnum.UNKNOWN];
        }

        const entitiesToPurge = affectedEntities.map((s) => {
            try {
                const apiEntry = ApiEntityTypesEnum[s];
                return apiEntry;
            } catch (e) {
                return ApiEntityTypesEnum.UNKNOWN;
            }
        }).concat([apiEntity]);
        return entitiesToPurge;
    }

    constructor() {
        super();
    }
}
