import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterType } from '@constants/advanced-filter/filter-type.enum';

@Component({
  selector: 'jjk-datagrid-advanced-filter-item',
  template: ``,
})
export class DataGridAdvancedFilterItemComponent {

  @Input() columnName: string;
  @Input() displayText: string;
  @Input() filterType: FilterType;
  @Input() groupBy?: string;
  @Input() dataSource?: Observable<string[]>;
  // for multiselect that get data asynchronously must recieve a string
  @Input() getOptionsFunction?: (searchTerm: string) => Promise<any[]>;
  @Input() hidden = false;

  constructor() {
  }
}
