import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { AdminSettingRoutes } from '@pages/admin-settings/admin-settings-routes.const';
import { AuditsRoutes } from '@pages/audits/audits-routes.const';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { ChemicalRoutes } from '@pages/chemicals/chemical-routes.const';
import { IncidentRoutes } from '@pages/incidents/Incident-routes.const';
import { SdsManagementRoutes } from '@pages/sds-management/sds-management-routes';
import { SettingRoutes } from '@pages/settings/settings-routes.const';
import { TopicsRoutes } from '@pages/topics/topics-routes.const';
import { TrainingRoutes } from '@pages/training/training-routes.const';

export const RootRoutes = {
    Auth: { urlRoute: '', children: { ...AuthRoutes } },
    Demo: { urlRoute: 'demo', associatedApiEntity: ApiEntityTypesEnum.UNKNOWN, breadcrumbText: '--DEMO--', authTag: '', children: {} },
    Forbidden: { urlRoute: 'forbidden', associatedApiEntity: ApiEntityTypesEnum.UNKNOWN, breadcrumbText: '', authTag: '', children: {} },
    Unauthorized: { urlRoute: 'unauthorized', associatedApiEntity: ApiEntityTypesEnum.UNKNOWN, breadcrumbText: '', authTag: '', children: {} },
    AuthRedirect: { urlRoute: 'auth-redirect', associatedApiEntity: ApiEntityTypesEnum.UNKNOWN, breadcrumbText: '', authTag: '', children: {} },
    Message: { urlRoute: 'message', associatedApiEntity: ApiEntityTypesEnum.UNKNOWN, breadcrumbText: '', authTag: '', children: {} },
    AdminSettings: { urlRoute: 'admin_settings', associatedApiEntity: ApiEntityTypesEnum.AdminSettings, breadcrumbText: 'Admin Settings', children: { ...AdminSettingRoutes } },

    Dashboard: { urlRoute: 'dashboard', authTag: 'dashboard-home', children: {} },
    Chemicals: { urlRoute: 'chemicals', authTag: 'dashboard-chemical', children: { ...ChemicalRoutes } },
    Incidents: { urlRoute: 'incidents', authTag: 'dashboard-incident', children: { ...IncidentRoutes } },
    Training: { urlRoute: 'training', authTag: 'dashboard-training', children: { ...TrainingRoutes } },
    Audits: { urlRoute: 'audits', authTag: 'dashboard-audit', children: { ...AuditsRoutes } },
    Topics: { urlRoute: 'topics', authTag: 'dashboard-topic', children: {} },
    TopicIndex: { urlRoute: 'topic_index', breadcrumbText: 'Topic Index', authTag: 'topic', children: {...TopicsRoutes} },
    SdsManagement: { urlRoute: 'sds_management', authTag: 'sds-management', children: { ...SdsManagementRoutes } },

    Tasks: { urlRoute: 'tasks', associatedApiEntity: ApiEntityTypesEnum.Task, breadcrumbText: 'task', authTag: 'task', children: {} },
    CompanyData: { urlRoute: 'company_data', associatedApiEntity: ApiEntityTypesEnum.Company, breadcrumbText: 'Company Data', authTag: 'company-data', children: {} },
    Medical: { urlRoute: 'medical_recordkeeping', associatedApiEntity: ApiEntityTypesEnum.Medical, breadcrumbText: 'Medical', authTag: 'covid-medical,view', children: {} },
    ReportingCenter: { urlRoute: 'reporting_center', associatedApiEntity: ApiEntityTypesEnum.UNKNOWN, breadcrumbText: 'Reporting Center', authTag: 'reporting-center', children: {} },
    Locations: { urlRoute: 'locations', associatedApiEntity: ApiEntityTypesEnum.Location, breadcrumbText: 'Location', authTag: '', children: {} },

    PlansAndPolicies: { urlRoute: 'plans_policies', associatedApiEntity: ApiEntityTypesEnum.PlanAndPolicy, breadcrumbText: 'Plans&Policies', authTag: 'pnp', children: {} },
    RegSense: { urlRoute: 'regsense', associatedApiEntity: ApiEntityTypesEnum.EditorialAsa, breadcrumbText: 'RegSense', authTag: 'regsense', children: {} },
    News: { urlRoute: 'news', associatedApiEntity: ApiEntityTypesEnum.EditorialAsa, breadcrumbText: 'News', authTag: 'news', children: {} },
    Charts: { urlRoute: 'charts', breadcrumbText: 'Charts', children: {} },
    Webcasts: { urlRoute: 'webcasts', associatedApiEntity: ApiEntityTypesEnum.Webcast, breadcrumbText: 'Webcast', authTag: 'webcast', children: {} },

    Profile: { urlRoute: 'profile', breadcrumbText: 'Profile', authTag: '', children: {} },
    Settings: { urlRoute: 'settings', associatedApiEntity: ApiEntityTypesEnum.Settings, breadcrumbText: 'Settings', children: { ...SettingRoutes } },
    ImportExport: { urlRoute: 'import-export', breadcrumbText: 'Import-Export', authTag: 'import-export,view', children: {} },
};
