<div *ngIf="!groupingRow" class="actions-custom-column-">
    <!-- Dropdown Menu -->

    <span *ngIf="!showActionsMenu" style="color:#BCC5C9;">
        <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
    </span>


    <a *ngIf="showActionsMenu" role="button" id="editActions" data-bs-toggle="dropdown" aria-haspopup="true">
       <a><i class="fa fa-ellipsis-h" aria-hidden="true"></i></a>
    </a>

    <!-- Dropdown Items Container -->
    <div style="height: 211px; overflow-y: scroll; border: 0px;" class="dropdown-menu" aria-labelledby="editActions">
        <!-- Dropdown Items -->
        <ng-container *ngFor="let dropdownItem of customColumnParams;">
            <ng-container *featureToggle="dropdownItem.featureToggle">
                <ng-container *ngIf="dropdownItem.field || dropdownItem.routerLink">
                    <a *ngIf="dropdownItem.visible" class="dropdown-item"
                    (click)="onChildComponentActionEmit(dropdownItem.displayText)"
                    [routerLink]="row | buildRouterLink:dropdownItem">
                        <span *ngIf="dropdownItem.icon" [innerHTML]="dropdownItem.icon"></span> {{ dropdownItem.displayText }}
                    </a>
                </ng-container>
                <ng-container *ngIf="!dropdownItem.field && !dropdownItem.routerLink">
                    <span *ngIf="dropdownItem.visible" class="dropdown-item"
                    (click)="onChildComponentActionEmit(dropdownItem.displayText)">
                        <span *ngIf="dropdownItem.icon" [innerHTML]="dropdownItem.icon"></span>
                            {{ dropdownItem.displayText }}
                    </span>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
