import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { RootNavigationTypesEnum } from '@constants/enums/root-navigation-types.enum';

/*
This separate break out is used to build a complete route list at the app level while
maintaining Lazy loading of modules.  We need this information to restore a deep linking
request.
*/
export const IncidentRoutes = {
  DashboardIncidents: { urlRoute: '', associatedApiEntity: ApiEntityTypesEnum.Incident, breadcrumbText: 'Incidents Dashboard', children: { } },
  IncidentRecordKeeping: { urlRoute: 'incident_recordkeeping', associatedApiEntity: ApiEntityTypesEnum.Incident, breadcrumbText: 'Incident', children: {} },
  EmploymentData: { urlRoute: 'employment_data', associatedApiEntity: RootNavigationTypesEnum.EmploymentData, breadcrumbText: 'Employment Data', children: {} },
  IncidentOshaReports: { urlRoute: 'incident_osha_reports', associatedApiEntity: RootNavigationTypesEnum.IncidentOshaReports, breadcrumbText: 'Incident OSHA Reports', children: {} },
  ViewIncident: { urlRoute: 'incident_recordkeeping/:id', associatedApiEntity: ApiEntityTypesEnum.Incident, breadcrumbText: 'Incident', children: {} },
  EditGeneralInformation: { urlRoute: 'incident_recordkeeping/general_information/edit', associatedApiEntity: ApiEntityTypesEnum.Incident, breadcrumbText: 'Incident/Edit', children: {} },
  EditSpecificGeneralInformation: { urlRoute: 'incident_recordkeeping/general_information/edit/:id', associatedApiEntity: ApiEntityTypesEnum.Incident, breadcrumbText: 'Incident', children: {} },
  EditForm300: { urlRoute: 'incident_recordkeeping/form300/edit/:id', associatedApiEntity: ApiEntityTypesEnum.AdditionalForm300Info, breadcrumbText: 'Incident/Form 300', children: {} },
  EditAdditionalEmployeeInfo: { urlRoute: 'incident_recordkeeping/additional_employee_info/edit/:id', associatedApiEntity: ApiEntityTypesEnum.AdditionalEmployeeInfo, breadcrumbText: 'Incident/AdditionalEmployeeInfo', children: {} },
  EditInvestigation: { urlRoute: 'incident_recordkeeping/investigation/edit/:id', associatedApiEntity: ApiEntityTypesEnum.Investigation, breadcrumbText: 'Incident/Investigation', children: {} },
  EditAdditionalForm301Info: { urlRoute: 'incident_recordkeeping/additional_form301_info/edit/:id', associatedApiEntity: ApiEntityTypesEnum.AdditionalForm301Info, breadcrumbText: 'Incident/Form 301', children: {} },
  EditInjuryIllness: { urlRoute: 'incident_recordkeeping/injuryillness/edit/:id', associatedApiEntity: ApiEntityTypesEnum.Incident, breadcrumbText: 'Incident/Injury', children: {} },
  Form301ReportMultiple: { urlRoute: 'reporting/form301', associatedApiEntity: ApiEntityTypesEnum.IncidentReporting, breadcrumbText: 'Reporting/Form301', children: {} },
  Form301Report: { urlRoute: 'reporting/form301/:id', associatedApiEntity: ApiEntityTypesEnum.IncidentReporting, breadcrumbText: 'Reporting/Form301', children: {} },
  IncidentIntake: { urlRoute: 'incident_intake_form/:authCode', associatedApiEntity: ApiEntityTypesEnum.IncidentIntake, breadcrumbText: 'Incident Intake Form', children: {} },
  AddFromIncidentIntake: { urlRoute: 'incident_recordkeeping/general_information/add/:id', associatedApiEntity: ApiEntityTypesEnum.Incident, breadcrumbText: 'Incident', children: {} },
  IncidentTrend: { urlRoute: 'incident_trend_analysis', associatedApiEntity: ApiEntityTypesEnum.IncidentViewModel, breadcrumbText: 'Incident', children: {} },
};
