import { AfterViewInit, Component, ContentChildren, EventEmitter, forwardRef, Input, Output, QueryList } from '@angular/core';
import { DataGridColumnCustomActionComponent } from '@components/datagrid-column-custom-action/datagrid-column-custom-action.component';
import { DataGridColumnCustomLinkComponent } from '@components/datagrid-column-custom-link/datagrid-column-custom-link.component';

@Component({
    selector: 'jjk-datagrid-column-custom-dropdown',
    template: `<ng-content></ng-content>`,
    providers: [{ provide: DataGridColumnCustomActionComponent, useExisting: forwardRef(() => DatagridColumnCustomDropdownComponent) }],
})
export class DatagridColumnCustomDropdownComponent extends DataGridColumnCustomActionComponent implements AfterViewInit {

    @Input() public displayText: string = null;
    @Input() public authFeature: string = null;

    public dropdownItems: any[] = null;

    @ContentChildren(DataGridColumnCustomLinkComponent) LinkButtonList: QueryList<DataGridColumnCustomLinkComponent>;

    constructor() {
        super();
        this.componentType = 'dropdown';
    }

    ngAfterViewInit() {
        this.dropdownItems = this.LinkButtonList.toArray();
    }
}
