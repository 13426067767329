import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'jjkp-ag-grid-recurring-audit-cell-renderer',
    templateUrl: './ag-grid-recurring-audit-cell-renderer.component.html',
    styleUrls: ['./ag-grid-recurring-audit-cell-renderer.component.scss']
  })
export class AgGridRecurringAuditCellRendererComponent implements ICellRendererAngularComp {
    public cellValue!: string;
    templateId: string;

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
      this.cellValue = this.getValueToDisplay(params);
      this.templateId = params.data.scheduleTemplateId;
    }

    // gets called whenever the user gets the cell to refresh
    refresh(params: ICellRendererParams) {
      // set value into cell again
      this.cellValue = this.getValueToDisplay(params);
      return true;
    }

    buttonClicked() {
      alert(`${this.cellValue} button pushed!`);
    }

    getValueToDisplay(params: ICellRendererParams) {
      return params.value;
    }
  }
