export enum AssociatedType {
    ChemicalManagementProduct = 'FB86D81A-E456-47CE-86D8-098D6808A1B8',
    Task = '412ACD2B-5E57-406E-8918-D29A504CEB6C',
    IncidentInvestigation = '742E64C1-3C99-4DCA-98C5-6A585F773A7E',
    IncidentRecordableDetails = '2A8F6606-D981-4378-B0BB-B3702D1AD46A',
    IncidentCorrectiveActions = '9F999CF8-4ED1-491B-BE76-B0E54E0257F4',
    EventReviewQuestion = 'DD00378D-0E59-467C-8B11-5655FE982866',
    ClassroomTrainingEvent = 'EEAD8A99-03FE-4658-A0CC-BD2B0906A302',
    Employee = '96B67B84-7342-410F-A45C-B0E2BACA1DF7',
    MedicalTest = '7900D226-B009-4D49-84A7-44BA52B7A195',
    MedicalVaccination = '544DC0FD-BE1E-4D32-9144-3CEC5E31F7B0',
    IncidentIntake = '23CEE79F-2809-4A85-A038-7D1562757171'
}
