import { Component, Input } from '@angular/core';

@Component({
    selector: 'jjkp-grid-page-header',
    templateUrl: './header.component.html',
    styleUrls: ['../../../../../../src/styles/_gridpage.scss'],
})
export class GridPageHeaderComponent {

    @Input() title: string;
    @Input() description: string;

    constructor() {
    }

}
