import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { ChartType } from '@constants/enums/chart-types.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ChartItem } from '@models/chart/chart-item.model';
import { ChartParams } from '@models/chart/chart-params.model';
import { Chart } from '@models/chart/chart.model';
import { ChartsBaseModel } from '@models/reporting/charts/charts-base.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { LegendPosition } from '@swimlane/ngx-charts';
import { axisFormatWholeNumber } from '../chart-helpers';

@Component({
    selector: 'jjk-audit-dashboard-chart-audit-inspection-and-conformance-chart',
    templateUrl: './jjk-audit-dashboard-chart-audit-inspection-and-conformance-chart.component.html',
    styles: [`
        .chart-container {
        margin-bottom: 45px;
        min-height: 300px;
        }
        `],
})

export class JJKAuditDashboardChartAuditInspectionAndConformanceChartComponent {
    @Input() chartType = 'BarAndLine';
    @Input() view;
    @Input() legend = true;
    @Input() legendTitle = 'Locations';
    @Input() legendPosition = LegendPosition.Below;
    @Input() @Output() chartData: ChartsBaseModel;
    @Input() results: any;
    @Input() locId: string;
    @Input() showAddButtonEmptyState = true;
    @Input() emptyStateMessage;

    @Output() action = new EventEmitter();

    isEmptyState = true;

    constructor(public zone: NgZone) {
        this.setLocation(null);
    }

    setLocation(locatonId: string) {
        const params = new ChartParams();
        params.LocationId = locatonId;

        ApiFactory.retrieveEntity(ApiEntityTypesEnum.Chart)
            .addHeader('X-ChartParams', JSON.stringify(params))
            .addDataEntry('chartType', 'AuditConformanceChart')
            .addRouteHint('Chart/ChartAsync')
            .addSuccessHandler((x) => {
                this.chartData = this.mapAuditAndInspectionConformance(x);
            }).buildAndSend();
    }

    mapAuditAndInspectionConformance(x) {
        const model = new ChartsBaseModel() ;
        model.title = 'Scored Audit & Inspection Conformance-Previous 12 months';
        model.type = ChartType.BAR_AND_LINE;
        model.view = [1000, 400];
        model.showXAxis = true;
        model.showYAxis = true;
        model.gradient = false;
        model.showLegend = true;
        model.legendTitle = '';
        model.legendPosition = LegendPosition.Below;
        model.showXAxisLabel = true;
        model.xAxisLabel = '';
        model.showYAxisLabel = true;
        model.yAxisLabel = 'Events';
        model.yAxisLabelRight = 'Score';
        model.showGridLines = true;
        model.innerPadding = '10%';
        model.animations = true;

        model.lineChartScheme = {
            name: 'coolthree',
            selectable: true,
            group: 'Ordinal',
            domain: ['#f17e3d'],
        };

        model.comboBarScheme = {
            name: 'LightBlue',
            selectable: true,
            group: 'Ordinal',
            domain: ['#466ac2'],
        };

        model.showRightYAxisLabel = true;

        model.lineChartSeries = this.mapLineCharts(x.xAxis, x.lineCharts);
        model.barChart = this.mapBarCharts(x.xAxis, x.barCharts);
        return model;
    }

    mapBarCharts(xAxis, barCharts) {
        const convertedBarCharts = [];

        for (let i = 0; i < xAxis.length; i++) {
          const element = xAxis[i];
          const chart = new Chart();
          chart.name = element;
          chart.series = new Array(1);

          const recordableIncidentChart = barCharts[0];
          const recordableIncidentItem = new ChartItem();
          recordableIncidentItem.name = recordableIncidentChart.label;
          recordableIncidentItem.value = recordableIncidentChart.values[i];
          if (recordableIncidentItem.value > 0) {
            this.isEmptyState = false;
          }

          chart.series[0] = recordableIncidentItem;
          convertedBarCharts.push(chart);
        }

        return convertedBarCharts;
      }

      mapLineCharts(xAxis, lineCharts) {
        const convertedLineCharts = [];

        const element = lineCharts[0];
        const chartIncidentRate = new Chart();
        chartIncidentRate.name = element.label;
        chartIncidentRate.series = [];

        for (let j = 0; j < xAxis.length; j++) {
          const dartIncidentItem = new ChartItem();
          dartIncidentItem.name = xAxis[j];
          dartIncidentItem.value = element.values[j];
          if (dartIncidentItem.value > 0) {
            this.isEmptyState = false;
          }
          chartIncidentRate.series.push(dartIncidentItem);
        }

        convertedLineCharts.push(chartIncidentRate);
        return convertedLineCharts;
      }

      onEmptyStateAddNewClick(event) {
        this.action.emit(event);
      }

      axisFormatWholeNumber(val) {
        return axisFormatWholeNumber(val);
      }

      yRightAxisTickFormattingFN(val) {
        return val + '%';
      }
}
