<footer *ngIf="!isNonAuth">
  <div class="container-fluid">
    <div class="row">
      <section class="col-lg-7 col-md-6 col-sm-8 col-8 footer-left-section">
        <a href="https://support.jjkeller.com/Safety_Management_Suite" target="_blank">Support Center</a>
        <p class="copyright">Copyright {{ currentYear }} J. J. Keller &amp; Associates, Inc.</p>
      </section>
      <section class="col-lg-5 col-md-6 col-sm-4 col-4 jjk footer-right-section">
        <img class="img-fluid jjk-logo" src="{{jjkHorizontalRGBLogo}}" alt="">
      </section>
    </div>
  </div>
</footer>
