export interface IValidatorInfo {
    required: boolean;
    minLength: number;
    maxLength: number;
    type: string;
}

export class ValidatorInfo {

    public static validateString(value, info: ValidatorInfo): boolean {
        return true;
    }

    public static validateDate(value, info: ValidatorInfo): boolean {
        return true;
    }

    public static validateNumber(value, info: ValidatorInfo): boolean {
        return true;
    }

}
