import { Component, Input } from '@angular/core';
import { DynamicControl } from '@models/dynamic-forms.export';

@Component({
  selector: 'dynamic-form-label-applies-to',
  templateUrl: './dynamic-form-label-applies-to.component.html',
})
export class DynamicFormLabelAppliesToComponent {
  @Input() controlData: DynamicControl;
  get isValid(): boolean {
    return this.controlData.isBaseValid;
  }

  constructor() {
  }
}
