
<body class="jjk-login" *ngIf="!sms_under_maintenance">
    <div class="container-fluid">
        <div class="row">
            <div id="leftContentDiv" class="col-lg-7 main-content">
                <sms-alert-dismiss *ngIf="showAlert" [messageText]="alertText" (dismissAlertClick)="showAlert = false" [customStyle]="'Error'"></sms-alert-dismiss>
                <h1>Login</h1>
                    <form [formGroup]="form" *ngIf="form" aria-label="Sign in with your email address">
                    <div class="entry">
                        <div class="entry-item">
                        <label for="signInName" class="form-label">
                            Email Address
                        </label>
                        <input appAutofocus type="text" id="signInName" name="Email Address" title="Please enter a valid Email Address" maxlength="254" formControlName="email" placeholder="Email Address" aria-label="Email Address" [class.field-error]="shouldShowErrors('email')">
                        <div *ngIf="hasErrorsIgnorePristine('email')" class="invalid-text">Please enter a valid email address.</div>
                        </div>
                        <div class="entry-item">
                            <div class="password-label">
                            <label for="password" class="form-label">Password</label>
                            <a id="forgotPassword" [routerLink]="forgotPasswordRoute">(Forgot your password?)</a>
                            </div>
                            <input password formControlName="password" id="password" name="Password"
                            [class.field-error]="shouldShowErrors('password')" placeholder="Password" aria-label="Password" aria-required="true">
                        </div>


                        <div class="buttons"><p>By signing into Safety Management Suite, you agree to the <a href="{{termsOfUseUrl}}" target="_blank">Terms of Use</a>. For information about how we use any personal information we collect for our own purposes, please review our <a href="{{noticeAtCollectionUrl}}" target="_blank">Notice at Collection</a>.</p>
                        <save-button [innerText]="'Log In'" (onSave)="onFormSubmit()" [isDisabled]="isProcessingRequest"></save-button>
                        <jjk-loader *ngIf="isProcessingRequest"></jjk-loader>
                        </div>
                    </div>
                    </form>
            </div>
            <div id="rightContentDiv" class="col-lg-5 right-item">
                <h2>Don't have an account?</h2>
                <div class="buttons">
                    <button id="next" (click)="navigateToSignUpPage()">Sign Up Now</button>
                </div>
                <p><strong>Or learn more at </strong> <a href="http://jjkellersafety.com" target="_blank">JJKellerSafety.com</a></p>
            </div>
        </div>
    </div>
</body>
