<div *ngIf="controlData" class="form-group" [class.has-danger]="showErrors">
    <label [attr.for]="controlData.name" [class.req]="controlData.required" *ngIf="controlData.displayName">
        <strong>{{ controlData.displayName }}</strong>
    </label>
    <jjk-date-picker [name]="controlData.name" [value]="controlData.value"
        [isDisabled]="controlData?.isDisabled" (dateChanged)="selectedDate($event)"
        [maxDate]="controlData?.configOptions?.dateOptions?.maxDate"></jjk-date-picker>
    <div *ngIf="controlData?.errors?.greaterThan && showErrors" class="error-message">{{
    controlData?.errors?.greaterThan?.message }}</div>
    <dynamic-form-label-applies-to [controlData]="controlData"></dynamic-form-label-applies-to>
</div>