import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DIALOG_MESSAGES, TechSupprtNumber } from '@constants/messages.constants';
import { environment } from '@env/environment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { EnableMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { SmsNavigationManager } from '@modules/navigation/sms-navigation-manager.class';

@Component({
    selector: 'layout-message',
    templateUrl: './layout-message.component.html',
    styleUrls: ['./layout-message.component.scss'],
})
export class LayoutMessageComponent extends EnableMessageProcessingDirective implements OnInit {

    currentYear = (new Date()).getFullYear();
    defaultMessage = `There was an error with your SMS account.  Please contact technical support at ${TechSupprtNumber}.`;
    message: string;
    jjkHorizontalRGBLogo = environment.jjkCDN + environment.jjkHorizontalRGBLogo;

    constructor(public activatedRoute: ActivatedRoute, mb: MessageBusService, protected navigator: SmsNavigationManager) {
        super(mb);
    }

    protected configureMessageBus(builder: IMsgProcessingReg): void { }

    async ngOnInit() {

        if (window.history.state && window.history.state.message) {
            this.message = window.history.state.message;
            LoggedInUserInfo.Instance.userInfo.errorMessage = this.message;
            this.checkMessage(this.message);
        } else if (window.localStorage.getItem('loginAccountErrorMessage')) {
            this.message = window.localStorage.getItem('loginAccountErrorMessage');
            window.localStorage.removeItem('loginAccountErrorMessage');
        } else {
            if (LoggedInUserInfo.Instance.userInfo.errorMessage) {
                this.message = LoggedInUserInfo.Instance.userInfo.errorMessage;
                this.checkMessage(this.message);
            } else {
                this.message = this.defaultMessage;
            }
        }
    }

    checkMessage(message) {
        // If this is a trial self-renewal, we need to show that message instead of the passed message and show the buttons
        if (message.startsWith('Renew')) {
            this.message = DIALOG_MESSAGES.RENEW_EXPIRED_SUBSCRIPTION;
            LoggedInUserInfo.Instance.resetLoggedInUserData();
        }
    }
}
