import { AbstractControl } from '@angular/forms';

export function ValidateFileExtension(control: AbstractControl) {
    if (!control || !control.value) { return; } // Nothing to validate

    const allowedExtensions = ['csv', 'doc', 'docx', 'gif', 'jpg', 'jpeg', 'pdf', 'png',
                               'ppt', 'pptx', 'rtf', 'tsv', 'txt', 'xls', 'xlsx', 'mov', 'mp4', 'mkv', 'wav', 'wmv'];
    const fileextension = control.value.split('.').pop();

    return allowedExtensions.indexOf(fileextension.toLowerCase()) > -1
        ? null
        : { invalidFileExtension: true, allowedExtensions };
}

export function ValidatePdfFileExtension(control: AbstractControl) {
    if (!control || !control.value) { return; }
    const allowedExtensions = ['pdf'];
    const fileextension = control.value.split('.').pop();

    return allowedExtensions.indexOf(fileextension.toLowerCase()) > -1
        ? null
        : { invalidFileExtension: true, allowedExtensions };
}
