import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ScreenService {
    private resizeSource = new BehaviorSubject<any>('');
    screenResized$ = this.resizeSource.asObservable();
    screenHeight = 1080;
    screenWidth = 1920;

    constructor() {
        try {
            this.screenWidth = window.innerWidth;
            this.screenHeight = window.innerHeight;
            window.addEventListener('resize', () => this.onResize());
            this.resizeSource.next({ screenHeight: this.screenHeight, screenWidth: this.screenWidth});
        } catch (err) {
            console.warn(`Screen sizes aren't available, using defaults.`, err);
        }
    }

    onResize() {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;
        this.resizeSource.next({ screenHeight: this.screenHeight, screenWidth: this.screenWidth});
    }
}
