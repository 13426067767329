import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SmsColumnDefinition } from '../common/models/sms-column-definition.model';

@Injectable()
export class SmsMinimapService {
    private columnInfo: SmsColumnDefinition[];
    columnInfoChanged$: BehaviorSubject<SmsColumnDefinition[]> = new BehaviorSubject<SmsColumnDefinition[]>([]);

    constructor() {
    }

    initColumnInfo(columnInfo: SmsColumnDefinition[]) {
        this.columnInfo = columnInfo;
        this.columnInfoChanged$.next(this.columnInfo);
    }

    setColumnInfo(column: SmsColumnDefinition) {
        const columnInfo = this.columnInfo && this.columnInfo.find((col) => col.value === column.value);
        if (columnInfo !== undefined) {
            this.columnInfo = this.columnInfo.filter((col) => col.value !== columnInfo.value);
            this.columnInfo.push(columnInfo);
            this.columnInfoChanged$.next(this.columnInfo);
        }
    }
}

export function SmsMinimapFactory() {
    return new SmsMinimapService();
}

export const smsMinimapServiceServiceProvider = {
    provide: SmsMinimapService,
    useFactory: SmsMinimapFactory,
};
