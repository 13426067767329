import { Directive, Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Injectable()
@Directive({
    selector: '[appPhoneNumberValidator][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: PhoneNumberValidator, multi: true },
    ],
})
export class PhoneNumberValidator implements Validator {
    validator: ValidatorFn;

    constructor() {
       this.validator = validatePhoneNumberFactory();
    }

    validate(c: UntypedFormControl) {
        return this.validator(c);
    }

    public validatePhoneNumber(phoneNumber: string): boolean {
        // eslint-disable-next-line no-useless-escape
        const regexp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g);

        if (phoneNumber === '' || phoneNumber === null || phoneNumber === undefined) {
            return true;
        } else {
            return regexp.test(phoneNumber);
        }
    }

}

// validation function
function validatePhoneNumberFactory(): ValidatorFn {
    return (c: AbstractControl) => {
        // eslint-disable-next-line no-useless-escape
        const regexp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g);

        const isValid = regexp.test(c.value);

        if (isValid || c.value === '' || c.value === null || c.value === undefined) {
            return null;
        } else {
            return { appPhoneNumberValidator: { valid: false } };
        }
    };
}
