import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { DynamicFormControlComponent } from '@components/dynamic-form-control/dynamic-form-control.component';

@Component({
    selector: 'dynamic-form-control-multi-select',
    templateUrl: './dynamic-form-control-multi-select.component.html',
    styleUrls: ['../dynamic-form-control/dynamic-form-control.component.scss'],
})
export class DynamicFormControlMultiSelectComponent extends DynamicFormControlComponent implements AfterViewInit {
    @Input() addNew: boolean;
    @Output() openAddNew = new EventEmitter<any[]>();

    private _selectedValue: any[] = [];
    get selectedValue() {
        return this._selectedValue;
    }
    set selectedValue(val) {
        this._selectedValue = val;
        this.controlData.patchValue(this.selectedValue);
    }

    constructor() {
        super();
    }

    onControlDataUpdate() {
        super.onControlDataUpdate();
        this.selectedValue = this.controlData.value;
    }

    ngAfterViewInit(): void {
        $('ng-select input').attr('autocomplete', 'off');
    }

    getStyleClasses() {
        return [];
    }

    openAddNewForm() {
        this.openAddNew.emit();
    }
}
