import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ACTIONS, TOPICS } from '@constants/messages.constants';
import { environment } from '@env/environment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { EnableServiceMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableServiceMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageTypeEnum } from '@modules/messaging/baseClasses/MessageTypeEnum';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { SystemEventMessage } from '@modules/messaging/types/Messages/SystemEventMessage';

@Injectable()
export class SessionManagementService extends EnableServiceMessageProcessingDirective {
    constructor(mb: MessageBusService) {
        super(mb);
        mb.messageProcessedNotifier = () => this.resetSessionExpirationTimer();
    }

    private _2MinAsMs = 2 *60 * 1000;

    elapsedTimeSinceActivity = 0;
    sessionIntervalTimer: any;
    tokenManagmentTimer: any;

    protected configureMessageBus(builder: IMsgProcessingReg): void {
        builder
            .add()
            .inBoundDataTopic(TOPICS.APPLEVEL.SYSTEM)
            .listenForMessageType(MessageTypeEnum.SystemEventMessage)
            .withAction(ACTIONS.AUTH.AUTH_TOKEN_WAS_REFRESHED)
            .thenCallFunction((m) => this.establishTokenTimer())
            .withAction(ACTIONS.AUTH.AUTH_LOGIN_SUCCESS)
            .thenCallFunction((m) => this.updateSessionData(m))
            .withAction(ACTIONS.AUTH.AUTH_REQUEST_OKTA_SIGNUP)
            .thenCallFunction((m) => this.processRequestOktaSignup(m));
    }

    updateSessionData(m: SystemEventMessage): void {
        clearInterval(this.sessionIntervalTimer);
        this.elapsedTimeSinceActivity = 0;
        if (!LoggedInUserInfo.Instance.clientUserInfo.isNonAuth) {
            this.sessionIntervalTimer = setInterval(() => this.checkSessionExpiration(), 1000);
        }
        this.establishTokenTimer();
    }

    refreshSecurityToken() {

        const msg = this.msgBuilder
            .SystemEventMessage()
            .usingHostSenderId()
            .then
            .proceedWithoutData
            .assignAction(ACTIONS.AUTH.AUTH_REQUEST_TOKEN_REFRESH)
            .sendOn.systemTopic()
            .build();
        this.sendTopicMessage(msg);
    }

    establishTokenTimer() {
        if(LoggedInUserInfo.Instance?.clientUserInfo?.refreshToken){
            // set a timer to refresh the Security Token Before It Expires
            const tokenData = LoggedInUserInfo.Instance;
            const refreshTokenDate: number = tokenData.tokenExpirationInAsMs;
            const expTime = moment(refreshTokenDate);
            this.tokenManagmentTimer = setTimeout(() => this.refreshSecurityToken(), tokenData.msUntilExpired - this._2MinAsMs);
        }
    }

    checkSessionExpiration() {
        this.elapsedTimeSinceActivity++;
        const urlPath = window.location.pathname;
        if (!LoggedInUserInfo.Instance.isAuthorized && !(urlPath === '/message')) {
            const msg = this.msgBuilder
                .SystemEventMessage()
                .usingHostSenderId()
                .then.proceedWithoutData.assignAction(ACTIONS.AUTH.AUTH_REQUEST_LOGOUT)
                .sendOn.systemTopic()
                .build();
            this.sendTopicMessage(msg);
        }

        if (this.elapsedTimeSinceActivity >= environment.sessionExpirationInSeconds) {
            clearInterval(this.sessionIntervalTimer);
            const msg = this.msgBuilder
                .SystemEventMessage()
                .usingHostSenderId()
                .then.proceedWithoutData.assignAction(ACTIONS.AUTH.SESSION_EXPIRED)
                .sendOn.systemTopic()
                .build();
            this.sendTopicMessage(msg);
        }
    }

    resetSessionExpirationTimer() {
        this.elapsedTimeSinceActivity = 0;
    }

    processRequestOktaSignup(m: SystemEventMessage) {
        clearInterval(this.sessionIntervalTimer);
        LoggedInUserInfo.Instance.resetLoggedInUserData();
    }
}
