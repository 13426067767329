import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { TreeData } from '@models/tree-data.model';
import { TreeNode } from '@models/tree-node.model';

@Component({
    selector: 'jjk-tree-select',
    templateUrl: './tree-select.component.html',
    styleUrls: ['./tree-select.component.scss'],
})
export class TreeSelectComponent implements OnChanges, OnDestroy {

    public isOpened: boolean;

    public treeContainerStyle = {};

    private _subscription: Subscription;
    private _filterData: TreeData[];
    private _matchedDataLength: number;
    private _selectedOption: TreeData;
    private _focusedNode = -1;
    private _maxLine = 10;

    public treeData: TreeNode[];
    // public selectedData: TreeData = new TreeData();
    public selectedNode: TreeNode;

    @Input() public get selectedOption(): TreeData {
        return this._selectedOption;
    }
    public set selectedOption(selection: TreeData) {        
        if (this._selectedOption !== selection) {
            this._selectedOption = selection;
        }
    }

    get selectedText() {
        return ((this.selectedOption) ? this.selectedOption.name : '') || 'Select One';
    }

    @Input() options: TreeData[];
    @Input() disableOptions: string[];
    @Input() disabled: boolean;
    @Input() title: string;
    @Input() filter: boolean;
    @Input() set maxLine(val) {
        this._maxLine = val;
        this.setContainerDimensions();
    }
    @Input() clearAll: boolean;
    @Output() selectedOptionChange: EventEmitter<TreeData> = new EventEmitter<TreeData>();
    @Output() clear = new EventEmitter<any[]>();

    // References of HTML
    @ViewChild('dropdownMenu', { static: true }) private dropdownMenu: ElementRef;
    @ViewChild('dropdownFilter') private dropdownFilter: ElementRef;

    constructor() {
        this.isOpened = false;
        this._subscription = LoggedInUserInfo.Instance.userInfoChanged.subscribe((userInfo) => {
        });
        setTimeout(() => {
            this.clearSearchFilter();
        }, 1000);
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
        this.isOpened = false;
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.hasOwnProperty('options')) {
            this._filterData = this.options;
        }
        if (this.options && this.selectedOption) {
            if (this.selectedOption.hasOwnProperty('id')) {
                this.options.forEach((obj) => {
                    if (obj.id === this.selectedOption['id']) {
                        this.selectedOption = obj;
                    }
                });
            } else if (this.selectedOption.hasOwnProperty('name')) {
                this.options.forEach((obj) => {
                    if (obj.name === this.selectedOption['name']) {
                        this.selectedOption = obj;
                    }
                });
            }
        }
    }

    setContainerDimensions() {
        this.treeContainerStyle = {
            'max-height': ((this._maxLine || 10) * 35) + 5 + 'px',
            'overflow-y': 'scroll',
        };
    }

    onSelectChange(selectedNode: TreeNode) {
        if (selectedNode) {
            this.selectedOption = this.options.find((opt) => opt.id === selectedNode.id);
            this.selectedOptionChange.emit(this._selectedOption);
            this.hideDropdown();
        }
    }

    onDropdownItemSelect(event: Event, option: TreeData) {
        if (this.disableOptions.length > 0 && option.hasOwnProperty('name')) {
            const nonselectable = this.disableOptions.findIndex((opt) => opt === option.name);
            if (nonselectable != -1) { 
                return; 
            }
        }
        this.selectedOption = option;
        this.selectedOptionChange.emit(option);
        this.hideDropdown();
    }

    onDropdownMenuClick($event: MouseEvent) {
        this.clearSearchFilter();
        this.isOpened = !this.isOpened;
        if (this.isOpened) {
            this.showDropdown();
        }
        if ($event.target instanceof HTMLInputElement) { return; } else { this.showDropdown(); }
    }

    onFilterSearch($event) {
        if ($event.keyCode === 13) { // Enter
            const enabled = this._filterData.filter((element) => element.isEnabled === true);
            if (enabled.length === 1) {
                this.onDropdownItemSelect($event, enabled[0]);
            } else {
                if (this._matchedDataLength === 1) {
                    const matched = this._filterData.filter((element) => element.isMatched === true && element.isEnabled === true);
                    if (matched.length > 0) {
                        this.onDropdownItemSelect($event, matched[0]);
                    }
                }
            }
            if (this._focusedNode !== -1) {
                const elements = document.getElementsByClassName('card-node');
                const focusText = elements[this._focusedNode]['innerText'];
                const option = this.options.find((opt) => opt.name === focusText);
                if (option.isEnabled) {
                    this.selectedOption = option;
                    this.selectedOptionChange.emit(this._selectedOption);
                    this.hideDropdown();
                }
            }
        } else if ($event.keyCode === 38) { // Arrow Up
            const elements = document.getElementsByClassName('card-node');
            if (this._focusedNode !== -1 && this._focusedNode !== elements.length) {
                const removeClass = elements[this._focusedNode].classList;
                removeClass.remove(removeClass[removeClass.length - 1]);
            }
            this._focusedNode = (this._focusedNode <= 0) ? elements.length : this._focusedNode;
            this._focusedNode--;
            const classList = elements[this._focusedNode].classList;
            classList.add(classList[classList.length - 1] + '-hover');
        } else if ($event.keyCode === 40) { // Arrow Down
            const elements = document.getElementsByClassName('card-node');
            if (this._focusedNode !== -1 && this._focusedNode !== elements.length) {
                const removeClass = elements[this._focusedNode].classList;
                removeClass.remove(removeClass[removeClass.length - 1]);
            }
            this._focusedNode = (this._focusedNode >= elements.length - 1) ? -1 : this._focusedNode;
            this._focusedNode++;
            const classList = elements[this._focusedNode].classList;
            classList.add(classList[classList.length - 1] + '-hover');
        } else {
            this._focusedNode = -1;
            this.setSearchedItems($event.target.value);
        }
    }

    setSearchedItems(searchText) {
        this._filterData = [];
        let root: any = [];
        if (searchText !== '') {
            const filteredItems = this.options.filter((element) => element.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 && element.isEnabled === true);
            if (filteredItems && filteredItems.length > 0) {
                this._matchedDataLength = filteredItems.length;
                filteredItems.map((element) => element.isMatched = true);
                this._filterData = filteredItems;
                filteredItems.forEach((element) => {
                    this.searchParents(element.parentGroupId);
                });
                if (this._filterData.length > 0) {
                    root = this._filterData.filter((element) => element.parentGroupId === null);
                    if (root.length > 0) {
                        this.treeData = this.buildOptionTree(root, 0);
                    }
                }
            }
        } else {
            if (this.options) {
                // this._filterData = JSON.parse(JSON.stringify(this.options));
                const filteredItems = this.options.filter((element) => element.isEnabled === true);
                if (filteredItems && filteredItems.length > 0) {
                    this._filterData = filteredItems;
                    filteredItems.forEach((element) => { this.searchParents(element.parentGroupId); });
                    this._filterData.map((element) => element.isMatched = false);
                    root = this._filterData.filter((element) => element.parentGroupId === null);
                    if (root.length > 0) {
                        this.treeData = this.buildOptionTree(root, 0);
                    }
                }
            }
        }
    }

    searchParents(parentGroupId: string) {
        const node = this.options.filter((element) => element.id === parentGroupId);
        if (node.length > 0) {
            const exists = this._filterData.filter((element) => element.id === node[0].id);
            if (exists.length === 0) {
                node[0].isMatched = false;
                this._filterData.push(node[0]);
            }
            if (node[0].parentGroupId !== null && node[0].parentGroupId !== '') {
                this.searchParents(node[0].parentGroupId);
            }
        }
    }

    buildOptionTree(obj: object, level: number): TreeNode[] {
        return Object.keys(obj).reduce<TreeNode[]>((accumulator, key) => {
            const value = obj[key];
            const node = new TreeNode();
            node.id = value.id;
            node.name = value.name;
            node.parentId = value.parentGroupId;
            node.enabled = (this.disableOptions && this.disableOptions.includes(value.name)) ? false : value.isEnabled;
            node.opened = true;
            node.matched = value.isMatched;
            const children = this._filterData.filter((element) => element.parentGroupId === value.id);
            if (value.name === 'Root' && level === 0) {
                children.sort((a, b) => (a.name > b.name) ? -1 : 1);
            } else {
                children.sort((a, b) => (a.name > b.name) ? 1 : -1);
            }
            if (children.length > 0) {
                node.children = this.buildOptionTree(children, level + 1);
            } else {
                node.children = [];
            }
            return accumulator.concat(node);
        }, []);
    }

    clearSearchFilter() {
        if (!this.dropdownFilter) { return; }
        this.dropdownFilter.nativeElement.value = '';
        this.setSearchedItems('');
    }

    showDropdown() {
        setTimeout(() => {
            this.dropdownFilter.nativeElement.focus();
        }, 0);
    }

    blurDropdown() {
        if (this.isOpened) {
            setTimeout(() => {
                this.hideDropdown();
            }, 500);
        }
    }

    closeDropdown(event: Event) {
        event.stopPropagation();
        this.hideDropdown();
    }

    hideDropdown() {
        const elements = document.getElementsByClassName('card-node');
        if (this._focusedNode !== -1 && this._focusedNode !== elements.length) {
            const removeClass = elements[this._focusedNode].classList;
            removeClass.remove(removeClass[removeClass.length - 1]);
        }
        this._focusedNode = -1;
        this.isOpened = false;
    }

    ClearSelection()
    {
        this.selectedOption = null;
        this.selectedOptionChange.emit(this._selectedOption);
        this.hideDropdown();
    }

    clearClick() {
        this.clear.emit();
    }
}
