<div class="aggridtoolbar clearfix">

    <div *ngIf="rowCount && !showSelectAll" class="results">
        <span>
            {{ _rowCount }} Results
        </span>
    </div>

    <div *ngIf="showSelectAll" class="actionsMenuLeft">
        <ul class="actions">

            <li><a (click)="onSelectAll()"><i class="custom-icon" aria-hidden="true"></i> Select All</a></li>
            <li><a (click)="onSelectAll(false)"><i class="custom-icon" aria-hidden="true"></i> Deselect All</a></li>

            <ng-container *ngIf="showSelectAll && _rowCount > 0">
                <span class="resultsSelectedRows">
                    {{ _rowCount }} Rows Selected
                </span>
            </ng-container>
        </ul>
    </div>

    <div class="rightActionsContainer">
        <div class="actionsfloatingfilters">
            <ul class="actions ulfloatingfilters">
                <li *ngIf="showFloatingFilters && !floatingFilterVisible"><a (click)="onShoFloatingFilter()"><i class="custom-icon" aria-hidden="true"></i>Show Filters Row</a></li>
                <li *ngIf="showFloatingFilters && floatingFilterVisible"><a (click)="onShoFloatingFilter()"><i class="custom-icon" aria-hidden="true"></i>Hide Filters Row</a></li>
            </ul>
        </div>
    
        <div class="actionsMenu">
            <ul class="actions ulfloatingfilters">
                <li class="actionsMenuItems-inactive" *ngIf="!filtersChanged"><i class="custom-icon aggridtoolbar-clearFilter-icon-inactive" aria-hidden="true"></i> Clear Filters</li>
                <li *ngIf="filtersChanged"><a (click)="showClearModal()"><i class="custom-icon aggridtoolbar-clearFilter-icon-active" aria-hidden="true"></i> Clear Filters</a></li>
                
                <li class="actionsMenuItems-inactive" *ngIf="!columnsChanged && !filtersChanged"><i class="custom-icon aggridtoolbar-resetFilter-icon-inactive" aria-hidden="true"></i> Reset </li>
                <li *ngIf="columnsChanged || filtersChanged"><a (click)="showResetModal()"><i class="custom-icon aggridtoolbar-resetFilter-icon-active" aria-hidden="true"></i>  Reset </a></li>
            </ul>
        </div>
    </div>
    

    

    <!-- Reset Modal Window -->
    <modal-window [id]="'resetModal'" [title]="'Reset View'" [cancelButtonText]="'Close'" [confirmButtonText]="'Confirm'" (onConfirm)="onResetConfirm()" (onCancel)="onCancelReset()" [autoClose]="true">
        <span>  All columns and filters will be reset to the default view.  </span>
    </modal-window>

    <!-- Clear Modal Window -->
    <modal-window [id]="'clearFiltersModal'" [title]="'Clear Filters'" [cancelButtonText]="'Close'" [confirmButtonText]="'Confirm'" (onConfirm)="onClearConfirm()" (onCancel)="onCancelClear()" [autoClose]="true">
        <span>  All applied filters will be removed.  </span>
    </modal-window>

</div>