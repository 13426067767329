<section id="searchSection" class="filter">
  <form class="navbar-form" role="search">
    <div class="input-group">
      <input id="srch-term-id" [(ngModel)]="searchTerm" (keydown)="filter($event)" class="form-control srch-term" [placeholder]="placeholder" name="srch-term" [attr.alphaId]="alphaId" type="text" [class.field-error]="showSearchButton && searchHasErrors() && showErrors">
      <span *ngIf="showSearchButton" class="input-group-btnx">
        <a class="btn btn-placeholder" (click)="searchDeleteClick()">
          <i class="fas fa-times"></i>
        </a>
      </span>
      <button [ngClass]="customButtonStyle" *ngIf="showSearchButton" data-bs-toggle="collapse" class="btn btn-primary search" type="button" (click)="search()">Search</button>
      <button *ngIf="showBackButton" data-bs-toggle="collapse" class="btn btn-secondary btnback" type="button" (click)="back()">{{backButtonText}}</button>
      <jjk-loader *ngIf="showLoader" class="loader-large" [marginTop]="'5px'" [marginLeft]="'20px'" [loadingText]="loadingText"></jjk-loader>
    </div>
  </form>
  <ng-content select="[searchNote]"></ng-content>
  <jjk-loader *ngIf="showLoader" class="loader-mobile" [marginTop]="'5px'" [loadingText]="loadingText"></jjk-loader>
  <div *ngIf="showSearchButton && searchHasErrors() && showErrors" class="error-message">
    Please enter at least {{ minSearchTermLength }} characters
  </div>
</section>