import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ApiFactory } from '@services/core/api-factory.class';
import { showModal } from '@utilities/helpers';

@Component({
    selector: 'jjk-delete',
    templateUrl: './delete.component.html',
})
export class DeleteComponent implements OnInit {
    @Input() public entityType: ApiEntityTypesEnum;
    // If the ApiEntityTypesEnum text does not match what you want to show on the dialog, override it here
    @Input() public entityNameOverride = '';
    // The html id for the modal window (change if using more than one in a page)
    @Input() public modalId = 'deleteModal';
    // The id of the record you want to delete
    @Input() public recordId = '';
    // Title or name that you want to show on the modal confirmation
    @Input() public recordName = '';
    // Multiple entity types if passing in more than 1 in an array
    @Input() public multipleEntities = false;
    // Extended message if you want to add more text after the entity name
    @Input() public additionalMessageAfterEntityName = '';
    // Additional message to add before the action name (action name being 'delete' for example)
    @Input() public additionalMessageBeforeActionWord = '';
    // Extended message if you want to add more text in addition to traditional confirmation message
    @Input() public additionalMessage = '';
    //Clarifying statement
    @Input() public extraMessageAfterAdditionalMessage = '';

    // Extended message if you want to add more text in the next line in addition to traditional confirmation message
    @Input() public additionalLineMessage = '';
    // Extended message if you want to add more text in the next line in addition to traditional confirmation message
    @Input() public additionalSecondLineMessage = '';
    // The Id Column Name
    @Input() public idColumnName = 'id';

    // You can provide an alternative title, which will show at the top of the modal window. By Default this is set to "Delete"
    @Input() title = 'Delete';

    // You can provide an alternative text for the main Confirm Button at the bottom right.
    // this will also show as the verb in the question "Are you sure you want to..." By Default this is set to "Delete"
    @Input() confirmButtonText = 'Delete';

    @Input() skipDelete = false;

    @Output() onDeleteSuccessful: EventEmitter<any> = new EventEmitter();
    @Output() onDeleteFailed: EventEmitter<any> = new EventEmitter();
    @Output() onDeleteCancel: EventEmitter<any> = new EventEmitter();
    @Output() onDeleteX: EventEmitter<any> = new EventEmitter();
    @Output() onDeleteConfirmed: EventEmitter<any> = new EventEmitter();

    get entityName(): string {
        return this.entityNameOverride || this.entityType.toString();
    }

    @Input() public deleteByArray = false;
    @Input() public deleteMany = false;
    @Input() public objectArrayToDelete: any[] = [];
    @Input() public mainButtonisRed = true;
    @Input() public totalEntityCount = 0;
    @Input() public entitiesWithNoPermissionCount = 0;
    @Input() public canDelete = true;
    failedItems: any[] = [];
    successItems: any[] = [];
    isConfirmDisabled = false;

    constructor() {
    }

    ngOnInit() {
    }

    open(recordToDelete?: any): void {
        if (recordToDelete) {
            this.recordId = recordToDelete[this.idColumnName];
            this.recordName = recordToDelete.name ? recordToDelete.name : recordToDelete.title;
        }
        else {
            this.recordId = null;
            this.recordName = null;
        }

        showModal(this.modalId);
    }

    onCancel() {
        this.onDeleteCancel.emit();
    }

    onX() {
        this.onDeleteX.emit();
    }

    onDeleteConfirm() {
        if (this.skipDelete || this.entityName === '' || (this.deleteMany && this.objectArrayToDelete.length === 0)) {
            this.onDeleteSuccessful.emit('success');
        } else {
            if (this.multipleEntities == true){
                for (let iterator=0; iterator< this.objectArrayToDelete.length; iterator++) {

                    ApiFactory.deleteEntity(this.objectArrayToDelete[iterator].type)
                        .addEntityId(this.objectArrayToDelete[iterator].id)
                        .addSuccessHandler((response) => {
                            this.successItems.push(iterator);
                            if (this.successItems.length + this.failedItems.length === this.objectArrayToDelete.length) {
                                this.onDeleteSuccessful.emit({ succeeded: this.successItems, failed: this.failedItems });
                            }
                        })
                        .addErrorHandler((response) => this.onError(response))
                        .buildAndSend();
                }
            }
            if (!this.deleteByArray && !this.deleteMany) {
                ApiFactory.deleteEntity(this.entityType)
                    .addEntityId(this.recordId)
                    .addSuccessHandler((response) => this.onSuccess(response))
                    .addErrorHandler((response) => this.onError(response))
                    .buildAndSend();
            } else if (this.deleteByArray) {
                // We set this as a Put Request because the Delete method in HttpClient-with-Cache doesn't add any objects to the body of the request
                ApiFactory.updateEntity(this.entityType, this.objectArrayToDelete)
                    .addRouteHint('DeleteMany')
                    .addSuccessHandler((response) => this.onSuccess(response))
                    .addErrorHandler((response) => this.onError(response))
                    .buildAndSend();
            } else if (this.deleteMany) {
                this.successItems = [];
                this.failedItems = [];
                for (const iterator of this.objectArrayToDelete) {
                    ApiFactory.deleteEntity(this.entityType)
                        .addEntityId(iterator)
                        .addSuccessHandler((response) => {
                            this.successItems.push(iterator);
                            if (this.successItems.length + this.failedItems.length === this.objectArrayToDelete.length) {
                                this.onDeleteSuccessful.emit({ succeeded: this.successItems, failed: this.failedItems });
                            }
                        })
                        .addErrorHandler((response) => {
                            this.failedItems.push(iterator);
                            if (this.successItems.length + this.failedItems.length === this.objectArrayToDelete.length) {
                                this.onDeleteSuccessful.emit({ succeeded: this.successItems, failed: this.failedItems });
                            }
                        })
                        .buildAndSend();
                }
            }

            this.onDeleteConfirmed.emit();
        }
    }

    private onSuccess(response) {
        this.onDeleteSuccessful.emit(response);
    }

    private onError(response) {
        this.onDeleteFailed.emit(response);
    }
}
