<div *ngIf="!isSdsManagement; else sdsManagement" class="container-fluid">
  <div class="row">
    <div class="col-md-12 main-content">
      <jjk-horizontal-navigation></jjk-horizontal-navigation>
      <section>
        <jjk-notifications-bar>
            <jjk-notification-card
                [title]="'New SDS'"
                [counter]="homeNotifications?.newSds"
                [routerLink]="'/chemicals/product_list'"
                [queryParams]="newSdsQueryParams"
                [state]="{tabsAndFilter: 'reset'}"
                authFeature="chemical-productlist"
                [featureToggleName]="'chemical_feature'"
                [isLoading]="loadingNotifications"></jjk-notification-card>
            <jjk-notification-card
                [title]="'Open Incidents'"
                [counter]="homeNotifications?.openIncidents"
                [routerLink]="'/incidents/incident_recordkeeping'"
                [queryParams]="openIncidentsQueryParams"
                [state]="{tabsAndFilter: 'reset'}"
                authFeature="incident-recordkeeping"
                [featureToggleName]="'incident_feature'"
                [isLoading]="loadingNotifications"></jjk-notification-card>
            <jjk-notification-card
                [title]="'Open Tasks'"
                [counter]="homeNotifications?.openTasks"
                [routerLink]="'/tasks'"
                [queryParams]="openTasksQueryParams"
                [state]="{tabsAndFilter: 'reset'}"
                authFeature="task"
                [featureToggleName]="'task_feature'"
                [isLoading]="loadingNotifications"></jjk-notification-card>
            <jjk-notification-card
                [title]="'Past-Due Audits'"
                [counter]="homeNotifications?.pastDueAudits"
                [routerLink]="'/audits/audit_events'"
                [queryParams]="pastDueAuditsQueryParams"
                [state]="{tabsAndFilter: 'reset'}"
                authFeature="audit-events"
                [featureToggleName]="'audit_feature'"
                [isLoading]="loadingNotifications"></jjk-notification-card>
            <jjk-notification-card 
                [title]="'Enrollments Available'"
                [subtitle]="'Expiring Soon:'"
                [showSubtitle]="showSubtitle"
                [counter]="enrollmentsAvailableCount"
                [subtitleCounter]="enrollmentsExpiringSoonCount"
                [routerLink]="enrollmentsRouterLink"
                authFeature="training-online"
                [featureToggleName]="'training_online'"
                [isLoading]="loadingEnrollmentsAvailable"></jjk-notification-card>
        </jjk-notifications-bar>
      </section>
      <div *featureToggle="'!aem_implementation'">
        <jjk-pimcore-zone [zones]="'Home:1'" *featureToggle="'pimcore_zone_one'"></jjk-pimcore-zone>
        <jjk-pimcore-zone [zones]="'Home:2'" *featureToggle="'pimcore_zone_two'"></jjk-pimcore-zone>
      </div>
      <section *featureToggle="'news_feature'">
        <div class="row" auth authFeature="news">
          <div class="col-sm">
            <jjk-mini-grid
                [cardName]="'Recent Site Updates'"
                [emptyUrl]="'/news'"
                [url]="'/news/details'"
                [tableData]="siteUpdateData"
                [loading]="loadingSiteUpdates"
                [loadingText]="'Fetching Site Updates...'"
                [showAllRouterLink]="'/news'"
                [selectedTabParam]="'su'"
                [state]="{tabsAndFilter: 'reset'}"></jjk-mini-grid>
          </div>
          <div class="col-sm">
            <jjk-mini-grid
                [cardName]="'Industry News'"
                [emptyUrl]="'/news'"
                [url]="'/news/details'"
                [tableData]="newsData"
                [loading]="loadingNews"
                [loadingText]="'Fetching Industry News...'"
                [showAllRouterLink]="'/news'"
                [selectedTabParam]="'in'"
                [state]="{tabsAndFilter: 'reset'}"></jjk-mini-grid>
          </div>
          <div class="col-sm">
            <jjk-mini-grid
                [cardName]="'Regulatory Change Notices'"
                [emptyUrl]="'/news'"
                [url]="'/news/details'"
                [tableData]="changeNoticeData"
                [loading]="loadingChangeNotices"
                [loadingText]="'Fetching Change Notices...'"
                [showAllRouterLink]="'/news'"
                [selectedTabParam]="'cn'"
                [state]="{tabsAndFilter: 'reset'}"></jjk-mini-grid>
          </div>
        </div>
      </section>
      <section auth authFeature="task">
        <div class="row" *featureToggle="'task_feature'">
          <div class="col-md-6">
            <jjk-task-breakdown-chart [emptyStateMessage]="taskBreakdownEmptyStateMessage" (action)="navigateToTasks(true)"></jjk-task-breakdown-chart>
          </div>
          <div class="col-md-6">
            <jjk-completed-tasks-chart [emptyStateMessage]="completedTaskEmptyStateMessage" (action)="navigateToTasks()"></jjk-completed-tasks-chart>
          </div>
        </div>
      </section>
      <jjk-safety-management-process></jjk-safety-management-process>
      <div *featureToggle="'!aem_implementation'">
        <jjk-pimcore-zone [zones]="'Home:3'" *featureToggle="'pimcore_zone_three'"></jjk-pimcore-zone>
        <jjk-pimcore-zone [zones]="'Home:4'" *featureToggle="'pimcore_zone_four'"></jjk-pimcore-zone>
        <jjk-pimcore-zone [zones]="'Dev:Home:0'" *ngIf="isDevEnvironment"></jjk-pimcore-zone>
      </div>
    </div>
  </div>
</div>
<ng-template #sdsManagement>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 main-content">
            <jjk-horizontal-navigation></jjk-horizontal-navigation>
            <div class="coming-soon">
                <p>Coming soon</p>
            </div>
        </div>
    </div>
</div>
</ng-template>