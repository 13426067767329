import { Platform } from '@angular/cdk/platform';
import { AfterContentInit, Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'ng-select'
})
export class DisableAutoCompleteNgSelectDirective implements AfterContentInit, OnDestroy {

  constructor(private el: ElementRef, private platform: Platform) {}

   ngAfterContentInit(): void {
     if (this.platform.BLINK) {
      const inputs = this.el.nativeElement.getElementsByTagName('input');
      for (const item of inputs) {
        item.addEventListener('focus', this.focusHandler);
      }
    }
  }

  ngOnDestroy() {
    if (this.platform.BLINK) {
      const inputs = this.el.nativeElement.getElementsByTagName('input');
      for (const item of inputs) {
        item.removeEventListener('focus', this.focusHandler);
      }
    }
  }

  focusHandler = (e) => {
    const inputs = this.el.nativeElement.getElementsByTagName('input');
    for (const item of inputs) {
      item.autocomplete = 'off';
      item.removeEventListener('focus', this.focusHandler);
    }
  }
}
