import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthForbiddenComponent } from '@components/auth-forbidden/auth-forbidden.component';
import { AuthUnauthorizedComponent } from '@components/auth-unauthorized/auth-unauthorized.component';
import { AzureLoginResponseComponent } from '@components/AzureLoginResponseComponent/azure-login-response.component';
import { RootRoutes } from '@constants/settings/root-routes.const';
import { AuthModule } from '@modules/auth/auth.module';
import { SmsNavigationManager } from '@modules/navigation/sms-navigation-manager.class';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { DashboardComponent } from '@pages/dashboard/home/dashboard.component';
import { LayoutMessageComponent } from '@pages/layout/layout-message/layout-message.component';
import { TopicsDashboardComponent } from '@pages/topics/topics-dashboard/topics-dashboard.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  { path: RootRoutes.Auth.urlRoute, loadChildren: () => import('@modules/auth/auth.module').then((m) => m.AuthModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.Message.urlRoute, component: LayoutMessageComponent },
  { path: RootRoutes.Forbidden.urlRoute, component: AuthForbiddenComponent, canActivate: [SmsNavigationManager] },
  { path: RootRoutes.Unauthorized.urlRoute, component: AuthUnauthorizedComponent, canActivate: [SmsNavigationManager] },
  { path: RootRoutes.AuthRedirect.urlRoute, component: AzureLoginResponseComponent },
  { path: RootRoutes.AdminSettings.urlRoute, loadChildren: () => import('@pages/admin-settings/admin-settings.module').then((m) => m.AdminSettingsModule), canActivate: [SmsNavigationManager] },

  // Adding authTag data for routes that load children will override a child's authTag data
  { path: RootRoutes.Dashboard.urlRoute, component: DashboardComponent, canActivate: [SmsNavigationManager], data: {authTag: 'dashboard-home' } },
  { path: RootRoutes.Chemicals.urlRoute, loadChildren: () => import('@pages/chemicals/chemicals.module').then((m) => m.ChemicalsModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.Incidents.urlRoute, loadChildren: () => import('@pages/incidents/incidents.module').then((m) => m.IncidentsModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.Training.urlRoute, loadChildren: () => import('@pages/training/training.module').then((m) => m.TrainingModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.Audits.urlRoute, loadChildren: () => import('@pages/audits/audits.module').then((m) => m.AuditsModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.Topics.urlRoute, component: TopicsDashboardComponent, canActivate: [SmsNavigationManager], data: {authTag: 'dashboard-topic' } },
  { path: RootRoutes.TopicIndex.urlRoute, loadChildren: () => import('@pages/topics/topics.module').then((m) => (m).TopicsModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.SdsManagement.urlRoute, loadChildren: () => import('@pages/sds-management/sds-management.module').then((m) => (m).SdsManagementModule), canActivate: [SmsNavigationManager] },

  { path: RootRoutes.Tasks.urlRoute, loadChildren: () => import('@pages/tasks/tasks.module').then((m) => m.TasksModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.CompanyData.urlRoute, loadChildren: () => import('@pages/company-data/company-data.module').then((m) => m.CompanyDataModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.Medical.urlRoute, loadChildren: () => import('@pages/medical/medical.module').then((m) => m.MedicalModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.ReportingCenter.urlRoute, loadChildren: () => import('@pages/reporting-center/reporting-center.module').then((m) => m.ReportingCenterModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.PlansAndPolicies.urlRoute, loadChildren: () => import('@pages/plans-policies/plans-policies.module').then((m) => m.PlansPoliciesModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.Webcasts.urlRoute, loadChildren: () => import('@pages/webcasts/webcasts.module').then((m) => m.WebcastsModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.RegSense.urlRoute, loadChildren: () => import('@pages/regsense/regsense.module').then((m) => m.RegSenseModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.News.urlRoute, loadChildren: () => import('@pages/news/news.module').then((m) => m.NewsModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.Profile.urlRoute, loadChildren: () => import('@pages/profile/profile.module').then((m) => m.ProfileModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.Settings.urlRoute, loadChildren: () => import('@pages/settings/settings.module').then((m) => m.SettingsModule), canActivate: [SmsNavigationManager] },
  { path: RootRoutes.ImportExport.urlRoute, loadChildren: () => import('@pages/import-data/import-data.module').then((m) => m.ImportDataModule), canActivate: [SmsNavigationManager] },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  // wildcard route, keep last
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollOffset: [0, 100],
    onSameUrlNavigation: 'reload'
}),
    AuthModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
