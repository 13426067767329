<div class="grid-wrapper" [ngStyle]="{'height': this.height, 'max-height': this.maxHeight, 'min-height': this.minHeightWithActionColumn}">
  <div [ngStyle]="style">
    <ag-grid-angular class="ag-theme-alpine" style="width: 100%; height: 100%;"
      [context]="context"
      [columnDefs]="columnDefinition"
      [defaultColDef]="defaultColDef"
      [rowData]="gridRowData"
      [rowSelection]="'multiple'"
      [components]="components"     
      [overlayLoadingTemplate]="overlayLoadingTemplate"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [animateRows]="false"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
<slide-out-modal [ngSwitch]="slideoutModal" [modalObjName]="objName" [newRowData]="newRowData" [confirmButtonText]="confirmButtonText" [isNextVisible]="isAdd ? true : false" (onConfirm)="refreshGrid(true)" (onNext)="refreshGrid(false)" (onCancel)="clearEditObjects()" [isRedConfirmButton]="isDelete" [isActionButtonEnabled]="keepButtonEnabled">
  <add-edit-hazard-form *ngSwitchCase="'1'" [title]="objName" [sdsId]="relevantId" [isAdd]="isAdd" [isView]="isView" [isEdit]="isEdit" [isDelete]="isDelete" [rowData]="gridRowData" [record]="record"></add-edit-hazard-form>
  <add-edit-statements-form *ngSwitchCase="'2'" [title]="objName" [sdsId]="relevantId" [isAdd]="isAdd" [isView]="isView" [isEdit]="isEdit" [isDelete]="isDelete" [rowData]="gridRowData" [record]="record"></add-edit-statements-form>
  <add-edit-ingredient-form *ngSwitchCase="'3'" [title]="objName" [sdsId]="relevantId" [isAdd]="isAdd" [isView]="isView" [isEdit]="isEdit" [isDelete]="isDelete" [rowData]="gridRowData" [record]="record"></add-edit-ingredient-form>
</slide-out-modal>