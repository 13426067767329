export enum OperatorEnum {
    GreaterThan = '>',
    LessThan = '<',
    EqualTo = '=',
    NotEqualTo = '!=',
    GreaterThanOrEqualTo = '>=',
    LessThanOrEqualTo = '<=',
    Between = 'Between',
    Contains = 'Contains',
    DoesNotContain = 'DoesNotContain',
    StartsWith = 'StartsWith',
    EndsWith = 'EndsWith',
}
