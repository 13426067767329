<div class="modal fade" id="jjkAccountMigration" tabindex="-1" role="dialog" aria-labelledby="verificationHeader"
    data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">Verification Required</h1>
                <div *ngIf="showMigrationErrorAlert" id="alertWrapper">
                    <sms-alert-dismiss [messageText]="alertText" (dismissAlertClick)="showMigrationErrorAlert = false" [customStyle]="'Error'">
                    </sms-alert-dismiss>
                </div>
            </div>
            <div class="modal-body">
                <div>
                    <p>In order to proceed please use your Network Credentials to log into Safety Management Suite.</p>
                </div>
                <form [formGroup]="form" *ngIf="form"
                    aria-label="Use your Network Credentials to log into Safety Management Suite">
                    <div class="entry">
                        <div class="entry-item">
                            <label for="password" class="form-label">
                                Password
                            </label>
                            <input appAutofocus password formControlName="password" id="password" name="Password"
                                [class.field-error]="shouldShowErrors('password')" placeholder="Password"
                                aria-label="Password" aria-required="true">
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <div class="flex-div">
                    <a [routerLink]="[]" class="create-account" (click)="goToSignup()"><span class="signup-arrow"></span>Skip Verification, Proceed to Signup</a>
                </div>
                <save-button [innerText]="'Verify'" (onSave)="onFormSubmit()" [isDisabled]="isProcessingRequest">
                </save-button>
            </div>
        </div>
    </div>
</div>