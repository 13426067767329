import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { SmsColumnDefinition } from '@components/hacksaw/common/models/sms-column-definition.model';
import { Filter } from '@models/advanced-filter/filter.model';
import { isNullOrUndefined, valueToBoolean } from '@utilities/helpers';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';

@Injectable()
export class FilterService {
    _fullDataList: any[]; // full list of data from http call.
    set fullDataList(val) {
        this._fullDataList = val;
        this.getTabbedList(this.tabFilter);
    }
    get fullDataList() { return this._fullDataList; }
    tabbedDataList: any[]; // data within currently selected tab, this is the data used for search and advanced filter
    tabsField: (t: any) => any; // function that returns the field that will be used for tabs
    searchFields: string[]; // the fields that the search is hooked up to, should come from the page/component
    private _defaultTabValues: string[]; // defines values that you want included in your default tab, empty if you want them all

    private messageSource = new BehaviorSubject('default message');
    currentMessage = this.messageSource.asObservable();
    stillInFeature = false;
    itemsToShow: number;
    defaultItemsToShow = 50;

    constructor() {
    }

    //#region Observable Properties

    // TODO: move this to another service
    // list of columns for higher level filtering
    private _savedColumns = new BehaviorSubject<SmsColumnDefinition[]>([]);
    set savedColumns(value) {
        this._savedColumns.next(value);
    }
    get savedColumns() {
        return this._savedColumns.getValue();
    }
    savedColumns$: Observable<SmsColumnDefinition[]> = this._savedColumns.asObservable();

    // list of tabs for higher level filtering
    private _tabs = new BehaviorSubject<string[]>(undefined);
    set tabs(value) {
        this._tabs.next(value);
    }
    get tabs() {
        return this._tabs.getValue();
    }
    tabs$: Observable<string[]> = this._tabs.asObservable();

    // value of the current tab selected initializes to '', bound to tabs and tab dropdown
    private _tabFilter = new BehaviorSubject<string>('');
    set tabFilter(value) {
        this._tabFilter.next(value);
    }
    get tabFilter() {
        return this._tabFilter.getValue();
    }
    tabFilter$: Observable<string> = this._tabFilter.asObservable();

    // toggles search vs advanced filter, bound to css properties
    private _searchIsDisabled = new BehaviorSubject<boolean>(false);
    set searchIsDisabled(value) {
        this._searchIsDisabled.next(value);
    }
    get searchIsDisabled() {
        return this._searchIsDisabled.getValue();
    }
    searchIsDisabled$: Observable<boolean> = this._searchIsDisabled.asObservable();

    // cancels simple search option while Advanced Filters are in use.
    private _searchIsAllowed = new BehaviorSubject<boolean>(true);
    set searchIsAllowed(value) {
        this._searchIsAllowed.next(value);
    }
    get searchIsAllowed() {
        return this._searchIsAllowed.getValue();
    }
    searchIsAllowed$: Observable<boolean> = this._searchIsAllowed.asObservable();

    // list of advanced filters being used, 6 max
    private _filters = new BehaviorSubject<Filter[]>([]);
    set filters(value) {
        this._filters.next(value);
    }
    get filters() {
        return this._filters.getValue();
    }
    filters$: Observable<Filter[]> = this._filters.asObservable();

    // data that has gone through tab, and either search or advanced filter, bound to table
    private _filteredDataList = new BehaviorSubject<any[]>([]);
    set filteredDataList(value) {
        this._filteredDataList.next(value);
    }
    get filteredDataList() {
        return this._filteredDataList.getValue();
    }
    filteredDataList$: Observable<any> = this._filteredDataList.asObservable();

    // text in filter input
    private _searchTerm = new BehaviorSubject<string>('');
    set searchTerm(value: string) {
        this._searchTerm.next(value);
        this.filteredDataList = this.searchTerm.length >= 3 ?
            this.performFilter(this.searchFields, this.searchTerm)
            : this.tabbedDataList;
    }
    get searchTerm(): string {
        return this._searchTerm.getValue();
    }
    searchTerm$: Observable<string> = this._searchTerm.asObservable();

    // text in search input
    private _searchValue = new BehaviorSubject<Filter[]>([]);
    set searchValue(value) {
        this._searchValue.next(value);
    }
    get searchValue() {
        return this._searchValue.getValue();
    }
    searchValue$: Observable<Filter[]> = this._searchValue.asObservable();

    //#endregion Observable Properties

    changeMessage(message: string) {
        this.messageSource.next(message);
    }

    // Temporary method to get data for the view details page.
    initDataList(data) {
        this.fullDataList = data;
    }

    initFilterableData(data, tabList?: string[], defaultTabValues?: string[], tabsField?: any) {
        this._defaultTabValues = defaultTabValues;
        if (this.filters.length > 0) {
            // retain state
            this.getTabbedList(this.tabFilter);
        } else {
            this.fullDataList = data;
            this.getTabbedList(this.tabFilter);
            // Initialize the tab values by sending in static list or field to use
            this.tabs = this.initTabValues(tabList ? tabList : null, tabsField ? tabsField : null);
            this.searchIsAllowed = true;
        }
    }

    initTabValues(tabList?: string[], tabsField?: any) {
        if (tabsField) { this.tabsField = tabsField; }
        if (tabList) {
            return tabList;
        } else {
            const tabsContent = this.tabsField ? this.fullDataList.map(this.tabsField) : [];
            return tabsContent
                .filter((a) => !!a)
                .filter((v, i) => (tabsContent.indexOf(v) === i))
                .sort((a, b) => (a.toString().toLowerCase().localeCompare(b.toString().toLowerCase())));
        }
    }

    toggleSearchAndFilters() {
        this.searchIsDisabled = !this.searchIsDisabled;
    }

    // ************Hierarchical Sort ***************

    hierarchySortFunc(a, b) {
        return a.value > b.value;
    }

    hierarchySort(hashArr, key, result) {

        if (hashArr[key] === undefined) { return; }
        const arr = hashArr[key].sort(this.hierarchySortFunc);
        for (let i = 0; i < arr.length; i++) {
            result.push(arr[i]);
            this.hierarchySort(hashArr, arr[i].id, result);
        }

        return result;
    }

    getListForAutoComplete(valuesSelector: (data: any) => string) {
        const values = this.tabbedDataList
            .filter(valuesSelector) // remove nulls or empty values
            .map(valuesSelector); // flattens object
        return values
            .filter((v, i) => (values.indexOf(v) === i)) // remove duplicates
            .sort((a, b) => (a.toLowerCase().localeCompare(b.toLowerCase())));
    }

    getTabbedList(tabValue) {
        this.tabFilter = tabValue;

        if (tabValue === '') {
            if (this._defaultTabValues) {
                this.filteredDataList = this.tabbedDataList = this.fullDataList.filter((data) => {
                    return this._defaultTabValues.indexOf(this.tabsField(data)) >= 0;
                });
            } else {
                this.filteredDataList = this.tabbedDataList = this.fullDataList.slice(0);
            }
        } else if (tabValue === 'myDoc') {
            this.filteredDataList = this.tabbedDataList = this.fullDataList.filter((data) => {
                const companyId = LoggedInUserInfo.Instance.userInfo.companyId;
                return data.companyId === companyId;
            });
        } else {
            this.filteredDataList = this.tabbedDataList = this.fullDataList.filter((data) => {
                return this.tabsField(data) === tabValue;
            });
        }

        this.searchIsDisabled && !this.searchIsAllowed
            // run filters
            ? this.filteredDataList = this.performAdvancedFilter(this.filters)
            // run search
            : this.filteredDataList = this.performFilter(this.searchFields, this.searchTerm);

    }

    getTabbedListArray(tabValue: string[]) {

        this.filteredDataList = this.tabbedDataList = this.fullDataList.filter((data) => {
            return tabValue.find((item) => item === this.tabsField(data));
        });

        this.searchIsDisabled && !this.searchIsAllowed
            // run filters
            ? this.filteredDataList = this.performAdvancedFilter(this.filters)
            // run search
            : this.filteredDataList = this.performFilter(this.searchFields, this.searchTerm);
    }

    removeFilters() {
        this.filters = new Array<Filter>();
        this.filteredDataList = this.tabbedDataList;
    }

    applyFilters(filters) {
        // Overridable method
    }

    filterDate(prop: string, operator: string, filterValue: any) {
        if (operator === 'Equals') {
            this.filteredDataList = this.filteredDataList.filter((item) => {
                if (item.hasOwnProperty(prop)) {
                    const value = item[prop];
                    const itemDate = moment(value);
                    if (itemDate.isSame(moment(filterValue), 'day')) {
                        return item;
                    }
                }
            });
        } else if (operator === 'Greater Than') {
            this.filteredDataList = this.filteredDataList.filter((item) => {
                if (item.hasOwnProperty(prop)) {
                    const value = item[prop];
                    const itemDate = moment(value);
                    if (itemDate.isAfter(moment(filterValue), 'day')) {
                        return item;
                    }
                }
            });
        } else if (operator === 'Less Than') {
            this.filteredDataList = this.filteredDataList.filter((item) => {
                if (item.hasOwnProperty(prop)) {
                    const value = item[prop];
                    const itemDate = moment(value);
                    if (itemDate < moment(filterValue)) {
                        return item;
                    }
                }
            });
        } else {
            this.filteredDataList = this.filteredDataList.filter((item) => {
                if (item.hasOwnProperty(prop)) {
                    const value = item[prop];
                    if (value) { // Date is not null or undefined
                        const itemDate = moment(value);
                        if (itemDate.isValid() && itemDate.isBetween(filterValue.startDate, filterValue.endDate, 'days', '[]')) {
                            return item;
                        }
                    }
                }
            });
        }
    }

    filterString(prop: string, operator: string, filterValue: string) {
        if (this.filteredDataList.length > 0) {
            this.filteredDataList = this.filteredDataList.filter((item) => {
                if (item.hasOwnProperty(prop)) {
                    let value = item[prop];

                    if (!isNullOrUndefined(value)) {
                        value = value.toLocaleLowerCase();
                        if (operator === 'Contains') {

                            if (Array.isArray(filterValue)) {

                                const filtermatch = filterValue.filter((fv) => {
                                    return value.toString().indexOf(fv.toLocaleLowerCase()) !== -1;
                                });

                                if (filtermatch.length > 0) { return true; }

                            } else {
                                return value.toString().indexOf(filterValue.toLocaleLowerCase()) !== -1;
                            }

                        } else {

                            if (Array.isArray(filterValue)) {

                                const filtermatch = filterValue.filter((fv) => {
                                    return value.toString().indexOf(fv.toLocaleLowerCase()) === -1;
                                });

                                if (filtermatch.length > 0) { return true; }

                            } else {
                                return value.toString().indexOf(filterValue.toLocaleLowerCase()) === -1;
                            }

                        }
                    }
                }
            });
        }

    }

    filterSelect(prop: string, operator: string, filterValue: any) {
        this.filteredDataList = this.filteredDataList.filter((item) => {
            if (item.hasOwnProperty(prop)) {
                let value = item[prop];

                if (!isNullOrUndefined(value)) {
                    value = value.toLocaleLowerCase();

                    const _filterValue = Array.isArray(filterValue) ? filterValue[0].toLocaleLowerCase() : filterValue.toLocaleLowerCase();

                    if (operator === 'Equals') {
                        return value === _filterValue;
                    } else {
                        return value === '' || value !== _filterValue;
                    }
                }
            }
        });
    }

    filterProperty(prop: (any) => string, operator: string, filterValue: string) {
        this.filteredDataList = this.filteredDataList.filter((item) => {
            let value = prop(item);
            if (value) {
                value = value.toLocaleLowerCase();
                if (operator === 'Contains') {
                    return value.toString().indexOf(filterValue.toLocaleLowerCase()) !== -1;
                } else {
                    return value.toString().indexOf(filterValue.toLocaleLowerCase()) === -1;
                }
            }
        });
    }

    filterBool(prop: string, filterValue: string) {
        this.filteredDataList = this.filteredDataList.filter((item) => {
            if (item.hasOwnProperty(prop)) {
                const value = valueToBoolean(item[prop]);

                if (value === filterValue) {
                    return true;
                }
            }
        });
    }

    filterNumber(prop: string, operator: string, filterValue: any, tofilterValue: any) {
        if (operator === 'Equals') {
            this.filteredDataList = this.filteredDataList.filter((item) => {
                if (item.hasOwnProperty(prop)) {
                    const value = item[prop];
                    if (+value === +filterValue) {
                        return item;
                    }
                }
            });
        } else if (operator === 'Greater Than') {
            this.filteredDataList = this.filteredDataList.filter((item) => {
                if (item.hasOwnProperty(prop)) {
                    const value = item[prop];
                    if (value && +value > +filterValue) {
                        return item;
                    }
                }
            });
        } else if (operator === 'Less Than') {
            this.filteredDataList = this.filteredDataList.filter((item) => {
                if (item.hasOwnProperty(prop)) {
                    const value = item[prop];
                    if (value && +value < +filterValue) {
                        return item;
                    }
                }
            });
        } else {
            this.filteredDataList = this.filteredDataList.filter((item) => {
                if (item.hasOwnProperty(prop)) {
                    const value = item[prop];
                    if (value) {
                        if (+filterValue <= +value && +tofilterValue >= +value) {
                            return item;
                        }
                    }
                }
            });
        }
    }

    performFilter(props: string[], filterBy: string | null): any[] {
        const list = this.tabbedDataList;
        let newDataList: any[] = [];
        if (filterBy) {
            filterBy = filterBy.toLocaleLowerCase();
            for (const field of props) {
                const data = list.filter((item: any) => {
                    if (item.hasOwnProperty(field)) {
                        let value = item[field];
                        if (typeof value === 'string') {
                            value = value.toLocaleLowerCase();
                        }
                        return value && value.toString().indexOf(filterBy) !== -1 || null;
                    }
                });
                newDataList = newDataList.concat(data);
            }
            this.filteredDataList = newDataList;
        } else {
            // No filter was provided so return the list
            this.filteredDataList = list;
        }
        return this.filteredDataList;
    }

    performAdvancedFilter(filters) {
        this.filters = filters;
        this.filteredDataList = this.tabbedDataList;

        if (filters) {
            const defaultSearch = this.filters.find(x => this.searchFields.find(y => y === x.column));
            if (defaultSearch) { this.searchTerm = defaultSearch.filterValue; }
            this.applyFilters(filters);
        }
        return this.filteredDataList;
    }

    removeObjectFromList(predicate: (value: any, index: number, arr: any[]) => boolean): void {
        const indexToRemove = this.fullDataList.findIndex(predicate);
        if (indexToRemove > -1) {
            this.fullDataList.splice(indexToRemove, 1);
            this.fullDataList = Object.assign([], this.fullDataList);
        }
    }

    updateObjectInList(updatedValue: any): void {
        this.fullDataList.map((oldValue, i) => {
            if (oldValue.id === updatedValue.id) {
                this.fullDataList[i] = updatedValue;
            }
        });
    }

    cleanFilterService() {
        this.fullDataList = [];
        this.filteredDataList = [];
        this.tabbedDataList = [];
        this.searchTerm = '';
        this.tabFilter = '';
        this.filters = new Array<Filter>();
        this.stillInFeature = false;
        this.itemsToShow = this.defaultItemsToShow;
        this._savedColumns = new BehaviorSubject<SmsColumnDefinition[]>([]);
    }
}
