import { MessageType } from '../messageType';
import { Message } from './Message';

export class UserInterfaceMessage extends Message {
           // this is used during handler registration
           public static messageRegistrationKey: MessageType = 'UIMessage';

           // this is used in message identification
           public get messageType(): MessageType {
               return UserInterfaceMessage.messageRegistrationKey;
           }
       }
