import { Component } from '@angular/core';
import { DynamicFormControlComponent } from '@components/dynamic-form-control/dynamic-form-control.component';

@Component({
    selector: 'dynamic-form-control-richedit',
    templateUrl: './dynamic-form-control-richedit.component.html',
    styleUrls: ['../dynamic-form-control/dynamic-form-control.component.scss'],
})
export class DynamicFormControlRichEditComponent extends DynamicFormControlComponent {
    constructor() {
        super();
    }
}
