<div *ngIf="chartData" class="chart-container">
    <h2 style="text-transform: none;">{{chartData.title}}</h2>
    <jjk-empty-state *ngIf="isEmptyState" auth authFeature="incident,create" (action)='onEmptyStateAddNewClick($event)' [showAddButton]="showAddButtonEmptyState" [displayText]="emptyStateMessage"></jjk-empty-state>
    <div #chartContainer>
        <ngx-charts-pie-chart
            [view]="[chartContainer.offsetWidth,296]"
            [scheme]="chartData.colorScheme"
            [results]="chartData.data"
            [labels]="chartContainer.offsetWidth > 650"
            [legend]="650 >= chartContainer.offsetWidth"
            [legendTitle]="''"
            [legendPosition]="legendPosition"
            [trimLabels]="false">
        </ngx-charts-pie-chart>
    </div>
</div>
