import { Directive, Output } from '@angular/core';
import { IIdentifiable } from '../interfaces/IIdentifiable';

@Directive()
export abstract class AutoIncrementingIdentifierDirective implements IIdentifiable<number | string>  {
    private static _id: number | string = 0;

    @Output()
    public id: number | string;

    protected warningTextColor = 'yellow';
    protected informationTextColor = 'green';

    protected warningBackColor = 'yellow';
    protected informationBackColor = 'green';

    constructor() {
        if (typeof AutoIncrementingIdentifierDirective._id === 'number') { this.id = AutoIncrementingIdentifierDirective._id++; }
    }
}
