import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ChartParams } from '@models/chart/chart-params.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { BaseChartComponent } from '@swimlane/ngx-charts';

@Component({
  selector: 'jjk-chemical-sds-aging-chart',
  templateUrl: './chemical-sds-aging-chart.component.html',
  styles: [`
      .chart-container {
        width: 95%;
        margin-left: 10px;
        margin-bottom: 45px;
        min-height: 380px;
        display: block !important;
      }
      .EmptyStateContainer {
        text-align: center;
        max-width: 500px;
        margin:0 auto;
      }
      `],
})

export class ChemicalSDSAgingChartComponent extends BaseChartComponent implements OnInit {

  @Output() action = new EventEmitter();

  showChart = false;
  isEmptyState = false;
  emptyStateMessage = 'Add Chemical Products to view a chart that visually represents your Safety Management Suite data.';

  title = 'SDS Aging – Primary SDS';
  xAxisLabel = 'Years';
  yAxisLabel = 'Count';
  legendTitle = 'SDS Aging';
  legend = false;
  showXAxisLabel = true;
  showYAxisLabel = true;
  xAxis = true;
  yAxis = true;
  gradient = false;
  barAnimations = false;
  resultsCount = 0;

  public yAxisTickFormattingFn = this.yAxisTickFormatting.bind(this);

  colorScheme = {
    domain: ['#F5D63D', '#F58207', '#8DC740', '#511A8B', '#0701FB', '#DA111C' ],
  };

  ngOnInit() {
    const params = new ChartParams();

    ApiFactory.retrieveEntity(ApiEntityTypesEnum.Chart)
      .addHeader('X-ChartParams', JSON.stringify(params))
      .addDataEntry('chartType', 'SDSAgingChart')
      .addRouteHint('Chart/ChartAsync')
      .addSuccessHandler((x) => {
        this.results = this.mapBarCharts(x.xAxis, x.barCharts);

        if (this.resultsCount === 0) {
          this.isEmptyState = true;
        }
        this.view = [window.innerWidth / 1.35, 400];
        this.showChart = true;
      }).buildAndSend();
  }

  mapBarCharts(xAxis, barCharts) {
    const data = [];

    for (let i = 0; i < xAxis.length; i++) {
      data.push({ name: xAxis[i], value: barCharts[0].values[i] });

      this.resultsCount += barCharts[0].values[i];
    }

    return data;
  }

  yAxisTickFormatting(value) {
    if (value % 1 === 0) {
      return value.toLocaleString();
    } else {
      return '';
    }
  }

  onEmptyStateAddNewClick(event: any) {
    this.action.emit(event);
  }

  onResize(event) {
    this.view = [event.target.innerWidth / 1.35, 400];
  }
}
