<section class="index-nav">
  <ul class="nav" role="navigation">
    <li *ngFor="let a of alpha" [value]="a" (click)="onIndexClick(a)">
      <a *ngIf="generateLinks" [routerLink]="[]" [fragment]="a === '#' ? 'numbers' : a" class="nav-link" [class.disabled]="!dataExistsFor(a)" role="link">{{ a }}</a>
      <a *ngIf="!generateLinks" class="nav-link" [class.disabled]="!dataExistsFor(a)" role="link">{{ a }}</a>
    </li>
  </ul>
</section>
<section class="large-index-mobile">
  <div class="large-index">
    <select class="mb10" [(ngModel)]="currentIndex" (change)="onIndexChange($event.target.value)">
      <option [disabled]="!dataExistsFor(a)" *ngFor="let a of alpha" [value]="a">{{ a }}</option>
    </select>
  </div>
</section>
