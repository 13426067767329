export enum MedicalTestResult {
    Negative = 'C837C7A4-AA72-40AB-BD3E-01588AC93F40',
    Inconclusive = 'A369F9A3-4BD5-47EF-B197-C5F480E4143E',
    Positive = '7C12D355-6AB6-4411-ABC7-F67602C13E79',
  }
// Remove the above code and rename MedicalTestResultv2 to MedicalTestResult when removing split MEDICAL_CP_40
  export enum MedicalTestResultv2 {
    Negative = 'Negative',
    Inconclusive = 'Inconclusive',
    Positive = 'Positive',
  }
