<div *ngIf="controlData" class="form-group" [class.has-danger]="showErrors">
  <label [attr.for]="controlData.name" class="small form-label" [class.req]="controlData.required" *ngIf="controlData.displayName">
      <strong>{{ controlData.displayName }}</strong>
  </label>
  <div [ngClass]="getStyleClasses()">
    <input class="form-control" type="text" id="{{controlData.name}}" [formControl]="controlData" [ngClass]="getStyleClasses()" [maxlength]="maxlength" [attr.disabled]="controlData?.isDisabled ? 'true' : null" (keypress)="onInputKeyPress($event)" (paste)="onPasteHandler($event)" autocomplete=off  (ngModelChange)="ngChange()">
    <div *ngIf="controlData?.errors?.maxlength && showErrors" class="error-message" [class.has-applies-to]="controlData.configOptions?.appliesTo">
      Maximum length is {{ controlData.errors?.maxlength.requiredLength }}
    </div>
    <div *ngIf="controlData?.errors?.notUnique" class="error-message" [class.has-applies-to]="controlData.configOptions?.appliesTo">
      {{ controlData.displayName }} already exists
    </div>
    <div *ngIf="(controlData?.errors?.appPhoneNumberValidator
                  || controlData?.errors?.appIsNumericValidator
                  || controlData?.errors?.appEmailAddressValidator )
                  && showErrors"
      class="error-message" [class.has-applies-to]="controlData.configOptions?.appliesTo">
      {{ controlData.displayName }} is not valid
    </div>
    <dynamic-form-label-applies-to [controlData]="controlData"></dynamic-form-label-applies-to>
  </div>
</div>
