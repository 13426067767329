<li>
  <span>
    <!-- Link -->
    <a id="LinkOption{{index}}-{{cell.id}}" *ngIf="component['componentType'] === 'link' && component['routerLink'] !== null"
    [routerLink]="cell | buildRouterLink:component"
    (click)="valueChanged(cell, component)"
    [queryParams]="queryParams(cell, component)">{{ component['displayText'] }}</a>

    <!-- Button -->
    <a id="ButtonOption{{index}}-{{rowIdx}}" *ngIf="component['componentType'] === 'link' && component['routerLink'] === null"
    (click)="valueChanged(cell, component)">{{ component['displayText'] }}</a>

    <!-- Dropdown -->
    <ng-container *ngIf="component['componentType'] === 'dropdown'">

      <!-- Dropdown Menu -->
      <a role="button" id="editActions" data-bs-toggle="dropdown" aria-haspopup="true">
        <a>{{ component['displayText'] }} <i class="fas fa-caret-down"></i></a>
      </a>

      <div class="dropdown-menu" aria-labelledby="editActions">
        <!-- Dropdown Items -->
        <ng-container *ngFor="let dropdownItem of component['dropdownItems'];">
          <!-- Dropdown Item Link -->
          <a *ngIf="dropdownItem['componentType'] === 'link' && dropdownItem['routerLink'] !== null"
            class="dropdown-item"
            [routerLink]="cell | buildRouterLink:dropdownItem">{{ dropdownItem['displayText'] }}</a>
          <!-- Dropdown Item Button -->
          <a *ngIf="dropdownItem['componentType'] === 'link' && dropdownItem['routerLink'] === null"
            class="dropdown-item"
            (click)="valueChanged(cell, dropdownItem)">{{ dropdownItem['displayText'] }}</a>
        </ng-container>
      </div>

    </ng-container>
  </span>
</li>
