<div class="print-logo" style="display: none;" [class.report-view]="isReportView">
  <img style="height:40px; width:34px;"  src="assets/images/jjkDiamond.svg"  alt="">
  &nbsp;
  <img class="img-fluid" style="height:100px; width:400px;" src="assets/images/sms_logo.svg" alt="Safety Management Suite">
</div>
<div class="print-copyright" style="display: none;" [class.report-view]="isReportView">
  <p>
    This page is printed from a system owned by J. J. Keller &amp; Associates, Inc. Any content added by J. J. Keller &amp; Associates, Inc. is property thereof. For re-use of content owned by J. J. Keller &amp; Associates, Inc., please contact copyright&#64;jjkeller.com or call 1-800-558-5011.
  </p>
</div>
<ng-content></ng-content>
<div class="print-copyright" style="display: none;" [class.report-view]="isReportView">
  <p>
    This page is printed from a system owned by J. J. Keller &amp; Associates, Inc. Any content added by J. J. Keller &amp; Associates, Inc. is property thereof. For re-use of content owned by J. J. Keller &amp; Associates, Inc., please contact copyright&#64;jjkeller.com or call 1-800-558-5011.
  </p>
</div>
