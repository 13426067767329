import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { isValidArray } from '@utilities/helpers';
import { IDynamicControlConfigOptions } from './dynamic-control-config-options.model';
import { DynamicValidator } from './dynamic-validator.model';

export abstract class DynamicControl extends UntypedFormControl {
    id: number;
    type: string;
    name: string;
    displayName: string;
    configOptions?: IDynamicControlConfigOptions;
    dynamicValidators?: DynamicValidator[];
    required: boolean;
    isDisabled = false;
    showErrors = false;
    // Evaluates the most basic state of the control value, no validations applied
    get isBaseValid(): boolean {
        if (this.value instanceof Array) {
            return this.value.length > 0;
        } else if (this.configOptions && this.configOptions.isZeroValid && this.value === 0) {
            return true;
        }
        return !!this.value;
    }

    constructor(type, name, displayName, value = null, dynamicValidators: DynamicValidator | DynamicValidator[] = null, configOptions = null) {
        super(value);
        this.type = type;
        this.name = name;
        this.displayName = displayName;
        this.configOptions = configOptions ? configOptions : { isZeroValid: false };

        if (isValidArray(dynamicValidators)) {
            this.dynamicValidators = dynamicValidators as DynamicValidator[];
        } else if (dynamicValidators instanceof DynamicValidator) {
            this.dynamicValidators = [dynamicValidators];
        } else {
            this.dynamicValidators = [];
        }

        if (isValidArray(this.dynamicValidators)) {
            this.required = this.dynamicValidators.some((element) => element.value && element.type === 'required');
            this.startUpValidators();
        }
    }

    private startUpValidators() {
        const validators: ValidatorFn[] = [];
        if (isValidArray(this.dynamicValidators)) {
            this.dynamicValidators.forEach((x) => {
                validators.push(x.validatorFn);
            });
        }
        this.setValidators(validators);
    }

    findDynamicValidator(validatorType: string) {
        return this.dynamicValidators.find((m) => m.type === validatorType);
    }
}
