import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { IHasFormFile } from '@models/has-form-file';
import { LoggerService } from '@services/core/logger-service.class';
import { ValidateAllowedFileExtension } from '@utilities/validators/file-allowed-extension.validator';
import { ValidateFileSize } from '@utilities/validators/file-size.validator';
import { FileUploadService } from './file-upload.service';

@Component({
  selector: 'jjk-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  validatedFileTypes = '*.*';
  fileName = new UntypedFormControl('', [Validators.required]);
  fileSize = new UntypedFormControl(0, ValidateFileSize);
  fileDimensions = new UntypedFormControl(undefined);
  hintText = '';
  validateImgDim = false;
  invalidImageDimensions: boolean;
  invalidSvgFile: boolean;
  fileExtension: string;

  private _model: IHasFormFile;
  get model(): IHasFormFile { return this._model; }
  @Input() set model(value: IHasFormFile) {
    this._model = value;
    this.onModelChanged();
  }

  @Input() isValidationEnabled = false;
  @Input() fileTypes = '';
  @Input() maxWidth = 0;
  @Input() maxHeight = 0;

  extensionErrorMsg = '';

  @Output() fileChange = new EventEmitter<IHasFormFile>();
  @Output() InvalidExtensionSelected = new EventEmitter<void>();

  constructor(private fileUploadService: FileUploadService) { }

  ngOnInit() {
    this.validatedFileTypes = this.fileUploadService.validateProvidedExtensions(this.fileTypes);
    this.fileName.setValidators([Validators.required, ValidateAllowedFileExtension(this.validatedFileTypes)]);
    if (this.maxWidth > 0 && this.maxHeight > 0) {
      this.fileDimensions.setValidators([this.ValidateFileDimensions(this.maxWidth, this.maxHeight)]);
      this.validateImgDim = true;
    }
    this.hintText = this.validatedFileTypes.replace(/\./g, ' ').toUpperCase();
  }

  onFileChange($event) {
    if ($event.target.files[0]) {
      this.model.file = $event.target.files[0];
      this.isValidationEnabled = true;

      this.fileName.setValue(this.model.file ? this.model.file.name : '');
      this.fileSize.setValue(this.model.file ? this.model.file.size : 0);
      if (this.validateImgDim) {
        this.fileExtension = this.model.file ? this.model.file.name.split('.').pop() : '';
        this.invalidImageDimensions = this.invalidSvgFile = false;
        this.fileDimensions.setValue(this.model.file ? this.model.file : undefined);
      }
      this.fileChange.emit(this.model);

      if (this.fileName.errors && this.fileName.errors.invalidFileExtension) {
        if (this.fileName.errors.allowedExtensions.length > 1) {
          const exts = this.fileName.errors.allowedExtensions.map((e) => e.toUpperCase());
          const pos = exts.length - 1;
          exts[pos] = 'and ' + exts[pos];
          this.extensionErrorMsg = 'Only ' + exts.join(', ') + ' file types can be uploaded.';
        } else {
          this.extensionErrorMsg = 'Only ' + this.fileName.errors.allowedExtensions[0].toUpperCase() + ' file type can be uploaded.';
        }
        this.InvalidExtensionSelected.emit();
      }
    }
  }

  private onModelChanged() {
    if (this.model) {
      this.fileName.setValue(this.model.fileName);
      this.fileSize.setValue(this.model.fileSize);
    } else {
      this.fileName.setValue('');
      this.fileSize.setValue(0);
    }
  }

  private ValidateFileDimensions(maxWidth: number, maxHeight: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const filereader = new FileReader();
      if (control.value) {
        if (this.fileExtension === 'svg') {
          filereader.readAsText(control.value);
        } else {
          filereader.readAsDataURL(control.value);
        }
        filereader.onload = () => {
              if (this.fileExtension === 'svg') {
                try {
                  const doc = new DOMParser().parseFromString(filereader.result as string, 'application/xml');
                  const viewBox = doc.documentElement.getAttributeNode('viewBox');
                  let naturalWidth = 0;
                  let naturalHeight = 0;

                  if (viewBox) {
                    const arrayDimensions = viewBox.value.split(' ');
                    naturalWidth = +arrayDimensions[2];
                    naturalHeight = +arrayDimensions[3];
                  } else {
                    naturalWidth = +doc.documentElement.getAttributeNode('width').nodeValue;
                    naturalHeight = +doc.documentElement.getAttributeNode('height').nodeValue;
                  }

                  if (naturalWidth > maxWidth || naturalHeight > maxHeight) {
                    this.invalidImageDimensions = true;
                    return { width: naturalWidth, height: naturalHeight };
                  }
                } catch (error) {
                  LoggerService.trace('SVG file not valid: ', error);
                  this.invalidSvgFile = true;
                }
              } else {
                const img = new Image();
                img.src = filereader.result as string;
                img.onload = () => {
                    if (img.naturalWidth > maxWidth || img.naturalHeight > maxHeight) {
                        this.invalidImageDimensions = true;
                        return { width: img.naturalWidth, height: img.naturalHeight };
                    }
                };
              }
          };
      }
      return null;
    };
  }
}
