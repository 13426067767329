export enum TaskPriorityEnum {
    High = '6d8ee5ae-8e62-4957-b4f1-6793f659ef79',
    Medium = '68419e21-1953-4de3-9ad6-c76296f68355',
    Low = 'aaaeeea8-6871-446f-a90a-a6ca958c9c98',
  }

  export const TaskPriorityMap = new Map([
    [TaskPriorityEnum.High, 'High'],
    [TaskPriorityEnum.Medium, 'Medium'],
    [TaskPriorityEnum.Low, 'Low'],
  ]);
