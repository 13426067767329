export class SortCriteriaEntry {
    public sequenceOrder: number;
    public fieldName: string;
    public sortDescending: boolean;

    constructor(sequence: number, fieldName: string, sortDescending: boolean) {
        this.sequenceOrder = sequence;
        this.fieldName = fieldName;
        this.sortDescending = sortDescending;
    }
}
