import { AbstractControl } from '@angular/forms';
import { FileSize } from '@constants/filesize.constants';

export function ValidateFileSize(control: AbstractControl) {
    if (!control || !control.value) { return null; } // Nothing to validate here

    const allowedSize = FileSize.maxFileSize;
    const maxFileSize = FileSize.maxFileBytes; // bytes

    return control.value <= maxFileSize
        ? null
        : { invalidFileSize: true, allowedSize, allowedSizeUnit: 'MB' };
}
