<div class="modal fade" id="timeoutModal" tabindex="-1" role="dialog" aria-labelledby="timeoutModalHeader"
  data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="timeoutModalHeader">{{countDownMsg}}</h5>
      </div>
      <div class="modal-body"></div>
      <div class="modal-footer">
        <jjk-system-message-button buttonText="{{loginoutText}}"
          [stylingClasses]="{ 'btn-secondary' : !sessionExpired, 'btn-primary' : sessionExpired }"
          msgAction="ACTIONS.AUTH.AUTH_REQUEST_LOGOUT" (clicked)="hideModal()"> </jjk-system-message-button>
        <jjk-system-message-button buttonText='Keep Working' msgAction="ACTIONS.AUTH.AUTH_LOGIN_SUCCESS"
          (clicked)="hideModal()" *ngIf="!sessionExpired"> </jjk-system-message-button>
      </div>
    </div>
  </div>
</div>
