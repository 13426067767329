<ng-container *ngFor="let height of bars[0].height; let i = index;  trackBy: trackBy">
    <svg:g ngx-charts-bar *ngFor="let bar of bars; trackBy: trackBy "
        [@animationState]="'active'"
        [width]="bar.width / bar.height.length"
        [height]="bar.height[i]"
        [x]="bar.x - (bar.width * 1.1) / 2 + i * ((bar.width * 1.1) / bar.height.length)"
        [y]="bar.y[i]"
        [fill]="bar.color[i]"
        [stops]="bar.gradientStops"
        [data]="bar.data[i]"
        [orientation]="'vertical'"
        [roundEdges]="bar.roundEdges"
        [gradient]="gradient"
        [isActive]="isActive(bar.data)"
        [animations]="animations"
        [noBarWhenZero]="noBarWhenZero"
        (select)="onClick($event)"
        (activate)="activate.emit($event)"
        (deactivate)="deactivate.emit($event)"
        ngx-tooltip
        [tooltipDisabled]="tooltipDisabled"
        [tooltipPlacement]="'top'"
        [tooltipType]="'tooltip'"
        [tooltipTitle]="bar.tooltipText">
    </svg:g>
</ng-container>
