<modal-window [id]="'id'" [title]="title" [confirmButtonText]="'Save'" (onConfirm)="onFormSubmit()" (onCancel)="onCancel()" (onX)="onCancel()" [autoClose]="false" [isDisabled]="isDisabled">
  <form [formGroup]="form">
    <div class="form-group" *ngIf="isEdit">
      <h2>{{attachment.fileName}}</h2>
    </div>

    <div class="form-group">
      <div class="alert alert-warning" *ngIf="duplicateFound">
        A file with this name already exists. If you continue, both files will exist.
      </div>
      <div class="alert alert-danger" *ngIf="hasErrors('fileName') && attachment.file">
        Only files of these types can be uploaded: <span *ngFor="let type of fileName.errors.allowedExtensions">{{type | uppercase}} </span>
      </div>
      <div class="alert alert-danger" *ngIf="hasErrors('fileSize') && attachment.file">
        Only files up to {{fileSize.errors.allowedSize}} {{fileSize.errors.allowedSizeUnit}} can be uploaded.
      </div>
    </div>

    <div class="form-group" *ngIf="!isEdit">
      <label for="inputFile" class="btn btn-secondary form-label" [class.has-danger]="shouldShowErrors('fileName') && fileName?.errors?.required">Choose File</label>
      <p class="file-hint">
        Only {{allowedFileTypes}} file types can be uploaded.
      </p>
      <label for="inputFile" class="small filename form-label">{{fileName.value}}</label>
      <input type="file" class="form-control-file hidden-xs-up" id="inputFile" #inputFile aria-describedby="fileHelp" (change)="onFileChange($event)">
      <input type="hidden" name="fileHidden" formControlName="fileName">
      <input type="hidden" name="fileHiddenSize" formControlName="fileSize">
    </div>

    <div *ngIf="isDescriptionHide" class="form-group">
      <label class="form-label" for="attachmentDescription"><strong>Description</strong></label>
      <textarea class="form-control" id="attachmentDescription" #description rows="5" maxlength="7000" formControlName="description"></textarea>
      <div class="character-counter" [innerHtml]="description.value.length + '/7000'"></div>
    </div>
  </form>
  <jjk-loader (click)="onFormSubmit()" *ngIf="uploadingFile" [marginTop]="'20px'" [loadingText]="'Please wait while we upload your file. This may take a minute...'"></jjk-loader>
</modal-window>
