import { Injectable } from '@angular/core';
import { ACTIONS, TOPICS } from '@constants/messages.constants';
import { environment } from '@env/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { EnableServiceMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableServiceMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageTypeEnum } from '@modules/messaging/baseClasses/MessageTypeEnum';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { Message } from '@modules/messaging/types/Messages/Message';

@Injectable({
    providedIn: 'root'
})
export class ApplicationInsightsService extends EnableServiceMessageProcessingDirective {
    private appInsights: ApplicationInsights;
    private static _instance: ApplicationInsightsService = null;

    public static get Instance(): ApplicationInsightsService {
        return this._instance;
    }

    constructor(mb: MessageBusService) {
        super(mb);
        if (environment.appInsightsInstrumentationKey) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment.appInsightsInstrumentationKey,
                    enableAutoRouteTracking: true
                },
            });
            this.appInsights.loadAppInsights();
            this.appInsights.trackPageView();
        }
        ApplicationInsightsService._instance = this;
    }

    protected configureMessageBus(builder: IMsgProcessingReg): void {
        builder
            .add()
            .inBoundDataTopic(TOPICS.APPLEVEL.SYSTEM)
            .listenForMessageType(MessageTypeEnum.SystemEventMessage)
            .withAction(ACTIONS.AUTH.AUTH_LOGIN_SUCCESS)
            .thenCallFunction((m) => this.setAuthenticatedUserByMessage(m));
    }

    setAuthenticatedUserByMessage(message: Message) {
        try {
            if (message?.messageData?.user?.user?.email) {
                this.appInsights?.setAuthenticatedUserContext(message.messageData.user.user.email);
            } else if (message?.messageData?.user?.userId) {
                this.appInsights?.setAuthenticatedUserContext(message.messageData.user.userId);
            }
        }
        catch (ex) {
            this.exception(ex);
        }
    }

    setAuthenticatedUser(user: string) {
        if (user) {
            this.appInsights?.setAuthenticatedUserContext(user);
        }
    }

    startTrackPage(route: string) {
        if (this.appInsights) {
            this.appInsights.startTrackPage(route);
        }
    }

    stopTrackPage(route: string) {
        if (this.appInsights) {
            this.appInsights.stopTrackPage(route);
        }
    }

    error(err: any) {
        if (this.appInsights) {
            this.appInsights.trackException({ exception: new Error(err) });
        } else {
            console.error(err);
        }
    }

    trace(message: string, object: any = null) {
        let serializedObject = '';
        if (object) {
            serializedObject = JSON.stringify(object);
            message += ': ' + serializedObject;
        }
        if (this.appInsights) {
            this.appInsights.trackTrace({ message });
        } else {
            console.log(message);
        }
    }

    exception(ex: any) {
        if (this.appInsights) {
            this.appInsights.trackException({ exception: ex });
        } else {
            console.error(ex);
        }
    }
}
