import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { Country } from '@models/entity-models/autogenerated/country';
import { State } from '@models/entity-models/autogenerated/state';
import { ApiFactory } from '@services/core/api-factory.class';
import { FilterExpressionBuilder } from '@services/core/models/Filter-Entry';
import { isNullOrUndefined } from '@utilities/helpers';

@Component({
    selector: 'jjk-geography-select',
    templateUrl: './geography-select.component.html',
    styleUrls: ['./geography-select.component.scss'],
})
export class GeographySelectComponent implements OnInit, OnChanges {

    @Input() form: UntypedFormGroup;
    countryList: Country[] = [];
    stateList: State[] = [];
    @Input() defaultCountry: string;
    @Input() countryLabel: string;
    @Input() countryId: string;
    @Input() stateLabel: string;
    @Input() stateId: string;
    @Input() isDisabled = false;
    @Input() emptyControls: string[] = [];
    @Input() countryRequired = false;
    @Input() stateRequired = false;
    @Input() countryError = false;
    @Input() stateError = false;

    private _selectedCountry = null;
    get selectedCountry() { return this._selectedCountry; }
    set selectedCountry(val) {
        this._selectedCountry = val;
        this.selectedState = null;
        this.form.patchValue({ countryId: this._selectedCountry });
    }
    private _selectedState = null;
    get selectedState() { return this._selectedState; }
    set selectedState(val) {
        this._selectedState = val;
        this.form.patchValue({ stateId: this._selectedState });
    }
    countryText: string;
    stateText: string;

    constructor() {
    }

    ngOnInit() {
        this.loadCountries();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.countryId) {
            this.countryId = changes.countryId.currentValue;
        }
        if (changes.stateId) {
            this.stateId = changes.stateId.currentValue;
        }
        if (this.stateLabel) {
            this.form.get('countryId').valueChanges.subscribe((val) => {
                this.loadStateList(val);
            });
        }

        if (changes.countryId) { this.setDefaultValues(); }
    }

    loadCountries() {
        ApiFactory.retrieveEntity(ApiEntityTypesEnum.Country)
        .addSuccessHandler((countries: Country[]) => {
            this.countryList = countries;
            this.setDefaultValues();
        })
        .removePaging()
        .buildAndSend();
    }

    setDefaultValues() {
        if (this.countryList.length > 0) {
            if (this.countryId) {
                this.selectedCountry = this.countryId;
            } else if (this.defaultCountry) {
                    this.selectedCountry = this.defaultCountry && this.countryList.find((c) => c.countryName.toLowerCase() === this.defaultCountry.toLowerCase()).id || null;
            }
            this.selectedState = this.stateId;
            if (this.isDisabled) {
                this.countryText = this.countryList.find((x) => x.id === this.selectedCountry).countryName;
            }
        }
    }

    loadStateList(countryId: string) {
        if (isNullOrUndefined(countryId)) { return; }
        const simpleExpression = FilterExpressionBuilder.For(ApiEntityTypesEnum.State)
            .Use('CountryId', ParameterTypeEnum.String)
            .Equal(countryId)
            .Build().AsExpression;
        ApiFactory
            .retrieveEntity(ApiEntityTypesEnum.State)
            .addFilterEntries(simpleExpression)
            .removePaging()
            .addSuccessHandler((states) => {
                this.setStateList(states);
            })
            .buildAndSend();
    }

    private setStateList(states: State[]) {
        this.stateList = states;

        if (this.isDisabled && this.selectedState) {
            this.stateText = this.stateList.find((x) => x.id === this.selectedState)?.stateName;
        }

        // If the state is required, we need to add validation for state and zip code
        if (this.stateRequired) {
            // USA or Canada
            if (this.stateList.length) {
                this.form.get('stateId').setValidators(Validators.required);
                this.form.get('zipCode').setValidators(Validators.required);
                $('#stateLabel').addClass('req');
                $('#zipCodeLabel').addClass('req');
            } else {
                this.form.get('stateId').clearValidators();
                this.form.get('stateId').setValue(null);
                this.form.get('zipCode').clearValidators();
                $('#stateLabel').removeClass('req');
                $('#zipCodeLabel').removeClass('req');
            }
            this.form.get('stateId').updateValueAndValidity();
            this.form.get('zipCode').updateValueAndValidity();
        }
    }

    public controlNeedsMoreInfo(name: string) {
        return this.emptyControls.indexOf(name) > -1 ? true : false;
    }
}
