<div class="card-deck">
    <div class="card">
        <div class="card-body">
            <h3 class="card-title">{{ cardName }}</h3>
            <div class="flex-row">
                <jjk-loader *ngIf="loading" [marginTop]="'20px'" [loadingText]="loadingText"></jjk-loader>

                <jjk-datagrid *ngIf="tableData?.length" [tableData]="tableData" [defaultSort]="defaultSort ? defaultSort: 'publishDate'"
                    [defaultSortDirection]="defaultSortDirection" [itemsToShow]="3" [displayShowMore]="false" [viewAuthFeature]="viewAuthFeature">

                    <jjk-datagrid-column-custom [title]="'titleInfo'" [columnName]="'newsTitle'"
                        [displayText]="displayText" [priority]="1" [alwaysShow]="true" [routerLink]="url" [field]="'id'"
                        [canSort]="false">
                        <!-- This will be added at a later time -->
                        <!-- <jjk-datagrid-column-custom-link [routerLink]="'details'" [field]="'id'" [displayText]="'View'"></jjk-datagrid-column-custom-link>
                        <jjk-datagrid-column-custom-link [routerLink]="'share'" [field]="'id'" [displayText]="'Share'"></jjk-datagrid-column-custom-link> -->
                    </jjk-datagrid-column-custom>
                    <jjk-datagrid-column-text *ngIf="secondColumnTitle" [columnName]="secondColumnValue" [displayText]="secondColumnTitle"
                        [alwaysShow]="true" [priority]="2" [pipe]="secondColumnIsDate ? 'date' : ''">
                    </jjk-datagrid-column-text>
                    <jjk-datagrid-column-text *ngIf="thirdColumnTitle" [columnName]="thirdColumnValue" [displayText]="thirdColumnTitle"
                        [alwaysShow]="true" [priority]="3" [pipe]="thirdColumnIsDate ? 'date' : ''">
                    </jjk-datagrid-column-text>
                </jjk-datagrid>
                <div *ngIf="!loading && tableData.length && !useRouterLink" class="more text-center">
                    <a class="btn btn-link" [routerLink]="showAllRouterLink"
                        [queryParams]="{tab: selectedTabParam}">Show All</a>
                </div>
                <div *ngIf="!loading && useRouterLink && tableData.length" class="more text-center">
                    <div *ngIf="!this.predefinedState">
                        <a class="btn btn-link" [href]="showAllRouterLink">Show All</a>
                    </div>
                    <div *ngIf="this.predefinedState">
                        <a class="btn btn-link" [routerLink]="this.showAllRouterLink"  [queryParams]="{ predefinedState: this.predefinedState, initialLoadStateQueryParams: this.searchValue }">Show All</a>
                    </div>
                </div>
                <ng-container *ngIf="!loading && !predefinedState && tableData.length === 0">
                    <div>
                        {{ emptyMessage }}
                    </div>
                    <div class="more text-center">
                        <a class="btn btn-link" [routerLink]="emptyUrl" [queryParams]="{tab: selectedTabParam}">Go to {{cardName}}</a>
                    </div>
                </ng-container>
                <ng-container *ngIf="!loading && predefinedState && tableData.length === 0">
                    <div>
                        {{ emptyMessage }}
                    </div>
                    <div class="more text-center">
                        <a class="btn btn-link" [routerLink]="this.showAllRouterLink" [queryParams]="{ predefinedState: this.predefinedState, initialLoadStateQueryParams: this.searchValue }">Go to {{cardName}}</a>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
