<div class="container-fluid">
  <div class="row">
      <div id="leftContentDiv" class="col-lg-12 main-content">
          <sms-alert-dismiss *ngIf="showAlert" [messageText]="alertText" (dismissAlertClick)="showAlert = false" [customStyle]="'Error'"></sms-alert-dismiss>
            <form [formGroup]="form" *ngIf="form" aria-label="Sign in with your email address">
              <div class="entry">
                  <div class="entry-item">
                    <label class="form-label" for="password">
                      Password
                    </label>
                    <input appAutofocus password formControlName="password" id="password" name="Password"
                    [class.field-error]="shouldShowErrors('password')" placeholder="Password" aria-label="Password" aria-required="true">
                  </div>
                <div class="buttons container">
                  <div class="row">
                  <div id="save-wrapper" class="col-lg-4 col-md-4">
                  <save-button [innerText]="'Complete'" (onSave)="onFormSubmit()" [isDisabled]="isProcessingRequest"></save-button>
                  </div>
                <div id="back-wrapper" *ngIf="alertText" class="col-lg-4 col-md-4">
                  <back-button (click)="closeForm()"></back-button>
                  </div>
                  <div id="loader-wrapper" *ngIf="isProcessingRequest" class="col-lg-4 col-md-4">
                    <jjk-loader></jjk-loader>
                  </div>
                </div>
                </div>
              </div>
            </form>
      </div>
  </div>
</div>
