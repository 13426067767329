<ng-container *ngIf="!fileType && !isDownloading && showDownload">
    <a target="_blank" (click)="download()" class="small print-hide"><i class="fas fa-download" data-bs-toggle="tooltip"
            data-bs-original-title="Download" data-bs-placement="bottom"></i></a>
</ng-container>

<div *ngIf="fileType && !isDownloading && isDownloadable" class="icon-table">
    <a *ngIf="fileType !== 'pdfAlreadyAdded'" target="_blank" class="small print-hide" (click)="download()"
        data-bs-toggle="tooltip" data-bs-original-title="Download" data-bs-placement="bottom">
        <jjkp-file-icon [fileType]="fileType"></jjkp-file-icon>
    </a>
    <a *ngIf="fileType === 'pdfAlreadyAdded'" target="_blank" class="small print-hide" (click)="download()"
        data-bs-toggle="tooltip" data-bs-original-title="Already Linked" data-bs-placement="bottom">
        <jjkp-file-icon [fileType]="fileType"></jjkp-file-icon>
    </a>
    <a *ngIf="isPreviewable()" target="_blank" (click)="open()" class="small print-hide"><i
            class="fas fa-external-link-alt" data-bs-toggle="tooltip" data-bs-original-title="Preview"
            data-bs-placement="bottom"></i></a>

    <span *ngIf="fileSize && isDownloadable" class="file-size"> {{getFileSizeText(fileSize)}} </span>
</div>

<div *ngIf="fileType && !isDownloadable" class="icon-table">
    <jjkp-file-icon [fileType]="fileType"></jjkp-file-icon>
</div>

<span class="position-absolute d-inline-block">
    <jjk-loader *ngIf="isDownloading"></jjk-loader>
</span>