<!--
    JJK-Empty-State component V 0.01
-->

<section>
    <div class="tab-content">
        <div class="text-center empty-state">
            <p [ngClass]="{'justify-text': !showAddButton}" [innerHtml]="displayText"></p>
            <button *ngIf="showAddButton" class="btn btn-secondary" type="button" (click)="onAddNewClick($event)"><i class="fa fa-plus"></i></button>
        </div>
    </div>
</section>