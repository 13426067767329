import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ITopicRecipient } from '../interfaces/ITopicRecipient';
import { MessageBusService } from '../services/messageBusService';
import { MessageHandlerRegistration } from '../types/messageHandlerRegistration';
import { Message } from '../types/Messages/Message';
import { AutoIncrementingIdentifierDirective } from './AutoIncrementingIdentifier';
import { IMsgProcessingReg, MessageBusConfigurationBuilder } from './MessageBusConfigurationBuilder';
import { IMsgType, OutboundMessageBuilder } from './OutboundMessageBuilder';

/** By inheriting this class into a component or service, that super class will
 * become registered with the MessageBus, register for topics, and register
 * function callbacks for each Action that is registered.
 */
@Directive()
export abstract class EnableMessageProcessingDirective extends AutoIncrementingIdentifierDirective
    implements OnInit, OnDestroy, ITopicRecipient {
    private msgBusConfigurationBuilder = new MessageBusConfigurationBuilder();
    private _registeredTopicMessageHandlers: Map<string, MessageHandlerRegistration<Message>[]>;

    // this is a flag to indicate the UI lifecycle has been completed.
    protected initialized = false;

    // this is the lead off for creating and sending a message
    protected get msgBuilder(): IMsgType {
        return new OutboundMessageBuilder(this.id) as IMsgType;
    }

    // This event is used to allow directives to be notified when we are created.
    // We pass a reference to ourselves giving Host control reference.
    @Output()
    public Created: EventEmitter<EnableMessageProcessingDirective> = new EventEmitter<EnableMessageProcessingDirective>(false);

    @Output()
    protected processChildControls = new EventEmitter();

    @Input()
    public outboundDataTopic: string;

    @Input()
    public inboundDataTopic: string;

    @Input()
    public outboundUITopic: string;

    @Input()
    public inboundUITopic: string;

    constructor(protected messageBus: MessageBusService) {
        super();
    }
    public id: string | number;

    ngOnInit(): void {
        const closureInstance = this;
        this.Created.emit(closureInstance);
        this.internalConfigureMessageBus();
    }

    ngOnDestroy(): void {
        this.messageBus.unregister(this);
    }

    protected buildMessageBusRegistrations() {
        this._registeredTopicMessageHandlers = this.msgBusConfigurationBuilder.build();
        this.messageBus.registerTopicRecipient(this, this.msgBusConfigurationBuilder.buildAsArray());
    }

    /**
     * This method is called to allow the inheritor a chance to register handlers for
     *  Topics, Messages, and Actions
     */
    private internalConfigureMessageBus(): void {
        this.msgBusConfigurationBuilder = new MessageBusConfigurationBuilder();
        this.configureMessageBus(this.msgBusConfigurationBuilder as IMsgProcessingReg);
        this.buildMessageBusRegistrations();
    }

    /**
     * This method is called to enable the implementor a chance to
     * setup message handlers and registrations
     * @param builder This
     */
    protected abstract configureMessageBus(builder: IMsgProcessingReg): void;

    /******************************************************************************************
     * This method will send a Topic Message as provided.  There is another
     * function called buildAndSendTopicMessage that constructs the message
     * from an Action and data but uses the OutboundTopic attribute from the
     * HTML declaration.
     * @param Message This is the message to send
     *****************************************************************************************/
    protected async sendTopicMessage(message: Message) {
        try {
            await this.messageBus.send(message);
        } catch (e) {
        }
    }

    /******************************************************************************************
     * When a Topic message is received, this method is invoked by the MessageBus and provides
     * the Topic Message for processing.
     **************************************************************************************** */
    public topicMessageReceived(message: Message) {
        // if the message is private and we arent the recipient, leave....
        if (message.isPrivate && message.recipientId !== this.id) {
            return;
        }

        // in theory it is possible to post to multiple topics
        message.topics.forEach(
            (topic) => {
                // have we registered for this topic?
                if (this._registeredTopicMessageHandlers.has(topic)) {
                    try {
                        // get the topic handlers
                        const messageRegistrationArray = this._registeredTopicMessageHandlers.get(topic);

                        // Are we looking at the proper message type?
                        const handlerEntry = messageRegistrationArray.filter((e) => e.messageAction === message.action);
                        const handler = handlerEntry.find((e) => e.messageType === message.messageType);

                        if (handler) {
                            try {
                                handler.handler(message);
                            } catch (ex) {
                            }
                        }
                    } catch (e) {
                    }
                }
            });
    }
}
