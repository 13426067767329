import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ITooltipAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'ag-grid-sds-status-tooltip',
  templateUrl: './ag-grid-sds-status-tooltip.component.html',
  styleUrls: ['./ag-grid-sds-status-tooltip.component.scss']
})
export class AgGridSdsStatusTooltipComponent implements ITooltipAngularComp {
  private params: any;
  private valueToDisplay: string;
  public isHeader: boolean;
  private isGroupedHeader: boolean;

  agInit(params): void {
    this.params = params;
    this.isHeader = params.rowIndex === undefined && params.location === 'header';
    this.isGroupedHeader = !!params.colDef.children;
  }
}
