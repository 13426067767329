<jjkp-password-rules *ngIf="rules" [rules]="rules" [showValidation]="true" [validationErrors]="rulesViewValidationErrors"
[showErrorText]="showRuleErrorText" (rulesAreLoaded)="rulesAreLoaded = true" [header]="'Reset Your Password'" [loaderTop]="true"  [parentActionSuccess]="parentActionSuccess"></jjkp-password-rules>
<sms-alert-dismiss *ngIf="showAlert" [messageText]="alertText" (dismissAlertClick)="showAlert = false" [customStyle]="'Error'"></sms-alert-dismiss>
<jjkp-forgot-password-okta-other-applications *ngIf="hasOnlyOtherApplications && b2c2OktaToggle" [nonSMSproducts]="nonSMSproducts"></jjkp-forgot-password-okta-other-applications>
<div>
    <form [formGroup]="form" *ngIf="form"  [hidden]="!rulesAreLoaded">
        <div class="entry">
            <div class="entry-item">
                <label for="newPassword" class="req form-label">{{newPasswordText}}</label>
                <input password formControlName="newPassword" id="newPassword" name="newPassword"
                [class.field-error]="shouldShowErrors('newPassword')" aria-label="New Password" aria-required="true" autofocus>
            </div>
            <div class="entry-item" *ngIf="!hasOnlyOtherApplications">
                <label for="passwordConfirm" class="req form-label">Confirm New Password</label>
                <input password formControlName="passwordConfirm" id="passwordConfirm" name="passwordConfirm"
                [class.field-error]="shouldShowErrors('passwordConfirm')" aria-label="Confirm new password" aria-required="true" (keyup)="updateNewPasswordValidity()">
            </div>
            <div>
                <save-button [innerText]="'Submit'" (onSave)="onFormSubmit()" [isDisabled]="isProcessingRequest" [title]="'Submit'"></save-button>
                <button [innerText]="'Cancel'" type="button" class="btn btn-secondary" (click)="cancel()" [isDisabled]="isProcessingRequest"></button>
                <button *ngIf="!hideResend" (click)="resend()" id="resendCode">{{resendLinkText}}</button>
            </div>
        </div>
    </form>
</div>
