import { isValidArray } from '@utilities/helpers';

export class FieldCompletion<T> {
    completedFields: number;
    totalFields: number;
    percentComplete: number;

    data: T;
    sections = new Map<string, FieldCompletion<T>>();

    constructor(data: T) {
        this.data = data;
        this.totalFields = 0;
        this.completedFields = 0;
        this.calculatePercent();
    }

    public addSection(section: FieldCompletion<any>, sectionName: string) {
        this.totalFields += section.totalFields;
        this.completedFields += section.completedFields;
        this.calculatePercent();
        this.sections.set(sectionName, section);
    }

    public addFieldFn(fieldFn: (data: T) => any) {
        if (!this.data) {
            this.totalFields++;
            return;
        }
        this.addField(fieldFn(this.data));
        this.calculatePercent();
    }

    private addField(value: any) {
        this.totalFields++;
        if (isValidArray(value)) {
            this.completedFields++;
        } else if (value && !(value instanceof Array)) {
            this.completedFields++;
        } else if (typeof value === 'boolean' && !value) {
            this.completedFields++;
        }

    }
    public calculatePercent() {
        this.percentComplete = this.completedFields && this.totalFields
            ? (this.completedFields / this.totalFields) * 100
            : 0;
    }
}
