<div *ngIf="controlData?.configOptions?.appliesTo; let appliesTo" class="validation">
  <div *ngIf="appliesTo.form300 || appliesTo.form301">
    <span class="small">Applies to:</span>
    <span *ngIf="appliesTo.form300" class="small">
      Form 300
      <i class="fas fa-check" [class.status-gray]="!isValid"></i>
    </span>
    <span *ngIf="appliesTo.form300 && appliesTo.form301">
      ,
    </span>
    <span *ngIf="appliesTo.form301" class="small">
      Form 301
      <i class="fas fa-check" [class.status-gray]="!isValid"></i>
    </span>
  </div>
</div>