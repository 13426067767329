import { ChartType } from '@constants/enums/chart-types.enum';
import { LegendPosition } from '@swimlane/ngx-charts';

export class ChartsBaseModel {
  view: any[];
  showXAxis: boolean;
  showYAxis: boolean;
  gradient: boolean;
  showLegend: boolean;
  legendPosition: LegendPosition;
  animations: boolean;
  showRightYAxisLabel: boolean;
  innerPadding: string;
  yAxisLabelRight: string;
  lineChartSeries: any[];
  barChart: any[];
  type: ChartType;
  title: string;
  data: any[]; // Make Base Model for data
  showGridLines = false;
  xAxis = true;
  yAxis = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  xAxisLabel: string;
  yAxisLabel: string;
  legend: boolean;
  legendTitle: string;
  colorScheme = {
      domain: ['#0701FB', '#F58207', '#8DC740', '#511A8B', '#DA111C', '#000000', '#BF9C11', '#F5D63D', '#737B80', '#E520DC'],
  };
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#f17e3d', '#f4bf0e'],
  };
  comboBarScheme = {
    name: 'LightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#466ac2', '#aaaea2'],
  };
}
