import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'newBadge'})
export class NewBadgePipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) {}

    transform(value: string, newSDS: boolean) {
        if (value && newSDS) {
            return this.sanitized.bypassSecurityTrustHtml(
                `${value} <span class="badge bg-success"> New </span>`);
        } else {
            return value;
        }
    }
}
