<div *ngIf="chartData"  class="chart-container description">
  <h3 style="text-transform: none;">{{chartData.title}}</h3>

  <ngx-charts-line-chart *ngIf="chartType === 'Line' && results"
      [view]="view"
      [scheme]="chartData.colorScheme"
      [results]="results"
      [legend]="chartData.legend"
      [legendTitle]="chartData.legendTitle"
      [xAxis]="chartData.xAxis"
      [yAxis]="chartData.yAxis"
      [showXAxisLabel]="chartData.showXAxisLabel"
      [showYAxisLabel]="chartData.showYAxisLabel"
      [xAxisLabel]="chartData.xAxisLabel"
      [yAxisLabel]="chartData.yAxisLabel"
      [showGridLines]="chartData.showGridLines"
      >
    </ngx-charts-line-chart>

  <ngx-charts-pie-chart *ngIf="chartType === 'Pie'"
    [view]="view"
    [scheme]="chartData.colorScheme"
    [results]="chartData.data"
    [labels]="true"
    [trimLabels]="false"
    >
  </ngx-charts-pie-chart>

  <combo-chart-component *ngIf="chartType === 'BarAndLine'"
    [view]="view"
    [scheme]="chartData.comboBarScheme"
    [colorSchemeLine]="chartData.lineChartScheme"
    [results]="chartData.barChart"
    [animations]="chartData.animations"
    [lineChart]="chartData.lineChartSeries"
    [gradient]="chartData.gradient"
    [xAxis]="chartData.showXAxis"
    [yAxis]="chartData.showYAxis"
    [legend]="chartData.showLegend"
    [legendTitle]="chartData.legendTitle"
    [legendPosition]="chartData.legendPosition"
    [showGridLines]="chartData.showGridLines"
    [showXAxisLabel]="chartData.showXAxisLabel"
    [showYAxisLabel]="chartData.showYAxisLabel"
    [showRightYAxisLabel]="chartData.showYAxisLabel"
    [xAxisLabel]="chartData.xAxisLabel"
    [yAxisLabel]="chartData.yAxisLabel"
    [yAxisLabelRight]="chartData.yAxisLabelRight"
    >
  </combo-chart-component>

</div>
