import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalAction } from '@constants/enums/modal-action.enum';
import { QuestionType } from '@models/dynamic-modal-wizard/question-type';
import { Question } from '@models/dynamic-modal-wizard/sms-question.model';

@Component({
    selector: 'dynamic-modal-wizard',
    templateUrl: './dynamic-modal-wizard.component.html',
    styleUrls: ['./dynamic-modal-wizard.component.scss'],
})
export class DynamicModalWizardComponent implements OnInit {
    @Input() title = '';
    @Input() modalId = '';
    @Input() modalSubText = '';
    @Input() subTextURL = '';
    @Input() recordId = '';
    @Input() questionnaire: Question[];
    @Input() confirmButtonText = 'Record';
    @Input() saveOnDecisionOnly = false;
    @Output() onDecision = new EventEmitter<QuestionType>(false);

    selectedOption = {};
    showSection = {};
    lastQuestion: Question;
    selectedValue: any = undefined;
    QuestionType = QuestionType;

    constructor() {
    }

    ngOnInit() {
        this.questionnaire.forEach((element) => {
            this.selectedOption[element.id] = null;
            this.showSection[element.id] = false;
        });
        this.showSection[1] = true;
    }

    onSelectionChange(question: any) {
        // Reset the selection.
        for (let i = question.id + 1; i <= this.questionnaire.length; i++) {
            this.showSection[i] = false;
            this.selectedOption[i] = null;
        }

        this.selectedValue = this.selectedOption[question.id];
        if (this.selectedValue === 'dontKnow') {
            this.saveLastQuestion(question.nextItemToShow.onDontKnow);
        } else if (this.selectedValue === true) {
            this.saveLastQuestion(question.nextItemToShow.onYes);
        } else {
            this.saveLastQuestion(question.nextItemToShow.onNo);
        }
    }

    saveLastQuestion(questionId: any) {
        this.lastQuestion = this.questionnaire.find((x) => x.id === questionId);
        if (!this.saveOnDecisionOnly || this.lastQuestion.type === QuestionType.DecisionYes || this.lastQuestion.type === QuestionType.DecisionNo) {
            this.showSection[questionId] = true;
        }
    }

    onCancel() {
        this.questionnaire.forEach((element) => {
            this.selectedOption[element.id] = null;
            this.showSection[element.id] = false;
        });
        this.showSection[1] = true;
        this.selectedValue = undefined;
        this.lastQuestion = undefined;
        this.close();
    }

    onConfirm() {
        if (this.saveOnDecisionOnly && this.lastQuestion.type === QuestionType.Question) {
            this.selectedValue = undefined;
            this.showSection[this.lastQuestion.id] = true;
        } else {
            this.onDecision.emit(this.lastQuestion ? this.lastQuestion.type : null);
            this.lastQuestion = undefined;
            this.onCancel();
        }
    }

    close() {
        ($(`#${this.modalId}`) as any).modal(ModalAction.Hide);
    }

    disableConfirm() {
        return this.saveOnDecisionOnly && this.selectedValue === undefined;
    }
}
