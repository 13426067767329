export enum UserTypes {
    SuperAdmin = 1,
    SupportUser = 2,
    SAPAdmin = 3,
    StandardUser = 4,
    SdsNonAuth = 5,
    ProductOwner = 6,
    AuditNonAuth = 7,
    IncidentNonAuth = 8,
    Postman = 9,
    SdsMgmt = 10,
    RegisteredUser = 100
}
