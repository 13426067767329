<div class="card-deck notifications">
    <a class="card flex-row align-items-center" [ngClass]="{'no-pointer-events': disableNavigation}" [style.background]="color">
      <div>
        <div class="enrollmentsCardTitlePopover">
          <h3 class="card-title" (click)="cardClicked()">Enrollments Available</h3>
          <jjk-popover [isDarkBlueIcon]="true"
            [content]="'Quantity reflects the total enrollments available for your account. If you have questions or would like to purchase more, please contact J. J. Keller Sales at 1-800-230-4094.'">
          </jjk-popover>
        </div>
        <span *ngIf="enrollmentsExpiringSoonCount > 0" class="card-subtitle" (click)="cardClicked()">Expiring Soon: </span>
        <span *ngIf="enrollmentsExpiringSoonCount > 0" class="card-subtitleCounter" (click)="cardClicked()"> {{enrollmentsExpiringSoonCount}}</span>
      </div>
      <span class="large-text flex-fill text-end" (click)="cardClicked()">{{remainingEnrollments}}</span>
    </a>
  </div>