import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[twoDigitDecimalNumber]'
})
export class TwoDigitDecimalNumberDirective {
    // Allow decimal numbers
    private regex = new RegExp(/^[0-9]{0,15}\.?[0-9]{0,2}$/g);
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

    constructor(private el: ElementRef) {
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1 ||
            (event.key === 'a' && event.ctrlKey === true) || // Allow: Ctrl+A
            (event.key === 'c' && event.ctrlKey === true) || // Allow: Ctrl+C
            (event.key === 'v' && event.ctrlKey === true) || // Allow: Ctrl+V
            (event.key === 'x' && event.ctrlKey === true) || // Allow: Ctrl+X
            (event.key === 'a' && event.metaKey === true) || // Cmd+A (Mac)
            (event.key === 'c' && event.metaKey === true) || // Cmd+C (Mac)
            (event.key === 'v' && event.metaKey === true) || // Cmd+V (Mac)
            (event.key === 'x' && event.metaKey === true)) { // Cmd+X (Mac)) {
        return;
        }
        const current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
        if (next && !String(next).match(this.regex)) {
        event.preventDefault();
        }
    }

    @HostListener('paste', ['$event']) onPaste(e) {
        let pastedText = undefined;
        if (e.clipboardData && e.clipboardData.getData) {
            pastedText = e.clipboardData.getData('text/plain');
        }
        if (typeof pastedText === 'string' && pastedText?.length > 0) {
            e.preventDefault();
            const cursorPosStart = this.el.nativeElement.selectionStart;
            const cursorPosEnd = this.el.nativeElement.selectionEnd;
            const v = e.target.value;
            const textBefore = v.substring(0, cursorPosStart);
            const textAfter  = v.substring(cursorPosEnd, v.length);
            e.target.value = textBefore + String(pastedText).replace(/,/g, '') + textAfter;
            e.target.dispatchEvent(new KeyboardEvent('input'));
            return false;
        }
    }
}