import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ag-grid-custom-toolbar',
    templateUrl: './ag-grid-custom-toolbar.component.html',
    styleUrls: ['./ag-grid-custom-toolbar.component.scss'],
})
export class AGGridCustomToolbarComponent {

    _rowCount: number;
    @Input()
    set rowCount(data: number) {
        this._rowCount = data;
    }
    get rowCount() {
        return this._rowCount;
    }

    @Input() filtersChanged = false;
    @Input() columnsChanged = false;
    @Input() floatingFilterVisible = true;
    @Input() showFloatingFilters = false;
    @Input() showSelectAll = false;

    @Output() action = new EventEmitter();

    constructor() {
    }

    showResetModal() {
        ($(`#resetModal`) as any).modal('show');
    }

    onResetConfirm() {
        this.action.emit('Reset');
    }

    onCancelReset() {
        ($(`#resetModal`) as any).modal('hide');
    }

    showClearModal() {
        ($(`#clearFiltersModal`) as any).modal('show');
    }

    onClearConfirm() {
        this.action.emit('Clear');
    }

    onCancelClear() {
        ($(`#clearFiltersModal`) as any).modal('hide');
    }

    onShoFloatingFilter() {
        this.action.emit('ShowFilters');
    }

    onSelectAll(option = true) {
       if (option) {
        this.action.emit('selectAll');
       } else {
        this.action.emit('deselectAll');
       }
    }
}
