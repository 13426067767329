<body>
    <main class="wrapper">
        <div class="container-fluid">
            <div class="row">
                <div id="leftContentDiv" class="main-content">
                    <div>
                        <h1 *ngIf="this.resetPasswordEmail">{{title}}</h1>
                        <div *ngIf="!this.resetPasswordEmail && !hasOnlyOtherApplications" class="message-block info">{{alertMessage}}</div>
                    </div>
                    <div id="verificationError" *ngIf="this.showAlertAuthCode" class="message-block authExpired danger">
                        <p>{{this.alertText}}</p>
                    </div>
                    <sms-alert-dismiss *ngIf="showAlert && !this.expiredTokenReset" [messageText]="alertText"
                    (dismissAlertClick)="showAlert = false" [customStyle]="'Error'"></sms-alert-dismiss>
                    <div id="verificationInfo" *ngIf="this.resetPasswordEmail && !this.showAlertAuthCode"
                    class="message-block emailSent info">
                    <p>An e-mail has been sent to the address you provided with a verification code to proceed.</p>
                    </div>
                    <jjkp-forgot-password-okta-other-applications *ngIf="hasOnlyOtherApplications && b2c2OktaToggle" [nonSMSproducts]="nonSMSproducts"></jjkp-forgot-password-okta-other-applications>
                    <jjkp-verification-code *ngIf="this.resetPasswordEmail"
                        [verificationCode]="verificationCode"
                        [header]="'Reset Your Password'"
                        [emailAddress]="email"
                        [resendText]="resendText"
                        [expiredTokenReset]="expiredTokenReset"
                        (successResponse)="openChangePassword()"
                        (failedResponse)="setAlertAuthCode($event)"
                        (cancelClicked)="navigateToLoginPage()"
                        (resendClicked)="resendVerificationCode()"
                        (verificationCodeEvt)="setVerificationCode($event)"
                        >
                    </jjkp-verification-code>
                    <jjk-loader *ngIf="!rules && !form && !this.resetPasswordEmail"></jjk-loader>
                    <div [hidden]="!rules && !form">
                        <jjkp-password-rules *ngIf="rules && !this.resetPasswordEmail && !hasOnlyOtherApplications" [loaderTop]="true" [parentActionSuccess]="parentActionSuccess" [rules]="rules" [showValidation]="true" [header]="null" [showErrorText]="showRuleErrorText"
                            (rulesAreLoaded)="rulesAreLoaded" [validationErrors]="newPasswordValidationErrors" [header]="'Reset Your Password'" (answer)="openCreateNewPassword($event)">
                        </jjkp-password-rules>
                        <div>
                            <form [formGroup]="form" *ngIf="form && !this.expiredTokenReset">
                                <div class="entry">
                                    <div class="entry-item" *ngIf="!requestResetPassword">
                                        <label for="currentPassword" class="req form-label">Current Password</label>
                                        <input password formControlName="currentPassword" id="currentPassword" name="Current Password"
                                        [class.field-error]="shouldShowErrors('currentPassword')" aria-label="Current Password" aria-required="true" autofocus>
                                    </div>
                                    <div class="entry-item">
                                        <label for="newPassword" class="req form-label">{{newPasswordText}}</label>
                                        <input password formControlName="newPassword" id="newPassword" name="New Password"
                                        [class.field-error]="shouldShowErrors('newPassword')" aria-label="New Password" aria-required="true">
                                    </div>
                                    <div class="entry-item" *ngIf="(b2c2OktaToggle && !hasOnlyOtherApplications) || !b2c2OktaToggle">
                                        <label for="passwordConfirm" class="req form-label">Confirm New Password</label>
                                        <input password formControlName="passwordConfirm" id="passwordConfirm" name="passwordConfirm"
                                        [class.field-error]="shouldShowErrors('passwordConfirm')" aria-label="Confirm new password" aria-required="true" (keyup)="updateNewPasswordValidity()">
                                    </div>
                                    <div class="buttons">
                                        <save-button [innerText]="'Confirm'" (onSave)="onFormSubmit()" [isDisabled]="isProcessingRequest" [title]="'Submit'"></save-button>
                                        <button [innerText]="'Cancel'" type="button" class="btn btn-secondary" (click)="navigateToLoginPage()" [isDisabled]="isProcessingRequest"></button>
                                        <button *ngIf="resendText !== '' && !hideResend" (click)="resendVerificationCode()" id="resendCode">{{resendText}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</body>
