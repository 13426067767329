import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'auth-footer',
  templateUrl: './auth-footer.component.html',
  styleUrls: ['./auth-footer.component.scss'],
})
export class AuthFooterComponent {

  customerServiceNumber = environment.customerServiceNumber;
  currentYear = (new Date()).getFullYear();
  termsOfUseUrl = environment.termsOfUseUrl;
  aboutJJKUrl = environment.aboutJJKUrl;
  noticeAtCollectionUrl = environment.noticeAtCollectionUrl;
  supportContactUrl = environment.supportContactUrl;
  jjkUrl = environment.jjkUrl;
  jjkHorizontalRGBLogo = environment.jjkCDN + environment.jjkHorizontalRGBLogo;
  constructor() {
  }
}
