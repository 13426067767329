import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { DragAndDropActionItemComponent } from '@components/drag-and-drop-action-item/drag-and-drop-action-item.component';

@Component({
    selector: 'jjk-drag-and-drop-grid-standard-row',
    template: `<ng-content></ng-content>`,
})
export class DragAndDropGridStandardRowComponent implements AfterViewInit {

    constructor() {
    }

    @Input() columnName: string;
    @Input() header: string;
    @Input() title: string;
    @Input() content: string;
    @Input() footer: string;
    @Input() showFileInfo: boolean;
    @Input() fileDownloadable: any;
    @Input() fileDownloading: boolean;

    @Output() onDownload = new EventEmitter();

    actionItems: DragAndDropActionItemComponent[];

    @ContentChildren(DragAndDropActionItemComponent) childActionItemComponentList !: QueryList<DragAndDropActionItemComponent>;

    ngAfterViewInit() {
        this.actionItems = this.childActionItemComponentList.toArray();
    }
}
