import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'insertChar'})
export class InsertCharPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
   /**
   * Insert character at specified location(s) within the value.
   * First argument should be the character you want to insert. Any subsequent args should be ints specifying the indexes you want the new character to be inserted at.
   * - Example: <p [innerHTML]="form.field | insertChar: '-' : '2' : '4' : '6'"></p>
   */
  transform(value: any, ...args: any[]) {
    if (value && args?.length > 1) {
      // first arg is the char to insert, get that and then remove it from array
      const charToInsert = args[0].toString();
      args.shift();
      // filter out non-ints and then sort desc so the char is inserted in the correct locations
      const sortedArgs = args.filter(x => !isNaN(parseInt(x, 10))).sort((a, b) => b - a);
      sortedArgs.forEach(arg => {
        if (value.toString().length >= arg) {
          value = value.toString().slice(0, arg) + charToInsert + value.toString().slice(arg);
        }
      });
      return value;
    }
    return value;
  }
}
