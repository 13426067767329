import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * @whatItDoes Truncates a string
 * @howToUse `expression | json`
 * @description
 *
 * Truncates a string value to a provided limit (on complete words, ending at a period)
 *
 * ### Example
 * <p [innerHTML]="row.description | truncate : 255"></p>
 *
 */
@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }

    transform(value: string, limit = 255) {
        if (value && value.length > limit) {
            const truncated = value.substr(0, limit);
            const truncatedCompleteWords = truncated.substr(0, truncated.lastIndexOf('.') + 1);

            return this.sanitized.bypassSecurityTrustHtml(truncatedCompleteWords);
        } else {
            return value;
        }
    }
}
