import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'jjk-drag-and-drop-action-item',
    templateUrl: './drag-and-drop-action-item.component.html',
})
export class DragAndDropActionItemComponent {
    @Input() component: any;
    @Input() row: any;
    @Input() displayText: string;
    @Input() container: any;
    @Input() shouldDisplay: any;

    @Output() action = new EventEmitter();

    constructor() {
    }

    onActionItemClick(component, container, row, displayText) {
        if (container) {
            this.action.emit({ component, container, row, displayText });
        } else {
            this.action.emit({ component, row, displayText });
        }
    }

}
