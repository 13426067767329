import { ValidatorFn } from "@angular/forms";

export class PasswordRuleViewModel {
    displayText: string;
    validator: ValidatorFn;
    errorName: string;
    constructor(
        displayText: string,
        validator: ValidatorFn,
        errorName: string,
    ) {
        this.displayText = displayText;
        this.validator = validator;
        this.errorName = errorName;
    }
}
