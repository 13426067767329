import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { ApiFactory } from '@services/core/api-factory.class';
import { FilterExpressionBuilder } from '@services/core/models/Filter-Entry';

@Component({
    selector: 'jjkp-citations-select',
    templateUrl: './citations-select.component.html',
})
export class CitationsSelectComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

    allCitationsFromApi: any;
    citationsFromApi$: Observable<any[]>;
    citationsLoading = false;
    citationsinput$ = new Subject<string>();
    citationsInputSubscription$: Subscription;

    @Output() selectedObjects = new EventEmitter<any[]>();

    @Input() citationObjects;

    private _selectedCitations;
    get selectedCitations() {
        return this._selectedCitations;
    }
    set selectedCitations(value) {
        this._selectedCitations = value;
        this.emitSelectedCitations();
    }

    constructor() {
    }

    ngOnInit() {
        this.citationsInputSubscription$ = this.citationsinput$.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            tap((term) => this.requestCitationData(term))).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        // For Edit controls, get the list of citations, add them to the dropdown list, selected them, update the subscription
        if (changes.citationObjects && changes.citationObjects.currentValue && changes.citationObjects.previousValue === undefined && changes.citationObjects.currentValue.length > 0) {
            this.citationObjects = changes.citationObjects.currentValue.sort(this.sortOptionsAscending);
            this.allCitationsFromApi = this.allCitationsFromApi || this.citationObjects;
            this.selectedCitations = this.citationObjects.map((citation) => citation.id);
            this.citationsFromApi$ = of(this.setCitationsFromApi(this.allCitationsFromApi));
        }
    }

    ngAfterViewInit(): void {
        $('ng-select input').attr('autocomplete', 'off');
    }

    ngOnDestroy() {
        if (this.citationsInputSubscription$) { this.citationsInputSubscription$.unsubscribe(); }
    }

    requestCitationData(term) {
        if (term) {
            this.citationsLoading = true;
            const search = FilterExpressionBuilder.For(ApiEntityTypesEnum.EditorialAsa)
                .Use('Search', ParameterTypeEnum.String)
                // eslint-disable-next-line no-useless-escape
                .Equal(`\"${term}\"*`)
                .Build().AsExpression;

            const searchFields = FilterExpressionBuilder.For(ApiEntityTypesEnum.EditorialAsa)
                .Use('SearchFields', ParameterTypeEnum.String)
                .Equal('CfrTitle')
                .Build().AsExpression;

            ApiFactory.retrieveEntity(ApiEntityTypesEnum.EditorialAsa)
                .addFilterEntries(search)
                .addFilterEntries(searchFields)
                .addSuccessHandler((response) => {
                    const results = response.value
                        .filter((c) => c.isActive === '1' && c.description !== null)
                        .map((citation) => {
                            return {
                                id: citation.rowKey,
                                displayText: citation.description,
                            };
                        });
                    this.allCitationsFromApi = results.concat(this.citationObjects);
                    this.citationsFromApi$ = of(this.setCitationsFromApi(this.allCitationsFromApi));
                    this.citationsLoading = false;
                })
                .removePaging()
                .buildAndSend();
        }
    }

    setCitationsFromApi(allCitationsFromApi: any) {
        return this.getUniqueList(allCitationsFromApi.filter(i => i && i.id), 'id').sort(this.sortOptionsAscending);
    }

    private emitSelectedCitations() {
        const citationObjects = [];
        this.selectedCitations.forEach((id) => {
            citationObjects.push(this.allCitationsFromApi.find((o) => o.id === id));
        });
        this.citationObjects = citationObjects;
        this.selectedObjects.emit(citationObjects);

    }

    private sortOptionsAscending(a, b) {
        return a.displayText.localeCompare(b.displayText);
    }

    getUniqueList(array, propertyName) {
        return [...new Map(array.map(item => [item[propertyName] || null, item])).values()];
    }
}
