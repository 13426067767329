export class GroupingCriteria {
    public rowGroupCols: string[];
    public groupKeys: string[];
    public valueCols: any;

    constructor(rowGroupCols: string[], groupKeys: string[], valueCols: any) {
        this.rowGroupCols = rowGroupCols;
        this.groupKeys = groupKeys;
        this.valueCols = valueCols;
    }
}
