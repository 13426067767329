export const fileTypesForPreview = [
    'application/pdf',
    'image/gif',
    'image/jpeg',
    'image/png',
    'pdf',
    'gif',
    'jpeg',
    'jpg',
    'png',
    'pdfAlreadyAdded',
    // 'mov',
    // 'wav',
    // 'mp4',
    // 'mkv',
    // 'wmv',
];
