import { Component, OnInit } from '@angular/core';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ACTIONS } from '@constants/messages.constants';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { TermsOfUse } from '@models/entity-models/autogenerated/termsofuse';
import { UserDataProfile } from '@models/entity-models/autogenerated/userdataprofile';
import { EnableMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { AuthService } from '@services/auth.service';
import { ApiFactory } from '@services/core/api-factory.class';
import { SplitioService } from '@services/splitio.service';

@Component({
  selector: 'tou-modal',
  templateUrl: './tou-modal.component.html',
  styleUrls: ['./tou-modal.component.scss'],
})
export class TouModalComponent extends EnableMessageProcessingDirective implements OnInit {

  isTOUActive = true;
  isConfirmDisabled = true;
  userDataProfile: UserDataProfile;

  constructor(mb: MessageBusService, private splitioService: SplitioService, private authService: AuthService) {
    super(mb);
   }

  protected configureMessageBus(builder: IMsgProcessingReg): void { }

  async ngOnInit() {
    this.isTOUActive = (await this.splitioService.getToggle(TOGGLES.TERMS_OF_USE_FEATURE)) === TREATMENT.ON;
    /* Terms of Use modal should only appear if:
     * - this is not a non-auth user
     * - this is not an impersonation session
     * - the user has not accepted the most recent version of the TOU
     *
     * We want to add a CSS to the profile modal to be able to hide it until
     * the TOU modal is dealt with */
    if (this.isTOUActive && !LoggedInUserInfo.Instance.userInfo.acceptedTermsOfUse && !LoggedInUserInfo.Instance.userInfo.isNonAuth && !LoggedInUserInfo.Instance.userInfo.impersonationId)
    {
      ($('#touModal') as any).modal('show');
      ($('#authMigrationInfo') as any).addClass('tou-modal-active');
      ($('#profileModal') as any).addClass('tou-modal-active');
      ($('#passwordExpireModal') as any).addClass('tou-modal-active');
    }
  }

  async onConfirm() {
    ApiFactory.retrieveEntity(ApiEntityTypesEnum.TermsOfUse)
        .addSuccessHandler((tou: TermsOfUse) => {
          const userId = LoggedInUserInfo.Instance.userInfo.userId.toString();

          const userAcceptedTerms = {
            termsOfUseId: tou[0].id,
            userId: userId,
            createdByUserId: userId,
            isDeleted: false,
          };

          ApiFactory.saveNewEntity(ApiEntityTypesEnum.UserAcceptedTerms, userAcceptedTerms)
              .addSuccessHandler(() => {
                })
              .buildAndSend();

          ApiFactory.retrieveEntity(ApiEntityTypesEnum.UserDataProfile)
              .addRouteHint('GetUserDataProfileByUserId')
              .addDataEntry('userId', userId)
              .addSuccessHandler(() => {
                LoggedInUserInfo.Instance.userInfo.acceptedTermsOfUse = true;
                this.authService.updateUserProfileData(LoggedInUserInfo.Instance.userInfo.user, LoggedInUserInfo.Instance.userInfo.userDataProfile);
                ($('#touModal') as any).modal('hide');
                ($('#authMigrationInfo') as any).removeClass('tou-modal-active');
                ($('#profileModal') as any).removeClass('tou-modal-active');
                ($('#passwordExpireModal') as any).removeClass('tou-modal-active');
              })
              .buildAndSend();
        })
        .buildAndSend();
  }

  async onCancel() {
    ($('#touModal') as any).modal('hide');
    const msg = this.msgBuilder.SystemEventMessage().usingHostSenderId().then.proceedWithoutData.assignAction(ACTIONS.AUTH.AUTH_REQUEST_LOGOUT).sendOn.systemTopic().build();
    await this.sendTopicMessage(msg);
  }
}
