import { DynamicControl } from './dynamic-control.model';
import { IDynamicSelectOptions } from './dynamic-select-control-options.model';
import { DynamicSelectDataload } from './dynamic-select-dataload.model';
import { DynamicValidator } from './dynamic-validator.model';

export class DynamicSelectControl extends DynamicControl {
    options: IDynamicSelectOptions[] = [];

    constructor(dataload: DynamicSelectDataload, name, displayName, value = null, dynamicValidators: DynamicValidator | DynamicValidator[] = null, configOptions = null) {
        super('select', name, displayName, value, dynamicValidators, configOptions);
        if (dataload) {
            this.options = dataload.options;
        }
    }
}
