const apiRootUrl = 'https://api.jjkellerportal.com';
const webRootUrl = 'https://www.jjkellerportal.com';
const blobRootUrl = 'https://jjkpprodsa.blob.core.windows.net';
const pimcoreRootUrl = 'https://www.jjkellersafety.com';
const jjkRootUrl = 'https://jjkeller.com';
const trainingOnDemandRootUrl = 'https://www.jjkellertraining.com';
const brightcovePlayerRootUrl = '//players.brightcove.net/5219833346001/cUniOsV7VL_default/index.html';
const b2cTenant = 'JJKellerPortalLoginProd';
const clientId = 'c9f88073-4c53-4dec-8b82-9a0065b617a6';
const redirectUri = `${webRootUrl}/auth-redirect`;
const tenantId = 'd31ebc02-d599-47e3-8dec-9da47c995f42';

export const environment = {
    appInsightsInstrumentationKey: '0f18439a-7c90-4f56-aedc-2d83a83bb193',
    disableCaching: true,
    sessionFinalWarningTimerInMinutes: 5,
    environmentName: 'environmentProd',
    sessionExpirationInSeconds: 60 * 10,
    splitioAuthKey: '858qk0c5g13ijft0iqgpkqapjuog3kn7p6h5',
    production: true,
    plansandpoliciesjjkazurebloburl: `${blobRootUrl}/plansandpolicies/`,
    companydatajjkazurebloburl: `${blobRootUrl}/companydata/`,
    ghspictogramjjkazurebloburl: `${blobRootUrl}/ghspictograms/`,
    attachmentjjkazurebloburl: `${blobRootUrl}/attachments/`,
    publicjjkazurebloburl: `${blobRootUrl}/public/`,
    jjkellerPortalApiRootUrl: `${apiRootUrl}/`,
    jjkellerportalapiurl: `${apiRootUrl}/api/v1/`,
    courseSampleUrl: `${trainingOnDemandRootUrl}/samples/`,
    apiVersion: 1,
    appRootUrl: `${webRootUrl}`,
    azureEndpoints: {
        tenantIdentinfier: tenantId,
        clientIdentinfier: clientId,
        apiScopes: [
            `https://${b2cTenant}.onmicrosoft.com/api/SMS.Write`,
            `https://${b2cTenant}.onmicrosoft.com/api/SMS.Read`,
            `https://${b2cTenant}.onmicrosoft.com/api/user_impersonation`,
        ],
        authorize: {
            rootEndpoint: `https://${b2cTenant}.b2clogin.com/${b2cTenant}.onmicrosoft.com/oauth2/v2.0/authorize`,
            clientID: clientId,
            response_type: 'code',
            redirect_uri: redirectUri,
            response_mode: 'query',
            scope: encodeURIComponent(clientId + ' offline_access'),
            state: 'magic',
            userFlow: 'B2C_1A_SIGNIN',
        },
        reset: {
            rootEndpoint: `https://${b2cTenant}.b2clogin.com/${b2cTenant}.onmicrosoft.com/oauth2/v2.0/authorize`,
            tenantName: `${b2cTenant}.onmicrosoft.com`,
            grant_type: 'refresh_token',
            clientID: clientId,
            response_type: 'code',
            redirect_uri: redirectUri,
            scope: 'openid',
            prompt: 'login',
            userFlow: 'B2C_1A_SIGNIN',
        },
        logout: {
            rootEndpoint: `https://${b2cTenant}.b2clogin.com/${tenantId}/oauth2/v2.0/logout`,
            clientID: clientId,
            response_type: 'code',
            redirect_uri: `${webRootUrl}/dashboard`,
            response_mode: 'query',
            userFlow: 'B2C_1A_SIGNIN',
        },
    },
    loginConfig: {
        loginEnabled: true, // TODO: implement functionality to get this working
        sessionTimeout: 15 * 60 * 1000,
    },
    titleSuffix: ' - J. J. Keller & Associates, Inc.',
    froalaEditorLicense:
        'UBB7jD5A3E3F4J3A7A6bHIMFI1EWBXIJe1BZLZFd1d1MXQLjC10D7D5A3B2A3D4E2A2C5==',
    discussionsSite: 'https://discussions.jjkellerportal.com',
    jjkUrl: `${jjkRootUrl}`,
    aboutJJKUrl: `${jjkRootUrl}/learn/company-overview`,
    termsOfUseUrl: `${jjkRootUrl}/learn/terms-conditions`,
    noticeAtCollectionUrl: `${jjkRootUrl}/learn/notice-at-collection`,
    supportContactUrl: `https://support.jjkeller.com/?title=Safety_Management_Suite%2FContact_Support%3A_Hours%252C_Phone%252C_and_Email`,
    pimcoreTermsOfUseUrl: `${pimcoreRootUrl}/terms-of-use`,
    brightcoveVideoUrl: `${brightcovePlayerRootUrl}?videoId=`,
    supportCenterUrl: 'https://support.jjkeller.com/Safety_Management_Suite',
    webcastsUrl: "https://www.jjkellersafety.com/subscriber-webcasts",
    customerServiceNumber: `1-877-564-2333 ext 2410`,
    // CSP
    cspChemicalLabelConfiguratorIframesrc: 'https://jjk.csp-1.com/cspconfigurator/ChemAppConfigurator',
    cspProxyDomain: 'https://jjk.csp-1.com',
    gridPaginationSize: 100,
    jjkCDN: 'https://cdn.jjkeller.com/wcsstore/CVCatalogAssetStore/images/global/logos/corp',
    jjkBurgandyDiamondLogo: '/JJKeller_diamond_K_Burgundy_RGB.svg',
    jjkHorizontalRGBLogo: '/JJKeller_horizlogo1953_RGB.svg',
    //Okta
    CLIENT_ID: '0oa703m3xu6ZT2DIY5d7',
    ISSUER: 'https://login.jjkeller.com/oauth2/ausesls67xqltLQec5d7',
    CALLBACK_URL: `${webRootUrl}/login/callback`,
};
