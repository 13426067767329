import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { DataGridColumnComponent } from '@components/datagrid-column/datagrid-column.component';

@Component({
    selector: 'jjk-datagrid-column-input',
    template: `<ng-content></ng-content>`,
    providers: [{ provide: DataGridColumnComponent, useExisting: forwardRef(() => DataGridColumnInputComponent) }],
})
export class DataGridColumnInputComponent extends DataGridColumnComponent {
    @Input() public maxLength: number;

    constructor() {
        super();
        this._columnType = 'Input';
    }

}
