import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ApiActionsEnum } from '@constants/enums/api-actions.enum';
import { EnableServiceMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableServiceMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { ApiMessage } from '@modules/messaging/types/Messages/apiMessage';
import { EndpointBroker } from './core/endpoint-broker.service';

@Injectable()
export class MessageDateAugmentationService extends EnableServiceMessageProcessingDirective {

    protected configureMessageBus(builder: IMsgProcessingReg): void {}

    constructor(mb: MessageBusService, private endPointBroker: EndpointBroker) {
        super(mb);
        this.endPointBroker.addPreMsgProcessingHandler((m, a) => this.preProcessMessages(m, a), 'preProcessMessages');
        this.endPointBroker.addPostMsgProcessingHandler((r, a) => this.postProcessMessages(r, a), 'postProcessMessages');
    }

    traverseObject(obj: any, transform: (itemToChange: any) => void) {
        if (Object.prototype.toString.call(obj) === '[object Array]') {
            obj.forEach((x: any) => {
                this.traverseObject(x, transform);
            });
        } else if ((typeof obj === 'object') && (obj !== null)) {
            for (const key of Object.keys(obj)) {
                this.traverseObject(obj[key], transform);
            }
        }
        transform(obj);
    }

    dateProcessor(object: any, action: ApiActionsEnum) {
        switch (action) {
            case ApiActionsEnum.CREATE:
            case ApiActionsEnum.UPDATE:
                this.traverseObject(object, (value) => {
                    if (moment(value, 'MM/DD/YYYY', true).isValid()) {
                        value = moment(value).utc().format();
                    } else if (value instanceof Date) {
                        value = moment(value).utc().format();
                    }
                });
                break;
        }
    }

    preProcessMessages(m: ApiMessage, a: ApiActionsEnum) {
        this.dateProcessor(m.messageData, a);
        return m;
    }

    postProcessMessages(r: any, a: ApiActionsEnum) {
        this.dateProcessor(r, a);
        return r;
    }

}
