import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { UserTypes } from '@constants/enums/user-type.enum';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { AlertType } from '@models/alert';
import { UserProfile } from '@models/core/UserProfile';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { ApiFactory } from '@services/core/api-factory.class';
import { LoggerService } from '@services/core/logger-service.class';
import { FilterExpressionBuilder } from '@services/core/models/Filter-Entry';
import { ImpersonationService } from '@services/impersonation.service';
import { isNullOrUndefined, newImpersonationCode } from '@utilities/helpers';
import { EmailAddressValidatorAsync } from '@utilities/validators/email-address.validator';

@Component({
    selector: 'layout-impersonation-modal',
    templateUrl: './layout-impersonation-modal.component.html',
    styleUrls: ['./layout-impersonation-modal.component.scss'],
})
export class LayoutImpersonationModalComponent extends ValidatableFormBaseDirective implements OnInit {

    isImplicitImpersonator = false;
    isFormSubmitted = false;
    impersonationList = null;
    impersonationOption = null;
    showErrors = false;
    showImpersonateTab = true;

    resetForm: UntypedFormGroup = new UntypedFormGroup({
        email: new UntypedFormControl('', {validators: Validators.required, asyncValidators: EmailAddressValidatorAsync()}),
    });

    private _selectedImpersonation = null;
    get selectedImpersonation() {
        return this._selectedImpersonation;
    }
    set selectedImpersonation(val) {
        this.form.reset();
        this._selectedImpersonation = val;
        this.form.patchValue({ id: this._selectedImpersonation });
        this.getImpersonation();
    }

    private _userName = null;
    get userName() {
        return this._userName;
    }
    set userName(val) {
        this._userName = val;
        this.form.patchValue({ userName: this._userName });
    }

    private _impersonationCode = 'xxxx-xxxx';
    get impersonationCode() {
        return this._impersonationCode;
    }
    set impersonationCode(val) {
        this._impersonationCode = val;
        this.form.patchValue({ impersonationCode: this._impersonationCode });
    }

    constructor(
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private impersonationService: ImpersonationService,
        private authService: AuthService,
        private alertService: AlertService) { super(); }

    async ngOnInit() {
        if (!LoggedInUserInfo.Instance.userInfo.mainUser) {
            LoggedInUserInfo.Instance.userInfo.mainUser = LoggedInUserInfo.Instance.userInfo.user;
        }
        this.isImplicitImpersonator = (LoggedInUserInfo.Instance.userInfo.mainUser.userType === UserTypes.SuperAdmin || LoggedInUserInfo.Instance.userInfo.mainUser.userType === UserTypes.ProductOwner) ? true : false;
        this.form = this.buildForm();
        $('#impersonationModal').on('shown.bs.modal', () => {
            this._userName = LoggedInUserInfo.Instance.userInfo.user.userName || LoggedInUserInfo.Instance.userInfo.user.email;
            this._impersonationCode = newImpersonationCode();
            this.startImpersonation();
        });
        $('#impersonatingModal').on('shown.bs.modal', () => {
            this.getImpersonationList();
        });
    }

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            id: [null, Validators.required],
            description: [null, (this.isImplicitImpersonator) ? Validators.required : null],
            userName: [null, Validators.required],
            impersonationCode: [null, (this.isImplicitImpersonator) ? null : Validators.required],
        });
    }

    hideModal(modalName: string) {
        this.form.reset();
        this.resetFormSubmissionStatus();
        this.resetForm.reset();
        this.showErrors = false;
        ($('#' + modalName) as any).modal('hide');
    }

    startImpersonation() {
        const requestData = {
            impersonatedUserId: LoggedInUserInfo.Instance.userInfo.userId,
            impersonationCode: this.impersonationCode,
        };
        ApiFactory.saveNewEntity(ApiEntityTypesEnum.Impersonations, requestData)
            .addSuccessHandler((response) => this.router.navigate(['/dashboard']))
            .addErrorHandler((response) => LoggerService.trace('trace', response))
            .buildAndSend();
    }

    private getImpersonationList() {
        const userEx = FilterExpressionBuilder.For(ApiEntityTypesEnum.User)
            .Use('ImpersonatingUserId', ParameterTypeEnum.String)
            .Equal(LoggedInUserInfo.Instance.userInfo.mainUser.id)
            .Build().AsExpression;

        const endTimeEx = FilterExpressionBuilder.For(ApiEntityTypesEnum.Impersonations)
            .Use('EndTime', ParameterTypeEnum.String)
            .Equal(null)
            .Build().AsExpression;

        ApiFactory.retrieveEntity(ApiEntityTypesEnum.Impersonations)
            .addFilterEntries(userEx)
            .addFilterEntries(endTimeEx)
            .addSuccessHandler((response) => {
                this.impersonationList = [];
                this.impersonationList = response;
                this.impersonationOption = [];
                this.impersonationOption = this.mapToSelectOptions(response);
                this.impersonationOption.unshift({ id: Guid.EMPTY, displayText: 'New Impersonation', value: Guid.EMPTY });
                if (LoggedInUserInfo.Instance.userInfo.impersonationId === '') {
                    this.selectedImpersonation = Guid.EMPTY;
                } else {
                    this.selectedImpersonation = LoggedInUserInfo.Instance.userInfo.impersonationId;
                }
            })
            .buildAndSend();
    }

    private mapToSelectOptions(response: any[]) {
        response = response.filter((i) => i.endTime === null && i.isDeleted === false);
        return response
            .map((item) => {
                // return { id: item.id, displayText: item.impersonatedUser.email + '(' + item.impersonationCode + ')', value: item.id };
                return { id: item.id, displayText: item.impersonatedUser.email, value: item.id };
            })
            .sort((a, b) => {
                if (a.value === b.value) {
                    return 0;
                }
                return a.value < b.value ? -1 : 1;
            });
    }

    get isImpersonating() {
        if (this.form && this.form.value.id === LoggedInUserInfo.Instance.userInfo.impersonationId) {
            return true;
        }
        return false;
    }

    private getImpersonation() {
        this.isFormSubmitted = false;
        this.userName = '';
        this.impersonationCode = '';
        if (this.form && this.form.value && this.form.value.id) {
            const impersonation = this.impersonationList.find((e) => e.id === this.form.value.id);
            if (impersonation) {
                this.userName = impersonation.impersonatedUser.userName || impersonation.impersonatedUser.email;
                this.impersonationCode = impersonation.impersonationCode;
                this.form.patchValue({ description: impersonation.description });
            }
        }
    }

    protected async submitForm() {
        if (this.isFormSubmitted) { return; }
        this.isFormSubmitted = true;

        if (this.form.value.userName === LoggedInUserInfo.Instance.userInfo.mainUser.email) {
            const userNameError = 'You can\'t impersonate yourself.';
            this.alertService.addAlert(AlertType.Danger, userNameError);
            this.isFormSubmitted = false;
            return;
        }
        const userToImpersonate = await this.authService.getUserByEmail(this.form.value.userName);
        if(isNullOrUndefined(userToImpersonate)) {
            this.getControl('userName').setErrors({ invalidUser: true });
            this.reportError('Invalid User', userToImpersonate)
        } else {
            this.checkImpersonating(userToImpersonate)
        }
    }

    checkImpersonating(userProfile: UserProfile) {
        if (userProfile) {
            const impersonatedUserId = userProfile.user.id;
            const currentDate = new Date();
            const subscriptionDate = new Date(userProfile.subscription.endDate);

            let errorMessage = '';
            if (!userProfile.user.isActive) {
                errorMessage = 'You cannot impersonate an inactive user.';
            } else if (subscriptionDate < currentDate) {
                errorMessage = 'You cannot impersonate a user with an expired subscription.';
            }

            if (errorMessage) {
                this.getControl('userName').setErrors({ invalidUser: true });
                this.alertService.addAlert(AlertType.Danger, errorMessage);
                this.isFormSubmitted = false;
                return;
            }

            if (this.isImplicitImpersonator && this.form.value.description && !this.form.value.impersonationCode) {
                const requestData = {
                    impersonatedUserId,
                    impersonatingUserId: LoggedInUserInfo.Instance.userInfo.mainUser.id,
                    impersonationCode: newImpersonationCode(),
                    description: this.form.value.description,
                    startTime: new Date(),
                };
                ApiFactory.saveNewEntity(ApiEntityTypesEnum.Impersonations, requestData)
                    .addSuccessHandler((response) => this.successImpersonating(response, userProfile))
                    .addErrorHandler((response) => this.reportError('Failed', response))
                    .buildAndSend();
            } else {
                const impersonatedUserEx = FilterExpressionBuilder.For(ApiEntityTypesEnum.Impersonations)
                    .Use('ImpersonatedUserId', ParameterTypeEnum.String)
                    .Equal(impersonatedUserId)
                    .Build().AsExpression;

                const impersonationCodeEx = FilterExpressionBuilder.For(ApiEntityTypesEnum.Impersonations)
                    .Use('ImpersonationCode', ParameterTypeEnum.String)
                    .Equal(this.form.value.impersonationCode)
                    .Build().AsExpression;

                const endTimeEx = FilterExpressionBuilder.For(ApiEntityTypesEnum.Impersonations)
                    .Use('EndTime', ParameterTypeEnum.String)
                    .Equal(null)
                    .Build().AsExpression;

                ApiFactory.retrieveEntity(ApiEntityTypesEnum.Impersonations)
                    .addFilterEntries(impersonatedUserEx)
                    .addFilterEntries(impersonationCodeEx)
                    .addFilterEntries(endTimeEx)
                    .removePaging()
                    .addSuccessHandler((response) => this.startImpersonating(userProfile, response))
                    .addErrorHandler((response) => this.reportError('Invalid Code', response))
                    .buildAndSend();
            }
        } else {
            this.getControl('userName').setErrors({ invalidUser: true });
            this.isFormSubmitted = false;
        }
    }

    startImpersonating(userProfile: UserProfile, impersonationList) {
        if (impersonationList.length > 0) {
            const impersonations = impersonationList[0];
            impersonations.impersonatingUserId = LoggedInUserInfo.Instance.userInfo.mainUser.id;
            impersonations.description = this.form.value.description;
            impersonations.startTime = new Date();
            ApiFactory.updateEntity(ApiEntityTypesEnum.Impersonations, impersonations)
                .addSuccessHandler((response) => this.successImpersonating(response, userProfile))
                .addErrorHandler((response) => this.reportError('Update', response))
                .buildAndSend();
        } else {
            this.getControl('impersonationCode').setErrors({ invalidCode: true });
            this.isFormSubmitted = false;
        }
    }

    async successImpersonating(response, userProfile: UserProfile) {
        LoggedInUserInfo.Instance.userInfo.impersonationId = response.id;
        LoggedInUserInfo.Instance.userInfo.userId = userProfile.user.id;
        LoggedInUserInfo.Instance.userInfo.azureUID = userProfile.user.azureInternalId;
        LoggedInUserInfo.Instance.userInfo.oktaUID = userProfile.user.oktaId;
        await this.authService.loadSmsUserInfo('' , '', true).then((data) => {
            this.isFormSubmitted = false;
            if (data !== 'complete') {
                this.alertService.addAlert(AlertType.Danger, data);
            } else {
                this.hideModal('impersonatingModal');
                this.isFormSubmitted = false;
                this.router.navigate(['/dashboard']);
                location.reload();
            }
        });
    }

    async endImpersonating() {
        await this.impersonationService.endImpersonating();
        this.hideModal('impersonationEndModal');
        this.router.navigate(['/dashboard']);
    }

    reportError(description: string, errorData: any) {
        this.isFormSubmitted = false;
    }

    getControlEmail(formControlName: string): AbstractControl {
        return this.resetForm.controls[formControlName];
    }

    hasErrorsEmail(formControlName: string): boolean {
        return this.getControlEmail(formControlName).errors !== null;
    }
    
    hasErrorsIgnorePristineEmail(formControlName: string): boolean {
        if (!this.form.pristine && (this.getControlEmail(formControlName).dirty)) {
            return this.hasErrorsEmail(formControlName);
        }
        return false;
    }

    async sendPasswordReset(){
        if(this.isFormSubmitted){return;}
        if(!this.resetForm.valid){
            this.showErrors = true;
            return;
        }

        this.isFormSubmitted = true;
        this.showErrors = false;

        const verifyUser = await this.authService.getUserByEmail(this.resetForm.value.email);
        if(isNullOrUndefined(verifyUser)){
            this.getControlEmail('email').setErrors({ emailNotFound: true });
        } else {
            await this.authService.requestResetPasswordEmail(this.getControlEmail('email').value);
            this.alertService.addAlert(AlertType.Success, 'Password Reset Sent Successful');
        }
        this.isFormSubmitted = false;
    }

    onTabClick(show: boolean) {
        this.showImpersonateTab = show;
    }
}
