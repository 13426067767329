import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { isNullOrUndefined } from '@utilities/helpers';

@Component({
    selector: 'ag-grid-download-custom-column',
    templateUrl: './ag-grid-download-custom-column.component.html',
    styleUrls: ['./ag-grid-download-custom-column.component.scss']
})
export class AGGridDownloadCustomColumnComponent implements ICellRendererAngularComp {
    @Input() cell: any;
    @Output() onClicked = new EventEmitter<boolean>();

    // Complete list of parameters, provided by the grid
    public params: any;
    public componentParent: any;
    // Use this to configure component appearance and behavior based on custom parameters
    customColumnParams: any;
    // Contains the row datasource
    row: any;
    showDownload = true;
    downloadable = true;

    viewAuth = false;
    productListAlreadyAdded: boolean;

    constructor() {
    }

    agInit(params: any): void {
        this.params = params;
        this.componentParent = this.params.context.componentParent;
        this.row = this.params.data;
        this.downloadable = isNullOrUndefined(this.row.isDownloadable) || this.row.isDownloadable === true;

        if (!isNullOrUndefined(params.authFeature)) {
            this.viewAuth = this.checkpermissions(params.authFeature);
        }

        if (!isNullOrUndefined(params.sdsSearchGrid) && params.sdsSearchGrid === true) {
            if (!this.row.parentAlreadyInUse)
                return;
            this.productListAlreadyAdded = true;
        }
    }

    checkpermissions(feature) {
        const split = feature.toString().split(',');
        const domain = split[0];
        const permissionType = (split.length > 1) ? split[1] : '';
        const ignoreGroup = (split.length > 2) ? split[2] === 'ignoreGroup' : false;

        return LoggedInUserInfo.Instance.userInfo.checkAuthFeature(domain, permissionType, this.params.data, false, ignoreGroup);
    }

    refresh(params: ICellRendererParams): boolean {
        return true;
    }

    openAsDownloadOrPreview(event) {
        this.params['ChildComponentAction'] = 'DownloadColumn';
        this.params['DownloadFile'] = event;
        this.params.action(this.params);
    }

}
