import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ChartsBaseModel } from '@models/reporting/charts/charts-base.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { LegendPosition } from '@swimlane/ngx-charts';

@Component({
    selector: 'jjk-task-breakdown-chart',
    templateUrl: './jjk-task-breakdown-chart.component.html',
    styles: [`
        .chart-container {
        margin-bottom: 45px;
        min-height: 300px;
        }
        `],
})

export class JJKTaskBreakdownChartComponent implements OnChanges {
    @Input() chartType = 'Pie';
    @Input() view;
    @Input() legend = true;
    @Input() legendTitle = '';
    @Input() legendPosition = LegendPosition.Below;
    @Input() chartData: ChartsBaseModel;
    @Input() results: any;
    @Input() showAddButtonEmptyState = true;
    @Input() emptyStateMessage;

    @Output() action = new EventEmitter();

    isEmptyState = true;

    constructor() {
        ApiFactory.retrieveEntity(ApiEntityTypesEnum.Chart)
            .addDataEntry('chartType', 'TaskBreakdownChart')
            .addRouteHint('Chart/ChartAsync')
            .addSuccessHandler((x) => {
                this.chartData = this.mapTaskBreakdownChart(x);
                this.isEmptyState = this.chartData.data.length > 0 ? false : true;
            }).buildAndSend();
    }

    mapTaskBreakdownChart(x) {
        const model = new ChartsBaseModel() ;
        model.data = x.nameValues;
        model.colorScheme.domain = [];
        x.nameValues.forEach((element: { color: string; }) => {
            model.colorScheme.domain.push(element.color);
        });
        model.title = 'Task Breakdown';
        return model;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.chartData) {
            this.updateChart(changes.chartData.currentValue);
        }
    }
    updateChart(chartData) {
        this.chartData = chartData;
    }

    onEmptyStateAddNewClick(event: any) {
        this.action.emit(event);
    }
}
