<div *ngIf="showChart" class="chart-container">
    <h2 style="text-transform: none;"> {{title}} </h2>
    
    <div class="EmptyStateContainer">
      <jjk-empty-state *ngIf="isEmptyState" auth authFeature="chemical,create" (action)='onEmptyStateAddNewClick($event)' [showAddButton]="true" [displayText]="emptyStateMessage"></jjk-empty-state>
    </div>

    <div #containerRef (window:resize)="onResize($event)">
      <ngx-charts-bar-vertical *ngIf="!isEmptyState"
      [view]="view"
      [scheme] ="colorScheme"
      [results]="results"
      [gradient]="gradient"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [legend]="legend"
      [legendTitle]="legendTitle"
      [xAxis]="xAxis"
      [yAxis]="yAxis"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [yAxisTickFormatting]="yAxisTickFormattingFn"
      [animations]="barAnimations"
      tooltipDisabled="true"
      >
      </ngx-charts-bar-vertical>
    </div>

</div>
  