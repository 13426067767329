import { Component, OnInit } from '@angular/core';
import { UserCreationType } from '@constants/enums/user-creation-type.enum';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { AuthService } from '@services/auth.service';
import { LoggerService } from '@services/core/logger-service.class';

@Component({
    selector: 'okta-first-login-modal',
    templateUrl: './okta-first-login-modal.component.html',
    styleUrls: ['./okta-first-login-modal.component.scss']
})
export class OktaFirstLoginModalComponent implements OnInit {

    products: string;
    otherProducts: string;
    isNewOktaUser = false;
    isSapUser = false;
    dataLoaded = false;

    constructor(private authService: AuthService,) { }

    async ngOnInit() {
        ($('#oktaFirstLoginModal') as any).modal('show');
        ($('#touModal') as any).addClass('notification-modal-active');
        ($('#profileModal') as any).addClass('notification-modal-active');
        ($('#passwordExpireModal') as any).addClass('notification-modal-active');

        await this.authService.checkIfOktaUser(LoggedInUserInfo.Instance.userInfo.user.email)
            .then((data) => {
                const nonSMSproducts = data.products.filter(p => p !== 'Safety Management Suite' && p !== 'Safety Management Suite RO' && p !== 'SafetyManagementSuite.APIM')
                this.products = nonSMSproducts.join(', ');
                this.otherProducts = nonSMSproducts.length > 1 ? `${this.products.substring(0, this.products.lastIndexOf(','))} and ${this.products.substring(this.products.lastIndexOf(',') + 2)}` : this.products;
                this.isNewOktaUser = data.products.filter(p => p !== 'Safety Management Suite' && p !== 'Safety Management Suite RO' && p !== 'SafetyManagementSuite.APIM').length === 0;
                this.dataLoaded = true;
            })
            .catch((errorMessage) => {
                LoggerService.error(errorMessage);
            });
        this.isSapUser = LoggedInUserInfo.Instance.userInfo.user.userCreationType === UserCreationType.SAPCreated;
    }

    onConfirm() {
        LoggedInUserInfo.Instance.userInfo.loginCount = 1;
        ($('#oktaFirstLoginModal') as any).modal('hide');
        ($('#touModal') as any).removeClass('notification-modal-active');
        ($('#profileModal') as any).removeClass('notification-modal-active');
        ($('#passwordExpireModal') as any).removeClass('notification-modal-active');
    }
}
