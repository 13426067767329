import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/*
 *
 * Usage:
 *   <p [innerHTML]="oitForm.governingBody | notPopulated"></p>
 * Example:
 *
*/
@Pipe({name: 'notApplicable'})
export class NotApplicablePipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value, pipeArg) {
    if (pipeArg === 'Uploaded') {
      if (value < 0) {
        return this.sanitized.bypassSecurityTrustHtml(
          `<span class="italic">(N/A)</span>`);
      } else {
        return value;
      }
    } else {
      if (!value || value.length < 1 || value === 'null') {
        return this.sanitized.bypassSecurityTrustHtml(
          `<span class="italic">(not populated)</span>`);
      } else {
        return value;
      }
    }
  }
}
