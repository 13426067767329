import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';

@Component({
    selector: 'profile-modal',
    templateUrl: './profile-modal.component.html',
    styleUrls: ['./profile-modal.component.scss'],
})
export class ProfileModalComponent implements OnInit, OnDestroy {

    isVisible = false;
    showForm = false;

   constructor() {
   }

    ngOnDestroy() {
        this.hideModal();
    }

    ngOnInit() {
        if (!LoggedInUserInfo.Instance.userInfo.user.lastReviewed && !LoggedInUserInfo.Instance.userInfo.impersonationId) {
            this.showForm = true;
            this.openProfileModal();
        }
    }

    openProfileModal() {
        if (this.isVisible) { return; }
        this.isVisible = true;
        ($('#profileModal') as any).modal('show');
        ($('#authMigrationInfo') as any).addClass('profile-modal-active');
        ($('#passwordExpireModal') as any).addClass('profile-modal-active');
    }

    hideModal() {
        ($('#profileModal') as any).modal('hide');
        ($('#authMigrationInfo') as any).removeClass('profile-modal-active');
        ($('#passwordExpireModal') as any).removeClass('profile-modal-active');
        this.isVisible = false;
        this.showForm = false;
    }
}
