import { AfterViewInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { NotificationCardComponent } from '../notification-card/notification-card.component';

@Component({
    selector: 'jjk-notifications-bar',
    templateUrl: './notifications-bar.component.html',
})
export class NotificationsBarComponent implements AfterViewInit {
    @Input() title = 'Notifications';

    @ContentChildren(NotificationCardComponent) notificationCards !: QueryList<NotificationCardComponent>;
    subscription$: Subscription;

    //#region cards
    private _cards = new BehaviorSubject<NotificationCardComponent[]>([]);
    set cards(value) {
        this._cards.next(value);
    }
    get cards() {
        return this._cards.getValue();
    }
    cards$: Observable<NotificationCardComponent[]> = this._cards.asObservable();
    //#endregion

    constructor() {
    }

    ngAfterViewInit() {
        setTimeout(() => this.cards = this.notificationCards.toArray());
    }

    onCardClick(card: NotificationCardComponent) {
        if (card) {
            card.onClick.emit(card.title);
        }
    }
}
