<div [id]="subFormId" class="collapse" [class.show]="isOpen" [attr.alphaId]="alphaId">
  <form *ngIf="formControls" class="form-group">
    <div class="card subform">
      <div class="form-group" *ngFor="let control of formControls">
        <dynamic-form-control [controlData]='control' [resetControl]="resetControls.asObservable()"></dynamic-form-control>
      </div>
      <div class="form-group text-end">
        <button class="btn btn-secondary btn-sm" type="button" (click)="reset()">
          Cancel
        </button>
        <button class="btn btn-primary btn-sm" type="button" (click)="submit()" [disabled]="isFormSubmitted">
          Save
        </button>
      </div>
    </div>
  </form>
</div>
<modal-window *ngIf="modalData" [id]="'makeActiveModal' + this.alphaId" [title]="'Reactivate ' + title + '?'" [cancelButtonText]="'No'" [confirmButtonText]="'Yes'" (onConfirm)="reActivate(modalData)" [data]="modalData" [isActionButtonEnabled]="true">
  <p> {{ modalDisplay }}</p>
</modal-window>