import { AbstractControlOptions, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { DynamicControl } from '@models/dynamic-forms.export';
import { isValidArray } from '@utilities/helpers';

export enum SubmitStatus {
  NotStarted,
  WithErrors,
  Sent,
  Failed,
  Succeded,
}

export class DynamicFormGroup extends UntypedFormGroup {

  private _submitStatus = SubmitStatus.NotStarted;
  get submitStatus(): SubmitStatus {
    return this._submitStatus;
  }
  set submitStatus(status: SubmitStatus) {
    this._submitStatus = status;
    this.updateControlsErrors();
    if (this._submitStatus === SubmitStatus.Succeded) {
      this.onSubmitStatusSucceded$.next(this);
    }
  }

  get isSubmitStatusSucceded() {
    return this.submitStatus === SubmitStatus.Succeded;
  }

  onSubmitStatusSucceded$ = new Subject<DynamicFormGroup>();

  dynamicControls: DynamicControl[] = [];
  baseForm: UntypedFormGroup;

  constructor(controls: DynamicControl[], validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null) {
    super({}, validatorOrOpts);

    this.dynamicControls = [];
    if (isValidArray(controls)) {
      controls.forEach((control) => {
        this.addControl(control.name, control);
        this.dynamicControls.push(control);
      });
    }

    this.baseForm = this as UntypedFormGroup;
  }

  updateControlsErrors() {
    const showErrors = this.submitStatus === SubmitStatus.WithErrors;
    this.dynamicControls.forEach((x) => {
      x.showErrors = showErrors;
    });
  }
}
