import { Pipe, PipeTransform } from '@angular/core';
import { buildFullName } from '@utilities/helpers';

@Pipe({
    name: 'fullNameFormat',
})
export class FullNameFormatPipe implements PipeTransform {
    transform(lastName: any, firstName: any): any {
        return buildFullName(lastName, firstName);
    }
}
