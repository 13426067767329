import { Injectable } from '@angular/core';
import { EnableServiceMessageProcessingDirective } from 'app/modules/messaging/baseClasses/EnableServiceMessageProcessing';
import { MessageBusService } from 'app/modules/messaging/services/messageBusService';
import { ApiMessage } from 'app/modules/messaging/types/Messages/apiMessage';
import { ApiActionsEnum } from '@constants/enums/api-actions.enum';
import { TOPICS } from '@constants/messages.constants';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageTypeEnum } from '@modules/messaging/baseClasses/MessageTypeEnum';
import { EndpointBroker } from './endpoint-broker.service';

/**
 * The ApiMasterService class is intended to make API service
 * calls simple.  It does so by forcing the implementor to register
 * a URL template for each of the 4 main HTTP verbs.  In addition
 * there is a method to allow additional endpoints to be registered
 * via a simple helper that is passed in to the method.  Accessing
 * these endpoints is performed by calling the methods implemented
 * here that corespond to those verbs.  Part of calling these action
 * methods requires the provision of a response function.  In the
 * event the data received needs to be further processed, it is the
 * responsibility of the implementing API to do that.
 *
 * It is expected that Filtering and Sorting data will be part of the
 * MessageData sent to the API.  Those values will be passed as header
 * values.
 */
@Injectable()
export class ApiMasterService extends EnableServiceMessageProcessingDirective {
    constructor(
        messageBus: MessageBusService,
        private endPointBroker: EndpointBroker) {
        super(messageBus);
    }

    protected configureMessageBus(builder: IMsgProcessingReg): void {
        const t1 = builder.add().inBoundDataTopic(TOPICS.API.API_MASTER_SERVICE);
        t1.listenForMessageType(MessageTypeEnum.ApiMessage)
            .withAction(ApiActionsEnum.CREATE)
            .thenCallFunction((m: ApiMessage) => this.processCreateMessage(m))
            .withAction(ApiActionsEnum.DELETE)
            .thenCallFunction((m: ApiMessage) => this.processDeleteMessage(m))
            .withAction(ApiActionsEnum.RETRIEVE)
            .thenCallFunction((m: ApiMessage) => this.processRetrieveMessage(m))
            .withAction(ApiActionsEnum.UPDATE)
            .thenCallFunction((m: ApiMessage) => this.processUpdateMessage(m));
    }

    //#endregion

    // These are the inbound messages from within the App
    protected processCreateMessage(msg: ApiMessage) {
        this.endPointBroker.post(msg);
    }
    protected processRetrieveMessage(msg: ApiMessage) {
        this.endPointBroker.get(msg);
    }
    protected processUpdateMessage(msg: ApiMessage) {
        this.endPointBroker.put(msg);
    }
    protected processDeleteMessage(msg: ApiMessage) {
        this.endPointBroker.delete(msg);
    }
}
