<div id="alertDismiss" [ngClass]="customStyle" class="alert alert-info d-flex align-items-center" role="alert">
  <div style="margin-right: 6px;">
    <button type="button" class="btn" (click)="dismissAlert()">
      <strong id="dismissButton">Dismiss</strong>
    </button>
  </div>
  <div id="message">
    {{ messageText }}
  </div>
</div>
