<ul class="tree-root" *ngIf="treeData">
  <li *ngFor="let node of treeData" class="tree-node" (click)="onSelect($event,node);">
    <div class="card-node" [ngClass]="{disabled:!node.enabled, matched:node.matched, selected:(selectedOption?.id==node.id)}">
      <div class="node-arrow" *ngIf="node.children.length != 0" (click)="toggleChild($event,node)">
        <i class="fas fa-angle-right" aria-hidden="true" *ngIf="!node.opened"></i>
        <i class="fas fa-angle-down" aria-hidden="true" *ngIf="node.opened"></i>
      </div>
      <div class="node-arrow" *ngIf="node.children.length == 0">
        <i class="fas fa-circle" aria-hidden="true" style="font-size: 10px;"></i>
      </div>
      <div class="node-label">
        {{node.name}}
      </div>
    </div>
    <jjk-tree *ngIf="node.opened" [treeData]="node.children" [(selectedOption)]="selectedData" (selectedOptionChange)="onSelectChange($event)"></jjk-tree>
  </li>
</ul>