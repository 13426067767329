import { EventEmitter, Inject, Injectable, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthErrorMessage } from '@constants/auth/auth-error-message.enum';
import { WARNINGDAYS } from '@constants/auth/password-expire';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { ACTIONS, DATAKEYS, TOPICS } from '@constants/messages.constants';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { environment } from '@env/environment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { JJKellerApiResponse } from '@models/api/JJKellerApiResponse.model';
import { ChangePassword } from '@models/auth/change-password.model';
import { PasswordRequirements } from '@models/auth/password-requirements.model';
import { JJKUserInfo } from '@models/core/JJKUserInfo.class';
import { User } from '@models/entity-models/autogenerated/user';
import { UserDataProfile } from '@models/entity-models/autogenerated/userdataprofile';
import { NonAuthResponse } from '@models/nonAuth/nonAuthResponse';
import { EnableServiceMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableServiceMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageTypeEnum } from '@modules/messaging/baseClasses/MessageTypeEnum';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { Message } from '@modules/messaging/types/Messages/Message';
import { SystemEventMessage } from '@modules/messaging/types/Messages/SystemEventMessage';
import { NavigationEntry } from '@modules/navigation/NavigationEntry';
import { SmsNavigationManager } from '@modules/navigation/sms-navigation-manager.class';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { AgGridStateService } from '@services/aggridState.service';
import { ApiFactory } from '@services/core/api-factory.class';
import { FilterExpressionBuilder } from '@services/core/models/Filter-Entry';
import { PendoService } from '@services/pendo.service';
import { SessionManagementService } from '@services/session.management.service';
import { SplitioService } from '@services/splitio.service';

/**
 * This service will publish messages to all subscribers listening for
 * information regarding the current security session.  Messaging will
 * be the exclusive way to interact with the security layer.
 */
@Injectable()
export class AuthService extends EnableServiceMessageProcessingDirective implements OnDestroy, OnInit {

    @Output() migrationStatusEmitter = new EventEmitter<any>();
    constructor(msgbus: MessageBusService,
        private navigator: SmsNavigationManager,
        private agGridState: AgGridStateService,
        private router: Router,
        private sessionService: SessionManagementService,
        private pendoService: PendoService,
        public authStateService: OktaAuthStateService,
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
        private splitioService: SplitioService,) {
        super(msgbus);
    }

    protected configureMessageBus(builder: IMsgProcessingReg): void {
        builder
            .add()
            .inBoundDataTopic(TOPICS.APPLEVEL.SYSTEM)
            .listenForMessageType(MessageTypeEnum.SystemEventMessage)
            .withAction(ACTIONS.AUTH.AUTH_REQUEST_LOGOUT)
            .thenCallFunction((f) => this.processRequestLogoutMessage())
            .withAction(ACTIONS.AUTH.CREATE_NEW_SMS_ACCOUNT)
            .thenCallFunction(async (f) => await this.processCreateSMSUserDataMessage(f))
            .withAction(ACTIONS.AUTH.AUTH_REQUEST_LOGIN)
            .thenCallFunction(async (f) => await this.processLoginMessage(f))
            .withAction(ACTIONS.AUTH.AUTH_REQUEST_TOKEN_REFRESH)
            .thenCallFunction(async (f) => await this.processTokenRefreshMessage(f))
            .withAction(ACTIONS.AUTH.AUTH_REQUEST_AUTH_TOKEN)
            .thenCallFunction(async (f) => await this.processAuthTokenRequestMessage(f))
            .withAction(ACTIONS.AUTH.AUTH_REQUEST_OKTA_LOGIN)
            .thenCallFunction(async (f) => { return await this.processNewAuthTokenRequestMessage(f) })
            .withAction(ACTIONS.AUTH.REQUEST_PASSWORD_RESET)
            .thenCallFunction(async (f) => await this.processPasswordResetMessage(f));

    }

    private async buildAuthorizeEndpoint(desiredLandingPageUrl: string, msg?: Message) {
        const e = environment.azureEndpoints.authorize;

        if (!desiredLandingPageUrl || desiredLandingPageUrl.length === 0) {
            desiredLandingPageUrl = '/dashboard';
        }

        const stateObj = desiredLandingPageUrl.toString().trim();
        const stateJson = stateObj;
        /* https://jjkellerportallogin.b2clogin.com/JJKellerPortalLogin.onmicrosoft.com/oauth2/v2.0/authorize?
        p=B2C_1_SMS_SignIn_V2&
        client_id=f6e3ef54-f3f6-4450-9de0-029d34e8a9fa&
        nonce=defaultNonce&
        redirect_uri=https%3A%2F%2Fjjkellerportal-dev-webui-webapp.azurewebsites.net%2Fauth-redirect&
        scope=openid&
        response_type=code&
        prompt=login
        */

        const emailAddress = msg?.messageData[DATAKEYS.APPLEVEL.SECURITY.USER_CREDENTIALS]?.emailAddress === undefined ? "" : msg.messageData[DATAKEYS.APPLEVEL.SECURITY.USER_CREDENTIALS].emailAddress;
        const url = `${e.rootEndpoint}?p=${e.userFlow}&` +
            `client_id=${e.clientID}&` +
            `nonce=defaultNonce&` +
            `redirect_uri=${e.redirect_uri}&` +
            `response_mode=${e.response_mode}&` +
            `scope=${e.scope}&` +
            `response_type=${e.response_type}&` +
            `login_hint=${emailAddress}&` +
            `prompt=login&` +
            `state=${stateJson}&` +
            `max_age=900&` +
            `AccessTokenLifetime=90`;

        const isB2CRedirect = msg?.messageData[DATAKEYS.APPLEVEL.SECURITY.FORCE_REDIRECT] === true

        // PBI 87372: [SMS][OKTA] Adjust re-login flow for Okta
        if (!isB2CRedirect || (LoggedInUserInfo.Instance.userInfo.iss && !LoggedInUserInfo.Instance.userInfo.iss.includes('b2clogin'))) {
            window.localStorage.setItem('desiredPageUrl', desiredLandingPageUrl)
            return AuthRoutes.Login.urlRoute;
        }
        // ----------------------------------------------
        return url;
    }

    private buildResetPasswordEndpoint(desiredLandingPageUrl: string): string {
        const e = environment.azureEndpoints.reset;
        const url = `${e.rootEndpoint}?p=${e.userFlow}&client_id=${e.clientID}&redirect_uri=${e.redirect_uri}&scope=${e.scope}&response_type=${e.response_type}&prompt=${e.prompt}&state='${desiredLandingPageUrl}'`;
        return url;
    }

    /**
     * This method is called in response to a message to convert the AuthCode to an
     * actual Auth Token.  If all goes well, the token is recieved and processed,
     * resulting in an update to the LoggedInUser global variable.
     * @param systemMsg
     */
    async processAuthTokenRequestMessage(systemMsg: SystemEventMessage): Promise<void> {

        const azureAuthCode = systemMsg.messageData[DATAKEYS.AUTH.AUTH_CODE];
        const clientId = systemMsg.messageData[DATAKEYS.AUTH.CLIENT_ID];
        const targetUrl = systemMsg.messageData[DATAKEYS.GENERAL.DATA] || '';
        const inviteToken = systemMsg.messageData[DATAKEYS.APPLEVEL.ROUTE.INVITE_TOKEN] || '';
        const materialCode = systemMsg.messageData[DATAKEYS.APPLEVEL.ROUTE.MATERIAL_CODE] || '';
        const accountNumber = systemMsg.messageData[DATAKEYS.APPLEVEL.ROUTE.ACCOUNT_NUMBER] || '';
        const promoCode = systemMsg.messageData[DATAKEYS.APPLEVEL.ROUTE.PROMO_CODE] || '';
        // since we have a code, we now need to convert it to an AuthToken which occurs at a different Endpoint
        const msg = ApiFactory.retrieveEntity(ApiEntityTypesEnum.Security)
            .addDataEntry('azureAuthCode', azureAuthCode)
            .addDataEntry('azureClientId', clientId)
            .addRouteHint('GetAuthToken')
            .addSuccessHandler((d) => this.receivedAuthToken(d, targetUrl.trim(), inviteToken, materialCode, accountNumber, promoCode))
            .addErrorHandler((e) => this.errorGettingAuthToken(e));
        msg.buildAndSend();
    }

    /**
     * This method is called in response to a message to convert the AuthCode to an
     * actual Auth Token.  If all goes well, the token is recieved and processed,
     * resulting in an update to the LoggedInUser global variable.
     * @param systemMsg
     */
    async processNewAuthTokenRequestMessage(systemMsg: SystemEventMessage) {

        const azureAuthCode = systemMsg.messageData[DATAKEYS.AUTH.AUTH_CODE];
        const clientId = systemMsg.messageData[DATAKEYS.AUTH.CLIENT_ID];
        const inviteToken = systemMsg.messageData[DATAKEYS.APPLEVEL.ROUTE.INVITE_TOKEN] || '';
        const materialCode = systemMsg.messageData[DATAKEYS.APPLEVEL.ROUTE.MATERIAL_CODE] || '';
        const accountNumber = systemMsg.messageData[DATAKEYS.APPLEVEL.ROUTE.ACCOUNT_NUMBER] || '';
        const promoCode = systemMsg.messageData[DATAKEYS.APPLEVEL.ROUTE.PROMO_CODE] || '';
        const credentials = systemMsg.messageData[DATAKEYS.APPLEVEL.SECURITY.USER_CREDENTIALS] || '';

        const desiredLandingPageUrl = window.localStorage.getItem('desiredPageUrl');
        const targetUrl = systemMsg.messageData[DATAKEYS.GENERAL.DATA] ? systemMsg.messageData[DATAKEYS.GENERAL.DATA] : desiredLandingPageUrl;

        if (desiredLandingPageUrl) { window.localStorage.removeItem('desiredPageUrl'); }

        // since we have a code, we now need to convert it to an AuthToken which occurs at a different Endpoint
        this.receivedAuthToken(credentials, targetUrl.trim(), inviteToken, materialCode, accountNumber, promoCode);
    }

    errorGettingAuthToken(e: JJKellerApiResponse): void {
        LoggedInUserInfo.Instance.resetLoggedInUserData();
        this.agGridState.removeAllGridsFromLocalStorage();
        this.login();
        // redirect to a fatal error page
    }

    /**
     * This handler is called when we receive an Auth Token from Azure.  In this Token
     * is a Refresh Token that is used to keep the Auth Token alive.  We also get an ID Token
     * which speaks about the User and then the Auth Token itself which has the Scopes to
     * describe what the user can do.
     * @param d
     * @param targetURL
     */
    async receivedAuthToken(d: any, targetURL: string, inviteToken: string, materialCode: string, accountNumber: string, promoCode: string): Promise<void> {

        const msgJson = JSON.parse(d);
        LoggedInUserInfo.Instance.userInfo.accessToken = msgJson['access_token'];
        LoggedInUserInfo.Instance.userInfo.refreshToken = msgJson['refresh_token'];
        LoggedInUserInfo.Instance.userInfo.idToken = msgJson['id_token'];
        LoggedInUserInfo.Instance.userInfo.passwordExpiresInDays = msgJson['PasswordExpiresInDays'];
        try {
            if (LoggedInUserInfo.Instance.isNewUser) {
                await this.createNewSMSUserRecord(LoggedInUserInfo.Instance.userInfo, inviteToken, materialCode, accountNumber, promoCode);
            } else {
                await this.loadSmsUserInfo(materialCode, promoCode);
                if (LoggedInUserInfo !== undefined && LoggedInUserInfo.Instance.userInfo.migrated === undefined) {
                    await this.getMigrationStatus();
                }
            }
            this.navigator.navigateTo(await NavigationEntry.FromRouteString(targetURL));
        } catch (error) {

        }
    }

    async receivedAuthResponse() {
        LoggedInUserInfo.Instance.userInfo.accessToken = this.oktaAuth.getAccessToken();
        LoggedInUserInfo.Instance.userInfo.idToken = this.oktaAuth.getIdToken();
        LoggedInUserInfo.Instance.userInfo.refreshToken = this.oktaAuth.getRefreshToken();
        try {
            if (!LoggedInUserInfo.Instance.userInfo.isInitialized) {
                await this.loadSmsUserInfo('','');//(materialCode, promoCode);
                if (LoggedInUserInfo !== undefined && LoggedInUserInfo.Instance.userInfo.migrated === undefined) {
                    await this.getMigrationStatus();
                }
                const targetUrl = window.localStorage.getItem('desiredPageUrl');
                if (targetUrl) window.localStorage.removeItem('desiredPageUrl');
                this.navigator.navigateTo(await NavigationEntry.FromRouteString(targetUrl ?? '/dashboard'));
            }
        } catch (error) {

        }
    }

    async processTokenRefreshMessage(systemMsg: SystemEventMessage): Promise<void> {
        // since we have a code, we now need to convert it to an AuthToken which occurs at a different Endpoint
        const msg = ApiFactory.updateEntity(ApiEntityTypesEnum.Security, ApiEntityTypesEnum.Security)
            .addRouteHint('RefreshAuthToken')
            .addSuccessHandler((d) => this.updateAuthToken(d))
            .addErrorHandler((e) => this.errorGettingAuthToken(e));
        msg.buildAndSend();

    }

    updateAuthToken(data: any) {
        const msgJson = JSON.parse(data);
        LoggedInUserInfo.Instance.userInfo.accessToken = msgJson['access_token'];
        LoggedInUserInfo.Instance.userInfo.refreshToken = msgJson['refresh_token'];
        LoggedInUserInfo.Instance.userInfo.idToken = msgJson['id_token'];

        this.splitioService.getToggle(TOGGLES.OKTA_UNIFIED_LOGIN).then((isUnifiedSplitOn) =>
        {
            if (isUnifiedSplitOn === TREATMENT.ON) {

                const tokenHelper = new JwtHelperService();
                const token = this.oktaAuth.tokenManager.getTokens().then((token) =>
                {
                    const accessToken = token.accessToken;
                    accessToken.accessToken = LoggedInUserInfo.Instance.userInfo.accessToken;

                    const idToken = token.idToken;
                    idToken.idToken = LoggedInUserInfo.Instance.userInfo.idToken

                    const refreshToken = token.refreshToken;
                    refreshToken.refreshToken = LoggedInUserInfo.Instance.userInfo.refreshToken;

                    this.oktaAuth.tokenManager.setTokens({accessToken, idToken, refreshToken });
                });
            }
            this.sendTokenRefreshedMessage();
        });
    }

    async processLoadSMSUserDataMessage(f: SystemEventMessage): Promise<void> {
        const azureUserId = f.messageData[DATAKEYS.APPLEVEL.SECURITY.USER_DATA];
        const redirectUrl = f.messageData[DATAKEYS.APPLEVEL.ROUTE.URL];
        await this.loadSmsUserInfo('', '');
        const redirectNavEntry = await NavigationEntry.FromRouteString(redirectUrl);
        await this.navigator.navigateTo(redirectNavEntry);
    }

    async processCreateSMSUserDataMessage(f: SystemEventMessage): Promise<void> {
        const redirectUrl = f.messageData[DATAKEYS.APPLEVEL.ROUTE.URL];
        const inviteToken = f.messageData[DATAKEYS.APPLEVEL.ROUTE.INVITE_TOKEN] || '';
        const materialCode = f.messageData[DATAKEYS.APPLEVEL.ROUTE.MATERIAL_CODE] || '';
        const accountNumber = f.messageData[DATAKEYS.APPLEVEL.ROUTE.ACCOUNT_NUMBER] || '';
        const promoCode = f.messageData[DATAKEYS.APPLEVEL.ROUTE.PROMO_CODE] || '';

        LoggedInUserInfo.Instance.userInfo.accessToken = f.messageData[DATAKEYS.AUTH.ID_TOKEN];

        await this.createNewSMSUserRecord(LoggedInUserInfo.Instance.userInfo, inviteToken, materialCode, accountNumber, promoCode);
        const redirectNavEntry = await NavigationEntry.FromRouteString(redirectUrl);
        await this.navigator.navigateTo(redirectNavEntry);
    }

    async processPasswordResetMessage(f: SystemEventMessage): Promise<void> {
        const redirectUrl = f.messageData[DATAKEYS.APPLEVEL.ROUTE.URL];
        const resetUrl = this.buildResetPasswordEndpoint(redirectUrl);
        window.location.href = resetUrl;
    }

    async processLoginMessage(msg: SystemEventMessage) {
        const redirectUrl = msg.messageData[DATAKEYS.APPLEVEL.ROUTE.URL];
        await this.login(redirectUrl, msg);
    }

    public async processRequestLogoutMessage() {
        await this.logout();
    }

    public async login(redirectUrl = '', msg?: Message): Promise<void> {

        if (LoggedInUserInfo.Instance.isAuthorized) {
            // will need to check to see if the token is still valid first
            // Get The User Data From SMS
            if (!LoggedInUserInfo.Instance.smsUserDataLoaded) {
                await this.processLoadSMSUserDataMessage(msg);
            }
            return;
        }
        window.location.href = await this.buildAuthorizeEndpoint(redirectUrl, msg);
    }

    public async logout(): Promise<void> {
        const isUnifiedSplitOn = (await this.splitioService.getToggle(TOGGLES.OKTA_UNIFIED_LOGIN)) === TREATMENT.ON;
        LoggedInUserInfo.Instance.resetLoggedInUserData();

        // call logout service on Azure
        const redirectUrl = environment.azureEndpoints.logout.redirect_uri;
        let url = environment.azureEndpoints.logout.rootEndpoint + `?p=${environment.azureEndpoints.logout.userFlow}&post_logout_redirect_uri=${redirectUrl}`;
        url = AuthRoutes.Login.urlRoute;

        const idToken = await this.oktaAuth.tokenManager.get('idToken') as any;
        const loginMessage = window.localStorage.getItem('loginAccountErrorMessage');
        window.localStorage.clear();
        window.sessionStorage.clear();
        this.deleteCookies();

        if (loginMessage) {
            window.localStorage.setItem('loginAccountErrorMessage', loginMessage);
        }

        if (isUnifiedSplitOn)
        {
            await this.oktaAuth.signOut({postLogoutRedirectUri: environment.appRootUrl, idToken: idToken});
        } else {
            setTimeout(() => {
                window.location.href = url;
            }, 1000);
        }

        // await this.login();
    }

    // The "expire" attribute of every cookie is
    // Set to the past
    // This will not expire all cookies, but
    // the ones it is possible to expire programattically
    private deleteCookies() {
        const allCookies = document.cookie.split(';');
        for (let i = 0; i < allCookies.length; i++)
            document.cookie = allCookies[i] + "=;expires="
                + new Date(0).toUTCString();
    }

    async loadSmsUserInfo(materialCode: string, promoCode: string, isImpersonation = false): Promise<string> {
        const loadComplete = new Promise<string>((f) => {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.Security)
                .addDataEntry('materialCode', materialCode)
                .addDataEntry('promoCode', promoCode)
                .addSuccessHandler((d) => {
                    LoggedInUserInfo.Instance.userInfo.populateFromSmsDb(d);
                    this.splitioService.initSdk();
                    if (d.isTrialSelfRenewal) {
                        this.navigator.navigateToMessage('Renew');
                    } else {
                        this.sendLoginSuccessMessage(LoggedInUserInfo.Instance.userInfo);
                        // only update login data if this is not an impersonation session
                        if (!isImpersonation) {
                            this.updateLoginData();
                        }
                        f('complete');
                    }
                })
                .addErrorHandler((error) => {
                    let errorMessage = '';
                    if (error && error.errorData) {
                        errorMessage = error.errorData[0].errorMessage || '';
                    }
                    if (LoggedInUserInfo.Instance.userInfo.impersonationId) {
                        LoggedInUserInfo.Instance.userInfo.impersonation = null;
                        LoggedInUserInfo.Instance.userInfo.impersonationId = '';
                        LoggedInUserInfo.Instance.userInfo.userId = LoggedInUserInfo.Instance.userInfo.mainUser.id;
                        LoggedInUserInfo.Instance.userInfo.azureUID = LoggedInUserInfo.Instance.userInfo.mainUser.azureInternalId;
                        this.loadSmsUserInfo(materialCode, promoCode);
                        f(errorMessage);
                    } else {
                        this.splitioService.getToggle(TOGGLES.OKTA_UNIFIED_LOGIN).then(async (value) => {
                            LoggedInUserInfo.Instance.resetLoggedInUserData();
                            this.agGridState.removeAllGridsFromLocalStorage();

                            if (value === TREATMENT.ON) {
                                window.localStorage.setItem('loginAccountErrorMessage', errorMessage);
                                await this.oktaAuth.signOut({
                                    postLogoutRedirectUri: `${environment.appRootUrl}/message`
                                  });
                            }
                            else {
                                this.navigator.navigateToMessage(errorMessage);
                            }
                            throw Error(errorMessage);
                        });
                    }
                }).buildAndSend();
        });
        return loadComplete;
    }

    async createNewSMSUserRecord(userInfo: JJKUserInfo, inviteToken: string, materialCode: string, accountNumber: string, promoCode: string) {
        const loadComplete = new Promise<void>((f) => {
            ApiFactory.saveNewEntity(ApiEntityTypesEnum.Security, userInfo)
                .addDataEntry('azureUserId', userInfo.azureUID)
                .addDataEntry('inviteToken', inviteToken)
                .addDataEntry('materialCode', materialCode)
                .addDataEntry('accountNumber', accountNumber)
                .addDataEntry('promoCode', promoCode)
                .addSuccessHandler((d) => {
                    // TODO: There is a disconnect between field names in Azure and SMS
                    LoggedInUserInfo.Instance.userInfo.populateFromSmsDb(d);
                    LoggedInUserInfo.Instance.userInfo.azureUID = userInfo.azureUID;
                    this.sendLoginSuccessMessage(LoggedInUserInfo.Instance.userInfo);
                    this.updateLoginData();
                    this.splitioService.initSdk();
                    f();
                })
                .addErrorHandler((error) => {
                    let errorMessage = '';
                    if (error && error.errorData) {
                        errorMessage = error.errorData[0].errorMessage || '';
                    }
                    this.navigator.navigateToMessage(errorMessage);
                    throw Error(errorMessage);
                })
                .buildAndSend();
        });
        return loadComplete;
    }

    updateUserProfileData(user: User, profile: UserDataProfile) {
        this.updateUserName(profile);
        LoggedInUserInfo.Instance.userInfo.updateUserDataProfile(user, profile);
    }

    updateLoginData() {
        let userDataProfile: UserDataProfile = null;
        ApiFactory.retrieveEntity(ApiEntityTypesEnum.UserDataProfile)
            .addRouteHint('GetUserDataProfileByUserId')
            .addDataEntry('userId', LoggedInUserInfo.Instance.userInfo.userId)
            .addSuccessHandler((response: UserDataProfile) => {
                userDataProfile = response;

                if (userDataProfile) {
                    ApiFactory.updateEntity(ApiEntityTypesEnum.UserDataProfile, userDataProfile)
                        .addRouteHint('UpdateUserDataProfileOnLogin')
                        .addSuccessHandler(() => { })
                        .addErrorHandler((e) => this.onError(e))
                        .buildAndSend();
                } else {
                    const requestData = {
                        userId: LoggedInUserInfo.Instance.userInfo.userId,
                        loginCount: 1,
                        lastLoginDate: new Date(),
                    };
                    ApiFactory.saveNewEntity(ApiEntityTypesEnum.UserDataProfile, requestData)
                        .addSuccessHandler((udp) => { this.updateUserName(udp); })
                        .addErrorHandler((e) => this.onError(e))
                        .buildAndSend();
                }
            })
            .buildAndSend();

        this.pendoService.Initialize();
    }

    updateUserName(profile: UserDataProfile) {
        const user = LoggedInUserInfo.Instance.userInfo.user;
        user.name = profile.lastName + ', ' + profile.firstName;
        ApiFactory.updateEntity(ApiEntityTypesEnum.User, user)
            .addRouteHint('UserUpdateName')
            .addSuccessHandler(() => { })
            .buildAndSend();
    }

    onError(response: any) { }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() { }

    sendTokenRefreshedMessage() {
        this.sendEventMessage(ACTIONS.AUTH.AUTH_TOKEN_WAS_REFRESHED, LoggedInUserInfo.Instance);
    }

    sendRefreshingTokenMessage(user: JJKUserInfo = null) {
        this.sendEventMessage(ACTIONS.AUTH.SESSION_REFRESH, user);
    }

    sendLoginSuccessMessage(user: JJKUserInfo) {
        this.sendEventMessage(ACTIONS.AUTH.AUTH_LOGIN_SUCCESS, user);
    }

    sendLoginFailedMessage(user: any = null) {
        this.sendEventMessage(ACTIONS.AUTH.AUTH_LOGIN_FAILED, user);
    }

    sendUserInfoUpdated(user: JJKUserInfo) {
        this.sendEventMessage(ACTIONS.AUTH.AUTH_LOGIN_SUCCESS, user);
    }

    sendEventMessage(action: string, user: JJKUserInfo | LoggedInUserInfo) {
        const msg = this.msgBuilder
            .SystemEventMessage()
            .usingHostSenderId()
            .then.addData(DATAKEYS.APPLEVEL.SECURITY.USER_DATA, user)
            .and.assignAction(action)
            .sendOn.systemTopic()
            .build();

        this.sendTopicMessage(msg);
    }

    // If the user is logged in, they will be redirected to the dashboard, or logged out by the sessionService if their session has expired.
    redirectLoggedInUser() {
        if (LoggedInUserInfo.Instance.userInfo.accessToken) {
            this.sessionService.checkSessionExpiration();
            this.router.navigate(['/dashboard']);
        }
    }

    async getNonAuthToken(authCode: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.Security)
                .addDataEntry('authCode', authCode)
                .addRouteHint('GetAuthToken')
                .addSuccessHandler((d: NonAuthResponse) => {
                    LoggedInUserInfo.Instance.userInfo.populateFromSmsDb(d.userProfile);
                    LoggedInUserInfo.Instance.userInfo.accessToken = d.authToken;
                    LoggedInUserInfo.Instance.userInfo.isNonAuth = true;
                    this.splitioService.initSdk();
                    resolve();
                })
                .addErrorHandler((e) => {
                    reject(e.errorData[0].errorMessage);
                }).buildAndSend();
        });
    }

    async verifyOktaUser(credentials: any, targetUrl: string): Promise<string> {
        const verifyUser = new Promise<string>((errorMessage) => {
            ApiFactory.saveNewEntity(ApiEntityTypesEnum.Security, credentials)
                .addRouteHint('ValidateIdentity')
                .addSuccessHandler((x) => {
                    this.loginUser(x, targetUrl);
                    errorMessage('');
                })
                .addErrorHandler(async (e) => {
                    if (e.errorData[0].errorMessage == AuthErrorMessage.PasswordExpired) {
                        const navigationExtras: NavigationExtras = { state: { credentials: credentials } };
                        this.router.navigate([`/${AuthRoutes.ResetPassword.urlRoute}`], navigationExtras);
                    }
                    else {
                        errorMessage(e.errorData[0].errorMessage);
                    }
                })
                .skipClientSideCache()
                .buildAndSend();
        });
        return verifyUser;
    }

    async loginUser(credentials, targetUrl) {
        targetUrl = targetUrl ?? window.localStorage.getItem('desiredPageUrl') ?? '/dashboard'
        const msg = this.msgBuilder.SystemEventMessage()
            .usingHostSenderId().then
            .addData(DATAKEYS.APPLEVEL.SECURITY.USER_CREDENTIALS, credentials)
            .addData(DATAKEYS.GENERAL.DATA, targetUrl)
            .assignAction(ACTIONS.AUTH.AUTH_REQUEST_OKTA_LOGIN).sendOn.systemTopic().build();
        this.processNewAuthTokenRequestMessage(msg);
    }

    requestLogin(credentials: any) {
        const msg = this.msgBuilder.SystemEventMessage()
            .usingHostSenderId()
            .then
            .addData(DATAKEYS.APPLEVEL.SECURITY.FORCE_REDIRECT, true)
            .addData(DATAKEYS.APPLEVEL.SECURITY.USER_CREDENTIALS, credentials)
            .addData(DATAKEYS.APPLEVEL.ROUTE.URL, window.localStorage.getItem('desiredPageUrl') ?? '/dashboard')
            .assignAction(ACTIONS.AUTH.AUTH_REQUEST_LOGIN)
            .sendOn.systemTopic().build();
        this.processLoginMessage(msg);
    }

    async getPasswordRules(): Promise<PasswordRequirements> {
        return new Promise<PasswordRequirements>((resolve, reject) => {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.Security)
                .addRouteHint("PasswordRules")
                .addSuccessHandler((response) => {
                    resolve(response);
                })
                .addErrorHandler((response) => {
                    reject(response.errorData[0].errorMessage);
                })
                .removePaging()
                .buildAndSend();
        });
    }

    async changePassword(changePassword: ChangePassword): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiFactory.saveNewEntity(ApiEntityTypesEnum.Security, changePassword)
                .addRouteHint("ChangePassword")
                .addSuccessHandler(() => {
                    resolve();
                })
                .addErrorHandler((response) => {
                    reject(response.errorData[0].errorMessage);
                })
                .removePaging()
                .buildAndSend();
        });
    }

    async requestResetForgottenPasswordEmail(emailAddress: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiFactory.saveNewEntity(ApiEntityTypesEnum.Security, emailAddress)
                .addRouteHint("ResetForgottenPasswordEmail")
                .addSuccessHandler(() => {
                    resolve();
                })
                .addErrorHandler((response) => {
                    if (response.httpStatusCode == 401) {
                        reject(AuthErrorMessage.SessionExpired);
                    }
                    else {
                        reject(response.errorData[0].errorMessage);
                    }
                })
                .removePaging()
                .buildAndSend();
        });
    }
    async resetPasswordEmail(emailAddress: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiFactory.saveNewEntity(ApiEntityTypesEnum.Security, emailAddress)
                .addRouteHint("ResetPassword")
                .addSuccessHandler(() => {
                    resolve();
                })
                .addErrorHandler((response) => {
                    if (response.httpStatusCode == 401) {
                        reject(AuthErrorMessage.SessionExpired);
                    }
                    else {
                        reject(response.errorData[0].errorMessage);
                    }
                })
                .removePaging()
                .buildAndSend();
        });
    }

    async resetPassword(resetPassword: ChangePassword): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiFactory.updateEntity(ApiEntityTypesEnum.Security, resetPassword)
                .addRouteHint("ResetPassword")
                .addSuccessHandler(() => {
                    resolve();
                })
                .addErrorHandler((response) => {
                    if (response.httpStatusCode == 401) {
                        reject(AuthErrorMessage.SessionExpired);
                    }
                    else {
                        reject(response.errorData[0].errorMessage);
                    }
                })
                .removePaging()
                .buildAndSend();
        });
    }

    async requestResetPasswordEmail(emailAddress: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiFactory.saveNewEntity(ApiEntityTypesEnum.Security, emailAddress)
                .addRouteHint("RequestResetPassword")
                .addSuccessHandler(() => {
                    resolve();
                })
                .addErrorHandler((response) => {
                    if (response.httpStatusCode == 401) {
                        reject(AuthErrorMessage.SessionExpired);
                    }
                    else {
                        reject(response.errorData[0].errorMessage);
                    }
                })
                .removePaging()
                .buildAndSend();
        });
    }

    async requestResetPassword(resetPassword: ChangePassword): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiFactory.updateEntity(ApiEntityTypesEnum.Security, resetPassword)
                .addRouteHint("RequestResetPassword")
                .addSuccessHandler(() => {
                    resolve();
                })
                .addErrorHandler((response) => {
                    if (response.httpStatusCode == 401) {
                        reject(AuthErrorMessage.SessionExpired);
                    }
                    else {
                        reject(response.errorData[0].errorMessage);
                    }
                })
                .removePaging()
                .buildAndSend();
        });
    }

    public requestOktaSignup() {
        const msg = this.msgBuilder.SystemEventMessage()
            .usingHostSenderId().then.proceedWithoutData
            .assignAction(ACTIONS.AUTH.AUTH_REQUEST_OKTA_SIGNUP).sendOn.systemTopic().build();
        this.sendTopicMessage(msg);
    }

    createIdentity(credentials: any, inviteToken: string, materialCode: string, accountNumber: string, promoCode: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiFactory.saveNewEntity(ApiEntityTypesEnum.Security, credentials)
                .addRouteHint("Identity")
                .addDataEntry("credentials", credentials)
                .addDataEntry('inviteToken', inviteToken)
                .addDataEntry('materialCode', materialCode)
                .addDataEntry('accountNumber', accountNumber)
                .addDataEntry('promoCode', promoCode)
                .addSuccessHandler(() => {
                    resolve();
                })
                .addErrorHandler((response) => {
                    reject(response.errorData[0].errorMessage);
                })
                .removePaging()
                .buildAndSend();
        });
    }

    async getRecoveryQuestionByEmail(email: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            ApiFactory.saveNewEntity(ApiEntityTypesEnum.Security, email)
                .addRouteHint("GetRecoveryQuestion")
                .addSuccessHandler((question) => {
                    resolve(question);
                })
                .addErrorHandler((response) => {
                    reject(response.errorData[0].errorMessage);
                })
                .removePaging()
                .buildAndSend();
        });
    }

    public shouldWarnPasswordExpire(): boolean {
        try {
            if (LoggedInUserInfo.Instance.userInfo.passwordExpiresInDays <= WARNINGDAYS
                && LoggedInUserInfo.Instance.userInfo.passwordExpiresInDays != LoggedInUserInfo.Instance.userInfo.promptedDaysToExpire) {
                return true;
            }
            return false;
        }
        catch (e) {
            return false;
        }
    }

    getMigrationStatus() {
        const apiFactory = ApiFactory.retrieveEntity(ApiEntityTypesEnum.OktaUserMigration);
        const userIdFilter = FilterExpressionBuilder.For(ApiEntityTypesEnum.OktaUserMigration)
            .Use('UserId', ParameterTypeEnum.String)
            .Equal(LoggedInUserInfo.Instance.userInfo.userId)
            .Build().AsExpression;
        apiFactory.addFilterEntries(userIdFilter);

        apiFactory.addSuccessHandler(async (data) => {
            if (data[0] === undefined || data[0].completedDate !== null) {
                this.migrationStatusEmitter.emit(false);
            } else if (data[0].completedDate === null) {
                this.migrationStatusEmitter.emit(true);
            }
        })
            .addErrorHandler((e) => {
                this.migrationStatusEmitter.emit(false);
            })
            .removePaging()
            .buildAndSend();
    }

    public checkIfOktaUser(emailAddress: string, skipSmsAccountCheck = false): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.Security)
                .addRouteHint("UserOktaInfo")
                .addDataEntry("emailAddress", encodeURIComponent(emailAddress))
                .addDataEntry("skipSmsAccountCheck", skipSmsAccountCheck)
                .addSuccessHandler((response) => {
                    resolve(response);
                })
                .addErrorHandler((response) => {
                    reject(response.errorData[0].errorMessage);
                })
                .buildAndSend();
        });
    }

    public shouldShowOktaFirstLogin() {
        try {
            if (LoggedInUserInfo.Instance.userInfo.oktaUID && !LoggedInUserInfo.Instance.userInfo.loginCount && !LoggedInUserInfo.Instance.userInfo.user.email.toLowerCase().endsWith('@jjkeller.com')) {
                return true;
            }
            return false;
        }
        catch (e) {
            return false;
        }
    }

    public getUserByEmail(email: string): Promise<any> {
        return new Promise<any>((resolve) => {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.Security)
                .addDataEntry('email', email)
                .addRouteHint('GetUserByEmail')
                .addSuccessHandler((response) => resolve(response))
                .addErrorHandler((response) => resolve(response))
                .buildAndSend();
        });
    }
}
