import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControlOptions, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthErrorMessage } from '@constants/auth/auth-error-message.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { GENERAL_MESSAGES } from '@constants/messages.constants';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { PasswordRuleViewModel } from '@models/auth/rule.model';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { ConvertApiErrorToAlertMessage, GetPasswordRulesAndValidation } from '@pages/auth/helpers';
import { AuthService } from '@services/auth.service';
import { ApiFactory } from '@services/core/api-factory.class';
import { LoggerService } from '@services/core/logger-service.class';
import { isNullOrEmpty } from '@utilities/helpers';
import { PasswordValidators } from '@utilities/validators/password-validators';

@Component({
  selector: 'auth-migration-form',
  styleUrls: ['./auth-migration-form.component.scss'],
  templateUrl: './auth-migration-form.component.html',
})

export class AuthMigrationFormComponent extends ValidatableFormBaseDirective implements OnInit {
  @Input() showPasswordRuleErrorText = false;
  @Output() loadingFailure = new EventEmitter<string>();
  @Input() rules: PasswordRuleViewModel[];
  selectedA: any;
  selectedQ = '';
  waitingFormSubmit: boolean;
  isLoading: boolean;
  isExistingInOkta: boolean;
  showMigrationError = false;
  showMigrationSubmissionErrorAlert: boolean;
  alertText = `* There was an error updating your security credentials. Please reach out to our
  customer care
  team for assistance.`;
  serverError: boolean;
  showErrorText: boolean;
  loadingFormMessage = GENERAL_MESSAGES.LOADING_FORM_MESSAGE;

  ruleValidators: ValidatorFn[];
  rulesAreLoaded: boolean;
  migrated: boolean;
  public static needsMigration: boolean;
  nonSMSproducts: any;
  hasOtherApplications = false;
    processingErrors: boolean;
  get rulesViewValidationErrors(): ValidationErrors {
    return this.form ? this.getControl('newPassword').errors : null;
  }
  get showRuleErrorText(): boolean {
      return this.form ? this.shouldShowErrors('newPassword') : false;
  }

  constructor(private authService: AuthService, private formBuilder: UntypedFormBuilder) {
    super();
  }

  async ngOnInit() {
    this.serverError = false;
  }

  async onConfirmSetUp() {
    this.isLoading = true;
    await this.authService.checkIfOktaUser(LoggedInUserInfo.Instance.userInfo.user.email)
            .then((data) => {
                 this.isExistingInOkta = !isNullOrEmpty(data.id);
                 this.nonSMSproducts = data.products.filter(p => p !== 'Safety Management Suite' && p !== 'Safety Management Suite RO' && p !== 'SafetyManagementSuite.APIM');
                 this.hasOtherApplications = !(data.products.filter(p => p !== 'Safety Management Suite' && p !== 'Safety Management Suite RO' && p !== 'SafetyManagementSuite.APIM').length === 0);

                 if (!this.isExistingInOkta) {
                    this.getPasswordRules();
                }
            })
            .catch((errorMessage) => {
                this.processingErrors = true;
                LoggerService.error(errorMessage);
            })
            .finally(() => {
                ($('#authMigrationInfo') as any).modal('hide');
                $('html').css({'overflow':'hidden'});
                ($('#authMigrationForm') as any).modal('show');
                this.isLoading = false;
            });
    }

  async getPasswordRules() {
    if (!this.rules) {
      await this.authService.getPasswordRules()
        .then(data => { this.rules = GetPasswordRulesAndValidation(data, LoggedInUserInfo.Instance.userInfo.user.email) })
        .catch((errorMessage) => this.loadingFailure.emit(errorMessage));
    }
    this.ruleValidators = this.rules.filter(r => r.validator).map(r => r.validator);
    this.buildForm();
  }

  buildForm() {
    this.ruleValidators.push(Validators.required);
    this.form = this.formBuilder.group({
      newPassword: [null, this.ruleValidators],
      passwordConfirm: [null, Validators.required],
    }, {
      validators: [PasswordValidators.PasswordMatchValidator('newPassword', 'passwordConfirm')],
    } as AbstractControlOptions);
  }

  protected async submitForm() {
if (!this.form.valid) {
      this.showPasswordRuleErrorText = true;
      this.showErrorText = true;
      return;
    }
    const user = {
      emailAddress: LoggedInUserInfo.Instance.userInfo.user.email,
      password: this.getControl('newPassword').value,
      firstName: LoggedInUserInfo.Instance.userInfo.userFirstName,
      lastName: LoggedInUserInfo.Instance.userInfo.userLastName,
    };
    await this.migrateToOkta(user);
  }

  async migrateToOkta(user: any) {
    this.waitingFormSubmit = true;
    return ApiFactory.saveNewEntity(ApiEntityTypesEnum.Security, user)
      .addRouteHint('Migrate')
      .addSuccessHandler((x) => {
        this.waitingFormSubmit = false;
        this.showMigrationError = false;
        ($('#authMigrationForm') as any).modal('hide');
        $('html').css({ 'overflow': 'visible' });
        ($('#passwordExpireModal') as any).removeClass('auth-migration-form-modal-active');
      })
      .addErrorHandler((e) => {
          this.serverError = true;
          LoggerService.trace(`Migration error: ${e}`);
          this.waitingFormSubmit = false;
          this.showMigrationSubmissionErrorAlert = true;
          this.showMigrationError = true;
        }
      )
      .buildAndSend();
  }

  closeForm() {
    ($('#authMigrationForm') as any).modal('hide');
    $('html').css({ 'overflow': 'visible' });
    ($('#passwordExpireModal') as any).removeClass('auth-migration-form-modal-active');
  }

  setAlert(apiErrorMessage: AuthErrorMessage) {
    this.alertText = ConvertApiErrorToAlertMessage(apiErrorMessage);
     this.showMigrationSubmissionErrorAlert = true;
  }
}
