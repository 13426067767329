export enum AgGridPredefinedState {
  NewChemicalSDS = "NewChemicalSDS",
  InfoRequiredSDS = "InfoRequiredSDS",
  OpenIncidents = "OpenIncidents",
  OpenOshaRecordableIncidents = 'OpenOshaRecordableIncidents',
  OpenIncidentTasks = 'OpenIncidentTasks',
  OpenTasks = "OpenTasks",
  PastDueTrainingEvents = 'PastDueTrainingEvents',
  PastDueAudits = "PastDueAudits",
  OpenAuditTasks = "OpenAuditTasks",
  MissingEmployeeData = "MissingEmployeeData",
  TrainingAsset = "TrainingAsset" 
} 
