import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'jjk-notification-card',
  template: '',
})
export class NotificationCardComponent {

  constructor() {
  }

  @Input() title: string;
  @Input() counter: number;
  @Input() routerLink: string;
  @Input() queryParams: string;
  @Input() state: any;
  @Input() authFeature: any;
  @Input() isLoading: boolean;
  @Input() featureToggleName: string;
  @Output() onClick = new EventEmitter<string>();
  @Input() cardClass: string;
  @Input() countClass: string;
  @Input() subtitle: string;
  @Input() subtitleCounter: number;
  @Input() showSubtitle: boolean;

}
