import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss'],
})
export class AuthHeaderComponent {

  customerServiceNumber = environment.customerServiceNumber;
  currentYear = (new Date()).getFullYear();
  pimcoreTermsOfUseUrl = environment.pimcoreTermsOfUseUrl;
  isNonAuth = false;
  constructor() {
  }
}
