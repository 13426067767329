import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldCompletion } from '@models/common/field-completion.model';
import { enumNameFromValue } from '@utilities/helpers';

@Component({
  selector: 'accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
})
export class AccordionItemComponent implements OnInit  {

  private isExpaned = false;
  private notificationType: string;
  private _accordionNotificationType: AccordiontNotificationType;
  @Input() title = '';
  @Input() mobileTitle = '';
  @Input() description = '';
  @Input() unableToSourceMessage = '';
  @Input() toggleId = '';
  @Input() fieldCompletion: FieldCompletion<any>;
  @Input() addRouterLink: string | any[];
  @Input() editRouterLink: string | any[];
  @Input() additionalRouterLink: string | any[];
  @Input() additionalRouterLinkText: string;
  @Input() additionalRouterLink1: string | any[];
  @Input() additionalRouterLinkText1: string;
  @Input() additionalRouterLink2: string | any[];
  @Input() additionalRouterLinkText2: string;
  @Input() labelDisplayText: string;
  @Input() descriptionLinkText: string;
  @Input() labelColor: string;
  @Input() additionalText: string;
  @Input() authFeature = '';
  @Input() addtionalLinkAuthFeature = '';
  @Input() authEntity;
  @Input() hideEditRouterLink = false;
  @Input() editWording = 'Edit';
  @Input() accordionNotificationText: string;
  @Input() beginExpand = false;
  @Input()
  public get accordionNotificationType(): AccordiontNotificationType {
    return this._accordionNotificationType;
  }
  public set accordionNotificationType(value: AccordiontNotificationType) {
    this._accordionNotificationType = value;
    this.notificationType = enumNameFromValue(AccordiontNotificationType, this.accordionNotificationType).toLowerCase();
  }
  @Output() isExpanded = new EventEmitter();
  @Output() labelClick = new EventEmitter();
  @Output() descriptionLinkClick = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    if(this.beginExpand){
      setTimeout(() => document.getElementById('expand' + this.toggleId)?.click(), 100);
    }
  }

  onClick() {
    this.isExpaned = !this.isExpaned;
    if (this.isExpaned) {
      this.isExpanded.emit(null);
    }
  }

  onLabelClick() {
    this.labelClick.emit();
  }

  onDescriptionLinkClick(event) {
    this.descriptionLinkClick.emit();
    event.stopPropagation();
  }
}

export enum AccordiontNotificationType {
    Success,
    Info,
    Warning,
    Danger,
}
