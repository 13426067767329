import { Component, OnInit } from '@angular/core';
import * as MD5 from 'md5';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { UserTypes } from '@constants/enums/user-type.enum';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { AutoIncrementingIdentifierDirective } from '@modules/messaging/baseClasses/AutoIncrementingIdentifier';
import { ApiFactory } from '@services/core/api-factory.class';
import { EmailAddressValidator } from '@utilities/validators/email-address.validator';

@Component({
  selector: 'layout-profile-menu',
  templateUrl: './layout-profile-menu.component.html',
  styleUrls: ['./layout-profile-menu.component.scss'],
})
export class LayoutProfileMenuComponent extends AutoIncrementingIdentifierDirective implements OnInit {

  hasAvatar: boolean;
  avatar: string;
  isSapAdmin = LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.SAPAdmin;

  get userName() {
    if (LoggedInUserInfo.Instance.userInfo.userDataProfile
      && LoggedInUserInfo.Instance.userInfo.userDataProfile.firstName
      && LoggedInUserInfo.Instance.userInfo.userDataProfile.lastName) {
      return LoggedInUserInfo.Instance.userInfo.userDataProfile.firstName + ' ' + LoggedInUserInfo.Instance.userInfo.userDataProfile.lastName;
    } else {
      return LoggedInUserInfo.Instance.userInfo.user?.email;
    }
  }

  get userAvatar() {
    if (LoggedInUserInfo.Instance.userInfo.userDataProfile
      && LoggedInUserInfo.Instance.userInfo.userDataProfile.firstName
      && LoggedInUserInfo.Instance.userInfo.userDataProfile.lastName) {
      return LoggedInUserInfo.Instance.userInfo.userDataProfile.firstName.charAt(0) + LoggedInUserInfo.Instance.userInfo.userDataProfile.lastName.charAt(0);
    }
  }

  constructor() {
    super();
  }

  ngOnInit() {
    this.getAvatar(LoggedInUserInfo.Instance.userInfo.user.email);
  }

  public getAvatar(emailAddress: any) {
    this.hasAvatar = false;
    const emailValidator = new EmailAddressValidator();
    if (emailAddress && emailValidator.validateEmailAddress(emailAddress)) {
      ApiFactory.retrieveEntity(ApiEntityTypesEnum.Avatar)
        .addDataEntry('email', MD5(emailAddress))
        .addRouteHint('GetAvatar')
        .addSuccessHandler((response) => {
          if (response) {
            this.hasAvatar = true;
            this.avatar = response;
          }
        })
        .removePaging()
        .buildAndSend();
    }
    this.setAvatar();
  }

  private setAvatar() {
    if (!this.hasAvatar) {
        if (LoggedInUserInfo.Instance.userInfo.userDataProfile
            && LoggedInUserInfo.Instance.userInfo.userDataProfile.firstName
            && LoggedInUserInfo.Instance.userInfo.userDataProfile.lastName) {
            this.avatar = LoggedInUserInfo.Instance.userInfo.userDataProfile.firstName.charAt(0) + LoggedInUserInfo.Instance.userInfo.userDataProfile.lastName.charAt(0);
        }
    }
  }
}
