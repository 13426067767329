import { Guid } from 'guid-typescript';
import { DefaultIds } from '@constants/defaultIds';
import { GroupPermission } from '@constants/enums/group_permission.enum';
import { RolesEnum } from '@constants/enums/roles.enum';
import { UserTypes } from '@constants/enums/user-type.enum';
import { Company } from '@models/entity-models/autogenerated/company';
import { Features } from '@models/entity-models/autogenerated/features';
import { FeaturesPerCompany } from '@models/entity-models/autogenerated/featurespercompany';
import { FeaturesPerSubscription } from '@models/entity-models/autogenerated/featurespersubscription';
import { Groups } from '@models/entity-models/autogenerated/groups';
import { GroupsRolePerUserOrGroup } from '@models/entity-models/autogenerated/groupsroleperuserorgroup';
import { Impersonations } from '@models/entity-models/autogenerated/impersonations';
import { Permissions } from '@models/entity-models/autogenerated/permissions';
import { PermissionsPerRole } from '@models/entity-models/autogenerated/permissionsperrole';
import { Roles } from '@models/entity-models/autogenerated/roles';
import { Subscription } from '@models/entity-models/autogenerated/subscription';
import { SubscriptionType } from '@models/entity-models/autogenerated/subscriptiontype';
import { User } from '@models/entity-models/autogenerated/user';
import { UserDataProfile } from '@models/entity-models/autogenerated/userdataprofile';
import { UserGroups } from '@models/entity-models/autogenerated/usergroups';
import { FeatureShow } from '@models/feature-show';
import { GroupAndEnabled } from '@models/group-enabled.model';
import { isNullOrEmpty, isNullOrUndefined } from '@utilities/helpers';
import { FeaturesPerUserOrGroup } from '../entity-models/autogenerated/featuresperuserorgroup';
import { CalculatedPermission } from './CalculatedPermission';
import { UserProfile } from './UserProfile';

export class JJKUserInfo {
    public static emptyGuid = Guid.EMPTY;
    private _jjkCompanyId = DefaultIds.JjkCompany;
    private _jjkUserId = DefaultIds.JjkUser;

    private _idToken: string;
    private _refreshToken: string;
    private _accessToken: string;
    private _passwordExpiresInDays: number;
    private _promptedDaysToExpire: number;
    private _lastRefreshedDateTime;
    private _iss: string;

    private _azureUID = JJKUserInfo.emptyGuid;
    private _oktaUID: string;
    private _companyId = JJKUserInfo.emptyGuid;
    private _userId = JJKUserInfo.emptyGuid;
    private _sharedGroupId = JJKUserInfo.emptyGuid;
    private _companyGroupId = JJKUserInfo.emptyGuid;
    private _noAccessGroupId = JJKUserInfo.emptyGuid;
    private _rootGroupId = JJKUserInfo.emptyGuid;
    private _impersonationId = '';
    private _userFirstName = '';
    private _userLastName = '';
    private _loginCount = null;

    private _company: Company;
    private _user: User;
    private _userDataProfile: UserDataProfile;
    private _groupRole: GroupsRolePerUserOrGroup[];
    private _mainUser: User;
    private _subscription: Subscription;
    private _subscriptionType: SubscriptionType;
    private _logoUrl: string;
    private _allowNoAccessControlGroup: boolean;
    private _impersonation: Impersonations[];
    private _roles: Roles[];
    private _groups: Groups[];
    private _userGroups: UserGroups[];
    private _features: FeatureShow[];
    private _subscriptionFeature: Features[];
    private _permissions: Permissions[];
    private _permissionsPerRole: PermissionsPerRole[];
    private _calculatedPermissions: CalculatedPermission[];
    private _sharedGroup: Groups;
    private _companyGroup: Groups;
    private _noAccessGroup: Groups;
    private _rootGroup: Groups;
    private _isNonAuth = false;
    private _errorMessage = '';
    private _isTrialSelfRenewal = false;
    private _selfRenewalMaterialCode = '';
    private _selfRenewalPromoCode = '';
    private _acceptedTermsOfUse = true;
    private _migrated: boolean;
    private _userKey: string;

    private notifyPropertyChangedHandler: () => void;
    private _tokenDecoderFn: (string: any) => void;
    private _ignorePropertyChangedNotification = false;
    public disableUpdateNotifications = () => this._ignorePropertyChangedNotification = true;
    public enableUpdateNotifications = () => this._ignorePropertyChangedNotification = false;
    public registerNewAuthTokenDecoder(tokenDecoderFn: (string: any) => void) {
        this._tokenDecoderFn = tokenDecoderFn;
    }

    public get isInitialized() {
        const initialized =
            (this._azureUID !== JJKUserInfo.emptyGuid || !isNullOrEmpty(this._oktaUID)) &&
            this._companyId !== JJKUserInfo.emptyGuid &&
            this._userId !== JJKUserInfo.emptyGuid;
        return initialized || this._isNonAuth;
    }

    public get userFirstName() {
        return this._userFirstName;
    }
    public set userFirstName(value) {
        this._userFirstName = value;
        this.notifyPropertyChanged();
    }
    public get userLastName() {
        return this._userLastName;
    }
    public set userLastName(value) {
        this._userLastName = value;
        this.notifyPropertyChanged();
    }
    public get jjkCompanyId(): string {
        return this._jjkCompanyId;
    }
    public set jjkCompanyId(value: string) {
        this._jjkCompanyId = value;
        this.notifyPropertyChanged();
    }
    public get companyId(): string {
        return this._companyId;
    }
    public set companyId(value: string) {
        this._companyId = value;
        this.notifyPropertyChanged();
    }
    public get userId(): string {
        return this._userId;
    }
    public set userId(value: string) {
        this._userId = value;
        this.notifyPropertyChanged();
    }
    public get sharedGroupId(): string {
        return this._sharedGroupId;
    }
    public set sharedGroupId(value: string) {
        this._sharedGroupId = value;
        this.notifyPropertyChanged();
    }
    public get rootGroupId(): string {
        return this._rootGroupId;
    }
    public set rootGroupId(value: string) {
        this._rootGroupId = value;
        this.notifyPropertyChanged();
    }
    public get companyGroupId(): string {
        return this._companyGroupId;
    }
    public set companyGroupId(value: string) {
        this._companyGroupId = value;
        this.notifyPropertyChanged();
    }
    public get noAccessGroupId(): string {
        return this._noAccessGroupId;
    }
    public set noAccessGroupId(value: string) {
        this._noAccessGroupId = value;
        this.notifyPropertyChanged();
    }
    public get azureUID() {
        return this._azureUID;
    }
    public set azureUID(value) {
        this._azureUID = value;
        this.notifyPropertyChanged();
    }
    public get oktaUID() {
        return this._oktaUID;
    }
    public set oktaUID(value) {
        this._oktaUID = value;
        this.notifyPropertyChanged();
    }
    public get iss(): string {
        return this._iss;
    }
    public set iss(value: string) {
        this._iss = value;
        this.notifyPropertyChanged();
    }
    public get idToken(): string {
        return this._idToken;
    }
    public set idToken(value: string) {
        this._idToken = value;
        this.notifyPropertyChanged();
    }
    public get refreshToken(): string {
        return this._refreshToken;
    }
    public set refreshToken(value: string) {
        this._refreshToken = value;
        this.notifyPropertyChanged();
    }
    public get accessToken(): string {
        return this._accessToken;
    }
    public set accessToken(value: string) {
        this._accessToken = value;
        this._tokenDecoderFn(value);
        this.notifyPropertyChanged();
    }
    public get passwordExpiresInDays(): number {
        return this._passwordExpiresInDays;
    }
    public set passwordExpiresInDays(value: number) {
        this._passwordExpiresInDays = value;
        this.notifyPropertyChanged();
    }
    public get promptedDaysToExpire(): number {
        return this._promptedDaysToExpire;
    }
    public set promptedDaysToExpire(value: number) {
        this._promptedDaysToExpire = value;
        this.notifyPropertyChanged();
    }
    public get impersonationId() {
        return this._impersonationId;
    }
    public set impersonationId(value) {
        this._impersonationId = value;
        this.notifyPropertyChanged();
    }
    public get impersonation() {
        return this._impersonation;
    }
    public set impersonation(value) {
        this._impersonation = value;
        this.notifyPropertyChanged();
    }
    public get mainUser(): User {
        return this._mainUser;
    }
    public set mainUser(value) {
        this._mainUser = value;
        this.notifyPropertyChanged();
    }
    public get fullName(): string { return `${this.userLastName}, ${this.userFirstName}`; }

    public get lastRefreshedDateTime() {
        const currentDateTime = new Date().toISOString();
        return this._lastRefreshedDateTime || currentDateTime;
    }
    public set lastRefreshedDateTime(value) {
        this._lastRefreshedDateTime = value;
        this.notifyPropertyChanged();
    }
    public registerPropertyChangedHandler(handler: () => void) {
        this.notifyPropertyChangedHandler = handler;
    }
    public get isNonAuth(): boolean {
        return this._isNonAuth;
    }
    public set isNonAuth(value: boolean) {
        this._isNonAuth = value;
        this.notifyPropertyChanged();
    }
    public get errorMessage(): string {
        return this._errorMessage;
    }
    public set errorMessage(value: string) {
        this._errorMessage = value;
        this.notifyPropertyChanged();
    }
    public get isTrialSelfRenewal(): boolean {
        return this._isTrialSelfRenewal;
    }
    public set isTrialSelfRenewal(value: boolean) {
        this._isTrialSelfRenewal = value;
        this.notifyPropertyChanged();
    }
    public get selfRenewalMaterialCode(): string {
        return this._selfRenewalMaterialCode;
    }
    public set selfRenewalMaterialCode(value: string) {
        this._selfRenewalMaterialCode = value;
        this.notifyPropertyChanged();
    }
    public get selfRenewalPromoCode(): string {
        return this._selfRenewalPromoCode;
    }
    public set selfRenewalPromoCode(value: string) {
        this._selfRenewalPromoCode = value;
        this.notifyPropertyChanged();
    }
    public get acceptedTermsOfUse(): boolean {
        return this._acceptedTermsOfUse;
    }
    public set acceptedTermsOfUse(value: boolean) {
        this._acceptedTermsOfUse = value;
        this.notifyPropertyChanged();
    }
    public get loginCount(): number {
        return this._loginCount;
    }
    public set loginCount(value: number) {
        this._loginCount = value;
        this.notifyPropertyChanged();
    }
    public get migrated(): boolean {
        return this._migrated;
    }
    public set migrated(value: boolean) {
        this._migrated = value;
        this.notifyPropertyChanged();
    }
    private notifyPropertyChanged() {
        if (this._ignorePropertyChangedNotification) { return; }
        if (this.notifyPropertyChangedHandler) {
            this.notifyPropertyChangedHandler();
        }
    }
    public get userKey(): string {
        return this._userKey;
    }
    public set userKey(value: string) {
        this._userKey = value;
    }

    populateFromSmsDb(userProfile: UserProfile) {
        this._ignorePropertyChangedNotification = true;
        this._lastRefreshedDateTime = new Date().toISOString();
        this.azureUID = userProfile.user.azureInternalId;
        this.oktaUID = userProfile.user.oktaId;
        this.userId = userProfile.user.id;
        this.companyId = userProfile.user.companyId;
        this._user = userProfile.user;
        this._userDataProfile = userProfile.userDataProfile;
        this._company = userProfile.company;
        this._userFirstName = userProfile.userDataProfile && userProfile.userDataProfile.firstName;
        this._userLastName = userProfile.userDataProfile && userProfile.userDataProfile.lastName;
        this._loginCount = userProfile.userDataProfile && userProfile.userDataProfile.loginCount;
        this._subscription = userProfile.subscription;
        this._subscriptionType = userProfile.subscriptionType;
        this._logoUrl = userProfile.logoUrl;
        this._allowNoAccessControlGroup = userProfile.allowNoAccessControlGroup;
        this._groups = userProfile.groups;
        this._userGroups = userProfile.userGroups;
        this._roles = userProfile.roles;
        this._groupRole = userProfile.groupRoles;
        this._permissions = userProfile.permissions;
        this._permissionsPerRole = userProfile.permissionsPerRole;
        this._calculatedPermissions = userProfile.calculatedPermissions;

        this.loadImpersonation(userProfile.impersonations);
        this.loadFeatures(userProfile.features, userProfile.featuresPerSubscription, userProfile.featuresPerCompany, userProfile.featuresPerUserOrGroup);

        this._sharedGroup = this._groups.find((g) => g.name === 'Shared');
        this._sharedGroupId = this._sharedGroup.id;
        this._companyGroup = this._groups.find((g) => g.name === 'Company');
        this._companyGroupId = this._companyGroup.id;
        this._noAccessGroup = this._groups.find((g) => g.name === 'No Access Control');
        this._noAccessGroupId = this._noAccessGroup.id;
        this._rootGroup = this.groups.find((g) => g.name === 'Root');
        this._rootGroupId = this._rootGroup.id;

        this._isTrialSelfRenewal = userProfile.isTrialSelfRenewal;
        this._selfRenewalMaterialCode = userProfile.selfRenewalMaterialCode;
        this._selfRenewalPromoCode = userProfile.selfRenewalPromoCode;
        this._acceptedTermsOfUse = userProfile.acceptedTermsOfUse;
        this._userKey = userProfile.userKey;

        this._ignorePropertyChangedNotification = false;
        this.notifyPropertyChangedHandler();
    }

    updateUserDataProfile(user: User, profile: UserDataProfile) {
        this._user = user;
        this._userDataProfile = profile;
        this._userFirstName = profile.firstName;
        this._userLastName = profile.lastName;
        this.notifyPropertyChangedHandler();
    }

    populateFromJSON(obj: any) {
        this._ignorePropertyChangedNotification = false;
        Object.assign(this, obj);
        this._companyId = obj['_companyId'];
        this._userId = obj['_userId'];
    }

    populateFromToken(json: any = null) {
        this._ignorePropertyChangedNotification = true;
        if (json && !this.impersonationId) {
            this.azureUID = json['oid'] ? json['oid'] : JJKUserInfo.emptyGuid;
        }
        this.iss = json['iss'] ? json['iss'] : undefined;
        this._ignorePropertyChangedNotification = false;
        this.notifyPropertyChangedHandler();
    }

    public toString(): string {
        const asKVP = JSON.stringify(this).split(',');
        const entries = Object.keys(asKVP).map((key) => asKVP[key]);
        const properKeys = [];
        entries.forEach((k) => {
            let cleanKey = k;
            if (k[1] === '_') {
                cleanKey = '"' + k.slice(2);
            }
            properKeys.push(cleanKey);
        });

        const adjustedResponse = properKeys.join(',');
        return adjustedResponse;
    }
    public get company(): Company {
        return this._company;
    }
    public get user(): User {
        return this._user;
    }
    public get userDataProfile(): UserDataProfile {
        return this._userDataProfile;
    }
    public get subscription(): Subscription {
        return this._subscription;
    }
    public get subscriptionType(): SubscriptionType {
        return this._subscriptionType;
    }
    public get logoUrl(): string {
        return this._logoUrl;
    }
    public set logoUrl(value) {
        this._logoUrl = value;
    }
    public get allowNoAccessControlGroup(): boolean {
        return this._allowNoAccessControlGroup;
    }
    public set allowNoAccessControlGroup(value) {
        this._allowNoAccessControlGroup = value;
    }
    public get groups(): Groups[] {
        return this._groups || [];
    }
    public set groups(value) {
        this._groups = value;
        this.notifyPropertyChanged();
    }
    public get userGroups(): UserGroups[] {
        return this._userGroups || [];
    }
    public get allRoles(): Roles[] {
        return this._roles;
    }
    public get roles(): Roles[] {
        return this._roles?.filter((r) => !r.hideInUI);
    }
    public get groupRole(): GroupsRolePerUserOrGroup[] {
        return this._groupRole;
    }
    public get permission(): Permissions[] {
        return this._permissions;
    }
    public get permissionsPerRole(): PermissionsPerRole[] {
        return this._permissionsPerRole;
    }
    public get features(): FeatureShow[] {
        return this._features || [];
    }
    public get featurePerSubscription(): Features[] {
        return this._subscriptionFeature || [];
    }

    public getRoleValue(groupId: string) {
        const calcPerm = this._calculatedPermissions?.find((cp) => cp.groupId === groupId);
        return calcPerm ? calcPerm.value : 0;
    }

    public getRoleId(groupId: string): string {
        const calcPerm = this._calculatedPermissions?.find((cp) => cp.groupId === groupId);
        return calcPerm ? calcPerm.roleId : null;
    }

    public loadFeatures(features: Features[], featuresPerSubscription: FeaturesPerSubscription[], featuresPerCompany: FeaturesPerCompany[], featuresPerUserOrGroup: FeaturesPerUserOrGroup[]) {
        this._features = [];
        this._subscriptionFeature = [];
        if (features && features.length > 0) {
            for (const element of features) {
                const subFeature = (featuresPerSubscription && featuresPerSubscription.length > 0) ? featuresPerSubscription.find((f) => f.featureId === element.id) : null;
                if (subFeature) {
                    this._subscriptionFeature.push(element);
                    const compFeature = (featuresPerCompany && featuresPerCompany.length > 0) ? featuresPerCompany.find((f) => f.featureId === element.id) : null;
                    if (compFeature && !compFeature.show) {
                        this._features.push(new FeatureShow(element, compFeature.show));
                    } else {
                        const userFeature = (featuresPerUserOrGroup && featuresPerUserOrGroup.length > 0) ? featuresPerUserOrGroup.find((f) => f.featureId === element.id && f.userId != null) : null;
                        if (userFeature) {
                            this._features.push(new FeatureShow(element, userFeature.show));
                        } else {
                            const userGroupFeature = (featuresPerUserOrGroup && featuresPerUserOrGroup.length > 0) ? featuresPerUserOrGroup.find((f) => f.featureId === element.id && f.userGroupId != null) : null;
                            if (userGroupFeature) {
                                this._features.push(new FeatureShow(element, userGroupFeature.show));
                            } else {
                                this._features.push(new FeatureShow(element, subFeature.defaultShow));
                            }
                        }
                    }
                } else {
                    this._features.push(new FeatureShow(element, false));
                }
            }
        }
    }

    loadFeatureWiseGroup(domain, type, authData) {
        const groups = [];
        for (const group of this.groups) {
            if (group.name === 'Root' || group.name === 'No Access Control') {
                groups.push(new GroupAndEnabled(group, false));
                continue;
            }
            const authEntity: any = {};
            if (authData) { Object.assign(authEntity, authData); }
            authEntity.groupId = group.id;
            const enabled = this.checkAuthFeature(domain, type, authEntity);
            groups.push(new GroupAndEnabled(group, enabled));
        }
        return groups;
    }

    loadImpersonation(impersonations: Impersonations[]) {
        if (impersonations.length > 0 && this._mainUser) {
            this.impersonation = impersonations;
            const impersonation = impersonations.find((e) => e.impersonatingUserId === this._mainUser.id);
            this.impersonationId = (impersonation) ? impersonation.id : '';
        }
    }

    public get disableDataGroup(): boolean {
        return (!this._subscriptionType?.allowDataGroups || this._company.disableAccessControl);
    }

    public get canAddNew(): boolean {
        if (this.disableDataGroup) {
            return true;
        } else {
            return (this.getRoleValue(this._sharedGroup.id) & GroupPermission.Create) !== 0;
        }
    }

    public get isRootLevelAdmin(): boolean {
        let isRootAdmin = false;
        if (this._rootGroupId) {
            const roleValue = this.getRoleValue(this._rootGroupId);
            if (roleValue && roleValue === RolesEnum.Admin) { isRootAdmin = true; }
        }
        return isRootAdmin;
    }

    public get isAdmin(): boolean {
        const adminGroup = this._calculatedPermissions?.find((cp) => (cp.value & GroupPermission.Admin) !== 0);
        return (adminGroup) ? true : false;
    }

    public get IsSuperUser(): boolean {
        return this.user?.userType === UserTypes.SAPAdmin || this.user?.userType === UserTypes.SuperAdmin || this.user?.userType === UserTypes.ProductOwner;
    }

    public get maxRole(): string {
        // eslint-disable-next-line prefer-spread
        const highestValue = Math.max.apply(Math, this._calculatedPermissions.map((cp) => cp.value));
        return this._calculatedPermissions.find((cp) => cp.value === highestValue).roleId;
    }

    public canCreate(domain, type): boolean {
        let allowCreate = false;
        for (const rolePerm of this.permissionsPerRole) {
            if (!rolePerm.show) { continue; }
            const permission = this.permission.find((e) => e.id === rolePerm.permissionId);
            if (!permission || permission.domain.trim() !== domain || permission.type.trim() !== type) {
                continue;
            }
            const createGroup = this._calculatedPermissions.find((cp) => cp.roleId === rolePerm.roleId);
            allowCreate = (createGroup) ? true : false;
            if (allowCreate) { break; }
        }
        return allowCreate;
    }

    public checkAuthFeature(domain, type, authEntity, routeCheck = false, ignoreGroup = false, isCompanyDataTypeGroupingEnabled = false): boolean {
        let show = true;
        try {
            // Check if feature turned on
            const feature = this.features.find((f) => f.shortName === domain);
            show = feature && feature.show;

            /* Check the users max role - if it is None, do not show anything but the home dashboard
             * NOTE: this scenario should only occur for non-SAP admins on a team subscription */
            if (this.maxRole === DefaultIds.NoneRole) {
                if (domain !== 'dashboard-home') {
                    return false;
                }
            }

            /* If this is the 'incident-report' feature, check the generateOSHAReports feature of the subscription */
            if (domain === 'incident-report') {
                return show && this.checkAuthFeature('employee', 'view', '') && this.subscriptionType.generateOSHAReports;
            }

            /* If this is the setting-incident-intake feature, check that the user can access the incident-nonauth feature */
            if (domain === 'setting-incident-intake') {
                if (!this.checkAuthFeature('incident-nonauth', '', '')) {
                    return false;
                }
            }

            /* If this is an admin permission, verify the feature is on or this is the Settings route,
             * then verify the user is either an SAP or data group admin
             * NOTE: this is not a type in the permissions file, so the permissions data is not checked */
            if (type === 'admin') {
                const isSuperType = this.IsSuperUser;
                if ((show || domain === 'setting') && (isSuperType || this.isAdmin)) {
                    return true;
                } else if ((domain === 'setting-features') && isSuperType && this._subscriptionType?.allowDataGroups) {
                    return true;
                } else {
                    return false;
                }
            }

            if (type === 'product-owner') {
                if ((show || domain === 'setting') && (this.user.userType === UserTypes.ProductOwner)) {
                    return true;
                } else {
                    return false;
                }
            }

            /* If type is clone, we need to check that the user has create permission on the domain passed
             * and view permission on the entity being cloned */
            if (type === 'clone') {
                if (this.checkAuthFeature(domain, 'create', '') && this.checkAuthFeature(domain, 'view', authEntity, false, ignoreGroup)) {
                    return true;
                }
                else {
                    return false;
                }
            }

            // If type is included and the feature is turned on (or doesn't exist), this a "permission" check (otherwise it is only a "feature" check)
            if (type && (feature === undefined || show)) {
                show = false;

                // Temporary fix for chemical-binder checks
                // We do not want to check the entity in these cases since there is no group ID for binders yet
                if (domain === 'chemical-binder') {
                    authEntity = null;
                    if (type !== 'view') {
                        domain = 'chemical';
                    }
                }

                // If feature allow then find data group from entity so we can check user's permission.
                // if domain is company data or covid-medical, then only check shared permission
                let dataGroupId = (authEntity && authEntity.groupId && authEntity.groupId !== Guid.EMPTY && !ignoreGroup) ? authEntity.groupId :
                    ((!isCompanyDataTypeGroupingEnabled && !routeCheck && !ignoreGroup && domain === 'company-data') || domain === 'covid-medical') ? this.sharedGroupId : null;

                if (this.disableDataGroup && authEntity && authEntity.groupId !== this.sharedGroupId) {
                    dataGroupId = this.sharedGroupId;
                }

                // Get user's role for data group found above (or use max role if no entity passed), then get all permissions for said role
                const roleId = dataGroupId ? this.getRoleId(dataGroupId) : this.maxRole;
                const permissionsPerRole = this.permissionsPerRole.filter((e) => roleId === e.roleId);

                // Search through all permissions for this role - if a permission check gets through to the end, show will be set to true
                for (const rolePerm of permissionsPerRole) {
                    // Find permission for the current rolePermission record - if it doesn't match the current 'domain,type' - skip it
                    const permission = this.permission.find((e) => e.id === rolePerm.permissionId);
                    if (!permission || permission.domain.trim() !== domain || permission.type.trim() !== type) {
                        continue;
                    }

                    // If there is no entity but this permission checks an entity, skip it
                    if (!authEntity && !routeCheck && !ignoreGroup && (permission.createdByType || permission.assignedToType || permission.promotedType || permission.companyType)) {
                        continue;
                    }

                    // Check the permission and see if it is valid to show - if not, skip it
                    if (!rolePerm.show) {
                        continue;
                    }

                    /* If an entity was passed, check the entity permissions - if any of those checks fail, show will stay false; otherwise
                     * it will flow through to set show to true */
                    if (authEntity) {
                        const createdUserId = isNullOrUndefined(authEntity.createdByUserId) ? authEntity.addedBy : authEntity.createdByUserId;
                        if (createdUserId == this._userId) {
                            show = true;
                            break;
                        }
                        if ((permission.assignedToType && !this.checkExtraRestriction(authEntity.assignedToEmployeeId ? authEntity.assignedToEmployeeId : (authEntity.assignedToId ? authEntity.assignedToId : authEntity.assignedTo), permission.assignedToType)) ||
                            (permission.companyType && !this.checkExtraRestriction(authEntity.companyId, permission.companyType))) {
                            continue;
                        }
                    }
                    show = true;
                    break;
                }

                /* If this is a download or clone permission, check the promoted flag of the entity and
                 * verify the subscription type allows downloads of non-promoted assets */
                if ((type === 'download' || type === 'clone') && authEntity && authEntity.promoted === false) {
                    show = show && this.subscriptionType.downloadPromotedEqualZero;
                }
            }
        } catch (e) {
            show = false;
        }
        return show;
    }

    public checkExtraRestriction(val: any, type: string): boolean {
        if (type.trim().length > 0) {
            switch (type.trim()) {
                case 'n': return !val;
                case 'u': return val === this.userId || val === this.fullName;
                case 'o': return val !== this.userId && val !== this._jjkUserId;
                case '0': return val === false;
                case '1': return val === true;
                case 'c': return !val || val === this.companyId;
                case 'j': return val === this.jjkCompanyId;
                default:
                    return true;
            }
        }
    }

    canAddTo(domain, type, authEntity): boolean {
        if (this.disableDataGroup) { return true; }
        const dataGroupId = (authEntity && authEntity.groupId) ? authEntity.groupId : null;
        if (!dataGroupId) { return false; }
        const enabled = this.checkAuthFeature(domain, type, authEntity);
        return enabled;
    }

    filterAssignToUser(domain, type, dataGroupId, users, assignedUserId) {
        let currentUser = null;
        let otherUsers = null;

        const roleId = this.getRoleId(dataGroupId);
        const permissionsPerRole = this.permissionsPerRole.filter((e) => roleId === e.roleId);
        for (const rolePerm of permissionsPerRole) {
            if (!rolePerm.show) { continue; }
            const permission = this.permission.find((e) => e.id === rolePerm.permissionId);
            if (!permission || permission.domain.trim() !== domain || permission.type.trim() !== type) { continue; }
            if (permission.assignedToType) {
                if (permission.assignedToType.trim() === 'u') {
                    if (!currentUser) {
                        currentUser = users.filter((e) => e.id === this.userId);
                    }
                } else if (permission.assignedToType.trim() === 'o') {
                    if (!otherUsers) {
                        otherUsers = users.filter((e) => e.id !== this.userId);
                    }
                }
            }
        }
        const assignToUsers = [...(currentUser || []), ...(otherUsers || [])];
        if (assignedUserId && !assignToUsers.some((e) => e.id === assignedUserId)) {
            const assignedUser = users.find((e) => e.id === assignedUserId);
            assignToUsers.push(assignedUser);
        }
        return assignToUsers;
    }

    isAdminGroup(groupId) {
        const adminGroup = this._calculatedPermissions.find((cp) => cp.groupId === groupId && this.getRoleValue(groupId) === RolesEnum.Admin);
        return (adminGroup ? true : false);
    }

    canEditGroup(groupId) {
        const roleValue = this.getRoleValue(groupId)
        const editGroup = this._calculatedPermissions.find((cp) => cp.groupId === groupId && (roleValue === RolesEnum.Admin || roleValue === RolesEnum.Editor));
        return (editGroup ? true : false);
    }
}
