<div class="form-group">
  <label class="form-label" style="display: inline-block" for="textAreaDescription">{{ noteTitle }}</label>&nbsp;
  <a auth [authFeature]="authFeature" [authEntity]="parentEntity" [routerLink]="[]" class="small" (click)="openEditDialog();"><strong>Add</strong></a>

  <jjk-empty-state auth [authFeature]="authFeature" [authEntity]="parentEntity" *ngIf="showCta()" (action)='openEditDialog()'></jjk-empty-state>

  <div class="table-responsive" *ngIf="notes.length !== 0">
    <table class="table table-striped">
      <tbody>
        <ng-container *ngFor="let note of notes">
          <tr *ngIf="!note.isDeleted">
            <td scope="row">
              <div>
                <p><a auth [authFeature]="authFeature" [authEntity]="parentEntity" [routerLink]="[]" (click)="openEditDialog(note?.id);">{{note.title}}</a></p>
                <ng-container *ngIf="authFeature">
                  <span auth [authFeature]="authFeature" [authEntity]="parentEntity" [authNegate]="true">{{note.title}}</span>
                </ng-container>
              </div>
              <div>
                <p>{{note.description}}</p>
                <ul class="actions">
                  <li auth [authFeature]="authFeature" [authEntity]="note"><a [routerLink]="[]" (click)="openEditDialog(note?.id);">Edit</a></li>
                  <li auth [authFeature]="authFeature" [authEntity]="note"><a [routerLink]="[]" (click)="openDeleteDialog(note?.id);">Delete</a></li>
                </ul>
              </div>
              <audit-info [auditInfo]="note" [addEndSpacing]="false"></audit-info>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<modal-window [id]="editModalId" [title]="currentNote.id.length || currentNote.isAdded ? 'Edit' : 'Add'" [confirmButtonText]="'Save'" (onConfirm)="onSaveNote()" (onCancel)="onCancel()" [autoClose]="false">
  <form [formGroup]="noteEditForm">
    <div class="form-group" [ngClass]="showErrors && noteEditForm.get('title').errors?.required ? 'form-group-error' : ''">
      <label class="form-label" for="title"><strong class="req">Title</strong></label>
      <input type="text" id="title" name="title" class="form-control" maxlength="100" formControlName="title" required [ngClass]="this.showErrors && noteEditForm.get('title').errors?.required ? 'field-error' : ''" autocomplete="off">
    </div>

    <div class="form-group">
      <label class="form-label" for="description"><strong>Description</strong></label>
      <textarea class="form-control" id="description" #description rows="5" maxlength="7000" formControlName="description" ng-trim="false"></textarea>
      <div class="character-counter" [innerHtml]="description.value.length + '/7000'"></div>
    </div>
  </form>
</modal-window>
<modal-window [id]="deleteModalId" [title]="'Delete'" [confirmButtonText]="'Delete'" (onConfirm)="onDeleteNote()">
  <p>Are you sure you want to delete this note?</p>
</modal-window>