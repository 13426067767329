import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import ColumnBreakpoints from '../common/column-breakpoints';
import { SmsColumnDefinition } from '../common/models/sms-column-definition.model';
import { SmsColumnToggleEvent } from '../common/models/sms-column-toggle-event.model';
import { ScreenService } from '../screen.service';
import { SmsMinimapService } from './sms-minimap.service';

@Component({
    selector: 'sms-minimap',
    templateUrl: './sms-minimap.component.html',
    styleUrls: ['./sms-minimap.component.scss'],
})
export class SmsMinimapComponent implements OnChanges, OnDestroy, OnInit {
    @Input() data;
    @Input() columnOptions;
    @Input() savedColumns: SmsColumnDefinition[];
    @ViewChild('columnToggle') columnToggle;
    @Output() updateColumns = new EventEmitter<SmsColumnDefinition[]>();
    @Output() onSelectionUpdated = new EventEmitter<SmsColumnToggleEvent>();

    columns: SmsColumnDefinition[];
    showDropdown = false;

    constructor(private screenService: ScreenService, private smsMinimapService: SmsMinimapService) { }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: HTMLElement) {
        if (this.showDropdown && !this.columnToggle.nativeElement.contains(targetElement)) {
            this.toggleDropdown();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.savedColumns && this.savedColumns.length > 0) {
            this.columns = this.savedColumns;
            this.smsMinimapService.initColumnInfo(this.columns);
            this.screenService.screenResized$
                .subscribe((dimensions) => this.updateColumnsOnResize(dimensions));
        } else if (changes.data && changes.data.currentValue && !changes.data.firstChange &&
            (!this.columns || this.columns.length === 0)) {
            this.columns = SmsColumnDefinition.buildColumnInfo(this.data, this.columnOptions);
            this.smsMinimapService.initColumnInfo(this.columns);
            this.screenService.screenResized$
                .subscribe((dimensions) => this.updateColumnsOnResize(dimensions));
        } else {
            if (this.data && this.data.length) {
                this.columns = SmsColumnDefinition.buildColumnInfo(this.data, this.columnOptions);
                this.smsMinimapService.initColumnInfo(this.columns);
            }
            this.screenService.screenResized$
                .subscribe((dimensions) => this.updateColumnsOnResize(dimensions));
        }
    }

    ngOnDestroy() {
        this.updateColumns.emit(this.columns);
    }

    ngOnInit() {
        if (!this.columns) {
            this.columns = SmsColumnDefinition.buildColumnInfo(this.data, this.columnOptions);
            this.smsMinimapService.initColumnInfo(this.columns);
        }
        this.screenService.screenResized$
            .subscribe((dimensions) => this.updateColumnsOnResize(dimensions));
    }

    toggleColumn(columnName: string) {
        const columnToToggle = this.columns.find((column) => column.value === columnName);
        if (columnToToggle) {
            columnToToggle.visibility.hidden = !columnToToggle.visibility.hidden;
            columnToToggle.visibility.manuallyToggled = !columnToToggle.visibility.manuallyToggled;
            this.smsMinimapService.setColumnInfo(columnToToggle);
        }
        this.savedColumns = this.columns.map((c) => ({ ...c }));
        this.updateColumns.emit(this.columns);
        this.onSelectionUpdated.emit({ changedColumn: columnToToggle, columnsStatus: this.columns });
    }

    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
    }

    getHideableCols() {
        let hideableCols = [];
        if (this.columns) {
            hideableCols = this.columns.filter((col) => col.visibility.alwaysShow === false);
        }
        return hideableCols;
    }

    updateColumnsOnResize(dimensions) {
        if (this.columns) {
            for (const col of this.columns) {
                const { alwaysShow, manuallyToggled, priority } = col.visibility;

                if (manuallyToggled) {
                    // resizing has no effect on manually toggled columns
                    // since it assumes the user made a specific choice
                    continue;
                } else if (alwaysShow) {
                    col.visibility.hidden = false;
                    this.smsMinimapService.setColumnInfo(col);
                } else if (dimensions.screenWidth < ColumnBreakpoints[priority]) {
                    col.visibility.hidden = true;
                    this.smsMinimapService.setColumnInfo(col);
                } else {
                    col.visibility.hidden = false;
                    this.smsMinimapService.setColumnInfo(col);
                }
            }
        }
    }
}
