import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { AssociatedType } from '@constants/enums/associated-type.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { ComponentMode } from '@constants/settings/component-mode';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { Note } from '@models/entity-models/autogenerated/note';
import { NoteData } from '@models/notes.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { FilterExpressionBuilder } from '@services/core/models/Filter-Entry';
import { isValidArray } from '@utilities/helpers';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
    @Input() notePrefix: string;
    @Input() componentMode: ComponentMode;
    @Input() associatedTypeId: AssociatedType;
    @Input() modalId: string;
    @Input() authFeature: string;
    @Input() parentEntity: any;

    @Input()
    public get associatedId(): string {
        return this._associatedId;
    }
    public set associatedId(value: string) {
        if (value) {
            this._associatedId = value;

            const simpleExpression = FilterExpressionBuilder.For(ApiEntityTypesEnum.Note)
            .Use('AssociatedId', ParameterTypeEnum.String)
            .Equal(this._associatedId)
            .Build().AsExpression;

            ApiFactory.retrieveEntity(ApiEntityTypesEnum.Note)
                .addFilterEntries(simpleExpression)
                .addSuccessHandler((response) => {
                    this.buildNotes(response);
                })
                .removePaging()
                .buildAndSend();
        }
    }

    noteTitle = 'Notes';
    private _associatedId: string;
    notes: NoteData[] = [];

    currentNote: NoteData = this.newNote();

    companyId: string;
    noteEditForm: UntypedFormGroup;
    showErrors: boolean;
    editModalId: string;
    deleteModalId: string;

    @Output() onNotesChange = new EventEmitter<NoteData[]>();

    constructor(private notesFormBuilder: UntypedFormBuilder) { }

    ngOnInit() {
        if (LoggedInUserInfo && LoggedInUserInfo.Instance && LoggedInUserInfo.Instance.userInfo) {
            this.companyId = LoggedInUserInfo.Instance.userInfo.companyId.toString();
        }

        if (this.componentMode === null) { throw new Error('Attribute \'componentMode\' is required'); }
        if (this.associatedTypeId === null) { throw new Error('Attribute \'associatedTypeId\' is required'); }
        if (this.associatedId === null) { throw new Error('Attribute \'associatedId\' is required'); }
        if (this.modalId === null) { throw new Error('Attribute \'modalId\' is required'); }

        if (this.notePrefix && this.notePrefix !== '') {
            this.noteTitle = `${this.notePrefix} Notes`;
        }

        this.editModalId = `${this.modalId}_editDialog`;
        this.deleteModalId = `${this.modalId}_deleteDialog`;

        this.noteEditForm = this.notesFormBuilder.group({
            title: ['', Validators.required],
            description: [''],
        });
    }

    buildNotes(data: Note[]) {
        this.notes = this.getNotesData(data);
    }

    openEditDialog(noteId = '') {
        if (noteId.length) {
            this.currentNote = this.notes.find((a) => a.id === noteId);
        } else {
            this.currentNote = this.newNote();
        }
        this.noteEditForm.get('title').setValue(this.currentNote.title);
        this.noteEditForm.get('description').setValue(this.currentNote.description);
        ($(`#${this.editModalId}`) as any).modal('show');

        $(`#${this.editModalId}`).on('shown.bs.modal', function() {
            setTimeout(() => {
                $('#title', this).trigger('focus');
            }, 150);
        });
    }

    openDeleteDialog(noteId = '') {
        this.currentNote = this.notes.find((a) => a.id === noteId);
        ($(`#${this.deleteModalId}`) as any).modal('show');
    }

    onDeleteNote() {
        if (this.componentMode === ComponentMode.View) {
            ApiFactory.deleteEntity(ApiEntityTypesEnum.Note)
                .addEntityId(this.currentNote.id)
                .addSuccessHandler(() => {
                    this.noteDeleted();
                })
                .buildAndSend();
        } else {
            this.noteDeleted();
        }
    }

    noteDeleted() {
        if (this.componentMode === ComponentMode.Edit) {
            this.currentNote.isDeleted = true;

            if (this.currentNote.isAdded && this.currentNote.isDeleted) {
                this.notes = this.notes.filter((n) => n.id !== this.currentNote.id);
            }

            this.onNotesChange.emit(this.notes);

        } else {
            this.notes = this.notes.filter((n) => n.id !== this.currentNote.id);
        }
    }

    onSaveNote() {
        if (this.noteEditForm.valid) {
            if (this.currentNote.title !== this.noteEditForm.get('title').value || this.currentNote.description !== this.noteEditForm.get('description').value) {
                this.currentNote.title = this.noteEditForm.get('title').value;
                this.currentNote.description = this.noteEditForm.get('description').value;

                if (!this.currentNote.id.length) {
                    this.currentNote.isAdded = true;
                }

                if (!this.currentNote.isAdded) {
                    this.currentNote.isModified = true;
                }

                if (this.currentNote.isAdded && this.currentNote.id.length) {
                    this.currentNote.isModified = true;
                }

                if (this.componentMode === ComponentMode.View) {
                    this.saveNote();
                } else {
                    if (this.currentNote.isAdded && !this.currentNote.isModified) {
                        // Give the new note a temp id for edit/delete purposes before actually being saved.
                        this.currentNote.id = Guid.raw();
                        this.notes = [this.currentNote, ...this.notes];
                    }
                    this.onNotesChange.emit(this.notes);
                }

                this.showErrors = false;
            }

            ($(`#${this.editModalId}`) as any).modal('hide');
        } else {
            this.showErrors = true;
        }
    }

    onCancel() {
        this.noteEditForm.get('title').setValue('');
        this.noteEditForm.get('description').setValue(this.currentNote.description);
        this.showErrors = false;
    }

    public saveNote() {
        if (this.currentNote.id === '') {
            const note = {
                companyId: this.currentNote.companyId,
                associatedId: this.currentNote.associatedId,
                associatedTypeId: this.currentNote.associatedTypeId,
                title: this.currentNote.title,
                description: this.currentNote.description,
                isDeleted: this.currentNote.isDeleted,
                sortOrder: this.currentNote.sortOrder,
                createdByUser: LoggedInUserInfo.Instance.userInfo.user,
            };

            ApiFactory.saveNewEntity(ApiEntityTypesEnum.Note, note)
                .addSuccessHandler((response: NoteData) => {
                    this.currentNote = response;
                    this.notes = [response, ...this.notes];
                })
                .buildAndSend();
        } else {
            ApiFactory.updateEntity(ApiEntityTypesEnum.Note, this.currentNote)
                .addSuccessHandler((response: NoteData) => {
                    response.modifiedByUser = response.modifiedByUser ? response.modifiedByUser : this.currentNote.createdByUser;
                    this.currentNote = response;
                    const index = this.notes.findIndex((x: NoteData) => x.id === this.currentNote.id);
                    this.notes[index] = this.currentNote;
                })
                .buildAndSend();
        }
    }

    // TODO FIX TYPE BACK TO NoteData
    newNote(): any {
        return {
            id: '',
            companyId: this.companyId,
            associatedId: this.associatedId,
            associatedTypeId: this.associatedTypeId,
            title: '',
            description: '',
            isDeleted: false,
            createdByUserId: '',
            modifiedByUserId: '',
            createdDate: null,
            modifiedDate: null,
            sortOrder: Math.max(...this.notes.map((n) => n.sortOrder), 0) + 1,
            isAdded: false,
            isModified: false,
            associatedType: null,
            createdByUser: null,
            modifiedByUser: null,
            _validateInfo: null,
            _validate: null,
        };
    }

    getNotesData(data: Note[]): NoteData[] {
        return data.map((n) => {
            return {
                id: n.id,
                companyId: n.companyId,
                groupId: this.parentEntity.groupId,
                associatedId: n.associatedId,
                associatedTypeId: n.associatedTypeId,
                title: n.title,
                description: n.description,
                isDeleted: false,
                createdByUserId: n.createdByUserId,
                modifiedByUserId: n.modifiedByUserId,
                createdDate: n.createdDate,
                modifiedDate: n.modifiedDate,
                createdByUser: n.createdByUser,
                modifiedByUser: n.modifiedByUser,
                sortOrder: n.sortOrder,
                isAdded: false,
                isModified: false,
            } as NoteData;
        })
            .sort((a: Note, b: Note) => {
                return b.sortOrder - a.sortOrder;
            });
    }

    showCta(): boolean {
        return this.notes.length === 0 || this.notes.filter((n) => n.isDeleted).length === this.notes.length;
    }

    async saveAll(associatedId: string) {
        if (!isValidArray(this.notes)) { return; }

        const notesChanges = this.notes
            .filter((n) => n.isAdded || n.isModified || n.isDeleted)
            .sort((a: NoteData, b: NoteData) => a.sortOrder - b.sortOrder);

        if (isValidArray(notesChanges)) {
            const saveProcesses = notesChanges.map(async (x) => {
                await this.runSave(associatedId, x);
            });
            await Promise.all(saveProcesses);
        }
    }

    private runSave(associatedId: string, note: NoteData): Promise<void> {
        let apiFactory: ApiFactory;
        if (note.isAdded) {
            const newNote = {
                companyId: note.companyId,
                associatedId,
                associatedTypeId: note.associatedTypeId,
                title: note.title,
                description: note.description,
                isDeleted: note.isDeleted,
                sortOrder: note.sortOrder,
            };

            apiFactory = ApiFactory.saveNewEntity(ApiEntityTypesEnum.Note, newNote);
        } else if (note.isModified) {
            apiFactory = ApiFactory.updateEntity(ApiEntityTypesEnum.Note, note);
        } else if (note.isDeleted) {
            apiFactory = ApiFactory.deleteEntity(ApiEntityTypesEnum.Note).addEntityId(note.id);
        }

        return new Promise<void>((resolve, reject) => {
            apiFactory
                .addSuccessHandler(() => resolve())
                .addErrorHandler(() => resolve())
                .buildAndSend();
        });
    }
}
