import { Component, Input, OnDestroy } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { AgPromise, IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { ApiFactory } from '@services/core/api-factory.class';
import { FilterExpressionBuilder, IFilterDataOnly } from '@services/core/models/Filter-Entry';
import { isNullOrUndefined } from '@utilities/helpers';

@Component({
    selector: 'jjkp-ag-grid-custom-autocomplete-filter',
    templateUrl: './ag-grid-custom-autocomplete-filter.component.html',
    styleUrls: ['./ag-grid-custom-autocomplete-filter.component.scss'],
})
export class AgGridCustomAutocompleteFilterComponent implements IFilterAngularComp, OnDestroy {
    params: any;

    allValuesFromApi: any;
    valuesFromApi$: Observable<any[]>;
    entityInput$ = new Subject<string>();
    entityInputSubscription$: Subscription;
    filterSelectedValues: any[] = [];

    public loadingData = false;

    @Input() entity: ApiEntityTypesEnum;
    @Input() fieldId = 'id';
    @Input() fieldName = 'name';
    @Input() addRouteHint = '';
    @Input() routeParamValue = '';
    @Input() routeParamName = '';
    @Input() filterExpression: IFilterDataOnly[];
    @Input() returnAdditionalProperty;

    private _selectedValues;
    get selectedValues() {
        return this._selectedValues;
    }
    set selectedValues(value) {
        this._selectedValues = value;
    }

    constructor() { }

    agInit(params: IFilterParams): void {
        this.params = params;
        this.entity = this.params?.entity;
        this.fieldName = this.params?.fieldName;
        this.addRouteHint = !isNullOrUndefined(this.params?.addRouteHint) ? this.params?.addRouteHint : '' ;
        this.routeParamValue = !isNullOrUndefined(this.params?.routeParamValue) ? this.params?.routeParamValue : '' ;
        this.routeParamName = !isNullOrUndefined(this.params?.routeParamName) ? this.params?.routeParamName : '' ;
        this.returnAdditionalProperty = !isNullOrUndefined(this.params?.returnAdditionalProperty) ? this.params?.returnAdditionalProperty: false ;
        this.filterExpression = this.params?.filterExpression;
        this.inputTextSubscription();
    }

    inputTextSubscription() {
        if (this.entityInputSubscription$) { this.entityInputSubscription$.unsubscribe(); }

        this.entityInputSubscription$ = this.entityInput$.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            tap((term) => this.requestEntityData(term))).subscribe();
    }

    isFilterActive(): boolean {
        return ( isNullOrUndefined(this.selectedValues) ? false : this.selectedValues.length > 0 ? true : false);
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        return ( isNullOrUndefined(this.selectedValues) ? false : this.selectedValues.length > 0 ? true : false);
    }

    getModel() {
        if (!isNullOrUndefined(this.selectedValues) && this.selectedValues?.length > 0) {
            if (!this.returnAdditionalProperty) {
                return { values: this.filterSelectedValues.map(f => f.displayText) , filterType: 'set' };
            } else {
                return { values: this.filterSelectedValues.map(f => f.displayText) , filterType: 'set', additionalProperty: this.filterSelectedValues.map(f => f.additionalProperty) };
            }
        }
    }

    setModel(model: any): void | AgPromise<void> {
        if (model && model?.values?.length > 0) {
            this.loadingData = true;
            const appliedFilters = [...new Set(model.values)];
            model.values = appliedFilters;
            this.filterSelectedValues = [];
            this.recoverPreviousFilterStatus(model);
        } else {
            this.clearFilter();
        }
    }

    async recoverPreviousFilterStatus(model) {

        const promiseArray = model.values.map(async val => {
            // must us a different call from requestEntityData because the setting of this.allValuesFromApi within that
            // method in this async loop causes conflicts that can prevent some values from properly being set as selected.
            const results = await this.requestIndividualEntityData(val);
            // don't add duplicates
            const originalIds = new Set(this.filterSelectedValues.map(x => x.id));
            this.filterSelectedValues = this.filterSelectedValues.concat(results.filter((d) =>
            model.values.includes(d.displayText)).filter(x => !originalIds.has(x.id)));
        });

        await Promise.all(promiseArray);

        this.allValuesFromApi = this.filterSelectedValues
        this.valuesFromApi$ = of(this.setDataFromApi(this.allValuesFromApi));

        this._selectedValues = [];
        this._selectedValues = this.filterSelectedValues.map(f => f.id);
        this.loadingData = false;
        this.params.filterChangedCallback();
    }

    onNewRowsLoaded?(): void { }
    onAnyFilterChanged?(): void { }
    afterGuiAttached?(params?: IAfterGuiAttachedParams): void { }

    getModelAsString?(model: any): string {
        if (model && model?.values?.length > 0) {
            return '(' + model.values.length + ') ' + model.values
        } else {
            return ''
        }
    }

    ngOnDestroy() {
        if (this.entityInputSubscription$) { this.entityInputSubscription$.unsubscribe(); }
    }

    async requestEntityData(term): Promise<void> {
        if (term) {
            this.loadingData = true;
            const apiFactory = this.getApiFactoryForApiCall(term);

            return new Promise((resolve, reject) => {
                apiFactory
                    .addSuccessHandler((response) => {
                        let results = this.mapApiResponse(response);

                        results = [...new Map(results.map(item => [item.displayText, item])).values()]
                        this.allValuesFromApi = results.concat(this.filterSelectedValues);

                        if (results.length > 0)
                            this.valuesFromApi$ = of(this.setDataFromApi(this.allValuesFromApi));

                        this.loadingData = false;
                        resolve();
                    })
                    .addErrorHandler(() => {
                        this.loadingData = false;
                        reject();
                    })
                    .removePaging()
                    .buildAndSend();
            });
        } if (term && term.trim() === '') {
            this.allValuesFromApi = this.filterSelectedValues
            this.valuesFromApi$ = of(this.setDataFromApi(this.allValuesFromApi));
        }
    }

    async requestIndividualEntityData(term): Promise<any> {
        if (term) {
            const apiFactory = this.getApiFactoryForApiCall(term);

            return new Promise((resolve, reject) => {
                apiFactory
                    .addSuccessHandler((response) => {
                        let results = this.mapApiResponse(response);

                        results = [...new Map(results.map(item => [item.displayText, item])).values()];
                        resolve(results);
                    })
                    .addErrorHandler(() => {
                        reject();
                    })
                    .removePaging()
                    .buildAndSend();
            });
        } if (term && term.trim() === '') {
            return this.filterSelectedValues
        }
    }

    setDataFromApi(values: any) {
        return this.getUniqueList(values.filter(i => i && i.id), 'id').sort(this.sortOptionsAscending);
    }

    getUniqueList(array, propertyName) {
        return [...new Map(array.map(item => [item[propertyName] || null, item])).values()];
    }

    sortOptionsAscending(a, b) {
        return a.displayText.toString().localeCompare(b.displayText);
    }

    onSelectedValue(values) {
        if (!isNullOrUndefined(values) && Array.isArray(values)){
            this.filterSelectedValues = []
            this.filterSelectedValues = values

            this.params.filterChangedCallback();
        }
    }

    clearFilter() {
        this.filterSelectedValues = [];
        this.selectedValues = [];
        this.allValuesFromApi = [];
        this.valuesFromApi$ = of([]);
        this.inputTextSubscription();
    }

    onClose() {
        this.valuesFromApi$ = of(this.filterSelectedValues);
    }

    private getApiFactoryForApiCall(term): ApiFactory {
        const filter = FilterExpressionBuilder.For(this.entity)
                .Use(this.fieldName, ParameterTypeEnum.String)
                .Contains(term.toString())
                .Build().AsExpression;

        const apiFactory = ApiFactory.retrieveEntity(this.entity);
        apiFactory.addFilterEntries(filter);

        if (this.filterExpression) {
            apiFactory.addFilterEntries(this.filterExpression);
        }

        if (this.addRouteHint !== '')
            apiFactory.addRouteHint(this.addRouteHint);
        if(this.routeParamValue != '' && this.routeParamName != '')
            apiFactory.addDataEntry(this.routeParamName,this.routeParamValue);

        return apiFactory;
    }

    private mapApiResponse(response): any {
        if (this.returnAdditionalProperty) {
            return response.map((x) => {
                return {
                    id: x[this.fieldId],
                    displayText: x[this.fieldName],
                    additionalProperty: x[this.returnAdditionalProperty]
                };
            });
        }

        return response.map((x) => {
            return {
                id: x[this.fieldId],
                displayText: x[this.fieldName],
            };
        });
    }
}
