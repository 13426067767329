<form *ngIf="form" [formGroup]="form">
    <h2>{{title}}</h2>

    <div class="form-group"
        [ngClass]="showErrors && form.get('ingredientName').errors?.required ? 'form-group-error' : ''">
        <label class="form-label" [class.req]="isAdd || isEdit" for="ingredientName">Ingredient Name</label>
        <p *ngIf="isView || isDelete" [innerHTML]="record?.ingredientName"></p>
        <input *ngIf="isEdit || isAdd" type="text"
            [ngClass]="{'field-error': showErrors && form.get('ingredientName').errors?.required}"
            formControlName="ingredientName" class="form-control" name="ingredientName" id="ingredientName">

    </div>

    <div class="form-group" [class.has-danger]="form.get('casRegistryNumber').errors"
        [ngClass]="showErrors && form.get('casRegistryNumber').errors?.required ? 'form-group-error' : ''">
        <label class="form-label" [class.req]="false" for="casRegistryNumber">CAS RN</label>
        <p *ngIf="isView || isDelete" [innerHTML]="record?.casRegistryNumber"></p>
        <input *ngIf="isEdit || isAdd" minlength="5" maxlength="12" type="text"
            [ngClass]="{'field-error': showErrors && form.get('casRegistryNumber').errors?.required}"
            formControlName="casRegistryNumber" class="form-control" name="casRegistryNumber" id="casRegistryNumber"
            placeholder="e.g. 32-65-7">
        <div *ngIf="form.get('casRegistryNumber').errors" class="error-message">
            <br>
            CAS RN values contain at least 5 numbers (without leading zeros), no more than 10 numbers, and 2 dashes (optional).
        </div>
    </div>

    <div class="form-group"
        [ngClass]="showErrors && form.get('globalProportionId').errors?.required ? 'form-group-error' : ''">
        <label class="form-label" for="globalProportionId"><strong>Proportion</strong></label>
        <p *ngIf="isView || isDelete" [innerHTML]="record?.globalProportion"></p>
        <jjk-select *ngIf="isEdit || isAdd" [options]="proportionOptions" id="globalProportionId"
            [(selection)]="selectedProportion" required
            [ngClass]="showErrors && form.get('globalProportionId').errors?.required ? 'field-error' : ''">
        </jjk-select>
    </div>

    <div class="form-group" [ngClass]="showErrors && form.get('minPercent').errors?.required ? 'form-group-error' : ''">
        <label class="form-label" [class.req]="false" for="minPercent">Min. %</label>
        <p *ngIf="isView || isDelete" [innerHTML]="record?.minPercent"></p>
        <input *ngIf="isEdit || isAdd" type="number" min="0" max="100" maxlength="6"
            [ngClass]="{'field-error': showErrors && (form.get('minPercent').errors?.outOfRange || form.get('minPercent').errors?.hasAtMostDecimalPlaces)}"
            oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            formControlName="minPercent" class="form-control" name="minPercent" id="minPercent">

    </div>

    <div class="form-group" [ngClass]="showErrors && form.get('maxPercent').errors?.required ? 'form-group-error' : ''">
        <label class="form-label" [class.req]="false" for="maxPercent">Max. %</label>
        <p *ngIf="isView || isDelete" [innerHTML]="record?.maxPercent"></p>
        <input *ngIf="isEdit || isAdd" type="number" min="0" max="100" maxlength="6"
            [ngClass]="{'field-error': showErrors && (form.get('maxPercent').errors?.outOfRange || form.get('maxPercent').errors?.hasAtMostDecimalPlaces)}"
            oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            formControlName="maxPercent" class="form-control" name="maxPercent" id="maxPercent">

    </div>

    <div class="form-group"
        [ngClass]="showErrors && form.get('globalPercentById').errors?.required ? 'form-group-error' : ''">
        <label class="form-label" for="globalPercentById"><strong>Global Percent By</strong></label>
        <p *ngIf="isView || isDelete" [innerHTML]="record?.globalPercentBy"></p>
        <jjk-select *ngIf="isEdit || isAdd" [options]="percentByOptions" id="globalPercentById"
            [(selection)]="selectedPercentBy"
            [ngClass]="showErrors && form.get('globalPercentById').errors?.required ? 'field-error' : ''">
        </jjk-select>
    </div>

</form>