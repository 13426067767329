import { Component } from '@angular/core';
import { IFloatingFilterParams } from 'ag-grid-community';

@Component({
  selector: 'jjkp-ag-grid-custom-autocomplete-floating-filter',
  templateUrl: './ag-grid-custom-autocomplete-floating-filter.component.html',
  styleUrls: ['./ag-grid-custom-autocomplete-floating-filter.component.scss']
})

export class AgGridCustomAutocompleteFloatingFilterComponent {
  params: IFloatingFilterParams;
  currentValue: string = null;

  agInit(params: IFloatingFilterParams): void {
    this.params = params;
  }

  onParentModelChanged(parentModel) {
    // When the filter is empty we will receive a null message here
    if (parentModel && parentModel?.values?.length > 0) {
      const appliedFilters = [...new Set(parentModel.values)];
      this.currentValue = '(' + appliedFilters.length + ') ' + appliedFilters;
    } else {
      this.currentValue = null;
    }
  }

  onInputClick() {
    this.params.showParentFilter();
  }
}
