import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * @whatItDoes Truncates a string and adds a link at the end
 * @howToUse `expression | json`
 * @description
 *
 * Truncates a string value to a provided limit (on complete words) and appends a truncateText at the end, linking to provided url
 *
 * ### Example
 * <p [innerHTML]="row.description | truncateLink : 255 : 'webcasts/' + row.id"></p>
 *
 */
@Pipe({ name: 'truncateLink' })
export class TruncateLinkPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }

    transform(value: string, limit = 255, url = '', truncateText = 'Read More...') {
        if (!value || value.length > limit) {
            const truncated = value.substr(0, limit);
            const truncatedCompleteWords = truncated.substr(0, truncated.lastIndexOf(' '));

            return this.sanitized.bypassSecurityTrustHtml(
                `${truncatedCompleteWords} <a href="${url}"><strong>${truncateText}</strong></a>`);
        } else {
            return value;
        }
    }
}
