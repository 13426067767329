<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 main-content">
      <jjk-horizontal-navigation></jjk-horizontal-navigation>
      <a *featureToggle="'topic_index_feature'" href="/topic_index"><button class="btn btn-primary viewAllTopicsBtn">View All Topics</button></a>
      <div class="form-group">
        <label> Search </label>
        <jjk-search-tips [searchFields]="'Topics and their Synonyms'"></jjk-search-tips>
        <search-input placeholder="" [searchTerm]="searchTerm" [showLoader]="showLoader" [loadingText]="'Fetching topics...'" (searchClick)="searchClick($event)" [showSearchButton]="true">
          <p searchNote class="note">Search “Topic Names” and “Synonyms”</p>
        </search-input>
      </div>
      <div class="row" *featureToggle="'!aem_implementation'">
        <div class="col pimcoreTopics">
          <jjk-pimcore-zone [zones]="'Topics:2'"></jjk-pimcore-zone>
        </div>
      </div>
      <h2>Popular Topics</h2>
      <div class="row popularTopics">
        <div class="col col-12 col-sm-12 col-md-4 col-lg-3">
          <a href="/topic_index/96" class="card">
            <img src="{{popularTopicsUrl}}ForkliftsAndPoweredTrucks.jpg" alt="Forklifts and Powered Trucks">
            <div class="card-body">
              <h5 class="card-title">Forklifts and Powered Trucks</h5>
            </div>
          </a>
        </div>
        <div class="col col-12 col-sm-12 col-md-4 col-lg-3">
          <a href="/topic_index/83" class="card">
            <img src="{{popularTopicsUrl}}FallProtection.jpg" alt="Fall Protection">
            <div class="card-body">
              <h5 class="card-title">Fall Protection</h5>
            </div>
          </a>
        </div>
        <div class="col col-12 col-sm-12 col-md-4 col-lg-3">
          <a href="/topic_index/77" class="card">
            <img src="{{popularTopicsUrl}}Ergonomics.jpg" alt="Ergonomics">
            <div class="card-body">
              <h5 class="card-title">Ergonomics</h5>
            </div>
          </a>
        </div>
        <div class="col col-12 col-sm-12 col-md-4 col-lg-3">
          <a href="/topic_index/40" class="card">
            <img src="{{popularTopicsUrl}}ConfinedSpaces.jpg" alt="Confined Spaces">
            <div class="card-body">
              <h5 class="card-title">Confined Spaces</h5>
            </div>
          </a>
        </div>
        <div class="col col-12 col-sm-12 col-md-4 col-lg-3">
          <a href="/topic_index/137" class="card"> <img src="{{popularTopicsUrl}}LockoutTagout.jpg" alt="Lockout/Tagout">
            <div class="card-body">
              <h5 class="card-title">Lockout/Tagout</h5>
            </div>
          </a>
        </div>
        <div class="col col-12 col-sm-12 col-md-4 col-lg-3">
          <a href="/topic_index/158" class="card">
            <img src="{{popularTopicsUrl}}PersonalProtectiveEquipment.jpg" alt="Personal Protective Equipment">
            <div class="card-body">
              <h5 class="card-title">Personal Protective Equipment</h5>
            </div>
          </a>
        </div>
        <div class="col col-12 col-sm-12 col-md-4 col-lg-3">
          <a href="/topic_index/106" class="card">
            <img src="{{popularTopicsUrl}}HazardCommunication.jpg" alt="Hazard Communication">
            <div class="card-body">
              <h5 class="card-title">Hazard Communication</h5>
            </div>
          </a>
        </div>
        <div class="col col-12 col-sm-12 col-md-4 col-lg-3">
          <a href="/topic_index/19" class="card">
            <img src="{{popularTopicsUrl}}BloodbornePathogens.jpg" alt="Bloodborne Pathogens">
            <div class="card-body">
              <h5 class="card-title">Bloodborne Pathogens</h5>
            </div>
          </a>
        </div>
        <div class="col col-12 col-sm-12 col-md-4 col-lg-3">
          <a href="/topic_index/66" class="card">
            <img src="{{popularTopicsUrl}}EmergencyPreparedness.jpg" alt="Emergency Preparedness">
            <div class="card-body">
              <h5 class="card-title">Emergency Preparedness</h5>
            </div>
          </a>
        </div>
        <div class="col col-12 col-sm-12 col-md-4 col-lg-3">
          <a href="/topic_index/1" class="card">
            <img src="{{popularTopicsUrl}}AccidentInvestigation.jpg" alt="Accident Investigation">
            <div class="card-body">
              <h5 class="card-title">Accident Investigation</h5>
            </div>
          </a>
        </div>
        <div class="col col-12 col-sm-12 col-md-4 col-lg-3">
          <a href="/topic_index/87" class="card">
            <img src="{{popularTopicsUrl}}FireExtinguishers.jpg" alt="Fire Extinguishers">
            <div class="card-body">
              <h5 class="card-title">Fire Extinguishers</h5>
            </div>
          </a>
        </div>
        <div class="col col-12 col-sm-12 col-md-4 col-lg-3">
          <a href="/topic_index/152" class="card">
            <img src="{{popularTopicsUrl}}OSHARecordkeeping.jpg" alt="OSHA Recordkeeping">
            <div class="card-body">
              <h5 class="card-title">OSHA Recordkeeping</h5>
            </div>
          </a>
        </div>
      </div>
      <h2>Topics by Industry</h2>
      <jjk-loader *ngIf="isLoading" [marginTop]="'20px'" [loadingText]="'Fetching Industries...'"></jjk-loader>
      <div class="row topicsByIndustry d-none d-md-flex">
        <div class="col col-12 col-sm-12 col-md-4 col-lg-3" *ngFor="let industry of industries;">
          <a [href]="getTopicIndexURL(industry.id)" class="card">
            <div class="card-body">
              <h5 class="card-title text-break">{{industry.displayText}}</h5>
            </div>
          </a>
        </div>
      </div>
      <div class="row topicsByIndustrySelect d-md-none">
        <jjk-select [searchable]="false" [options]="industries" [(selection)]="selectedIndustry" (selectionChange)="goToTopicIndex()"></jjk-select>
      </div>
    </div>
  </div>
</div>
