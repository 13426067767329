<div>
  <div style="padding-bottom:0px;">
    <h5 style="font-weight: 600;" id="authMigrationFormHeader">Security Enhancement Requirements</h5>
  </div>
  <hr style="width:33%; margin-bottom: 0px;">
  <div style="padding-top:0px; padding-bottom:25px">
    <p>Your Safety Management Suite account will now use the same login credentials as the following J. J. Keller sites.  <br>
      As a one-time verification, please enter the same password you use to access:<br></p>
      <ul>
        <li *ngFor="let item of nonSMSproducts | slice:0:3" style="font-weight: 600;">{{item}}</li>
      </ul>
      <p>Please note that any changes or updates to your credentials will be affected across all products.<br>
      Contact support at <a href="tel:833-813-7268">833-813-7268</a> or <a href="mailto:safetysupport@jjkeller.com">safetysupport&#64;jjkeller.com</a> for any questions.</p>
  </div>
</div>