import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { hideModal, newRandomId } from '@utilities/helpers';

@Component({
  selector: 'slide-out-modal',
  templateUrl: './slide-out-modal.component.html',
  styleUrls: ['./slide-out-modal.component.scss'],
})
export class SlideOutModalComponent implements OnDestroy {
  @Input() id: '';
  @Input() title = '';
  @Input() isDisabled = false;
  @Input() isConfirmDisabled = false;
  @Input() isNextVisible = false;
  @Input() isActionButtonEnabled = true;
  @Input() data: any; // placeholder for any info you want to pass
  @Input() cancelButtonText = 'Cancel';
  @Input() confirmButtonText = 'OK';
  @Input() nextButtonText = 'Add Another';
  @Input() autoClose = true;
  @Input() isRedConfirmButton = false;
  @Input() showLoader = false;
  @Input() modalObjName: string;
  @Input() isEdit: Boolean;
  @Input() isOpen;
  @Input() newRowData;
  @Output() updatedRecord = new EventEmitter();
  @Output() clearRecord = new EventEmitter();
    
  // When passed, it will validate your data on confirmation click and emit the onErrors event
  @Input() validateFunction: (data: any) => string[];
  @Output() onX: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onErrors: EventEmitter<string[]> = new EventEmitter<string[]>();

  // Secure id in case a component name is repeated in the screen
  alphaId = newRandomId(4);

  get ariaLabelId(): string {
    return `${this.id}Label`;
  }

  constructor() {
  }

  ngOnDestroy() {
    // Fix Browser back
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }

  onCancelClicked() {
    setTimeout(() => {
      this.onCancel.emit(this.data);
      this.close();
    }, 50);
  }

  onNextClicked() {
    this.onNext.emit(this.data);  
  }

  onXClicked() {
    this.onX.emit(this.data);
  }

  onConfirmClicked() {
    this.onConfirm.emit(this.data);
  }

  close() {
   hideModal($(`div.modal[alphaId='${this.alphaId}']`));
  }
}
