
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { ForgotPasswordComponent } from '@pages/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@pages/auth/reset-password/reset-password.component';
import { SignUpComponent } from '@pages/auth/signup/signup.component';
import { LoginSplitComponent } from './login/login-split.component';

const routes: Routes = [
    { path: AuthRoutes.Login.urlRoute, component: LoginSplitComponent },
    { path: AuthRoutes.Signup.urlRoute, component: SignUpComponent },
    { path: AuthRoutes.ForgotPassword.urlRoute, component: ForgotPasswordComponent },
    { path: AuthRoutes.ResetPassword.urlRoute, component: ResetPasswordComponent },
    { path: AuthRoutes.RequestResetPassword.urlRoute, component: ResetPasswordComponent },
  ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule { }
