import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from '@models/tree-node.model';

@Component({
  selector: 'jjk-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent {

  @Input() treeData: TreeNode[];

  public selectedData: any;

  @Input()
  get selectedOption() {
    return this.selectedData;
  }
  set selectedOption(val) {
    this.selectedData = val;
  }

  @Output() selectedOptionChange: EventEmitter<object | string> = new EventEmitter<object | string>();

  constructor() {
  }

  toggleChild(event: Event, node) {
    event.stopPropagation();
    node.opened = !node.opened;
  }

  onSelectChange($event) {
    this.selectedOptionChange.emit(this.selectedData);
  }

  onSelect(event: Event, option: TreeNode) {

    event.stopPropagation();
    if (option.enabled) {
      this.selectedData = option;
      this.selectedOptionChange.emit(this.selectedData);
    } else {
      this.selectedData = null;
    }
  }
}
