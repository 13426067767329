import { Injectable } from '@angular/core';
import { allValidExtension } from '../../../constants/valid-extensions';
import { isNullOrEmpty } from '@utilities/helpers';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {

  providedExtensions: string[] = [];
  validatedExtensions: string[] = [];

  constructor() {
  }

  /*
  This function receives a string of comma-concatenated extensions, such as '.pdf,.jpg'
  and validates that they exist in our global list of accepted extensions for uploading files
  in JJKeller. If no value is provided it returns the complete list, if values are provided it
  returns those items from the list that have a match. the list is returned as a comma-concatenated string
  */
  validateProvidedExtensions(extensions: string): string {
    this.validatedExtensions = [];
    if (isNullOrEmpty(extensions)) {
      return allValidExtension.join(',');
    } else {
      this.providedExtensions = extensions.split(',');
      this.providedExtensions = this.providedExtensions.map((e) => {
        return !e.startsWith('.') ? '.' + e : e;
      });
      allValidExtension.map((ve) => {
        if (this.providedExtensions.includes(ve)) { this.validatedExtensions.push(ve); }
      });
      return this.validatedExtensions.length > 0 ? this.validatedExtensions.join(',') : allValidExtension.join(',');
    }
  }

}
