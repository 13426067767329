import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { ApiFactory } from '@services/core/api-factory.class';

@Component({
    selector: 'jjkp-location-group-select',
    templateUrl: './location-group-select.component.html',
    styleUrls: ['./location-group-select.component.scss']
})
export class LocationGroupSelectComponent implements OnInit {

    constructor() { }

    @Input() isDisabled = false;
    private _selectedValue: any;
    get selectedValue(): any {
        return this._selectedValue;
    }
    @Input()
    set selectedValue(value: any) {
        if(this._selectedValue === value) {
            return;
        }
        this._selectedValue = value;
        this.selectedValueChange.emit(this._selectedValue);
        this.selectedItemChanged.emit(this.options.find(x => x.id === value));
    }
    @Output()
    selectedValueChange = new EventEmitter<any>();

    @Output()
    selectedItemChanged = new EventEmitter<any>();

    isDataGroupsEnabled = !LoggedInUserInfo.Instance.userInfo.disableDataGroup;
    options: any[] = [];
    isLoading = true;
    locations: any[];
    groups: any[];

    ngOnInit() {
        this.loadSelectData();
    }

    private async loadSelectData() {
        await this.loadOptions();
        this.options.sort((a, b) => {
            if (a.value === b.value) return 0;
            return a.value < b.value ? -1 : 1;
        });
        this.isLoading = false;
    }

    private loadOptions(): Promise<void> {
        return new Promise((resolve, reject) => {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.Location)
            .addRouteHint('LocationsAndGroups')
                .addSuccessHandler((l) => {
                    this.options = this.mapToSelectOptions(l);
                    resolve();
                }).addErrorHandler(() => {
                    reject();
                })
                .removePaging()
                .buildAndSend();
        });
    }

    private mapToSelectOptions(response: any[]) {
        return response
            .map((item) => {
                return {
                    id: item.id,
                    displayText: item.name,
                    value: item.name.toLowerCase(),
                    type: item.type,
                    icon: item.type.toLowerCase() === 'location' ? 'location-dot-solid' : 'buildings-sharp-solid'
                };
            });
    }
}
