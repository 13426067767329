import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { SplitioService } from '@services/splitio.service';
import { hideModal, newRandomId, showModal } from '@utilities/helpers';
import { IModalWindow } from './modal-windows.model';

@Component({
  selector: 'modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss'],
})
export class ModalWindowComponent implements OnInit, OnDestroy, IModalWindow {
  @Input() id = '';
  @Input() title = '';
  @Input() isDisabled = false;
  @Input() isConfirmDisabled = false;
  @Input() isNextVisible = false;
  @Input() isActionButtonEnabled = true;
  @Input() data: any; // placeholder for any info you want to pass
  @Input() cancelButtonText = 'Cancel';
  @Input() confirmButtonText = 'OK';
  @Input() nextButtonText = 'Next';
  @Input() autoClose = true;
  @Input() isRedConfirmButton = false;
  @Input() showCloseButton = true;
  @Input() showLoader = false;
  @Input() isActionButtonShow = true;

  // When passed, it will validate your data on confirmation click and emit the onErrors event
  @Input() validateFunction: (data: any) => string[];
  @Output() onX: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onErrors: EventEmitter<string[]> = new EventEmitter<string[]>();

  // Secure id in case a component name is repeated in the screen
  alphaId = newRandomId(4);

  get ariaLabelId(): string {
    return `${this.id}Label`;
  }

  constructor(private splitioService: SplitioService) {
  }

  ngOnInit() { }

  ngOnDestroy() {
    // Fix Browser back
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }

  onCancelClicked() {
    setTimeout(() => {
      this.onCancel.emit(this.data);
      this.close();
    }, 50);
  }

  onNextClicked() {
    this.onNext.emit(this.data);
  }

  onXClicked() {
    this.onX.emit(this.data);
    this.close();
  }

  async onConfirmClicked() {
    if (!this.validateFunction) {
      this.onConfirm.emit(this.data);
      if (this.autoClose) {
        this.close();
      }
    } else {
      const errors = this.validateFunction(this.data);
      if (errors.length > 0) {
        const naics2017WizardOn = (await this.splitioService.getToggle(TOGGLES.NAICS_2017_WIZARD)) === TREATMENT.ON;
        const startsWithNaicsError = errors[0].startsWith("NAICS Code is not a valid NAICS", 0);

        if(naics2017WizardOn && startsWithNaicsError)
          errors[0] = "NAICS Code is not a valid NAICS 2017 code"
        else
          errors[0] = "NAICS Code is not a valid NAICS 2012 code"
        this.onErrors.emit(errors);
      } else {
        this.onConfirm.emit(this.data);
        if (this.autoClose) {
          this.close();
        }
      }
    }
  }

  close() {
    hideModal($(`div.modal[alphaId='${this.alphaId}']`));
  }

  /**
   * @param contextData It can be any related data besides of the data property
   */
  show(contextData?: any) {
    showModal($(`div.modal[alphaId='${this.alphaId}']`));
  }
}
