import { isValidArray } from '@utilities/helpers';
import { IDynamicSelectOptions } from './dynamic-select-control-options.model';

export class DynamicSelectDataload {
  options: IDynamicSelectOptions[];

  constructor(optionsData: any[], idMember: string, displayTextMember: string, sortMember = '', sortDirection = 'asc', icon = '') {
    if (!isValidArray(optionsData)) {
      this.options = [];
      return;
    }

    const sortProperty = sortMember ? sortMember : displayTextMember;

    let options = optionsData;
    if (sortDirection === 'asc') {
      options = options.sort((a, b) => (a[sortProperty]) < (b[sortProperty]) ? -1 : 1);
    } else if (sortDirection === 'none') {
      // do not sort list
    } else {
      options = options.sort((a, b) => (a[sortProperty]) > (b[sortProperty]) ? -1 : 1);
    }

    this.options = options
      .map((x) => {
        return {
          id: x[idMember],
          icon,
          displayText: x[displayTextMember],
          disabled: x.hasOwnProperty('isActive') && !x.isActive ? (x.hasOwnProperty('showDisable') && x.showDisable ? false : x.isActive) : false,
          showDisable: x.hasOwnProperty('showDisable') ? x.showDisable : false,
          orig: x,
        };
      });
  }
}
