<div class="autocompleteFilter container mt-2 mb-2">
    <jjk-select class="selectComponent"
        [options]="valuesFromApi$"
        [typeAheadInput$]="entityInput$"
        [multiple]="true" 
        [searchViaApi]="true" 
        [showLoading]="loadingData" 
        [(selection)]="selectedValues"
        placeholderText="Type to search" 
        typeToSearchText="No items found"
        (selectedValuesChange)="onSelectedValue($event)"
        (close)="onClose()"
        (clear)="clearFilter()">
    </jjk-select>
</div>

