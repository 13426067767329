<div class="pageNav">
    <button  *ngIf="authForAdd" type="button" auth authFeature="{{authForAdd}}" class="btn btn-primary" [ngClass]="extraClassForPrimary" (click)="newItem($event)">{{addButtonDisplayText}}</button>
    <button  *ngIf="authForSecondary" type="button" auth authFeature="{{authForSecondary}}" class="btn btn-secondary" (click)="secondaryButtonAction($event)">{{secondaryButtonDisplayText}}</button>

    <!-- Any extra, page specific buttons/wizards/html added on page component will show here -->
    <ng-content></ng-content>

    <nav class="pageActionsMenu" *ngIf="showPageSubActions" [class.closed]="!isActionsMenuOpen">
        <div class="menuTitle active" (click)="isActionsMenuOpen = !isActionsMenuOpen">
            Actions <span class="fa fa-chevron-{{isActionsMenuOpen? 'up' : 'down'}}"></span>
        </div>
        <div class="menuItems" [class.hide]="!isActionsMenuOpen">
            <div class="menuItemGroup">
            <!-- Example actions from click thru. Specific actions to be created in upcoming PBIs. -->
            <span class="headingOnly hasSubmenu">Generate Report</span>
            <nav id="reportsMenu" class="subMenu">
                <a class="menuItem pdf" href="current-view-pdf.asp">Current View—PDF</a>
                <a class="menuItem csv" href="current-view-csv.asp">Current View—CSV</a>
            </nav>
            </div>
            <a class="menuItem import leavePage" href="import_data.asp">Import Data</a>
            <a class="menuItem export leavePage" href="export_data.asp">Export Data</a>
            <!-- ^^^Example actions from click thru.^^^ -->
        </div>
    </nav>

</div>