<div id="loadingIndicator" style="display: none;">
    <div class="loader"></div>
</div>
<div *ngIf=" iframe_Element?.src !== '' ">
    <iframe id="configurator" 
            name="configurator"
            class="csp-app-iframe"
            scrolling="auto"
            frameborder="0"
            [width]="width"
            [height]="height"
            (load)="actionClearEmit($event)">
    </iframe>
</div>
<div class="clear"></div>
<button id="labelDownloadingButton" (click)="labelDownloadingEmit()" class="invisible"></button>
<button id="labelDownloadedButton" (click)="labelDownloadedEmit()" class="invisible"></button>