import { DatePipe, DecimalPipe, LowerCasePipe, PercentPipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { Injectable, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MonthFormatPipe } from '@utilities/pipes/month-format.pipe';
import { NotApplicablePipe } from '@utilities/pipes/not-applicable.pipe';
import { NotPopulatedPipe } from '@utilities/pipes/not-populated.pipe';
import { YesNoSliderPipe } from '@utilities/pipes/yes-no-slider.pipe';

@Injectable()
export class DataGridPipeManager {

    constructor(private sanitizer: DomSanitizer) {}

    private _usLocale = 'en-US';

    _pipes = {
        titlecase: new TitleCasePipe(),
        uppercase: new UpperCasePipe(),
        lowercase: new LowerCasePipe(),
        notPopulated: new NotPopulatedPipe(this.sanitizer),
        date: new DatePipe(this._usLocale),
        yesNoSlider: new YesNoSliderPipe(this.sanitizer),
        monthFormat: new MonthFormatPipe(),
        number: new DecimalPipe(this._usLocale),
        notApplicable: new NotApplicablePipe(this.sanitizer),
        percent: new PercentPipe(this._usLocale),
    };

    applyPipe(value: string, pipeName: string, pipeArg) {
        if (pipeName && this.validateValue(value)) {
            return this.pipeTransform(value, pipeName, pipeArg);
        } else if (!this.validateValue(value)) {
            // If it is not a valid value, the "notPopulated" pipe is shown.
            return this.pipeTransform(value, 'notPopulated', pipeArg);
        } else {
            return value;
        }
    }

    pipeTransform(value: string, pipeName: string, pipeArg) {
        if (pipeName === 'date') {
            pipeArg = 'MM/dd/yyyy';
        }

        try {
            return (this._pipes[pipeName] as PipeTransform).transform(value, pipeArg);
        } catch {
            return this.pipeTransform('', 'notPopulated', pipeArg);
        }
    }

    validateValue(value: string) {
        return value !== null && value !== undefined && value !== '';
    }
}
