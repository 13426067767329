import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class NavigationService {

    constructor(private location: Location, private router: Router, private route: ActivatedRoute) { }

    navigateTo(targetUrl: string, overrideHistory = false) {
        if (!overrideHistory && window.history.length > 1 && window.history.state && window.history.state.navigationId > 1) {
            this.location.back();
        } else {
            if (targetUrl) {
                this.router.navigate([targetUrl]);
            } else {
                this.router.navigate([this.route.parent.url]);
            }
        }
    }

}
