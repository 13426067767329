import { Injectable, Injector, NgZone } from '@angular/core';
import { AutoIncrementingIdentifierDirective } from '../baseClasses/AutoIncrementingIdentifier';
import { IMessageDistributor } from '../interfaces/IMessageDistributor';
import { IMessageSender } from '../interfaces/IMessageSender';
import { IRegistration } from '../interfaces/IRegistration';
import { ITopicRecipient } from '../interfaces/ITopicRecipient';
import { MessageHandlerRegistration } from '../types/messageHandlerRegistration';
import { Message } from '../types/Messages/Message';

@Injectable()
export class MessageBusService extends AutoIncrementingIdentifierDirective
    implements IRegistration<ITopicRecipient>, IMessageSender {

    public static AppInjector: Injector;

    constructor(protected messageDistributor: IMessageDistributor, zone: NgZone) { super(); }

    // Save our listener collection by topic
    private topicSubscribers: Map<string, ITopicRecipient[]> = new Map<string, ITopicRecipient[]>();

    public messageProcessedNotifier: () => void;

    /** **********************************************************************************************************
     * This function will allow the registrationh of either a Topic recipient.
     * @param host This is the implementor of the given interface
     * @param parameters Any parameters needed to support the registration - Topics are an example.
     ***********************************************************************************************************/
    public register = (host: ITopicRecipient, parameters?: any[]) => {
        try {
            this.registerTopicRecipient(host, parameters);
        } catch (e) {
        }
    }

    public unregister = (host: ITopicRecipient, parameters?: any[]) => {
        try {
            this.unregisterTopicRecipient(host, parameters);
        } catch (e) {
        }
    }

    registerTopicRecipient = (host: ITopicRecipient, registrations: MessageHandlerRegistration<Message>[]): void => {
        if (!host || !registrations) {
            return;
        }
        try {
            registrations.forEach((registration) => {
                // Is this topic already registered?
                let topicListeners = this.topicSubscribers.get(registration.forTopic);
                if (!topicListeners) {
                    // The topic is new, so register it
                    topicListeners = new Array<ITopicRecipient>();
                    this.topicSubscribers.set(registration.forTopic, topicListeners);
                    // Since the listener collection is new, we know there is no registered host
                    topicListeners.push(host);
                } else {
                    // Only register the host if it doesn't already exist
                    if (!topicListeners.includes(host)) {
                        topicListeners.push(host);
                    }
                }
            });
        } catch (e) {
        }
    }

    unregisterTopicRecipient = (host: ITopicRecipient, topics?: string[]): void => {
        // If no topics are specified, it means remove from all topics
        if (!topics) {
            this.topicSubscribers.forEach((entry) => {
                const index = entry.indexOf(host);
                if (index >= 0) {
                    entry.splice(index, 1);
                }
            });
            return;
        }

        // Topics were specified, so remove from target topics only
        topics.forEach((topic) => {
            // get the specified topicListeners
            const topicListeners = this.topicSubscribers.get(topic);
            if (!topicListeners) {
                return;
            }

            // We must have the collection, so remove the host
            const hostIndex = topicListeners.indexOf(host);
            if (hostIndex < 0) {
                return;
            }

            // Found the host location in the array, so remove it
            topicListeners.splice(hostIndex, 1);
        });
    }

    public async send(msg: Message) {
        if (!msg.topics) {
            return;
        }

        if (this.messageProcessedNotifier && msg.isUserActivity) {
            this.messageProcessedNotifier();
        }

        // gather all recipients for all topics
        let receivers = new Array<ITopicRecipient>();

        msg.topics.forEach((topic) => {
            const ts = this.topicSubscribers.get(topic);
            if (ts) {
                receivers = receivers.concat(ts);
            }
        });

        if (receivers && receivers.length > 0) {
            this.messageDistributor.distributeTopics(receivers, msg);
        }
    }
}
