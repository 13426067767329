<div class="modal fade" [id]="id" [attr.alphaId]="alphaId" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" [id]="ariaLabelId">{{title}}</h1>
        <button *ngIf="showCloseButton" type="button" class="btn-close" (click)="onXClicked()" aria-label="Close" [disabled]="isDisabled">
        </button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <jjk-loader *ngIf="showLoader"></jjk-loader>
        <button *ngIf="cancelButtonText" type="button" class="btn btn-secondary" (click)="onCancelClicked()" [disabled]="isDisabled">{{cancelButtonText}}</button>
        <button type="button" class="btn" [ngClass]="isRedConfirmButton ? 'btn-danger' : 'btn-primary'" (click)="onConfirmClicked()" *ngIf="!isNextVisible && isActionButtonShow" [disabled]="!isActionButtonEnabled || isDisabled || isConfirmDisabled">{{confirmButtonText}}</button>
        <button type="button" class="btn btn-primary" (click)="onNextClicked()" *ngIf="isNextVisible" [disabled]="!isActionButtonEnabled || isDisabled">{{nextButtonText}}</button>
      </div>
    </div>
  </div>
</div>
