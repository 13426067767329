import { Directive, Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Injectable()
@Directive({
    selector: '[appIsNumericValidator][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: IsNumericValidator, multi: true },
    ],
})
export class IsNumericValidator implements Validator {
    validator: ValidatorFn;

    constructor() {
        this.validator = validateIsNumericFactory();
    }

    validate(c: UntypedFormControl) {
        return this.validator(c);
    }

    public validateIsNumeric(value: string): boolean {
        const regexp = new RegExp(/^-?\d+\.?\d*$/);
        if (value === '' || value === null || value === undefined) {
            return true;
        } else {
            return regexp.test(value);
        }
    }

}

// validation function
function validateIsNumericFactory(): ValidatorFn {
    return (c: AbstractControl) => {
        const regexp = new RegExp(/^-?\d+\.?\d*$/);
        const isValid = regexp.test(c.value);

        if (isValid || c.value === '' || c.value === null || c.value === undefined) {
            return null;
        } else {
            return { appIsNumericValidator: { valid: false } };
        }
    };
}
