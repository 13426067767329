import { Component } from '@angular/core';
import { AgFilterComponent } from 'ag-grid-angular';
import { AgPromise, IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';

@Component({
  selector: 'jjkp-ag-grid-custom-time-filter',
  templateUrl: './ag-grid-custom-time-filter.component.html'
})
export class AgGridCustomTimeFilterComponent implements AgFilterComponent {
  params: any;
  selectedTime1: string;
  selectedTime2: string;
  isSecondDateHidden = true;
  selectedCompare = 'equals';
  suppressGreaterThan = false;
  suppressLessThan = false;
  suppressNotEqual = false;
  suppressRange = false;
  isResetVisible = false;

  agInit(params: IFilterParams): void {
    this.params = params;
    this.suppressGreaterThan = this.params.suppressGreaterThan ? this.params.suppressGreaterThan : false;
    this.suppressLessThan = this.params.suppressLessThan ? this.params.suppressLessThan : false;
    this.suppressNotEqual = this.params.suppressNotEqual ? this.params.suppressNotEqual : false;
    this.suppressRange = this.params.suppressRange ? this.params.suppressRange : false;
    this.isResetVisible = this.params?.buttons?.some((x: string) => x === 'reset');
  }

  isFilterActive(): boolean {
    return this.areInputTimesValid();
  }
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const rowTime = this.params.valueGetter(params.node)?.trim();
    const time1 = this.getTimeIfValid(this.selectedTime1?.trim());
    const time2 = this.getTimeIfValid(this.selectedTime2?.trim());

    if (this.getTimeIfValid(rowTime)) {
      if (this.selectedCompare === 'equals') {
        return rowTime === time1;
      }
      else if (this.selectedCompare === 'greaterThan') {
        return rowTime > time1;
      }
      else if (this.selectedCompare === 'lessThan') {
        return rowTime < time1;
      }
      else if (this.selectedCompare === 'notEqual') {
        return rowTime !== time1;
      }
      else if (this.selectedCompare === 'inRange') {
        // make sure the range can go both ways (between the values of either date)
        return ((rowTime > time1) && (rowTime < time2)) || ((rowTime < time1) && (rowTime > time2));
      }
    }
    else if (this.selectedCompare === 'notEqual')
    {
      return true; // can return true for not populated or invalid times if they're looking for not equal to selected time
    }
    else
    {
      return false;
    }
  }
  getModel() {
    return { filter: this.selectedTime1, filterTo: this.selectedTime2, filterType: 'time', type: this.selectedCompare };
  }
  setModel(model: any): void | AgPromise<void> {
    if (model)
    {
      if (model.filter)
      {
        this.selectedTime1 = model.filter;
      }
      if (model.filterTo)
      {
        this.selectedTime2 = model.filterTo;
      }
      if (model.selectedCompare)
      {
        this.selectedCompare = model.selectedCompare;
      }
    }
    else {
      this.clearFilter();
    }
  }
  onChange(): void {
    this.params.filterChangedCallback();
  }
  onCompareChange() {
    if (this.selectedCompare === 'inRange') {
      this.isSecondDateHidden = false;
    }
    else {
      this.isSecondDateHidden = true;
    }
    this.onChange();
  }
  clearFilter() {
    this.selectedTime1 = null;
    this.selectedTime2 = null;
    this.selectedCompare = 'equals';
    this.isSecondDateHidden = true;
    this.onChange();
  }
  areInputTimesValid(): boolean {
    if ((this.selectedTime1) && ((this.selectedCompare !== 'inRange') || (this.selectedCompare === 'inRange' && this.selectedTime2))) {
      let valid = false;

      if (this.selectedTime1) {
        valid = this.getTimeIfValid(this.selectedTime1.trim()) !== null;
      }

      if (valid && this.selectedCompare === 'inRange' && this.selectedTime2) {
        valid = this.getTimeIfValid(this.selectedTime2.trim()) !== null;
      }

      return valid;
    }
    else {
      return false;
    }
  }
  getTimeIfValid(time: string): string {
    try {
      if (time) {
        const splitTime = time.split(':');
        if (splitTime.length === 2 && splitTime[0].length <= 2 && splitTime[1].length <= 2) {
          const hour = +splitTime[0];
          const min = +splitTime[1];

          if (!isNaN(hour) && !isNaN(min)) {
            return splitTime[0].padStart(2, '0') + ':' + splitTime[1].padStart(2, '0');
          }
        }
      }
      return null;
    }
    catch (error) {
      return null;
    }
  }
}
