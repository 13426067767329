import { Pipe, PipeTransform } from '@angular/core';
import { getPropertyIfExists, isNullOrUndefined } from '@utilities/helpers';

@Pipe({ name: 'buildRouterLink' })
export class BuildRouterLinkPipe implements PipeTransform {
    transform(value: any, property: any): any {
        return this.buildRouterLink(value, property);
    }

    private buildRouterLink(row: any, childComponent: any, footer?: boolean) {
        if (!row) {
            return '';
        }
        // The Field property is used to obtain the name of the column from which it is expected to obtain the corresponding value for the selected row.
        let columnComponent = childComponent['field'];
        let columnComponent2 = childComponent['field2'];
        let columnComponent3 = childComponent['field3'];
        let routerLink = childComponent['routerLink'];

        if (footer) {
            columnComponent = childComponent['footerValue'];
            routerLink = childComponent['footerRouterLink'];
        }

        if (isNullOrUndefined(columnComponent)) {
            return routerLink;
        }

        if (columnComponent.indexOf('.') >= 0) {
            columnComponent = getPropertyIfExists(row, columnComponent);

            if (!isNullOrUndefined(columnComponent)) {
                return !isNullOrUndefined(routerLink) && routerLink !== '' ? `${routerLink}/${columnComponent}` : columnComponent;
            }
        } else {
            columnComponent = !isNullOrUndefined((row)[columnComponent]) ? (row)[columnComponent] : columnComponent;
            columnComponent2 = !isNullOrUndefined((row)[columnComponent2]) ? (row)[columnComponent2] : columnComponent2;
            columnComponent3 = !isNullOrUndefined((row)[columnComponent3]) ? (row)[columnComponent3] : null;

            let additionalParams = !isNullOrUndefined(columnComponent2) ? `${columnComponent}/${columnComponent2}` : `${columnComponent}`;
            if(!isNullOrUndefined(columnComponent3)){
                additionalParams = additionalParams + `/${columnComponent3}`
            }
            return !isNullOrUndefined(routerLink) && routerLink !== '' ? `${routerLink}/${additionalParams}` : columnComponent;
        }
    }
}
