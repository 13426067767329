
<div class="row">
    <sms-breadcrumb [breadcrumbItems]="breadcrumbItems"></sms-breadcrumb>
</div>
    <div [class.showMenu]="showMenu" [class.extraSmall]="extraSmall">
    <div class="topWrapper">
        <div class="titleBlock">
            <h1>{{title}}</h1>
            <div id="pageActionsMenuIcon" alt="Click to display the menu." [class.open]="open" (click)="open = !open">
            <span></span>
            <span></span>
            <span></span>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="page-edit-nav col-md-12">
            <div class="pageActionsMenu" [id]="'pageActionsMenu'" [class.active]="open">
                <!-- action links -->
                <ng-content>
                </ng-content>
            </div>
        </div>
    </div>
</div>