
<div class="containerMaintenance">
    <div class="centerText">
      <img src="assets/images/exclamation-triangle.svg" alt="Exclamation">
      <p>J. J. Keller Safety Management Suite is currently down for maintenance and will be available soon. <br>
         For questions, please contact Technical Support at  <a href="mailto:SafetySupport@jjkeller.com">SafetySupport&#64;jjkeller.com</a></p> 
    </div>
</div>


