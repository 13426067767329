
<button class="btn btn-link btn-sm no-padding bold" (click)="openWindow()">Accommodations</button> |

<modal-window [id]="modalId" [title]="title" [confirmButtonText]="'Save'" (onConfirm)="onSave()" (onCancel)="onCancel()" (onX)="onCancel()" [autoClose]="false">
    <form *ngIf="editForm" [formGroup]="editForm">

      <div class="form-group" [ngClass]="showErrors && editForm.get('statusId').errors?.required ? 'form-group-error' : ''">
        <label class="form-label" for="statusId"><strong class="req">Accommodation Status</strong></label>
        <jjk-select [options]="statusOptions" id="statusId" [(selection)]="selectedStatus" required
            [ngClass]="showErrors && editForm.get('statusId').errors?.required ? 'field-error' : ''"></jjk-select>
      </div>

    </form>
</modal-window>