import { Platform } from '@angular/cdk/platform';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeInputComponent),
      multi: true,
    },
  ],
})
export class TimeInputComponent implements OnInit, ControlValueAccessor {
  hours: any[];
  minutes: any[];
  ampm = [{ value: 'AM' }, { value: 'PM' }];

  @Input() use24HourTime = false;

  private isDisabled = false;
  @Input()
  public get disabled() {
    return this.isDisabled;
  }
  public set disabled(value) {
    this.isDisabled = value === null ? false : true;
  }

  private _hourSelected: any = null;
  public get hourSelected() {
    return this._hourSelected;
  }
  public set hourSelected(value) {
    this._hourSelected = value;
    if (!this._minutesSelected) {
      this.minutesSelected = this.minutes[0];
    }
    this.change();
  }

  private _minutesSelected: any = null;
  public get minutesSelected() {
    return this._minutesSelected;
  }
  public set minutesSelected(value) {
    this._minutesSelected = value;
    this.change();
  }

  private _ampmSelected: any = this.ampm[0];
  public get ampmSelected() {
    return this._ampmSelected;
  }
  public set ampmSelected(value) {
    this._ampmSelected = value;
    this.change();
  }

  private _value: any;
  @Input()
  public get value(): any {
    return this._value;
  }
  public set value(value: any) {
    // UTC?
    if (value) {
      this._value = moment(value, 'HH:mm').format('HH:mm');
    }
    else {
      this._value = null;
    }
    this.setTime();
    if (this.onChange) {
      this.onChange(this._value);
    }
  }

  onChange: (value: any) => void;
  onTouch: () => void;

  constructor(public platform: Platform) {
    if (this.use24HourTime) {
      this.hours = Array(24).fill(0).map((x, i) => {
        return { value: `${x + i}`.padStart(2, '0') };
      });
    } else {
      // Map method required two parameters so even when x is not useful we can't get rid of it
      this.hours = Array(12).fill(0).map((x, i) => {
        return { value: `${i + 1}`.padStart(2, '0') };
      });
    }
    this.minutes = Array(60).fill(0).map((x, i) => {
      return { value: `${x + i}`.padStart(2, '0') };
    });
  }

  ngOnInit() { }

  resetTime() {
    this._hourSelected = null;
    this._minutesSelected = null;
    this._ampmSelected = this.ampm[0];
    this.change();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  setTime() {
    this._minutesSelected = this.minutes.find((m) => m.value === moment(this.value, 'HH:mm').format('mm'));
    const hour = moment(this.value, 'HH:mm').format('HH');
    if (this.use24HourTime) {
      this._hourSelected = this.hours.find((h) => h.value === hour);
    } else {
      if (+hour >= 12) {
        this._ampmSelected = this.ampm.find((ampm) => ampm.value === 'PM');
        this._hourSelected = this.hours.find((h) => h.value === (hour === '12' ? hour : `${+hour - 12}`.padStart(2, '0')));
      } else {
        this._ampmSelected = this.ampm.find((ampm) => ampm.value === 'AM');
        this._hourSelected = this.hours.find((h) => h.value === (+hour === 0 ? '12' : hour));
      }
    }
  }

  change() {
    if (this.onTouch) {
      this.onTouch();
    }
    if (this.hourSelected && this.minutesSelected) {
      if (this.use24HourTime) {
        this._value = `${this.hourSelected.value}:${this.minutesSelected.value}`;
      } else {
        let hour = '';
        if (this.ampmSelected.value === 'PM') {
          hour = this.hourSelected.value === '12' ? this.hourSelected.value : `${+this.hourSelected.value + 12}`;
        } else {
          hour = this.hourSelected.value === '12' ? '00' : this.hourSelected.value;
        }
        this._value = hour + ':' + this.minutesSelected.value;
      }
    } else {
      this._value = null;
    }
    if (this.onChange) {
      this.onChange(this._value);
    }
  }
}
