import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { environment } from '@env/environment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { AuthRoutes } from '@pages/auth/auth-routes.const';
import { ConvertApiErrorToAlertMessage } from '@pages/auth/helpers';
import { ApiFactory } from '@services/core/api-factory.class';
import { NavigationService } from '@services/navigation.service';
import { isNullOrEmpty } from '@utilities/helpers';
import { EmailAddressValidatorAsync } from '@utilities/validators/email-address.validator';

@Component({
    selector: 'jjkp-migrate-login-form',
    styleUrls: ['./migrate-login-form.component.scss'],
    templateUrl: './migrate-login-form.component.html',
})
export class MigrateLoginFormComponent extends ValidatableFormBaseDirective implements OnInit {
    @Output() cancelClicked = new EventEmitter();

    showHeader = false;
    showTerms = false;
    showSignup = false;
    showForgotLink= false;

    refireRedirectCheck = false;
    alertText = '';
    showAlert = false;
    isProcessingRequest = false;
    forgotPasswordRoute = `/${AuthRoutes.ForgotPassword.urlRoute}`;
    addToSms = false;
    inviteToken: string;

    constructor(private formBuilder: UntypedFormBuilder,
                private navigator: NavigationService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.buildForm();
        this.getControl('email').setValue(LoggedInUserInfo.Instance.userInfo.user.email);
    }

    navigateToSignUpPage() {
        this.navigator.navigateTo(AuthRoutes.Signup.urlRoute, true);
    }

    private buildForm() {
        const emailAddress = window.history.state && window.history.state.emailAddress ? window.history.state.emailAddress : null;

        this.form = this.formBuilder.group({
            email: [emailAddress, {validators: Validators.required, asyncValidators: EmailAddressValidatorAsync()}],
            password: [null, Validators.required]
        });
    }

    protected async submitForm(eventData?: any) {
        this.isProcessingRequest = true;
        const credentials = {
            emailAddress: this.getControl('email').value,
            password: this.getControl('password').value
        };

        let isGood = false;

        this.isProcessingRequest = true;
        await this.MigrateUserToOktaGroup(credentials)
        .then(() =>
        {
            isGood = true;
        })
        .catch(errorMessage => {
            this.setAlert(ConvertApiErrorToAlertMessage(errorMessage));
        })
        .finally(() => this.isProcessingRequest = isGood);

        if (isGood) {
            this.isProcessingRequest = true;
            await this.LinkOktaIdToSMS(credentials)
            .then(() =>
            {
                //emit a "good to go" so parent modal can close
                this.cancelClicked.emit();
            })
            .catch(errorMessage => {
                this.setAlert(ConvertApiErrorToAlertMessage(errorMessage));
            })
            .finally(() => this.isProcessingRequest = false);
        }
    }

    async showResponseMessage(errorMessage){
        if (isNullOrEmpty(errorMessage)) return;

        this.setAlert('The username or password provided in the request are invalid.');

        this.isProcessingRequest = false;
    }

    async MigrateUserToOktaGroup(user: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiFactory.saveNewEntity(ApiEntityTypesEnum.Security, user)
                .addRouteHint("Migrate/User/Group")
                .addSuccessHandler(() => {
                resolve();
                })
                .addErrorHandler((response) => {
                reject(response.errorData[0].errorMessage);
                })
                .removePaging()
                .buildAndSend();
            });
    }

    async LinkOktaIdToSMS(user: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiFactory.saveNewEntity(ApiEntityTypesEnum.Security, user)
                .addRouteHint("Migrate")
                .addSuccessHandler(() => {
                resolve();
                })
                .addErrorHandler((response) => {
                reject(response.errorData[0].errorMessage);
                })
                .removePaging()
                .buildAndSend();
            });
    }

    setAlert(message: string) {
        this.alertText = message;
        this.showAlert = true;
    }

    closeForm() {
        this.cancelClicked.emit();
    }
}
