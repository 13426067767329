import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'jjkp-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent  
{

  constructor() 
  {  }
}
