<div *ngIf="!groupingRow" class="actions-custom-column-" style="text-decoration: none;">
<button mat-button [matMenuTriggerFor]="topLevel" class="top-nav">
    <a ng-class="showActionsMenu ? 'null' : 'ellipsis'" id="editActions">
        <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
    </a>
</button>

<mat-menu #topLevel="matMenu" #menuTrigger>
    <div class="dropdown-header">
        <p>Actions</p>
    </div>
    <ng-container *ngFor="let dropdownItem of customColumnParams;">
        <ng-container *featureToggle="dropdownItem.featureToggle">
            <ng-container *ngIf="dropdownItem.field || dropdownItem.routerLink" >
                <button mat-menu-item *ngIf="!dropdownItem.subMenu && dropdownItem.visible" (click)="onChildComponentActionEmit(dropdownItem.displayText)"
                [routerLink]="row | buildRouterLink:dropdownItem"><span *ngIf="dropdownItem.icon" [innerHTML]="dropdownItem.icon"></span>
                    {{dropdownItem.displayText}}</button>
            </ng-container>
            <ng-container *ngIf="!dropdownItem.field && !dropdownItem.routerLink && !dropdownItem.subMenu">
                <button mat-menu-item *ngIf="!dropdownItem.subMenu && dropdownItem.visible" (click)="onChildComponentActionEmit(dropdownItem.displayText)"><span *ngIf="dropdownItem.icon" [innerHTML]="dropdownItem.icon"></span>
                    {{dropdownItem.displayText}}</button>
            </ng-container>
            <ng-container *ngIf="dropdownItem.subMenu && dropdownItem.visible">
                <button mat-menu-item *ngIf="dropdownItem.subMenu && dropdownItem.visible" [matMenuTriggerFor]="subMenu"><span *ngIf="dropdownItem.icon" [innerHTML]="dropdownItem.icon"></span>
                    {{dropdownItem.displayText}}</button>
            </ng-container>
        </ng-container>
        <mat-menu #subMenu="matMenu" >
            <ng-container *ngFor="let subDropdownItem of dropdownItem.subMenu;">
                <ng-container *featureToggle="subDropdownItem.featureToggle">
                    <ng-container *ngIf="(subDropdownItem.field || subDropdownItem.routerLink) && subDropdownItem.visible" style="transform: translateY(-8px);">
                        <button mat-menu-item (click)="onChildComponentActionEmit(dropdownItem.displayText, subDropdownItem, subDropdownItem.displayText)"><span *ngIf="subDropdownItem.icon" [innerHTML]="subDropdownItem.icon"></span>
                            {{subDropdownItem.displayText}}</button>
                    </ng-container>
                    <ng-container *ngIf="(!subDropdownItem.field && !subDropdownItem.routerLink) && subDropdownItem.visible" style="transform: translateY(-8px);">
                        <button mat-menu-item (click)="onChildComponentActionEmit(dropdownItem.displayText, null, subDropdownItem.displayText)"><span *ngIf="subDropdownItem.icon" [innerHTML]="subDropdownItem.icon"></span>
                            {{subDropdownItem.displayText}}</button>
                    </ng-container>
                </ng-container>
            </ng-container>
        </mat-menu>
    </ng-container>
</mat-menu>
</div>