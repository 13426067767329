export interface IAlert {
    id: string;
    area: AlertArea;
    type: AlertType;
    message: string;
}

export enum AlertArea {
    All,
    Audits,
    CompanyData,
    Incidents,
    Modals,
    PlansAndPolicies,
    RegSense,
    Settings,
    Tasks,
    Training,
    Webcasts,
    Accordions,
}

export enum AlertType {
    Success,
    Info,
    Warning,
    Danger,
}
