import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import { Platform, PlatformModule } from '@angular/cdk/platform';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as $ from 'jquery';
import { SharedModule } from '@components/shared.module';
import { SystemMessageButtonComponent } from '@components/v2/message-button/system-message-button.Component';
import { RootRoutes } from '@constants/settings/root-routes.const';
import { environment } from '@env/environment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { AuthModule } from '@modules/auth/auth.module';
import { JJKMessagingModule } from '@modules/messaging/jjk-messaging.module';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { SmsNavigationManager } from '@modules/navigation/sms-navigation-manager.class';
import { OKTA_CONFIG, OktaAuthGuard, OktaAuthModule, OktaCallbackComponent } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { PasswordExpireModalComponent } from '@pages/auth/password-expire-modal/password-expire-modal.component';
import { DashboardComponent } from '@pages/dashboard/home/dashboard.component';
import { JjkAccountMigrationComponent } from '@pages/layout/jjk-account-migration/jjk-account-migration.component';
import { AuthFooterComponent } from '@pages/layout/layout-auth/auth-footer/auth-footer.component';
import { AuthHeaderComponent } from '@pages/layout/layout-auth/auth-header/auth-header.component';
import { AuthMigrationFormComponent } from '@pages/layout/layout-auth/auth-migration/auth-migration-form.component';
import { LayoutFooterComponent } from '@pages/layout/layout-footer/layout-footer.component';
import { LayoutHeaderComponent } from '@pages/layout/layout-header/layout-header.component';
import { LayoutImpersonationModalComponent } from '@pages/layout/layout-impersonation-modal/layout-impersonation-modal.component';
import { LayoutMenuOptionsComponent } from '@pages/layout/layout-menu-options/layout-menu-options.component';
import { LayoutMessageComponent } from '@pages/layout/layout-message/layout-message.component';
import { LayoutProfileMenuComponent } from '@pages/layout/layout-profile-menu/layout-profile-menu.component';
import { LayoutSearchInputComponent } from '@pages/layout/layout-search-input/layout-search-input.component';
import { LayoutSideNavComponent } from '@pages/layout/layout-side-nav/layout-side-nav.component';
import { LayoutTimeoutModalComponent } from '@pages/layout/layout-timeout-modal/layout-timeout-modal.component';
import { OktaFirstLoginModalComponent } from '@pages/layout/okta-first-login-modal/okta-first-login-modal.component';
import { ProfileFormComponent } from '@pages/layout/profile-modal/profile-form.component';
import { ProfileModalComponent } from '@pages/layout/profile-modal/profile-modal.component';
import { TouModalComponent } from '@pages/layout/tou-modal/tou-modal.component';
import { PrintPageComponent } from '@pages/print-page/print-page.component';
import { TopicsDashboardComponent } from '@pages/topics/topics-dashboard/topics-dashboard.component';
import { ApiManagerService } from '@services/api-manager.service';
import { ApplicationInsightsService } from '@services/application-insights.service';
import { AuthService } from '@services/auth.service';
import { ApiMasterService } from '@services/core/api-master-service.class';
import { ApiPrePostProcessingMiddleware } from '@services/core/api-pre-post-processing-middleware.service';
import { ApiTemplateManager } from '@services/core/api-template-manager.class';
import { CacheInvalidationEngine } from '@services/core/CacheInvalidationEngine.service';
import { CachingInterceptorService } from '@services/core/CachingInterceptor.service';
import { EndpointBroker } from '@services/core/endpoint-broker.service';
import { HttpCacheService } from '@services/core/HttpCache.service';
import { HttpClientWithCache } from '@services/core/httpClient-with-Cache.class';
import { ErrorHandlerService } from '@services/error-handler.service';
import { FeatureAuthService } from '@services/feature-auth.services';
import { GoogleTagManagerService } from '@services/google-tag-manager.service';
import { ImpersonationService } from '@services/impersonation.service';
import { IncidentApiService } from '@services/incident-api.service';
import { LocationsApiService } from '@services/location-api.service';
import { MenuService } from '@services/menu.service';
import { MessageDateAugmentationService } from '@services/message-date-augmentation.service';
import { PimcoreService } from '@services/pimcore.service';
import { NewRESTLocationsApiService } from '@services/RESTFULlocation-api.service';
import { SessionManagementService } from '@services/session.management.service';
import { SimpleCustomDataApiService } from '@services/simple-custom-data-api.service';
import { SplitioService } from '@services/splitio.service';
import { TimeoutInterceptor } from '@services/Timeout-Interceptor';
import { WindowRefService } from '@services/windowref.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import config from './app.config';
import { MaintenanceComponent } from './ui-elements/pages/auth/maintenance/maintenance.component';

// Third Party Libraries
// App level Modules, Routing, and Components
@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        LayoutFooterComponent,
        LayoutHeaderComponent,
        LayoutImpersonationModalComponent,
        LayoutMenuOptionsComponent,
        LayoutMessageComponent,
        LayoutProfileMenuComponent,
        LayoutSearchInputComponent,
        LayoutSideNavComponent,
        LayoutTimeoutModalComponent,
        AuthFooterComponent,
        AuthHeaderComponent,
        AuthMigrationFormComponent,
        PrintPageComponent,
        ProfileModalComponent,
        PasswordExpireModalComponent,
        ProfileFormComponent,
        SystemMessageButtonComponent,
        TopicsDashboardComponent,
        TouModalComponent,
        OktaFirstLoginModalComponent,
        JjkAccountMigrationComponent,
        MaintenanceComponent,
    ],
    imports: [
        FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
        AppRoutingModule,
        AuthModule,
        BrowserAnimationsModule,
        FormsModule,
        MatMomentDateModule,
        NgxChartsModule,
        HttpClientModule,
        JJKMessagingModule.forRoot(),
        ReactiveFormsModule,
        SharedModule,
        OktaAuthModule,
        PlatformModule
    ],
    providers: [
        ApiManagerService,
        ApiMasterService,
        ApiPrePostProcessingMiddleware,
        ApiTemplateManager,
        ApplicationInsightsService,
        AuthService,
        CacheInvalidationEngine,
        EndpointBroker,
        GoogleTagManagerService,
        HttpClientWithCache,
        ImpersonationService,
        LocationsApiService,
        IncidentApiService,
        MenuService,
        MessageDateAugmentationService,
        NewRESTLocationsApiService,
        PimcoreService,
        SimpleCustomDataApiService,
        SplitioService,
        FeatureAuthService,
        SessionManagementService,
        SmsNavigationManager,
        ErrorHandlerService,
        WindowRefService,
        HttpCacheService,
        [{provide: ErrorHandler, useClass: ErrorHandlerService}],
        [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
        [{ provide: HTTP_INTERCEPTORS, useClass: CachingInterceptorService, multi: true }],[
            {
              provide: OKTA_CONFIG,
              useFactory: () => {
                const oktaAuth = new OktaAuth(config.oidc);
                return {
                  oktaAuth,
                  onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
                    const triggerLogin = async () => {
                      await oktaAuth.signInWithRedirect();
                    };
                    if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
                      // App initialization stage
                      triggerLogin();
                    }
                  }
                }
              }
            },
          ],
    ],
    bootstrap: [AppComponent],
})
export class AppModule {

    /* eslint-disable @typescript-eslint/no-unused-vars */
    constructor(
        i: Injector,
        private ams: ApiMasterService,
        private nav: SmsNavigationManager,
        private msgDateSvc: MessageDateAugmentationService,
        private sessionSvc: SessionManagementService,
        private authService: AuthService,
        private appInsights: ApplicationInsightsService,
        private platform: Platform) {

        MessageBusService.AppInjector = i;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}

export const AllRoutes = {
    ...RootRoutes,
};
