import { environment } from '@env/environment';
import { ApplicationInsightsService } from '@services/application-insights.service';

export abstract class LoggerService {
    // Helper functions
    private static isInputUndefind = (input: any): boolean => {
        if (typeof input === 'undefined') {
            return true;
        }
        return false;
    }

    private static isProd = (): boolean => {
        if (environment.environmentName === 'environmentProd') {
            return true;
        }
        return false;
    }

    private static findLoggerServiceConditionalAnswerString = (input: any): string => {
        let prod = true;
        let isUndefined = true;

        isUndefined = LoggerService.isInputUndefind(input);
        prod = LoggerService.isProd();

        if (prod && !(isUndefined)) {
            return 'SafeProd';
        } else if (!(isUndefined)) {
            return 'SafeNotProd';
        } else {
            return 'NotSafeNotProd';
        }
    }

    private static logConsole = (consoleDecisionStr: string, errorOrMsg: any = null): void => {
        if (consoleDecisionStr === 'SafeProd') {
            return;
        }
        console.log(errorOrMsg.toString());
    }

    private static executeTrace = (msg: string, object: any = null, service: ApplicationInsightsService): void => {
        service?.trace(msg, object);
    }

    // Main functions
    static trace(msg: string, object: any = null) {
        try {
            const appInsights = ApplicationInsightsService.Instance;
            if ((LoggerService.findLoggerServiceConditionalAnswerString(msg)) === 'SafeProd') {
                LoggerService.executeTrace(msg, object, appInsights);
                return;
            }
            LoggerService.logConsole('safeAndNotProd', msg);
        } catch (e) {
            LoggerService.logConsole('Error logging trace to AI: ' + e.toString());
        }
    }

    static error = (err: any): void => {
        try {
            const appInsights = ApplicationInsightsService.Instance;
            const answerString = LoggerService.findLoggerServiceConditionalAnswerString(err.toString());
            if (answerString === 'SafeNotProd' || answerString === 'NotSafeNotProd') {
                LoggerService.logConsole(answerString, err);
            } else {
                appInsights?.error(err);
            }
        }
        catch (e) {
            console.log('Error logging error to AI: ' + e.toString());
        }
    }
}
