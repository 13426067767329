<div class="searchResults">
  <ng-container *ngIf="dataExists(); then showItems; else showEmpty"></ng-container>
  <ng-template #showItems>
  <ng-container *ngFor="let index of alpha">
  <div *ngIf="showEmptyIndexes || dataExistsFor(index)" [id]="index === '#' ? 'numbers' : index" class="alphaNumericGroup">
    <div class="alphaNumericGroupHeading">{{ index }}</div>
    <a *ngFor="let data of getItemsFor(index)" class="topicItem" [routerLink]="getLinkFor(data)">{{ descriptionField ? descriptionField(data) : '' }}</a>
  </div>
  </ng-container>
  </ng-template>
  <ng-template #showEmpty>
  <div class="alphaNumericGroup">
    <p>No Records Exist</p>
  </div>
  </ng-template>
</div>
