import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { DataGridColumnCustomActionComponent } from '@components/datagrid-column-custom-action/datagrid-column-custom-action.component';

@Component({
    selector: 'jjk-datagrid-column-custom-link',
    template: `<ng-content></ng-content>`,
    providers: [{ provide: DataGridColumnCustomActionComponent, useExisting: forwardRef(() => DataGridColumnCustomLinkComponent) }],
})
export class DataGridColumnCustomLinkComponent extends DataGridColumnCustomActionComponent {

    @Input() public displayText: string = null;
    @Input() public routerLink: string = null;
    @Input() public field: string = null;
    @Input() public queryParams: any = null;
    @Input() public queryParamsOnlyWithEmptyField = true;
    @Input() public authFeature: string = null;

    @Output() action = new EventEmitter();

    ObjectArray: any[];

    constructor() {
        super();
        this.componentType = 'link';
    }

}
