import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'auth-unauthorized',
  templateUrl: './auth-unauthorized.component.html',
})

export class AuthUnauthorizedComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
