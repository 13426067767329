import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { HazardCategory } from '@models/entity-models/autogenerated/hazardcategory';
import { HazardType } from '@models/entity-models/autogenerated/hazardtype';
import { HazardViewModel } from '@models/entity-models/sds-management/hazard-view-model';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { isNullOrEmpty, sortByProperty } from '@utilities/helpers';

@Component({
  selector: 'add-edit-hazard-form',
  templateUrl: './add-edit-hazard-form.component.html',
  styleUrls: ['../slide-out-modal.component.scss'],
})
export class AddEditHazardFormComponent extends ValidatableFormBaseDirective implements OnInit {

  @Input() isEdit: Boolean;
  @Input() isDelete: Boolean;
  @Input() isView: Boolean;
  @Input() isAdd: Boolean;
  @Input() record: HazardViewModel;
  @Input() sdsId: string;
  @Output() updatedRecord = new EventEmitter();
  @Output() clearRecord = new EventEmitter();
  @Output() selectedHazard: HazardViewModel[] = [];
  @Output() textChange = new EventEmitter();

  title: string;
  otherHazard = false;
  showErrors: boolean;
  hazardOptions: any[] = [];
  categoryOptions: any[] = [];
  processing = false;
  isOtherHazard = false;

  private _selectedHazardType = '';
  get selectedHazardType() {
    return this._selectedHazardType;
  }
  set selectedHazardType(val) {
    this._selectedHazardType = val;
    this.form.patchValue({
      hazardTypeId: this._selectedHazardType,
      hazardType: this.hazardOptions.find(t => t.id == this._selectedHazardType)?.displayText
    });
    this.isOtherHazard = this._selectedHazardType == '30b80f65-c4a4-4296-bbb5-a4b6bb91ace4' ? true : false;
    if (this.isOtherHazard) {
      if(isNullOrEmpty(this.form?.controls['otherHazardText'].value)){
     this.form?.controls['otherHazardText'].setValidators(Validators.required);
     this.form.get('otherHazardText').enable();
      }
    } else {
      this.form?.controls['otherHazardText'].clearValidators();
      this.form.patchValue({
        otherHazardText: ''
      });
      this.form.get('otherHazardText').disable();
    }
    this.form?.controls['otherHazardText'].updateValueAndValidity();
  }

  private _selectedCategory = '';
  get selectedCategory() {
    return this._selectedCategory;
  }
  set selectedCategory(val) {
    this._selectedCategory = val;
    this.form.patchValue({
      hazardCategoryId: this._selectedCategory,
      hazardCategory: this.categoryOptions.find(c => c.id == this._selectedCategory)?.displayText
    });
  }

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  async ngOnInit() {
    this.buildForm();
    this.getCategoryOptions();
    this.getTypeOptions();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.isEdit) {
      this.setUpEditPage();
    }
    else if (this.isView) {
      this.setUpViewPage();
    }
    else if (this.isDelete) {
      this.setUpDeletePage();
    }
  }
  onTextChange(text: any) {
    this.form.patchValue({otherHazardText : text.target.value});
  }
  setUpAddPage() {
    this.title = `Add Hazard`;
    this.selectedHazardType = '';
    this.selectedCategory = '';
    this.form.patchValue({
      id: Guid.EMPTY,
      globalSafetyDataSheetId: this.sdsId,
      hazardTypeId: '',
      hazardType: '',
      hazardCategoryId: '',
      hazardCategory: '',
      otherHazardText: '',
      isDeleted: [false],
      wasModified: [false],
      isNew: [true]
    });
  }

  setUpViewPage() {
    this.title = `View Hazard`;
    this.form.patchValue({otherHazardText: this.record.otherHazardText});
  }

  async setUpEditPage() {
    this.title = `Edit Hazard`;
    this.selectedHazardType = this.record.hazardTypeId;
    this.selectedCategory = this.record.hazardCategoryId;

    this.form.patchValue({
      id: this.record.id,
      globalSafetyDataSheetId: this.sdsId,
      isDeleted: this.record.isDeleted,
      wasModified: this.record.wasModified,
      otherHazardText: this.record.otherHazardText,
      isNew: this.record.isNew,
    })

  }

  setUpDeletePage() {
    this.title = `Delete Hazard`;
    this.selectedHazardType = this.record.hazardTypeId;
    this.selectedCategory = this.record.hazardCategoryId;
    this.form.patchValue({
      id: this.record.id,
      globalSafetyDataSheetId: this.sdsId,
      isDeleted: this.record.isDeleted,
      wasModified: this.record.wasModified,
      otherHazardText: this.record.otherHazardText,
      isNew: this.record.isNew,
    });
  }

  getCategoryOptions(): Promise<void> {
    const apiFactory = ApiFactory.retrieveEntity(ApiEntityTypesEnum.HazardCategory);
    return new Promise((resolve, reject) => {
      apiFactory.addSuccessHandler((data: HazardCategory[]) => {
        sortByProperty<HazardCategory>(data, (d) => d.name, false);
        this.categoryOptions = data.map((element) => ({
          id: element.id,
          displayText: element.name
        }));

        resolve();
      })
        .removePaging()
        .buildAndSend();
    });
  }

  getTypeOptions(): Promise<void> {
    const apiFactory = ApiFactory.retrieveEntity(ApiEntityTypesEnum.HazardType);
    return new Promise((resolve, reject) => {
      apiFactory.addSuccessHandler((data: HazardType[]) => {
        sortByProperty<HazardType>(data, (d) => d.name, false);
        this.hazardOptions = data.map((element) => ({
          id: element.id,
          displayText: element.name
        }));

        resolve();
      })
        .removePaging()
        .buildAndSend();
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      id: [''],
      globalSafetyDataSheetId: [''],
      hazardTypeId: ['', Validators.required],
      hazardType: ['', Validators.required],
      hazardCategoryId: [''],
      hazardCategory: [''],
      otherHazardText: [''],
      isDeleted: [false],
      wasModified: [true],
      isNew: [false]
    });
  }

  clearForm() {
    this.processing = false;
    this.form.reset;
    this.showErrors = false;
    this.selectedHazardType = null;
    this.selectedCategory = null;
    this.isOtherHazard = false;
  }

  protected submitForm() { }
}
