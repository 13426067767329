const dateRegExps = {
    /* eslint-disable no-useless-escape */
    MMDDYYYY: /(0?[1-9]|1[012])[- \/.](0?[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/,
    YYYYMMDD: /(19|20)\d\d[- \/.](0?[1-9]|1[012])[- \/.](0?[1-9]|[12][0-9]|3[01])/,
    /* eslint-enable  no-useless-escape */
};

/**
 * Returns a boolean representing whether n
 * can parse to a Number properly.
 * @param {*} n
 */
export const isNumeric = (n) => {
    return n instanceof Date || (!isNaN(parseFloat(n)) && isFinite(n));
};

/**
 * Creates a more easily read string from a camelCase variable name.
 * @param camelCase stringLikeThis to convert to a String Like This
 */
export function convertToFriendlyString(camelCase: string) {
    return camelCase.replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase());
}

/**
 * /*
 * Determines whether a string resolves to a date
 * @param value string to check for Date shape
 */
export function stringIsDate(value: string): boolean {
    const formattedStr = value.trim().substr(0, 10);
    return Object.keys(dateRegExps).some((key) => dateRegExps[key].test(formattedStr));
}
