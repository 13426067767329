import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[password]'
})

export class PasswordDirective {

    // Optional for use with modals - Sending any new value through will trigger a reset to hide the password text
    @Input() hidePassword: any;
    private shown = false;
    private inlineSpan = document.createElement('span');
    private iconSpan = document.createElement('span');

    private textHtml = `<i class="fas fa-eye-slash" style="padding-left: 5px;"></i>`;
    private passwordHtml = `<i class="fas fa-eye" style="padding-left: 5px;"></i>`;

    private parentNode: any;
    private element: any;

    constructor(private el: ElementRef) { }

    ngOnInit() {
        this.createElements();
        this.reorderElements();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hidePassword && !changes.hidePassword.firstChange) {
            this.toggle(false);
        }
    }

    createElements() {
        this.parentNode = this.el.nativeElement.parentNode;
        this.element = this.el.nativeElement;
        this.element.setAttribute('type', 'password');
        this.inlineSpan.style.whiteSpace = 'nowrap';

        this.iconSpan.innerHTML = this.passwordHtml;
        this.iconSpan.addEventListener('click', (event) => {
            this.toggle(!this.shown);
        });
    }

    reorderElements() {
        this.parentNode.replaceChild(this.inlineSpan, this.element);
        this.inlineSpan.appendChild(this.element);
        this.inlineSpan.appendChild(this.iconSpan);
    }

    toggle(shown: boolean) {
        this.shown = shown;
        if (this.shown) {
            this.showPlainText();
        } else {
            this.hideText();
        }
    }

    showPlainText() {
        this.element.setAttribute('type', 'text');
        this.iconSpan.innerHTML = this.textHtml;
    }

    hideText() {
        this.element.setAttribute('type', 'password');
        this.iconSpan.innerHTML = this.passwordHtml;
    }
}