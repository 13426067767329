import { Injectable } from '@angular/core';

@Injectable()
export class NewRESTLocationsApiService {
    // this property is used by ApiTemplateManager as the default for all Routes
    commonUrl = 'location/';

    // protected addAdditionalEndpoint(endpointManager: IEndpointHelper): void {
    //     endpointManager.addNewEndpointTemplate(
    //         ACTIONS_legacy.API.NEW_API_ROB.CAN_DELETE,
    //         `location/canDelete?CompanyId=${this.TS + 'companyId' + this.TE}&Id=${this.TS + 'id' + this.TE}`
    //     );
    // }
    // protected registerBroadcastMessageHandlers() {}
}
