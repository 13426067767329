<label class="switch" *ngIf="parent !== undefined; else parentTemplate">
  <!-- Child template -->
  <input type="checkbox" [id]="parent.id + '-' + id" [(ngModel)]="isChecked" [disabled]="getIsDisabled(this)">
  <span class="slider round" [ngClass]="{ 'disabled' : getIsDisabled(this) }"></span>
</label>
<ng-template #parentTemplate>
  <!-- Parent template -->
  <label class="switch">
    <input type="checkbox" [id]="id" [(ngModel)]="isChecked" [disabled]="isDisabled">
    <span class="slider round" [ngClass]="{ 'disabled' : isDisabled }"></span>
  </label>
</ng-template>
