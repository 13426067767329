import { Component, Input } from '@angular/core';
import { IAuditInfo } from '@models/auditinfo.model';

@Component({
    selector: 'audit-info',
    templateUrl: './audit-info.component.html',
    styleUrls: ['./audit-info.component.scss'],
})
export class AuditInfoComponent {

    @Input() auditInfo: IAuditInfo;
    @Input() addEndSpacing = true;
}
