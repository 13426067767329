import { Component, Input } from '@angular/core';

@Component({
  selector: 'jjkp-forgot-password-okta-other-applications',
  templateUrl: './forgot-password-okta-other-applications.component.html',
})

export class ForgotPasswordOktaOtherApplicationsComponent {

  @Input() nonSMSproducts: any;
  
}