import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthErrorMessage } from '@constants/auth/auth-error-message.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { ConvertApiErrorToAlertMessage } from '@pages/auth/helpers';
import { AuthService } from '@services/auth.service';
import { ApiFactory } from '@services/core/api-factory.class';
import { LoggerService } from '@services/core/logger-service.class';
import { EmailAddressValidatorAsync } from '@utilities/validators/email-address.validator';

@Component({
    selector: 'jjk-account-migration',
    templateUrl: './jjk-account-migration.component.html',
    styleUrls: ['./jjk-account-migration.component.scss']
})
export class JjkAccountMigrationComponent extends ValidatableFormBaseDirective implements OnInit {

    isProcessingRequest = false;
    showMigrationErrorAlert = false;
    alertText: string;
    userEmail: string;

    constructor(private formBuilder: UntypedFormBuilder,
                private auth: AuthService) {
        super();
    }

    ngOnInit(): void {
        this.buildForm();
        ($('#jjkAccountMigration') as any).modal('show');
    }

    private buildForm() {
        this.userEmail = LoggedInUserInfo.Instance.userInfo.user.email;
        this.form = this.formBuilder.group({
            email: [this.userEmail, { validators: Validators.required, asyncValidators: EmailAddressValidatorAsync() }],
            password: [null, Validators.required]
        });
    }

    protected async submitForm() {
        this.isProcessingRequest = true;
        const user = {
            emailAddress: LoggedInUserInfo.Instance.userInfo.user.email,
            password: this.getControl('password').value,
            firstName: LoggedInUserInfo.Instance.userInfo.userFirstName,
            lastName: LoggedInUserInfo.Instance.userInfo.userLastName
        }
        await new Promise((resolve, reject) => {
            ApiFactory.saveNewEntity(ApiEntityTypesEnum.Security, user)
                .addRouteHint('Migrate')
                .addSuccessHandler((x) => {
                    ($('#jjkAccountMigration') as any).modal('hide');
                    resolve(x);
                })
                .addErrorHandler((response: any) => {
                    LoggerService.trace(`Migration error: ${response}`);
                    this.isProcessingRequest = false;
                    this.setAlert(response.errorData[0].errorMessage);
                    reject();
                })
                .buildAndSend();
        });
    }

    goToSignup() {
        if (!this.isProcessingRequest) {
            ($('#jjkAccountMigration') as any).modal('hide');
            this.auth.requestOktaSignup();
        }
    }

    setAlert(apiErrorMessage: AuthErrorMessage) {
        this.alertText = ConvertApiErrorToAlertMessage(apiErrorMessage);
        this.showMigrationErrorAlert = true;
    }
}
