<div *ngIf="chartData"  class="chart-container">
  <h2 style="text-transform: none;">{{chartData.title}}<jjk-popover [content]="'To help identify trends, the monthly incident and DART rates displayed here represent a rolling 6 month average. The rate for each month is calculated using that total number of incidents and employee hours for that month, plus those of the 5 subsequent months.'"></jjk-popover></h2>
  <jjk-empty-state *ngIf="isEmptyState" auth authFeature="incident,create" (action)='onEmptyStateAddNewClick($event)' [showAddButton]="showAddButtonEmptyState" [displayText]="emptyStateMessage"></jjk-empty-state>
  <combo-chart-component *ngIf="!isEmptyState"
    [view]="view"
    [scheme]="chartData.comboBarScheme"
    [colorSchemeLine]="chartData.lineChartScheme"
    [barChart]="chartData.barChart"
    [animations]="chartData.animations"
    [lineChart]="chartData.lineChartSeries"
    [gradient]="chartData.gradient"
    [xAxis]="chartData.showXAxis"
    [yAxis]="chartData.showYAxis"
    [legend]="chartData.showLegend"
    [legendTitle]="''"
    [legendPosition]="chartData.legendPosition"
    [showGridLines]="chartData.showGridLines"
    [showXAxisLabel]="chartData.showXAxisLabel"
    [showYAxisLabel]="chartData.showYAxisLabel"
    [showRightYAxisLabel]="chartData.showYAxisLabel"
    [xAxisLabel]="chartData.xAxisLabel"
    [yAxisLabel]="chartData.yAxisLabel"
    [yAxisLabelRight]="chartData.yAxisLabelRight"
    [yAxisTickFormatting]="axisFormatWholeNumber">
  </combo-chart-component>
</div>
