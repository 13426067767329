
<div class="control-group">
    <div class="form-group" [ngClass]="shouldShowErrors ? 'form-group-error' : ''" >
        <label for="reminder1" class="block form-label" [class.req]="!isDisabled">Notification <jjk-popover
                [content]="toolTipText">
            </jjk-popover></label>
        <jjk-select id="reminder1" class="reminder-input" [options]="reminders"
            [isDisabled]="isDisabled ? true : null" [(selection)]="reminder1"
            (selectionChange)="removeFirstReminderOptionFromSecondReminder()"></jjk-select>
    </div>

    <div class="form-group">
        <label class="form-label" for="reminder2">Second Notification</label>
        <jjk-select id="reminder2" class="reminder-input" [options]="reminders2"
            [isDisabled]="isDisabled ? true : null" [(selection)]="reminder2"></jjk-select>
    </div>
</div>
<div class="control-group">
    <div class="form-group">
        <label class="form-label">Send Immediate Notification</label>
        <label class="switch switch-flat" style="display: block;">
            <input class="switch-input" [disabled]="immediateIsDisabled" type="checkbox" [(ngModel)]="isSendImmediately" [attr.disabled]="isDisabled ? true : null">
            <span class="switch-label" data-on="Yes" data-off="No"></span> <span
                class="switch-handle"></span>
        </label>
    </div>
</div>