import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { UserCreationType } from '@constants/enums/user-creation-type.enum';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { Country } from '@models/entity-models/autogenerated/country';
import { State } from '@models/entity-models/autogenerated/state';
import { User } from '@models/entity-models/autogenerated/user';
import { UserDataProfile } from '@models/entity-models/autogenerated/userdataprofile';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { AuthService } from '@services/auth.service';
import { ApiFactory } from '@services/core/api-factory.class';
import { GoogleTagManagerService } from '@services/google-tag-manager.service';
import { SmsCommon } from '@services/sms.common';
import { isNullOrUndefined } from '@utilities/helpers';
import { PhoneNumberValidator } from '@utilities/validators/phone-number.validator';

@Component({
    selector: 'profile-form',
    templateUrl: './profile-form.component.html',
})
export class ProfileFormComponent extends ValidatableFormBaseDirective implements OnInit {

    countryList: Country[] = [];
    stateList: State[] = [];

    user: User;
    userDataProfile: UserDataProfile;

    userId = LoggedInUserInfo.Instance.userInfo.userId;
    isFormSubmitted = false;

    @Output() profileSaved: EventEmitter<boolean> = new EventEmitter();

    constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private googleTagService: GoogleTagManagerService) { super(); }

    ngOnInit() {
        ApiFactory.retrieveEntity(ApiEntityTypesEnum.User)
            .addEntityId(this.userId)
            .addSuccessHandler((u: User) => {
                this.user = u;
            })
            .buildAndSend();

        ApiFactory.retrieveEntity(ApiEntityTypesEnum.UserDataProfile)
            .addRouteHint('GetUserDataProfileByUserId')
            .addDataEntry('userId', this.userId)
            .addSuccessHandler((response: UserDataProfile) => {
                this.userDataProfile = response;
                this.buildForm(this.userDataProfile);
                this.loadCountries();
            })
            .buildAndSend();

    }

    updateLoggedInUser(profile: UserDataProfile) {
        // If this is a self-registered user and the first time they are filling out the profile, send to SAP!
        if (this.user && (this.user.userCreationType === UserCreationType.SelfRegistered
            || this.user.userCreationType === UserCreationType.SelfRenewal) && !this.user.lastReviewed) {
            // Fire the registrationComplete event for GTM
            this.googleTagService.sendCustomDatalayerEvent('registrationComplete');

            ApiFactory.retrieveEntity(ApiEntityTypesEnum.SAP)
                .addRouteHint('SendSAPDataToQueue')
                .addSuccessHandler(() => { })
                .buildAndSend();
        }

        // update last reviewed date for user
        const updatedUser = LoggedInUserInfo.Instance.userInfo.user;
        updatedUser.lastReviewed = new Date();
        ApiFactory.updateEntity(ApiEntityTypesEnum.User, updatedUser)
            .addSuccessHandler((user) => {
                this.authService.updateUserProfileData(user, profile);
                this.profileSaved.emit(true);
            })
            .buildAndSend();
    }

    buildForm(data?) {
        this.form = this.formBuilder.group({
            firstName: [data && data.firstName ? data.firstName : null, Validators.required],
            lastName: [data && data.lastName ? data.lastName : null, Validators.required],
            jobTitle: [data && data.jobTitle ? data.jobTitle : null, Validators.required],
            phoneNum: [data && data.phoneNum ? data.phoneNum : null, [new PhoneNumberValidator().validator, Validators.required]],
            phoneNumExt: [data && data.phoneNumExt ? data.phoneNumExt : null],
            companyName: [data && data.companyName ? data.companyName : '', Validators.required],
            companyAddr1: [data && data.companyAddr1 ? data.companyAddr1 : '', Validators.required],
            companyAddr2: [data && data.companyAddr2 ? data.companyAddr2 : ''],
            companyCity: [data && data.companyCity ? data.companyCity : '', Validators.required],
            companyCountryId: [data && data.companyCountryId ? data.companyCountryId : null, Validators.required],
            companyStateId: [data && data.companyStateId ? data.companyStateId : null],
            companyPostalCode: [data && data.companyPostalCode ? data.companyPostalCode : ''],
        });

        this.form.get('companyCountryId').valueChanges.subscribe((val) => {
            this.countryChange(val);
        });
        this.countryChange(this.form.value.companyCountryId);
    }

    protected submitForm() {
        // Checks again for a profile if the page loaded before the new user profileData was created in the database
        // or if last login date is null (which should never be the case)
        // This can happen when a new user is created and the user comes directly to this form.
        if (this.isFormSubmitted) { return; }
        this.isFormSubmitted = true;

        if (!this.userDataProfile || this.userDataProfile.lastLoginDate === null) {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.UserDataProfile)
                .addRouteHint('GetUserDataProfileByUserId')
                .addDataEntry('userId', LoggedInUserInfo.Instance.userInfo.userId)
                .addSuccessHandler((response: UserDataProfile) => {
                    this.updateUserProfile(response);
                })
                .buildAndSend();
        } else {
            this.updateUserProfile(this.userDataProfile);
        }
    }

    private async updateUserProfile(updatedProfile: UserDataProfile) {
        try {
            updatedProfile.firstName = this.form.value.firstName;
            updatedProfile.lastName = this.form.value.lastName;
            updatedProfile.jobTitle = this.form.value.jobTitle;
            updatedProfile.phoneNum = this.form.value.phoneNum;
            updatedProfile.phoneNumExt = this.form.value.phoneNumExt;
            updatedProfile.companyName = this.form.value.companyName;
            updatedProfile.companyAddr1 = this.form.value.companyAddr1;
            updatedProfile.companyAddr2 = this.form.value.companyAddr2;
            updatedProfile.companyCity = this.form.value.companyCity;
            updatedProfile.companyCountryId = this.form.value.companyCountryId !== undefined ? this.form.value.companyCountryId : '';
            updatedProfile.companyStateId = this.form.value.companyStateId !== undefined ? this.form.value.companyStateId : '';
            updatedProfile.companyPostalCode = this.form.value.companyPostalCode;
            updatedProfile.loginCount = !isNullOrUndefined(updatedProfile.loginCount) ? updatedProfile.loginCount : 1;
            await new Promise<void>((resolve, reject) => {
                ApiFactory.updateEntity(ApiEntityTypesEnum.UserDataProfile, updatedProfile)
                    .addSuccessHandler((profile) => {
                        this.isFormSubmitted = false;
                        this.updateLoggedInUser(profile);
                        resolve();
                    })
                    .addErrorHandler(() => reject())
                    .buildAndSend();
            });
        } catch (error) {
            this.profileSaved.emit(true);
        }
    }

    loadCountries() {
        ApiFactory.retrieveEntity(ApiEntityTypesEnum.Country)
            .removePaging()
            .addSuccessHandler((countries) => this.setCountryList(countries))
            .buildAndSend();
    }

    setCountryList(countries: Country[]) {
        this.countryList = countries;

        // Set USA as default for a new record
        if (this.form && !this.form.value.companyCountryId) {
            const usaId = this.countryList.find((c) => c.countryName.toLocaleUpperCase() === 'USA').id;
            this.form.get('companyCountryId').setValue(usaId);
        }
    }

    countryChange(countryId: string) {
        ApiFactory.retrieveEntity(ApiEntityTypesEnum.State)
            .addFilterEntries(SmsCommon.getFilterExpression(ApiEntityTypesEnum.State, 'CountryId', countryId))
            .removePaging()
            .addSuccessHandler((states) => this.setStateList(states))
            .buildAndSend();
    }

    setStateList(states: State[]) {
        this.stateList = states;

        // USA or Canada
        if (this.stateList.length) {
            this.form.get('companyStateId').setValidators(Validators.required);
            this.form.get('companyPostalCode').setValidators(Validators.required);
        } else {
            this.form.get('companyStateId').clearValidators();
            this.form.get('companyStateId').setValue(null);
            this.form.get('companyPostalCode').clearValidators();
        }
        this.form.get('companyStateId').updateValueAndValidity();
        this.form.get('companyPostalCode').updateValueAndValidity();
    }
}
