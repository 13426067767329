<div *ngIf="chartData"  class="chart-container">
  <h2 style="text-transform: none;">{{chartData.title}}</h2>
  <jjk-empty-state *ngIf="isEmptyState" auth authFeature="task" (action)='onEmptyStateAddNewClick($event)' [showAddButton]="showAddButtonEmptyState" [displayText]="emptyStateMessage"></jjk-empty-state>
  <ngx-charts-bar-vertical *ngIf="!isEmptyState"
    [view]="view"
    [scheme]="chartData.colorScheme"
    [results]="chartData.data"
    [gradient]="chartData.gradient"
    [xAxis]="chartData.showXAxis"
    [yAxis]="chartData.showYAxis"
    [legend]="chartData.showLegend"
    [showXAxisLabel]="chartData.showXAxisLabel"
    [showYAxisLabel]="chartData.showYAxisLabel"
    [xAxisLabel]="chartData.xAxisLabel"
    [yAxisLabel]="chartData.yAxisLabel"
    [yAxisTickFormatting]="axisFormatWholeNumber">
  </ngx-charts-bar-vertical>  
</div>
