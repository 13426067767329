// AUTO-GENERATED CODE
//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { FeaturesPerCompany } from './featurespercompany';
import { FeaturesPerSubscription } from './featurespersubscription';
import { FeaturesPerUserOrGroup } from './featuresperuserorgroup';
import { User } from './user';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { IValidatorInfo, ValidatorInfo } from '@models/validations/validatorInfo';
import { IModelRecord, IModelAudit, IModelBase } from '@models/interfaces/entity-interfaces';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
export class Features  {

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
        createdByUserId: string;
        createdDate: Date;
        displayOrder?: number;
        featureName: string;
        id: string;
        impersonationCreatedByUserId?: string;
        impersonationModifiedByUserId?: string;
        isDeleted: boolean;
        modifiedByUserId?: string;
        modifiedDate?: Date;
        shortName: string;
        subFeatureName: string;
        groupId: string;
        permissions: number;

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    constructor(json?: Partial<Features>) {
        this.createdByUserId = json && json.createdByUserId;
        this.createdDate = (!json || json.createdDate === undefined) ? null : (json.createdDate === null ? new Date() : json.createdDate);
        this.displayOrder = json && json.displayOrder;
        this.featureName = json && json.featureName;
        this.id = json && json.id;
        this.impersonationCreatedByUserId = json && json.impersonationCreatedByUserId;
        this.impersonationModifiedByUserId = json && json.impersonationModifiedByUserId;
        this.isDeleted = json && json.isDeleted;
        this.modifiedByUserId = json && json.modifiedByUserId;
        this.modifiedDate = (!json || json.modifiedDate === undefined) ? null : json.modifiedDate;
        this.shortName = json && json.shortName;
        this.subFeatureName = json && json.subFeatureName;
        this.groupId = json && json.groupId;
        this.permissions = json && json.permissions || 0;

    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validateInfo(fieldName: string): IValidatorInfo {
        switch (fieldName) {
        case 'createdByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'createdDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'displayOrder': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'number',
            };
        }
        case 'featureName': {
            return {
                required: true,
                minLength: undefined, maxLength: 255,
                type: 'string',
            };
        }
        case 'id': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationCreatedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationModifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'isDeleted': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'modifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'modifiedDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'shortName': {
            return {
                required: true,
                minLength: undefined, maxLength: 50,
                type: 'string',
            };
        }
        case 'subFeatureName': {
            return {
                required: false,
                minLength: undefined, maxLength: 255,
                type: 'string',
            };
        }
            default: return { required: false, minLength: undefined, maxLength: undefined, type: undefined };
        }
    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validate(skip: string[] = []): [number, number, string[]?] {
        let count = 0;
        const errorFields = [];
        if (!skip.includes('createdByUserId') && ValidatorInfo.validateString(this.createdByUserId, this._validateInfo('createdByUserId'))) {
            count++;
            errorFields.push('createdByUserId');
        }
        if (!skip.includes('createdDate') && ValidatorInfo.validateDate(this.createdDate, this._validateInfo('createdDate'))) {
            count++;
            errorFields.push('createdDate');
        }
        if (!skip.includes('displayOrder') && ValidatorInfo.validateNumber(this.displayOrder, this._validateInfo('displayOrder'))) {
            count++;
            errorFields.push('displayOrder');
        }
        if (!skip.includes('featureName') && ValidatorInfo.validateString(this.featureName, this._validateInfo('featureName'))) {
            count++;
            errorFields.push('featureName');
        }
        if (!skip.includes('id') && ValidatorInfo.validateString(this.id, this._validateInfo('id'))) {
            count++;
            errorFields.push('id');
        }
        if (!skip.includes('impersonationCreatedByUserId') && ValidatorInfo.validateString(this.impersonationCreatedByUserId, this._validateInfo('impersonationCreatedByUserId'))) {
            count++;
            errorFields.push('impersonationCreatedByUserId');
        }
        if (!skip.includes('impersonationModifiedByUserId') && ValidatorInfo.validateString(this.impersonationModifiedByUserId, this._validateInfo('impersonationModifiedByUserId'))) {
            count++;
            errorFields.push('impersonationModifiedByUserId');
        }
        if (!skip.includes('isDeleted') && ValidatorInfo.validateString(this.isDeleted, this._validateInfo('isDeleted'))) {
            count++;
            errorFields.push('isDeleted');
        }
        if (!skip.includes('modifiedByUserId') && ValidatorInfo.validateString(this.modifiedByUserId, this._validateInfo('modifiedByUserId'))) {
            count++;
            errorFields.push('modifiedByUserId');
        }
        if (!skip.includes('modifiedDate') && ValidatorInfo.validateDate(this.modifiedDate, this._validateInfo('modifiedDate'))) {
            count++;
            errorFields.push('modifiedDate');
        }
        if (!skip.includes('shortName') && ValidatorInfo.validateString(this.shortName, this._validateInfo('shortName'))) {
            count++;
            errorFields.push('shortName');
        }
        if (!skip.includes('subFeatureName') && ValidatorInfo.validateString(this.subFeatureName, this._validateInfo('subFeatureName'))) {
            count++;
            errorFields.push('subFeatureName');
        }
        return [count, 8, errorFields];
    }
}
