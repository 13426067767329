export enum ParameterTypeEnum {
    Undefined = 'Undefined',
    Date = 'Date?',
    Time = 'Time',
    Number = 'Number',
    String = 'String',
    StringArray = 'String[]',
    Boolean = 'Boolean',
    NullableDecimal = 'decimal?',
    NonNullableDate = 'Date'
}
