/**
 * Defines various levels of visibility a column may have in Hacksaw.
 * Used for responsive column toggling and manual column display interaction.
 */
export class SmsColumnVisibility {
    alwaysShow = false;
    hidden = false;
    manuallyToggled = false;
    priority = 1;
}
