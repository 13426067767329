import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'jjkp-chemicals-page-header',
    templateUrl: './chemicals-page-header.component.html',
    styleUrls: ['./chemicals-page-header.component.scss'],
})
export class ChemicalsPageHeaderComponent {

    @Output() buttonClickEvent = new EventEmitter();

    constructor() {}

    eventEmitter(event) {
        switch (event) {
            case 'Add': {
                this.buttonClickEvent.emit(event);
                break;
            }
            case 'Find': {
                this.buttonClickEvent.emit(event);
                break;
            }
        }
    }
}
