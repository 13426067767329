import { Validators } from '@angular/forms';
import { AuthErrorMessage, AuthErrorMessageMap } from '@constants/auth/auth-error-message.enum';
import { PasswordRequirements } from '@models/auth/password-requirements.model';
import { PasswordRuleViewModel } from '@models/auth/rule.model';
import { LoggerService } from '@services/core/logger-service.class';
import { PasswordValidators } from '@utilities/validators/password-validators';

export function GetPasswordRulesAndValidation(rules: PasswordRequirements, email: string = null, includePasswordConfirmRule = true): PasswordRuleViewModel[] {
    const ruleList = Array<PasswordRuleViewModel>();
    if (!rules?.complexity) {return ruleList;}
    const requirement = rules.complexity;

    if (requirement.excludeUsername) { ruleList.push(new PasswordRuleViewModel(
        'Password cannot contain any parts of username.',
        PasswordValidators.usernameMatchValidator(email,{ hasUsername: true }), 'hasUsername')) }

    if (requirement.minLength > 0) { ruleList.push(new PasswordRuleViewModel(
        `Password must contain ${requirement.minLength} ${requirement.minLength > 1 ? 'characters.' : 'character.'}`,
        Validators.minLength(requirement.minLength), 'minlength'))}

    if (requirement.minLowerCase > 0) { ruleList.push(new PasswordRuleViewModel(
        `Password must contain ${requirement.minLowerCase} lower case ${requirement.minLowerCase > 1 ? 'characters.' : 'character.'}`,
        PasswordValidators.patternValidator(/[a-z]/g, 1,  { hasSmallCase: true }), 'hasSmallCase'))}

    if (requirement.minUpperCase > 0) { ruleList.push(new PasswordRuleViewModel(
        `Password must contain ${requirement.minUpperCase} upper case ${requirement.minUpperCase > 1 ? 'characters.' : 'character.'}`,
        PasswordValidators.patternValidator(/[A-Z]/g, requirement.minUpperCase,  { hasCapitalCase: true }), 'hasCapitalCase'))}

    if (requirement.minSymbol > 0) { ruleList.push(new PasswordRuleViewModel(
        `Password must contain ${requirement.minSymbol} ${requirement.minSymbol > 1 ? 'symbols.' : 'symbol.'}`,
        PasswordValidators.patternValidator(/([!@#$%^&*])/g, 1, { hasSpecialCharacters: true }), 'hasSpecialCharacters'))}

    if (requirement.minNumber > 0) { ruleList.push(new PasswordRuleViewModel(
        `Password must contain ${requirement.minNumber} ${requirement.minNumber > 1 ? 'numbers.' : 'number.'}`,
        PasswordValidators.patternValidator(/\d/g, 1,  { hasNumber: true }), 'hasNumber'))}

    if (includePasswordConfirmRule) { ruleList.push(new PasswordRuleViewModel(
        `New and Confirm Passwords must match`,
        null, 'passwordMatch'))}

    return ruleList;
}

export function ConvertApiErrorToAlertMessage(apiErrorMessage: AuthErrorMessage, defaultErrorMessage?: string) :  string {
    if (apiErrorMessage.startsWith(AuthErrorMessage.GenericPasswordFailure)){
        return AuthErrorMessageMap.get(AuthErrorMessage.GenericPasswordFailure);
    } else {
        if (apiErrorMessage.endsWith(" is already registered")) {
            return 'User is already registered.';
        } else if(apiErrorMessage.startsWith('Auth Code') && apiErrorMessage.endsWith("could not be verified")) {
            return 'Verification Code is invalid or expired. Please select Resend Verification Code.'
        } else if (apiErrorMessage.startsWith('Okta authentication failed')) {
            return 'This service uses the same password as other J. J. Keller solutions. If you have forgotten your password, please log into one of these solutions to reset it.'
        }

        const errorMessage = AuthErrorMessageMap.get(apiErrorMessage)
        || defaultErrorMessage
        || `There was a problem contacting the server.`;

        LoggerService.trace("Okta Error " + apiErrorMessage + " translates to " + errorMessage);

        return errorMessage;
    }
}
