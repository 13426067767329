import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartsBaseModel } from '@models/reporting/charts/charts-base.model';
import { LegendPosition } from '@swimlane/ngx-charts';

@Component({
    selector: 'jjk-chart',
    templateUrl: './base-chart.component.html',
    styles: [`
        .chart-container {
        margin-bottom: 45px;
        min-height: 300px;
        }
        `],
})

export class JJKBaseChartComponent implements OnChanges {
    @Input() chartType = 'Pie';
    @Input() view; // = [400, 600];
    @Input() legend = true;
    @Input() legendTitle = 'Locations';
    @Input() legendPosition = LegendPosition.Below;
    @Input() chartData: ChartsBaseModel;
    @Input() results: any;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.chartData) {
            this.updateChart(changes.chartData.currentValue);
        }
    }
    updateChart(chartData) {
        this.chartData = chartData;
    }
}
