import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'layout-footer',
  templateUrl: './layout-footer.component.html',
  styleUrls: ['./layout-footer.component.scss'],
})
export class LayoutFooterComponent {

  customerServiceNumber = environment.customerServiceNumber;
  currentYear = (new Date()).getFullYear();
  pimcoreTermsOfUseUrl = environment.pimcoreTermsOfUseUrl;
  isNonAuth = false;
  jjkHorizontalRGBLogo = environment.jjkCDN + environment.jjkHorizontalRGBLogo;

  constructor() {
  }
}
