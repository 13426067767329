export enum AuthErrorMessage {
    MatchesCurrentPassword = `Unable to complete request Api validation failed: password.value (400, E0000001): password.value: Password cannot be your current password`,
    MatchesCurrentPassword2 = `Unable to complete request Update of credentials failed (403, E0000014): Password cannot be your current password`,
    MatchesRecentPassword = `Unable to complete request Api validation failed: password.value (400, E0000001): password.value: Password has been used too recently`,
    MatchesRecentPassword2 = `Unable to complete request Update of credentials failed (403, E0000014): Password has been used too recently`,
    PasswordLockedOut = `Unable to complete request Forgot password not allowed on specified user. (403, E0000034): User is currently locked out.`,
    // GenericPasswordFailure is only the beginning of the message, as the details following it may change.
    GenericPasswordFailure = `Unable to complete request Api validation failed: password.value (400, E0000001): password.value: Password requirements were not met.`,
    LockedOut = `Unable to complete request Error Code: LOCKED_OUT`,
    IncorrectCurrentPassword = `Unable to complete request Update of credentials failed (403, E0000014): Old Password is not correct`,
    AnswerHasQuestion = 'Unable to complete request Api validation failed: securityAnswer (400, E0000001): securityAnswer: The security question answer is too weak, answer must not be part of the question',
    UserAlreadyExits = 'Unable to complete request Api validation failed: login (400, E0000001): login: An object with this field already exists in the current organization',
    PasswordExpired = `Unable to complete request Password must be reset. PASSWORD_EXPIRED`,
    AuthenticationFailed = 'Authentication failed (401, E0000004)',
    SessionExpired = `Unabled to completed request. SESSION_EXPIRED`,
    AuthenticationFailed2 = 'Authentication failed',
    NotSetup = `Unable to complete request Error Code: NOT_SETUP`,
}

export const AuthErrorMessageMap = new Map([
    [AuthErrorMessage.MatchesCurrentPassword, 'The password has been used too recently.'],
    [AuthErrorMessage.MatchesCurrentPassword2, 'The password has been used too recently.'],
    [AuthErrorMessage.MatchesRecentPassword, 'The password has been used too recently.'],
    [AuthErrorMessage.MatchesRecentPassword2, 'The password has been used too recently.'],
    [AuthErrorMessage.PasswordLockedOut, 'Unable to reset password. The account is currently locked out.'],
    [AuthErrorMessage.GenericPasswordFailure, 'The password does not meet all requirements.'],
    [AuthErrorMessage.LockedOut, 'The account is currently locked out.'],
    [AuthErrorMessage.IncorrectCurrentPassword, 'Current Password is not correct.'],
    [AuthErrorMessage.AnswerHasQuestion, 'Answer cannot contain part of the question'],
    [AuthErrorMessage.UserAlreadyExits, 'User already exists'],
    [AuthErrorMessage.PasswordExpired, 'Password has expired and needs to be reset.'],
    [AuthErrorMessage.AuthenticationFailed, 'The credentials provided were incorrect.'],
    [AuthErrorMessage.SessionExpired, 'Unable to complete request. Session has expired.'],
    [AuthErrorMessage.AuthenticationFailed2, 'The credentials provided were incorrect.'],
]);
