import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, NgZone } from '@angular/core';
import { ProcessComponentDirective } from './directives/processComponent.directive';
import { IMessageDistributor } from './interfaces/IMessageDistributor';
import { MessageBusService } from './services/messageBusService';
import { MessageDistributionService } from './services/messageDistribution.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [ProcessComponentDirective],
  exports: [ProcessComponentDirective],
})
export class JJKMessagingModule {
  static forRoot(): ModuleWithProviders<JJKMessagingModule> {
    return {
      ngModule: JJKMessagingModule,
      providers: [
        { provide: IMessageDistributor, useClass: MessageDistributionService, deps: [NgZone]  },
        { provide: MessageBusService, useClass: MessageBusService, deps: [IMessageDistributor, NgZone] },
      ],
    };
  }
}
