import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalWindowComponent } from '@components/modal-window/modal-window.component';
import { IModalWindow } from '@components/modal-window/modal-windows.model';
import { EntityState } from '@constants/enums/entity-state';
import { AttachmentFile } from '@models/attachments-file.model';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { newRandomId } from '@utilities/helpers';
import { ValidateFileExtension } from '@utilities/validators/file-extension.validator';
import { ValidateFileSize } from '@utilities/validators/file-size.validator';

@Component({
  selector: 'attachments-edit-modal',
  templateUrl: './attachments-edit-modal.component.html',
  styleUrls: ['./attachments-edit-modal.component.scss'],
})
export class AttachmentsEditModalComponent extends ValidatableFormBaseDirective implements OnInit, AfterViewInit, IModalWindow {
  constructor(private mb: MessageBusService) {
    super();

  }
  @Input() isDescriptionHide = true;
  @Input() id: string;
  @Input() data: AttachmentFile[];
  @Input() isDisabled = false;
  title: string;
  isEdit = false;
  attachment: AttachmentFile;
  duplicateFound = false;
  uploadingFile = false;
  intervalTimer: any;

  @Output() onAttachment = new EventEmitter<AttachmentFile>();
  @ViewChild('description') descriptionField: ElementRef;
  @ViewChild('inputFile') inputFileField: ElementRef;
  @ViewChild(ModalWindowComponent, { static: true }) modal: ModalWindowComponent;

  description = new UntypedFormControl('');
  fileName = new UntypedFormControl('');
  fileSize = new UntypedFormControl(0);
  allowedFileTypes = 'CSV, DOC, DOCX, GIF, JPG, JPEG, PDF, PNG, PPT, PPTX, RTF, TSV, TXT, XLS, XLSX, MOV, MP4, MKV, WAV, WMV';

  ngOnInit(): void {
    if (!this.id) {
      this.id = newRandomId(4);
    }

    this.form = new UntypedFormGroup({
      description: this.description,
      fileName: this.fileName,
      fileSize: this.fileSize,
    });

    // messageProcessedNotifier will be picked up by the session.management.service and cause the session timer to be reset
    // this will prevent the page from timing out when files are uploading
    this.intervalTimer = setInterval(() => {
      if (this.uploadingFile) {
            this.mb.messageProcessedNotifier();
          }
      }, 60000); 
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalTimer);
  }

  protected configureMessageBus(builder: IMsgProcessingReg): void { }  

  ngAfterViewInit(): void {
    this.elementsSetup();
  }

  close(): void {
    this.modal.close();
  }

  show(attachmentId: string): void {
    this.formSetup(attachmentId);
    this.elementsSetup();
    this.modal.show();
  }

  formSetup(attachmentId: string) {
    this.uploadingFile = false;
    this.attachment = new AttachmentFile();
    const tempAttachment = this.data.find((x) => x.id === attachmentId);
    this.isEdit = !!tempAttachment;

    // Edit case does not allow file modifications
    if (this.isEdit) {
      this.title = 'Edit';
      Object.assign(this.attachment, tempAttachment);
    } else {
      this.title = 'Add';
      this.attachment.id = newRandomId(4); // Temporary id so we are able to find and delete locally
      this.fileName.valueChanges.subscribe((value) => {
        this.checkForDuplicate(value);
      });
    }

    this.fileSize.setValidators(ValidateFileSize);
    this.fileSize.updateValueAndValidity();

    this.fileName.setValue(this.attachment.fileName);
    this.fileName.setValidators([Validators.required, ValidateFileExtension]);
    this.fileName.updateValueAndValidity();

    this.description.setValue(this.attachment.description);
  }

  elementsSetup() {
    if (this.descriptionField && this.descriptionField.nativeElement) {
      this.descriptionField.nativeElement.focus();
    }
    if (this.inputFileField && this.inputFileField.nativeElement) {
      this.inputFileField.nativeElement.value = '';
    }
  }

  checkForDuplicate(name: string): any {
    this.duplicateFound = name && !!this.data.find((a) => !a.isDeleted && a.id !== this.attachment.id && (a.fileName || '').localeCompare(name) === 0);
  }

  onFileChange($event) {
    if ($event.target.files[0]) {
      this.attachment.file = $event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.attachment.file);
      reader.onload = (_event) => {
        this.attachment.fileUrl = reader.result.toString();
      };
      this.fileName.setValue(this.attachment.file ? this.attachment.file.name : '');
      this.fileSize.setValue(this.attachment.file ? this.attachment.file.size : '');
    }
  }

  onCancel() {
    this.close();
    this.resetFormSubmissionStatus();
  }

  protected submitForm() {
    if (!this.isEdit) {
      this.attachment.entityState = EntityState.Added;
    } else if ((this.attachment.description || '').localeCompare(this.description.value) !== 0
      || (this.attachment.fileName || '').localeCompare(this.fileName.value) !== 0
      || this.attachment.fileSize !== +this.fileSize.value) {
      this.attachment.entityState = EntityState.Modified;
    } else {
      return; // No valid change has happened
    }

    // We fill the most basic data on this modal
    this.uploadingFile = true;
    this.attachment.fileName = this.fileName.value;
    this.attachment.description = this.description.value || '';
    this.onAttachment.emit(this.attachment);
  }
}
