import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';

@Component({
    selector: 'jjk-safety-management-process',
    templateUrl: './safety-management-process.component.html',
    styleUrls: ['./safety-management-process.component.scss'],
})
export class SafetyManagementProcessComponent {

    selectedShieldId = 1;
    pnpAuth = LoggedInUserInfo.Instance.userInfo.checkAuthFeature('pnp', '', '');
    taskAuth = LoggedInUserInfo.Instance.userInfo.checkAuthFeature('task', '', '');
    auditsAuth = LoggedInUserInfo.Instance.userInfo.checkAuthFeature('audit', '', '');

    show = this.pnpAuth && this.taskAuth && this.auditsAuth;

    constructor() {
    }

    onClick(id: number) {
        this.selectedShieldId = id;
    }
}
