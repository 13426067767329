export enum TaskTypeEnum {
    Task = '438b05a4-4d00-4dd1-9339-348bbd256a33',
    CorrectiveAction = 'd1797aec-171f-4ca9-86d6-3eee1f0e05c6',
    PreventiveAction = '4d59923a-7149-472a-8406-f9d024d2881c',
    Form = 'c069bb1b-23ee-41cd-ac84-3994720e5b31',
  }

  export const TaskTypeMap = new Map([
    [TaskTypeEnum.Task, 'Task'],
    [TaskTypeEnum.CorrectiveAction, 'Corrective Action'],
    [TaskTypeEnum.PreventiveAction, 'Preventive Action'],
    [TaskTypeEnum.Form, 'Form'],
  ]);
