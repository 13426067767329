import { ApiMessage } from '../../../modules/messaging/types/Messages/apiMessage';

/** This model is used to encapsulate all needed information
 * for the server to generate the correct results.
 */
export class DataControlPackage {
    public static encodeAsJSON(msg: ApiMessage): string {
        return encodeURI(JSON.stringify({
            sortCriteria: msg.sortingCriteria,
            groupingCriteria: msg.groupingCriteria,
            partialCountCriteria: msg.partialCountCriteria,
            pageNumberToReturn: msg.pageNumber,
            numberOfRecordsPerPage: msg.itemsPerPage,
            filterExpressions: msg.filters,
            reportParams: msg.reportParams === undefined ? null : msg.reportParams,
            generateCSV: msg.generateCSV,
        }));
    }
}
