import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'jjk-datagrid-custom-column-content',
    templateUrl: './datagrid-custom-column-content.component.html',
})

export class DataGridCustomColumnContentComponent implements OnInit {
    @Input() component: any;
    @Input() row: any;
    @Input() value: string;
    @Input() authFeature = '';

    @Output() action = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    onComponentClick(component, row) {
        this.action.emit({ component, row });
    }
}
