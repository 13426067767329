export const LOGGING = {
    HEADERS: {
        SESSION_MGMNT: '==========######========= SESSION MANAGEMENT ==========######=========',
        CACHING: '============================== CACHING ==============================',
        MSGBUS: {
            EXTERNAL: '******************************* MESSAGE BUS *******************************',
            INTERNAL: '&&&&&&&&&&&&&&&&&&&& MSGBUS INTERNALS &&&&&&&&&&&&&&&&&&&&',
        },
        APIFACTORY: '############################### API FACTORY ###############################',
        UI: {
            GRIDVIEW: '+++====++++====++++====++++| GRIDVIEW BASE |++++====++++====++++====+++',
        },
        NAVIGATION: '%%%%%%%%%%%%%%%%%%%%%%} NAVIGATION {%%%%%%%%%%%%%%%%%%%%%%',
        AUTHORIZATION: 'AUTH -- AUTH -- AUTH -- AUTH --| AUTHORIZATION |-- AUTH -- AUTH -- AUTH -- AUTH',
    },
    SETTINGS: {
        KEY_EXPIRATION_IN_MINUTES: 5,
        SHOW_BY_DEFAULT: false,
        SHOW_AUTHENTICATION: true,
        SHOW_CACHING: false,
        SHOW_MSGBUS: false,
        SHOW_API: false,
        SHOW_NAVIGATION: true,
    },
};
