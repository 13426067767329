export enum FilterType {
    autocomplete = 'autocomplete',
    bool = 'bool',
    date = 'date',
    dateRange = 'dateRange',
    nestedAutocomplete = 'nestedAutocomplete',
    select = 'select',
    text = 'text',
    multiselect = 'multiselect',
    multiselectAsync = 'multiselectAsync',
    number = 'number',
}
