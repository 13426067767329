import { Injectable } from '@angular/core';
import { WindowRefService } from './windowref.service';

/**
 * This service will send events to the window object utilizing the dataLayer push event.
 * These events will then send asynchronously to Google Tag Manager
 * @author : Mark Herried
 */

@Injectable()
export class GoogleTagManagerService {

    constructor(private winRef: WindowRefService) { }

    sendPageView(urlAfterRedirects: string) {
        this.winRef.nativeWindow.dataLayer.push({
            event: 'pageView',
            action: urlAfterRedirects,
            split_client: true,
        });
    }

    sendCustomDatalayerEvent(eventName: string) {
        this.winRef.nativeWindow.dataLayer.push({
            event: eventName,
        });
    }
}
