import { Injectable } from '@angular/core';

function _window(): any {
    // return the global native browser window object
    return window;
}
/**
 * This service will reference the window object for the browser.
 * @author : Mark Herried
 */
@Injectable()
export class WindowRefService {
    get nativeWindow(): any {
        return _window();
    }
}
