<div class="row">
    <div class="col-md-12">
            <div class="description" *ngIf="title">
                <h1>{{ title }}</h1>
                <p>{{ description }}</p>
            </div>
            <div class="card-deck">
                <ng-container *ngFor="let card of cards, index as i">
                    <ng-container *featureToggle="card.featureToggleName">
                        <a auth [authFeature]="card.authTag" [routerLink]="[card.routerLink]" class="card">
                            <div class="card-body">
                                <h3 class="card-title"
                                    [ngClass]="{'text-center' : centerText}">{{card.title}}</h3>
                            </div>
                        </a>
                    </ng-container>
                </ng-container>
            </div>
    </div>
</div>