import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { DynamicControl } from '@models/dynamic-forms.export';
import { ApiFactory } from '@services/core/api-factory.class';
import { LoggerService } from '@services/core/logger-service.class';
import { isNullOrUndefined, isValidArray, mapToListObjects, newRandomId, sortOptionsAscending } from '@utilities/helpers';

@Component({
    selector: 'jjk-select-with-subform',
    templateUrl: './jjk-select-with-subform.component.html',
})
export class SelectWithSubformComponent implements OnInit {
    componentId = newRandomId(6);
    isOpen = false;
    dataList: any[];
    activeList: any[];
    @Input() title: string;
    @Input() uniqueField: string;
    @Input() uniqueFieldDisplayName: string;
    @Input() emitFullSelection = false;
    @Input() showError = false;
    @Input() entityType: ApiEntityTypesEnum;
    @Input() formControls: DynamicControl[];
    @Input() controlId: string;
    @Input() multiple = false;
    @Input() options = false;
    @Input() isSelectDisabled = false;
    @Input() addNew = true;
    @Output() selectionChange = new EventEmitter<any[]>();
    @Output() selectionObjectChange = new EventEmitter<any[]>();
    @Output() formDisplayChange = new EventEmitter<boolean>();
    @Output() subFormDataSave = new EventEmitter<any[]>();
    selectionValue: any[] = [];
    @Input()
    get selection() {
        return this.selectionValue;
    }
    set selection(val) {
        this.selectionValue = val;
        if (this.emitFullSelection) {
            this.emitSelectedObject();
        }
        this.selectionChange.emit(this.selectionValue);
    }

    show = false;

    constructor() {
    }

    async ngOnInit() {
        this.getData();
    }

    getData(newId?: string) {
        const apiFactory = ApiFactory.retrieveEntity(this.entityType)
        if (this.entityType != ApiEntityTypesEnum.EmployeeViewModel) { apiFactory.addRouteHint('GetDropdownListViewModel'); }

        apiFactory.addSuccessHandler((response) => {
            this.setLists(response, newId);
            })
            .addErrorHandler((response) => {
                LoggerService.trace(`error: ${response}`);
                this.show = true;
            })
            .removePaging()
            .buildAndSend()
    }

    setLists(response, newId?: string) {
        this.dataList = response;
        this.activeList = this.mapObjects(this.dataList.filter((i) => i.isActive
            || ((!isValidArray(this.selection) && i.id === this.selection) // single select
                || isValidArray(this.selection) && this.selection.includes(i.id)))); // multiselect
        if (newId) {
            this.updateSelection(newId);
            this.subFormDataSave.emit(this.dataList);
        }
        this.show = true;
    }

    updateSelection(newId) {
        if (this.multiple) {
            if (this.selection === undefined) {
                this.selection = [];
            }
            this.selection.push(newId);
            // Change Detection in the select list won't fire unless you create a new reference to the selections.
            this.selection = this.selection.slice();
        } else {
            this.selection = newId;
        }
    }

    afterFormSave(formData) {
        this.getData(formData.id);
        this.isOpen = false;
        this.formDisplayChange.emit(this.isOpen);

    }
    private emitSelectedObject() {
        if (this.emitFullSelection && this.dataList && this.multiple) {
            const objects = [];
            this.selection.forEach((id) => {
                objects.push(this.dataList.find((o) => o.id === id));
            });
            this.selectionObjectChange.emit(objects);
        } else if (this.emitFullSelection && this.dataList) {
            const object = this.dataList.find((o) => o.id === this.selection);
            this.selectionObjectChange.emit(object);
        }
    }

    private mapObjects(data: any) {
        switch (this.entityType) {
            case ApiEntityTypesEnum.EmployeeViewModel:
                return this.mapEmployeesViewModel(data);

            case ApiEntityTypesEnum.Equipment:
                return this.mapEquipmentToListObjects(data);

            case ApiEntityTypesEnum.JobTitle:
                return this.takeOutBlanks(data);

            default:
                return mapToListObjects(data);
        }
    }

    private mapEmployeesViewModel(data: any) {
        return data
            .map((e) => ({
                id: e.id,
                displayText: `${e.lastName}, ${e.firstName} / ${e.employeeCode} / ${e.locationName}`,
                isActive: e.isActive,
                isDeleted: false,
                jobTitle: e.jobTitleName,
            }))
            .sort(sortOptionsAscending);
    }

    private mapEquipmentToListObjects(data: any) {
        return data
            .map((e) => ({
                id: e.id,
                displayText: `${e.name} ${e.location ? '/ ' + e.location.name : ' '} ${e.workArea ? '/ ' + e.workArea.name : ''}`,
                isActive: e.isActive,
                isDeleted: e.isDeleted,
            }))
            .sort(sortOptionsAscending);
    }

    private takeOutBlanks(data: any) {
        const exist = data.find(x => x.id == this.selection);
        if(isNullOrUndefined(exist) || exist?.name == "")
            this.selection = [];
        return mapToListObjects(data);
    }

    openCloseForm() {
        this.isOpen = !this.isOpen;
        this.formDisplayChange.emit(this.isOpen);
        if (this.isOpen && isValidArray(this.formControls)) {
            setTimeout(() => {
                const firstControl = document.getElementById(this.formControls[0].name);
                if (firstControl) {
                    firstControl.focus();
                }
            }, 500);
        }
    }
}
