import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AlertArea, AlertType, IAlert } from '@models/alert';
import { newRandomId } from '@utilities/helpers';

@Injectable()
export class AlertService {

    public onNewAlert$ = new Subject<IAlert>();
    public onRemoveAlerts$ = new Subject<string | string[] | AlertArea>();
    private pendingAlerts: IAlert[] = [];
    public WaitForNewSubscribers: boolean;

    constructor() {
    }

    public addAlert(alertType: AlertType, message: string, alertArea: AlertArea = AlertArea.All) {
        const newAlert = { id: newRandomId(2), area: alertArea, type: alertType, message } as IAlert;

        if (this.WaitForNewSubscribers) {
            this.pendingAlerts.push(newAlert);
        } else {
            this.onNewAlert$.next(newAlert);
        }
    }

    public addErrorAlert(message: string, alertArea: AlertArea = AlertArea.All) {
        this.addAlert(AlertType.Danger, message, alertArea);
    }

    public addDeleteAlert(itemName: string, alertArea: AlertArea = AlertArea.All) {
        this.addAlert(AlertType.Success, `You have deleted <b>${itemName}</b>`, alertArea);
    }

    public addDeleteAlertError(itemName: string, alertArea: AlertArea = AlertArea.All) {
        this.addAlert(AlertType.Danger, `There was an error while deleting <b>${itemName}</b>, refresh the page and try again.`, alertArea);
    }

    public addMarkActiveAlert(itemName: string, alertArea: AlertArea = AlertArea.All) {
        this.addAlert(AlertType.Success, `You have marked <b>${itemName}</b> as active`, alertArea);
    }

    public addMarkInactiveAlert(itemName: string, alertArea: AlertArea = AlertArea.All) {
        this.addAlert(AlertType.Success, `You have marked <b>${itemName}</b> as inactive`, alertArea);
    }

    public addUpdateAlertError(itemName: string, alertArea: AlertArea = AlertArea.All) {
        this.addAlert(AlertType.Danger, `There was an error updating <b>${itemName}</b>.`, alertArea);
    }

    public addMarkInactiveAlertError(itemName: string, alertArea: AlertArea = AlertArea.All) {
        this.addAlert(AlertType.Danger, `There was an error while marking <b>${itemName}</b> as inactive, refresh the page and try again.`, alertArea);
    }

    public removeAlerts(alertsData: string | string[] | AlertArea) {
        this.onRemoveAlerts$.next(alertsData);
    }

    public subscribe(newSubscriber: (value: IAlert) => void): Subscription {
        const newSubscription = this.onNewAlert$.subscribe(newSubscriber);

        if (this.WaitForNewSubscribers) {
            this.pendingAlerts.forEach((pendingAlert) => {
                this.onNewAlert$.next(pendingAlert);
            });
            this.pendingAlerts = [];
            this.WaitForNewSubscribers = false;
        }

        return newSubscription;
    }

    public addUnenrollAlert(itemName: string, alertArea: AlertArea = AlertArea.All) {
        this.addAlert(AlertType.Success, `You have unenrolled <b>${itemName}</b>`, alertArea);
    }
}
