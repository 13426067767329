<div class="modal right fade pe-3" [id]="modalObjName + '_slideoutmodal'" [attr.alphaId]="alphaId" data-bs-backdrop="static"
  data-bs-keyboard="false" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" [id]="ariaLabelId">{{title}}</h1>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <jjk-loader *ngIf="showLoader"></jjk-loader>
        <button *ngIf="cancelButtonText" type="button" class="btn btn-cancel" (click)="onCancelClicked()"
          [disabled]="isDisabled">{{cancelButtonText}}</button>
        <button *ngIf="isNextVisible" type="button" class="btn btn-secondary" (click)="onNextClicked()"
          [disabled]="!isActionButtonEnabled || isDisabled">{{nextButtonText}}</button>
        <button type="button" class="btn" [ngClass]="isRedConfirmButton ? 'btn-danger' : 'btn-primary'" (click)="onConfirmClicked()"
          [disabled]="!isActionButtonEnabled || isDisabled || isConfirmDisabled">{{confirmButtonText}}</button>
      </div>
    </div>
  </div>
</div>