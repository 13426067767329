import { Directive, ElementRef, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
    selector: '[safeHtml]',
})
export class SafeHtmlDirective implements OnInit {
    @Input('safeHtml') innerHtml: string;

    constructor(private el: ElementRef, private domSanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.el.nativeElement.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML, this.innerHtml);
    }
}
