import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';

export const SdsManagementRoutes = {
    Requests: { urlRoute: 'requests', associatedApiEntity: ApiEntityTypesEnum.Request, children: {} },
    ViewRequest: { urlRoute: 'requests/:id', associatedApiEntity: ApiEntityTypesEnum.Request, children: {} },
    SearchAssignRequest: { urlRoute: 'requests/searchassign/:id', associatedApiEntity: ApiEntityTypesEnum.Request, children: {} },
    EditRequest: { urlRoute: 'requests/edit/:id', associatedApiEntity: ApiEntityTypesEnum.Request, children: {} },
    GlobalProducts: { urlRoute: 'global_products', associatedApiEntity: ApiEntityTypesEnum.GlobalProduct, children: {} },
    GlobalProductView: { urlRoute: 'global_products/view/:id', associatedApiEntity: ApiEntityTypesEnum.GlobalProduct, children: {} },
    GlobalProductAdd: { urlRoute: 'global_products/add', associatedApiEntity: ApiEntityTypesEnum.GlobalProduct, children: {} },
    GlobalProductAddFromRequest: { urlRoute: 'global_products/add/fromRequest/:requestId', associatedApiEntity: ApiEntityTypesEnum.GlobalProduct, children: {} },
    GlobalProductEditFromRequest: { urlRoute: 'global_products/edit/fromRequest/:requestId/globalProduct/:id', associatedApiEntity: ApiEntityTypesEnum.GlobalProduct, children: {} },
    GlobalProductEdit: { urlRoute: 'global_products/edit/:id', associatedApiEntity: ApiEntityTypesEnum.GlobalProduct, children: {} },
    ViewGlobalProductSds: { urlRoute: 'global_products/:productId/sds/view/:id', associatedApiEntity: ApiEntityTypesEnum.GlobalProduct, children: {} },
    AddGlobalProductSds: { urlRoute: 'global_products/:productId/sds/add', associatedApiEntity: ApiEntityTypesEnum.GlobalProduct, children: {} },
    EditGlobalProductSds: { urlRoute: 'global_products/:productId/sds/edit/:id', associatedApiEntity: ApiEntityTypesEnum.GlobalProduct, children: {} },
};
