import { Component } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import * as moment from 'moment';
import { DynamicFormControlComponent } from '@components/dynamic-form-control/dynamic-form-control.component';
import { ControlSize } from '@constants/enums/control-size.enum';

@Component({
    selector: 'dynamic-form-control-date-picker',
    templateUrl: './dynamic-form-control-date-picker.component.html',
    styleUrls: ['../dynamic-form-control/dynamic-form-control.component.scss'],
})
export class DynamicFormControlDatePickerComponent extends DynamicFormControlComponent {

    constructor(public controlContainer: ControlContainer) {
        super();
        this.controlSize = ControlSize.Small;
    }

    selectedDate(event: moment.Moment) {
        this.controlData.setValue(event && event.toDate());
    }
}
