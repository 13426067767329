export const ACTIONS = {
    GENERAL: {
        DATA: { NEW: 'new', DELETE: 'delete', UPDATE: 'update', LOCK: 'lock', UNLOCK: 'unlock' },
    },
    AUTH: {
        SESSION_EXPIRED: 'sessionExpired',
        SESSION_REFRESH: 'sessionRefresh',
        AUTH_REQUEST_LOGIN: 'req_AuthLogin',
        AUTH_REQUEST_OKTA_LOGIN: 'req_AuthOktaLogin',
        AUTH_REQUEST_OKTA_SIGNUP: 'req_AuthOktaSignup',
        AUTH_REQUEST_LOGOUT: 'req_AuthLogout',
        AUTH_LOGIN_SUCCESS: 'authLoginInSuccess',
        AUTH_LOGIN_FAILED: 'authLoginFailed',
        AUTH_REQUEST_TOKEN_REFRESH: 'authTokenNeedsRefresh',
        AUTH_REQUEST_AUTH_TOKEN: 'requestAuthToken',
        AUTH_TOKEN_WAS_REFRESHED: 'authTokenRefreshed',
        REQUEST_LOAD_SMS_USER_DATA: 'requestSMSUserData',
        REQUEST_PASSWORD_RESET: 'requestPwdReset',
        CREATE_NEW_SMS_ACCOUNT: 'createNewSMSAccount',
    },
    GLOBAL: {
        ROUTING: { GO: 'go', GOBACK: 'goback' },
        SYSTEM: { LOST_CONNECTION: 'noconnection', EXCEPTION: 'genError' },
    },
    UI: {
        CONTROLS: {
            WIZARD: {
                WIZARD_STARTED: 'wizardStarted',
                WIZARD_COMPLETED_SUCCESS: 'wizardCompletedSuccess',
                WIZARD_CANCELLED: 'wizardCancelled',
            },
            DATAGRID: {
                COLUMN_UPDATE: 'colUpdate',
                COLUMN_MAP: 'colMap',
                SAVED_COLUMNS: 'savedColumns',
                FILTER_COLUMNS: 'filterColumns',
            },
            ADVANCED_FILTER: { COLUMN_MAP: 'afColMap' },
        },
        PAGES: {
            SHOW: 'page_show',
            HIDE: 'page_hide',
        },
    },
};

export const TOPICS = {
    PAGES: {
        PLANS_POLICIES_PAGE: 'page_plansPolicies',
        INCIDENTS_PAGE: 'page_incidents',
        EMPLOYEES_PAGE: 'page_employees',
        REPORT_PAGE: 'report_page',
        DEMO: 'playgroundDemo',
    },
    APPLEVEL: {
        ROUTING: 'SVC_Routing',
        SYSTEM: 'SVC_System',
    },
    API: {
        API_MASTER_SERVICE: 'ApiMasterService',
    },
};

export const DATAKEYS = {
    AUTH: {
        AUTH_CODE: 'authCode',
        AUTH_TOKEN: 'authToken',
        ID_TOKEN: 'id_token',
        REFRESH_TOKEN: 'refreshToken',
        CLIENT_ID: 'clientId',
    },
    GENERAL: {
        DATA: '__data__',
    },

    APPLEVEL: {
        ROUTE: {
            URL: 'url',
            INVITE_TOKEN: 'invitationToken',
            MATERIAL_CODE: 'materialCode',
            ACCOUNT_NUMBER: 'accountNumber',
            PROMO_CODE: 'promoCode',
        },
        SECURITY: { USER_DATA: 'user',
                    USER_CREDENTIALS: 'credentials',
                    FORCE_REDIRECT: 'force_redirect'
        },
    },
    UI: {
        PAGES: {
            PLANS_POLICIES: { COLUMN_DATA: 'colData' },
        },
        CONTROLS: {
            GENERAL: {
                DATA: '__data__',
            },
            DATAGRID: {
                COLUMN_MAPPING: 'colMap',
                SAVED_COLUMNS: 'savedColumns',
                FILTER_COLUMNS: 'filterColumns',
            },
            ADVANCED_FILTER: { COLUMN_MAP: 'afColMap' },
        },
    },
    HTTP_KEYS: {
        BODY: 'data',
        FILTERS: 'filters',
        SORTING: 'sorting',
        COMPANY_ID: 'companyId',
        VERSION_ID: 'version',
    },
};

export const TechSupprtNumber = '1-833-813-7268';

export const DIALOG_MESSAGES = {
    MAX_SDS_MONITOR_LIMIT_REACHED_MESSAGE: 'You have reached the maximum quantity of Safety Data Sheets that J. J. Keller will find and monitor as part of your account. Contact Sales to subscribe for unlimited access.',
    CONTACT_TECH_SUPPORT_MESSAGE: `There was an error loading your request. Please contact technical support at ${TechSupprtNumber}.`,
    CANNOT_EDIT_COMPLETE_REQUEST: '"Complete" or "Unable to Source SDS" Requests cannot be updated.',
    RENEW_EXPIRED_SUBSCRIPTION: 'Your trial access has expired. Please contact J. J. Keller sales at 1-800-230-4094 to discuss options to continue with the J. J. Keller® Safety Management Suite.',
}

export const GENERAL_MESSAGES = {
    LOADING_FORM_MESSAGE: 'Please wait while we load the form...',
}