import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { Employee } from '@models/entity-models/autogenerated/employee';
import { MedicalAccommodationStatusEnum, MedicalAccommodationStatusMap} from '@constants/enums/medical-accommodation-status.enum'
import { MedicalAccommodationStatus } from '@models/entity-models/autogenerated/medicalaccommodationstatus';
import { ApiFactory } from '@services/core/api-factory.class';
import { sortByProperty } from '@utilities/helpers';

@Component({
  selector: 'medical-accommodation-modal',
  templateUrl: './medical-accommodation-modal.component.html',
})
export class MedicalAccommodationModalComponent implements OnInit {

  @Input() employee: Employee;

  @Input() modalIsOpen = false;

  @Output() updatedAccommodation = new EventEmitter();

  title: string;
  modalId = 'MedicalAccommodationModal';
  editForm: UntypedFormGroup;
  showErrors: boolean;
  statusOptions: any[] = [];

  private _selectedStatus = null;
  get selectedStatus() {
      return this._selectedStatus;
  }
  set selectedStatus(val) {
      this._selectedStatus = val;
      this.editForm.patchValue({ statusId: this._selectedStatus });
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    ) {
  }

  async ngOnInit() {
    this.getStatusOptions();

    this.editForm = this.formBuilder.group({
        statusId: ['', Validators.required],
    });
    this.selectedStatus = this.employee.medicalAccommodationStatusId || MedicalAccommodationStatusEnum.NotRequested;
    this.title = `Request Accommodation for ${this.employee.lastName}, ${this.employee.firstName}`;

  }

  async getStatusOptions(): Promise<void> {
    const options = new Promise<void>((resolve, reject) => {
    ApiFactory.retrieveEntity(ApiEntityTypesEnum.MedicalAccommodationStatus)
    .addSuccessHandler((data: MedicalAccommodationStatus[]) => {
      sortByProperty<MedicalAccommodationStatus>(data, (d) => d.value, false);
      this.statusOptions = data.map((element) => ({
        id: element.id,
        displayText: MedicalAccommodationStatusMap.get(element.id as MedicalAccommodationStatusEnum),
        }));
        resolve();
      })
      .buildAndSend();
    });
    await Promise.all([options]);
  }

  onSave() {
      if (this.editForm.valid) {
        const employee = new Employee();
            employee.id = this.employee.id;
            employee.medicalAccommodationStatusId = this.editForm.get('statusId').value;

        ApiFactory.updateEntity(ApiEntityTypesEnum.Employee, employee)
        .addRouteHint("UpdateAccommodationStatus")
        .addSuccessHandler((response: any) => {
            this.updatedAccommodation.emit(response);
            this.selectedStatus = this.employee.medicalAccommodationStatusId = response.medicalAccommodationStatusId;
            this.closeWindow();
        })
        .buildAndSend();

      } else {
          this.showErrors = true;
      }

  }

  onCancel () {
    this.selectedStatus = this.employee.medicalAccommodationStatusId || MedicalAccommodationStatusEnum.NotRequested;
    this.closeWindow();
  }

  closeWindow() {
    ($(`#${this.modalId}`) as any).modal('hide');
  }

  openWindow() {
    ($(`#${this.modalId}`) as any).modal('show');

    $(`#${this.modalId}`).on('shown.bs.modal', function() {
        setTimeout(() => {
            $('#statusId', this).trigger('focus');
        }, 150);
    });

  }
}
