import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { HazardTypeDescMap, HazardTypeEnum } from '@constants/sds-management/hazard-type';
import { GlobalStatementsViewModel } from '@models/entity-models/sds-management/global-statements-view-model';
import { ValidatableFormBaseDirective } from '@models/forms/validatable-form-base.model';

@Component({
  selector: 'add-edit-statements-form',
  templateUrl: './add-edit-statement-hazard-form.component.html',
  styleUrls: ['../slide-out-modal.component.scss'],
})
export class AddEditStatementsFormComponent extends ValidatableFormBaseDirective implements OnInit {

  @Input() isView: Boolean;
  @Input() isAdd: Boolean;
  @Input() isEdit: Boolean;
  @Input() isDelete: Boolean;
  @Input() record: GlobalStatementsViewModel;
  @Input() sdsId: string;
  @Input() title: string;
  @Output() updatedRecord = new EventEmitter();
  @Output() clearRecord = new EventEmitter();

  titleShow = '';
  label = '';
  showErrors: boolean;
  editMode: boolean;
  processing = false;
  newHazard: GlobalStatementsViewModel;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  async ngOnInit() {
    this.buildForm();
  }

  setUpViewPage(){
    this.titleShow = `${this.title.replace('_', ' ')}`;
    this.label = this.titleShow;
  }
  
  setUpAddPage() {
    this.titleShow = `Add ${this.title.replace('_', ' ')}`;
    this.label = `${this.title.replace('_', ' ')}`;
    this.form.patchValue({
      id: Guid.EMPTY,
      globalSafetyDataSheetId: this.sdsId,
      statement: '',
      statementTypeId: HazardTypeDescMap.get(this.title.split('_')[0] as HazardTypeEnum)
    });
  }

  async setUpEditPage() {
    this.titleShow = `Edit ${this.title.replace('_', ' ')}`;
    this.label = `${this.title.replace('_', ' ')}`;
    this.form.patchValue({
      id: this.record.id,
      globalSafetyDataSheetId: this.sdsId,
      statement: this.record.statement,
      statementTypeId: this.record.statementTypeId,
      isDeleted: this.record.isDeleted,
      wasModified: this.record.wasModified,
      isNew: this.record.isNew,
    });
  }

  async setUpDeletePage() {
    this.titleShow = `Delete ${this.title.replace('_', ' ')}`;
    this.label = `${this.title.replace('_', ' ')}`;
    this.form.patchValue({
      id: this.record.id,
      globalSafetyDataSheetId: this.sdsId,
      statement: this.record.statement,
      statementTypeId: this.record.statementTypeId,
      isDeleted: this.record.isDeleted,
      wasModified: this.record.wasModified,
      isNew: this.record.isNew,
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      id: [Guid.EMPTY, Validators.required],
      globalSafetyDataSheetId: [''],
      statement: ['', Validators.required],
      statementTypeId: ['', Validators.required],
      isDeleted: [false],
      wasModified: [true],
      isNew: [false],
    });
  }

  clearForm() {
    this.newHazard = null;
    this.processing = false;
    this.form.reset();
    this.showErrors = false;
  }

  protected submitForm() {}
}
