<mat-action-list>
  <mat-list-item *ngIf="!params.hideImportExport" [routerLink]="'/import-export'" [queryParams]="{recordtype : params.gridType, action : 'import'}" auth authFeature="import-export,view">
      <i class="fas fa-file-import fa-fw toolbarIcon"></i>Import
  </mat-list-item>
  <mat-list-item *ngIf="!params.hideImportExport" mat-list-item [routerLink]="'/import-export'" [queryParams]="{recordtype : params.gridType, action : 'export'}" auth authFeature="import-export,view">
      <i class="fas fa-file-export fa-fw toolbarIcon"></i>Export
  </mat-list-item>
  <div [hidden]="isGridActionsHidden">

    <ng-container *ngIf="!showExportAll">
        <div mat-subheader>Report Current View</div>
        <mat-list-item (click)="GenerateReport($event, 'CSV', false)"><i class="fas fa-file-csv fa-fw toolbarIcon"></i> Generate CSV </mat-list-item>
        <mat-list-item (click)="GenerateReport($event, 'EXCEL', false)"><i class="fas fa-file-excel fa-fw toolbarIcon"></i> Generate Excel (.xlsx) </mat-list-item>
        <mat-list-item (click)="GenerateReport($event, 'XML', false)"><i class="fas fa-file-code fa-fw toolbarIcon"></i> Generate XML </mat-list-item>
    </ng-container>

    <ng-container *ngIf="showExportAll">
        <div mat-subheader>Grid Reporting</div>
        <mat-list-item *ngIf="showExportAll" (click)="onExportAllClick()"><i class="fas fa-file-csv fa-fw toolbarIcon"></i> Generate CSV</mat-list-item>
    </ng-container>

    <mat-divider [hidden]="!itemsSelected"></mat-divider>

    <div mat-subheader class="sub-header-center" [hidden]="!itemsSelected">MANAGE SELECTION</div>
    <mat-list-item (click)="Copy($event)" [hidden]="!itemsSelected">
        <i class="fas fa-copy fa-fw toolbarIcon"></i> Copy
    </mat-list-item>
    <mat-list-item (click)="CopyWithHeaders($event)" [hidden]="!itemsSelected">
        <i class="fas fa-copy fa-fw toolbarIcon"></i> Copy with Headers
    </mat-list-item>
    <mat-list-item *ngIf="params.gridType === 'Incident'" auth [authFeature]="'incident-report'" (click)="printForm301($event)" [hidden]="!itemsSelected">
        <i class="fas fa-print fa-fw toolbarIcon"></i> Print Form 301
    </mat-list-item>
    <mat-list-item *ngIf="params.showMassDataGroupUpdate" (click)="massDataGroupUpdate($event)" [hidden]="!itemsSelected">
        <i class="fas fa-pen fa-fw toolbarIcon"></i> Update Data Group
    </mat-list-item>
    <mat-list-item *ngIf="params.showMakeActive"  (click)="MakeActive($event)" [hidden]="!itemsSelected">
        <i class="columns-active-icon toolbarIcon"></i> Make Active
    </mat-list-item>
    <mat-list-item *ngIf="params.showMakeInactive"  (click)="MakeInactive($event)" [hidden]="!itemsSelected">
        <i class="actions-inactive-icon toolbarIcon"></i> Make Inactive
    </mat-list-item>
    <mat-list-item *ngIf="!params.hideDelete" (click)="Delete($event)" [hidden]="!itemsSelected">
        <i class="fas fa-trash fa-fw toolbarIcon"></i> Delete
    </mat-list-item>
    <mat-list-item *ngIf="params.showFindAndMonitorSDSs" (click)="findAndMonitorSDSs($event)" [hidden]="!itemsSelected">
        <i class="fas fa-search fa-fw toolbarIcon"></i> Ask J. J. Keller to Find and Monitor
    </mat-list-item>
    <mat-list-item *ngIf="params.showCompanyUsage" (click)="CompanyUsage($event)" [hidden]="!itemsSelected">
        <i class="fas fa-plus fa-fw toolbarIcon"></i> Add Company Usage
    </mat-list-item>

    <!-- *** Temporarily commented, this will be addressed in PBI #61083
    <mat-list-item *ngIf="params.gridType === 'Employee' && params.userHasEditPermissions"  (click)="MakeInactive($event)" [hidden]="!itemsSelected">
        <i class="actions-inactive-icon toolbarIcon"></i> Make Inactive
    </mat-list-item>
    <mat-list-item *ngIf="params.gridType === 'Employee' && params.userHasEditPermissions"  (click)="ResetLearnerPassword($event)" [hidden]="!itemsSelected">
        <i class="fas fa-edit fa-fw toolbarIcon"></i> Reset Learner Password
    </mat-list-item>
    -->

    <div mat-subheader [hidden]="!itemsSelected">Report From Selection</div>
    <mat-list-item (click)="GenerateReport($event, 'CSV', true)" [hidden]="!itemsSelected">
        <i class="fas fa-file-csv fa-fw toolbarIcon"></i> Generate CSV
    </mat-list-item>
    <mat-list-item (click)="GenerateReport($event, 'EXCEL', true)" [hidden]="!itemsSelected">
        <i class="fas fa-file-excel fa-fw toolbarIcon"></i> Generate Excel (.xlsx)
    </mat-list-item>
    <mat-list-item (click)="GenerateReport($event, 'XML', true)" [hidden]="!itemsSelected">
        <i class="fas fa-file-code fa-fw toolbarIcon"></i> Generate XML
    </mat-list-item>

     <!-- Additional Custom Side Panel Actions -->
    <ng-container *ngIf="params.additionalActions">
        <div mat-subheader [hidden]="!itemsSelected">Additional Actions</div>

        <ng-container *ngFor="let action of params.additionalActions;">
           <mat-list-item (click)="onAdditionalActionClick(action.displayText)" [hidden]="!itemsSelected">
               <span *ngIf="action.icon" [innerHTML]="action.icon"></span>
               {{ action.displayText }}
           </mat-list-item>
       </ng-container>
    </ng-container>


  </div>
</mat-action-list>