export const cspRules = {
    '7.75x4.75': {
        Width: 4.75,
        Height: 7.75,
        Corner: .1,
        ProductName: // todo aka Product Identifier
        {
            X: .09,
            Y: .125,
            Width: 4.6,
            Height: .35,
            BackgroundColor: 'black',
            FontSize: 30,
            FontColor: 'white',
            Editable: true,
        },
        Pictograms:
        {
            X: .2,
            Y: .8,
            Width: 1,
            Height: 1,
            Editable: true,
        },
        SignalWords:
        {
            X: 1.4,
            Y: 1,
            FontSize: 24,
            FontColor: 'black',
            Editable: true,
        },
        Statements:
        {
            X: 1.4,
            Y: 1.2,
            Width: 4.5,
            Height: 1.75,
            FontSize: 16,
            FontColor: 'black',
            Editable: true,
        },
        SupplierInfo: {
            X: 1.8,
            Y: 7.35,
            FontSize: 12,
            FontColor: 'black',
            Editable: true,
            Align: 'middle',
        },
    },
    '7x3': {
        Width: 3.0,
        Height: 7.0,
        Corner: .1,
        ProductName: // todo aka Product Identifier
        {
            X: .09,
            Y: .1,
            Width: 2.83,
            Height: .225,
            BackgroundColor: 'black',
            FontSize: 35,
            FontColor: 'white',
            Editable: true,
        },
        Pictograms:
        {
            X: .1,
            Y: .7,
            Width: .8,
            Height: .8,
            Editable: true,
        },
        SignalWords:
        {
            X: 1,
            Y: .7,
            FontSize: 26,
            FontColor: 'black',
            Editable: true,
        },
        Statements:
        {
            X: 1,
            Y: .9,
            Width: 3.0,
            Height: 1.75,
            FontSize: 18,
            FontColor: 'black',
            Editable: true,
        },
        SupplierInfo: {
            X: .8,
            Y: 6.7,
            FontSize: 14,
            FontColor: 'black',
            Editable: true,
            Align: 'middle',
        },
    },
    '3.5x2': {
        Width: 2.0,
        Height: 3.5,
        Corner: .1,
        ProductName: // todo aka Product Identifier
        {
            X: .08,
            Y: .08,
            Width: 1.86,
            Height: .16,
            BackgroundColor: 'black',
            FontSize: 36,
            FontColor: 'white',
            Editable: true,
        },
        Pictograms:
        {
            X: .1,
            Y: .38,
            Width: .5,
            Height: .5,
            Editable: true,
        },
        SignalWords:
        {
            X: .7,
            Y: .38,
            FontSize: 24,
            FontColor: 'black',
            Editable: true,
        },
        Statements:
        {
            X: .7,
            Y: .5,
            Width: 4.5,
            Height: 1.75,
            FontSize: 16,
            FontColor: 'black',
            Editable: true,
        },
        SupplierInfo: {
            X: .6,
            Y: 3.35,
            FontSize: 12,
            FontColor: 'black',
            Editable: true,
            Align: 'middle',
        },
    },
    '8.125x5': {
        Width: 5.0,
        Height: 8.125,
        Corner: .1,
        ProductName: // todo aka Product Identifier
        {
            X: .08,
            Y: .125,
            Width: 4.82,
            Height: .35,
            BackgroundColor: 'black',
            FontSize: 34,
            FontColor: 'white',
            Editable: true,
        },
        Pictograms:
        {
            X: .1,
            Y: .8,
            Width: 1.3,
            Height: 1.3,
            Editable: true,
        },
        SignalWords:
        {
            X: 1.8,
            Y: 1,
            FontSize: 24,
            FontColor: 'black',
            Editable: true,
        },
        Statements:
        {
            X: 1.8,
            Y: 1.3,
            Width: 4.5,
            Height: 1.75,
            FontSize: 16,
            FontColor: 'black',
            Editable: true,
        },
        SupplierInfo: {
            X: 1.5,
            Y: 7.7,
            FontSize: 12,
            FontColor: 'black',
            Editable: true,
            Align: 'middle',
        },
    },
    '3.333x4': {
        Width: 4.0,
        Height: 3.333,
        Corner: .1,
        ProductName: // todo aka Product Identifier
        {
            X: .1,
            Y: .07,
            Width: 3.8,
            Height: .25,
            BackgroundColor: 'black',
            FontSize: 24,
            FontColor: 'white',
            Editable: true,
        },
        Pictograms:
        {
            X: .1,
            Y: .5,
            Width: .7,
            Height: .7,
            Editable: true,
        },
        SignalWords:
        {
            X: .9,
            Y: .5,
            FontSize: 18,
            FontColor: 'black',
            Editable: true,
        },
        Statements:
        {
            X: .9,
            Y: .6,
            Width: 2.5,
            Height: 3.25,
            FontSize: 14,
            FontColor: 'black',
            Editable: true,
        },
        SupplierInfo: {
            X: 2.0,
            Y: 3.1,
            FontSize: 12,
            FontColor: 'black',
            Editable: true,
            Align: 'middle',
        },
    },
    // ------------------------
    // ------------------------
    '4.75x7.75': {
        Width: 7.75,
        Height: 4.75,
        Corner: .1,
        ProductName:
        {
            X: .1,
            Y: .125,
            Width: 7.6,
            Height: .45,
            BackgroundColor: 'black',
            FontSize: 28,
            FontColor: 'white',
            Editable: true,
        },
        Pictograms:
        {
            X: .3,
            Y: .9,
            Width: 1,
            Height: 1,
            Editable: true,
        },
        SignalWords:
        {
            X: 1.5,
            Y: 1,
            FontSize: 16,
            FontColor: 'black',
            Editable: true,
        },
        Statements:
        {
            X: 1.5,
            Y: 1.3,
            Width: 4.5,
            Height: 1.75,
            FontSize: 12,
            FontColor: 'black',
            Editable: true,
        },
        SupplierInfo: {
            X: 2.6,
            Y: 4.35,
            FontSize: 10,
            FontColor: 'black',
            Editable: true,
            Align: 'middle',
        },
    },
    '3x7': {
        Width: 7.0,
        Height: 3.0,
        Corner: .1,
        ProductName:
        {
            X: .1,
            Y: .1,
            Width: 6.8,
            Height: .35,
            BackgroundColor: 'black',
            FontSize: 22,
            FontColor: 'white',
            Editable: true,
        },
        Pictograms:
        {
            X: .1,
            Y: .7,
            Width: .7,
            Height: .7,
            Editable: true,
        },
        SignalWords:
        {
            X: .9,
            Y: .9,
            FontSize: 13,
            FontColor: 'black',
            Editable: true,
        },
        Statements:
        {
            X: .9,
            Y: 1.1,
            Width: 2.5,
            Height: 1.75,
            FontSize: 10,
            FontColor: 'black',
            Editable: true,
        },
        SupplierInfo: {
            X: 2.6,
            Y: 2.75,
            FontSize: 8,
            FontColor: 'black',
            Editable: true,
            Align: 'middle',
        },
    },
    '2x3.5': {
        Width: 3.5,
        Height: 2.0,
        Corner: .1,
        ProductName: // todo aka Product Identifier
        {
            X: .1,
            Y: .07,
            Width: 3.3,
            Height: .18,
            BackgroundColor: 'black',
            FontSize: 24,
            FontColor: 'white',
            Editable: true,
        },
        Pictograms:
        {
            X: .1,
            Y: .35,
            Width: .5,
            Height: .5,
            Editable: true,
        },
        SignalWords:
        {
            X: .75,
            Y: .4,
            FontSize: 16,
            FontColor: 'black',
            Editable: true,
        },
        Statements:
        {
            X: .75,
            Y: .55,
            Width: 4.5,
            Height: 1.70,
            FontSize: 12,
            FontColor: 'black',
            Editable: true,
        },
        SupplierInfo: {
            X: 1.3,
            Y: 1.9,
            FontSize: 10,
            FontColor: 'black',
            Editable: true,
            Align: 'middle',
        },
    },
    '5x8.125': {
        Width: 8.125,
        Height: 5.0,
        Corner: .1,
        ProductName: // todo aka Product Identifier
        {
            X: .1,
            Y: .125,
            Width: 7.95,
            Height: .45,
            BackgroundColor: 'black',
            FontSize: 24,
            FontColor: 'white',
            Editable: true,
        },
        Pictograms:
        {
            X: .1,
            Y: .85,
            Width: 1.3,
            Height: 1.3,
            Editable: true,
        },
        SignalWords:
        {
            X: 1.7,
            Y: 1.1,
            FontSize: 16,
            FontColor: 'black',
            Editable: true,
        },
        Statements:
        {
            X: 1.7,
            Y: 1.4,
            Width: 4.5,
            Height: 1.75,
            FontSize: 12,
            FontColor: 'black',
            Editable: true,
        },
        SupplierInfo: {
            X: 3,
            Y: 4.6,
            FontSize: 10,
            FontColor: 'black',
            Editable: true,
            Align: 'middle',
        },
    },
    '4x3.333': {
        Width: 3.333,
        Height: 4.0,
        Corner: .1,
        ProductName: // todo aka Product Identifier
        {
            X: .1,
            Y: .07,
            Width: 3.15,
            Height: .3,
            BackgroundColor: 'black',
            FontSize: 24,
            FontColor: 'white',
            Editable: true,
        },
        Pictograms:
        {
            X: .125,
            Y: .425,
            Width: .5,
            Height: .5,
            Editable: true,
        },
        SignalWords:
        {
            X: .75,
            Y: .5,
            FontSize: 18,
            FontColor: 'black',
            Editable: true,
        },
        Statements:
        {
            X: .75,
            Y: .6,
            Width: 4.5,
            Height: 1.70,
            FontSize: 14,
            FontColor: 'black',
            Editable: true,
        },
        SupplierInfo: {
            X: 1.75,
            Y: 3.75,
            FontSize: 12,
            FontColor: 'black',
            Editable: true,
            Align: 'middle',
        },
    },
    // ------------------------------------------------------
    // '4.25x11': {
    //     Width: 11.0,
    //     Height: 4.25,
    //     Corner: .1,
    //     ProductName: // todo aka Product Identifier
    //     {
    //         X: .1,
    //         Y: .1,
    //         Width: 10.8,
    //         Height: .7,
    //         BackgroundColor: 'black',
    //         FontSize: 26,
    //         FontColor: 'white',
    //         Editable: false,
    //     },
    //     Pictograms:
    //     {
    //         X: .1,
    //         Y: .8,
    //         Width: 1,
    //         Height: 1,
    //         Editable: true,
    //     },
    //     SignalWords:
    //     {
    //         X: 1.2,
    //         Y: 1.2,
    //         FontSize: 18,
    //         FontColor: 'black',
    //         Editable: true,
    //     },
    //     Statements:
    //     {
    //         X: 1.2,
    //         Y: 1.5,
    //         Width: 4.5,
    //         Height: 1.75,
    //         FontSize: 15,
    //         FontColor: 'black',
    //         Editable: true,
    //     },
    //     SupplierInfo: {
    //         X: 4.15,
    //         Y: 3.85,
    //         FontSize: 10,
    //         FontColor: 'black',
    //         Editable: true,
    //         Align: 'middle',
    //     },
    //     Footer: {
    //         X: 4.20,
    //         Y: 4,
    //         FontSize: 12,
    //         FontColor: 'black',
    //         Editable: false,
    //         Align: 'middle',
    //     },
    // },
    // // ------------------------------------------------------
    // '8.375x3.25': {
    //     Width: 3.25,
    //     Height: 8.375,
    //     Corner: .1,
    //     ProductName: // todo aka Product Identifier
    //     {
    //         X: .1,
    //         Y: .1,
    //         Width: 3.05,
    //         Height: .24,
    //         BackgroundColor: 'black',
    //         FontSize: 28,
    //         FontColor: 'white',
    //         Editable: false,
    //     },
    //     Pictograms:
    //     {
    //         X: .1,
    //         Y: .38,
    //         Width: .6,
    //         Height: .6,
    //         Editable: true,
    //     },
    //     SignalWords:
    //     {
    //         X: .75,
    //         Y: .7,
    //         FontSize: 28,
    //         FontColor: 'black',
    //         Editable: true,
    //     },
    //     Statements:
    //     {
    //         X: .75,
    //         Y: .8,
    //         Width: 4.5,
    //         Height: 1.75,
    //         FontSize: 18,
    //         FontColor: 'black',
    //         Editable: true,
    //     },
    //     SupplierInfo: {
    //         X: 1,
    //         Y: 8,
    //         FontSize: 14,
    //         FontColor: 'black',
    //         Editable: true,
    //         Align: 'middle',
    //     },
    //     Footer: {
    //         X: 1.5,
    //         Y: 8.1,
    //         FontSize: 12,
    //         FontColor: 'black',
    //         Editable: false,
    //         Align: 'middle',
    //     },
    // },
    // '7.5x10': {
    //     Width: 10.0,
    //     Height: 7.5,
    //     Corner: .1,
    //     ProductName: // todo aka Product Identifier
    //     {
    //         X: .1,
    //         Y: .1,
    //         Width: 9.80,
    //         Height: .6,
    //         BackgroundColor: 'black',
    //         FontSize: 26,
    //         FontColor: 'white',
    //         Editable: false,
    //     },
    //     Pictograms:
    //     {
    //         X: .1,
    //         Y: .7,
    //         Width: 2,
    //         Height: 2,
    //         Editable: true,
    //     },
    //     SignalWords:
    //     {
    //         X: 2.25,
    //         Y: 1.5,
    //         FontSize: 28,
    //         FontColor: 'black',
    //         Editable: true,
    //     },
    //     Statements:
    //     {
    //         X: 2.25,
    //         Y: 1.8,
    //         Width: 4.5,
    //         Height: 1.75,
    //         FontSize: 18,
    //         FontColor: 'black',
    //         Editable: true,
    //     },
    //     SupplierInfo: {
    //         X: 3,
    //         Y: 7.1,
    //         FontSize: 14,
    //         FontColor: 'black',
    //         Editable: true,
    //         Align: 'middle',
    //     },
    //     Footer: {
    //         X: 3,
    //         Y: 7.25,
    //         FontSize: 12,
    //         FontColor: 'black',
    //         Editable: false,
    //         Align: 'middle',
    //     },
    // },
};
