import { Injectable } from '@angular/core';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';

@Injectable()
export class FeatureAuthService {

    constructor() {}

    public hasAccess(authFeature: string, authNegate = false, authEntity: any = null) {
        let show = true;
        if (LoggedInUserInfo.Instance.userInfo.company?.subscriptionId && authFeature && authFeature !== '') {
            show = false;
            const split = authFeature.toString().split(',');
            const domain = split[0];
            const permissionType = (split.length > 1) ? split[1] : '';
            const ignoreGroup = (split.length > 2) ? split[2] === 'ignoreGroup' : false;
            const companyDataGroupingEnabled = (split.length > 2) ? split[2] === 'companyDataGroupingEnabled' : false;

            if (permissionType === 'create' && domain !== 'company-data') {
                show = LoggedInUserInfo.Instance.userInfo.canCreate(domain, permissionType);
            } else {
                show = LoggedInUserInfo.Instance.userInfo.checkAuthFeature(domain, permissionType, authEntity, false, ignoreGroup, companyDataGroupingEnabled);
            }

            if (authNegate) {
                show = !show;
            }

            return show;
        }
    }

    public isDisabled(authFeature: string, authNegate = false, authEntity: any = null)
    {
        const split = authFeature.toString().split(',');
        if (split.length === 3 && split[2] === 'disable') {
            return !this.hasAccess(authFeature, authNegate, authEntity);
        }
        return false;
    }
}