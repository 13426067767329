import { AfterViewInit, Component } from '@angular/core';
import { DynamicFormControlComponent } from '@components/dynamic-form-control/dynamic-form-control.component';

@Component({
    selector: 'dynamic-form-control-tree-select',
    templateUrl: './dynamic-form-control-tree-select.component.html',
    styleUrls: ['../dynamic-form-control/dynamic-form-control.component.scss'],
})
export class DynamicFormControlTreeSelectComponent extends DynamicFormControlComponent implements AfterViewInit {
    private _selectedValue;
    get selectedValue() {
        return this._selectedValue;
    }
    set selectedValue(val) {
        this._selectedValue = val;
        this.controlData.patchValue(this.selectedValue?.id);
    }

    constructor() {
        super();
    }

    onControlDataUpdate() {
        super.onControlDataUpdate();
        if (this.controlData) {
            this.selectedValue = this.controlData.value;
        }
    }

    ngAfterViewInit(): void {
        // removes browser default autocomplete for IE/Edge
        $('ng-select input').attr('autocomplete', 'off');
    }
}
