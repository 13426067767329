import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TREATMENT } from '@constants/treatment';
import { SplitioService } from '@services/splitio.service';

@Directive({
    selector: '[featureToggle]',
})
export class FeatureToggleDirective implements OnInit, OnDestroy {
    @Input('featureToggle') toggleName: string;
    private reverseToggle = false;
    private isReady$: Subscription;

    constructor(private splitioService: SplitioService,
                private viewContainer: ViewContainerRef,
                private template: TemplateRef<any>) {
                }

    async ngOnInit() {
        this.reverseToggle = this.toggleName && this.toggleName.indexOf('!') === 0;
        this.toggleName = this.toggleName && this.toggleName.replace('!', '');

        if (((await this.isOn()) && !this.reverseToggle) || (!(await this.isOn()) && this.reverseToggle)) {
            this.viewContainer.createEmbeddedView(this.template);
        } else {
            this.viewContainer.clear();
        }
    }

    ngOnDestroy(): void {
        if (this.isReady$) { this.isReady$.unsubscribe(); }
    }

    async isOn(): Promise<boolean> {
        if (!this.toggleName) {
            return true;
        }
        const toggleState = await this.splitioService.getToggle(this.toggleName);
        return toggleState === TREATMENT.ON;
    }
}
