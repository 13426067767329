export enum EditorialTemplateTypeEnum {
    PlansAndPolicies = 1,
    News = 2,
    Regsense = 3,
    ClassroomPrograms = 4,
    TrainingAssets = 5,
    OnlineCourses = 6,
    Checklists = 7,
    Questions = 8,
    ChemicalCrossreference = 9,
    OnlinePrograms = 10,
}
