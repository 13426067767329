import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'highPriority'})
export class HighPriorityPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
    transform(value: string) {
        if (value && value.toLowerCase() === 'high') {
            return this.sanitized.bypassSecurityTrustHtml(
                `<span class="bold"> ${value} </span>`);
        } else {
            return value;
        }
    }
}
