import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { OperatorEnum } from '../../../constants/enums/OperatorEnum.enum';
import { Operation } from './operation.class';

export interface ISetProperty {
    Use(property: string, type: ParameterTypeEnum): IOperations;
}

export interface IOutput {
    AsJSON: string;
    URIEncodedDataJSON: string;
    AsExpression: IFilterDataOnly[];
}

export interface IExpressionBuilder extends ISetProperty {
    Or: IOperations;
    And: IOperations;
    Build(): IOutput;
}

export interface IOperations {
    GreaterThan(value: Date | number): IExpressionBuilder;
    GreaterThanOrEqualTo(value: Date | number): IExpressionBuilder;
    LessThan(value: Date | number): IExpressionBuilder;
    LessThanOrEqualTo(value: Date | number): IExpressionBuilder;
    Equal(value: Date | number | string): IExpressionBuilder;
    NotEqualTo(value: Date | number | string): IExpressionBuilder;
    Between(valueLow: Date | number, valueHigh: Date | number): IExpressionBuilder;
    Contains(value: Date | number | string | string[]): IExpressionBuilder;
    DoesNotContain(value: Date | number | string): IExpressionBuilder;
    StartsWith(value: string): IExpressionBuilder;
    EndsWith(value: string | number): IExpressionBuilder;
}

export interface IFilterDataOnly {
    operations: Operation[];
    fieldName: string;
    entityName: ApiEntityTypesEnum;
}

export class FilterExpression implements IFilterDataOnly {
    operations: Operation[] = [];
    fieldName: string;
    fieldType: ParameterTypeEnum;
    entityName: ApiEntityTypesEnum;
}

export class FilterExpressionBuilder implements ISetProperty, IExpressionBuilder, IOperations, IOutput {
    public expressions: IFilterDataOnly[] = [];

    // When Use() is called, it will use this instance to make and return a new FilterExpression
    private instanceCreationAuthority: FilterExpressionBuilder;

    private filterExpressionData: FilterExpression = new FilterExpression();

    private logicState = 'AND';

    public get AsJSON(): string {
        return JSON.stringify(this.AsExpression);
    }

    public get EntityJSON(): string {
        return JSON.stringify(this);
    }

    public get URIEncodedDataJSON(): string {
        return encodeURI(this.AsJSON);
    }

    public get AsExpression(): IFilterDataOnly[] {
        return this.instanceCreationAuthority.expressions;
    }

    public static For(entity: ApiEntityTypesEnum): ISetProperty {
        const exp = new FilterExpressionBuilder();
        exp.filterExpressionData.entityName = entity;
        return exp;
    }

    private createNewInstance(): FilterExpressionBuilder {
        const fe = new FilterExpressionBuilder();
        fe.instanceCreationAuthority = this;
        this.expressions.push(fe.filterExpressionData);
        return fe;
    }

    public Use(property: string, fieldType: ParameterTypeEnum): IOperations {
        const newExpression = this.instanceCreationAuthority.createNewInstance();

        newExpression.filterExpressionData.fieldName = property;
        newExpression.filterExpressionData.fieldType = fieldType;

        return newExpression;
    }

    public Build(): IOutput {
        return this.instanceCreationAuthority;
    }

    public get Or(): IOperations {
        this.logicState = 'OR';
        return this;
    }

    public get And(): IOperations {
        this.logicState = 'AND';
        return this;
    }

    private addOperation(
        operation: OperatorEnum,
        expressionValue: string | number | Date,
        optValue: string | number | Date = null,
    ): IExpressionBuilder {
        this.filterExpressionData.operations.push(new Operation(operation, expressionValue, optValue, this.logicState));
        return this;
    }

    public GreaterThan(value: Date | number): IExpressionBuilder {
        return this.addOperation(OperatorEnum.GreaterThan, value);
    }
    public GreaterThanOrEqualTo(value: Date | number): IExpressionBuilder {
        return this.addOperation(OperatorEnum.GreaterThanOrEqualTo, value);
    }
    public LessThan(value: Date | number): IExpressionBuilder {
        return this.addOperation(OperatorEnum.LessThan, value);
    }
    public LessThanOrEqualTo(value: Date | number): IExpressionBuilder {
        return this.addOperation(OperatorEnum.LessThanOrEqualTo, value);
    }
    public Equal(value: Date | number | string): IExpressionBuilder {
        return this.addOperation(OperatorEnum.EqualTo, value);
    }
    public NotEqualTo(value: Date | number | string): IExpressionBuilder {
        return this.addOperation(OperatorEnum.NotEqualTo, value);
    }
    public Between(valueLow: Date | number, valueHigh: Date | number): IExpressionBuilder {
        return this.addOperation(OperatorEnum.Between, valueLow, valueHigh);
    }
    public Contains(value: Date | number | string): IExpressionBuilder {
        return this.addOperation(OperatorEnum.Contains, value);
    }
    public DoesNotContain(value: Date | number | string): IExpressionBuilder {
        return this.addOperation(OperatorEnum.DoesNotContain, value);
    }
    public StartsWith(value: string): IExpressionBuilder {
        return this.addOperation(OperatorEnum.StartsWith, value);
    }
    public EndsWith(value: string | number): IExpressionBuilder {
        return this.addOperation(OperatorEnum.EndsWith, value);
    }

    private constructor() {
        this.instanceCreationAuthority = this;
    }
}
