import { ValidatorFn } from '@angular/forms';

export class DynamicValidator {
    type: string;
    value: any;
    validatorFn: ValidatorFn;

    constructor(type: string, value: any, validatorFn: () => ValidatorFn) {
        this.type = type;
        this.value = value;
        this.validatorFn = validatorFn();
    }
}
